import { lensPath, set } from 'ramda';
import * as types from '../../../types';
import { setData } from './player';

const logsP = ['apps', 'admin', 'logs'];

export const appsAdminDeathsLogsSearchP = [...logsP, 'search'];
export const appsAdminDeathsLogsSearchL = lensPath(appsAdminDeathsLogsSearchP);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminDeathsLogsSearchL, search, state);
};

export const appsAdminLogResultsP = [...logsP, 'results'];
export const appsAdminLogCompletedP = [...logsP, 'completed'];
const setResults = setData(appsAdminLogResultsP, appsAdminLogCompletedP);

export const appsAdminLogsReducerTree = {
  [types.ADMIN_LOGS_SEARCH_SET]: [setSearch],
  [types.ADMIN_LOGS_FETCH_SUCCESS]: [setResults],
};
