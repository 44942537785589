import { useState } from 'react';
import { convertToMapGMAP } from './GtaMap.utils';

declare var google: any;

const createMarker = (
  map: any,
  x: number,
  y: number,
  icon: string,
  title: string = 'anonym'
) => {
  var marker = new google.maps.Marker({
    position: convertToMapGMAP(x, y),
    title,
    // draggable: true
    icon,
  });

  //create infowindow for this marker
  var infowindow = new google.maps.InfoWindow({
    content: marker.title,
  });

  // close open infowindow when clicked on map
  google.maps.event.addListener(map, 'click', function () {
    infowindow.close();
  });

  // open infowindow when clicked on marker
  google.maps.event.addListener(marker, 'click', function () {
    infowindow.open(map, marker);
  });

  // To add the marker to the map, call setMap();
  marker.setMap(map);

  return marker;
};

export type GtaMapMarkerType = {
  x: number;
  y: number;
  icon: string;
  title: string;
  id: string;
};

export const useGtaMapMarker = () => {
  const [markers, setMarkers] = useState<any[]>([]);

  const updateMarkers = (map: any, newMarkers: GtaMapMarkerType[]) => {
    // delete old ones
    markers.forEach((marker) => {
      marker.setMap(null);
    });

    // create new markers
    const googleMarkers = newMarkers.map((marker) => {
      return createMarker(map, marker.x, marker.y, marker.icon, marker.title);
    });
    setMarkers(googleMarkers);
  };

  return [updateMarkers];
};
