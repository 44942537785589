import { Dispatch } from 'redux';
import { setIsIngame, startLogin, updateVisiblity } from '../actions';

declare var window: any;

export const initStartup = (dispatch: Dispatch, getState: () => any) => {
  if ('alt' in window) {
    dispatch(updateVisiblity(false));
    dispatch(setIsIngame(true));

    window.alt.on('setVisible', (isVisible: boolean) => {
      dispatch(updateVisiblity(isVisible));
    });

    window.alt.on('login', (username: string, password: string) => {
      dispatch(startLogin(username, password));
    });
  } else {
    dispatch(updateVisiblity(true));
  }
};
