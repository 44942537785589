import { append, lensPath, path, pathOr, pipe, set } from 'ramda';
import * as types from '../../types';

export const defaultTabs = () => [
  {
    history: [''],
    historyPointer: 0,
  },
];

export const appsBrowserTabP = ['apps', 'browser', 'tabs'];
export const appsBrowserTabL = lensPath(appsBrowserTabP);
const updateTabGoto = (state: any, { index, url }: any) => {
  return pipe(
    pathOr(defaultTabs(), appsBrowserTabP),
    (tabs) => {
      const tab = tabs[index] || defaultTabs()[0];
      tab.history = [...tab.history.slice(0, tab.historyPointer + 1), url];
      tab.historyPointer = tab.history.length - 1;

      return set(lensPath([index]), tab, tabs);
    },
    (tabs) => set(appsBrowserTabL, tabs, state)
  )(state);
};

export const appsBrowserActiveTabIndexP = ['apps', 'browser', 'activeTabIndex'];
export const appsBrowserActiveTabIndexL = lensPath(appsBrowserActiveTabIndexP);
const updateActiveIndex = (state: any, { index }: any) => {
  return set(appsBrowserActiveTabIndexL, index, state);
};

const createTab = (state: any, {}: any) => {
  const newTab = defaultTabs()[0];
  const tabs = pipe(
    pathOr(defaultTabs(), appsBrowserTabP),
    append(newTab)
  )(state);
  return pipe(
    set(appsBrowserTabL, tabs),
    set(appsBrowserActiveTabIndexL, tabs.length - 1)
  )(state);
};

const goBack = (state: any, { index }: any) => {
  const tab: any = path([...appsBrowserTabP, index], state);
  const newPointer = Math.max(tab.historyPointer - 1, 0);
  return set(
    lensPath([...appsBrowserTabP, index, 'historyPointer']),
    newPointer,
    state
  );
};
const goForward = (state: any, { index }: any) => {
  const tab: any = path([...appsBrowserTabP, index], state);
  const newPointer = Math.min(tab.historyPointer + 1, tab.history.length - 1);
  return set(
    lensPath([...appsBrowserTabP, index, 'historyPointer']),
    newPointer,
    state
  );
};

export const appsBrowserCarsP = ['apps', 'browser', 'cars'];
export const appsBrowserCarsL = lensPath(appsBrowserCarsP);
const setCars = (state: any, { data }: any) => {
  return set(appsBrowserCarsL, data, state);
};

export const appsBrowserReducerTree = {
  [types.BROWSER_TAB_GOTO]: [updateTabGoto],
  [types.BROWSER_TAB_SELECT]: [updateActiveIndex],
  [types.BROWSER_TAB_CREATE]: [createTab],
  [types.BROWSER_TAB_BACK]: [goBack],
  [types.BROWSER_TAB_FORWARD]: [goForward],
  [types.BROWSER_CARS_FETCH_SUCCESS]: [setCars],
};
