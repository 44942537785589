export const Black = '#000000';

export const Gray100 = '#000000';
export const Gray90 = '#1A1A1A';
export const Gray80 = '#333333';
export const Gray70 = '#4d4d4d';
export const Gray60 = '#666666';
export const Gray50 = '#808080';
export const Gray40 = '#999999';
export const Gray30 = '#b3b3b3';
export const Gray20 = '#cccccc';
export const Gray10 = '#e6e6e6';
export const Gray0 = '#ffffff';
export const Gray = Gray50;

export const White = '#ffffff';

export const Red = '#d50000';
export const RedExtraLight = '#e66666';
export const RedLight = '#dd3333';
export const RedDark = '#aa0000';
export const RedExtraDark = '#800000';

export const Yellow = '#ffd600';
export const YellowLight = '#ffde33';
export const YellowDark = '#ccab00';

export const Blue = '#2962ff';
export const BlueLight = '#5481ff';
export const BlueDark = '#214ecc';
export const BlueNight = '#081e2b';
export const BlueNightDark = '#040f16';

export const Green = '#00c853';
export const GreenLight = '#33d375';
export const GreenDark = '#00a042';

export const Purple = '#aa00ff';
export const PurpleLight = '#bb33ff';
export const PurpleDark = '#8800cc';

export const Pink = '#c51162';
export const PinkLight = '#d14181';
export const PinkDark = '#9e0e4e';

export const Orange = '#ff6d00';
export const OrangeLight = '#ff8a33';
export const OrangeDark = '#cc5700';
export const OrangePumpkin = '#dd8800';
export const OrangePumpkinDark = '#bb6600';

export const Cyan = '#00b8d4';
export const CyanLight = '#33c6dd';
export const CyanDark = '#0093aa';

export const Teal = '#00bfa5';
export const TealLight = '#33ccb7';
export const TealDark = '#009984';

export const Brown = '#6d4c41';
export const BrownLight = '#8a7067';
export const BrownDark = '#573d34';

export const PrimaryColor = Red;
export const PrimaryColorLight = RedLight;
export const PrimaryColorDark = RedDark;
export const PrimaryColorExtraDark = RedExtraDark;

export const SecondaryColor = Yellow;
export const SecondaryColorLight = YellowLight;
export const SecondaryColorDark = YellowDark;

export const tailwindGray = (dark) => ({
  50: dark ? '#0d0d0d' : '#f2f2f2',
  100: dark ? '#1a1a1a' : '#e6e6e6',
  200: dark ? '#333333' : '#cccccc',
  300: dark ? '#4d4d4d' : '#b3b3b3',
  400: dark ? '#666666' : '#999999',
  500: dark ? '#999999' : '#666666',
  600: dark ? '#b3b3b3' : '#4d4d4d',
  700: dark ? '#cccccc' : '#333333',
  800: dark ? '#e6e6e6' : '#1a1a1a',
  900: dark ? '#f2f2f2' : '#0d0d0d',
});

export const COLORS = {
  Black,
  Gray100,
  Gray90,
  Gray80,
  Gray70,
  Gray60,
  Gray50,
  Gray40,
  Gray30,
  Gray20,
  Gray10,
  Gray0,
  Gray,

  White,

  Red,
  RedExtraLight,
  RedLight,
  RedDark,
  RedExtraDark,

  Yellow,
  YellowLight,
  YellowDark,

  Blue,
  BlueLight,
  BlueDark,
  BlueNight,
  BlueNightDark,

  Green,
  GreenLight,
  GreenDark,

  Purple,
  PurpleLight,
  PurpleDark,

  Pink,
  PinkLight,
  PinkDark,

  Orange,
  OrangeLight,
  OrangeDark,
  OrangePumpkin,
  OrangePumpkinDark,

  Cyan,
  CyanLight,
  CyanDark,

  Teal,
  TealLight,
  TealDark,

  Brown,
  BrownLight,
  BrownDark,
};
