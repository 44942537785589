import { concat, lensPath, pathOr, pipe, set } from 'ramda';
import * as types from '../../../types';

export const appsAutorestartsP = ['apps', 'admin', 'autorestarts'];
export const appsAutorestartsL = lensPath(appsAutorestartsP);
const setAutorestarts = (state: any, { data }: any) => {
  return set(appsAutorestartsL, data, state);
};

const setAutorestartProp = (state: any, { path, value }: any) => {
  return set(lensPath([...appsAutorestartsP, ...path]), value, state);
};

export const appsRestartsP = ['apps', 'admin', 'restarts', 'data'];
export const appsRestartsL = lensPath(appsRestartsP);
export const appsRestartCompleteLoadedP = [
  'apps',
  'admin',
  'restarts',
  'completeLoaded',
];
export const appsRestartCompleteLoadedL = lensPath(appsRestartCompleteLoadedP);
const RESTART_SIZE = 25;
const setRestarts = (state: any, { data, offset }: any) => {
  const newData =
    offset === 0 ? data : pipe(pathOr([], appsRestartsP), concat(data))(state);
  return pipe(
    set(appsRestartsL, newData),
    set(appsRestartCompleteLoadedL, data.length < RESTART_SIZE)
  )(state);
};

export const appsAdminRestartReducerTree = {
  [types.ADMIN_RESTARTS_TIMETABLE_FETCH_SUCCESS]: [setAutorestarts],
  [types.ADMIN_RESTARTS_TIMETABLE_PROP_SET]: [setAutorestartProp],
  [types.ADMIN_RESTARTS_FETCH_SUCCESS]: [setRestarts],
};
