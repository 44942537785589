import { css } from '@emotion/react';
import React from 'react';

const containerStyle = css`
  margin-top: 2vh;
`;

export const Section = ({ text, customCss = '' }: any) => {
  return (
    <div
      css={[
        containerStyle,
        css`
          ${customCss}
        `,
      ]}
    >
      {text}
    </div>
  );
};
