import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startAdminPlayerTicketsFetch } from '../../../../actions';
import {
  appsAdminPlayerTicketsCompletedP,
  appsAdminPlayerTicketsP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { PlayerTicketsType } from '../types/playertickets';
import { DateTime } from 'luxon';
import { detailContainerStyle } from '../styles';

type TicketsType = {
  _id: string;
};
export const Tickets = ({ _id }: TicketsType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerTicketsType[]>(
    pathOr([], appsAdminPlayerTicketsP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerTicketsCompletedP)
  );

  const onLoad = () => {
    dispatch(startAdminPlayerTicketsFetch(_id));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Tickets</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad()}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Zivilist</TableCell>
              <TableCell>Aussteller</TableCell>
              <TableCell>Vergehen</TableCell>
              <TableCell>Betrag</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell>
                  {DateTime.fromJSDate(new Date(entry.created))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>{entry.owner.name}</TableCell>
                <TableCell>{entry.creator.name}</TableCell>
                <TableCell>{entry.reason}</TableCell>
                <TableCell>${entry.price}</TableCell>
                <TableCell>
                  {entry.paid
                    ? 'Bezahlt'
                    : entry.deleted
                    ? 'Gelöscht'
                    : 'Unbezahlt'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
