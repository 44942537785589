import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAdminPlayerWarning,
  startAdminPlayerWarningsFetch,
} from '../../../../actions';
import {
  appsAdminPlayerWarningsCompletedP,
  appsAdminPlayerWarningsP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import { PlayerWarningsType } from '../types/playerWarnings';
import Add from '@mui/icons-material/Add';
import { detailContainerStyle } from '../styles';

const textFieldStyle = css`
  width: 100%;
`;

type WarningsType = {
  _id: string;
};
export const Warnings = ({ _id }: WarningsType) => {
  const [newWarn, setNewWarn] = useState('');
  const [showNew, setShowNew] = useState(false);
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerWarningsType[]>(
    pathOr([], appsAdminPlayerWarningsP)
  );
  const hasLoaded = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerWarningsCompletedP)
  );

  const onLoad = () => {
    dispatch(startAdminPlayerWarningsFetch(_id));
  };

  const create = () => {
    dispatch(createAdminPlayerWarning(_id, newWarn));
    setNewWarn('');
    setShowNew(false);
  };

  const abort = () => {
    setShowNew(false);
  };

  return (
    <div css={detailContainerStyle}>
      <h2>
        Verwarnungen{' '}
        <Button variant="outlined" onClick={() => setShowNew(true)}>
          <Add />
        </Button>
      </h2>

      {list.length === 0 && !hasLoaded && (
        <Button variant="outlined" onClick={() => onLoad()}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Benutzer</TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>Grund</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell>
                  {DateTime.fromJSDate(new Date(entry.date))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>{entry.player?.name}</TableCell>
                <TableCell>{entry.admin?.name}</TableCell>
                <TableCell>{entry.reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {showNew && (
        <>
          <h3>Neue Verwarnung</h3>

          <TextField
            label="Beschreibung"
            type="text"
            value={newWarn}
            onChange={(ev: any) => setNewWarn(ev.target.value)}
            css={textFieldStyle}
          />

          <Button variant="outlined" onClick={() => create()}>
            Absenden
          </Button>
          <Button variant="outlined" onClick={() => abort()}>
            Abbrechen
          </Button>
        </>
      )}
    </div>
  );
};
