const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fix = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const funkStyle = {
  background: '#303947',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedLeft = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'text-align': 'left',
  color: '#fff',
  border: '0 !important',
};
const usedCenter = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const select = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
//Styles ab const einfügen für sep. - kann auch ohne Eigenschaft wie background genutzt werden
export default [
  //Dispatchmenü
  [
    {
      value: 'DIENSTPLAN',
      colSpan: 13,
      style: fix,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 13,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    {
      value: 'Ten-Codes (Kurzversion)',
      colSpan: 3,
      style: fix,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Dispatch',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Innendienst',
      colSpan: 3,
      style: fix,
      readOnly: true,
      width: '60vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-5',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Am Einsatzort',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: 'Mitarbeiter:', readOnly: true, style: usedCenter, width: '10vh' },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'D3', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      colSpan: 2,
      width: '25vh',
      type: 'lookup',
      searchField: 'D3', //Name einfügen
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'H3', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '10-6',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Beschäftigt',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'D4', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'D4', //Name einfügen
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'H4', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '10-7',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'On Duty',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 5,
      style: empty,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'E5', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '10-9',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'RTS',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Leader Units',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'E6', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '10-10',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Verstärkung',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'D7', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      colSpan: 2,
      width: '25vh',
      type: 'lookup',
      searchField: 'D7', //Name einfügen
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'H7', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '10-20',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Standort, Status',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'D8', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      colSpan: 2,
      width: '25vh',
      type: 'lookup',
      searchField: 'D8', //Name einfügen
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'H8', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '10-25',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Einsatzanfahrt',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'D9', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      colSpan: 2,
      type: 'lookup',
      searchField: 'D9', //Name einfügen
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'H9', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '10-50',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'VU Zivilfahrzeug',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'D10', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      colSpan: 2,
      width: '25vh',
      type: 'lookup',
      searchField: 'D10', //Name einfügen
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    { value: '', style: select, width: '10vh' }, //hier kommt die Dienstnummer rein zum Suchen
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '50vh',
      type: 'lookup',
      searchField: 'H10', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lspdcontroldata', //da soll er suchen
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata', //das soll er nehmen
        column: 'E',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A14',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A14',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A14',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G14',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G14',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G14',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A15',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A15',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A15',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G15',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G15',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G15',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A16',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A16',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A16',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G16',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G16',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G16',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A20',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A20',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A20',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G20',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G20',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G20',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A21',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A21',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A21',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G21',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G21',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G21',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A22',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A22',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A22',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G22',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G22',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G22',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A26',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A26',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A26',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G26',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G26',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G26',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A27',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A27',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A27',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G27',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G27',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G27',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A28',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A28',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A28',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G28',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G28',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G28',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A32',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A32',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A32',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G32',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G32',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G32',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A33',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A33',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A33',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G33',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G33',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G33',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A34',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A34',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A34',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G34',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G34',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G34',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A38',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A38',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A38',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G38',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G38',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G38',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A39',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A39',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A39',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G39',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G39',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G39',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A40',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A40',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A40',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G40',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G40',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G40',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A44',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A44',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A44',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G44',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G44',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G44',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A45',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A45',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A45',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G45',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G45',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G45',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A46',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A46',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A46',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G46',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G46',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G46',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A50',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A50',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A50',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G50',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G50',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G50',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A51',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A51',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A51',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G51',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G51',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G51',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A52',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A52',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A52',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G52',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G52',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G52',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A56',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A56',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A56',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G56',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G56',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G56',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A57',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A57',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A57',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G57',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G57',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G57',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A58',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A58',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A58',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G58',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G58',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G58',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A62',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A62',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A62',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G62',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G62',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G62',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A63',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A63',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A63',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G63',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G63',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G63',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A64',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A64',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A64',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G64',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G64',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G64',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 6, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      style: empty,
      colSpan: 1,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department Fahrzeugauswahl',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Fahrzeug:', readOnly: true, style: usedCenter },
    {
      value: '',
      style: select,
      readOnly: true,
      colSpan: 2,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'A',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'K',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: select,
      readOnly: true,
      type: 'select',
      depOptions: {
        sheetId: 'lspdcontroldata',
        column: 'I',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A68',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A68',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A68',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: 'Mitarbeiter:', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G68',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G68',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G68',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A69',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A69',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A69',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G69',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G69',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G69',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
  [
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A70',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 2,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A70',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'A70',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: empty,
      readOnly: true,
      width: '10vh',
    },
    { value: '', style: usedCenter, readOnly: true, width: '10vh' },
    { value: '', style: select, width: '10vh' },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'G70',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'D',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G70',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'E',
        rowStart: 1,
      },
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '25vh',
      type: 'lookup',
      searchField: 'G70',
      lookup: {
        sheetId: 'lspdcontroldata',
        column: 'C',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lspdcontroldata',
        column: 'F',
        rowStart: 1,
      },
    },
  ],
];
