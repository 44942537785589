import { lensPath, set } from 'ramda';
import { useReducer } from 'react';

export const requestRequiredSheets = (
  socket: any,
  requiredSheets: string[],
  updateDeps: (sheetId: string, data: any[][]) => void
) => {
  const closeIds: string[] = [];
  const closeChangeListener: any[] = [];

  requiredSheets.forEach((sheetId) => {
    socket.emit('datasheet.subscribe', sheetId, (id: string) => {
      closeIds.push(id);
    });

    socket.emit('datasheet.request', sheetId, (data: any) => {
      if (data.cells) {
        // save cells
        updateDeps(sheetId, data.cells);
      }
    });

    const listener = (data: any) => {
      if (data.id === sheetId) {
        // save data.cells
        updateDeps(sheetId, data.cells);
      }
    };
    socket.on('datasheet.changed', listener);
    closeChangeListener.push(listener);
  });

  return () => {
    closeChangeListener.forEach((listener) => {
      socket.off('datasheet.changed', listener);
    });
    closeIds.forEach((closeId) => {
      socket.emit('datasheet.unsubscribe', closeId);
    });
  };
};

type StateType = {
  [sheetId: string]: any[][];
};
export const useDependencySheetReducer = (): [
  StateType,
  () => void,
  (sheetId: string, data: any[][]) => void
] => {
  const initialState: StateType = {};
  const reducer = (
    state: StateType,
    action: { type: string; sheetId?: string; data?: any[][] }
  ) => {
    let newState;
    switch (action.type) {
      case 'clear':
        newState = {};
        break;
      case 'update':
        newState = {
          ...state,
          [action?.sheetId || 'error']: action.data || [],
        };
        break;
      default:
        throw new Error();
    }
    return newState;
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const clear = () => dispatch({ type: 'clear' });
  const update = (sheetId: string, data: any[][]) =>
    dispatch({ type: 'update', sheetId, data });
  return [state, clear, update];
};
