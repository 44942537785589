import React, { useState } from 'react';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/LockRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import Headline from '../../Headline';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

type MakeMainAccountType = {
  vban: string;
  close: () => void;
  makeMain: (vban: string, pin: string) => void;
};

export const MakeMainAccount = ({
  vban,
  close,
  makeMain,
}: MakeMainAccountType) => {
  const [currentPin, setCurrentPin] = useState('');

  const execute = () => {
    makeMain(vban, currentPin);
    close();
  };

  return (
    <div>
      <Headline>PIN ändern</Headline>
      <TextField
        css={inputStyle}
        type="password"
        placeholder="PIN"
        value={currentPin}
        onChange={(ev) => setCurrentPin(ev.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button onClick={execute} startIcon={<SaveIcon />}>
        Zum Hauptkonto machen
      </Button>

      <Button onClick={close} startIcon={<CloseIcon />}>
        Schliessen
      </Button>
    </div>
  );
};
