import { lensPath, set } from 'ramda';
import * as types from '../../types';

export const appsFractionOverviewP = ['apps', 'generalFractions', 'overview'];
export const appsFractionOverviewL = lensPath(appsFractionOverviewP);
const setOverview = (state: any, { data }: any) => {
  return set(appsFractionOverviewL, data, state);
};

export const appsLsmdMapVehiclesP = ['apps', 'lsmd', 'map', 'vehicles'];
export const appsLsmdMapVehiclesL = lensPath(appsLsmdMapVehiclesP);
const setMapVehicles = (state: any, { data }: any) => {
  return set(appsLsmdMapVehiclesL, data, state);
};

export const appsLsmdMapCallsP = ['apps', 'lsmd', 'map', 'calls'];
export const appsLsmdMapCallsL = lensPath(appsLsmdMapCallsP);
const setMapCalls = (state: any, { data }: any) => {
  return set(appsLsmdMapCallsL, data, state);
};

export const appsLsmdOldFilesListP = ['apps', 'lsmd', 'oldfiles', 'list'];
export const appsLsmdOldFilesListL = lensPath(appsLsmdOldFilesListP);
const setOldFilesList = (state: any, { data }: any) => {
  return set(appsLsmdOldFilesListL, data, state);
};

export const appsLsmdOldFileP = ['apps', 'lsmd', 'oldfiles', 'detail'];
export const appsLsmdOldFileL = lensPath(appsLsmdOldFileP);
const setOldFile = (state: any, { data }: any) => {
  return set(appsLsmdOldFileL, data, state);
};

export const appsLsmdReducerTree = {
  [types.FRACTION_OVERVIEW_DUTY_FETCH_SUCCESS]: [setOverview],
  [types.LSMD_MAP_VEHICLES_FETCH_SUCCESS]: [setMapVehicles],
  [types.LSMD_MAP_CALLS_FETCH_SUCCESS]: [setMapCalls],
  [types.LSMD_OLDFILES_LIST_SUCCESS]: [setOldFilesList],
  [types.LSMD_OLDFILE_FETCH_SUCCESS]: [setOldFile],
};
