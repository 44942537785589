import controlCenterTemplate from '../../datasheets/templates/lsmd/controlcenter';
import controlDatasheet from '../../datasheets/templates/lsmd/control-datasheet';
import dashboardTemplate from '../../datasheets/templates/lsmd/dashboard';
import leadTemplate from '../../datasheets/templates/lsmd/lead';
import aaoTemplate from '../../datasheets/templates/lsmd/aao';
import medcareTemplate from '../../datasheets/templates/lsmd/medcare';
import stationcareTemplate from '../../datasheets/templates/lsmd/stationcare';
import medsTemplate from '../../datasheets/templates/lsmd/meds';
import medsDocTemplate from '../../datasheets/templates/lsmd/medsDoc';
import manvTemplate, {
  manvLineTemplate,
} from '../../datasheets/templates/lsmd/manv';
import signsTemplate, {
  signsLineTemplate,
} from '../../datasheets/templates/lsmd/signs';
import vehiclecontrolTemplate, {
  lsmdVehicleControlLineTemplate,
} from '../../datasheets/templates/lsmd/vehiclecontrol';

export const LSMDAppSheets = [
  {
    sheetId: 'lsmddashboard',
    label: 'Dashboard',
    right: 'lsmd.dashboard',
    config: {
      template: dashboardTemplate,
      requiredSheets: ['lsmdlead', 'lsmdcontroldata'],
    },
  },
  {
    sheetId: 'lsmdcontrolcenter',
    label: 'Dienstblatt',
    right: 'lsmd.controlcenter',
    config: {
      template: controlCenterTemplate,
      requiredSheets: ['lsmdcontroldata'],
    },
  },
  {
    sheetId: 'lsmdaoo',
    label: 'AAO',
    right: 'lsmd.aoo',
    config: {
      template: aaoTemplate,
    },
  },
  {
    sheetId: 'lsmdvehiclecontrol',
    label: 'Fahrzeug Status',
    right: 'lsmd.vehiclecontrol',
    config: {
      template: vehiclecontrolTemplate,
      requiredSheets: ['lsmdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: lsmdVehicleControlLineTemplate,
    },
  },
  {
    sheetId: 'lsmdmedcare',
    label: 'MED-Übergabe',
    right: 'lsmd.medcare',
    config: {
      template: medcareTemplate,
      requiredSheets: ['lsmdcontroldata'],
    },
  },
  {
    sheetId: 'lsmdstationcare',
    label: 'Stationär',
    right: 'lsmd.stationcare',
    config: {
      template: stationcareTemplate,
      requiredSheets: ['lsmdcontroldata'],
    },
  },
  {
    sheetId: 'lsmdmeds',
    label: 'Medikamente Paramedic',
    right: 'lsmd.meds',
    config: {
      template: medsTemplate,
    },
  },
  {
    sheetId: 'lsmdmedsDoc',
    label: 'Medikamente Doctor',
    right: 'lsmd.medsDoc',
    config: {
      template: medsDocTemplate,
    },
  },
  {
    sheetId: 'lsmdmanv',
    label: 'MANV',
    right: 'lsmd.manv',
    config: {
      template: manvTemplate,
      requiredSheets: ['lsmdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: manvLineTemplate,
    },
  },
  {
    sheetId: 'lsmdsigns',
    label: 'Material',
    right: 'lsmd.signs',
    config: {
      template: signsTemplate,
      requiredSheets: ['lsmdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: signsLineTemplate,
    },
  },
  {
    sheetId: 'lsmdlead',
    label: 'Lead',
    right: 'lsmd.lead',
    config: {
      template: leadTemplate,
    },
  },
  {
    sheetId: 'lsmdcontroldata',
    label: 'DB-Daten',
    right: 'lsmd.controldata',
    config: {
      template: controlDatasheet,
    },
  },
];
