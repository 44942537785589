import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import {
  appsAdminDeathsLogsSearchP,
  appsAdminLogCompletedP,
  appsAdminLogResultsP,
} from '../../../redux/reducer/apps/admin/logs';
import { setAdminLogsSearch, startAdminLogsFetch } from '../../../actions';
import { LogsEntryType } from './types/logs';

const printValue = (value: any) =>
  Array.isArray(value) ? value.join(' ') : value;

type LogsType = {};
export const Logs = ({}: LogsType) => {
  const dispatch = useDispatch();
  const search = useSelector<any, string>(
    pathOr('', appsAdminDeathsLogsSearchP)
  );
  const list = useSelector<any, LogsEntryType[]>(
    pathOr([], appsAdminLogResultsP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminLogCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminLogsFetch(search, offset));
  };

  const updateSearch = (ev: any) => {
    dispatch(setAdminLogsSearch(ev.target.value));
  };
  const executeSearch = () => {
    dispatch(startAdminLogsFetch(search, 0));
  };

  useEffect(() => {
    dispatch(startAdminLogsFetch(search, 0));
  }, []);

  return (
    <div>
      <h1>Logs</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={updateSearch}
      />

      <Button variant="outlined" onClick={executeSearch}>
        Suche starten
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Administrator</TableCell>
            <TableCell>Zugriff</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.created))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>{entry.permissions[0] || entry.key}</TableCell>
              <TableCell>{entry.creator?.name}</TableCell>
              <TableCell>
                {entry.payload?.player?.name ||
                  entry.payload?.bankAccount?.vban ||
                  printValue(entry.payload?.value)}
              </TableCell>
            </TableRow>
          ))}
          {!reachedEndOfList && (
            <TableRow>
              <TableCell colSpan={7}>
                <Button variant="outlined" onClick={() => onLoad(list.length)}>
                  Weitere Laden
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
