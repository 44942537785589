import * as type from '../store/types';

export function startFetchAds(offset: number = 0) {
  return {
    type: type.ADS_FETCH_START,
    offset,
  };
}

export function startAdLike(postId: string) {
  return {
    type: type.LEOGRAM_POST_LIKE_START,
    postId,
    followActionType: type.ADS_FETCH_START,
    offset: 0,
  };
}
