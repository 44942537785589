const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedFullDoctor = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  color: '#d50000',
  'font-weight': 'bold',
  'justify-content': 'center',
  border: '0 !important',
};
const usedFix = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
};
export default [
  [
    {
      value: 'MEDIKAMENTENBLATT',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Tabletten',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  //Kategorie: Schmerzmittel
  [
    {
      value: 'Kategorie: Schmerzmittel',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ibuprofen 400mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'leichte bis mäßig starke Schmerzen, Fieber und Entzündungen.',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 1200 - 2400mg pro Tag / Einzeldosis höchstens 1000mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ibuprofen 600mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'leichte bis mäßig starke Schmerzen, Fieber und Entzündungen.',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 1200 - 2400mg pro Tag / Einzeldosis höchstens 1000mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Novalgin 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'mittelstarke bis starke schmerzen über längeren Zeitraum',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 4000mg pro Tag / Einzeldosis höchstens 1000mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Aspirin 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'leichte bis mittlere Schmerzen, Blutverdünnend!',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 2000mg pro Tag / Einzeldosis höchsten 500mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  //Kategorie: Antihistaminika
  [
    {
      value: 'Kategorie: Antihistaminika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Dimenhydrinat (Vomex)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Prophylaxe oder akutbehandlung von Übelkeit und Brechreiz',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 1-3 Dragees pro Tag / Einzeldosis 1 Dragee',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Sonstige',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Placebo 600mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Placebo halt ne...',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Nasenspray',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'mit Wasser und Salz aus regionalen Anbau...',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '---',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Bepanthen Augentropfen',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'ganz normale Augentropfen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '---',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Vitamin Komplett Preperat',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'erlesene Kombination verschiedener Vitamine',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '---',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Vernebler Medikamente',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Kategorie: Beta-2-Sympathomimetika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Salbutamol 2,5ml',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung bei akuter Bronchiospastik und Bronchioobstruktion',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'Dosierung - 2 Ampullen zusammen mit einer Ampulle Ipratropium über Vernebler',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Anticholinergika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ipratropium 2ml',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung bei akuter Bronchiospastik und Bronchioobstruktion',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'Dosierung - 1 Ampullen zusammen mit 2 Ampulle Salbutamol über Vernebler',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'I.V. Medikamente',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Kategorie: Schmerzmittel',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Novalgin 1g',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'mittelstarke bis starke Schmerzen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 1-3g max. 5g',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Diclofenac 75mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'leichte bis mittelstarke Schmerzen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 75mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Katecholamine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Adrenalin 1mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Reanimation, Anaphylaxie, Blutdruckerhöhung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V./I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Reanimation: 1mg/2 Minuten - Anaphylaxie: initial 0,5mg i.m.',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Antiarrhythmetikum',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Amiodraon 150mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Abolute Arrhythmie/Hyperdynamischer Kreislaufstillstand',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'nach 3. erfolgl. Defi. 300mg + 1mg Adrenalin, nach 5. erfolgl. Defi. 150mg + 1mg Adrenalin',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Benzodiazepine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Dormicum 15mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Beruhigungsmittel (kürzere Halbwertszeit als Diazepam)',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung initial 4-7mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Glukose',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Glukose 40% (=4g/10ml)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'stabilisation von Hypoglykämien',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung initial 8g, bei gleichbleibendem Zustand nochmal 8g',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Parasympatholytika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Buscopan 20mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Kolikartige Schmerzzustände',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedics',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'Dosierung 20mg initial - muss zusammen mit Novalgin verabreicht werden',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Antihistaminika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Dimenhydrinat (Vomex 62mg)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Prophylaxe oder akutbehandlung von Übelkeit und Brechreiz',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1 Ampulle (62mg) über 2 Minuten',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Kategorie: Lokalanästhetikum',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Lidocain 100mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Lokale Betäubung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - Eine Ampulle lokal unter die Haut',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Infusionsgabe/Perfusionsgabe',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Kategorie: Schmerzmittel',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Novalgin 1g Infusion',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'leichte bis mittelstarke Schmerzen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1g in Infusion kontinuierlich über Zugang',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Infusionslösungen',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ringerlösung 1000ml',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'isotonische Infusionslösung zur Volumenersatz Therapie',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Applikation über Zugang, je nach Situation',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'NaCl - Kochsalzlösung',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Trägerlösung zum Aufziehen von Medikamenten',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Kochsalz + Medikament für Infusion- oder Perfusorapplikation',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Impfstoffe',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat/Impstoff',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Grippe-Impfst.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 vorbereitetes Impfpräperat',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Hepatitis A/B-Impfst.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 vorbereitetes Impfpräperat',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Rota-Virus-Impfst.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 vorbereitetes Impfpräperat',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Pneumokokken-Impfst.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 vorbereitetes Impfpräperat',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Meningokokken-Impfst.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 vorbereitetes Impfpräperat',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Diphterie-Impfst.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 vorbereitetes Impfpräperat',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Keuchhusten-Impfst.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '---',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.M.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Paramedic',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 vorbereitetes Impfpräperat',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],

  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Legende',
      colSpan: 4,
      style: fixFett,
      readOnly: true,
      width: '95vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'Darreichungsform',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Erklärung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Als Tablette geschluckt',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'i.v. (= intravenös)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'In eine Vene',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'i.m. (=intramuskulär)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'In den Muskel (OS, OA)',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 's.c. (= subkutan)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'In das Unterhautfettgewebe',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 's.l. (= sublingual)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Unter die Zunge',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'rektal',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Ins Rektum z.B. als Zäpchen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'CAVE',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eine Art Warnhinweis',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
];
