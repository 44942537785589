import * as types from '../redux/types';
import { pipeC, ofType, mapC } from '../utils/callbag';
import { pathOr } from 'ramda';
import {
  desktopAppsMaxZP,
  desktopAppsMinP,
  desktopAppsShowP,
  desktopAppsZP,
  desktopTitleP,
} from '../redux/reducer/desktop';
import {
  setDesktopAppsMaxZ,
  setDesktopAppsMin,
  setDesktopAppsShow,
  setDesktopAppsZ,
  setDesktopTitle,
} from '../actions';

export const onOpenApp = (action$: any, getState: Function) =>
  pipeC(
    action$,
    ofType(types.DESKTOP_APP_OPEN),
    mapC(({ id, title }: any) => {
      const returnActions: any[] = [];
      const state = getState();

      // abort when app is already selected
      const activeTitle = pathOr('', desktopTitleP, state);
      if (activeTitle === title) {
        return;
      }

      // add it to the shown app list
      const showApps = pathOr({}, desktopAppsShowP, state);
      returnActions.push(
        setDesktopAppsShow({
          ...showApps,
          [id]: true,
        })
      );

      // move to the top (use a maximum z-index)
      const maxZ = pathOr(2, desktopAppsMaxZP, state);
      const newMaxZ = maxZ + 1;
      const appsZ = pathOr({}, desktopAppsZP, state);
      returnActions.push(
        setDesktopAppsZ({
          ...appsZ,
          [id]: newMaxZ,
        })
      );

      returnActions.push(setDesktopAppsMaxZ(newMaxZ));
      returnActions.push(setDesktopTitle(title));

      // if the app has already been shown but minimized
      const minApps: any = pathOr({}, desktopAppsMinP, state);
      if (minApps[id]) {
        // move to window's last position
        const r: any = document.querySelector(`#window-${id}`);
        r.style.transform = `translate(${r.style.getPropertyValue(
          '--window-transform-x'
        )}, ${r.style.getPropertyValue('--window-transform-y')}) scale(1)`;
        r.style.transition = 'ease-in 0.3s';

        // remove it from the minimized app list
        returnActions.push(
          setDesktopAppsMin({
            ...minApps,
            [id]: false,
          })
        );
      }

      return returnActions;
    })
  );
