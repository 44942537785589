import React, { useState } from 'react';
import { Datasheet } from '../../Datasheet/Datasheet';
import { Sidebar } from '../../sidebars/Sidebar';
import datatabelTemplate from './datatable.template';
import demotabelTemplate from './demotable.template';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableViewIcon from '@mui/icons-material/TableView';
import { css } from '@emotion/react';
import { Map } from './Map';
import AddLocationIcon from '@mui/icons-material/AddLocation';

const contentStyle = css`
  position: relative;
`;

export const ContentCreationApp = () => {
  const [selectedMenu, setSelectedMenu] = useState('map');

  const menu = [
    {
      id: 'map',
      title: 'Map',
      icon: <AddLocationIcon />,
    },
    {
      id: `datasheet1`,
      title: 'Tabellendemo',
      icon: <TableViewIcon />,
    },
    {
      id: `datasheet2`,
      title: 'Datenblatt',
      icon: <TableChartIcon />,
    },
  ];

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar
          items={menu}
          selected={selectedMenu}
          select={setSelectedMenu}
        />
      </div>
      <div className="flex-grow">
        <div
          className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6"
          css={contentStyle}
        >
          {selectedMenu === 'map' && <Map />}
          {selectedMenu === 'datasheet1' && (
            <Datasheet
              sheetId="contentCreationDemo"
              config={{
                template: demotabelTemplate,
                requiredSheets: ['contentCreationData'],
              }}
              isStyleEditingEnabled={true}
            />
          )}
          {selectedMenu === 'datasheet2' && (
            <Datasheet
              sheetId="contentCreationData"
              config={{
                template: datatabelTemplate,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
