import React, { useEffect, useRef, useState } from 'react';
import { dockSvg, useDockHoverAnimation, useWindowWidth } from './DockItem';
import { motion } from 'framer-motion';
import { SvgIcon } from '@mui/material';
import { css } from '@emotion/react';
import { connect } from '../../utils/websocket';

const unreadStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
	margin-top: -8px;
	margin-left: 8px;
}
`;

const beepTone = new Audio('/sounds/mail.mp3');
beepTone.volume = 0.25;

export default function DockMailItem({
  id,
  title,
  icon,
  bgColor,
  mouseX,
  desktop,
  openApp,
  isOpen,
}: any) {
  const itemRef = useRef<any>();
  const { width } = useDockHoverAnimation(mouseX, itemRef);
  const windowWidth = useWindowWidth();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const socket = connect();
    if (!socket) {
      return;
    }
    socket.emit('mail.update.subscribe');

    let lastCount = 0;
    const updateListener = (count: number) => {
      setUnreadCount(count);

      if (count > lastCount) {
        // play sound
        beepTone.play();
      }
      lastCount = count;
    };
    socket.on('mail.newCount', updateListener);
    return () => {
      socket.off('mail.newCount', updateListener);
      socket.emit('mail.update.unsubscribe');
    };
  }, []);

  return (
    <li
      id={`dock-${id}`}
      onClick={desktop || id === 'launchpad' ? () => openApp(id) : () => {}}
      className="flex flex-col items-center justify-end mb-1 transition duration-150 ease-in origin-bottom"
    >
      <p className="tooltip text-black text-sm absolute -top-full px-3 py-1 bg-gray-300 bg-opacity-80 blur-sm rounded-md">
        {title}
      </p>
      <motion.div
        className="w-12"
        ref={itemRef}
        title={title}
        draggable={false}
        style={
          windowWidth < 640
            ? {}
            : { width, willChange: 'width', position: 'relative' }
        }
      >
        <>
          {unreadCount > 0 && (
            <div css={unreadStyle}>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 50 50"
                preserveAspectRatio="xMinYMid meet"
              >
                <rect
                  width="25px"
                  height="25px"
                  x="25px"
                  y="0px"
                  fill="red"
                  rx="20px"
                ></rect>
                <text
                  x="37px"
                  y="13px"
                  fontSize="15px"
                  fill="white"
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {unreadCount}
                </text>
              </svg>
            </div>
          )}
          <SvgIcon
            component={icon}
            className="text-black"
            css={() => dockSvg(bgColor)}
          />
        </>
      </motion.div>
      <div
        css={{ 'user-select': 'none' }}
        className={`h-1 w-1 m-0 rounded-full bg-gray-800 ${
          isOpen ? '' : 'invisible'
        }`}
      />
    </li>
  );
}
