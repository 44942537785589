import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Geltungsbereich</strong></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das Tierschutzgesetz wirkt im gesamten Raum von San Andreas und den umliegenden Gewässern.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Tierquälerei</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einem Tier psychisch oder physisch schadet, macht sich der Tierquälerei strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer ein Tier nicht artgemäß hält, macht sich ebenfalls der Tierquälerei strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Wilderei</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einem Wildtier unbefugt das Leben nimmt, macht sich der Wilderei strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer eine erhebliche Menge von Wildtieren tötet, macht sich der schweren Wilderei strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Handel von Wildfleisch</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer die Erzeugnisse der Wildtiere handelt, macht sich des illegalen Handels von Wildfleisch strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Besitz von Wildfleisch</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer im Besitz von Wildfleisch ist, macht sich des illegalen Besitzes von Wildfleisch strafbar.</span></span></p>

`;

export const TierGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
