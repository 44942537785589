import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminBankAcoounts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_BANKACCOUNTS_FETCH_START),
    mapPromiseC(({ query }: any) => {
      return authFetchJson(`/admin/bank/search/${query}`)
        .then((data) => {
          return {
            type: types.ADMIN_BANKACCOUNTS_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_BANKACCOUNTS_FETCH_FAILED,
        }));
    })
  );
