import { lensPath, set } from 'ramda';
import * as types from '../../../types';
import { setData } from './player';

const ticketsP = ['apps', 'admin', 'tickets'];

export const appsAdminTicketsSearchP = [...ticketsP, 'search'];
export const appsAdminTicketsSearchL = lensPath(appsAdminTicketsSearchP);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminTicketsSearchL, search, state);
};

export const appsAdminTicketsResultsP = [...ticketsP, 'results'];
export const appsAdminTicketsCompletedP = [...ticketsP, 'completed'];
const setResults = setData(
  appsAdminTicketsResultsP,
  appsAdminTicketsCompletedP
);

export const appsAdminTicketsReducerTree = {
  [types.ADMIN_TICKETS_SEARCH_SET]: [setSearch],
  [types.ADMIN_TICKETS_FETCH_SUCCESS]: [setResults],
};
