import { css } from '@emotion/react';

export const DIN_A4_RATIO = 1.414;
export const DOCUMENT_WITH = 800;

export const documentContainerStyle = (
  zoom: any,
  template?: { imgSrc?: string; css?: string }
) => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  zoom: ${zoom};

  .ContentEditable__root {
    width: ${DOCUMENT_WITH}px !important;
    height: ${DOCUMENT_WITH * DIN_A4_RATIO}px !important;

    ${template && template.imgSrc
      ? `
      background-image: url(${template.imgSrc});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `
      : ''}

    ${template && template.css ? template.css : ''}
  }
`;
