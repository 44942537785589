import * as type from '../store/types';

export function fetchProfilePage(userTag: string) {
  return {
    type: type.LEOGRAM_PROFILE_PAGE_FETCH_START,
    userTag,
  };
}

export function startProfileFollow(userTag: string) {
  return {
    type: type.LEOGRAM_PROFILE_FOLLOW_START,
    userTag,
  };
}

export function startProfileUnfollow(userTag: string) {
  return {
    type: type.LEOGRAM_PROFILE_UNFOLLOW_START,
    userTag,
  };
}

export function fetchProfilePageMorePosts(userTag: string) {
  return {
    type: type.LEOGRAM_PROFILE_PAGE_MORE_FETCH_START,
    userTag,
  };
}

export function startProfilePostLike(postId: string) {
  return {
    type: type.LEOGRAM_PROFILE_POST_LIKE_START,
    postId,
  };
}
