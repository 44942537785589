import { css } from '@emotion/react';
import { Button, TextField } from '@mui/material';
import { lensPath, set } from 'ramda';
import React, { useState } from 'react';
import { GtaMap } from '../../shared/GtaMap';

const markerInputContainerStyle = css`
  margin-top: 1vh;
`;
const addButtonStyleStyle = css`
  margin-top: 1vh;
`;

type DataMarkerType = {
  x: number;
  y: number;
  title: string;
  id: string;
  icon: string;
};

export const Map = () => {
  const [markers, setMarkers] = useState<DataMarkerType[]>([]);

  const updateMarker = (index: number, prop: string) => (ev: any) => {
    setMarkers(set(lensPath([index, prop]), ev.target.value, markers));
  };

  const createNewMarker = () => {
    setMarkers([
      ...markers,
      {
        x: 0,
        y: 0,
        title: 'Demo',
        id: Math.random().toString(),
        icon: '/img/map/icons/policecar.png',
      },
    ]);
  };

  return (
    <div className="w-full h-full">
      <GtaMap markers={markers} />
      {markers.map((marker, index) => (
        <div key={index} css={markerInputContainerStyle}>
          <TextField
            type="number"
            label="X"
            value={marker.x}
            onChange={updateMarker(index, 'x')}
          />
          <TextField
            type="number"
            label="Y"
            value={marker.y}
            onChange={updateMarker(index, 'y')}
          />
          <TextField
            label="Title"
            value={marker.title}
            onChange={updateMarker(index, 'title')}
          />
        </div>
      ))}
      <div css={addButtonStyleStyle}>
        <Button variant="outlined" onClick={createNewMarker}>
          +
        </Button>
      </div>
    </div>
  );
};
