import { set, lensPath, pipe, dissoc } from 'ramda';

import * as types from '../types';
import { setToken } from '../../utils/fetch';

const jwt = require('jwt-decode');

export const loginFailedP = ['loginError'];
export const loginFailedL = lensPath(loginFailedP);

const loginFailed = (state: any, { status, msg }: any) => {
  return set(loginFailedL, { status, msg }, state);
};

const loginFailedReset = (state: any, {}: any) => {
  return set(loginFailedL, undefined, state);
};

export const usernameP = ['username'];
export const usernameL = lensPath(usernameP);
export const userRightsP = ['userRights'];
export const userRightsL = lensPath(userRightsP);
export const settingsP = ['settings'];
export const settingsL = lensPath(settingsP);
const loginSuccess = (state: any, { token, username, settings }: any) => {
  // save to localStorage
  localStorage.setItem('token', token);
  localStorage.setItem('username', username);
  localStorage.setItem('settings', JSON.stringify(settings));
  setToken(token);

  if (!token) {
    return state;
  }
  const decoded: any = jwt(token);

  return pipe(
    set(usernameL, username),
    set(userRightsL, decoded.rights),
    set(settingsL, settings)
  )(state);
};

const logout = (state: any) => {
  localStorage.removeItem('token');
  setToken('');
  return pipe(dissoc('desktop'), dissoc('apps'))(state);
};

export const getLoggedInusername = () => {
  return localStorage.getItem('username') || '';
};

export const loginReducerTree = {
  [types.LOGIN_FAILED]: [loginFailed],
  [types.LOGIN_FAILED_RESET]: [loginFailedReset],
  [types.LOGIN_SUCCESS]: [loginSuccess],
  [types.LOGOUT]: [logout],
};
