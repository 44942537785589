import { set, lensPath } from 'ramda';
import * as types from '../types';

export const desktopAppsShowP = ['desktop', 'appsShow'];
export const desktopAppsShowL = lensPath(desktopAppsShowP);
const setAppsShow = (state: any, { data }: any) => {
  return set(desktopAppsShowL, data, state);
};

export const desktopAppsZP = ['desktop', 'appsZ'];
export const desktopAppsZL = lensPath(desktopAppsZP);
const setAppsZ = (state: any, { data }: any) => {
  return set(desktopAppsZL, data, state);
};

export const desktopAppsMaxZP = ['desktop', 'appsMaxZ'];
export const desktopAppsMaxZL = lensPath(desktopAppsMaxZP);
const setAppsMaxZ = (state: any, { data }: any) => {
  return set(desktopAppsMaxZL, data, state);
};

export const desktopAppsMaxP = ['desktop', 'appsMax'];
export const desktopAppsMaxL = lensPath(desktopAppsMaxP);
const setAppsMax = (state: any, { data }: any) => {
  return set(desktopAppsMaxL, data, state);
};

export const desktopAppsMinP = ['desktop', 'appsMin'];
export const desktopAppsMinL = lensPath(desktopAppsMinP);
const setAppsMin = (state: any, { data }: any) => {
  return set(desktopAppsMinL, data, state);
};

export const desktopTitleP = ['desktop', 'title'];
export const desktopTitleL = lensPath(desktopTitleP);
const setTitle = (state: any, { data }: any) => {
  return set(desktopTitleL, data, state);
};

export const desktopReducerTree = {
  [types.DESKTOP_APPS_SHOW_SET]: [setAppsShow],
  [types.DESKTOP_APPS_Z_SET]: [setAppsZ],
  [types.DESKTOP_APPS_Z_MAX_SET]: [setAppsMaxZ],
  [types.DESKTOP_APPS_MAX_SET]: [setAppsMax],
  [types.DESKTOP_APPS_MIN_SET]: [setAppsMin],
  [types.DESKTOP_TITLE_SET]: [setTitle],
};
