import React from 'react';
import { css } from '@emotion/react';
import { layoutContainerStyle } from '../styles';
import Navbar from '../container/NavbarContainer';
import NewMessageContainer from '../container/NewMessageContainer';
import Header from './Header';

const postsStyle = css`
  max-width: 1920px;
  margin: auto;
  margin-top: 0.5vh;
  margin-bottom: 7vh;
`;

export const loadMoreStyle = css`
  cursor: pointer;

  :hover {
    background: #ffffff33;
    color: #ffffff;
  }
`;

export type NewPostType = {
  fetchPosts: () => void;
  posts: any[];
  goto: (route: string) => void;
  like: (postId: string) => void;
  dislike: (postId: string) => void;
  fetchMorePosts: () => void;
  isLoading: boolean;
};

export default () => (
  <div css={layoutContainerStyle}>
    <Header />
    <Navbar />
    <NewMessageContainer />
  </div>
);
