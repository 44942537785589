import { Button, TextField } from '@mui/material';
import { pathOr, without } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import InputAdornment from '@mui/material/InputAdornment';
import MailUnread from '@mui/icons-material/MarkunreadOutlined';
import MailRead from '@mui/icons-material/DraftsOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import ReplyIcon from '@mui/icons-material/ReplyOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { DateTime } from 'luxon';
import { MailPagination, MailType } from './types';
import { convertFromRaw, EditorState } from 'draft-js';
import ReadOnlyEditor from '../../../leogram/shared/Editor/ReadOnly';
import { css } from '@emotion/react';
import { Pagination } from '@mui/material';
import { startMailDelete, startMailRead } from '../../../actions';
import { usernameP } from '../../../redux/reducer/login';
import { createResponseEditorState } from './response.utils';
import Headline from '../../Headline';
import { LexicalEditor } from '../../../leogram/shared/LexicalEditor';

const headStyle = css`
  padding-left: 1vh;
`;

const searchInputStyle = css`
  width: 100%;
  max-width: 30vh;
  margin-top: 1vh !important;
  margin-bottom: 1vh !important;
`;

const mailStyle = css`
  padding: 0 1vh;
`;

const mailHeaderStyle = (theme: any) => css`
  margin-bottom: 1vh;
  padding-bottom: 1vh;
  border-bottom: 0.1vh solid ${theme.colors.Black}66;
  font-size: 1.4vh;
`;

const mailActionContainer = (theme: any) => css`
  margin-top: 1vh;
  padding-top: 1vh;
  border-top: 0.1vh solid ${theme.colors.Black}66;
  display: flex;
  justify-content: space-between;
`;

const noBorderBottom = css`
  border-bottom: none !important;
`;

const editorStyle = css`
  background-color: #ffffff;
`;

const tableStyle = css`
  .MuiTableCell-root {
    font-size: 1.6vh !important;
  }
`;

type ListType = {
  headline: string;
  fetchAction: (search: string, page: number) => any;
  reduxDataP: string[];
  canDelete: boolean;
  canAnswer: boolean;
  canSetReadStatus?: boolean;
  switchToSend?: (receivers: string[], subject: string, body: any) => void;
};

type RowType = {
  mail: MailType;
  canSetReadStatus: boolean;
  onDelete?: (id: string) => any;
  onAnswer?: (mail: MailType, dateString: string) => any;
  onSelectMail: (mail: any) => any;
};

const Row = ({
  mail,
  canSetReadStatus,
  onDelete,
  onAnswer,
  onSelectMail,
}: RowType) => {
  const [isOpen, setIsOpen] = useState(false);
  const dateString = DateTime.fromJSDate(new Date(mail.date))
    .setLocale('de')
    .toFormat('dd.LL.yy, HH:mm');

  const onSelect = () => {
    setIsOpen(!isOpen);
    onSelectMail(mail);
  };
  return (
    <>
      <TableRow key={mail._id} onClick={onSelect}>
        <TableCell style={{ width: 50 }} css={isOpen && noBorderBottom}>
          {canSetReadStatus && !mail.read ? <MailUnread /> : <MailRead />}
        </TableCell>
        <TableCell css={isOpen && noBorderBottom}>
          {mail.sender.replace('@redlion-rp.de', '')}
        </TableCell>
        <TableCell css={isOpen && noBorderBottom}>
          {mail.receivers.length > 0
            ? mail.receivers.map(
                (receiver: string, index: number) =>
                  `${receiver.replace('@redlion-rp.de', '')}${
                    index + 1 < mail.receivers.length ? ', ' : ''
                  }`
              )
            : '-'}
        </TableCell>
        <TableCell css={isOpen && noBorderBottom}>{mail.subject}</TableCell>
        <TableCell css={isOpen && noBorderBottom}>{dateString}</TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          <TableCell colSpan={5}>
            <div css={mailStyle}>
              <div css={mailHeaderStyle}>
                <div>Absender: {mail.sender}</div>
                <div>
                  Empfänger:{' '}
                  {mail.receivers.length > 0
                    ? mail.receivers.map(
                        (receiver: string, index: number) =>
                          `${receiver}${
                            index + 1 < mail.receivers.length ? ', ' : ''
                          }`
                      )
                    : '-'}
                </div>
                <div>Betreff: {mail.subject}</div>
              </div>
              <div css={editorStyle}>
                {(!mail.version || mail.version <= 1) && (
                  <ReadOnlyEditor
                    content={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(mail.body))
                    )}
                    size={{ width: '100%', height: 'unset' }}
                  />
                )}
                {mail.version === 2 && (
                  <LexicalEditor
                    content={mail.body}
                    setContent={() => {}}
                    isReadOnly={true}
                  />
                )}
              </div>
              {(onDelete || onAnswer) && (
                <div css={mailActionContainer}>
                  {onAnswer && (
                    <Button
                      variant="outlined"
                      startIcon={<ReplyIcon />}
                      onClick={() => onAnswer(mail, dateString)}
                    >
                      Antworten
                    </Button>
                  )}
                  {onDelete && (
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={() => onDelete(mail._id)}
                    >
                      Löschen
                    </Button>
                  )}
                </div>
              )}
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export const List = ({
  headline,
  fetchAction,
  reduxDataP,
  canDelete,
  canAnswer,
  canSetReadStatus = false,
  switchToSend,
}: ListType) => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const data = useSelector<any, MailPagination | undefined>(
    pathOr(undefined, reduxDataP)
  );
  const username = useSelector<any, string>(pathOr('', usernameP));

  useEffect(() => {
    dispatch(fetchAction('', 1));
  }, []);

  const startSearch = () => {
    dispatch(fetchAction(search, 1));
  };

  const handleChangePage = (ev: any, page: number) => {
    dispatch(fetchAction(search, page));
  };

  const onSearchInput = (key: string) => {
    if (key === 'Enter') {
      startSearch();
    }
  };

  const onSelectMail = (mail: MailType) => {
    if (canSetReadStatus && data && !mail.read) {
      dispatch(startMailRead(mail._id, fetchAction(search, data.page)));
    }
  };

  const onClearSearch = () => {
    dispatch(fetchAction('', 1));
    setSearch('');
  };

  const onAnswer = (mail: MailType, dateString: string) => {
    if (switchToSend) {
      switchToSend(
        [
          mail.sender,
          ...without([username + '@redlion-rp.de'], mail.receivers),
        ],
        mail.subject,
        createResponseEditorState(
          mail.body,
          mail.sender,
          mail.receivers.join(', '),
          dateString,
          mail.subject,
          mail.version
        )
      );
    }
  };

  return data ? (
    <div>
      <div css={headStyle}>
        <Headline>{headline}</Headline>
        <TextField
          css={searchInputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: search !== '' && (
              <InputAdornment
                position="end"
                css={{ cursor: 'pointer' }}
                onClick={onClearSearch}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
          type="text"
          placeholder="Suche"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyPress={(e) => onSearchInput(e.key)}
        />
      </div>

      <Table css={tableStyle}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Absender</TableCell>
            <TableCell>Empfänger</TableCell>
            <TableCell>Betreff</TableCell>
            <TableCell>Datum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.list.map((mail) => (
            <Row
              canSetReadStatus={canSetReadStatus}
              onSelectMail={onSelectMail}
              mail={mail}
              key={mail._id}
              onDelete={
                canDelete
                  ? () =>
                      dispatch(
                        startMailDelete(
                          mail._id,
                          fetchAction(search, data.page)
                        )
                      )
                  : undefined
              }
              onAnswer={canAnswer ? onAnswer : undefined}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5}>
              <Pagination
                page={data.page}
                count={data.pages}
                onChange={handleChangePage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  ) : null;
};
