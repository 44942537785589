import {
  APPS_PHOTO_GALERY_FETCH_START,
  APPS_PHOTO_GALERY_FETCH_SUCCESS,
  SCREENSHOT_CREATE_SUCCESS,
} from '../leogram/store/types';
import * as types from '../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../utils/fetch';

export const onFetchPhotos = (action$: any) =>
  pipeC(
    action$,
    ofType(APPS_PHOTO_GALERY_FETCH_START),
    mapPromiseC(({ offset }: any) => {
      return authFetchJson('/api/photos?offset=' + offset)
        .then((data) => ({
          type: APPS_PHOTO_GALERY_FETCH_SUCCESS,
          data,
          offset,
        }))
        .catch((res) => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Abfragen deiner Bilder',
          },
        ]);
    })
  );

export const onScreenshotStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENT_SCREENSHOT_START),
    mapC(() => {
      return {
        type: 'NOTIFY_SUCCESS',
        msg: 'Screenshot wird erstellt. Bitte warten...',
      };
    })
  );

export const onScreenshotSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(SCREENSHOT_CREATE_SUCCESS),
    mapC(({ successNotify }: any) => {
      return successNotify
        ? {
            type: 'NOTIFY_SUCCESS',
            msg: 'Screenshot erstellt',
          }
        : null;
    })
  );
