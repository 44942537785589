import React, { ReactChild } from 'react';
import { useAuthentication } from './hooks';

type Props = {
  needs: string[];
  children: ReactChild;
};
export const HideUnauthorized = ({ needs, children }: Props) => {
  const [hasRight] = useAuthentication();
  return hasRight(needs) ? <React.Fragment>{children}</React.Fragment> : null;
};
