import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { pathOr, find, propEq } from 'ramda';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeLspdWeaponTag,
  fetchLspdWeaponSearch,
  setLspdWeaponSearch,
} from '../../../actions';
import {
  lspdWeaponSearchDetailsP,
  lspdWeaponSearchP,
} from '../../../redux/reducer/apps/lspd';
import { WeaponSearchData } from './types';
import { css } from '@emotion/react';
import { translate } from '../../../utils/translate';
import { DateTime } from 'luxon';
import {
  getWeaponStateLabel,
  weaponStateIconStyle,
  WEAPON_STATES,
} from './weapon.utils';
import PriorityIcon from '@mui/icons-material/FiberManualRecordRounded';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useAuthentication } from '../../../utils/authentication';
import { Autocomplete } from '@mui/material';

const containerStyle = css`
  margin-top: 3vh;
`;
const cursorStyle = css`
  cursor: pointer;
`;

type WeaponSearchType = {
  openPerson: (_id: string, label: string) => void;
};
export const WeaponSearch = ({ openPerson }: WeaponSearchType) => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const [isEditingState, setEditingState] = useState(false);
  const [weaponState, setWeaponState] = useState('NONE');
  const search = useSelector(pathOr('', lspdWeaponSearchP));
  const data = useSelector<any, null | WeaponSearchData>(
    pathOr(null, lspdWeaponSearchDetailsP)
  );

  const setSearch = (search: string) => {
    dispatch(setLspdWeaponSearch(search));
  };

  const fetchData = () => {
    dispatch(fetchLspdWeaponSearch(search));
  };

  const saveNewState = () => {
    setEditingState(false);
    dispatch(changeLspdWeaponTag(data?.id ?? '', weaponState));
  };

  useEffect(() => {
    if (data && !data.notFound) {
      setWeaponState(data.tag);
    }
  }, [data]);

  return (
    <div className="w-full h-full">
      <h1>Waffen Seriennummer suchen</h1>
      <div>
        <TextField
          label="Suche"
          type="text"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />
        <Button variant="outlined" onClick={fetchData}>
          Suchen
        </Button>
      </div>
      {data && data.notFound && (
        <div css={containerStyle}>Keine Ergebnisse gefunden</div>
      )}
      {data && !data.notFound && (
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Typ</TableCell>
                <TableCell align="center">{translate(data.hash)}</TableCell>
                <TableCell align="center">
                  <EditIcon />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Seriennummer</TableCell>
                <TableCell align="center">{data.id}</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tag</TableCell>
                <TableCell align="center">
                  {!isEditingState && (
                    <>
                      <PriorityIcon
                        css={weaponStateIconStyle}
                        className={data.tag}
                      />{' '}
                      {getWeaponStateLabel(data.tag)}
                    </>
                  )}

                  {isEditingState && (
                    <Autocomplete
                      options={WEAPON_STATES}
                      getOptionLabel={(option: any) => option.label}
                      value={
                        find<any>(
                          propEq('value', weaponState),
                          WEAPON_STATES
                        ) || {
                          value: 'dadadada',
                          label: 'dadadada',
                        }
                      }
                      onChange={(ev, newValue) =>
                        setWeaponState(newValue?.value ?? 'NONE')
                      }
                      renderInput={(params) => (
                        <TextField
                          label="Tag auswählen"
                          variant="outlined"
                          {...params}
                        />
                      )}
                      renderOption={(props: any, option: any) => (
                        <div {...props}>
                          <PriorityIcon
                            css={weaponStateIconStyle}
                            className={option.value}
                          />{' '}
                          {option.label}
                        </div>
                      )}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {hasRight('lspd.weapon.setState') && !isEditingState && (
                    <Button
                      variant="outlined"
                      onClick={() => setEditingState(true)}
                    >
                      <EditIcon />
                    </Button>
                  )}
                  {hasRight('lspd.weapon.setState') && isEditingState && (
                    <Button variant="outlined" onClick={saveNewState}>
                      <SaveIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Datum</TableCell>
                <TableCell align="center">
                  {DateTime.fromJSDate(new Date(data.createdAt))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              {data.buyer && (
                <TableRow>
                  <TableCell>Käufer</TableCell>
                  <TableCell align="center">{data.buyer?.name ?? ''}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
              {data.owner && (
                <TableRow>
                  <TableCell>Besitzer</TableCell>
                  <TableCell
                    css={cursorStyle}
                    align="center"
                    onClick={() =>
                      openPerson(data.owner?.name ?? '', data.owner?.name ?? '')
                    }
                  >
                    {data.owner?.name ?? ''}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
              {data.sellerFactory && (
                <TableRow>
                  <TableCell>gekauft bei</TableCell>
                  <TableCell align="center">
                    {data.sellerFactory?.label ?? ''}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
