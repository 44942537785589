export const ofType = (type: any) => (source: any) => (
  start: number,
  sink: Function
) => {
  if (start !== 0) return;
  source(0, (t: number, d: any) => {
    if (t === 1) {
      if (d.type === type) sink(1, d);
    } else sink(t, d);
  });
};
