import { Source } from 'callbag';
import { CallbagState } from './types';

// @ts-ignore
export const fromPromiseC = <T>(promise: Promise<unknown>): Source<T> => (
  start: CallbagState,
  sink: any
) => {
  if (start !== 0) return;
  let ended = false;
  const onfulfilled = (val: unknown) => {
    if (ended) return;
    sink(1, val);
    if (ended) return;
    sink(2);
  };
  const onrejected = (err = new Error()) => {
    if (ended) return;
    sink(2, err);
  };
  promise.then(onfulfilled, onrejected);
  sink(0, (t: CallbagState) => {
    if (t === 2) ended = true;
  });
};
