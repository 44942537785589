import { flatten } from 'ramda';
import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../utils/fetch';

export const onFetchCompanies = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANIES_FETCH_START),
    mapPromiseC(() => {
      return Promise.all([
        authFetchJson('/businesses'),
        authFetchJson('/employeeBusinesses'),
      ])
        .then(flatten)
        .then((data) => [
          {
            type: types.COMPANIES_FETCH_SUCCESS,
            data,
          },
        ])
        .catch((res) => [
          {
            type: types.COMPANIES_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Abfragen deiner Firmen',
          },
        ]);
    })
  );

export const onStartCompanyLabelChange = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_LABEL_CHANGE_START),
    mapPromiseC(({ id, name }: any) => {
      return authFetchPost('/business/change/name', { id, name })
        .then((data) => [
          {
            type: types.COMPANY_LABEL_CHANGE_SUCCESS,
            data,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Firmenname geändert',
          },
          {
            type: types.COMPANIES_FETCH_START,
          },
        ])
        .catch((res) => [
          {
            type: types.COMPANY_LABEL_CHANGE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Ändern des Namens',
          },
        ]);
    })
  );

export const onStartCompanyAddChange = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_ADD_CHANGE_START),
    mapPromiseC(({ id, adLine }: any) => {
      return authFetchPost('/business/change/adline', { id, adLine })
        .then((data) => [
          {
            type: types.COMPANY_ADD_CHANGE_SUCCESS,
            data,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Werbetext geändert',
          },
          {
            type: types.COMPANIES_FETCH_START,
          },
        ])
        .catch((res) => [
          {
            type: types.COMPANY_ADD_CHANGE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Ändern des Werbetextes',
          },
        ]);
    })
  );

export const onStartCompanyStorageFetch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_STORAGE_FETCH_START),
    mapPromiseC(({ id, page, search }: any) => {
      return authFetchJson(
        `/business/paginate/storage/${id}?page=${page}&search=${search}`
      )
        .then((data) => [
          {
            type: types.COMPANY_STORAGE_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_STORAGE_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Abfragen des Lagers',
          },
        ]);
    })
  );

export const onStartCompanyStoragePrice = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_STORAGE_PRICE_SET_START),
    mapPromiseC(({ id, items }: any) => {
      return authFetchPost(`/business/storage/${id}`, items)
        .then(() => [
          {
            type: types.COMPANY_STORAGE_PRICE_SET_SUCCESS,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Preise erfolgreich gespeichert',
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_STORAGE_PRICE_SET_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Speichern der Preise',
          },
        ]);
    })
  );

export const onStartCompanyCounterFetch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_COUNTER_FETCH_START),
    mapPromiseC(({ id, page, search }: any) => {
      return authFetchJson(
        `/business/paginate/counter/${id}?page=${page}&search=${search}`
      )
        .then((data) => [
          {
            type: types.COMPANY_COUNTER_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_COUNTER_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Abfragen der Ladentheke',
          },
        ]);
    })
  );

export const onStartCompanyCounterPrice = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_COUNTER_PRICE_SET_START),
    mapPromiseC(({ id, items }: any) => {
      return authFetchPost(`/business/counter/${id}`, items)
        .then(() => [
          {
            type: types.COMPANY_COUNTER_PRICE_SET_SUCCESS,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Preise erfolgreich gespeichert',
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_COUNTER_PRICE_SET_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Speichern der Preise',
          },
        ]);
    })
  );

export const onStartCompanyEployeeEdit = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_EMPLOYEE_EDIT_START),
    mapPromiseC(({ id, employeeId, newRights }: any) => {
      return authFetchPost('/business/change/employeerights', {
        id,
        employeeId,
        newRights,
      })
        .then(() => [
          {
            type: types.COMPANY_EMPLOYEE_EDIT_SUCCESS,
          },
          {
            type: types.COMPANIES_FETCH_START,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Rechte geändert',
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_EMPLOYEE_EDIT_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Ändern der Rechte',
          },
        ]);
    })
  );

export const onStartCompanyEployeeSalary = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_EMPLOYEE_SALARY_START),
    mapPromiseC(({ id, employeeData }: any) => {
      return authFetchPost(`/business/salary/${id}`, employeeData)
        .then(() => [
          {
            type: types.COMPANY_EMPLOYEE_SALARY_SUCCESS,
          },
          {
            type: types.COMPANIES_FETCH_START,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Gehälter geändert',
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_EMPLOYEE_SALARY_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Ändern der Gehälter',
          },
        ]);
    })
  );

export const onStartCompanyEployeeFire = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_EMPLOYEE_FIRE_START),
    mapPromiseC(({ id, name }: any) => {
      return authFetchPost('/business/change/fire', {
        id,
        name,
      })
        .then(() => [
          {
            type: types.COMPANY_EMPLOYEE_FIRE_SUCCESS,
          },
          {
            type: types.COMPANIES_FETCH_START,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${name} erfolgreich entlassen`,
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_EMPLOYEE_FIRE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: `Enlassung von ${name} fehlgeschlagen`,
          },
        ]);
    })
  );

export const onStartCompanyEployeeInvite = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_EMPLOYEE_INVITE_START),
    mapPromiseC(({ id, name }: any) => {
      return authFetchPost('/business/invitation?mail=true', {
        id,
        name,
      })
        .then(() => [
          {
            type: types.COMPANY_EMPLOYEE_INVITE_SUCCESS,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${name} erfolgreich eingeladen`,
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_EMPLOYEE_INVITE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: `Einladung von ${name} fehlgeschlagen`,
          },
        ]);
    })
  );

export const onStartCompanyCheckInFetch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_EMPLOYEE_CHECKIN_FETCH_START),
    mapPromiseC(({ id }: any) => {
      return authFetchJson(`/business/employeeCheckIn/${id}`)
        .then((data) => [
          {
            type: types.COMPANY_EMPLOYEE_CHECKIN_FETCH_SUCCESS,
            data: data.actions,
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_EMPLOYEE_CHECKIN_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: `Check Ins konntent nicht geladen werden`,
          },
        ]);
    })
  );

export const onStartCompanyExportsFetch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_EXPORTS_FETCH_START),
    mapPromiseC(({ id }: any) => {
      return authFetchJson(`/export/assignment/myauctions/${id}`)
        .then((data) => [
          {
            type: types.COMPANY_EXPORTS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_EXPORTS_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: `Exports konnten nicht geladen werden`,
          },
        ]);
    })
  );

export const onStartCompanyProductionFetch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMPANY_PRODUCTION_FETCH_START),
    mapPromiseC(({ id }: any) => {
      return authFetchJson(`/business/production/${id}`)
        .then((data) => [
          {
            type: types.COMPANY_PRODUCTION_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => [
          {
            type: types.COMPANY_PRODUCTION_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: `Produktionen konnten nicht geladen werden`,
          },
        ]);
    })
  );

export const onFetchCompaniesByOwnerSimple = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMANIES_BY_OWNER_SIMPLE_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/business-owner-simple')
        .then((data) => [
          {
            type: types.COMANIES_BY_OWNER_SIMPLE_FETCH_SUCCESS,
            data,
          },
        ])
        .catch((res) => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Abfragen der Firmen',
          },
        ]);
    })
  );
