import * as type from '../store/types';

export function startSearch(query: string) {
  return {
    type: type.LEOGRAM_PROFILE_SEARCH_FETCH_START,
    query,
  };
}

export function setSearch(text: string) {
  return {
    type: type.LEOGRAM_PROFILE_SEARCH_SET,
    text,
  };
}
