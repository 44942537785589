import { lensPath, set } from 'ramda';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MoneyIcon from '@mui/icons-material/AttachMoneyRounded';
import { FractionSalaryData } from './types';
import Headline from '../../Headline';

type SalaryType = {
  fetchSalary: () => void;
  salary: FractionSalaryData[];
};

export const Salary = ({ fetchSalary, salary }: SalaryType) => {
  useEffect(() => {
    fetchSalary();
  }, []);
  return (
    <div>
      <Headline>Gehälter</Headline>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Rang</TableCell>
            <TableCell>Gehalt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {salary.map((entry, index) => (
            <TableRow key={entry.rank}>
              <TableCell>{entry.rank}</TableCell>
              <TableCell>${entry.money}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
