import { lensPath, set } from 'ramda';
import * as types from '../../types';

export const appsMailInboxP = ['apps', 'mail', 'inbox'];
export const appsMailInboxL = lensPath(appsMailInboxP);
const setAppsMailInbox = (state: any, { data }: any) => {
  return set(appsMailInboxL, data, state);
};

export const appsMailOutboxP = ['apps', 'mail', 'outbox'];
export const appsMailOutboxL = lensPath(appsMailOutboxP);
const setAppsMailOutbox = (state: any, { data }: any) => {
  return set(appsMailOutboxL, data, state);
};

export const appsMailTrashP = ['apps', 'mail', 'outbox'];
export const appsMailTrashL = lensPath(appsMailTrashP);
const setAppsMailTrash = (state: any, { data }: any) => {
  return set(appsMailTrashL, data, state);
};

export const appsMailReducerTree = {
  [types.MAIL_INBOX_FETCH_SUCCESS]: [setAppsMailInbox],
  [types.MAIL_OUTBOX_FETCH_SUCCESS]: [setAppsMailOutbox],
  [types.MAIL_TRASH_FETCH_SUCCESS]: [setAppsMailTrash],
};
