export const containsChecklist = (content: any) => {
  if (!content) {
    return false;
  }
  if (content.listType && content.listType === 'check') {
    return true;
  }
  if (content.children) {
    for (const child of content.children) {
      if (containsChecklist(child)) {
        return true;
      }
    }
  }
  return false;
};
