import { set, lensPath } from 'ramda';

import * as types from '../types';

export const registrationFailedP = ['leogram', 'registrationFailedText'];
export const registrationFailedL = lensPath(registrationFailedP);

export const registrationCodeIdP = ['leogram', 'registrationCodeId'];
export const registrationCodeIdL = lensPath(registrationCodeIdP);

const registerFailed = (state: any, { text }: any) => {
  return set(registrationFailedL, text, state);
};

const registerSuccess = (state: any, { id }: any) => {
  return set(registrationCodeIdL, id, state);
};

export const registerReducerTree = {
  [types.LEOGRAM_REGISTER_FAILED]: [registerFailed],
  [types.LEOGRAM_REGISTER_SUCCESS]: [registerSuccess],
};
