import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC } from '../utils/callbag';
import { path } from 'ramda';
import { registrationCodeIdP } from '../store/reducer/register';
import { authFetch } from '../utils/fetch';
import { startRouterGoto } from '../actions';

export const onRegisterStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_REGISTER_START),
    mapPromiseC(
      ({ email, displayName, userTag, password, passwordRe }: any) => {
        return authFetch('/leogram/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            userTag,
            displayName,
            password,
            passwordRe,
          }),
        })
          .then((res) => {
            return res.json().then(({ id }) => [
              {
                type: types.LEOGRAM_REGISTER_SUCCESS,
                id,
              },
              startRouterGoto('/registercode'),
            ]);
          })
          .catch((res) =>
            res.text().then((status: string) => ({
              type: types.LEOGRAM_REGISTER_FAILED,
              text: status,
            }))
          );
      }
    )
  );

export const onRegisterConfirmStart = (action$: any, getState: () => any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_REGISTER_CONFIRM_START),
    mapPromiseC(({ code }: any) => {
      const id = path(registrationCodeIdP, getState());
      return authFetch('/leogram/auth/registerwithcode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          code,
        }),
      })
        .then((res) => {
          return res.json().then(({ token }) => [
            {
              type: types.LEOGRAM_REGISTER_CONFIRM_SUCCESS,
              token,
            },
            startRouterGoto('/'),
          ]);
        })
        .catch((res) =>
          res.text().then((status: string) => ({
            type: types.LEOGRAM_REGISTER_CONFIRM_FAILED,
            text: status,
          }))
        );
    })
  );
