import React from 'react';
import { connect } from 'react-redux';
import { path, pathOr, pipe } from 'ramda';
import { Testsuite } from '../../components/apps/Testsuite';
import {
  sendTestsuite,
  startFetchMyTestsuites,
  startFetchTestsuite,
  startFetchTestsuitesCatalogue,
  startFetchTestsuitesResult,
  startFetchTestsuitesResults,
  startTestsuiteAssign,
} from '../../actions';
import {
  testsuitesAssignedP,
  testsuitesCatalogueP,
  testsuitesCurrentP,
  testsuitesResultsP,
} from '../../redux/reducer/apps/testsuites';
import { darkP } from '../../redux/reducer/common';

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
    catalogue: pathOr([], testsuitesCatalogueP, state),
    assigned: pathOr([], testsuitesAssignedP, state),
    current: path<any>(testsuitesCurrentP, state),
    results: pathOr([], testsuitesResultsP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchCatalogue: pipe(startFetchTestsuitesCatalogue, dispatch),
  assign: pipe(startTestsuiteAssign, dispatch),
  fetchAssigned: pipe(startFetchMyTestsuites, dispatch),
  fetchTestsuite: pipe(startFetchTestsuite, dispatch),
  sendTestsuite: pipe(sendTestsuite, dispatch),
  fetchResults: pipe(startFetchTestsuitesResults, dispatch),
  fetchResult: pipe(startFetchTestsuitesResult, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Testsuite);
