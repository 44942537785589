import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLsmdMapCalls, fetchLsmdMapVehicles } from '../../../actions';
import {
  appsLsmdMapCallsP,
  appsLsmdMapVehiclesP,
} from '../../../redux/reducer/apps/lsmd';
import { GtaMap } from '../../shared/GtaMap';
import { MapCallData, MapVehicleData } from './types';
import { DateTime } from 'luxon';

export const LsmdLiveMap = () => {
  const dispatch = useDispatch();
  const vehicles = useSelector<any, MapVehicleData[]>(
    pathOr([], appsLsmdMapVehiclesP)
  );
  const calls = useSelector<any, MapCallData[]>(pathOr([], appsLsmdMapCallsP));

  const fetchData = () => {
    dispatch(fetchLsmdMapVehicles());
    dispatch(fetchLsmdMapCalls());
  };

  useEffect(() => {
    fetchData();
    const int = setInterval(() => {
      fetchData();
    }, 10000);
    return () => {
      clearInterval(int);
    };
  }, []);

  const markers = [
    ...calls.map((x) => ({
      x: x.position.x,
      y: x.position.y,
      title: DateTime.fromJSDate(new Date(x.date))
        .setLocale('de')
        .toFormat('dd.LL.yy, HH:mm'),
      id: x._id,
      icon: '/img/map/icons/emergency.png',
    })),
    ...vehicles.map((x) => ({
      x: x.position.x,
      y: x.position.y,
      title: x.numberplate,
      id: x._id,
      icon: '/img/map/icons/mediccar.png',
    })),
  ];

  return <GtaMap markers={markers} />;
};
