import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminAutorestarts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_RESTARTS_TIMETABLE_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/admin/server/restart/auto')
        .then((data) => {
          return {
            type: types.ADMIN_RESTARTS_TIMETABLE_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_RESTARTS_TIMETABLE_FETCH_FAILED,
        }));
    })
  );

export const onUpdateAdminAutorestart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_RESTARTS_TIMETABLE_UPDATE_START),
    mapPromiseC(({ data }: any) => {
      return authFetchPost('/admin/server/restart/auto', data)
        .then((data) => {
          return [
            {
              type: types.ADMIN_RESTARTS_TIMETABLE_UPDATE_SUCCESS,
              data,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Restart erfolgreich geändert',
            },
            {
              type: types.ADMIN_RESTARTS_TIMETABLE_FETCH_START,
            },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_RESTARTS_TIMETABLE_UPDATE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Ändern des Restarts',
          },
        ]);
    })
  );

export const onFetchAdminRestarts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_RESTARTS_FETCH_START),
    mapPromiseC(({ offset }: any) => {
      return authFetchJson(`/admin/server/restart/list?offset=${offset}`)
        .then((data) => {
          return {
            type: types.ADMIN_RESTARTS_FETCH_SUCCESS,
            data,
            offset,
          };
        })
        .catch(() => ({
          type: types.ADMIN_RESTARTS_FETCH_FAILED,
        }));
    })
  );

export const onStartRestartServer = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_RESTART_SERVER_START),
    mapPromiseC(() => {
      return authFetch('/admin/server/restart')
        .then(() => {
          return [
            {
              type: types.ADMIN_RESTART_SERVER_SUCCESS,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Server wird in kürze neu gestartet',
            },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_RESTART_SERVER_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Serverrestart konnte nicht ausgeführt werden',
          },
        ]);
    })
  );
