import { lensPath, pathOr, set } from 'ramda';
import * as types from '../../types';

export const lspdMapVehiclesP = ['apps', 'lspd', 'map', 'vehicles'];
export const lspdMapVehiclesL = lensPath(lspdMapVehiclesP);
const setMapVehicles = (state: any, { data }: any) => {
  return set(lspdMapVehiclesL, data, state);
};

export const lspdMapCallsP = ['apps', 'lspd', 'map', 'calls'];
export const lspdMapCallsL = lensPath(lspdMapCallsP);
const setMapCalls = (state: any, { data }: any) => {
  return set(lspdMapCallsL, data, state);
};

export const lspdMapAnkleMonitorP = ['apps', 'lspd', 'map', 'ankleMonitor'];
export const lspdMapAnkleMonitorL = lensPath(lspdMapAnkleMonitorP);
const setAnkleMonitor = (state: any, { data }: any) => {
  return set(lspdMapAnkleMonitorL, data, state);
};

export const lspdPersonSearchP = ['apps', 'lspd', 'person', 'search'];
export const lspdPersonSearchL = lensPath(lspdPersonSearchP);
const setPersonSearch = (state: any, { search }: any) => {
  return set(lspdPersonSearchL, search, state);
};

export const lspdPersonSearchListP = ['apps', 'lspd', 'person', 'list'];
export const lspdPersonSearchListL = lensPath(lspdPersonSearchListP);
const setPersonSearchList = (state: any, { data }: any) => {
  return set(lspdPersonSearchListL, data, state);
};

export const lspdPersonSearchDetailsP = ['apps', 'lspd', 'person', 'details'];
export const lspdPersonSearchDetailsL = lensPath(lspdPersonSearchDetailsP);
const setPersonSearchDetails = (state: any, { data }: any) => {
  return set(lspdPersonSearchDetailsL, data, state);
};

export const lspdVehicleSearchP = ['apps', 'lspd', 'vehicle', 'search'];
export const lspdVehicleSearchL = lensPath(lspdVehicleSearchP);
const setVehicleSearch = (state: any, { search }: any) => {
  return set(lspdVehicleSearchL, search, state);
};

export const lspdVehicleSearchDetailsP = ['apps', 'lspd', 'vehicle', 'details'];
export const lspdVehicleSearchDetailsL = lensPath(lspdVehicleSearchDetailsP);
const setVehicleSearchDetails = (state: any, { data }: any) => {
  return set(lspdVehicleSearchDetailsL, data, state);
};
const clearVehicleSearchDetails = (state: any) => {
  return set(lspdVehicleSearchDetailsL, null, state);
};

export const lspdWeaponSearchP = ['apps', 'lspd', 'weapon', 'search'];
export const lspdWeaponSearchL = lensPath(lspdWeaponSearchP);
const setWeaponSearch = (state: any, { search }: any) => {
  return set(lspdWeaponSearchL, search, state);
};

export const lspdWeaponSearchDetailsP = ['apps', 'lspd', 'weapon', 'details'];
export const lspdWeaponSearchDetailsL = lensPath(lspdWeaponSearchDetailsP);
const setWeaponSearchDetails = (state: any, { data }: any) => {
  return set(lspdWeaponSearchDetailsL, data, state);
};
const clearWeaponSearchDetails = (state: any) => {
  return set(lspdWeaponSearchDetailsL, null, state);
};

export const lspdWantedP = ['apps', 'lspd', 'wanted', 'list'];
export const lspdWantedL = lensPath(lspdWantedP);
const setWantedList = (state: any, { data }: any) => {
  return set(lspdWantedL, data, state);
};

export const lspdLogsP = ['apps', 'lspd', 'logs', 'data'];
export const lspdLogsL = lensPath(lspdLogsP);
const setLogs = (state: any, { data, skip }: any) => {
  return skip === 0
    ? set(lspdLogsL, data, state)
    : set(lspdLogsL, [...pathOr([], lspdLogsP, state), ...data], state);
};

export const lspdOldFilesSearchP = ['apps', 'lspd', 'oldFiles', 'search'];
export const lspdOldFilesSearchL = lensPath(lspdOldFilesSearchP);
const setOldFilesSearch = (state: any, { search }: any) => {
  return set(lspdOldFilesSearchL, search, state);
};

export const lspdOldFilesP = ['apps', 'lspd', 'oldFiles', 'list'];
export const lspdOldFilesL = lensPath(lspdOldFilesP);
const setOldFiles = (state: any, { data, skip }: any) => {
  return skip === 0
    ? set(lspdOldFilesL, data, state)
    : set(lspdOldFilesL, [...pathOr([], lspdOldFilesP, state), ...data], state);
};

export const lspdOldFileP = ['apps', 'lspd', 'oldFiles', 'details'];
export const lspdOldFileL = lensPath(lspdOldFileP);
const setOldFile = (state: any, { data }: any) => {
  return set(lspdOldFileL, data, state);
};

export const lspdReducerTree = {
  [types.LSPD_MAP_VEHICLES_FETCH_SUCCESS]: [setMapVehicles],
  [types.LSPD_MAP_CALLS_FETCH_SUCCESS]: [setMapCalls],
  [types.LSPD_MAP_ANKLEMONITOR_FETCH_SUCCESS]: [setAnkleMonitor],
  [types.LSPD_PERSON_SEARCH_SET]: [setPersonSearch],
  [types.LSPD_PERSON_SEARCH_FETCH_SUCCESS]: [setPersonSearchList],
  [types.LSPD_PERSON_DETAILS_FETCH_SUCCESS]: [setPersonSearchDetails],
  [types.LSPD_VEHICLE_SEARCH_SET]: [setVehicleSearch],
  [types.LSPD_VEHICLE_FETCH_START]: [clearVehicleSearchDetails],
  [types.LSPD_VEHICLE_FETCH_SUCCESS]: [setVehicleSearchDetails],
  [types.LSPD_WEAPON_SEARCH_SET]: [setWeaponSearch],
  [types.LSPD_WEAPON_FETCH_START]: [clearWeaponSearchDetails],
  [types.LSPD_WEAPON_FETCH_SUCCESS]: [setWeaponSearchDetails],
  [types.LSPD_WANTED_FETCH_SUCCESS]: [setWantedList],
  [types.LSPD_LOGBOOK_FETCH_SUCCESS]: [setLogs],
  [types.LSPD_OLDFILES_SEARCH_SET]: [setOldFilesSearch],
  [types.LSPD_OLDFILES_FETCH_SUCCESS]: [setOldFiles],
  [types.LSPD_OLDFILE_FETCH_SUCCESS]: [setOldFile],
};
