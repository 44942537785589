import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { append, lensPath, path, pipe, set, without } from 'ramda';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Headline from '../../Headline';

const questionContainerStyle = css`
  margin: 3vh 1vh;
`;

const subHeadlineStyle = css`
  font-size: 1.5vh;
`;

const questionStyle = css`
  padding-bottom: 0.5vh;
  font-size: 1.375vh;
  font-weight: bold;
`;
const answerStyle = css`
  display: block;
`;

const answerContainerStyle = css`
  display: flex;
  align-items: center;
`;

const correctAnswerStyle = (theme: any) => css`
  padding: 1vh;
  background: ${theme.colors.GreenLight}99;
`;
const wrongAnswerStyle = (theme: any) => css`
  padding: 1vh;
  background: ${theme.colors.RedLight}8a;
`;

const textareaStyle = css`
  width: 100%;
  height: 10vh;
  border-radius: 0.3vh;
  padding: 0.5vh;
  color: #000000;
`;

export type TestSuiteType = {
  id: string;
  label: string;
  examinee?: string;
  multipleChoice: {
    hash: string;
    label: string;
    answers: {
      hash: string;
      label: string;
      userDecision?: boolean;
      isCorrect?: boolean;
    }[];
  }[];
  freeText: {
    hash: string;
    label: string;
    answer?: string;
  }[];
};

type SuiteType = {
  testsuite: TestSuiteType;
  isExaminee: boolean;
  sendTestsuite: (
    id: string,
    multipleChoiceAnswers: any[],
    freeTextAnswers: any[]
  ) => void;
};

export const Suite = ({ testsuite, sendTestsuite, isExaminee }: SuiteType) => {
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState<
    { question: string; answer: string[] }[]
  >([]);
  const [freeTextAnswers, setFreeTextAnswers] = useState<
    { question: string; answer: string }[]
  >([]);

  useEffect(() => {
    setMultipleChoiceAnswers(
      testsuite.multipleChoice.map((question) => ({
        question: question.hash,
        answer: isExaminee
          ? []
          : question.answers.filter((x) => x.userDecision).map((x) => x.hash),
      }))
    );
    setFreeTextAnswers(
      testsuite.freeText.map((question) => ({
        question: question.hash,
        answer: isExaminee ? '' : question.answer || '',
      }))
    );
  }, [testsuite]);

  const setMultipleChoice = (
    questionIndex: number,
    enabled: boolean,
    answerHash: string
  ) => {
    pipe<any, any, any, any, void>(
      path([questionIndex, 'answer']),
      enabled ? append(answerHash) : without([answerHash]),
      (data) =>
        set(lensPath([questionIndex, 'answer']), data, multipleChoiceAnswers),
      setMultipleChoiceAnswers
    )(multipleChoiceAnswers);
  };

  const setFreeText = (questionIndex: number, ev: any) => {
    pipe(
      path(['target', 'value']),
      (data) =>
        set(
          lensPath<any, any>([questionIndex, 'answer']),
          data,
          freeTextAnswers
        ),
      setFreeTextAnswers
    )(ev);
  };

  const send = () => {
    sendTestsuite(testsuite.id, multipleChoiceAnswers, freeTextAnswers);
  };

  return (
    <div>
      <Headline>{testsuite.label}</Headline>
      {!isExaminee && <h3>Geprüfte Person: {testsuite.examinee}</h3>}
      {testsuite.multipleChoice.length > 0 && (
        <h2 css={subHeadlineStyle}>Multiple Choice</h2>
      )}
      {testsuite.multipleChoice.map((question, index) => (
        <div key={question.hash} css={questionContainerStyle}>
          <div css={questionStyle}>
            {index + 1}) {question.label}
          </div>
          <div>
            {question.answers.map((answer, answerIndex) => {
              const checked =
                multipleChoiceAnswers[index]?.answer.indexOf(answer.hash) > -1;
              return (
                <div
                  key={answer.hash}
                  css={[
                    answerContainerStyle,
                    !isExaminee &&
                      (answer.isCorrect
                        ? correctAnswerStyle
                        : wrongAnswerStyle),
                  ]}
                >
                  <Checkbox
                    checked={checked}
                    disabled={!isExaminee}
                    onChange={() =>
                      setMultipleChoice(index, !checked, answer.hash)
                    }
                  />
                  <span css={answerStyle}>{answer.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      ))}
      {testsuite.freeText.length > 0 && (
        <h2 css={subHeadlineStyle}>Freitext</h2>
      )}
      {testsuite.freeText.map((freeText, index) => (
        <div key={freeText.hash} css={questionContainerStyle}>
          <div css={questionStyle}>
            {index + 1}) {freeText.label}
          </div>
          <div>
            <textarea
              css={textareaStyle}
              disabled={!isExaminee}
              value={freeTextAnswers[index]?.answer}
              onChange={(ev) => setFreeText(index, ev)}
            />
          </div>
        </div>
      ))}

      <Button onClick={send}>Absenden</Button>
    </div>
  );
};
