const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fix = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const funkStyle = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedLeft = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'text-align': 'left',
  color: '#fff',
  border: '0 !important',
};
const usedCenter = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
};
const select = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
};
export default [
  [
    { value: '', readOnly: true, style: empty },
    { value: 'A - Fahrzeug', readOnly: true, style: empty },
    { value: '', readOnly: true, style: empty, width: '5vh' },
    { value: 'C - D.-Nr.', readOnly: true, style: empty },
    { value: 'D - Rang', readOnly: true, style: empty },
    { value: 'E - Name', readOnly: true, style: empty, width: '20vh' },
    { value: 'F - Funktion', readOnly: true, style: empty, width: '20vh' },
    { value: 'G - Telefon-Nr.', readOnly: true, style: empty, width: '40vh' },
    { value: '', readOnly: true, style: empty },
    { value: 'I - Status', readOnly: true, style: empty, width: '5vh' },
    { value: '', readOnly: true, style: empty },
    { value: 'K - Kennung', readOnly: true, style: empty },
    { value: '', readOnly: true, style: empty },
    { value: 'M - Code', readOnly: true, style: empty },
    { value: 'N - DashBPrio', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '01', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '02', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '03', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '04', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '05', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '06', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '07', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '08', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '09', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '10', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '11', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '12', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '13', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '14', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '15', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '16', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '17', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '18', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '19', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '20', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '21', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '22', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '23', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '24', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '25', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '26', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '27', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '28', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '29', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '30', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '31', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '32', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '33', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '34', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '35', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '36', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '37', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '38', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '39', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '40', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '41', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '42', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '43', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '44', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '45', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '46', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '47', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '48', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '49', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '50', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '51', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '52', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '53', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '54', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '55', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '56', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '57', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '58', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '59', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '60', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '61', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '62', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '63', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '64', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '65', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '66', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '67', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '68', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '69', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '70', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '71', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '72', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '73', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '74', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '75', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '76', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '77', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '78', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '79', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '80', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '81', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '82', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '83', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '84', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '85', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '86', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '87', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '88', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '89', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '90', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '91', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '92', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '93', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '94', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '95', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '96', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '97', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '98', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '99', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '100', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '101', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '102', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '103', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '104', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '105', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '106', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '107', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '108', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '109', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
  [
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '110', readOnly: true, style: fix },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
    { value: '', style: usedCenter },
    { value: '', readOnly: true, style: empty },
  ],
];
