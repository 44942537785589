import { defaultTo, lensPath, path, pipe, set } from 'ramda';
import * as types from '../../types';

const TRANSACTION_LIMIT_PER_REQUEST = 25;

export const appsFinanceBanksP = ['apps', 'finance', 'banks'];
export const appsFinanceBanksL = lensPath(appsFinanceBanksP);
const setAppsFinanceBanks = (state: any, { data }: any) => {
  return set(appsFinanceBanksL, data, state);
};

export const appsFinanceAccountsP = ['apps', 'finance', 'accounts'];
export const appsFinanceAccountsL = lensPath(appsFinanceAccountsP);
const setAppsFinanceAccounts = (state: any, { data }: any) => {
  return set(appsFinanceAccountsL, data, state);
};
const clearAppsFinanceAccounts = (state: any, {}: any) => {
  return set(appsFinanceAccountsL, undefined, state);
};

export const appsFinanceTransactionsP = ['apps', 'finance', 'transactions'];
export const appsFinanceTransactionsByVbanP = (vban: string) => [
  ...appsFinanceTransactionsP,
  vban,
];
export const appsFinanceTransactionsByVbanL = (vban: string) =>
  lensPath(appsFinanceTransactionsByVbanP(vban));
const setAppsFinanceTransactions = (
  state: any,
  { vban, data, offset }: any
) => {
  const current: any = pipe(
    offset === 0 ? () => undefined : path(appsFinanceTransactionsByVbanP(vban)),
    defaultTo({
      data: [],
      isComplete: false,
    })
  )(state);

  const newCurrent = pipe(
    set(lensPath(['data']), [...current.data, ...data]),
    set(lensPath(['isComplete']), data.length < TRANSACTION_LIMIT_PER_REQUEST)
  )(current);

  return set(appsFinanceTransactionsByVbanL(vban), newCurrent, state);
};

export const appsFinanceAccessP = ['apps', 'finance', 'access'];
export const appsFinanceAccessByVbanP = (vban: string) => [
  ...appsFinanceAccessP,
  vban,
];
export const appsFinanceAccessByVbanL = (vban: string) =>
  lensPath(appsFinanceAccessByVbanP(vban));
const setAppsFinanceAccess = (state: any, { vban, data }: any) => {
  return set(appsFinanceAccessByVbanL(vban), data, state);
};

export const appsFinanceReducerTree = {
  [types.FINANCE_BANKS_FETCH_SUCCESS]: [setAppsFinanceBanks],
  [types.FINANCE_BANK_ACCOUNTS_FETCH_START]: [clearAppsFinanceAccounts],
  [types.FINANCE_BANK_ACCOUNTS_FETCH_SUCCESS]: [setAppsFinanceAccounts],
  [types.FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_SUCCESS]: [
    setAppsFinanceTransactions,
  ],
  [types.FINANCE_BANK_ACCOUNT_ACCESS_GET_SUCCESS]: [setAppsFinanceAccess],
};
