import * as type from '../store/types';

export function startFetchPhotos(offset: number) {
  return {
    type: type.APPS_PHOTO_GALERY_FETCH_START,
    offset,
  };
}

export function startDeletePhoto(photoId: string) {
  return {
    type: type.APPS_PHOTO_DELETE_START,
    photoId,
  };
}
