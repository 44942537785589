import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Geltungsbereich</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das Waffengesetz wirkt im gesamten Raum von San Andreas und den umliegenden Gewässern.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"> </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Besitz</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Besitz von Handfeuerwaffen ist nur mit einer entsprechenden Waffenlizenz gestattet.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer eine Handfeuerwaffe ohne Waffenlizenz mit sich führt, macht sich des illegalen Waffenbesitzes strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Wer eine Langwaffe, vollautomatische Waffe oder Explosionswaffe besitzt, macht sich des illegalen Waffenbesitzes strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="font-size: 14pt;">IV. Beamte des LSPD dürfen die ihnen zugeteilte Ausrüstung im Dienst offen tragen und verwenden. Es ist stets zu dokumentieren, welcher Beamte welche Ausrüstung besitzt.</span></p>
<p><span style="font-size: 14pt;">V. Wer Ausrüstung eines Beamten des LSPD mit sich führt und selber kein Beamter des LSPD ist oder sich nicht im aktiven Dienst befindet, macht sich strafbar.</span></p>
<p><span style="font-size: 14pt;"><br></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Handel</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer Waffen herstellt oder handelt, benötigt eine Lizenz.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer ohne Lizenz Waffen herstellt oder mit diesen handelt, macht sich des illegalen Waffenhandels strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Nutzung und Tragen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Nutzung von Handfeuerwaffen ist nur auf Schießständen und Privatgeländen gestattet.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Außerhalb dieser Gelände macht man sich der illegalen Nutzung von Waffen strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Die staatliche Fraktion LSPD darf Handfeuerwaffen und Langwaffen verhältnismäßig im Rahmen der hoheitlichen Maßnahmen nutzen. </span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Dem LSMD, dem DoJ und dem DMV ist es untersagt, im Dienst Waffen mit sich zu führen.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Besitzer einer Waffenlizenz dürfen ihre Waffe offen tragen.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Waffen</strong> </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Unter Waffen versteht man Handfeuerwaffen, Stichwaffen, Langwaffen, explosive Vorrichtungen und Explosionsschusswaffen.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="font-size: 14pt;">II. Messer sind keine Waffen.</span></p>
<p><span style="font-size: 14pt;">III. Eine Machete ist eine Stichwaffe.</span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 6 Waffenlizenz</strong> </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Waffenlizenz ist beim LSPD zu beantragen und hat einen festgelegten Gültigkeitszeitraum. </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Die Waffenlizenz kann vom LSPD und dem DoJ entzogen werden, sofern Fehlverhalten vom Nutzer vorliegt. </span></span></p>
`;

export const WaffenGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
