import React from 'react';
import { css } from '@emotion/react';
import { IoCloudOfflineOutline } from 'react-icons/io5';
import { GiSettingsKnobs } from 'react-icons/gi';
import RefreshIcon from '@mui/icons-material/Refresh';

type SibarType = {
  items: {
    id: string;
    icon: JSX.Element;
    title: string;
  }[];
  selected: string;
  select: (itemId: string) => void;
  refresh?: () => void;
};

export const Sidebar = ({ items, selected, select, refresh }: SibarType) => {
  return (
    <div className="sidebar w-full h-full bg-gray-100 text-black overflow-y-auto">
      <div className="h-12 pr-3 flex flex-row justify-end items-center">
        {/* <IoCloudOfflineOutline className="mr-3" size={20} />
        <GiSettingsKnobs size={20} /> */}
        {refresh && <RefreshIcon onClick={refresh} />}
      </div>
      <ul>
        {items.map((item, index) => (
          <li
            key={`bear-sidebar-${item.id}`}
            className={`py-1 pl-4 flex flex-row items-center cursor-pointer ${
              selected === item.id ? 'bg-red-400' : 'bg-transparent'
            } ${selected === item.id ? '' : 'hover:bg-gray-400'}`}
            onClick={() => select(item.id)}
          >
            <span>{item.icon}</span>
            <span className="ml-2">{item.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
