import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import {
  authFetch,
  authFetchDelete,
  authFetchJson,
  authFetchPost,
} from '../../utils/fetch';
import { DOCUMENT_A4 } from '../../leogram/shared/constants/documents';
import {
  documentsListP,
  documentsSearchP,
} from '../../redux/reducer/apps/documents';
import { pathOr } from 'ramda';

export const onFetchDocuments = (action$: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENTS_FETCH_START),
    mapPromiseC(({ page, search }: any) => {
      return authFetchJson(`/documents?page=${page}&search=${search}`)
        .then((data) => [
          {
            type: types.DOCUMENTS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Fehler beim Abfragen deiner Dokumente',
        }));
    })
  );

export const onFetchDocument = (action$: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENT_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/document/${_id}`)
        .then((data) => [
          {
            type: types.DOCUMENT_FETCH_SUCCESS,
            data: {
              ...data,
              type: data.type || DOCUMENT_A4.type,
            },
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Fehler beim Abfragen des Dokuments',
        }));
    })
  );

export const onSaveDocument = (action$: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENT_CREATE_START),
    mapPromiseC(({ document }: any) => {
      const _id = document._id;
      if (document.isNew) {
        delete document._id;
        delete document.isNew;
      }
      return authFetchPost('/document', document)
        .then((res) => res.json())
        .then((data) => [
          {
            type: types.DOCUMENT_RAW_REMOVE,
            _id,
          },
          {
            type: types.DOCUMENT_FETCH_SUCCESS,
            data,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Dokument gespeichert',
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Speichern fehlgeschlagen',
        }));
    })
  );

export const onPrintDocumentStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENT_PRINT_START),
    mapPromiseC(({ _id }: any) => {
      return authFetch(`/document-printer/print/${_id}`)
        .then((data) => [
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Dokument wird versucht zu drucken',
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Drucken fehlgeschlagen',
        }));
    })
  );

export const onDeleteDocument = (action$: any, getStore: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENT_DELETE_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchDelete(`/document/${_id}`)
        .then((data) => {
          const search = pathOr('', documentsSearchP, getStore());
          const { page } = pathOr<any>({ page: 1 }, documentsListP, getStore());

          return [
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Dokument gelöscht',
            },
            {
              type: types.DOCUMENTS_FETCH_START,
              search,
              page,
            },
          ];
        })
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Löschen fehlgeschlagen',
        }));
    })
  );

export const onFetchDocumentHistory = (action$: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENT_HISTORY_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/document-history-list/${_id}`)
        .then((data) => [
          {
            type: types.DOCUMENT_HISTORY_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Fehler beim Abfragen der Historie',
        }));
    })
  );

export const onFetchDocumentHistoryEntry = (action$: any) =>
  pipeC(
    action$,
    ofType(types.DOCUMENT_HISTORY_ENTRY_FETCH_START),
    mapPromiseC(({ documentId, historyId }: any) => {
      return authFetchJson(`/document-history-entry/${documentId}/${historyId}`)
        .then((data) => [
          {
            type: types.DOCUMENT_HISTORY_ENTRY_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Fehler beim Abfragen des Historie Eintrags',
        }));
    })
  );
