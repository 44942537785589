export const Dashboard = ({}: any) => {
  return (
    <div>
      <h1>Dashboard</h1>
      <div>Hier findest du die Glaskugel des Servers.</div>
      <div>
        Jegliche Logs sind hier zu finden allerdings nur je nach Rechten
        einsehbar.
      </div>
      <br></br>
      <div>
        Es nicht nicht das Ziel wahllos nach anderen zu suchen. Auch nicht nach
        sich selber. Nur bei einem Supportfall sollen die Möglichkeiten genutzt
        werden.
      </div>
    </div>
  );
};
