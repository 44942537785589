import React from 'react';
import { useMotionValue } from 'framer-motion';
import apps from '../../configs/apps';
import DockItem from './DockItem';
import { useAuthentication } from '../../utils/authentication';

export default function Dock({
  open,
  showApps,
  showLaunchpad,
  toggleLaunchpad,
  hide,
}: any) {
  const [hasRight] = useAuthentication();
  const openApp = (id: string) => {
    if (id === 'launchpad') toggleLaunchpad(!showLaunchpad);
    else {
      toggleLaunchpad(false);
      open(id);
    }
  };

  const mouseX = useMotionValue(null);

  return (
    <div
      className="dock w-full sm:w-max fixed left-0 right-0 mx-auto bottom-0 overflow-x-scroll sm:overflow-x-visible"
      style={{
        zIndex: hide ? 0 : 99999,
      }}
    >
      <ul
        className="mx-auto w-max px-2 space-x-2 flex flex-row justify-center justify-between bg-white bg-opacity-20 blur rounded-none sm:rounded-t-lg shadow-2xl"
        onMouseMove={(e: any) => mouseX.set(e.nativeEvent.x)}
        onMouseLeave={() => mouseX.set(null)}
      >
        {apps(hasRight).map((app) => {
          const Cmp = app.customDockItemCmp || DockItem;
          return (
            <Cmp
              key={`dock-${app.id}`}
              id={app.id}
              title={app.title}
              icon={app.icon}
              bgColor={app.bgColor}
              mouseX={mouseX}
              desktop={app.desktop}
              openApp={openApp}
              isOpen={app.desktop && showApps[app.id]}
              link={app.link}
            />
          );
        })}
      </ul>
    </div>
  );
}
