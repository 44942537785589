import React, { useEffect } from 'react';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { startAdminApartmentFetch } from '../../../actions';
import { appsAdminApartmentDetailP } from '../../../redux/reducer/apps/admin/apartment';
import { ApartmentDetailType } from './types/apartment';
import { PlayerInventoryEntry } from './types/playerinventory';
import { translate } from '../../../utils/translate';

const subItemsStyle = css`
  padding-left: 10px;
`;

const renderItems = (items: PlayerInventoryEntry[]) => (
  <div>
    {items.map((entry) => (
      <div key={entry._id}>
        <div>
          {entry.amount}x {translate(entry.hash)}{' '}
          {entry.data?.label && '- ' + entry.data.label}
        </div>
        {entry.subitems && entry.subitems.length > 0 && (
          <div css={subItemsStyle}>{renderItems(entry.subitems)}</div>
        )}
      </div>
    ))}
  </div>
);

type ApartmentViewType = {
  _id: string;
  openPlayer: (_id: string, playerName: string) => void;
};
export const ApartmentView = ({ _id, openPlayer }: ApartmentViewType) => {
  const dispatch = useDispatch();
  const apartment = useSelector<any, null | ApartmentDetailType>(
    pathOr(null, appsAdminApartmentDetailP)
  );

  useEffect(() => {
    dispatch(startAdminApartmentFetch(_id));
  }, [_id]);

  if (!apartment) {
    return null;
  }

  return (
    <div>
      <h1>Apartment: {apartment.name}</h1>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Hash</TableCell>
            <TableCell>{apartment.hash}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Dimension</TableCell>
            <TableCell>{apartment.dimension}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tür</TableCell>
            <TableCell>{apartment.locked ? 'Geschlossen' : 'Offen'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Besitzer</TableCell>
            <TableCell
              onClick={() =>
                apartment.owner &&
                openPlayer(apartment.owner._id, apartment.owner.name)
              }
            >
              {apartment.owner?.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Interior</TableCell>
            <TableCell>{apartment.interior}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>Inventar</h2>
      {renderItems(apartment.items)}

      <h2>Platzierte Objekte</h2>
      {apartment.objects &&
        apartment.objects.map((object) => (
          <div key={object._id.toString()}>
            <h3>{object.label}</h3>
            {renderItems(object.items)}
          </div>
        ))}
    </div>
  );
};
