import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/EditRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import InputAdornment from '@mui/material/InputAdornment';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import SendIcon from '@mui/icons-material/SendRounded';
import { HideUnauthorized } from '../../../utils/authentication';
import { FractionMemberData } from './types';
import { inviteFractionEmployee } from '../../../actions/fraction';
import Headline from '../../Headline';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

const iconStyle = css`
  cursor: pointer;
`;

type MembersType = {
  fetchMembers: () => void;
  members: FractionMemberData[];
  changeRank: (name: string, rank: number) => void;
  fire: (name: string) => void;
};

export const Members = ({
  fetchMembers,
  members,
  changeRank,
  fire,
}: MembersType) => {
  const [newEmployee, setNewEmployee] = useState('');
  const [
    employeeInEdit,
    setEmployeeInEdit,
  ] = useState<FractionMemberData | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMembers();
  }, []);

  const invite = () => {
    dispatch(inviteFractionEmployee(newEmployee));
    setNewEmployee('');
  };

  return (
    <div>
      <Headline>Mitarbeiter</Headline>
      <HideUnauthorized needs={['team.management.invite']}>
        <>
          <TextField
            type="text"
            placeholder="Max_Mustermann"
            value={newEmployee}
            css={inputStyle}
            onChange={(ev) => setNewEmployee(ev.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonAddIcon />
                </InputAdornment>
              ),
              endAdornment: newEmployee !== '' && (
                <InputAdornment
                  position="end"
                  css={{ cursor: 'pointer' }}
                  onClick={invite}
                >
                  <SendIcon />
                </InputAdornment>
              ),
            }}
          />
        </>
      </HideUnauthorized>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Rang</TableCell>
            <HideUnauthorized needs={['team.management.changeRank']}>
              <TableCell>Verwalten</TableCell>
            </HideUnauthorized>
            <HideUnauthorized needs={['team.management.fire']}>
              <TableCell></TableCell>
            </HideUnauthorized>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) => (
            <TableRow key={member.name}>
              <TableCell>{member.name}</TableCell>
              <TableCell>
                {employeeInEdit && employeeInEdit.name === member.name ? (
                  <TextField
                    id="standard-basic"
                    label="Rang"
                    value={employeeInEdit.rank}
                    type="number"
                    onChange={(ev) =>
                      setEmployeeInEdit({
                        ...employeeInEdit,
                        rank: parseInt(ev.target.value),
                      })
                    }
                    error={employeeInEdit.rank < 0 || employeeInEdit.rank > 13}
                    helperText="Der Rank muss zwischen 0 und 13 sein"
                  />
                ) : (
                  member.rank
                )}
              </TableCell>
              <HideUnauthorized needs={['team.management.changeRank']}>
                <TableCell>
                  {employeeInEdit && employeeInEdit.name === member.name ? (
                    <>
                      <SaveIcon
                        css={iconStyle}
                        onClick={() => {
                          changeRank(employeeInEdit.name, employeeInEdit.rank);
                          setEmployeeInEdit(null);
                        }}
                      />
                      <ClearIcon
                        css={iconStyle}
                        onClick={() => setEmployeeInEdit(null)}
                      />
                    </>
                  ) : (
                    <EditIcon
                      css={iconStyle}
                      onClick={() => setEmployeeInEdit(member)}
                    />
                  )}
                </TableCell>
              </HideUnauthorized>
              <HideUnauthorized needs={['team.management.fire']}>
                <TableCell>
                  <Button
                    onClick={() => {
                      fire(member.name);
                      setEmployeeInEdit(null);
                    }}
                    startIcon={<ClearIcon />}
                  >
                    entlassen
                  </Button>
                </TableCell>
              </HideUnauthorized>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {employeeInEdit && (
        <>
          <Headline>Mitarbeiter verwalten</Headline>
          <TextField
            css={inputStyle}
            id="standard-basic"
            label="Rang"
            value={employeeInEdit.rank}
            type="number"
            onChange={(ev) =>
              setEmployeeInEdit({
                ...employeeInEdit,
                rank: parseInt(ev.target.value),
              })
            }
            error={employeeInEdit.rank < 0 || employeeInEdit.rank > 13}
            helperText="Der Rank muss zwischen 0 und 13 sein"
          />

          <HideUnauthorized needs={['team.management.changeRank']}>
            <Button
              onClick={() => {
                changeRank(employeeInEdit.name, employeeInEdit.rank);
                setEmployeeInEdit(null);
              }}
            >
              Bearbeitung speichern
            </Button>
          </HideUnauthorized>

          {/* <HideUnauthorized needs={['team.management.fire']}>
            <Button
              onClick={() => {
                fire(employeeInEdit.name);
                setEmployeeInEdit(null);
              }}
            >
              Mitarbeiter feuern
            </Button>
          </HideUnauthorized> */}

          {/* <Button onClick={() => setEmployeeInEdit(null)}>
            Bearbeitung abbrechen
          </Button> */}
        </>
      )}
    </div>
  );
};
