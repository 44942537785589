import React from 'react';
import { Tab as MUITab, Tabs as MUITabs } from '@mui/material';
import CloseIcon from '@mui/icons-material/CancelRounded';
import { css } from '@emotion/react';

const tabsStyle = css`
  min-height: unset !important;
  height: 3vh;
  .MuiTabs-indicator {
    background-color: transparent !important;
  }
`;

const tabStyle = (theme: any) => css`
  color: ${theme.colors.Black} !important;
  margin: 0.5vh 0 0 0.5vh !important;
  padding: 0 1.5vh !important;
  background-color: ${theme.colors.tailwind.gray['50']} !important;
  border-top-left-radius: 1vh !important;
  border-top-right-radius: 1vh !important;
  opacity: 1 !important;
  min-width: unset !important;
  min-height: unset !important;
  height: 3vh;
  line-height: 1 !important;
`;

const activeTabStyle = css`
  font-weight: bold !important;
`;

const tabIconStyle = css`
  height: 1.25vh !important;
  width: 1.25vh !important;
`;

type TabListType = {
  tabs: any;
  onTabClick: (index: number) => void;
  onTabClose?: (index: number) => void;
  activeTabIndex: number;
};

const TabList = ({
  tabs,
  onTabClick,
  onTabClose,
  activeTabIndex,
}: TabListType) => (
  <MUITabs
    css={tabsStyle}
    className={`bg-gray-100`}
    value={0}
    variant="scrollable"
    scrollButtons="auto"
  >
    {tabs.map((tab: any, index: number) => (
      <MUITab
        key={index}
        css={[tabStyle, index === activeTabIndex && activeTabStyle]}
        icon={
          onTabClose ? (
            <CloseIcon
              css={tabIconStyle}
              onClick={(ev) => {
                ev.stopPropagation();
                onTabClose(index);
              }}
            />
          ) : undefined
        }
        iconPosition="end"
        label={tab}
        onClick={() => onTabClick(index)}
      />
    ))}
  </MUITabs>
);

export default TabList;
