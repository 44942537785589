import { set, lensPath, pipe, pathOr, concat } from 'ramda';

import * as types from '../types';
import { screenshotL, screenshotLoadingL } from './screenshot';

export const postsP = ['leogram', 'posts'];
export const postsL = lensPath(postsP);
export const postTextP = ['leogram', 'newPostText'];
export const postTextL = lensPath(postTextP);
export const isPostsLoadingP = ['leogram', 'isPostsLoading'];
export const isPostsLoadingL = lensPath(isPostsLoadingP);

const postsFetchSuccess = (state: any, { posts }: any) => {
  return set(postsL, posts, state);
};
const postsFetchMoreSuccess = (state: any, { posts }: any) => {
  const currentPosts: any[] = pathOr([], postsP, state);
  return set(postsL, concat(currentPosts, posts), state);
};

const setPostText = (state: any, { text }: any) => {
  return set(postTextL, text, state);
};

const clearPostText = (state: any) => {
  return pipe(
    set(postTextL, ''),
    set(screenshotL, null),
    set(screenshotLoadingL, false)
  )(state);
};

const setPostLoading = (enabled: boolean) => (state: any) => {
  return set(isPostsLoadingL, enabled, state);
};

export const existingUsersP = ['leogram', 'existingUsers'];
export const existingUsersL = lensPath(existingUsersP);
export const checkedUsersP = ['leogram', 'checkedUsers'];
export const checkedUsersL = lensPath(checkedUsersP);
const setExistingUsers = (state: any, { data, hasBeenChecked }: any) => {
  const current = pathOr([], existingUsersP, state);
  const sum = concat(current, data);
  const currentChecked = pathOr([], checkedUsersP, state);
  const sumChecked = concat(currentChecked, hasBeenChecked);
  return pipe(set(existingUsersL, sum), set(checkedUsersL, sumChecked))(state);
};

export const postsReducerTree = {
  [types.LEOGRAM_POSTS_FETCH_SUCCESS]: [postsFetchSuccess],
  [types.LEOGRAM_POST_NEW_TEXT_SET]: [setPostText],
  [types.LEOGRAM_POST_CREATE_SUCCESS]: [clearPostText],
  [types.LEOGRAM_POSTS_MORE_FETCH_SUCCESS]: [
    postsFetchMoreSuccess,
    setPostLoading(false),
  ],
  [types.LEOGRAM_POSTS_MORE_FETCH_START]: [setPostLoading(true)],
  [types.LEOGRAM_POSTS_MORE_FETCH_FAILED]: [setPostLoading(false)],
  [types.LEOGRAM_POSTS_MORE_FETCH_SUCCESS]: [setExistingUsers],
};
