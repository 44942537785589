import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../utils/fetch';

export const onFetchFolders = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDERS_FETCH_START),
    mapPromiseC(({ group, page, search }: any) => {
      return authFetchJson(`/folders/${group}?page=${page}&search=${search}`)
        .then((data) => [
          {
            type: types.FOLDERS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onCreateSave = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_SAVE_START),
    mapPromiseC(({ folder }: any) => {
      if (folder.isNew) {
        delete folder._id;
        delete folder.isNew;
      }
      return authFetchPost('/folder/save', folder)
        .then((res: any) => res.json())
        .then((data: any) => [
          {
            type: types.FOLDER_SELECTION_SET,
            group: folder.group,
            folderId: data._id,
          },
          {
            type: types.FOLDERS_FETCH_START,
            group: folder.group,
            page: 1,
            search: '',
          },
          {
            type: types.FOLDER_FETCH_START,
            group: folder.group,
            folderId: data._id,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Akte gespeichert',
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Erstellen fehlgeschlagen',
        }));
    })
  );

export const onFetchFolder = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_FETCH_START),
    mapPromiseC(({ group, folderId }: any) => {
      return authFetchJson(`/folder/${group}/${folderId}`)
        .then((data) => [
          {
            type: types.FOLDER_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onStartAddDocumentToFolder = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_ADD_DOCUMENT_START),
    mapPromiseC(({ group, folderId, documentId }: any) => {
      return authFetch(
        `/folder/${group}/${folderId}/add-document/${documentId}`
      )
        .then((data) => [
          {
            type: types.FOLDER_FETCH_START,
            group,
            folderId,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Dokument hinzugefügt',
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Hinzufügen fehlgeschlagen',
        }));
    })
  );

export const onFetchFolderDocument = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_DOCUMENT_FETCH_START),
    mapPromiseC(({ documentId }: any) => {
      return authFetchJson(`/document/${documentId}`)
        .then((data) => [
          {
            type: types.FOLDER_DOCUMENT_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );
