import { toast } from 'react-toastify';
import * as types from '../redux/types';
import { pipeC, ofType, mapC } from '../utils/callbag';

export const onFetchBanksFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.NOTIFY_ERROR),
    mapC(({ msg }: any) => {
      toast.error(msg);
    })
  );
export const onFetchBanksSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.NOTIFY_SUCCESS),
    mapC(({ msg }: any) => {
      toast.success(msg);
    })
  );
export const onFetchBanksDefault = (action$: any) =>
  pipeC(
    action$,
    ofType(types.NOTIFY_DEFAULT),
    mapC(({ msg }: any) => {
      toast(msg);
    })
  );
