import { path, pipe } from 'ramda';
import { connect } from 'react-redux';
import {
  fetchPosts,
  startLike,
  startDislike,
  fetchMorePosts,
  startRouterGoto,
} from '../actions';
import { Dashboard } from '../components/Dashboard';
import { postsP, isPostsLoadingP } from '../store/reducer/posts';

const mapStateToProps = (state: any) => {
  return {
    posts: path(postsP, state),
    isLoading: path(isPostsLoadingP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchPosts: pipe(fetchPosts, dispatch),
  goto: pipe(startRouterGoto, dispatch),
  like: pipe(startLike, dispatch),
  dislike: pipe(startDislike, dispatch),
  fetchMorePosts: pipe(fetchMorePosts, dispatch),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
