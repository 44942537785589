import { defaultTo, find, pipe, propEq } from 'ramda';

export const BUSINESS_CARD = {
  type: 'businessCard',
  label: 'Visitenkarte',
  height: '30vh',
  width: '52.5vh',
};

export const DOCUMENT_A5 = {
  type: 'documentA5',
  label: 'A5 Dokument',
  height: '40vh',
  width: '25vh',
};

export const DOCUMENT_A4 = {
  type: 'documentA4',
  label: 'A4 Dokument',
  height: '80vh',
  width: '50vh',
};

export const DOCUMENTS = [BUSINESS_CARD, DOCUMENT_A5, DOCUMENT_A4];

export const getObjectByType = (type: any, template = null) => {
  const foundDocument = pipe<any, any, any>(
    find(propEq('type', type)),
    defaultTo({})
  )(DOCUMENTS);
  return {
    ...foundDocument,
    template,
  };
};

export default [BUSINESS_CARD, DOCUMENT_A5, DOCUMENT_A4];
