import { path, pipe } from 'ramda';
import { connect } from 'react-redux';
import { startRegister, startRouterGoto } from '../actions';
import { Register } from '../components/Register';
import { registrationFailedP } from '../store/reducer/register';

const mapStateToProps = (state: any) => {
  return {
    failedText: path(registrationFailedP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  // @ts-ignore
  register: pipe(startRegister, dispatch),
  goto: pipe(startRouterGoto, dispatch),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Register);
