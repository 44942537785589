import { css } from '@emotion/react';
import { find, pathEq, pathOr, pipe } from 'ramda';

const exteriorColors = require('../../../resources/stores/mechanic/exterieurcolors');

const vehicleColorContainer = css`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const vehicleColor = (color: string) => css`
  width: 1.5vh;
  height: 1.5vh;
  display: inline-block;
  background-color: ${color};
  border-radius: 50%;
  margin: 0vh 0.3vh;
`;

export const renderVehColor = (veh: any) => {
  const firstColorValue = veh.color.first ?? 0;
  const firstColor =
    typeof firstColorValue === 'object'
      ? `rgb(${firstColorValue.r}, ${firstColorValue.g}, ${firstColorValue.b})`
      : pipe(
          (x: number) =>
            find(pathEq(['value'], x), exteriorColors.specialColors),
          pathOr('#000', ['hex'])
        )(firstColorValue);

  const secondColorValue = veh.color.second ?? 0;
  const secondColor =
    typeof secondColorValue === 'object'
      ? `rgb(${secondColorValue.r}, ${secondColorValue.g}, ${secondColorValue.b})`
      : pipe(
          (x: number) =>
            find(pathEq(['value'], x), exteriorColors.specialColors),
          pathOr('#000', ['hex'])
        )(secondColorValue);

  return (
    <div css={vehicleColorContainer}>
      <div css={() => vehicleColor(firstColor)} />
      <div css={() => vehicleColor(secondColor)} />
    </div>
  );
};
