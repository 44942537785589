import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { photosP } from '../store/reducer/photo';
import { startFetchPhotos } from '../actions';
import RefreshIcon from '@mui/icons-material/Refresh';

const galleryContainerStyle = (theme: any, columns: number) => {
  const gridColumns = `repeat(${columns}, ${100 / columns}%)`;
  return css`
    display: grid;
    grid-template-columns: ${gridColumns};
    grid-gap: 0.5vh;
    grid-auto-rows: auto;
    flex-grow: inherit !important;
    overflow-x: hidden;
    overflow-y: scroll;
    height: inherit;
    align-content: start;

    ::-webkit-scrollbar {
      width: 0.3vh;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      transition: all 0.5s ease;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.White};
      transition: all 0.5s ease;
    }
  `;
};

const photoContainerStyle = (theme: any) => css`
  display: flex;
  border-radius: 0.25vh;
  overflow: hidden;
  height: max-content;

  img {
    width: 100%;
    height: auto;
    :hover {
      opacity: 0.75;
    }
  }
`;

const loadMoreStyle = css`
  margin: 0.5vh 0;
  width: max-content !important;
  height: fit-content;
`;

// const demoData = [
//   {
//     date: '2021-11-07T10:50:29.573Z',
//     url: 'https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg',
//     thumb:
//       'https://thumb.gyazo.com/thumb/200/eyJhbGciOiJIUzI1NiJ9.eyJpbWciOiJfODBjZTgwOTZkZmQ1NzVhYTlkYTE4ODc5OTg3YjU0ZGYifQ.6p9UZATkJB5p8VxCpsps7WA5Kfq73fq39ukr55k3m6c-jpg.jpg',
//   },
//   {
//     date: '2021-11-07T10:50:29.573Z',
//     url: 'https://i.gyazo.com/7b41a636e0ff9a8f78d5215c7723e4f8.jpg',
//     thumb:
//       'https://thumb.gyazo.com/thumb/200/eyJhbGciOiJIUzI1NiJ9.eyJpbWciOiJfMDcxYWU1Y2NiMmQ1MjkwYzcwNmY3NzNhOGU4NmQxNGMifQ.oddbMNm9Zg5YFDPJpIUp8cSYsCpdi3lN-Rw_FJD3ZZo-jpg.jpg',
//   },
//   {
//     date: '2021-11-07T10:50:29.573Z',
//     url: 'https://i.gyazo.com/0e5ea7b11873648105a16a4bc5eef16e.jpg',
//     thumb:
//       'https://thumb.gyazo.com/thumb/200/eyJhbGciOiJIUzI1NiJ9.eyJpbWciOiJfYThkN2FhMmI0MTY4ZDYyM2QwMThlYzBkZDRjNmEyYTMifQ.RtGtyi9Ad7uymcNbirmnVvd1ubTY5R3VKhajkJkcvlU-jpg.jpg',
//   },
// ];

type GalleryType = {
  onImgClick: (img: any) => void;
  additionalStyles?: any;
  EmptyInfo: any;
  columns?: number;
};

const Gallery = ({
  onImgClick,
  additionalStyles,
  EmptyInfo,
  columns = 3,
}: GalleryType) => {
  const dispatch = useDispatch();
  const photos = useSelector<any, any[]>(pathOr([], photosP));
  // const photos = demoData;

  useEffect(() => {
    dispatch(startFetchPhotos(0));
  }, []);

  const loadMore = () => {
    dispatch(startFetchPhotos(photos.length));
  };

  return photos?.length > 0 ? (
    <div
      css={[(theme) => galleryContainerStyle(theme, columns), additionalStyles]}
    >
      {photos.map((entry) => (
        <div css={photoContainerStyle}>
          <img
            key={entry.url}
            src={entry.url}
            loading="lazy"
            onClick={() => onImgClick(entry)}
            css={[{ cursor: 'pointer' }]}
          />
        </div>
      ))}
      {photos.length >= 10 && (
        <Button
          startIcon={<RefreshIcon />}
          onClick={loadMore}
          variant="outlined"
          css={loadMoreStyle}
        >
          Mehr
        </Button>
      )}
    </div>
  ) : (
    <EmptyInfo />
  );
};

export default Gallery;
