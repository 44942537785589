export const FINANCE_BANKS_FETCH_START = 'FINANCE_BANKS_FETCH_START';
export const FINANCE_BANKS_FETCH_SUCCESS = 'FINANCE_BANKS_FETCH_SUCCESS';
export const FINANCE_BANKS_FETCH_FAILED = 'FINANCE_BANKS_FETCH_FAILED';

export const FINANCE_BANK_ACCOUNT_CREATE_START =
  'FINANCE_BANK_ACCOUNT_CREATE_START';

export const FINANCE_BANK_ACCOUNTS_FETCH_START =
  'FINANCE_BANK_ACCOUNTS_FETCH_START';
export const FINANCE_BANK_ACCOUNTS_FETCH_SUCCESS =
  'FINANCE_BANK_ACCOUNTS_FETCH_SUCCESS';
export const FINANCE_BANK_ACCOUNTS_FETCH_FAILED =
  'FINANCE_BANK_ACCOUNTS_FETCH_FAILED';

export const FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_START =
  'FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_START';
export const FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_SUCCESS =
  'FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_SUCCESS';
export const FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_FAILED =
  'FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_FAILED';

export const FINANCE_BANK_ACCOUNT_PIN_CHANGE_START =
  'FINANCE_BANK_ACCOUNT_PIN_CHANGE_START';
export const FINANCE_BANK_ACCOUNT_PIN_CHANGE_SUCCESS =
  'FINANCE_BANK_ACCOUNT_PIN_CHANGE_SUCCESS';
export const FINANCE_BANK_ACCOUNT_PIN_CHANGE_FAILED =
  'FINANCE_BANK_ACCOUNT_PIN_CHANGE_FAILED';

export const FINANCE_BANK_ACCOUNT_MAIN_SET_START =
  'FINANCE_BANK_ACCOUNT_MAIN_SET_START';
export const FINANCE_BANK_ACCOUNT_MAIN_SET_SUCCESS =
  'FINANCE_BANK_ACCOUNT_MAIN_SET_SUCCESS';
export const FINANCE_BANK_ACCOUNT_MAIN_SET_FAILED =
  'FINANCE_BANK_ACCOUNT_MAIN_SET_FAILED';

export const FINANCE_BANK_ACCOUNT_DELETE_START =
  'FINANCE_BANK_ACCOUNT_DELETE_START';
export const FINANCE_BANK_ACCOUNT_DELETE_SUCCESS =
  'FINANCE_BANK_ACCOUNT_DELETE_SUCCESS';
export const FINANCE_BANK_ACCOUNT_DELETE_FAILED =
  'FINANCE_BANK_ACCOUNT_DELETE_FAILED';

export const FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_START =
  'FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_START';
export const FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_SUCCESS =
  'FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_SUCCESS';
export const FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_FAILED =
  'FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_FAILED';

export const FINANCE_BANK_ACCOUNT_ACCESS_GET_START =
  'FINANCE_BANK_ACCOUNT_ACCESS_GET_START';
export const FINANCE_BANK_ACCOUNT_ACCESS_GET_SUCCESS =
  'FINANCE_BANK_ACCOUNT_ACCESS_GET_SUCCESS';
export const FINANCE_BANK_ACCOUNT_ACCESS_GET_FAILED =
  'FINANCE_BANK_ACCOUNT_ACCESS_GET_FAILED';

export const FINANCE_BANK_ACCOUNT_ACCESS_ADD_START =
  'FINANCE_BANK_ACCOUNT_ACCESS_ADD_START';
export const FINANCE_BANK_ACCOUNT_ACCESS_ADD_SUCCESS =
  'FINANCE_BANK_ACCOUNT_ACCESS_ADD_SUCCESS';
export const FINANCE_BANK_ACCOUNT_ACCESS_ADD_FAILED =
  'FINANCE_BANK_ACCOUNT_ACCESS_ADD_FAILED';

export const FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_START =
  'FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_START';
export const FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_SUCCESS =
  'FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_SUCCESS';
export const FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_FAILED =
  'FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_FAILED';

export const FINANCE_BANK_ACCOUNT_ACCESS_EDIT_START =
  'FINANCE_BANK_ACCOUNT_ACCESS_EDIT_START';
export const FINANCE_BANK_ACCOUNT_ACCESS_EDIT_SUCCESS =
  'FINANCE_BANK_ACCOUNT_ACCESS_EDIT_SUCCESS';
export const FINANCE_BANK_ACCOUNT_ACCESS_EDIT_FAILED =
  'FINANCE_BANK_ACCOUNT_ACCESS_EDIT_FAILED';

//   export const FINANCE_BANK_ACCOUNT_FETCH_START =
//   'FINANCE_BANK_ACCOUNT_FETCH_START';
// export const FINANCE_BANK_ACCOUNT_FETCH_SUCCESS =
//   'FINANCE_BANK_ACCOUNT_FETCH_SUCCESS';
// export const FINANCE_BANK_ACCOUNT_FETCH_FAILED =
//   'FINANCE_BANK_ACCOUNT_FETCH_FAILED';

// export const FINANCE_BANK_FETCH_START = 'FINANCE_BANK_FETCH_START';
// export const FINANCE_BANK_FETCH_SUCCESS = 'FINANCE_BANK_FETCH_SUCCESS';
// export const FINANCE_BANK_FETCH_FAILED = 'FINANCE_BANK_FETCH_FAILED';

// export const FINANCE_BANK_ACCOUNTS_BUSINESS_FETCH_START =
//   'FINANCE_BANK_ACCOUNTS_BUSINESS_FETCH_START';
// export const FINANCE_BANK_ACCOUNTS_BUSINESS_FETCH_SUCCESS =
//   'FINANCE_BANK_ACCOUNTS_BUSINESS_FETCH_SUCCESS';
// export const FINANCE_BANK_ACCOUNTS_BUSINESS_FETCH_FAILED =
//   'FINANCE_BANK_ACCOUNTS_BUSINESS_FETCH_FAILED';

// export const FINANCE_BANK_ACCOUNT_CREATE_START =
//   'FINANCE_BANK_ACCOUNT_CREATE_START';
// export const FINANCE_BANK_ACCOUNT_CREATE_SUCCESS =
//   'FINANCE_BANK_ACCOUNT_CREATE_SUCCESS';
// export const FINANCE_BANK_ACCOUNT_CREATE_FAILED =
//   'FINANCE_BANK_ACCOUNT_CREATE_FAILED';

// export const FINANCE_BANK_ACCOUNTS_PRIVATE_MAX_START =
//   'FINANCE_BANK_ACCOUNTS_PRIVATE_MAX_START';
// export const FINANCE_BANK_ACCOUNTS_PRIVATE_MAX_SUCCESS =
//   'FINANCE_BANK_ACCOUNTS_PRIVATE_MAX_SUCCESS';
// export const FINANCE_BANK_ACCOUNTS_PRIVATE_MAX_FAILED =
//   'FINANCE_BANK_ACCOUNTS_PRIVATE_MAX_FAILED';
