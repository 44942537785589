import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/EditRounded';
import CreditCardIcon from '@mui/icons-material/CreditCardRounded';
import AccessIcon from '@mui/icons-material/VpnKeyRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import StarIcon from '@mui/icons-material/StarOutlineRounded';
import { AccountAccess } from './AccountAccess';
import { AccountCard } from './AccountCard';
import { ChangePin } from './ChangePin';
import { DeleteAccount } from './DeleteAccounts';
import { MakeMainAccount } from './MakeMainAccount';
import { Transactions } from './Transactions';
import {
  AccountAccessData,
  AccountTransactionData,
  BankAccount,
} from './types';

const buttonGroupContainer = css`
  max-width: 35vh;
  display: inline-block;
  margin: 2vh 0;
`;

const buttonGroup = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const buttonStyle = css`
  margin: 0.25vh 0 !important;
`;

const actionContainer = css`
  padding: 1.5vh 0;
`;

enum ESubAction {
  PinChange = 'pinChange',
  MakeMainAccount = 'mainAccount',
  Access = 'access',
  Delete = 'delete',
}

type AccountType = {
  account: BankAccount;
  fetchTransactions: (vban: string, offset: number) => void;
  transactions: AccountTransactionData | undefined;
  changePin: (
    vban: string,
    currentPin: string,
    newPin: string,
    newPinRe: string
  ) => void;
  makeMain: (vban: string, pin: string) => void;
  deleteAccount: (vban: string, pin: string) => void;
  startTransfer: (vban: string) => void;
  fetchAccess: (vban: string) => void;
  addAccess: (vban: string, playerName: string) => void;
  access: AccountAccessData[];
  rmAccess: (vban: string, _id: string) => void;
  editAccess: (
    vban: string,
    _id: string,
    accessRightType: string,
    newValue: boolean
  ) => void;
};

export const Account = ({
  account,
  fetchTransactions,
  transactions,
  changePin,
  makeMain,
  deleteAccount,
  startTransfer,
  fetchAccess,
  addAccess,
  access = [],
  rmAccess,
  editAccess,
}: AccountType) => {
  const [activeSubAction, setActiveSubAction] = useState<ESubAction | null>(
    null
  );

  const closeSubAction = () => {
    setActiveSubAction(null);
  };

  useEffect(() => {
    setActiveSubAction(null);
    fetchTransactions(account.vban, 0);
    if (!account.main) {
      fetchAccess(account.vban);
    }
  }, [account]);

  return (
    <div>
      <AccountCard account={account} />
      <div css={buttonGroupContainer}>
        <div css={buttonGroup}>
          <Button
            css={buttonStyle}
            onClick={() => startTransfer(account.vban)}
            startIcon={<CreditCardIcon />}
            fullWidth
            variant="outlined"
          >
            Überweisung tätigen
          </Button>
          <Button
            css={buttonStyle}
            onClick={() => setActiveSubAction(ESubAction.PinChange)}
            startIcon={<EditIcon />}
            fullWidth
            variant="outlined"
          >
            PIN ändern
          </Button>
          {!account.main && !account.sharedAccess && (
            <Button
              css={buttonStyle}
              onClick={() => setActiveSubAction(ESubAction.MakeMainAccount)}
              startIcon={<StarIcon />}
              fullWidth
              variant="outlined"
            >
              Zum Hauptkonto machen
            </Button>
          )}
          {!account.main && (
            <Button
              css={buttonStyle}
              onClick={() => setActiveSubAction(ESubAction.Access)}
              startIcon={<AccessIcon />}
              fullWidth
              variant="outlined"
            >
              Kontozugriff
            </Button>
          )}
          {!account.main && !account.sharedAccess && (
            <Button
              css={buttonStyle}
              onClick={() => setActiveSubAction(ESubAction.Delete)}
              startIcon={<DeleteIcon />}
              fullWidth
              variant="outlined"
            >
              Konto löschen
            </Button>
          )}
        </div>
      </div>

      <div css={actionContainer}>
        {activeSubAction === ESubAction.PinChange && (
          <ChangePin
            vban={account.vban}
            close={closeSubAction}
            changePin={changePin}
          />
        )}
        {activeSubAction === ESubAction.MakeMainAccount && (
          <MakeMainAccount
            vban={account.vban}
            close={closeSubAction}
            makeMain={makeMain}
          />
        )}
        {activeSubAction === ESubAction.Delete && (
          <DeleteAccount
            vban={account.vban}
            close={closeSubAction}
            deleteAccount={deleteAccount}
          />
        )}
        {activeSubAction === ESubAction.Access && (
          <AccountAccess
            close={closeSubAction}
            account={account}
            access={access}
            editAccess={editAccess}
            rmAccess={rmAccess}
            addAccess={addAccess}
          />
        )}
      </div>

      <Transactions
        vban={account.vban}
        transactions={transactions}
        fetchTransactions={fetchTransactions}
      />
    </div>
  );
};
