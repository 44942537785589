import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC } from '../utils/callbag';
import { authFetch } from '../utils/fetch';
import { authFetchPost } from '../utils/fetch';

export const onCommentsFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMMENTS_FETCH_START),
    mapPromiseC(({ post, offset }: any) => {
      return authFetch(`/leogram/comments/${post}?skip=${offset}`)
        .then((res) => {
          return res.json().then((data) => ({
            type: types.COMMENTS_FETCH_SUCCESS,
            data,
            offset,
          }));
        })
        .catch((res) => {});
    })
  );

export const onCommentCreateStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMMENT_CREATE_START),
    mapPromiseC(({ post, text }: any) => {
      return authFetchPost('/leogram/comment', { post, text })
        .then(() => ({
          type: types.COMMENTS_FETCH_START,
          post,
          offset: 0,
        }))
        .catch(() => ({
          type: 'NOTIFY_ERROR',
          msg: 'Fehler beim Erstellen des Kommentars',
        }));
    })
  );

export const onCommentLikeToggleStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.COMMENT_LIKE_TOGGLE_START),
    mapPromiseC(({ comment }: any) => {
      return authFetch(`/leogram/comment/like/${comment}`)
        .then(() => ({
          type: types.COMMENT_LIKE_TOGGLE_SUCCESS,
          comment,
        }))
        .catch(() => ({
          type: 'NOTIFY_ERROR',
          msg: 'Fehler beim Like/Unlike des Kommentars',
        }));
    })
  );
