export const LEOGRAM_PROFILE_PAGE_FETCH_START =
  'LEOGRAM_PROFILE_PAGE_FETCH_START';
export const LEOGRAM_PROFILE_PAGE_FETCH_SUCCESS =
  'LEOGRAM_PROFILE_PAGE_FETCH_SUCCESS';
export const LEOGRAM_PROFILE_PAGE_FETCH_FAILED =
  'LEOGRAM_PROFILE_PAGE_FETCH_FAILED';

export const LEOGRAM_PROFILE_FOLLOW_START = 'LEOGRAM_PROFILE_FOLLOW_START';
export const LEOGRAM_PROFILE_FOLLOW_SUCCESS = 'LEOGRAM_PROFILE_FOLLOW_SUCCESS';
export const LEOGRAM_PROFILE_FOLLOW_FAILED = 'LEOGRAM_PROFILE_FOLLOW_FAILED';

export const LEOGRAM_PROFILE_UNFOLLOW_START = 'LEOGRAM_PROFILE_UNFOLLOW_START';
export const LEOGRAM_PROFILE_UNFOLLOW_SUCCESS =
  'LEOGRAM_PROFILE_UNFOLLOW_SUCCESS';
export const LEOGRAM_PROFILE_UNFOLLOW_FAILED =
  'LEOGRAM_PROFILE_UNFOLLOW_FAILED';

export const LEOGRAM_PROFILE_PAGE_MORE_FETCH_START =
  'LEOGRAM_PROFILE_PAGE_MORE_FETCH_START';
export const LEOGRAM_PROFILE_PAGE_MORE_FETCH_SUCCESS =
  'LEOGRAM_PROFILE_PAGE_MORE_FETCH_SUCCESS';
export const LEOGRAM_PROFILE_PAGE_MORE_FETCH_FAILED =
  'LEOGRAM_PROFILE_PAGE_MORE_FETCH_FAILED';
