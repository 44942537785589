import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startAdminPlayerTelNumbersFetch } from '../../../../actions';
import {
  appsAdminPlayerTelNumbersCompletedP,
  appsAdminPlayerTelNumbersP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { PlayerTelNumbers } from '../types/playertelnumbers';
import { detailContainerStyle } from '../styles';

type TelNumbersType = {
  _id: string;
};
export const TelNumbers = ({ _id }: TelNumbersType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerTelNumbers[]>(
    pathOr([], appsAdminPlayerTelNumbersP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerTelNumbersCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerTelNumbersFetch(_id, offset));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Telefonnummern</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nummer</TableCell>
              <TableCell>Benutzer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell>{entry.number}</TableCell>
                <TableCell>
                  {entry.users.map((user) => user.name).join(', ')}
                </TableCell>
              </TableRow>
            ))}
            {!reachedEndOfList && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="outlined"
                    onClick={() => onLoad(list.length)}
                  >
                    Weitere Laden
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
