import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { useDispatch, useSelector } from 'react-redux';
import { startAdminLiveStatsFetch } from '../../../actions';
import { appsAdminLiveStatsP } from '../../../redux/reducer/apps/admin/livestats';
import { LiveStatsDataType } from './types/livestats';
import CachedIcon from '@mui/icons-material/Cached';
import { GtaMap } from '../../shared/GtaMap';

const statsDefault = {
  vehicles: [],
  players: [],
};

type LiveStatsType = {
  openPlayer: (_id: string, playerName: string) => void;
};
export const LiveStats = ({ openPlayer }: LiveStatsType) => {
  const dispatch = useDispatch();
  const data = useSelector<any, LiveStatsDataType>(
    pathOr(statsDefault, appsAdminLiveStatsP)
  );

  useEffect(() => {
    dispatch(startAdminLiveStatsFetch());
  }, []);

  const refresh = () => {
    dispatch(startAdminLiveStatsFetch());
  };

  const markers = [
    ...data.vehicles
      .filter((x) => x.dimension === 0 && x.position)
      .map((x) => ({
        x: x.position.x,
        y: x.position.y,
        title: x.name + ' - ' + x.numberPlate,
        id: x._id,
        icon: '/img/map/icons/policecar.png',
      })),
    ...data.players
      .filter((x) => x.dimension === 0 && x.position)
      .map((x) => ({
        x: x.position.x,
        y: x.position.y,
        title: x.name,
        id: x._id,
        icon: '/img/map/icons/emergency.png',
      })),
  ];

  return (
    <>
      <Button variant="outlined" onClick={refresh}>
        Aktualisieren <CachedIcon />
      </Button>

      <h2>Fahrzeuganzahl: {data.vehicles.length}</h2>
      <h2>Spieler: {data.players.length}</h2>

      <div className="w-full h-full">
        <GtaMap markers={markers} />
      </div>

      <Table>
        <TableBody>
          {data.players.map((player, index) => (
            <TableRow key={player._id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell
                onClick={() => openPlayer(player._id, player.name)}
                css={{ cursor: 'pointer' }}
              >
                {player.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
