import { css } from '@emotion/react';
import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { startCreateBankAccount } from '../../../actions';

const containerStyle = css`
  margin-top: 20px;
`;
const btnContainerStyle = css`
  margin-top: 20px;
`;

type CreateType = {
  bankHash: string;
  data:
    | {
        name: string;
        type: string;
      }
    | undefined;
  abort: () => void;
};
export const BankCreateAccount = ({ bankHash, data, abort }: CreateType) => {
  const [note, setNote] = useState('');
  const [pin, setPin] = useState('');
  const [rePin, setRePin] = useState('');
  const dispatch = useDispatch();

  if (!data) {
    return null;
  }

  // @ts-ignore
  const isPinValid = pin.length === 4 && !isNaN(pin);
  const isRePinValid = pin === rePin;

  const create = () => {
    if (isPinValid && isRePinValid) {
      dispatch(startCreateBankAccount(bankHash, data.name, note, pin));
      abort();
    }
  };

  return (
    <div css={containerStyle}>
      <h1>{data.name} erstellen</h1>

      <div>
        <TextField
          label="Notiz"
          type="text"
          value={note}
          onChange={(ev) => setNote(ev.target.value)}
        />
      </div>
      <div>
        <TextField
          label="PIN (4 Zahlen)"
          type="password"
          value={pin}
          onChange={(ev) => setPin(ev.target.value)}
          error={!isPinValid}
        />
      </div>
      <div>
        <TextField
          label="PIN verifizieren"
          type="password"
          value={rePin}
          onChange={(ev) => setRePin(ev.target.value)}
          error={!isRePinValid}
        />
      </div>

      <div css={btnContainerStyle}>
        <Button variant="outlined" onClick={create}>
          Eröffnen
        </Button>
        <Button variant="outlined" onClick={abort}>
          Schliessen
        </Button>
      </div>
    </div>
  );
};
