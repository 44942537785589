import React from 'react';

const html = `
<p><span style="font-size: 14pt;"><strong>
		</strong><span style="color:rgb(255,255,255);"><strong>§ 1 Geltungsbereich</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Die Strafprozessordnung gilt im gesamten Staat San Andreas.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 2 Vernehmung</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Vor einer Vernehmung durch die Justiz wird ein Zeuge zur Wahrheit ermahnt und über die Folgen einer falschen oder unvollständigen Aussage belehrt. </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. Ein Zeuge hat auf die Vorladung eines Richters zu erscheinen. Die Zeugen haben eine Pflicht auszusagen, sofern durch das Gesetz keine Ausnahme geregelt ist.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">III. Zeugen, welche zum Termin nicht erscheinen können, haben die Möglichkeit, über die Staatsanwaltschaft eine Aussage schriftlich einzureichen. </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Zeugen müssen spätestens zwei Stunden vor der Verhandlung ihr Nichterscheinen der Staatsanwaltschaft mitteilen.<br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 3 Strafanzeige</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Eine Strafanzeige kann beim LSPD oder bei der Staatsanwaltschaft eingereicht werden.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. Für zivilrechtliche Angelegenheiten hat der Bürger sich stets an einen Anwalt zu wenden, dieser wendet sich dann an einen Richter.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 4 Untersuchungshaft</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Untersuchungshaft darf nur bei dringendem Tatverdacht durch einen Staatsanwalt oder Richter angeordnet werden. Eine Haftbefehl ist nur nötig, wenn die Zeit der Untersuchungshaft zwei Stunden oder die maximale Haftzeit für die beschuldigten Vergehen überschreitet.</span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. Bei Gefahr im Verzug ist in Ausnahmefällen das LSPD berechtigt, eine Präventivhaft von maximal zwei Stunden anzuordnen, muss diese aber im Nachhinein nachvollziehbar rechtfertigen können. Sollte die Zeit Überschritten werden, ist ein Haftbefehl nötig.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">III. Die Zeit, die eine Person in Untersuchungshaft verbringt, muss nicht zwangsweise an das endgültige Strafmaß angerechnet werden. </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 5 Durchsuchung</strong> </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Eine Durchsuchung muss von einem Richter schriftlich bestätigt oder in Auftrag gegeben werden oder der Richter muss persönlich vor Ort sein. Bei Gefahr im Verzug ist es einem Staatsanwalt oder der Leitung des LSPD gestattet, eine Durchsuchung anzuordnen, auch wenn kein Richter verfügbar ist. Dies muss im Nachhinein nachvollziehbar sein.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. Bei einer Durchsuchung dürfen nur die im Durchsuchungsbefehl oder die durch den Richter angeordneten Orte durchsucht werden, sofern diese der betroffenen Person zur Verfügung stehen oder dieser Person gehören.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Bei Verdacht oder Hinweisen auf Straftaten darf das LSPD Personen und Fahrzeuge ohne richterliche Genehmigung durchsuchen, muss dies aber im Nachhinein nachvollziehbar rechtfertigen können.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 6 Verteidigerrecht</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Die beschuldigte Person kann sich bei jeder Lage im Gerichtsverfahren einen Verteidiger hinzuziehen, dabei dürfen es nicht mehr als zwei Verteidiger sein.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 7 Gerichtsverfahren</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Die Entscheidung des Gerichts fällt im Laufe der Hauptverhandlung nach Anhörung aller Beteiligten. </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. Die Entscheidung, die außerhalb einer Gerichtsverhandlung entsteht, wird nach schriftlicher oder mündlicher Erklärung der Staatsanwaltschaft erwirkt.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 8 Revision</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Ein Antrag auf Revision muss spätestens drei Tage nach der Verkündung des Urteils bei der Verwaltung des DoJ schriftlich eingereicht werden. </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. In dem Revisionsantrag muss eine Begründung mit Fallnummer stehen. Der Antragsteller muss ein eingetragener freier Anwalt, Pflichtverteidiger, Staatsanwalt oder Richter sein.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Der Revisionsbetroffene muss im Antrag durch einen Anwalt namentlich genannt werden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 9 Entschädigung bei Unschuld</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Wird ein Beschuldigter aufgrund mangelnder Beweise freigelassen oder vom Gericht freigesprochen, kann der Richter entscheiden, ob der Beschuldigte für die folgenden Punkte entschädigt wird.</span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;">II. Ein Umwandlungssatz entspricht $20 pro Hafteinheit.</span></p>
	<p><span style="font-size: 14pt;">III. Untersuchungshaft, die durch einen Richter angeordnet wurde: Einfacher Umwandlungssatz.</span></p>
	<p><span style="font-size: 14pt;">IV. Nicht nachvollziehbare Untersuchungshaft, die durch einen Staatsanwalt oder das LSPD angeordnet wurde: Doppelter Umwandlungssatz.</span></p>
	<ul><li><span style="font-size: 14pt;">Bei Nachvollziehbarkeit gilt § 9 Abs. III APO.</span></li>
		<li><span style="font-size: 14pt;">Die Nachvollziehbarkeit muss durch den Staatsanwalt oder Officer nach dem Freispruch, jedoch direkt im Anschluss der Verhandlung, dem Richter erläutert werden.</span></li>
		<li><span style="font-size: 14pt;">Über die Nachvollziehbarkeit entscheidet der Richter.</span></li>
	</ul><p><span style="font-size: 14pt;">V. Eine Fußfessel: $2000 Entschädigung pro Tag.</span></p>
	<p><span style="font-size: 14pt;"><br></span></p>
	<p><span style="font-size: 14pt;"><strong>§ 10 Deal mit der Staatsanwaltschaft</strong></span></p>
	<p><span style="font-size: 14pt;">I. Die Staatsanwaltschaft ist berechtigt, einen Deal mit einem Beschuldigten einzugehen.</span></p>
	<p><span style="font-size: 14pt;">II. Für diesen Deal muss mindestens ein Zeuge anwesend sein.Dies kann ein LSPD Officer oder ein weiterer Staatsanwalt sein.</span></p>
	<p><span style="font-size: 14pt;">III. Der Deal kann eine Geldstrafe sowie eine Haftstrafe beinhalten. Bei Verfügbarkeit eines Richters ist die Höhe der Strafe mit diesem abzusprechen.</span></p>
	<p><span style="font-size: 14pt;">IV. Voraussetzung für einen Deal ist die Geständigkeit, Reue, Unrechtsbewusstsein oder verwertbare Informationen für Ermittlungen durch den Beschuldigten.</span></p>
	<p><span style="font-size: 14pt;">V. Die im Deal abgehandelte Straftat darf nicht weiter verfolgt werden. Die Geldstrafe sowie die Haftstrafe sind sofort zu vollstrecken.</span></p>
	<p><span style="font-size: 14pt;">VI. Der Beschuldigte bekommt ein $1 Ticket. Inhalt des Tickets ist: § 10 Deal mit der Staatsanwaltschaft, Straftaten, Strafmaß, zuständiger Staatsanwalt sowie Zeuge, Tatort und Datum. Geldstrafen sind per Ticket, ausgestellt durch das DoJ, zu bezahlen.</span></p>
	<p><span style="font-size: 14pt;">VII. Ein Deal darf nur eingegangen werden wenn das öffentliche Interesse an der Strafverfolgung nicht zu erwarten ist.</span></p>
	<p><span style="font-size: 14pt;">VIII. Das Rechtsmittel der Revision entfällt.</span></p>
	<p><span style="font-size: 14pt;"><br></span></p>
	<p><span style="font-size: 14pt;"><strong>§ 11 Sofortverfahren</strong></span></p>
	<p><span style="font-size: 14pt;">I. Sollte das öffentliche Interesse an der Strafverfolgung nicht zu erwarten sein, jedoch der Beschuldigte nicht geständig, aber die Beweislast erdrückend, kann ein Richter hinzugezogen werden, um ein Sofortverfahren durchzuführen.</span></p>
	<p><span style="font-size: 14pt;">II. Voraussetzungen für das Sofortverfahren sind die in § 11 Abs. 1 APO genannten Punkte. Außerdem müssen das Opfer und alle Zeugen anwesend sein (falls vorhanden) und jeder Zeuge muss den Willen zu einer Aussage.</span></p>
	<p><span style="font-size: 14pt;">III. Der Richter entscheidet nach Anhörung des Beschuldigten sowie den anderen Beteiligten über die Höhe der Strafe.</span></p>
	<p><span style="font-size: 14pt;">IV. Die Strafe ist direkt abzuleisten und zu zahlen.</span></p>
	<p><span style="font-size: 14pt;">V. Das Rechtsmittel der Revision entfällt.</span></p>
	
	<p><span style="font-size: 14pt;"><br></span></p>
	<p><span style="font-size: 14pt;"><strong>§ 12 Urteil</strong></span></p>
	<p><span style="font-size: 14pt;">I. Bei Erstellung des Urteils muss sich der Richter an die aktuellen Strafen, die im Strafenkatalog hinterlegt sind, halten.</span></p>
	<p><span style="font-size: 14pt;">II. Sozialarbeit, psychologische Gespräche etc. sind nicht abhängig vom Strafenkatalog, sind gesondert zu betrachten und werden nicht an die Hafteinheiten angerechnet.</span></p>
	<p><span style="font-size: 14pt;"><br></span></p>
	<p><span style="font-size: 14pt;"><strong>§ 13 Pflichtverteidigung</strong></span></p>
	<p><span style="font-size: 14pt;">I. Jeder Anwalt, der nicht durch das DoJ von seiner Pflichtverteidigung entbunden wird, ist verpflichtet, die Pflichtverteidigung bei Bedarf zu übernehmen.</span></p>
	<p><span style="font-size: 14pt;">II. Die Pflichtverteidiger sind der offiziellen Anwaltsliste zu entnehmen.</span></p>
	<p><span style="font-size: 14pt;">III. Der Pflichtverteidiger wird für seine Tätigkeit mit $2500 pro angefangener Stunde entlohnt.</span></p>
	<p><span style="font-size: 14pt;">IV. Die Rechnung ist im Nachgang beim DoJ einzureichen.</span></p>
`;

export const ProzessGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
