import * as type from '../redux/types';

export function startFetchTestsuitesCatalogue() {
  return {
    type: type.TESTSUITES_CATALOGUE_FETCH_START,
  };
}

export function startTestsuiteAssign(hash: string, name: string) {
  return {
    type: type.TESTSUITES_ASSIGN_START,
    hash,
    name,
  };
}

export function startFetchMyTestsuites() {
  return {
    type: type.TESTSUITES_LOAD_ASSIGNED_START,
  };
}

export function startFetchTestsuite(id: string) {
  return {
    type: type.TESTSUITES_LOAD_SUITE_START,
    id,
  };
}

export function sendTestsuite(
  id: string,
  multipleChoiceAnswers: any[],
  freeTextAnswers: any[]
) {
  return {
    type: type.TESTSUITES_ANSWER_SEND_START,
    id,
    multipleChoiceAnswers,
    freeTextAnswers,
  };
}

export function startFetchTestsuitesResults(playerName?: string) {
  return {
    type: type.TESTSUITES_RESULTS_FETCH_START,
    playerName,
  };
}

export function startFetchTestsuitesResult(id: string) {
  return {
    type: type.TESTSUITES_RESULT_FETCH_START,
    id,
  };
}
