import React from 'react';

const html = `
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <br />
          </span>
        </span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <strong>§ 1 Würde</strong>
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            I. Die Würde des Menschen ist unantastbar.
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <br />
          </span>
        </span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <strong>§ 2 Gesetzesgleichheit</strong>
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            I. Alle Menschen sind vor dem Gesetz gleich.
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <br />
          </span>
        </span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;"> </span>
        </span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <strong>§ 3 Körperliche Unversehrtheit</strong>
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            I. Jeder Mensch hat das Recht auf körperliche Unversehrtheit.
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <br />
          </span>
        </span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <strong>§ 4 Entfaltungsrecht</strong>
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            I. Jeder Mensch hat das Recht auf Entfaltung seiner Persönlichkeit,
            soweit er keine Rechte anderer verletzt oder gegen geltende Gesetze
            verstößt.
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <br />
          </span>
        </span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            <strong>§ 5 Unverletzlichkeit der Wohnung</strong>
          </span>
        </span>
        <span style="color:rgb(255,255,255);"></span>
      </p>
      <p>
        <span style="color:rgb(255,255,255);">
          <span style="font-size: 14pt;">
            I. Die Wohnung ist unverletzlich. Nur auf Grundlage eines Gesetzes
            darf in die Wohnung eingedrungen werden.
          </span>
        </span>
      </p>
`;

export const Grundgesetz = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
