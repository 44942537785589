const launchpadApps = [
  {
    id: 'redlion',
    title: 'Redlion RP',
    img: 'img/icons/launchpad/flint.png',
    link: 'https://redlion-rp.de/',
  },
  // {
  //   id: 'metallic',
  //   title: 'Metallic',
  //   img: 'img/icons/launchpad/meta.png',
  //   link: 'https://github.com/Renovamen/metallic',
  // },
  // {
  //   id: 'alkaid',
  //   title: 'Alkaid',
  //   img: 'img/icons/launchpad/rl.png',
  //   link: 'https://github.com/Renovamen/alkaid',
  // },
  // {
  //   id: 'oh-vue-icons',
  //   title: 'Oh, Vue Icons!',
  //   img: 'img/icons/launchpad/icon.png',
  //   link: 'https://oh-vue-icons.netlify.app/',
  // },
  // {
  //   id: 'gungnir',
  //   title: 'Gungnir',
  //   img: 'img/icons/launchpad/gungnir.png',
  //   link: 'https://vuepress-theme-gungnir.vercel.app/',
  // },
  // {
  //   id: 'what-if',
  //   title: 'My Notebook',
  //   img: 'img/icons/launchpad/notebook.png',
  //   link: 'https://note.zxh.io',
  // },
  // {
  //   id: 'cube',
  //   title: 'Cube Solver',
  //   img: 'img/icons/launchpad/cube.png',
  //   link: 'https://cube.zxh.io/',
  // },
  // {
  //   id: 'resume',
  //   title: 'Résumé',
  //   img: 'img/icons/launchpad/resume.png',
  //   link: 'https://resume.zxh.io/',
  // },
  // {
  //   id: 'zelda',
  //   title: 'Webpage for Zelda',
  //   img: 'img/icons/launchpad/zelda.png',
  //   link: 'https://zelda.zxh.io/',
  // },
  // {
  //   id: 'fishmail',
  //   title: 'Fishmail',
  //   img: 'img/icons/launchpad/fishmail.png',
  //   link: 'https://fishmail.vercel.app/',
  // },
];

export default launchpadApps;
