import controlCenterTemplate from '../../datasheets/templates/lspd/controlcenter';
import controlDatasheet from '../../datasheets/templates/lspd/control-datasheet';
import dashboardTemplate from '../../datasheets/templates/lspd/dashboard';
import leadTemplate from '../../datasheets/templates/lspd/lead';
import infoTemplate from '../../datasheets/templates/lspd/info';
import itemImpoundTemplate, {
  itemImpoundLineTemplate,
} from '../../datasheets/templates/lspd/itemInpound';
import jailTemplate, {
  jailLineTemplate,
} from '../../datasheets/templates/lspd/jail';
import signsTemplate, {
  signLineTemplate,
} from '../../datasheets/templates/lspd/signs';
import vehicleInpoundTemplate, {
  vehicleInpoundLineTemplate,
} from '../../datasheets/templates/lspd/vehicleInpound';
import weaponlicensesTemplate from '../../datasheets/templates/lspd/weaponlicenses';
import filesTodoListTemplate, {
  lspdFilesTodoListLineTemplate,
} from '../../datasheets/templates/lspd/files-todo-list';
import vehiclecontrolTemplate, {
  lspdVehicleControlLineTemplate,
} from '../../datasheets/templates/lspd/vehiclecontrol';

export const LSPDAppSheets = [
  {
    sheetId: 'lspddashboard',
    label: 'Dashboard',
    right: 'lspd.dashboard',
    config: {
      template: dashboardTemplate,
      requiredSheets: ['lspdlead', 'lspdcontroldata'],
    },
  },
  {
    sheetId: 'lspdcontrolcenter',
    label: 'Dienstblatt',
    right: 'lspd.controlcenter',
    config: {
      template: controlCenterTemplate,
      requiredSheets: ['lspdcontroldata'],
    },
  },
  {
    sheetId: 'lspdinfo',
    label: 'Infos',
    right: 'lspd.info',
    config: {
      template: infoTemplate,
      requiredSheets: ['lspdlead',],
    },
  },
  {
    sheetId: 'lspdfilestodo',
    label: 'Aktenaufgaben',
    right: 'lspd.info',
    config: {
      template: filesTodoListTemplate,
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: lspdFilesTodoListLineTemplate,
    },
  },
  {
    sheetId: 'lspdvehiclecontrol',
    label: 'Fahrzeug Status',
    right: 'lspd.vehiclecontrol',
    config: {
      template: vehiclecontrolTemplate,
      requiredSheets: ['lspdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 18,
      addLineTemplate: lspdVehicleControlLineTemplate,
    },
  },
  {
    sheetId: 'lspditemimpound',
    label: 'Sicherstellung',
    right: 'lspd.itemimpound',
    config: {
      template: itemImpoundTemplate,
      requiredSheets: ['lspdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: itemImpoundLineTemplate,
    },
  },
  {
    sheetId: 'lspdjail',
    label: 'Haft',
    right: 'lspd.jail',
    config: {
      template: jailTemplate,
      requiredSheets: ['lspdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: jailLineTemplate,
    },
  },
  {
    sheetId: 'lspdvehicleimpound',
    label: 'Verwahrstelle',
    right: 'lspd.vehicleimpound',
    config: {
      template: vehicleInpoundTemplate,
      requiredSheets: ['lspdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: vehicleInpoundLineTemplate,
    },
  },
  {
    sheetId: 'lspdsigns',
    label: 'Material',
    right: 'lspd.signs',
    config: {
      template: signsTemplate,
      requiredSheets: ['lspdcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: signLineTemplate,
    },
  },
  {
    sheetId: 'lspdweaponlicenses',
    label: 'Waffenschein',
    right: 'lspd.weaponlicenses',
    config: {
      template: weaponlicensesTemplate,
      requiredSheets: ['lspdcontroldata'],
    },
  },
  {
    sheetId: 'lspdlead',
    label: 'Lead',
    right: 'lspd.lead',
    config: {
      template: leadTemplate,
    },
  },
  {
    sheetId: 'lspdcontroldata',
    label: 'DB-Daten',
    right: 'lspd.controldata',
    config: {
      template: controlDatasheet,
    },
  },
];
