import React from 'react';
import { css } from '@emotion/react';

const headlineStyle = css`
  font-size: 2vh;
`;

const Headline = ({ children, css }: any) => (
  <h1 css={[headlineStyle, css]}>{children}</h1>
);

export default Headline;
