import React, { useEffect, useState } from 'react';
// import { parse } from 'fast-xml-parser';
import { css } from '@emotion/react';
import { find, pathEq, pathOr, pipe } from 'ramda';
import { Grundgesetz } from './laws/grundgesetz';
import { StrafGb } from './laws/strafgb';
import { WaffenGb } from './laws/waffengb';
import { Btmg } from './laws/Btmg';
import { TierGb } from './laws/TierGb';
import { ProzessGb } from './laws/ProcessGb';
import { STVO } from './laws/STVO';
import { MedGb } from './laws/MedGb';
import { HandelGb } from './laws/HandelGb';
import { KorruptionsGb } from './laws/KorruptiuonsGb';
import { PEingG } from './laws/PEingG';
import { ArbeitsGb } from './laws/ArbeitsGb';
import { ZiviGb } from './laws/ZiviGb';
import { History } from './laws/History';

const containerStyle = css`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;
  color: #fff;
  background-color: #0d0d0d;
`;
const menuContainerStyle = css`
  flex: 1;
  height: 100%;
  border-color: #888;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 1vh 0 #000000;
`;

const menuHeadStyle = css`
  font-size: 2vh;
  padding: 1.5vh;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid;
`;
const menuEntryStyle = (isActive: boolean) => css`
  font-size: 1.2vh;
  padding: 1vh;
  border-bottom: 1px solid;
  cursor: pointer;

  ${isActive ? 'filter: brightness(1);' : 'filter: brightness(0.7);'}
`;
const contentContainerStyle = css`
  flex: 3;
  height: 100%;
  padding: 2vh;
  overflow-y: auto;
  overflow-x: hidden;
  color: #fff;

  table {
    th,
    td {
      padding: 16px;
    }
    tr {
      border-bottom: 1px solid #ddd;
    }
  }

  ol,
  ul {
    list-style: disc;
    margin-left: 20px;
  }

  .spoilerBoxHeader {
    display: none !important;
  }
  .spoilerBoxContent {
    display: block !important;
  }
`;

const laws = [
  { title: 'Grundgesetz (GG)', Cmp: Grundgesetz },
  {
    title: 'Strafgesetzbuch (StGB)',
    Cmp: StrafGb,
  },
  { title: 'Waffengesetz (WaffG)', Cmp: WaffenGb },
  { title: 'Betäubungsmittelgesetz (BtmG)', Cmp: Btmg },
  { title: 'Tierschutzgesetz (TierSchG)', Cmp: TierGb },
  { title: 'Allgemeine Prozessordnung (APO)', Cmp: ProzessGb },
  { title: 'Straßenverkehrsordnung (StVO)', Cmp: STVO },
  { title: 'Medizinische Grundverordnung (MGVO)', Cmp: MedGb },
  { title: 'Handelsgesetzbuch (HGB)', Cmp: HandelGb },
  { title: 'Korruptionsgesetz (KG)', Cmp: KorruptionsGb },
  { title: 'Polizeiliches Eingriffsgesetz (PEinG)', Cmp: PEingG },
  { title: 'Arbeitsgesetz (AG)', Cmp: ArbeitsGb },
  { title: 'Zivilgesetz (ZG)', Cmp: ZiviGb },
  { title: 'Änderungen', Cmp: History },
];

export const Laws = () => {
  const [activeMenu, setActiveMenu] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    // fetch(RSS_URL)
    //   .then((res) => res.text())
    //   .then((xmlData) => {
    //     const jsonData = parse(xmlData);
    //     const laws = pipe(
    //       pathOr([], ['rss', 'channel', 'item']),
    //       find(pathEq(['title'], 'Gesetzbücher')),
    //       pathOr('', ['content:encoded'])
    //     )(jsonData);
    //     let test: any = laws.split(
    //       '<p><span style="color:rgb(255,255,255);"><span style="font-size: 24pt;"><strong>'
    //     );
    //     test.shift();
    //     test = test.map((x: any) => {
    //       const title = x.substr(0, x.indexOf('</strong>'));
    //       return {
    //         title,
    //         data:
    //           '<p><span style="color:rgb(255,255,255);"><span style="font-size: 24pt;"><strong>' +
    //           x,
    //       };
    //     });
    //     setData(test);
    //   });
  }, []);

  // const selectedContent = pipe(
  //   find(pathEq(['title'], activeMenu)),
  //   pathOr('', ['data'])
  // )(data);

  const Cmp =
    laws.find((law) => law.title === activeMenu)?.Cmp ?? (() => <div />);

  return (
    <div css={containerStyle}>
      <div css={menuContainerStyle}>
        <div css={menuHeadStyle}>Gesetzbücher</div>
        {laws.map((entry) => (
          <div
            key={entry.title}
            css={menuEntryStyle(activeMenu === entry.title)}
            onClick={() => setActiveMenu(entry.title)}
          >
            {entry.title}
          </div>
        ))}
      </div>
      <div
        css={contentContainerStyle}
        // dangerouslySetInnerHTML={{ __html: selectedContent }}
      >
        <Cmp />
      </div>
    </div>
  );
};
