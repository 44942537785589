import { css } from '@emotion/react';
import { pathOr, find, pathEq, pipe } from 'ramda';

export const WANTED_STATES = [
  { value: 'NONE', label: 'Nicht gesucht' },
  { value: 'WANTED', label: 'Gesucht' },
];

export const getWantedStateLabel = (state: string) => {
  return pipe(
    find(pathEq(['value'], state)),
    pathOr('-', ['label'])
  )(WANTED_STATES);
};

export const wantedStateIconStyle = (theme: any) => css`
  &.WANTED {
    color: ${theme.colors.Red};
  }
  &.NONE {
    color: ${theme.colors.Green};
  }
`;
