import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import {
  appsAdminDeathsCompletedP,
  appsAdminDeathsResultsP,
  appsAdminDeathsSearchP,
} from '../../../redux/reducer/apps/admin/deaths';
import { setAdminDeathsSearch, startAdminDeathsFetch } from '../../../actions';
import { DeathsEntryType } from './types/deaths';
import { getDeathReasonByNumber } from '../../../utils/death';

type DeathsType = {
  openPlayer: (_id: string, playerName: string) => void;
};
export const Deaths = ({ openPlayer }: DeathsType) => {
  const dispatch = useDispatch();
  const search = useSelector<any, string>(pathOr('', appsAdminDeathsSearchP));
  const list = useSelector<any, DeathsEntryType[]>(
    pathOr([], appsAdminDeathsResultsP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminDeathsCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminDeathsFetch(search, offset));
  };

  const updateSearch = (ev: any) => {
    dispatch(setAdminDeathsSearch(ev.target.value));
  };
  const executeSearch = () => {
    dispatch(startAdminDeathsFetch(search, 0));
  };

  useEffect(() => {
    dispatch(startAdminDeathsFetch(search, 0));
  }, []);

  return (
    <div>
      <h1>Deaths</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={updateSearch}
      />

      <Button variant="outlined" onClick={executeSearch}>
        Suche starten
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Opfer</TableCell>
            <TableCell>Täter</TableCell>
            <TableCell>Grund</TableCell>
            <TableCell>Mögliche Täter</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.date))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell
                onClick={() => openPlayer(entry.victim._id, entry.victim.name)}
                css={{ cursor: 'pointer' }}
              >
                {entry.victim.name}
              </TableCell>
              <TableCell
                onClick={() =>
                  entry.killer &&
                  openPlayer(entry.killer._id, entry.killer.name)
                }
                css={{ cursor: 'pointer' }}
              >
                {entry.killer?.name}
              </TableCell>
              <TableCell>{getDeathReasonByNumber(entry.reason)}</TableCell>
              <TableCell>
                {entry.possibleKillers && entry.possibleKillers.join(', ')}
              </TableCell>
            </TableRow>
          ))}
          {!reachedEndOfList && (
            <TableRow>
              <TableCell colSpan={7}>
                <Button variant="outlined" onClick={() => onLoad(list.length)}>
                  Weitere Laden
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
