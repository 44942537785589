import { UserPermissionType } from '../../../components/apps/Admin/types/userrights';
import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminPermissions = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PERMISSIONS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/admin/permissions/players')
        .then((data) => {
          return {
            type: types.ADMIN_PERMISSIONS_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_PERMISSIONS_FETCH_FAILED,
        }));
    })
  );

export const onChangeAdminPermissions = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PERMISSIONS_CHANGE_START),
    mapPromiseC(({ user }: { user: UserPermissionType }) => {
      return authFetchPost('/admin/permissions/players/change', {
        id: user._id,
        name: user.character?.name,
        roles: user.roles.map((x) => x.key),
        rights: user.rightsAdjustment
          .filter((x) => x.adjustment === 1)
          .map((x) => x.right),
        rightsRemoved: user.rightsAdjustment
          .filter((x) => x.adjustment === -1)
          .map((x) => x.right),
      })
        .then(() => {
          return [
            {
              type: types.ADMIN_PERMISSIONS_CHANGE_SUCCESS,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Erfolgreich geändert',
            },
            { type: types.ADMIN_PERMISSIONS_FETCH_START },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_PERMISSIONS_CHANGE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Ändern',
          },
        ]);
    })
  );

export const onDeleteAdminPermissions = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PERMISSIONS_DELETE_START),
    mapPromiseC(({ user }: { user: UserPermissionType }) => {
      return authFetchPost('/admin/permissions/players/delete', {
        id: user._id,
      })
        .then(() => {
          return [
            {
              type: types.ADMIN_PERMISSIONS_DELETE_SUCCESS,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Erfolgreich gelöscht',
            },
            { type: types.ADMIN_PERMISSIONS_FETCH_START },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_PERMISSIONS_DELETE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Löschen',
          },
        ]);
    })
  );
