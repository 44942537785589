import { filter } from 'ramda';
import React from 'react';
import { AiOutlineLink } from 'react-icons/ai';

type MiddlebarType = {
  items: {
    id: string;
    icon: JSX.Element;
    title: string;
    link?: string;
    excerpt?: string;
    hide?: boolean;
  }[];
  selected: string;
  select: (itemId: string) => void;
};

export const Middlebar = ({ items, selected, select }: MiddlebarType) => {
  const filteredItems = filter((x) => !x.hide, items);
  return (
    <div className="midbar w-full h-full bg-gray-50 border-r border-gray-300 overflow-y-scroll">
      <ul>
        {filteredItems.map((item, index) => (
          <li
            key={`bear-midbar-${item.id}`}
            className={`h-24 flex flex-col cursor-default border-l-2 ${
              selected === item.id
                ? 'border-red-500 bg-white'
                : 'border-transparent bg-transparent'
            } hover:bg-white`}
            onClick={() => select(item.id)}
          >
            <div className="h-8 mt-3 flex flex-row flex-none items-center">
              <div className="-mt-1 w-10 text-gray-500 flex flex-none justify-center">
                {item.icon}
              </div>
              <span className="relative text-gray-900 flex-grow font-bold">
                {item.title}
                {item.link && (
                  <a
                    className="absolute top-1 right-4"
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineLink className="text-gray-500" />
                  </a>
                )}
              </span>
            </div>
            <div className="h-16 ml-10 pb-2 pr-1 border-b border-gray-300 text-sm text-gray-500">
              {item.excerpt}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
