import * as types from '../../../redux/types';
import {
  pipeC,
  ofType,
  mapPromiseC,
  mapC,
  debounceC,
} from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { notifySuccess, notifyError } from '../../utils';

export const onFetchAdminVehicle = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLE_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/vehicle/${_id}`)
        .then((data) => {
          return {
            type: types.ADMIN_VEHICLE_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_VEHICLE_FETCH_FAILED,
        }));
    })
  );

export const onRemoveAdminVehicleItem = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLE_ITEM_REMOVE_START),
    mapPromiseC(({ _id, id, hash, amount }: any) => {
      return authFetchPost(`/admin/vehicle/${_id}/items/remove`, {
        vehicleId: _id,
        id,
        hash,
        amount,
      })
        .then(() => [
          notifySuccess('Item entfernt'),
          {
            type: types.ADMIN_VEHICLE_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Item entfernen fehlgeschlagen'));
    })
  );

export const onGarageAdminVehicle = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLE_GARAGE_START),
    mapPromiseC(({ _id, garage }: any) => {
      return authFetchPost(`/admin/vehicle/${_id}/garage`, {
        garage,
      })
        .then(() => [notifySuccess('Fahrzeug wird in kürze eingeparkt')])
        .catch(() => notifyError('Einparken fehlgeschlagen'));
    })
  );

export const onSearchNewAdminVehicleOwner = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLE_OWNER_NEW_SEARCH_START),
    debounceC(300),
    mapPromiseC(({ query }: any) => {
      return authFetchPost(`/admin/vehicle/ownersearch`, {
        query,
      })
        .then((res) => res.json())
        .then((data) => ({
          type: types.ADMIN_VEHICLE_OWNER_NEW_SEARCH_SUCCESS,
          data,
        }))
        .catch(() => ({ type: types.ADMIN_VEHICLE_OWNER_NEW_SEARCH_FAILED }));
    })
  );

export const onChangeAdminVehicleOwner = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLE_OWNER_CHANGE_START),
    mapPromiseC(({ _id, playerId }: any) => {
      return authFetchPost(`/admin/vehicle/owner/change`, {
        vehicle: _id,
        player: playerId,
      })
        .then(() => [
          notifySuccess('Besitzer geändert'),
          {
            type: types.ADMIN_VEHICLE_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Besitzer konnte nicht geändert werden'));
    })
  );

export const onChangeAdminVehicleNumberplate = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLE_NUMBERPLATE_CHANGE_START),
    mapPromiseC(({ _id, numberplate }: any) => {
      return authFetchPost(`/admin/vehicle/numberplate/change`, {
        vehicle: _id,
        numberplate,
      })
        .then(() => [
          notifySuccess('Kennzeichen geändert'),
          {
            type: types.ADMIN_VEHICLE_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Kennzeichen konnte nicht geändert werden'));
    })
  );

export const onRepairAdminVehicle = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLE_REPAIR_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchPost(`/admin/vehicle/damage/repair`, {
        vehicle: _id,
      })
        .then(() => [
          notifySuccess('Fahrzeug repariert'),
          {
            type: types.ADMIN_VEHICLE_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Reparatur konnte nicht durchgeführt werden'));
    })
  );
