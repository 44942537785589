import React from 'react';
import { Editor as WYSIWYGEditor } from 'react-draft-wysiwyg';
import { css } from '@emotion/react';
import { customStyleMap, editorStyles, blockRenderer } from './config';
import { DOCUMENT_A4 } from '../constants/documents';

const hiddenToolbarStyles = css`
  .rdw-editor-toolbar {
    display: none;
    visibility: hidden;
  }
`;

const Editor = ({
  content,
  size = { height: DOCUMENT_A4.height, width: DOCUMENT_A4.width },
  template,
}: any) => {
  return (
    <div css={[() => editorStyles(size, template), hiddenToolbarStyles]}>
      <WYSIWYGEditor
        editorState={content}
        readOnly={true}
        tabIndex={-1}
        customStyleMap={customStyleMap}
        customBlockRenderFunc={blockRenderer}
      />
    </div>
  );
};

export default Editor;
