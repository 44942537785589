import { path, pathOr, pipe } from 'ramda';
import { connect } from 'react-redux';
import { Navbar } from '../components/Navbar';
import { startRouterGoto } from '../actions';
import { activityNewP } from '../store/reducer/activity';

const routeP = ['router', 'location', 'pathname'];

const mapStateToProps = (state: any) => {
  return {
    route: path(routeP, state),
    newActivityAmount: pathOr(0, activityNewP, state)
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  goto: pipe(startRouterGoto, dispatch),
});

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(Navbar);
