const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const usedFullDoctor = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  color: '#d50000',
  'font-weight': 'bold',
  'justify-content': 'center',
  border: '0 !important',
};
const usedFix = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const select = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'justify-content': 'center',
      },
    },
  ],
};
export default [
  [
    {
      value: 'MANV PROTOKOLL',
      colSpan: 14,
      style: fixFett,
      readOnly: true,
      width: '140vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 14,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
  ],
  [
    {
      value: 'Datum',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Patientename:',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Gruppierung:',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Zustand:',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Behandlung:',
      colSpan: 4,
      style: fixFett,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'Behandelt von:',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Abtransport',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Behandelt',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
  ],
];

export const manvLineTemplate = [
  {
    value: '',
    colSpan: 1,
    style: usedFull,
    width: '10vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedFull,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedFull,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 1,
    style: usedFull,
    width: '10vh',
  },
  {
    value: '',
    colSpan: 4,
    style: usedFull,
    width: '80vh',
  },
  {
    value: '',
    style: usedFull,
    colSpan: 2,
    readOnly: true,
    width: '20vh',
    type: 'select',
    depOptions: {
      sheetId: 'lsmdcontroldata',
      column: 'E',
      rowStart: 1,
      rowEnd: 110,
    },
  },
  {
    value: false,
    type: 'checkbox',
    colSpan: 1,
    style: usedFull,
    readOnly: true,
    width: '10vh',
  },
  {
    value: false,
    type: 'checkbox',
    colSpan: 1,
    style: usedFull,
    readOnly: true,
    width: '10vh',
  },
];
