import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { fetchListValues, notifyError, notifySuccess } from '../../utils';

export const onFetchAdminPlayerDetails = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_DETAILS_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/player/${_id}`)
        .then((data) => {
          return {
            type: types.ADMIN_PLAYER_DETAILS_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_PLAYER_DETAILS_FETCH_FAILED,
        }));
    })
  );

export const onFetchAdminTpToAdminBase = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_TP_ADMINBASE_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetch(`/admin/player/${_id}/teleport`)
        .then(() => {
          return [
            {
              type: types.ADMIN_PLAYER_TP_ADMINBASE_FETCH_SUCCESS,
            },
            {
              type: types.ADMIN_PLAYER_DETAILS_FETCH_START,
              _id,
            },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_PLAYER_TP_ADMINBASE_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Teleportieren',
          },
        ]);
    })
  );

export const onFetchAdminToggleDisableSerial = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_DISABLESERIAL_TOGGLE_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetch(`/admin/player/multiaccount/serialdisable/${_id}`)
        .then(() => {
          return [
            {
              type: types.ADMIN_PLAYER_DETAILS_FETCH_START,
              _id,
            },
          ];
        })
        .catch(() => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler',
          },
        ]);
    })
  );

export const onFetchAdminToggleMultiAccAccCheck = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_MULTIACCOUNT_ACCCHECK_TOGGLE_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetch(
        `/admin/player/multiaccount/disable-account-check/${_id}`
      )
        .then(() => {
          return [
            {
              type: types.ADMIN_PLAYER_DETAILS_FETCH_START,
              _id,
            },
          ];
        })
        .catch(() => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler',
          },
        ]);
    })
  );

export const onFetchAdminSecondChance = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_SECONDCHANCE_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetch(`/admin/${_id}/secondchance`)
        .then(() => {
          return [
            {
              type: types.ADMIN_PLAYER_DETAILS_FETCH_START,
              _id,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Secondchance erfolgreich',
            },
          ];
        })
        .catch(() => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler',
          },
        ]);
    })
  );

export const onFetchAdminPlayerIds = fetchListValues(
  types.ADMIN_PLAYER_IDS_FETCH_START,
  types.ADMIN_PLAYER_IDS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_IDS_FETCH_FAILED,
  (_id: string, offset: number) => `/admin/player/${_id}/ids?offset=${offset}`
);

export const onFetchAdminPlayerBankAccounts = fetchListValues(
  types.ADMIN_PLAYER_BACKACCOUNTS_FETCH_START,
  types.ADMIN_PLAYER_BACKACCOUNTS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_BACKACCOUNTS_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/bankAccount?offset=${offset}`
);

export const onFetchAdminPlayerVehicles = fetchListValues(
  types.ADMIN_PLAYER_VEHICLES_FETCH_START,
  types.ADMIN_PLAYER_VEHICLES_FETCH_SUCCESS,
  types.ADMIN_PLAYER_VEHICLES_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/vehicles?offset=${offset}`
);

export const onFetchAdminPlayerTelNumbers = fetchListValues(
  types.ADMIN_PLAYER_TELNUMBERS_FETCH_START,
  types.ADMIN_PLAYER_TELNUMBERS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_TELNUMBERS_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/phoneNumbers?offset=${offset}`
);

export const onFetchAdminPlayerInventory = fetchListValues(
  types.ADMIN_PLAYER_INVENTORY_FETCH_START,
  types.ADMIN_PLAYER_INVENTORY_FETCH_SUCCESS,
  types.ADMIN_PLAYER_INVENTORY_FETCH_FAILED,
  (_id: string, offset: number) => `/admin/player/${_id}/items?offset=${offset}`
);

export const onFetchAdminPlayerMultiaccount = fetchListValues(
  types.ADMIN_PLAYER_MULTIACCOUNT_FETCH_START,
  types.ADMIN_PLAYER_MULTIACCOUNT_FETCH_SUCCESS,
  types.ADMIN_PLAYER_MULTIACCOUNT_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/accounts?offset=${offset}`
);

export const onFetchAdminPlayerMultiaccountB = fetchListValues(
  types.ADMIN_PLAYER_MULTIACCOUNTB_FETCH_START,
  types.ADMIN_PLAYER_MULTIACCOUNTB_FETCH_SUCCESS,
  types.ADMIN_PLAYER_MULTIACCOUNTB_FETCH_FAILED,
  (_id: string, offset: number) => `/admin/player/multiaccount/list/${_id}`
);

export const onFetchAdminPlayerFactoryOwner = fetchListValues(
  types.ADMIN_PLAYER_FACTORYOWNER_FETCH_START,
  types.ADMIN_PLAYER_FACTORYOWNER_FETCH_SUCCESS,
  types.ADMIN_PLAYER_FACTORYOWNER_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/factories?offset=${offset}`
);

export const onFetchAdminPlayerFactoryEmployment = fetchListValues(
  types.ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_START,
  types.ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_SUCCESS,
  types.ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/employeers?offset=${offset}`
);

export const onFetchAdminPlayerApartments = fetchListValues(
  types.ADMIN_PLAYER_APARTMENTS_FETCH_START,
  types.ADMIN_PLAYER_APARTMENTS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_APARTMENTS_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/apartments?offset=${offset}`
);

export const onFetchAdminPlayerDeaths = fetchListValues(
  types.ADMIN_PLAYER_DEATHS_FETCH_START,
  types.ADMIN_PLAYER_DEATHS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_DEATHS_FETCH_FAILED,
  (_id: string, offset: number) =>
    `/admin/player/${_id}/deaths?offset=${offset}`
);

export const onFetchAdminPlayerTickets = fetchListValues(
  types.ADMIN_PLAYER_TICKETS_FETCH_START,
  types.ADMIN_PLAYER_TICKETS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_TICKETS_FETCH_FAILED,
  (_id: string, offset: number) => `/admin/tickets/list/${_id}`
);

export const onFetchAdminPlayerNotes = fetchListValues(
  types.ADMIN_PLAYER_NOTES_FETCH_START,
  types.ADMIN_PLAYER_NOTES_FETCH_SUCCESS,
  types.ADMIN_PLAYER_NOTES_FETCH_FAILED,
  (_id: string, offset: number) => `/admin/notes/${_id}`
);

export const onFetchAdminPlayerWarnings = fetchListValues(
  types.ADMIN_PLAYER_WARNINGS_FETCH_START,
  types.ADMIN_PLAYER_WARNINGS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_WARNINGS_FETCH_FAILED,
  (_id: string, offset: number) => `/admin/warns/${_id}`
);

export const onFetchAdminPlayerBans = fetchListValues(
  types.ADMIN_PLAYER_BANS_FETCH_START,
  types.ADMIN_PLAYER_BANS_FETCH_SUCCESS,
  types.ADMIN_PLAYER_BANS_FETCH_FAILED,
  (_id: string, offset: number) => `/admin/bans/${_id}`
);

export const onFetchAdminNameChange = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_NAMECHANGE_START),
    mapPromiseC(({ _id, name }: any) => {
      return authFetchPost(`/admin/player/${_id}/rename`, { name })
        .then(() => [
          notifySuccess('Name erfolgreich geändert'),
          { type: types.ADMIN_PLAYER_DETAILS_FETCH_START },
        ])
        .catch(() => notifyError('Fehler beim Ändern des Namens'));
    })
  );

export const onFetchAdminPwChange = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_PWCHANGE_START),
    mapPromiseC(({ _id, password }: any) => {
      return authFetchPost(`/admin/player/${_id}/passwordreset`, { password })
        .then(() => [notifySuccess('Passwort erfolgreich geändert')])
        .catch(() => notifyError('Fehler beim Ändern des Namens'));
    })
  );

export const onFetchAdminMsg = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_MSG_START),
    mapPromiseC(({ _id, msgReason }: any) => {
      return authFetchPost(`/admin/player/${_id}/msguser`, { msgReason })
        .then(() => [notifySuccess('Nachricht gesendet')])
        .catch(() => notifyError('Fehler beim Senden der Nachricht'));
    })
  );

export const onFetchAdminKick = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_KICK_START),
    mapPromiseC(({ _id, kickReason }: any) => {
      return authFetchPost(`/admin/player/${_id}/kickuser`, { kickReason })
        .then(() => notifySuccess('Spieler gekickt'))
        .catch(() => notifyError('Fehler beim Kicken des Spielers'));
    })
  );

export const onFetchAdminPlayerMaxBankAccounts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/player/${_id}/getMaxBankAccounts`)
        .then(({ maxPrivateBankAccounts }) => ({
          type: types.ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_SUCCESS,
          data: maxPrivateBankAccounts,
        }))
        .catch(() => ({
          type: types.ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_FAILED,
        }));
    })
  );

export const onSetAdminPlayerMaxBankAccounts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_MAX_BANKACCOUNT_SET_START),
    mapPromiseC(({ _id, num }: any) => {
      return authFetchPost(`/admin/player/${_id}/setMaxBankAccounts/${num}`)
        .then(() => [
          notifySuccess('Maximale Bankkonten gesetzt'),
          {
            type: types.ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_START,
            _id,
          },
        ])
        .catch(() =>
          notifyError('Fehler beim Setzen der Maximalen Bankkonten')
        );
    })
  );

export const onResetAdminPlayerScMultiaccount = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_SCMULTIACCOUNT_RESET_START),
    mapPromiseC(({ _id, refreshId }: any) => {
      return authFetchPost(`/admin/player/multiaccount/disable/${_id}`)
        .then(() => [
          notifySuccess('Erfolgreich zurückgesetzt'),
          {
            type: types.ADMIN_PLAYER_MULTIACCOUNT_FETCH_START,
            _id: refreshId,
            offset: 0,
          },
        ])
        .catch(() => notifyError('Fehler beim Zurücksetzen'));
    })
  );

export const onCreateAdminPlayerNote = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_NOTE_CREATE_START),
    mapPromiseC(({ _id, note }: any) => {
      return authFetchPost(`/admin/note/${_id}`, { note })
        .then(() => [
          notifySuccess('Notiz erstellt'),
          {
            type: types.ADMIN_PLAYER_NOTES_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Fehler beim Erstellen der Notiz'));
    })
  );

export const onCreateAdminPlayerWarning = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_WARNING_CREATE_START),
    mapPromiseC(({ _id, reason }: any) => {
      return authFetchPost(`/admin/warn/${_id}`, { reason })
        .then(() => [
          notifySuccess('Verwarnung erstellt'),
          {
            type: types.ADMIN_PLAYER_WARNINGS_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Fehler beim Erstellen der Verwarnung'));
    })
  );

export const onCreateAdminPlayerBan = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_BAN_CREATE_START),
    mapPromiseC(({ _id, reason, expireInMinutes, banOnlyChar }: any) => {
      return authFetchPost(`/admin/ban/${_id}`, {
        reason,
        expireInMinutes,
        banOnlyChar,
      })
        .then(() => [
          notifySuccess('Ban erstellt'),
          {
            type: types.ADMIN_PLAYER_BANS_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Fehler beim Erstellen des Bans'));
    })
  );

export const onCreateAdminPlayerUnban = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_BAN_REMOVE_START),
    mapPromiseC(({ _id, refreshId }: any) => {
      return authFetch(`/admin/bans/unban/${_id}`)
        .then(() => [
          notifySuccess('Entbannt'),
          {
            type: types.ADMIN_PLAYER_BANS_FETCH_START,
            _id: refreshId,
          },
        ])
        .catch(() => notifyError('Fehler beim Entbannen'));
    })
  );

export const onRemoveAdminPlayerItem = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_PLAYER_ITEM_REMOVE_START),
    mapPromiseC(({ _id, id, hash, amount }: any) => {
      return authFetchPost(`/admin/player/${_id}/items/remove`, {
        playerId: _id,
        id,
        hash,
        amount,
      })
        .then(() => [
          notifySuccess('Item entfernt'),
          {
            type: types.ADMIN_PLAYER_INVENTORY_FETCH_START,
            _id,
            offset: 0,
          },
        ])
        .catch(() => notifyError('Fehler beim Entbannen'));
    })
  );
