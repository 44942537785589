// load initial token

export const tokenState = {
  token: localStorage.getItem('leogramToken') || '',
  canCreateScreenshots: false,
};

export const setToken = (t: string) => {
  tokenState.token = t;
};

export const setCanCreateScreenshots = (enabled: boolean) => {
  tokenState.canCreateScreenshots = enabled;
};
