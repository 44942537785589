export * from './common';
export * from './login';
export * from './commonapps';
export * from './testsuites';
export * from './finance';
export * from './company';
export * from './fraction';
export * from './mail';
export * from './admin';
export * from './settings';
export * from './browser';
export * from './documents';
export * from './folders';
export * from './desktop';
export * from './lsmd';
export * from './lspd';

export const ROUTER = '@@router/LOCATION_CHANGE';

export const NOTIFY_ERROR = 'NOTIFY_ERROR';
export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_DEFAULT = 'NOTIFY_DEFAULT';
