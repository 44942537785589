import React, {
  useState,
  createRef,
  forwardRef,
  useRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import { pathOr, pipe } from 'ramda';

import { isFullScreen } from '../../utils/screen';

// ------- import icons -------
import { BsBatteryFull } from 'react-icons/bs';
import { BiSearch } from 'react-icons/bi';
import { FaWifi } from 'react-icons/fa';
import { RiSignalWifiLine } from 'react-icons/ri';
import { toggleFullScreen } from '../../redux/action';
import { brightnessP } from '../../redux/reducer/common';
import { css } from '@emotion/react';

const TIME_OPTIONS = {
  locale: de,
};

const pawStyle = css`
  height: 18px;
`;

const headlineCustomStyle = css`
  // padding required for ingame tablet overlay
  padding: 0vh 1.5vh;
`;

const TopBarItem = forwardRef((props: any, ref: any) => {
  const hide = props.hideOnMobile ? 'hidden sm:inline-flex' : 'inline-flex';
  const hover = props.forceHover
    ? 'bg-white bg-opacity-30'
    : 'hover:bg-white hover:bg-opacity-30 rounded';
  return (
    <div
      ref={ref}
      className={`${hide} cursor-default flex-row space-x-1 ${hover} p-1`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
});

const TopBar = ({
  setSpotlightBtnRef,
  setControlCenterBtnRef,
  setAppleMenuBtnRef,
  setWifiMenuBtnRef,
  wifi,
  title,
  toggleSpotlight,
  toggleControlCenter,
  toggleAppleMenu,
  toggleWifiMenu,
}: any) => {
  const [date, setDate] = useState(new Date());
  const appleBtnRef = useRef();
  const controlCenterBtnRef = useRef();
  const wifiBtnRef = useRef();
  const spotlightBtnRef = useRef();

  // this.clickedOutside = {
  //   apple: false,
  //   control: false,
  // };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);

    // listen to screen size change
    window.addEventListener('resize', resize);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', resize);
    };
  }, []);

  useEffect(() => {
    setSpotlightBtnRef(spotlightBtnRef);
  }, [spotlightBtnRef]);

  useEffect(() => {
    setControlCenterBtnRef(controlCenterBtnRef);
  }, [controlCenterBtnRef]);

  useEffect(() => {
    setAppleMenuBtnRef(appleBtnRef);
  }, [appleBtnRef]);

  useEffect(() => {
    setWifiMenuBtnRef(wifiBtnRef);
  }, [wifiBtnRef]);

  const resize = () => {
    const isFull = isFullScreen();
    toggleFullScreen(isFull);
  };

  return (
    <div
      className="nightwind-prevent w-full h-6 px-4 fixed top-0 flex flex-row justify-between items-center text-sm text-white bg-gray-500 bg-opacity-10 blur shadow transition"
      css={headlineCustomStyle}
    >
      <div className="flex flex-row items-center space-x-4">
        <TopBarItem
          // forceHover={showAppleMenu}
          onClick={() => toggleAppleMenu()}
          ref={appleBtnRef}
        >
          {/* <AiFillApple size={18} /> */}
          <img src="/img/paw.png" css={pawStyle} />
        </TopBarItem>
        <span className="cursor-default font-semibold">{title}</span>
      </div>

      <div className="flex flex-row justify-end items-center space-x-2">
        <TopBarItem hideOnMobile={true}>
          <span className="text-xs mt-0.5 mr-1">100%</span>
          <BsBatteryFull size={20} />
        </TopBarItem>
        <TopBarItem
          hideOnMobile={true}
          onClick={toggleWifiMenu}
          ref={wifiBtnRef}
        >
          {wifi ? <FaWifi size={17} /> : <RiSignalWifiLine size={17} />}
        </TopBarItem>
        <TopBarItem ref={spotlightBtnRef} onClick={toggleSpotlight}>
          <BiSearch size={17} />
        </TopBarItem>
        <TopBarItem onClick={toggleControlCenter} ref={controlCenterBtnRef}>
          <img
            className="w-4 h-4 filter-invert"
            src="img/icons/menu/controlcenter.png"
            alt="control center"
          />
        </TopBarItem>

        <span>{format(date, 'eee d. MMM', TIME_OPTIONS)}</span>
        <span>{format(date, 'HH:mm', TIME_OPTIONS)}</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    volume: state.volume,
    brightness: pathOr(100, brightnessP, state),
    wifi: state.wifi,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  toggleFullScreen: pipe(toggleFullScreen, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
