import { registerReducerTree } from './register';
import { loginReducerTree } from './login';
import { postsReducerTree } from './posts';
import { profileSearchReducerTree } from './search';
import { profilePageReducerTree } from './profilepage';
import { screenshotReducerTree } from './screenshot';
import { routerReducerTree } from './router';
import { photoReducerTree } from './photo';
import { commentsReducerTree } from './comments';
import { activityReducerTree } from './activity';
import { adsReducerTree } from './ads';

export const leogramReducerTree: any = {
  ...registerReducerTree,
  ...loginReducerTree,
  ...postsReducerTree,
  ...profileSearchReducerTree,
  ...profilePageReducerTree,
  ...screenshotReducerTree,
  ...routerReducerTree,
  ...photoReducerTree,
  ...commentsReducerTree,
  ...activityReducerTree,
  ...adsReducerTree,
};
