import { toast } from 'react-toastify';
import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchPost } from '../../utils/fetch';

export const onFetchMyVehicles = (action$: any) =>
  pipeC(
    action$,
    ofType(types.APPS_MYVEHICLE_FETCH_START),
    mapPromiseC(() => {
      return authFetch('/vehicles/all')
        .then((res) => {
          return res.json().then((data) => [
            {
              type: types.APPS_MYVEHICLE_FETCH_SUCCESS,
              data,
            },
          ]);
        })
        .catch((res) => ({
          type: types.APPS_MYVEHICLE_FETCH_FAILED,
        }));
    })
  );

export const onFetchMyVehiclesFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.APPS_MYVEHICLE_FETCH_FAILED),
    mapC(() => {
      toast.error('Fahrzeuge konnten nicht abgefragt werden');
    })
  );

export const onMyVehiclesSetWaypoint = (action$: any) =>
  pipeC(
    action$,
    ofType(types.APPS_MYVEHICLE_WAYPOINT_SET),
    mapPromiseC(({ id }: any) => {
      return authFetchPost('/vehicles/waypoint', { id }).then(() => {
        toast.success('Navigationspunkt gesetzt');
        return undefined;
      });
    })
  );
