import { set, lensPath } from 'ramda';

import * as types from '../types';
import { setToken } from '../../utils/token';

export const loginFailedP = ['leogram', 'loginError'];
export const loginFailedL = lensPath(loginFailedP);

const loginFailed = (state: any, {  }: any) => {
  return set(loginFailedL, true, state);
};

const loginFailedReset = (state: any, {  }: any) => {
  return set(loginFailedL, false, state);
};
const loginSuccess = (state: any, { token, userTag }: any) => {
  // save to localStorage
  localStorage.setItem('leogramToken', token);
  localStorage.setItem('leogramUserTag', userTag);
  setToken(token);
  return state;
};

const logout = (state: any) => {
  localStorage.removeItem('leogramToken');
  setToken('');
  return state;
};

export const getLoggedInUserTag = () => {
  return localStorage.getItem('leogramUserTag') || '';
};

export const loginReducerTree = {
  [types.LEOGRAM_LOGIN_FAILED]: [loginFailed],
  [types.LEOGRAM_LOGIN_FAILED_RESET]: [loginFailedReset],
  [types.LEOGRAM_LOGIN_SUCCESS]: [loginSuccess],
  [types.LEOGRAM_LOGOUT]: [logout],
};
