export default [
  {
    type: 'h1',
    text: 'New Vibes Aparment - Modernes Apartment mit roten Konturen',
    customCss: '',
  },
  {
    type: 'section',
    text: 'Bereits der Eingangsbereich mit Flur ist grossräumig gestaltet und bietet eine hervorragende Möglichkeit, seine Gäste zu begrüssen als auch zum Aufstellen verschiedenster Möbelstücke zum Verstauen von Schuhen und Jacken. Hier ist definitiv auch genug Platz für viele, viele Frauenschuhe!',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/modern8/20211030142851_1.jpg',
      'img/interior/modern8/20211030142900_1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Direkt hinter dem Eingangsbereich befindet sich ein loftartiger Wohnbereich. Dieser riesige Bereich kann voll und ganz nach Ihren eigenen Wünschen eingerichtet werden. Mit Hilfe von Raumteilern können Sie den Raum in mehrere trennen. Ihre Träume einer erholsamen Wohnlandschaft werden hier voll und ganz erfüllt.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/modern8/20211030142924_1.jpg'],
    customCss: '',
  },

  {
    type: 'section',
    text: 'Eine riesige, offene Küche ermöglicht das Zaubern jeglicher Gourmet-Menüs. Die Elektroinstallationen entsprechen modernster Technik. Eine gigantische Arbeitsfläche mit anschliessendem Tresen lädt nicht nur zum Kochen ein, sondern bietet Ihren Gästen auch einen entspannten Platz an. So können Sie kochen und gleichzeitig Gespräche führen. Ist das nicht ein Traum?',
    customCss: '',
  },
  {
    type: 'section',
    text: 'Hinzu kommt noch ein Bereich, den man problemlos als Esszimmer nutzen kann. Stellen Sie hier Tische und Stühle Ihrer Wahl auf. Massiv Holz oder doch einen edlen Granittisch? Ihr Möbelhaus wird Sie sicher gut beraten.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/modern8/20211030142936_1.jpg',
      'img/interior/modern8/20211030142943_1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Komplettiert wird die Etage durch ein Arbeitszimmer. Edle Holzelemente machen dieses Zimmer besonders. Oder wird es vielleicht doch kein Arbeitszimmer, sondern ein Kinderzimmer?',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/modern8/20211030142959_1.jpg',
      'img/interior/modern8/20211030143020_1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Schauen Sie nun in die zweite Etage, die Ihnen zur Verfügung steht. Diese bietet einen Rückzug ins Private. Somit sind Wohnbereich und Platz für Gäste getrennt von Ihren privaten Schlafräumen und Bad. Im Treppenaufgang bietet sich zudem Stauraum.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/modern8/20211030143031_1.jpg',
      'img/interior/modern8/20211030143042_1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Ihr Schlafbereich beinhaltet nicht nur einen riesigen Raum, sondern auch eine getrente Räumlichkeit für Ihre Kleidung. Welche Frau wünscht sich nicht einen Ankleideraum?',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/modern8/20211030143047_1.jpg',
      'img/interior/modern8/20211030143057_1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Zu guter Letzt haben wir noch das Badezimmer. Die edle Inneneinrichtung beinhaltet zwei Waschbecken sowie eine Dusche und eine Badewanne.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/modern8/20211030143110_1.jpg',
      'img/interior/modern8/20211030143124_1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Damit sind wir am Ende der Besichtung. Diese edle Architektur hat natürlich ihren Preis. Dieser lohnt sich aber vollkommen, denn Ihr Traum kann hier wahr werden. Ihnen steht nicht nur eine grosse Fläche zur Verfügung, sondern durch den guten Schnitt der Wohnung auch viele, viele Möglichkeiten für Ihre Inneneinrichtung.',
    customCss: '',
  },
];
