import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { startAdminMetersFetch } from '../../../actions';
import { appsAdminMetersResultsP } from '../../../redux/reducer/apps/admin/meters';
import { ServerMeterData } from './types/meters';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { css } from '@emotion/react';
import de from 'date-fns/locale/de';
import { DateTime } from 'luxon';
registerLocale('de', de);

const containerStyle = css`
  height: 100%;
`;

const datepickerStyle = css`
  border: 0.1vh solid #fff;
  border-radius: 0.5vh;
  padding: 0.5vh;
  background: none;
`;

const toTwo = (x: number) => (x < 10 ? `0${x}` : x.toString());

export const Meters = ({}) => {
  const [targetDate, setTargetDate] = useState(new Date());
  const dispatch = useDispatch();
  const data = useSelector<any, ServerMeterData[]>(
    pathOr([], appsAdminMetersResultsP)
  );

  const fetchData = () => {
    if (targetDate) {
      dispatch(startAdminMetersFetch(0, targetDate));
    }
  };

  useEffect(() => {
    fetchData();
  }, [targetDate]);

  const prepared = data
    .map((entry) => {
      const date = new Date(entry.created);
      return {
        ...entry,
        customDate: `${toTwo(date.getHours())}:${toTwo(date.getMinutes())}`,
      };
    })
    .reverse();

  return (
    <div css={containerStyle}>
      <h1>Servermeters</h1>

      <h2>RAM Usage</h2>

      <DatePicker
        css={datepickerStyle}
        selected={targetDate}
        showTimeSelect
        onChange={(date: Date) => setTargetDate(date)}
        locale="de"
      />

      <div>
        {prepared && prepared.length > 1 && (
          <div>
            Daten von:{' '}
            {DateTime.fromJSDate(new Date(prepared[0].created))
              .setLocale('de')
              .toFormat('dd.LL.yy, HH:mm')}
            {'Uhr '}
            bis{' '}
            {DateTime.fromJSDate(
              new Date(prepared[prepared.length - 1].created)
            )
              .setLocale('de')
              .toFormat('dd.LL.yy, HH:mm')}
            Uhr
          </div>
        )}
      </div>

      <ResponsiveContainer width="100%" height="80%">
        <LineChart
          width={500}
          height={300}
          data={prepared}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="customDate" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="ram"
            stroke="#8884d8"
            unit="MB"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="heapTotal"
            stroke="#82ca9d"
            unit="MB"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="heapUsed"
            stroke="#ed8400"
            unit="MB"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
