import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminFactorysSearchP = [
  'apps',
  'admin',
  'factorysearch',
  'search',
];
export const appsAdminFactorysSearchL = lensPath(appsAdminFactorysSearchP);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminFactorysSearchL, search, state);
};

export const appsAdminFactorySearchResultsP = [
  'apps',
  'admin',
  'factorysearch',
  'results',
];
export const appsAdminFactorySearchResultsL = lensPath(
  appsAdminFactorySearchResultsP
);
const setSearchResults = (state: any, { data }: any) => {
  return set(appsAdminFactorySearchResultsL, data, state);
};

export const appsAdminFactorySearchReducerTree = {
  [types.ADMIN_FACTORY_SEARCH_SET]: [setSearch],
  [types.ADMIN_FACTORY_SEARCH_FETCH_SUCCESS]: [setSearchResults],
};
