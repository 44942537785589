import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { connect } from '../../utils/websocket';
import { lensPath, map, path, prop, reduce, set } from 'ramda';
import { Button, TextareaAutosize } from '@mui/material';
import { SimpleValueViewer } from './SimpleValueViewer';
import { renderCell } from './CellRender';
import { computeExpr, createScope } from './formula.utils';
import {
  requestRequiredSheets,
  useDependencySheetReducer,
} from './requiredSheets.utils';
import { setupData } from './setup.utils';
import { Cyan } from '../../configs/styles/colors';
import { useDispatch } from 'react-redux';
import { NOTIFY_ERROR } from '../../redux/types';

const editContainer = css`
  margin-top: 5vh;
  width: 50vh;
  height: 50vh !important;
  overflow: auto !important;
  color: #000;
`;

const containerStyle = css`
  position: absolute;
  width: calc(100% - 48px);
  overflow: auto;

  table {
    // width: 500px;
    background: #fff;
    color: #565656;
  }

  td {
    border: 0 !important;
  }

  input {
    height: 100% !important;
    width: 100% !important;
    color: #565656 !important;
  }

  .equation.cell {
    position: relative;
  }

  .equation.cell:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    border-style: solid;
    border-width: 6px 6px 0 0;
    border-color: #2185d0 transparent transparent transparent;
    z-index: 2;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .MuiInputBase-root {
    padding: 0 !important;
  }
  .MuiInputBase-input {
    padding: 0.6vh 0 !important;
    font-size: 1.1vh;
    line-height: 1.2vh;
  }

  font-size: 1.1vh;
  line-height: 1.2vh;

  .selected {
    outline: ${Cyan} solid 2px;
    outline-offset: -1px;
    outline-width: 1px;
  }

  .MuiInput-input {
    font-size: 1.1vh;
  }
`;

const buttonStyle = css`
  margin-top: 0.7vh !important;
  width: 100%;
  border-radius: 0 !important;
`;

const adjustTemplate = (template: any[][], data: any[][], config: any) => {
  const adaptedTemplates = [...template];
  const amountMissing = data.length - adaptedTemplates.length;
  if (amountMissing > 0 && config.addLineTemplate) {
    for (let i = 0; i < amountMissing; i++) {
      adaptedTemplates.push(JSON.parse(JSON.stringify(config.addLineTemplate)));
    }
  }
  return adaptedTemplates;
};

type DatasheetType = {
  config: {
    template: any[][];
    requiredSheets?: string[];
    canRemoveEmptyLines?: boolean;
    headLinesCountToIgnoreOnRemove?: number;
    addLineTemplate?: any[];
  };
  sheetId: string;
  isStyleEditingEnabled?: boolean;
  additionalStyles?: any;
};
export const Datasheet = ({
  config,
  sheetId,
  isStyleEditingEnabled,
  additionalStyles = css``,
}: DatasheetType) => {
  const dispatch = useDispatch();
  const [editTemplate, setEditTemplate] = useState<any>();
  const requiredSheets = config.requiredSheets;
  const template = editTemplate || config.template;

  const [data, setData] = useState<any[][]>(setupData(template, []));
  const [dataId, setDataId] = useState<string>('');
  const [websocket, setWebsocket] = useState<any>();
  const [inputData, setInputData] = useState<string>('');
  const [deps, clearDeps, updateDeps] = useDependencySheetReducer();

  useEffect(() => {
    setData(setupData(template, []));
    clearDeps();

    const socket = connect();
    let closeId = '';
    socket.emit('datasheet.subscribe', sheetId, (id: string) => {
      closeId = id;
    });
    socket.emit('datasheet.request', sheetId, (data: any) => {
      if (data.cells) {
        const adaptedTemplates = adjustTemplate(template, data.cells, config);
        setData(setupData(adaptedTemplates, data.cells));
        setDataId(data.dataId);
      }
    });

    const changeListener = (data: any) => {
      if (data.id === sheetId) {
        const adaptedTemplates = adjustTemplate(template, data.cells, config);
        setData(setupData(adaptedTemplates, data.cells));
        setDataId(data.dataId);
      }
      if (data.notify) {
        dispatch({
          type: NOTIFY_ERROR,
          msg: data.notify,
        });
      }
    };
    socket.on('datasheet.changed', changeListener);
    setWebsocket(socket);

    // load required sheets
    let closeRequires: null | Function;
    if (requiredSheets) {
      closeRequires = requestRequiredSheets(socket, requiredSheets, updateDeps);
    }

    return () => {
      socket.off('datasheet.changed', changeListener);
      socket.emit('datasheet.unsubscribe', closeId);
      if (closeRequires) {
        closeRequires();
      }
    };
  }, [sheetId, template, requiredSheets]);

  const valueRenderer = (cell: any) => cell.value;
  const onCellsChanged = (changes: any[]) => {
    const newData = reduce(
      (oldState, { cell, row, col, value }) => {
        return set(lensPath([row, col]), value, oldState);
      },
      data,
      changes
    );
    setData(newData);

    if (websocket) {
      websocket.emit('datasheet.change', {
        id: sheetId,
        dataId,
        cells: newData,
      });
    }
  };
  const onContextMenu = (e: any, cell: any, i: number, j: number) =>
    cell.readOnly ? e.preventDefault() : null;

  const adaptedTemplates = adjustTemplate(template, data, config);
  const scope = createScope(data, adaptedTemplates, deps);
  const cells = adaptedTemplates.map((row: any[], y: number) =>
    row.map((templateCell: any, x: number) => {
      const cellValue = path([y, x], data) || templateCell.value;

      const isFormula =
        typeof cellValue === 'string' && cellValue.startsWith('=');

      const cell = {
        ...templateCell,
        value: cellValue || templateCell.value,
      };

      return templateCell.type
        ? {
            ...templateCell,
            component: renderCell(cell, y, x, onCellsChanged, scope),
            forceComponent: true,
            width: '13vh',
          }
        : isFormula
        ? {
            valueViewer: SimpleValueViewer,
            width: '13vh',
            ...cell,
            ...computeExpr(cell.value, scope),
          }
        : {
            valueViewer: SimpleValueViewer,
            width: '13vh',
            ...cell,
          };
    })
  );

  const activate = () => {
    const corrected = inputData.replace('export default', 'const demoData = ');
    setEditTemplate(new Function(`${corrected} return demoData;`)());
  };

  const rmEmptyLines = () => {
    const newCellData = data.filter(
      (line, index) =>
        index < (config.headLinesCountToIgnoreOnRemove || 0) ||
        !reduce((isAllEmpty, entry) => isAllEmpty && !entry, true, line)
    );
    const newData = setupData(
      adjustTemplate(template, newCellData, config),
      newCellData
    );
    setData(newData);
    if (websocket) {
      websocket.emit('datasheet.change', {
        id: sheetId,
        dataId,
        cells: newData,
      });
    }
  };

  const addNewLine = () => {
    if (config.addLineTemplate) {
      const newData = setupData(
        [
          ...adaptedTemplates,
          JSON.parse(JSON.stringify(config.addLineTemplate)),
        ],
        [...data, config.addLineTemplate.map(() => '')]
      );
      setData(newData);
      if (websocket) {
        websocket.emit('datasheet.change', {
          id: sheetId,
          dataId,
          cells: newData,
        });
      }
    }
  };

  return (
    <div css={[containerStyle, additionalStyles]}>
      {/* @ts-ignore */}
      <ReactDataSheet
        data={cells}
        valueRenderer={valueRenderer}
        onContextMenu={onContextMenu}
        onCellsChanged={onCellsChanged}
      />
      {config.addLineTemplate && (
        <Button
          variant="outlined"
          css={buttonStyle}
          onClick={() => addNewLine()}
        >
          Zeile hinzufügen
        </Button>
      )}
      {config.canRemoveEmptyLines && (
        <Button
          variant="outlined"
          css={buttonStyle}
          onClick={() => rmEmptyLines()}
        >
          Leere Zeilen entfernen
        </Button>
      )}
      {isStyleEditingEnabled && (
        <>
          <TextareaAutosize
            css={editContainer}
            value={inputData}
            onChange={(ev: any) => setInputData(ev.target.value)}
          />
          <Button variant="outlined" onClick={() => activate()}>
            Aktivieren
          </Button>
        </>
      )}
    </div>
  );
};
