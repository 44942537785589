import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import {
  toggleDark,
  toggleWIFI,
  toggleAirdrop,
  toggleBleutooth,
  toggleFullScreen,
  setBrightness,
} from '../../redux/action';

// ------- import icons -------
import { FiBluetooth, FiRss } from 'react-icons/fi';
import {
  BsBrightnessAltHigh,
  BsFullscreen,
  BsFullscreenExit,
} from 'react-icons/bs';
import { IoSunny, IoMoon } from 'react-icons/io5';
import { FaWifi } from 'react-icons/fa';
import { pathOr, pipe } from 'ramda';
import { brightnessP, darkP } from '../../redux/reducer/common';
import { css } from '@emotion/react';

const zIndexStyle = css`
  z-index: 1000000;
`;

const SliderComponent = ({ icon, value, setValue }: any) => {
  return (
    <div className="slider flex flex-row w-full">
      <div className="h-8 p-2 bg-gray-100 rounded-l-full">{icon}</div>
      <Slider
        min={1}
        max={100}
        value={value}
        tooltip={false}
        orientation="horizontal"
        onChange={(v: number) => setValue(v)}
      />
    </div>
  );
};

type ControlCenterMenuType = {
  dark: boolean;
  wifi: boolean;
  brightness: number;
  bluetooth: boolean;
  airdrop: boolean;
  fullscreen: boolean;
  toggleDark: (enabled: boolean) => void;
  toggleWIFI: (enabled: boolean) => void;
  toggleAirdrop: (enabled: boolean) => void;
  toggleBleutooth: (enabled: boolean) => void;
  toggleFullScreen: (enabled: boolean) => void;
  setBrightness: (brightness: number) => void;
  toggleControlCenter: () => void;
  btnRef: any;
};
export const ControlCenterMenu = ({
  dark,
  wifi,
  brightness,
  bluetooth,
  airdrop,
  fullscreen,
  toggleDark,
  toggleWIFI,
  toggleAirdrop,
  toggleBleutooth,
  toggleFullScreen,
  setBrightness,
  toggleControlCenter,
  btnRef,
}: ControlCenterMenuType) => {
  const controlCenterRef = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        controlCenterRef &&
        !controlCenterRef.current.contains(e.target) &&
        !btnRef.current.contains(e.target)
      )
        toggleControlCenter();
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [controlCenterRef, btnRef, toggleControlCenter]);

  return (
    <div
      className="fixed w-96 max-w-full top-8 right-0 sm:right-2 p-2 grid grid-cols-4 grid-rows-3 gap-2 bg-white bg-opacity-40 blur rounded-2xl text-black shadow-2xl"
      css={zIndexStyle}
      ref={controlCenterRef}
    >
      <div className="row-span-2 col-span-2 bg-gray-200 bg-opacity-70 rounded-xl p-2 flex flex-col justify-around">
        <div className="flex flex-row items-center space-x-2 pr-6">
          <FaWifi
            size={36}
            className={`${
              wifi ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
            } rounded-full p-2`}
            onClick={() => toggleWIFI(!wifi)}
          />
          <div className="flex flex-col">
            <span className="font-medium">Wifi</span>
            <span className="font-thin text-xs">{wifi ? 'Home' : 'Off'}</span>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2 pr-6">
          <FiBluetooth
            size={36}
            className={`${
              bluetooth ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
            } rounded-full p-2`}
            onClick={() => toggleBleutooth(!bluetooth)}
          />
          <div className="flex flex-col">
            <span className="font-medium">Bluetooth</span>
            <span className="font-thin text-xs">
              {bluetooth ? 'On' : 'Off'}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2 pr-6">
          <FiRss
            size={36}
            className={`${
              airdrop ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
            } rounded-full p-2`}
            onClick={() => toggleAirdrop(!airdrop)}
          />
          <div className="flex flex-col">
            <span className="font-medium">LionDrop</span>
            <span className="font-thin text-xs">
              {airdrop ? 'Contacts Only' : 'Off'}
            </span>
          </div>
        </div>
      </div>
      <div className="col-span-2 bg-gray-200 bg-opacity-60 blur rounded-xl p-2 flex flex-row items-center space-x-2">
        {dark ? (
          <IoMoon
            size={34}
            className="text-gray-700 bg-gray-300 bg-opacity-50 rounded-full p-2"
            onClick={() => toggleDark(false)}
          />
        ) : (
          <IoSunny
            size={34}
            className="text-gray-700 bg-gray-300 bg-opacity-50 rounded-full p-2"
            onClick={() => toggleDark(true)}
          />
        )}
        <div className="flex flex-col">
          <span className="font-medium ml-1">
            {dark ? 'Dark Mode' : 'Light Mode'}
          </span>
        </div>
      </div>
      <div className="bg-gray-200 bg-opacity-60 blur rounded-xl p-2 flex flex-col justify-center items-center text-center">
        <BsBrightnessAltHigh size={20} />
        <span className="text-xs">Keyboard Brightness</span>
      </div>
      <div
        className="bg-gray-200 bg-opacity-60 blur rounded-xl p-2 flex flex-col justify-center items-center text-center cursor-default"
        onClick={() => toggleFullScreen(!fullscreen)}
      >
        {fullscreen ? (
          <BsFullscreenExit size={16} />
        ) : (
          <BsFullscreen size={16} />
        )}
        <span className="text-xs mt-1.5">
          {fullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
        </span>
      </div>
      <div className="col-span-4 bg-gray-200 bg-opacity-60 blur rounded-xl p-2 space-y-2 flex flex-col justify-around">
        <span className="font-medium">Display</span>
        <SliderComponent
          icon={<IoSunny size={16} className="text-gray-500" />}
          value={brightness}
          setValue={setBrightness}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
    wifi: state.wifi,
    brightness: pathOr(100, brightnessP, state),
    bluetooth: state.bluetooth,
    airdrop: state.airdrop,
    fullscreen: state.fullscreen,
  };
};

export default connect(mapStateToProps, {
  toggleDark,
  toggleWIFI,
  toggleAirdrop,
  toggleBleutooth,
  toggleFullScreen,
  setBrightness,
})(ControlCenterMenu);
