import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { startAdminPlayerApartmentsFetch } from '../../../../actions';
import {
  appsAdminPlayerApartmentsCompletedP,
  appsAdminPlayerApartmentsP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import { PlayerApartments } from '../types/playerApartments';
import { detailContainerStyle } from '../styles';

const tHeadStyle = css`
  // th {
  //   width: 200px;
  // }
`;
const tBodyStyle = css`
  // display: block;
  // max-height: 200px;
  // overflow: auto;

  // td {
  //   width: 200px;
  // }
`;

type ApartmentsType = {
  _id: string;
  openApartment: (_id: string, label: string) => void;
};
export const Apartments = ({ _id, openApartment }: ApartmentsType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerApartments[]>(
    pathOr([], appsAdminPlayerApartmentsP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerApartmentsCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerApartmentsFetch(_id, offset));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Apartments</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead css={tHeadStyle}>
            <TableRow>
              <TableCell>Hash</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Preis</TableCell>
            </TableRow>
          </TableHead>
          <TableBody css={tBodyStyle}>
            {list.map((entry) => (
              <TableRow
                key={entry._id}
                onClick={() => openApartment(entry._id, entry.hash)}
              >
                <TableCell>{entry.hash}</TableCell>
                <TableCell>{entry.name}</TableCell>
                <TableCell>${entry.price}</TableCell>
              </TableRow>
            ))}
            {!reachedEndOfList && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Button
                    variant="outlined"
                    onClick={() => onLoad(list.length)}
                  >
                    Weitere Laden
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
