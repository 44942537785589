import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  startAdminPlayerInventoryFetch,
  removeAdminPlayerItem,
} from '../../../../actions';
import { appsAdminPlayerItemsP } from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import ClearIcon from '@mui/icons-material/ClearRounded';
import { PlayerInventoryEntry } from '../types/playerinventory';
import { translate } from '../../../../utils/translate';
import { detailContainerStyle } from '../styles';

const itemContainerStyle = css`
  padding: 10px;
`;
const subItemsStyle = css`
  padding-left: 10px;
`;

const renderItems = (
  items: PlayerInventoryEntry[],
  isOnline: boolean,
  rmFn?: Function
) => (
  <Table>
    <TableBody>
      {items.map((entry) => (
        <TableRow key={entry._id}>
          <TableCell>
            {entry.amount}x {translate(entry.hash)}
          </TableCell>
          <TableCell>{entry.data?.label && entry.data.label}</TableCell>
          <TableCell>
            {!isOnline && rmFn && (
              <ClearIcon
                onClick={() => rmFn(entry._id, entry.hash, entry.amount)}
                css={{ cursor: 'pointer' }}
              />
            )}
          </TableCell>
          {entry.subitems && entry.subitems.length > 0 && (
            <TableCell css={subItemsStyle}>
              {renderItems(entry.subitems, isOnline)}
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

type InventoryType = {
  _id: string;
  isOnline: boolean;
};
export const Inventory = ({ _id, isOnline }: InventoryType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerInventoryEntry[]>(
    pathOr([], appsAdminPlayerItemsP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerInventoryFetch(_id, offset));
  };

  const rmItem = (id: string, hash: string, amount: number) => {
    dispatch(removeAdminPlayerItem(_id, id, hash, amount));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Inventar</h2>

      {list.length === 0 && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <div css={itemContainerStyle}>
          {renderItems(list, isOnline, rmItem)}
        </div>
      )}
    </div>
  );
};
