import { css } from '@emotion/react';
import React, { useState } from 'react';
import { TextView } from '../TextView';

const containerStyle = (additional: string) => css`
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;

  ${additional}
`;
const menuContainerStyle = css`
  flex: 1;
  height: 100%;
  border-color: #888;
  max-height: 100%;
  overflow: auto;
`;

const menuHeadStyle = css`
  font-size: 2vh;
  padding: 1.5vh;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid;
`;
const menuEntryStyle = (isActive: boolean) => css`
  font-size: 1.2vh;
  padding: 1vh;
  border-bottom: 1px solid;
  cursor: pointer;

  ${isActive ? 'filter: brightness(1);' : 'filter: brightness(0.7);'}
`;

const contentContainerStyle = css`
  flex: 3;
  height: 100%;
  padding: 2vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const createSimpleMenuWithContent = (config: any) => () => {
  const [activeMenu, setActiveMenu] = useState(config.menu[0].value);

  const pageContent = config.pages[activeMenu] || [];

  return (
    <div css={containerStyle(config.backgroundCss)}>
      <div css={menuContainerStyle} className="bg-gray-100 text-gray-600">
        <div css={menuHeadStyle}>{config.menuHeaderText}</div>
        {config.menu.map((entry: any) => (
          <div
            key={entry.value}
            css={menuEntryStyle(activeMenu === entry.value)}
            onClick={() => setActiveMenu(entry.value)}
          >
            {entry.label}
          </div>
        ))}
      </div>
      <div css={contentContainerStyle} className="p-4">
        <TextView contents={pageContent} />
      </div>
    </div>
  );
};
