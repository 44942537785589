import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminApartmentSearch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_APARTMENT_SEARCH_FETCH_START),
    mapPromiseC(({ search }: any) => {
      return authFetchJson(`/admin/houses/?q=${search}`)
        .then((data) => {
          return {
            type: types.ADMIN_APARTMENT_SEARCH_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_APARTMENT_SEARCH_FETCH_FAILED,
        }));
    })
  );
