import { css } from '@emotion/react';
import React from 'react';
import { ads } from './configs/ads';

const navSectionContainerStyle = css`
  padding: 1vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const navSectionEntryStyle = (bg: string, color: string) => css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 8vh;
  width: 8vh;
  font-size: 1.5vh;
  font-weight: bold;
  cursor: pointer;
  color: ${color};
  background-color: ${bg}aa;
  background: radial-gradient(circle, ${bg}44 0%, ${bg}66 100%),
    linear-gradient(140deg, #d50000, transparent);
  :hover {
    background-color: ${bg};
  }

  svg {
    height: 2vh;
    width: 2vh;
  }
`;

const marginStyle = css`
  min-width: 8vh;
  display: flex;
  justify-content: center;
  margin: 2vh;
`;

type adEntryType = {
  title: string;
  url: string;
  icon: any;
  bgColor: string;
  color: string;
};

type NavSectionType = {
  title: string;
  ads: adEntryType[];
  openUrl: (url: string) => void;
};
const NavSection = ({ title, ads, openUrl }: NavSectionType) => {
  return (
    <div className="mx-auto pt-8 w-full max-w-screen-md px-4">
      <div className="text-xl sm:text-2xl font-medium ml-2 text-black">
        {title}
      </div>
      <div css={navSectionContainerStyle}>
        {ads.map((entry) => (
          <div css={marginStyle} key={entry.title}>
            <div
              css={navSectionEntryStyle(entry.bgColor, entry.color)}
              key={entry.url}
              onClick={() => openUrl(entry.url)}
            >
              <entry.icon />
              {entry.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

type DashboardType = {
  openUrl: (url: string) => void;
};
export const Dashboard = ({ openUrl }: DashboardType) => {
  return (
    <div className="w-full safari-content bg-blue-50 overflow-y-auto bg-center bg-cover h-full">
      <div className="w-full pt-8 bg-gray-100 bg-opacity-80 blur h-full">
        <NavSection title="Übersicht" ads={ads} openUrl={openUrl} />
      </div>
    </div>
  );
};
