export const createReduxbagMiddleware = (...epics: Function[]) => (
  store: any
) => {
  const handlers: any[] = [];

  const action$ = (start: number, sink: Function) => {
    if (start !== 0) return;
    const handler = (action: any) => sink(1, action);
    sink(0, (t: number) => {
      if (t === 2) {
        handlers.splice(handlers.indexOf(handler), 1);
      }
    });
    handlers.push(handler);
  };

  epics.forEach((epic) => {
    const source = epic(action$, store.getState);
    source(0, (t: number, d: any) => {
      if (t === 1 && d && (d.type || Array.isArray(d))) {
        Array.isArray(d)
          ? d.forEach((a) => store.dispatch(a))
          : store.dispatch(d);
      }
    });
  });

  return (next: Function) => (action: any) => {
    const result = next(action);
    handlers.forEach((handler) => handler(action));
    return result;
  };
};
