export default [
  {
    type: 'h1',
    text: 'New Waterfall House',
    customCss: '',
  },
  {
    type: 'section',
    text: 'Dieses loftartige Apartment wurde in mondernen dunklen Tönen gehalten, sodass es Ihnen möglich ist mit farbigen Akzenten echte Highlights zu schaffen. Bereits der Eingangsbereich mit Flur ist grossräumig gestaltet und bietet eine hervorragende Möglichkeit, seine Gäste zu begrüssen, als auch zum Aufstellen verschiedenster Möbelstücke zum Verstauen von Schuhe und Jacken.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/new-waterfall-house/Eingangsbereich.jpg',
      'img/interior/new-waterfall-house/Treppe1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Wunderschöne Treppe bla bla bla.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/new-waterfall-house/Treppe1.jpg',
      'img/interior/new-waterfall-house/Treppe2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Eine offene Küche mit hochwertigen Küchengeräte lädt zum gemeinsamen Kochen mit Freunden ein. Die großen Küchenschränke bieten viel Stauraum für all Ihre Küchenuntensilien und Vorräte. Schaffen Sie einen Essbereich, in dem Sie zu jeder Zeit gutes Essen genießen können.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/new-waterfall-house/Kueche.jpg',
      'img/interior/new-waterfall-house/Essbereich.jpg',
     ],
    customCss: '',
  },

  {
    type: 'section',
    text: 'Dieser geräumige Wohnbereich lässt sich in einen Ort der Erholung verwandeln. Gestalten Sie sich eine Leseecke und einen gemütlichen Fernsehbereich. Setzen Sie farbige Akzente mit Teppichen oder kleinen Dekogegenständen.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/new-waterfall-house/Wohnbereich.jpg',
      'img/interior/new-waterfall-house/Wohnbereich1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Das kuschlige Schlafzimmer lädt zum Träumen ein und verfügt über einen begehbaren Kleiderschrank.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/new-waterfall-house/Schlafzimmer.jpg',
      'img/interior/new-waterfall-house/Schlafzimmer1.jpg',
      'img/interior/new-waterfall-house/Kleiderschrank.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'An das Schlafzimmer ist das Badezimmer mit einer großzügigen Badewanne und einer Dusche angeschlossen, in dem Sie sich nach einem anstrengenden Tag entspannen können.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/new-waterfall-house/Badezimmer.jpg',
      'img/interior/new-waterfall-house/Badezimmer1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Komplettiert wird dieses geräumige Apartment durch ein Arbeitszimmer. Gestalten Sie sich ein Arbeitszimmer nach Ihren Bedürfnissen oder einen Rückzugsort für Ihre Gäste.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/new-waterfall-house/Arbeitszimmer.jpg',
      'img/interior/new-waterfall-house/Arbeitszimmer1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Damit sind wir am Ende der Besichtung. Diese moderne Architektur hat natürlich ihren Preis. Dieser lohnt sich aber vollkommen, denn ihr Traum kann hier wahr werden. Ihnen steht nicht nur eine grösse Fläche zur Verfügung, sondern durch den guten Schnitt der Wohnung auch viele, viele Möglichkeiten für Ihre Inneneinrichtung.',
    customCss: '',
  },
];
