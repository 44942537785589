import { pipe, set, lensPath, prop, reduce, toPairs, map } from 'ramda';
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import { css } from '@emotion/react';
import { Pagination } from '@mui/material';
import { translate } from '../../../utils/translate';
import { CompanyData, CompanyStorageData, StorageItemData } from './types';
import { isProductionRunning } from './utils';
import Headline from '../../Headline';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

type StorageType = {
  company: CompanyData;
  fetchStorage: (id: string, page: number, search: string) => void;
  storage: CompanyStorageData | undefined;
  saveStoragePrices: (id: string, items: any[]) => void;
  title: string;
};

export const Storage = ({
  company,
  fetchStorage,
  storage,
  saveStoragePrices,
  title,
}: StorageType) => {
  const [search, setSearch] = useState('');
  const [prices, setPrices] = useState<{ [id: string]: number }>({});

  useEffect(() => {
    fetchStorage(company._id, 1, '');
  }, [company._id]);

  useEffect(() => {
    if (storage && storage.hasPrice) {
      pipe(
        reduce((old, entry: StorageItemData) => {
          return set(lensPath([entry._id]), entry.price, old);
        }, {}),
        setPrices
      )(storage.list);
    }
  }, [storage]);

  const executeSearch = () => {
    fetchStorage(company._id, 1, search);
  };

  const handleChangePage = (event: any, newPage: number) => {
    fetchStorage(company._id, newPage, search);
  };

  const updatePrice = (_id: string) => (ev: any) => {
    setPrices({
      ...prices,
      [_id]: ev.target.value,
    });
  };

  const savePrices = () => {
    const res = pipe(
      toPairs,
      map(([_id, price]: any[]) => ({
        _id,
        price: parseFloat(price),
      }))
    )(prices);
    saveStoragePrices(company._id, res);
  };

  const onClearSearch = () => {
    fetchStorage(company._id, 1, '');
    setSearch('');
  };
  const onSearchInput = (key: string) => {
    console.log(key);
    if (key === 'Enter') {
      executeSearch();
    }
  };

  const isProdRunning = isProductionRunning(company);

  return storage ? (
    <div>
      <Headline>{title}</Headline>

      <div>
        <TextField
          css={inputStyle}
          type="text"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyPress={(e) => onSearchInput(e.key)}
          placeholder="Suche"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: search !== '' && (
              <InputAdornment
                position="end"
                css={{ cursor: 'pointer' }}
                onClick={onClearSearch}
              >
                <ClearIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Gegenstand</TableCell>
            <TableCell>Anzahl</TableCell>
            {storage.hasPrice && <TableCell>Preis</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {storage.list.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>{translate(entry.hash)}</TableCell>
              <TableCell>{entry.amount}</TableCell>
              {storage.hasPrice && (
                <TableCell>
                  <TextField
                    placeholder="Preis"
                    type="number"
                    value={prices[entry._id]}
                    onChange={updatePrice(entry._id)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {storage.hasPrice && (
        <Button
          onClick={() => !isProdRunning && savePrices()}
          disabled={isProdRunning}
        >
          Preise speichern
        </Button>
      )}
      <Pagination
        page={storage.page}
        count={storage.pages}
        onChange={handleChangePage}
        variant="outlined"
        shape="rounded"
      />
    </div>
  ) : null;
};
