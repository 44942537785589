import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  startAdminPlayerKick,
  startAdminPlayerMsg,
  startAdminPlayerNameChange,
  startAdminPlayerPwChange,
  startAdminSecondchance,
} from '../../../../actions';
import { detailContainerStyle } from '../styles';
import { useAuthentication } from '../../../../utils/authentication';

type ActionsType = {
  _id: string;
  isOnline: boolean;
};
export const Actions = ({ _id, isOnline }: ActionsType) => {
  const [hasRight] = useAuthentication();
  const [active, setActive] = useState('');
  const [newName, setNewName] = useState('');
  const [newPw, setNewPw] = useState('');
  const [newPwRe, setNewPwRe] = useState('');
  const [msg, setMsg] = useState('');
  const [kickReason, setKickReason] = useState('');
  const dispatch = useDispatch();

  const renderApplyAndCancel = (
    applyFn: () => void,
    isApplyDisabled = false
  ) => (
    <div>
      <Button
        variant="outlined"
        disabled={isApplyDisabled}
        onClick={() => {
          if (!isApplyDisabled) {
            applyFn();
            setActive('');
          }
        }}
      >
        Anwenden
      </Button>
      <Button variant="outlined" onClick={() => setActive('')}>
        Abbrechen
      </Button>
    </div>
  );

  const changeName = () => {
    dispatch(startAdminPlayerNameChange(_id, newName));
  };
  const changePw = () => {
    dispatch(startAdminPlayerPwChange(_id, newPw));
  };
  const sendMsg = () => {
    dispatch(startAdminPlayerMsg(_id, msg));
  };
  const kickPlayer = () => {
    dispatch(startAdminPlayerKick(_id, kickReason));
  };
  const secondchancePlayer = () => {
    dispatch(startAdminSecondchance(_id));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Aktionen</h2>

      <div>
        <Button
          variant="outlined"
          disabled={isOnline}
          onClick={() => {
            if (!isOnline) {
              setActive('name');
              setNewName('');
            }
          }}
        >
          Spielernamen ändern
        </Button>
        <Button
          variant="outlined"
          disabled={isOnline}
          onClick={() => {
            if (!isOnline) {
              setActive('password');
              setNewPw('');
              setNewPwRe('');
            }
          }}
        >
          Passwort ändern
        </Button>
        <Button
          variant="outlined"
          disabled={!isOnline}
          onClick={() => {
            if (isOnline) {
              setActive('msg');
              setMsg('');
            }
          }}
        >
          Nachricht senden
        </Button>
        <Button
          variant="outlined"
          disabled={!isOnline}
          onClick={() => {
            if (isOnline) {
              setActive('kick');
              setKickReason('');
            }
          }}
        >
          Spieler kicken
        </Button>
        {hasRight('admin.player.secondchance') && (
          <Button
            variant="outlined"
            disabled={isOnline}
            onClick={secondchancePlayer}
          >
            Secondchance
          </Button>
        )}
      </div>

      {active === 'name' && (
        <div>
          <h2>Namen ändern</h2>
          <TextField
            label="Neuer Spielername"
            type="text"
            value={newName}
            onChange={(ev: any) => setNewName(ev.target.value)}
          />
          {renderApplyAndCancel(changeName)}
        </div>
      )}

      {active === 'password' && (
        <div>
          <h2>Passwort resetten</h2>
          <div>
            <TextField
              label="Neues Passwort"
              type="password"
              value={newPw}
              onChange={(ev: any) => setNewPw(ev.target.value)}
            />
          </div>
          <div>
            <TextField
              label="Wiederholen"
              type="password"
              value={newPwRe}
              onChange={(ev: any) => setNewPwRe(ev.target.value)}
            />
          </div>
          {renderApplyAndCancel(
            changePw,
            newPw.length < 8 || newPw !== newPwRe
          )}
        </div>
      )}

      {active === 'msg' && (
        <div>
          <h2>Nachricht an den Spieler senden</h2>
          <TextField
            label="Nachricht"
            type="text"
            value={msg}
            onChange={(ev: any) => setMsg(ev.target.value)}
          />
          {renderApplyAndCancel(sendMsg)}
        </div>
      )}

      {active === 'kick' && (
        <div>
          <h2>Spieler kicken</h2>
          <TextField
            label="Nachricht"
            type="text"
            value={kickReason}
            onChange={(ev: any) => setKickReason(ev.target.value)}
          />
          {renderApplyAndCancel(kickPlayer)}
        </div>
      )}
    </div>
  );
};
