import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import {
  lspdOldFilesP,
  lspdOldFilesSearchP,
} from '../../../redux/reducer/apps/lspd';
import { fetchLspdOldFiles, setLspdOldFilesSearch } from '../../../actions';
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import { OldFilesListEntryData } from './types';
import { css } from '@emotion/react';
import { DateTime } from 'luxon';

const containerStyle = css`
  margin-top: 3vh;
`;
const cursorStyle = css`
  cursor: pointer;
`;

type OldFilesType = {
  openOldFile: (_id: string, label: string) => void;
};
export const OldFiles = ({ openOldFile }: OldFilesType) => {
  const dispatch = useDispatch();
  const search = useSelector(pathOr('', lspdOldFilesSearchP));
  const setSearch = (search: string) => {
    dispatch(setLspdOldFilesSearch(search));
  };
  const data = useSelector<any, null | OldFilesListEntryData[]>(
    pathOr(null, lspdOldFilesP)
  );

  const fetchData = (skip: number) => {
    dispatch(fetchLspdOldFiles(search, skip));
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  return (
    <div>
      <h1>Alte Akten</h1>
      <div>
        <TextField
          label="Suche"
          type="text"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />
        <Button variant="outlined" onClick={() => fetchData(0)}>
          Suchen
        </Button>
      </div>
      {data && (
        <div css={containerStyle}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Datum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((entry) => (
                <TableRow
                  key={entry._id}
                  onClick={() => openOldFile(entry._id, entry.subject)}
                  css={cursorStyle}
                >
                  <TableCell>
                    {entry.closed ? '(CLOSED)' : ''} {entry.subject}
                  </TableCell>
                  <TableCell>
                    {DateTime.fromJSDate(new Date(entry.date))
                      .setLocale('de')
                      .toFormat('dd.LL.yy, HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="outlined"
                    onClick={() => fetchData(data.length)}
                  >
                    Weitere laden
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
