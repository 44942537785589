import * as type from '../store/types';

export function startFetchUnreadActivity() {
  return {
    type: type.ACTIVITY_UNREAD_FETCH_START,
  };
}

export function markActivityRead() {
  return {
    type: type.ACTIVITY_MARKREAD_FETCH_START,
  };
}

export function startFetchNewActivity(offset: number) {
  return {
    type: type.ACTIVITY_NEW_FETCH_START,
    offset,
  };
}
