import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC } from '../utils/callbag';
import { authFetch } from '../utils/fetch';

export const onActivityUnreadFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ACTIVITY_UNREAD_FETCH_START),
    mapPromiseC(() => {
      return authFetch('/leogram/activity/unread')
        .then((res) => {
          return res.json().then((data) => ({
            type: types.ACTIVITY_UNREAD_FETCH_SUCCESS,
            ...data,
          }));
        })
        .catch(() => {});
    })
  );

export const onActivityMarkreadFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ACTIVITY_MARKREAD_FETCH_START),
    mapPromiseC(() => {
      return authFetch('/leogram/activity/markread')
        .then(() => {})
        .catch(() => {});
    })
  );

export const onActivityNewFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ACTIVITY_NEW_FETCH_START),
    mapPromiseC(({ offset }: any) => {
      return authFetch(`/leogram/activity/new?skip=${offset}`)
        .then((res) => {
          return res.json().then((data) => ({
            type: types.ACTIVITY_NEW_FETCH_SUCCESS,
            offset,
            ...data,
          }));
        })
        .catch(() => {});
    })
  );
