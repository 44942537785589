import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {
  setAdminApartmentSearch,
  startAdminApartmentSearch,
} from '../../../actions';
import {
  appsAdminApartmentSearchP,
  appsAdminApartmentSearchResultsP,
} from '../../../redux/reducer/apps/admin/apartmentssearch';
import { ApartmentSearchEntry } from './types/apartmentsearch';

type ApartmentSearchType = {
  openApartment: (_id: string, label: string) => void;
};
export const ApartmentSearch = ({ openApartment }: ApartmentSearchType) => {
  const dispatch = useDispatch();
  const search = useSelector<any, string>(
    pathOr('', appsAdminApartmentSearchP)
  );
  const results = useSelector<any, ApartmentSearchEntry[]>(
    pathOr([], appsAdminApartmentSearchResultsP)
  );

  const updateSearch = (ev: any) => {
    dispatch(setAdminApartmentSearch(ev.target.value));
  };
  const executeSearch = () => {
    dispatch(startAdminApartmentSearch(search));
  };

  return (
    <div>
      <h1>Apartmentsuche</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={updateSearch}
      />

      <Button variant="outlined" onClick={executeSearch}>
        Suche starten
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Hash</TableCell>
            <TableCell>Besitzer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((entry) => (
            <TableRow
              key={entry._id}
              onClick={() => openApartment(entry._id, entry.hash)}
              css={{ cursor: 'pointer' }}
            >
              <TableCell>{entry.hash}</TableCell>
              <TableCell>{entry.owner?.name}</TableCell>
            </TableRow>
          ))}
          {results.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>Keine Ergebnisse</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
