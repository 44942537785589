import React from 'react';
import { connect } from 'react-redux';
import { path, pathOr, pipe } from 'ramda';
import { Fraction } from '../../components/apps/Fraction';
import {
  startFractionMembersFetch,
  startFractionMembersFire,
  startFractionMembersRankChange,
  startFractionPlayerStatusFetch,
  startFractionSalaryFetch,
} from '../../actions/fraction';
import {
  appsFractionMembersP,
  appsFractionPlayerStatusP,
  appsFractionSalaryP,
} from '../../redux/reducer/apps/fraction';
import { darkP } from '../../redux/reducer/common';

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
    playerStatus: pathOr(undefined, appsFractionPlayerStatusP, state),
    members: pathOr([], appsFractionMembersP, state),
    salary: pathOr([], appsFractionSalaryP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchPlayerStatus: pipe(startFractionPlayerStatusFetch, dispatch),
  fetchMembers: pipe(startFractionMembersFetch, dispatch),
  changeRank: pipe(startFractionMembersRankChange, dispatch),
  fire: pipe(startFractionMembersFire, dispatch),
  fetchSalary: pipe(startFractionSalaryFetch, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fraction);
