import { set, lensPath, pipe } from 'ramda';

import * as types from '../types';

export const routerUrlP = ['leogram', 'router', 'url'];
export const routerUrlL = lensPath(routerUrlP);
export const routerParamsP = ['leogram', 'router', 'params'];
export const routerParamsL = lensPath(routerParamsP);

const routerGoto = (state: any, { url, params }: any) => {
  return pipe(set(routerUrlL, url), set(routerParamsL, params))(state);
};

export const routerReducerTree = {
  [types.LEOGRAM_ROUTER_PATH_SET]: [routerGoto],
};
