import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { fetchListValues, notifyError, notifySuccess } from '../../utils';

export const onFetchAdminTickets = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_TICKETS_FETCH_START),
    mapPromiseC(({ query, offset }: any) => {
      return authFetchJson(`/admin/tickets/list/?q=${query}&offset=${offset}`)
        .then((data) => {
          return {
            type: types.ADMIN_TICKETS_FETCH_SUCCESS,
            data,
            offset,
          };
        })
        .catch(() => ({
          type: types.ADMIN_TICKETS_FETCH_FAILED,
        }));
    })
  );

export const onFetchAdminTicketDelete = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_TICKET_DELETE_START),
    mapPromiseC(({ _id, reason, search, offset }: any) => {
      return authFetchPost(`/admin/tickets/delete/${_id}`, {
        deletereason: reason,
      })
        .then(() => [
          notifySuccess('Erfolgreich gelöscht'),
          {
            type: types.ADMIN_TICKETS_FETCH_START,
            query: search,
            offset,
          },
        ])
        .catch(() => notifyError('Löschen fehlgeschlagen'));
    })
  );
