import { Button, TextField } from '@mui/material';
import { lensPath, pathOr, set, pipe, append, uniq, without } from 'ramda';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  startAdminAllRightsFetch,
  startAdminRoleAdd,
  startAdminRoleDelete,
  startAdminRoleEdit,
  startAdminRolesFetch,
} from '../../../actions';
import {
  appsAdminAllRightsP,
  appsAdminRolesP,
} from '../../../redux/reducer/apps/admin/roles';
import { AdminRight, AdminRole } from './types/roles';

const NEW_ROLE_TEMPLATE: AdminRole = {
  key: 'Neue Benutzergruppe',
  rights: [],
};

type RolesCmpType = {};

export const Roles = ({}: RolesCmpType) => {
  const [role, setRole] = useState<AdminRole | undefined>();
  const [isNewRole, setNewRole] = useState(false);
  const dispatch = useDispatch();
  const roles = useSelector<any, AdminRole[]>(pathOr([], appsAdminRolesP));
  const allRights = useSelector<any, AdminRight[]>(
    pathOr([], appsAdminAllRightsP)
  );

  useEffect(() => {
    dispatch(startAdminRolesFetch());
    dispatch(startAdminAllRightsFetch());
  }, []);

  const updateRoleName = (ev: any) => {
    setRole(set(lensPath(['key']), ev.target.value, role));
  };

  const updateRoleRight = (right: string, isEnabled: boolean) => {
    const newRights = isEnabled
      ? pipe(append(right), uniq)(role?.rights ?? [])
      : without([right], role?.rights ?? []);
    setRole(set(lensPath(['rights']), newRights, role));
  };

  const save = () => {
    if (!role) {
      return;
    }

    if (isNewRole) {
      dispatch(startAdminRoleAdd(role));
    } else {
      dispatch(startAdminRoleEdit(role));
    }
    setRole(undefined);
  };

  const deleteRole = (role: AdminRole) => {
    dispatch(startAdminRoleDelete(role));
    setRole(undefined);
  };

  return (
    <div>
      <h1>Benutzerrollen</h1>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Bearbeiten</TableCell>
            <TableCell>Löschen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.map((role) => (
            <TableRow key={role._id}>
              <TableCell>{role.key}</TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setRole(role);
                    setNewRole(false);
                  }}
                >
                  Bearbeiten
                </Button>
              </TableCell>
              <TableCell>
                {role.key !== 'superuser' && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      deleteRole(role);
                    }}
                  >
                    <FaTrash />
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={3}>
              <Button
                variant="outlined"
                onClick={() => {
                  setRole(NEW_ROLE_TEMPLATE);
                  setNewRole(true);
                }}
              >
                Neue Rolle erstellen
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {role && (
        <>
          <h1>
            {role.key} {isNewRole ? 'erstellen' : 'bearbeiten'}
          </h1>

          <TextField
            label="Rollenname"
            type="text"
            value={role.key}
            onChange={updateRoleName}
          />

          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Recht</TableCell>
                <TableCell>Aktiv</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allRights.map((right, index) => {
                const isChecked = role.rights.indexOf(right.key) > -1;
                return (
                  <TableRow key={right.key}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{right.key}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={isChecked}
                        onChange={() => updateRoleRight(right.key, !isChecked)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Button variant="outlined" onClick={save}>
            Speichern
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setRole(undefined);
            }}
          >
            Abbrechen
          </Button>
        </>
      )}
    </div>
  );
};
