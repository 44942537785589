export const FRACTION_PLAYER_STATUS_FETCH_START =
  'FRACTION_PLAYER_STATUS_FETCH_START';
export const FRACTION_PLAYER_STATUS_FETCH_SUCCESS =
  'FRACTION_PLAYER_STATUS_FETCH_SUCCESS';
export const FRACTION_PLAYER_STATUS_FETCH_FAILED =
  'FRACTION_PLAYER_STATUS_FETCH_FAILED';

export const FRACTION_EMPLOYEES_FETCH_START = 'FRACTION_EMPLOYEES_FETCH_START';
export const FRACTION_EMPLOYEES_FETCH_SUCCESS =
  'FRACTION_EMPLOYEES_FETCH_SUCCESS';
export const FRACTION_EMPLOYEES_FETCH_FAILED =
  'FRACTION_EMPLOYEES_FETCH_FAILED';

export const FRACTION_EMPLOYEES_RANK_CHANGE_START =
  'FRACTION_EMPLOYEES_RANK_CHANGE_START';
export const FRACTION_EMPLOYEES_RANK_CHANGE_SUCCESS =
  'FRACTION_EMPLOYEES_RANK_CHANGE_SUCCESS';
export const FRACTION_EMPLOYEES_RANK_CHANGE_FAILED =
  'FRACTION_EMPLOYEES_RANK_CHANGE_FAILED';

export const FRACTION_EMPLOYEES_FIRE_START = 'FRACTION_EMPLOYEES_FIRE_START';
export const FRACTION_EMPLOYEES_FIRE_SUCCESS =
  'FRACTION_EMPLOYEES_FIRE_SUCCESS';
export const FRACTION_EMPLOYEES_FIRE_FAILED = 'FRACTION_EMPLOYEES_FIRE_FAILED';

export const FRACTION_SALARY_FETCH_START = 'FRACTION_SALARY_FETCH_START';
export const FRACTION_SALARY_FETCH_SUCCESS = 'FRACTION_SALARY_FETCH_SUCCESS';
export const FRACTION_SALARY_FETCH_FAILED = 'FRACTION_SALARY_FETCH_FAILED';

export const FRACTION_EMPLOYEES_INVITE_START =
  'FRACTION_EMPLOYEES_INVITE_START';

export const FRACTION_DUTYLIST_SEARCH_SET = 'FRACTION_DUTYLIST_SEARCH_SET';
export const FRACTION_DUTYLIST_FETCH_START = 'FRACTION_DUTYLIST_FETCH_START';
export const FRACTION_DUTYLIST_FETCH_SUCCESS =
  'FRACTION_DUTYLIST_FETCH_SUCCESS';
