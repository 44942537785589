import { path, pipe } from 'ramda';
import { connect } from 'react-redux';
import { startDislike, startRouterGoto } from '../actions';
import { startAdLike, startFetchAds } from '../actions/ads';
import { Dashboard } from '../components/Dashboard';
import { adsP } from '../store/reducer/ads';
import { postsP, isPostsLoadingP } from '../store/reducer/posts';

const mapStateToProps = (state: any) => {
  return {
    posts: path(adsP, state),
    isLoading: false,
    title: '#werbung',
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchPosts: pipe(startFetchAds, dispatch),
  goto: pipe(startRouterGoto, dispatch),
  like: pipe(startAdLike, dispatch),
  dislike: pipe(startDislike, dispatch),
  fetchMorePosts: pipe(startFetchAds, dispatch),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
