import React from 'react';
import { css } from '@emotion/react';

const containerStyle = css`
  display: inline-block;
`;

export const Inline = ({ text, customCss = '' }: any) => {
  return (
    <span
      css={[
        containerStyle,
        css`
          ${customCss}
        `,
      ]}
    >
      {text}
    </span>
  );
};
