import House from '@mui/icons-material/House';
import Star from '@mui/icons-material/Star';
import LocalHospital from '@mui/icons-material/LocalHospital';
import Business from '@mui/icons-material/Business';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import Gavel from '@mui/icons-material/Gavel';
import EventIcon from '@mui/icons-material/Event';
import CarCrashIcon from '@mui/icons-material/CarCrash';

import {
  Blue,
  Green,
  Pink,
  Red,
  Teal,
  OrangeLight,
  YellowDark,
  BrownDark,
} from '../../../../configs/styles/colors';
export const ads = [
  // {
  //   title: 'LSPD',
  //   url: 'lspd.red',
  //   icon: Star,
  //   bgColor: Blue,
  //   color: '#fff',
  // },
  // {
  //   title: 'DMV',
  //   url: 'dmv.red',
  //   icon: DirectionsCar,
  //   bgColor: Teal,
  //   color: '#fff',
  // },
  // {
  //   title: 'DOJ',
  //   url: 'doj.red',
  //   icon: Gavel,
  //   bgColor: Green,
  //   color: '#fff',
  // },
  // {
  //   title: 'DOC',
  //   url: 'doc.red',
  //   icon: Business,
  //   bgColor: Pink,
  //   color: '#fff',
  // },
  // {
  //   title: 'LSMD',
  //   url: 'lsmd.red',
  //   icon: LocalHospital,
  //   bgColor: Red,
  //   color: '#fff',
  // },
  {
    title: 'Interior',
    url: 'lioninterior.red',
    icon: House,
    bgColor: '#003300',
    color: '#fff',
  },
  // {
  //   title: 'Events',
  //   url: 'events.red',
  //   icon: EventIcon,
  //   bgColor: OrangeLight,
  //   color: '#fff',
  // },
  {
    title: 'Gesetze',
    url: 'law.red',
    icon: EventIcon,
    bgColor: YellowDark,
    color: '#fff',
  },
  {
    title: 'Fahrzeuge',
    url: 'cars.red',
    icon: CarCrashIcon,
    bgColor: BrownDark,
    color: '#fff',
  },
];
