import { concat, find, pathOr, pipe, prop, uniqBy } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import {
  fetchFolderGroupsAsAdmin,
  fetchFolderGroupsAsMember,
  pollPlayerOnDuty,
} from '../../../actions';
import {
  folderGroupsAdminP,
  folderGroupsMemberP,
  playerOnDutyP,
} from '../../../redux/reducer/apps/folder-groups';
import GroupIcon from '@mui/icons-material/Group';
import { Sidebar } from '../../sidebars/Sidebar';
import { Groups } from './Groups';
import { AdminGroupData, MemberGroupData } from './group-types';
import { Folders } from './Folders';
import InfoIcon from '@mui/icons-material/Info';
import SourceIcon from '@mui/icons-material/Source';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { Information } from './Information';

const missingDutyContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2vh;
`;

export const FolderApp = () => {
  const dispatch = useDispatch();
  // const isOnDuty = useSelector(pathOr(false, playerOnDutyP));
  const [selectedMenu, setSelectedMenu] = useState('information');

  const groupsAsMember = useSelector<any, MemberGroupData[]>(
    pathOr([], folderGroupsMemberP)
  );
  const groupsAsAdmin = useSelector<any, AdminGroupData[]>(
    pathOr([], folderGroupsAdminP)
  );

  useEffect(() => {
    dispatch(fetchFolderGroupsAsMember());
    dispatch(fetchFolderGroupsAsAdmin());

    // dispatch(pollPlayerOnDuty());
    // const int = setInterval(() => {
    //   dispatch(pollPlayerOnDuty());
    // }, 20000);
    // return () => {
    //   clearInterval(int);
    // };
  }, []);

  const groups = pipe<any[], any[], any[]>(
    concat(groupsAsMember),
    uniqBy(prop('_id'))
  )(groupsAsAdmin);

  const menu = [
    {
      id: 'information',
      title: 'Information',
      icon: <InfoIcon />,
    },
    {
      id: 'groups',
      title: 'Gruppen',
      icon: <GroupIcon />,
    },
    ...groups.map((group) => ({
      id: `group-${group._id}`,
      title: group.label,
      icon: group.factory ? <SnippetFolderIcon /> : <SourceIcon />,
    })),
  ];

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      {/* {isOnDuty ? ( */}
      <>
        <div className="flex-none w-44">
          <Sidebar
            items={menu}
            selected={selectedMenu}
            select={setSelectedMenu}
          />
        </div>
        <div
          className="flex-grow"
          css={css`
            min-width: 0;
          `}
        >
          <div className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6">
            {selectedMenu === 'information' && <Information />}
            {selectedMenu === 'groups' && (
              <Groups
                groupsAsMember={groupsAsMember}
                groupsAsAdmin={groupsAsAdmin}
                goTo={setSelectedMenu}
              />
            )}
            {selectedMenu.startsWith('group-') && (
              <Folders
                groupId={
                  find(
                    (x: any) => x._id === selectedMenu.replace('group-', ''),
                    groups
                  )._id
                }
                groups={groups}
              />
            )}
          </div>
        </div>
      </>
      {/* ) : (
        <div
          css={missingDutyContainerStyle}
          className="w-full h-full bg-gray-50 text-gray-700"
        >
          Nur im Dienst verfügbar
        </div>
      )} */}
    </div>
  );
};
