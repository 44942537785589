import React, { useState } from 'react';
import { Sidebar } from '../../sidebars/Sidebar';
import SendIcon from '@mui/icons-material/Send';
import OutboxIcon from '@mui/icons-material/Outbox';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Send } from './Send';
import { List } from './List';
import {
  startInboxFetch,
  startOutboxFetch,
  startTrashFetch,
} from '../../../actions';
import {
  appsMailInboxP,
  appsMailOutboxP,
  appsMailTrashP,
} from '../../../redux/reducer/apps/mail';

const menu = [
  {
    id: 'inbox',
    title: 'Eingang',
    icon: <MoveToInboxIcon />,
  },
  {
    id: 'outbox',
    title: 'Ausgang',
    icon: <OutboxIcon />,
  },
  {
    id: 'trash',
    title: 'Papierkorb',
    icon: <DeleteIcon />,
  },
  {
    id: 'send',
    title: 'Mail senden',
    icon: <SendIcon />,
  },
];

type MailType = {};

export const Mail = ({}: MailType) => {
  const [selectedMenu, setSelectedMenu] = useState(menu[0].id);
  const [sendTo, setSendTo] = useState<string[]>(['']);
  const [sendSubject, setSendSubject] = useState('');
  const [sendBody, setSendBody] = useState(undefined);

  const switchToSend = (receivers: string[], subject: string, body: any) => {
    setSendTo(receivers);
    setSendSubject(subject);
    setSendBody(body);
    setSelectedMenu('send');
  };

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar
          items={menu}
          selected={selectedMenu}
          select={setSelectedMenu}
        />
      </div>
      <div className="flex-grow">
        <div className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto py-6">
          {selectedMenu === 'send' && (
            <Send
              sendTo={sendTo}
              setSendTo={setSendTo}
              sendSubject={sendSubject}
              setSendSubject={setSendSubject}
              sendBody={sendBody}
              setSendBody={setSendBody}
              setMenu={setSelectedMenu}
            />
          )}
          {selectedMenu === 'inbox' && (
            <List
              headline="Posteingang"
              fetchAction={startInboxFetch}
              reduxDataP={appsMailInboxP}
              canDelete={true}
              canAnswer={true}
              canSetReadStatus={true}
              switchToSend={switchToSend}
            />
          )}
          {selectedMenu === 'outbox' && (
            <List
              headline="Postausgang"
              fetchAction={startOutboxFetch}
              reduxDataP={appsMailOutboxP}
              canDelete={false}
              canAnswer={false}
            />
          )}
          {selectedMenu === 'trash' && (
            <List
              headline="Papierkorb"
              fetchAction={startTrashFetch}
              reduxDataP={appsMailTrashP}
              canDelete={false}
              canAnswer={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};
