import { css } from '@emotion/react';
import { TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CloseIcon from '@mui/icons-material/ClearRounded';
import Headline from '../../Headline';
import { CompanyData } from './types';
import { isProductionRunning } from './utils';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

const buttonStyle = css`
  display: flex !important;
`;

type OverviewType = {
  company: CompanyData;
  changeName: (id: string, name: string) => void;
  changeAdd: (id: string, adLine: string) => void;
};

export const Overview = ({ company, changeName, changeAdd }: OverviewType) => {
  const [editLabel, setEditLabel] = useState(false);
  const [newLabel, setNewLabel] = useState('');

  const [editAdd, setEditAdd] = useState(false);
  const [newAdd, setNewAdd] = useState('');

  const openLabelChange = () => {
    setNewLabel(company.label);
    setEditLabel(true);
  };

  const finishNameChange = () => {
    changeName(company._id, newLabel);
    setEditLabel(false);
  };

  const openAddChange = () => {
    setNewAdd(company.adLine);
    setEditAdd(true);
  };

  const finishAddChange = () => {
    changeAdd(company._id, newAdd);
    setEditAdd(false);
  };

  const isProdRunning = isProductionRunning(company);

  return (
    <div>
      <div>
        <Headline>{company.label}</Headline>
        <h2>{company.adLine}</h2>
      </div>
      <div className="py-3">
        {company.playerRights.writeBusinessInformation &&
          !isProdRunning &&
          !editLabel && (
            <Button
              css={buttonStyle}
              onClick={openLabelChange}
              startIcon={<FaEdit />}
            >
              Firmenname anpassen
            </Button>
          )}
        {editLabel && (
          <>
            <Headline>Firmenname ändern</Headline>
            <div>
              <span>Neuer Name</span>
              <TextField
                css={inputStyle}
                type="text"
                value={newLabel}
                onChange={(ev) => setNewLabel(ev.target.value)}
              />
            </div>
            <Button startIcon={<SaveIcon />} onClick={finishNameChange}>
              Ändern
            </Button>

            <Button
              startIcon={<CloseIcon />}
              onClick={() => setEditLabel(false)}
            >
              Schliessen
            </Button>
          </>
        )}
      </div>
      <div>
        {company.playerRights.writeBusinessInformation &&
          !isProdRunning &&
          !editAdd && (
            <Button
              css={buttonStyle}
              onClick={openAddChange}
              startIcon={<FaEdit />}
            >
              Werbetext anpassen
            </Button>
          )}
        {editAdd && (
          <>
            <Headline>Werbetext ändern</Headline>
            <div>
              <span>Neuer Werbetext</span>
              <TextField
                css={inputStyle}
                type="text"
                value={newAdd}
                onChange={(ev) => setNewAdd(ev.target.value)}
              />
            </div>
            <Button startIcon={<SaveIcon />} onClick={finishAddChange}>
              Ändern
            </Button>
            <Button startIcon={<CloseIcon />} onClick={() => setEditAdd(false)}>
              Schliessen
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
