import React, { useState } from 'react';
import { FaPaw, FaVuejs } from 'react-icons/fa';
import { Sidebar } from '../../sidebars/Sidebar';
import { Assign } from './Assign';
import { Overview } from './Overview';
import { Results, TestsuiteResultEntry } from './Results';
import { Suite, TestSuiteType } from './Suite';

const menu = [
  {
    id: 'overview',
    title: 'Übersicht',
    icon: <FaPaw />,
  },
  {
    id: 'assign',
    title: 'Zuweisen',
    icon: <FaPaw />,
  },
  {
    id: 'results',
    title: 'Ergebnisse',
    icon: <FaPaw />,
  },
];

type TestsuiteType = {
  fetchCatalogue: () => void;
  catalogue: {
    hash: string;
    label: string;
    amountOfMultipleChoiceQuestions: number;
    amountOfFreeTextQuestions: number;
    amountAvailableMultipleChoice: number;
    amountAvailableFreeText: number;
  }[];
  assign: (hash: string, name: string) => void;
  fetchAssigned: () => void;
  assigned: {
    createdDate: string;
    id: string;
    label: string;
    submitted: boolean;
  }[];
  fetchTestsuite: (id: string) => void;
  current?: TestSuiteType;
  sendTestsuite: (
    id: string,
    multipleChoiceAnswers: any[],
    freeTextAnswers: any[]
  ) => void;
  fetchResults: (playerName?: string) => void;
  results: TestsuiteResultEntry[];
  fetchResult: (id: string) => void;
};

export const Testsuite = ({
  fetchCatalogue,
  catalogue,
  assign,
  fetchAssigned,
  assigned,
  fetchTestsuite,
  current,
  sendTestsuite,
  fetchResults,
  results,
  fetchResult,
}: TestsuiteType) => {
  const [selectedMenu, setSelectedMenu] = useState(menu[0].id);

  const [showSuite, setShowSuite] = useState(false);
  const [isExaminee, setIsExaminee] = useState(false);

  const runSuite = (id: string) => {
    fetchTestsuite(id);
    setSelectedMenu('');
    setIsExaminee(true);
    setShowSuite(true);
  };

  const selectMenu = (id: string) => {
    setSelectedMenu(id);
    setShowSuite(false);
  };

  const sendSuite = (
    id: string,
    multipleChoiceAnswers: any[],
    freeTextAnswers: any[]
  ) => {
    sendTestsuite(id, multipleChoiceAnswers, freeTextAnswers);
    setSelectedMenu('overview');
    setShowSuite(false);
  };

  const fetchResultHelper = (id: string) => {
    fetchResult(id);
    setIsExaminee(false);
    setShowSuite(true);
    setSelectedMenu('');
  };

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar items={menu} selected={selectedMenu} select={selectMenu} />
      </div>
      <div className="flex-grow">
        <div className="w-full h-full bg-gray-50 text-gray-700 overflow-auto py-6 px-6">
          {selectedMenu === 'overview' && (
            <Overview
              fetchAssigned={fetchAssigned}
              assigned={assigned}
              runSuite={runSuite}
            />
          )}
          {selectedMenu === 'assign' && (
            <Assign
              fetchCatalogue={fetchCatalogue}
              catalogue={catalogue}
              assign={assign}
            />
          )}
          {selectedMenu === 'results' && (
            <Results
              fetchResults={fetchResults}
              results={results}
              fetchResult={fetchResultHelper}
            />
          )}
          {showSuite && current && (
            <Suite
              testsuite={current}
              isExaminee={isExaminee}
              sendTestsuite={sendSuite}
            />
          )}
        </div>
      </div>
    </div>
  );
};
