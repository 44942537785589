import { toast } from 'react-toastify';
import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchPost } from '../../utils/fetch';

export const onFetchTestsuiteCatalogueStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_CATALOGUE_FETCH_START),
    mapPromiseC(() => {
      return authFetch('/testsuites/catalogue')
        .then((res) => {
          return res.json().then((data) => [
            {
              type: types.TESTSUITES_CATALOGUE_FETCH_SUCCESS,
              data,
            },
          ]);
        })
        .catch((res) => ({
          type: types.TESTSUITES_CATALOGUE_FETCH_FAILED,
        }));
    })
  );

export const onFetchTestsuiteCatalogueFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_CATALOGUE_FETCH_FAILED),
    mapC(() => {
      toast.error('Abfragen der Kataloge fehlgeschlagen');
    })
  );

export const onTestsuiteAssignStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_ASSIGN_START),
    mapPromiseC(({ hash, name }: any) => {
      return authFetchPost('/testsuites/assign', { hash, target: name })
        .then((res) => ({
          type: types.TESTSUITES_ASSIGN_SUCCESS,
          name,
        }))
        .catch((res) => ({
          type: types.TESTSUITES_ASSIGN_FAILED,
          name,
        }));
    })
  );

export const onTestsuiteAssignSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_ASSIGN_SUCCESS),
    mapC(({ name }: any) => {
      toast.success(`Fragebogen erfolgreich an ${name} zugewiesen`);
    })
  );

export const onTestsuiteAssignFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_ASSIGN_FAILED),
    mapC(({ name }: any) => {
      toast.error(`Fragebogen konnte nicht an ${name} zugewiesen werden`);
    })
  );

export const onFetchAssignedTestsuitesStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_LOAD_ASSIGNED_START),
    mapPromiseC(() => {
      return authFetch('/testsuites/assignments')
        .then((res) => {
          return res.json().then((data) => [
            {
              type: types.TESTSUITES_LOAD_ASSIGNED_SUCCESS,
              data,
            },
          ]);
        })
        .catch((res) => ({
          type: types.TESTSUITES_LOAD_ASSIGNED_FAILED,
        }));
    })
  );

export const onFetchAssignedTestsuitesFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_LOAD_ASSIGNED_FAILED),
    mapC(() => {
      toast.error(`Fehler beim Abfragen der zugewiesenen Fragebögen`);
    })
  );

export const onFetchTestsuiteStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_LOAD_SUITE_START),
    mapPromiseC(({ id }: any) => {
      return authFetch(`/testsuites/assignments/${id}`)
        .then((res) => {
          return res.json().then((data) => [
            {
              type: types.TESTSUITES_LOAD_SUITE_SUCCESS,
              data: { ...data, id },
            },
          ]);
        })
        .catch((res) => ({
          type: types.TESTSUITES_LOAD_SUITE_FAILED,
        }));
    })
  );

export const onSendTestsuiteStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_ANSWER_SEND_START),
    mapPromiseC(({ id, multipleChoiceAnswers, freeTextAnswers }: any) => {
      return authFetchPost(`/testsuites/assignments/${id}`, {
        multipleChoiceAnswers,
        freeTextAnswers,
      })
        .then((res) => ({
          type: types.TESTSUITES_ANSWER_SEND_SUCCESS,
        }))
        .catch((res) => ({
          type: types.TESTSUITES_ANSWER_SEND_FAILED,
        }));
    })
  );

export const onSendTestsuiteSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_ANSWER_SEND_SUCCESS),
    mapC(() => {
      toast.success(`Fragebogen erfolgreich abgeschickt`);
    })
  );

export const onFetchTestsuiteResultsStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_RESULTS_FETCH_START),
    mapPromiseC(({ playerName }: any) => {
      return authFetch(
        playerName ? `/testsuites/results/${playerName}` : '/testsuites/results'
      )
        .then((res) => {
          return res.json().then((data) => [
            {
              type: types.TESTSUITES_RESULTS_FETCH_SUCCESS,
              data,
            },
          ]);
        })
        .catch((res) => ({
          type: types.TESTSUITES_RESULTS_FETCH_FAILED,
        }));
    })
  );

export const onFetchTestsuiteResultStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TESTSUITES_RESULT_FETCH_START),
    mapPromiseC(({ id }: any) => {
      return authFetch(`/testsuites/result/${id}`)
        .then((res) => {
          return res.json().then((data) => [
            {
              type: types.TESTSUITES_RESULT_FETCH_SUCCESS,
              data,
            },
          ]);
        })
        .catch((res) => ({
          type: types.TESTSUITES_RESULT_FETCH_FAILED,
        }));
    })
  );
