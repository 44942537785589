import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { fetchListValues, notifyError, notifySuccess } from '../../utils';

export const onFetchAdminDeaths = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_DEATHS_FETCH_START),
    mapPromiseC(({ query, offset }: any) => {
      return authFetchJson(`/admin/deaths/?q=${query}&offset=${offset}`)
        .then((data) => {
          return {
            type: types.ADMIN_DEATHS_FETCH_SUCCESS,
            data,
            offset,
          };
        })
        .catch(() => ({
          type: types.ADMIN_DEATHS_FETCH_FAILED,
        }));
    })
  );
