import React, { useState } from 'react';
import { Editor as WYSIWYGEditor } from 'react-draft-wysiwyg';
import { RichUtils, EditorState, AtomicBlockUtils } from 'draft-js';
import AlignLeftIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import AlignCenterIcon from '@mui/icons-material/FormatAlignCenterOutlined';
import AlignRightIcon from '@mui/icons-material/FormatAlignRightOutlined';
import AlignJustifyIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
import ListNumberedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import ListBulletedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import TextBoldIcon from '@mui/icons-material/FormatBoldOutlined';
import TextItalicIcon from '@mui/icons-material/FormatItalicOutlined';
import TextUnderlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import TextStrikethroughIcon from '@mui/icons-material/FormatStrikethroughOutlined';
import _find from 'lodash/find';
import { css } from '@emotion/react';

import { customStyleMap, editorStyles, emojis, blockRenderer } from './config';
import { rmImagesFromContent } from './rm-img';

const stampPreviewStyles = css`
  height: 1vh;
  width: 1vh;
`;

const toolbarOptions = {
  options: [
    'inline',
    'list',
    'textAlign',
    'fontFamily',
    'colorPicker',
    'emoji',
    'remove',
  ],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    bold: { component: TextBoldIcon },
    italic: { component: TextItalicIcon },
    underline: { component: TextUnderlinedIcon },
    strikethrough: { component: TextStrikethroughIcon },
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
    unordered: { component: ListBulletedIcon },
    ordered: { component: ListNumberedIcon },
  },
  fontFamily: {
    options: [
      'Arial',
      'Georgia',
      'Impact',
      'Tahoma',
      'Trebuchet MS',
      'Verdana',
      'Times New Roman',
      'cursive',
      'Courier',
      'monospace',
    ],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: { component: AlignLeftIcon },
    center: { component: AlignCenterIcon },
    right: { component: AlignRightIcon },
    justify: { component: AlignJustifyIcon },
  },
  colorPicker: {
    icon: '../images/FormatPaint.svg',
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      'rgb(97,189,109)',
      'rgb(26,188,156)',
      'rgb(84,172,210)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(239,239,239)',
      'rgb(255,255,255)',
      'rgb(250,197,28)',
      'rgb(243,121,52)',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)',
    ],
  },
  emoji: {
    icon: '../images/FormatEmoji.svg',
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    emojis,
  },
  remove: {
    icon: '../images/FormatClear.svg',
    className: undefined,
    component: undefined,
  },
};

const FontSizeDropdown = ({ editorState, onEditorStateChange }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const availableFontSizes = Object.keys(customStyleMap);

  const toggleInlineStyle = (inlineStyle: any) => {
    const newEditorState = RichUtils.toggleInlineStyle(
      editorState,
      inlineStyle,
    );
    onEditorStateChange(newEditorState);
  };

  const getCurrentFontSize = () => {
    const inlineStyle = editorState.getCurrentInlineStyle();

    const foundFontSize = _find(availableFontSizes, (fontSize) =>
      inlineStyle.has(fontSize),
    );
    return foundFontSize || 'Grösse';
  };

  return (
    <div className="rdw-block-wrapper" aria-label="rdw-block-control">
      <div
        className="rdw-dropdown-wrapper rdw-block-dropdown"
        aria-label="rdw-dropdown"
        aria-expanded="true"
      >
        <a
          className="rdw-dropdown-selectedtext"
          title="Block Type"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{getCurrentFontSize()}</span>
          <div
            className={
              isOpen ? 'rdw-dropdown-carettoclose' : 'rdw-dropdown-carettoopen'
            }
          />
        </a>
        {isOpen && (
          <ul className="rdw-dropdown-optionwrapper">
            {availableFontSizes
              .slice()
              .reverse()
              .map((fontSize) => (
                <li
                  key={fontSize}
                  className="rdw-dropdownoption-default"
                  onClick={() => {
                    setIsOpen(false);
                    toggleInlineStyle(fontSize);
                  }}
                >
                  {fontSize}
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const StampButton = ({
  editorState,
  onEditorStateChange,
  type,
  updateUsedStamps,
}: any) => {
  const addStamp = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      { type },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    onEditorStateChange(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, type),
    );

    updateUsedStamps(type);
  };

  return (
    <div onClick={addStamp} className="rdw-emoji-wrapper">
      <div className="rdw-option-wrapper">
        <img src={`../images/documents/${type}.png`} css={stampPreviewStyles} />
      </div>
    </div>
  );
};

const Editor = ({
  content,
  setContent,
  size,
  stamps,
  updateUsedStamps,
  template,
}: any) => {
  const stampComponents = stamps
    ? stamps.map((stamp: any) => (
        <StampButton
          editorState={content}
          onEditorStateChange={setContent}
          type={stamp}
          updateUsedStamps={updateUsedStamps}
        />
      ))
    : [];

  const updateContent = (newBody: EditorState) => {
    setContent(rmImagesFromContent(newBody));
  };

  return (
    <div css={() => editorStyles(size, template)}>
      <WYSIWYGEditor
        editorState={content}
        onEditorStateChange={updateContent}
        toolbar={toolbarOptions}
        tabIndex={-1}
        customStyleMap={customStyleMap}
        // THIS IS A HACK
        // onPaste listener seems to fix that ctrl+s adds empty lines after each line when pasting
        // somthing that was copied from the editor
        // @ts-ignore
        onPaste={() => {}}
        toolbarCustomButtons={[
          <FontSizeDropdown
            editorState={content}
            onEditorStateChange={setContent}
          />,
          ...stampComponents,
        ]}
        customBlockRenderFunc={blockRenderer}
      />
    </div>
  );
};

export default Editor;
