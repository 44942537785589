import * as types from '../../../redux/types';
import {
  pipeC,
  ofType,
  mapPromiseC,
  mapC,
  debounceC,
} from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { notifySuccess, notifyError } from '../../utils';

export const onFetchAdminApartment = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_APARTMENT_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/house/${_id}`)
        .then((data) => {
          return {
            type: types.ADMIN_APARTMENT_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_APARTMENT_FETCH_FAILED,
        }));
    })
  );
