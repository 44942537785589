import React from 'react';
import { connect } from 'react-redux';
import { pathOr, pipe } from 'ramda';
import { Company } from '../../components/apps/Company';
import {
  startCompaniesFetch,
  startCompanyChangeAdd,
  startCompanyChangeLabel,
  startCompanyCheckInsFetch,
  startCompanyCounterFetch,
  startCompanyCounterPrice,
  startCompanyEmployeeEdit,
  startCompanyEmployeeFire,
  startCompanyEmployeeInvite,
  startCompanyEmployeeSalary,
  startCompanyExportsFetch,
  startCompanyProductionFetch,
  startCompanyStorageFetch,
  startCompanyStoragePrice,
} from '../../actions/company';
import {
  appsCompanyAllP,
  appsCompanyCheckInsP,
  appsCompanyCounterP,
  appsCompanyExportsP,
  appsCompanyProductionP,
  appsCompanyStorageP,
} from '../../redux/reducer/apps/company';
import { usernameP } from '../../redux/reducer/login';
import { darkP } from '../../redux/reducer/common';

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
    companies: pathOr([], appsCompanyAllP, state),
    storage: pathOr(undefined, appsCompanyStorageP, state),
    counter: pathOr(undefined, appsCompanyCounterP, state),
    checkIns: pathOr([], appsCompanyCheckInsP, state),
    exports: pathOr([], appsCompanyExportsP, state),
    production: pathOr(undefined, appsCompanyProductionP, state),
    username: pathOr('', usernameP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetch: pipe(startCompaniesFetch, dispatch),
  changeName: pipe(startCompanyChangeLabel, dispatch),
  changeAdd: pipe(startCompanyChangeAdd, dispatch),
  fetchStorage: pipe(startCompanyStorageFetch, dispatch),
  saveStoragePrices: pipe(startCompanyStoragePrice, dispatch),
  fetchCounter: pipe(startCompanyCounterFetch, dispatch),
  saveCounterPrices: pipe(startCompanyCounterPrice, dispatch),
  editEmployeeRights: pipe(startCompanyEmployeeEdit, dispatch),
  changeSalaries: pipe(startCompanyEmployeeSalary, dispatch),
  fire: pipe(startCompanyEmployeeFire, dispatch),
  invite: pipe(startCompanyEmployeeInvite, dispatch),
  fetchCheckIns: pipe(startCompanyCheckInsFetch, dispatch),
  fetchExports: pipe(startCompanyExportsFetch, dispatch),
  fetchProduction: pipe(startCompanyProductionFetch, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);
