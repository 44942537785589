declare var google: any;

const game_1_x = 3077.225830078125,
  game_1_y = 6011.2470703125,
  map_1_lng = -47.327,
  map_1_lat = 78.468,
  game_2_x = -1868.5540771484375,
  game_2_y = -2999.6025390625,
  map_2_lng = -71.37,
  map_2_lat = 34.698;

export const convertToMapGMAP = (e: any, a: any) => {
  var r =
      map_1_lng +
      ((e - game_1_x) * (map_1_lng - map_2_lng)) / (game_1_x - game_2_x),
    t =
      map_1_lat +
      ((a - game_1_y) * (map_1_lat - map_2_lat)) / (game_1_y - game_2_y);
  return new google.maps.LatLng(t, r);
};

export const normalizeCoordinates = (coord: any, zoom: number) => {
  let y = coord.y;
  let x = coord.x;

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  let tileRange = 1 << zoom;

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null;
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }

  return {
    x: x,
    y: y,
  };
};

export const getTileImage = (rawCoordinates: any, zoomLevel: number) => {
  var coord = normalizeCoordinates(rawCoordinates, zoomLevel);
  if (!coord) {
    return null;
  }
  return '/img/map/atlas/' + zoomLevel + '-' + coord.x + '_' + coord.y + '.png';
};

export function EuclideanProjection() {
  // @ts-ignore
  (this.pixelOrigin_ = new google.maps.Point(128, 128)),
    // @ts-ignore
    (this.pixelsPerLonDegree_ = 256 / 360),
    // @ts-ignore
    (this.pixelsPerLonRadian_ = 256 / (2 * Math.PI)),
    // @ts-ignore
    (this.scaleLat = 2),
    // @ts-ignore
    (this.scaleLng = 2),
    // @ts-ignore
    (this.offsetLat = 0),
    // @ts-ignore
    (this.offsetLng = 0);
  // @ts-ignore
}
(EuclideanProjection.prototype.fromLatLngToPoint = function (e: any, a: any) {
  var r = a || new google.maps.Point(0, 0),
    t = this.pixelOrigin_;
  return (
    (r.x =
      t.x +
      (e.lng() + this.offsetLng) * this.scaleLng * this.pixelsPerLonDegree_),
    (r.y =
      t.y +
      (-1 * e.lat() + this.offsetLat) *
        this.scaleLat *
        this.pixelsPerLonDegree_),
    r
  );
}),
  (EuclideanProjection.prototype.fromPointToLatLng = function (e: any) {
    var a = this,
      r = a.pixelOrigin_,
      t = (e.x - r.x) / a.pixelsPerLonDegree_ / this.scaleLng - this.offsetLng,
      n =
        (-1 * (e.y - r.y)) / a.pixelsPerLonDegree_ / this.scaleLat -
        this.offsetLat;
    return new google.maps.LatLng(n, t, !0);
  });
