import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  convertToMapGMAP,
  EuclideanProjection,
  getTileImage,
} from './GtaMap.utils';
import { GtaMapMarkerType, useGtaMapMarker } from './useGtaMapMarker';

declare var google: any;

const googleStyles = css`
  .gm-style-mtc {
    display: none;
  }
  .gm-ui-hover-effect {
    display: none !important;
  }

  .gm-style-iw.gm-style-iw-c {
    border-radius: 0;
    background-color: rgb(76 87 101);
    color: #fff;
  }

  .gm-style .gm-style-iw-t::after {
    border-top: 0.2vh solid rgb(76 87 101);
    background: linear-gradient(
      45deg,
      rgb(76 87 101) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .gm-style-iw-d {
    background-color: rgb(76 87 101);
    overflow: hidden !important;
    padding: 0px 2vh 0px 0px;
    height: 2vh;
    margin-top: -1vh;
  }
`;

const createMap = (element: any) => {
  const map = new google.maps.Map(element, {
    center: convertToMapGMAP(315.7072466019408, 1701.7513644599276),
    backgroundColor: '#0fa8d2',
    zoom: 4,
    isPng: true,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
  });

  // Name the layer anything you like.
  const layerID = 'mapLayer';

  // Create a new ImageMapType layer.
  const layer = new google.maps.ImageMapType({
    name: layerID,
    minZoom: 3,
    maxZoom: 7,
    tileSize: {
      b: 'px',
      f: 'px',
      height: 256,
      width: 256,
    },
    getTileUrl: getTileImage,
  });

  // @ts-ignore
  layer.projection = new EuclideanProjection();

  map.mapTypes.set(layerID, layer);
  map.setMapTypeId(layerID);
  return map;
};

type GtaMapType = {
  markers?: GtaMapMarkerType[];
};
export const GtaMap = ({ markers }: GtaMapType) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState<any>();
  const [updateMarkers] = useGtaMapMarker();

  useEffect(() => {
    if (mapRef.current) {
      const map = createMap(mapRef.current);
      setMap(map);
    }
  }, [mapRef]);

  useEffect(() => {
    if (map && markers) {
      updateMarkers(map, markers);
    }
  }, [markers, map]);

  return <div className="w-full h-full" css={googleStyles} ref={mapRef}></div>;
};
