import * as types from '../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../utils/fetch';

export const fetchListValues = (
  startAction: string,
  successAction: string,
  failedAction: string,
  url: (_id: string, offset: number) => string
) => (action$: any) =>
  pipeC(
    action$,
    ofType(startAction),
    mapPromiseC(({ _id, offset }: any) => {
      return authFetchJson(url(_id, offset))
        .then((data) => {
          return {
            type: successAction,
            data,
            offset,
          };
        })
        .catch(() => ({
          type: failedAction,
        }));
    })
  );

export const notifySuccess = (msg: string) => ({
  type: types.NOTIFY_SUCCESS,
  msg,
});
export const notifyError = (msg: string) => ({
  type: types.NOTIFY_ERROR,
  msg,
});
