import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import {
  setAdminTicketsSearch,
  startAdminTicketDelete,
  startAdminTicketsFetch,
} from '../../../actions';
import {
  appsAdminTicketsCompletedP,
  appsAdminTicketsResultsP,
  appsAdminTicketsSearchP,
} from '../../../redux/reducer/apps/admin/tickets';
import { TicketEntryType } from './types/tickets';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Delete from '@mui/icons-material/Delete';
import Clear from '@mui/icons-material/Clear';
import { css } from '@emotion/react';

const successStyle = css`
  color: green;
`;
const warningStyle = css`
  color: orange;
`;
const errorStyle = css`
  color: red;
`;

type TicketsType = {};
export const Tickets = ({}: TicketsType) => {
  const [activeDeleteAction, setActiceDeleteAction] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const dispatch = useDispatch();
  const search = useSelector<any, string>(pathOr('', appsAdminTicketsSearchP));
  const list = useSelector<any, TicketEntryType[]>(
    pathOr([], appsAdminTicketsResultsP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminTicketsCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminTicketsFetch(search, offset));
  };

  const updateSearch = (ev: any) => {
    dispatch(setAdminTicketsSearch(ev.target.value));
  };
  const executeSearch = () => {
    dispatch(startAdminTicketsFetch(search, 0));
  };

  useEffect(() => {
    dispatch(startAdminTicketsFetch(search, 0));
  }, []);

  const deleteTicket = () => {
    if (deleteReason.length > 3 && activeDeleteAction) {
      dispatch(
        startAdminTicketDelete(activeDeleteAction, deleteReason, search, 0)
      );
      setActiceDeleteAction('');
      setDeleteReason('');
    }
  };

  return (
    <div>
      <h1>Tickets</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={updateSearch}
      />

      <Button variant="outlined" onClick={executeSearch}>
        Suche starten
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Empfänger</TableCell>
            <TableCell>Aussteller</TableCell>
            <TableCell>Vergehen</TableCell>
            <TableCell>Betrag</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Aktion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.created))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>{entry.owner.name}</TableCell>
              <TableCell>{entry.creator.name}</TableCell>
              <TableCell>{entry.reason}</TableCell>
              <TableCell>${entry.price}</TableCell>
              <TableCell>
                {entry.deleted ? (
                  <span css={warningStyle}>
                    <Delete />
                  </span>
                ) : entry.paid ? (
                  <span css={successStyle}>
                    <CheckCircle />
                  </span>
                ) : (
                  <span css={errorStyle}>
                    <Clear />
                  </span>
                )}
              </TableCell>
              <TableCell>
                {!entry.deleted &&
                  !entry.paid &&
                  entry._id !== activeDeleteAction && (
                    <Button
                      variant="outlined"
                      onClick={() => setActiceDeleteAction(entry._id)}
                    >
                      Löschen
                    </Button>
                  )}
                {entry._id === activeDeleteAction && (
                  <>
                    <TextField
                      label="Löschgrund"
                      value={deleteReason}
                      onChange={(ev) => setDeleteReason(ev.target.value)}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => deleteTicket()}
                      disabled={deleteReason.length <= 3}
                    >
                      Anwenden
                    </Button>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
          {!reachedEndOfList && (
            <TableRow>
              <TableCell colSpan={7}>
                <Button variant="outlined" onClick={() => onLoad(list.length)}>
                  Weitere Laden
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
