import { css } from '@emotion/react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { find, pathEq, pathOr, pipe, propEq } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLspdPersonTicket,
  fetchLspdPersonDetails,
  fetchLspdWeaponSearch,
  saveLspdPersonNotes,
  saveLspdPersonWanted,
  setLspdWeaponSearch,
} from '../../../actions';
import { lspdPersonSearchDetailsP } from '../../../redux/reducer/apps/lspd';
import { PersonDetailsData, PersonDetailsVehicleData } from './types';
import PriorityIcon from '@mui/icons-material/FiberManualRecordRounded';
import Editor from '../../../leogram/shared/Editor';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { DateTime } from 'luxon';
import { translate } from '../../../utils/translate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useAuthentication } from '../../../utils/authentication';
import { renderVehColor } from './vehcolor.utils';
import { weaponStateIconStyle } from './weapon.utils';
import {
  getWantedStateLabel,
  wantedStateIconStyle,
  WANTED_STATES,
} from './wanted.utils';
import { Autocomplete } from '@mui/material';

const PRIORITIES = [
  { label: 'Sehr Hoch', value: 'veryhigh' },
  { label: 'Hoch', value: 'high' },
  { label: 'Normal', value: 'normal' },
  { label: 'Gering', value: 'low' },
];

const containerStyle = css`
  margin-bottom: 3vh;
`;
const wantedContainerStyle = css`
  margin-bottom: 1vh;
`;
const cursorStyle = css`
  cursor: pointer;
`;
const wantedInnerContainerStyle = css`
  margin-top: 1vh;
`;

const priorityIconStyle = (theme: any) => css`
  &.veryhigh {
    color: ${theme.colors.Red};
  }
  &.high {
    color: ${theme.colors.Orange};
  }
  &.normal {
    color: ${theme.colors.Yellow};
  }
  &.low {
    color: ${theme.colors.Green};
  }
`;

const saveBtnStyle = css`
  margin-top: 1vh;
`;

const editorStyle = css`
  background-color: #ffffff;
`;

type PersonDetailsType = {
  _id: string;
  openMenu: (menu: string) => void;
};
export const PersonDetails = ({ _id, openMenu }: PersonDetailsType) => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const [isWantedEdit, setWantedEdit] = useState(false);
  const [wantedState, setWantedState] = useState('NONE');
  const [wantedReason, setWantedReason] = useState('');
  const data = useSelector<any, null | PersonDetailsData>(
    pathOr(null, lspdPersonSearchDetailsP)
  );
  const [notes, setNotes] = useState(EditorState.createEmpty());
  const [hasNotesChanged, setNotesChanged] = useState(false);

  useEffect(() => {
    dispatch(fetchLspdPersonDetails(_id));
  }, [_id]);

  useEffect(() => {
    if (data) {
      setNotes(
        data.notes
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(data.notes))
            )
          : EditorState.createEmpty()
      );
      setWantedState(pathOr('NONE', ['wanted', 'state'], data));
    }
  }, [data]);

  const saveNotes = () => {
    dispatch(
      saveLspdPersonNotes(
        data?.name ?? '',
        JSON.stringify(convertToRaw(notes.getCurrentContent()))
      )
    );
    setNotesChanged(false);
  };

  const deleteTicket = (_id: string) => {
    dispatch(deleteLspdPersonTicket(data?.name ?? '', _id));
  };

  const gotoWeapon = (id: string) => {
    openMenu('weaponsearch');
    dispatch(setLspdWeaponSearch(id));
    dispatch(fetchLspdWeaponSearch(id));
  };

  const saveWantedState = () => {
    setWantedEdit(false);
    dispatch(saveLspdPersonWanted(data?.name ?? '', wantedState, wantedReason));
  };

  const dataWantedState = pathOr('NONE', ['wanted', 'state'], data);
  const dataWantedReason = pathOr('', ['wanted', 'reason'], data);

  return data ? (
    <div className="w-full h-full">
      <h1>{data.name}</h1>

      <h2>Wanted</h2>
      <div css={wantedContainerStyle}>
        <PriorityIcon css={wantedStateIconStyle} className={dataWantedState} />{' '}
        {getWantedStateLabel(dataWantedState)}
        {dataWantedReason ? ` - ${dataWantedReason}` : ''}
      </div>
      <div css={containerStyle}>
        {hasRight('lspd.person.changeWanted') && !isWantedEdit && (
          <Button variant="outlined" onClick={() => setWantedEdit(true)}>
            Bearbeiten
          </Button>
        )}
        {hasRight('lspd.person.changeWanted') && isWantedEdit && (
          <>
            <div css={wantedInnerContainerStyle}>
              <Autocomplete
                options={WANTED_STATES}
                getOptionLabel={(option: any) => option.label}
                value={
                  find<any>(propEq('value', wantedState), WANTED_STATES) || {
                    value: 'dadadada',
                    label: 'dadadada',
                  }
                }
                onChange={(ev, newValue) =>
                  setWantedState(newValue?.value ?? 'NONE')
                }
                renderInput={(params) => (
                  <TextField
                    label="Wanted Status auswählen"
                    variant="outlined"
                    {...params}
                  />
                )}
                renderOption={(props: any, option: any) => (
                  <div {...props}>
                    <PriorityIcon
                      css={wantedStateIconStyle}
                      className={option.value}
                    />{' '}
                    {option.label}
                  </div>
                )}
              />
            </div>
            <div css={wantedInnerContainerStyle}>
              <TextField
                label="Wanted Grund"
                variant="outlined"
                fullWidth
                value={wantedReason}
                onChange={(ev: any) => setWantedReason(ev.target.value)}
              />
            </div>
            <div css={wantedInnerContainerStyle}>
              <Button variant="outlined" onClick={saveWantedState} fullWidth>
                Speichern
              </Button>
            </div>
          </>
        )}
      </div>

      <h2>Fahrzeuge</h2>
      <div css={containerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fahrzeugname</TableCell>
              <TableCell align="center">Kennzeichen</TableCell>
              <TableCell align="center">Motorisierung</TableCell>
              <TableCell align="center">Lackierung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.vehicles.map((veh) => (
              <TableRow key={veh.id}>
                <TableCell>{veh.name}</TableCell>
                <TableCell align="center">{veh.numberPlate}</TableCell>
                <TableCell align="center">{veh.engine}</TableCell>
                <TableCell align="center">{renderVehColor(veh)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <h2>Akten</h2>
      <div css={containerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Priorität</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>zuletzt geändert</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.folders.map((folder) => (
              <TableRow key={folder._id}>
                <TableCell>
                  <PriorityIcon
                    css={priorityIconStyle}
                    className={folder.priority}
                  />{' '}
                  {pipe(
                    find(pathEq(['value'], folder.priority)),
                    pathOr(folder.priority, ['label'])
                  )(PRIORITIES)}
                </TableCell>
                <TableCell>{folder.subject}</TableCell>
                <TableCell>
                  {DateTime.fromJSDate(new Date(folder.modifiedDate))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <h2>Notizen</h2>
      <div css={containerStyle}>
        <div css={editorStyle}>
          <Editor
            content={notes}
            setContent={(x: any) => {
              setNotes(x);
              if (!hasNotesChanged) {
                setNotesChanged(true);
              }
            }}
            size={{ width: '100%', height: 'unset' }}
          />
        </div>
        {hasNotesChanged && (
          <div css={saveBtnStyle}>
            <Button variant="outlined" onClick={saveNotes}>
              Speichern
            </Button>
          </div>
        )}
      </div>

      <h2>Tickets</h2>
      <div css={containerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Grund</TableCell>
              <TableCell align="center">Preis</TableCell>
              <TableCell align="center">Datum</TableCell>
              <TableCell align="center">bezahlt</TableCell>
              <TableCell align="center">Officer</TableCell>
              {hasRight('lspd.person.ticket.delete') && (
                <TableCell align="center">
                  <DeleteOutlineIcon />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.tickets.map((ticket) => (
              <TableRow key={ticket._id}>
                <TableCell>
                  {ticket.reason.split('\\n').map((entry, index) => (
                    <p key={index}>{entry}</p>
                  ))}
                </TableCell>
                <TableCell align="center">${ticket.price}</TableCell>
                <TableCell>
                  {DateTime.fromJSDate(new Date(ticket.created))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell align="center">
                  {ticket.paid && <CheckBoxIcon />}
                  {!ticket.paid && <CheckBoxOutlineBlankIcon />}
                </TableCell>
                <TableCell align="center">{ticket.creator?.name}</TableCell>
                {hasRight('lspd.person.ticket.delete') && (
                  <TableCell align="center">
                    {!ticket.paid && (
                      <div onClick={() => deleteTicket(ticket._id)}>
                        <DeleteOutlineIcon />
                      </div>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <h2>Waffenlizenzen</h2>
      <div css={containerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ausgestellt am</TableCell>
              <TableCell align="center">Laufzeit</TableCell>
              <TableCell>austellender Beamter</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.weaponLicenses.map((license) => (
              <TableRow key={license._id}>
                <TableCell>
                  {DateTime.fromJSDate(new Date(license.createdAt))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell align="center">{license.duration} Tage</TableCell>
                <TableCell>{license.officer?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <h2>Waffen</h2>
      <div css={containerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>gekauft am</TableCell>
              <TableCell>gekauft bei</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.weapons.map((weapon) => (
              <TableRow key={weapon._id}>
                <TableCell
                  onClick={() => gotoWeapon(weapon.id)}
                  css={cursorStyle}
                >
                  {weapon.id}
                </TableCell>
                <TableCell>{translate(weapon.hash)}</TableCell>
                <TableCell>
                  {DateTime.fromJSDate(new Date(weapon.createdAt))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>{weapon.sellerFactory?.label}</TableCell>
                <TableCell>
                  <PriorityIcon
                    css={weaponStateIconStyle}
                    className={weapon.tag}
                  />{' '}
                  {translate(`weaponTag.${weapon.tag}`) || '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <h2>Notizen aus altem System</h2>
      <div css={containerStyle}>
        {(data.oldNotes || '-').split('\\n').map((entry, index) => (
          <p key={index}>{entry}</p>
        ))}
      </div>
    </div>
  ) : null;
};
