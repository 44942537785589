import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 1 Versuch und Fahrlässigkeit</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer unmittelbar zu einer nicht vollendeten Straftat angesetzt hat, macht sich dem jeweiligen Paragraphen in Form des Versuches strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer im Zuge seiner Tat eine Folge billigend in Kauf nimmt, handelt fahrlässig.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 2 Körperverletzung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. We</span><span style="font-size: 14pt;">r eine Person psychisch oder physisch schädigt, macht sich der Körperverletzung strafbar.</span></span><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">§ 3 </span>Gefährliche Körperverletzung</span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer den Tatbestand der Kör</span><span style="font-size: 14pt;">perverletzung erfüllt, diese aber mit einer Waffe, einem gefährlichen Werkzeug oder gemeinschaftlich begeht, macht sich der gefährlichen Körperverletzung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 4 Schwere Körperverletzung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer den Tatbestand der Körperverletzung erfüllt und dafür die Verantwortung trägt, dass die Person das Bewusstsein verliert oder dauerhafte Schäden erleidet, macht sich der schweren Körperverletzung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 5 Totschlag</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer das Leben eines Menschen beendet, ohne dabei die Mordmerkmale zu erfüllen, macht sich des Totschlags strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 6 Mord</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einen Menschen vorsätzlich unter Erfüllung der Mordmerkmale tötet, macht sich des Mordes strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar. </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 7 Diebstahl</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einer Person eine fremde Sache wegnimmt und sich diese Sache aneignet, macht sich des Diebstahls strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 8 Schwerer Diebstahl</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer den Tatbestand eines Diebstahls erfüllt und dabei dafür in ein Haus, eine Firma, oder Auto einsteigt, diesen Diebstahl gewerbsmäßig durchführt, die Hilflosigkeit einer Person ausnutzt oder den Diebstahl gemeinschaftlich begeht, macht sich des schweren Diebstahls strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 9 Sachbeschädigung </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer eine fremde Sache beschädigt oder zerstört, macht sich der Sachbeschädigung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer eine fremde Sache in ihrem Erscheinungsbild unbefugt vorübergehend oder dauerhaft ändert, macht sich ebenfalls der Sachbeschädigung strafbar. </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 10 Brandstiftung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer eine fremde Sache entzündet und damit einen Schaden erzeugt, macht sich der Brandstiftung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 11 Raub </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer eine fremde Sache unter Androhung mit einer Gefahr für Leib oder Leben oder einem empfindlichen Übel entwendet, macht sich des Raubes strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 12 Schwerer Raub</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer eine fremde Sache unter Anwendung von Gewalt oder einem empfindlichen Übel entwendet, macht sich des schweren Raubes strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 13 Drohung</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einer Person mit Gewalt oder einem empfindlichen Übel droht, macht sich der Drohung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 14 Beleidigung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer die subjektive Ehre einer Person schädigt, macht sich der Beleidigung strafbar. </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 15 Üble Nachrede</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer Tatsachen oder Gerüchte über eine Person äußert, welche den öffentlichen Ruf der betroffenen Person schädigen, macht sich der üblen Nachrede strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 16 Erpressung </span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einen Menschen mit Gewalt oder durch Drohung mit einem empfindlichen Übel zu einer Handlung, Duldung oder Unterlassung nötigt und das Ziel hat, dem Vermögen der bedrohten Person zu schaden, macht sich der Erpressung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 17 Nötigung</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einen Menschen mit Gewalt oder durch Drohung mit einem empfindlichen Übel zu einer Handlung, Duldung oder Unterlassung nötig, macht sich der Nötigung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 18 Freiheitsberaubung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer anderen die Freiheit sich fortzubewegen nimmt oder gewaltsam einschränkt, macht sich der Freiheitsberaubung strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 19 Betrug</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer sich selbst einen unrechtmäßigen Vermögensvorteil verschaffen möchte und andere in ihrem Vermögen schädigt, macht sich des Betrugs strafbar</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 20 Hausfriedensbruch</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer gegen die Anweisung des Grundstückseigentümers oder Pächters, sich vom Grundstück fernzuhalten, das Grundstück trotzdem betritt, macht sich des Hausfriedensbruchs strafbar. </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 21 Sperrgebiete</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer staatliche Sperrgebiete ohne Anweisung der zuständigen Behörde betritt, macht sich des Hausfriedensbruchs in Sperrgebieten strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 22 Belästigung</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer andere Personen aktiv längerfristig in ihrem Handeln stört oder bei diesem Unwohlsein erzeugt, macht sich der Belästigung strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">§ 23 Sexuelle Belästigung</span><span style="color:rgb(255,255,255);"></span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. W</span>er eine Person gegen ihren Willen sexuell berührt oder sexuell anspricht, macht sich der sexuellen Belästigung strafbar.</span></p>
<p><span style="font-size: 14pt;"><br></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 24 Widerstand gegen die Staatsgewalt</span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer einen Beamten in seinen hoheitlichen Aufgaben und Maßna</span><span style="font-size: 14pt;">hmen stört, behindert oder sich den Maßnahmen widersetzt, macht sich des Widerstands gegen die Staatsgewalt strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 25 Ausweispflicht</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Jeder Bürger hat die Pflicht, sich gegenüber eines Beamten des LSPD oder DoJ im staatlichen Dienst mit seinem amtlichen Personalausweis auszuweisen.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 26 Vortäuschen einer Straftat</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer vorsätzlich Angaben zu einer nicht vorhandenen Straftat macht, macht sich des Vortäuschens einer Straftat strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 27 Notrufmissbrauch</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer den Notruf der öffentlichen Dienste missbraucht, macht sich des Notrufmissbrauchs strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 28 Amtsanmaßung </span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer sich als Beamter im staatlichen Dienst ausgibt, macht sich der Amtsanmaßung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 29 Meineid</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer vor Gericht einen Eid mit wissentlich falschem Inhalt leistet, macht sich des Meineids strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 30 Besitz staatlichen Eigentums</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer Sachen, welche Staatseigentum sind, vertreibt oder besitzt und dabei kein Mitglied der jeweiligen staatlichen Behörde ist, macht sich des Besitzes staatlichen Eigentums strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 31 Unterlassene Hilfeleistung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer anderen Personen bei einem Unfall oder medizinischer Gefahr trotz zumutbarer Umstände und Erforderlichkeit der Hilfeleistung nicht hilft, macht sich der unterlassenen Hilfeleistung strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 32 Vermummungsverbot</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer Vermummungen, welche eine Identifikation der Person unmöglich macht, auf dem Kopf oder in dem Gesicht trägt, macht sich strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Ausgenommen hiervon ist Schutzkleidung, welche beim Motorradfahren oder Arbeiten in der Mine erforderlich ist.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 33 Strafvereitelung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer vorsätzlich die Ermittlungen und Maßnahmen zur Aufklärung eines Sachverhalts behindert, stört oder relevante Informationen verschleiert, macht sich der Strafvereitelung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">§ 34 Nichteinhaltung staatlicher Fristen</span><span style="color:rgb(255,255,255);"></span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. W</span>er eine geregelte staatliche Frist nicht einhält, macht sich dem Nichteinhalten von staatlichen Fristen strafbar.</span></p>
<p><span style="font-size: 14pt;"><br></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 35 Illegale Zahlungsmittel </span></span></p>
<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer illegale Zahlungsmittel besitzt, mit diesen handelt oder diese in den Umlauf bringt, </span><span style="font-size: 14pt;">macht sich strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 36 Flucht aus Strafgefangenschaft</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer sich absichtlich der Strafgefangenschaft entzieht, um dem Vollzug der Haftstrafe zu entkommen, macht sich der Flucht aus der Strafgefangenschaft strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 37 Terrorismus</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer absichtlich eine Explosion herbeiführt, mit Waffen oder gefährlichen Gegenständen eine erhebliche Zahl von Menschen verletzt oder dabei die Sicherheit des Staates gefährdet, macht sich des Terrorismus strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 38 Verjährungsfristen</span></span><span style="color:rgb(255,255,255);"></span></p><p><span style="color:rgb(255,255,255);"></span></p><p><span style="font-size: 14pt;">I. Straftaten gegen das Leben verjähren nach 52 Wochen.</span></p>
<p><span style="font-size: 14pt;">II. Terrorismus verjährt nach 10 Wochen.</span></p>
<p><span style="font-size: 14pt;">III. Straftaten welche in Verbindung mit Waffen stehen verjähren nach 10 Wochen.</span></p>
<p><span style="font-size: 14pt;">IV. Korruption verjährt nach 8 Wochen.</span></p>
<p><span style="font-size: 14pt;">V. Straftaten gegen die körperliche Gesundheit verjähren nach 5 Wochen.</span></p>
<p><span style="font-size: 14pt;">VI. Straftaten gegen die Freiheit verjähren nach 4 Wochen.</span></p>
<p><span style="font-size: 14pt;">VII. Straftaten gegen das Eigentum verjähren nach 3 Wochen.</span></p>
<p><span style="font-size: 14pt;">VIII. Sonstige Straftaten verjähren nach 2 Wochen.</span></p>
<p><span style="font-size: 14pt;">IX. Verurteilte und aktenkundige Straftaten verjähren äquivalent.</span></p>
<p><span style="font-size: 14pt;">X. Ordnungswidrigkeiten verjähren nach dem Begleichen.</span></p><p><br></p><p><br></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 39 Zwangsheirat</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer gegen den Willen einer anderen Person dieser eine Heirat aufzwingt, macht sich der Zwangsheirat strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 40 Menschenhandel</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer Menschen als Eigentum handelt, verschenkt oder tauscht, macht sich des Menschenhandels strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 41 Geldwäsche</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer Falschgeld in das laufende Wirtschaftssystem einschleust oder im Besitz von Falschgeld ist, macht sich der Geldwäsche strafbar. </span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer willentlich eine erhebliche Summe in die laufende Wirtschaft einschleust, macht sich der schweren Geldwäsche strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 42 Urkundenfälschung</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer willentlich eine falsche Urkunde oder ein amtliches Dokument nutzt oder ein Dokument fälscht, macht sich der Urkundenfälschung strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Ebenfalls macht sich der Urkundenfälschung strafbar, wer nicht-eigene Dokumente und Urkunden zu seinem eigenen Nutzen missbraucht. </span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 43 Notwehr und rechtfertigender Notstand</span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer sein eigenes Wohl oder seine Rechtsgüter aufgrund von einer unmittelbaren und akuten Gefahr verteidigt, macht sich nicht strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Zu beachten ist, dass die Verteidigung erforderlich, angemessen und geeignet sein muss. Falls dies nicht der Fall ist, macht sich der Verteidigende strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Wer in einer gegenwärtigen Gefahr für Leib, Freiheit, Ehre oder Eigentum eine Tat begeht, um die Gefahr von sich oder einem anderen abzuwenden, handelt nicht rechtswidrig, wenn dabei die angewendete Tat verhältnismäßig ist.</span></span></p>
<p><br></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 44 Steuerhinterziehung </span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer bewusst falsche oder unvollständige Angaben bezogen auf seine Steuern macht oder steuerlich relevante Angaben gegenüber dem DoC verschweigt, macht sich der Steuerhinterziehung strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Versuch ist strafbar.</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
<p><span style="color:rgb(255,255,255);"></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">§ 45 Illegale Gegenstände</span></span></p>
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Besitz eines Funkgerätes ist strafbar.</span></span></p><p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Besitz eines Dietrichs ist strafbar.</span></span></p>
`;

export const StrafGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
