import React from 'react';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LocationIcon from '@mui/icons-material/MyLocationOutlined';
import { find, pathEq, pathOr, pipe } from 'ramda';
import { translate } from '../../utils/translate';

const exteriorColors = require('../../resources/stores/mechanic/exterieurcolors');

const cardStyle = css`
  width: 30vh;
  position: relative;
`;

const contentStyle = (dark: boolean) => css`
  display: inline-block;
  margin-top: -4vh;
  min-height: 15vh;
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${dark ? '#33333366' : '#ffffff66'},
    ${dark ? '#333333' : '#fff'} 20%
  );
`;

const vehicleColorContainer = css`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5vh;
  display: flex;
  width: 4.5vh;
  justify-content: space-between;
`;

const vehicleColor = (color: string) => css`
  width: 1.5vh;
  height: 1.5vh;
  display: inline-block;
  background-color: ${color};
  border-radius: 50%;
`;

const rowHeadStyle = css`
  margin: 1.5vh 0 0.5vh 0;
`;

const rowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25vh 0;
  font-size: 1.25vh !important;
`;

const vehNameStyle = css`
  font-size: 1.75vh !important;
  position: absolute;
  bottom: 10vh;
  width: 67%;
  right: 1vh;
  text-align: end;
  font-weight: bold;
`;

const numberplateStyle = (dark: boolean) => css`
  font-family: monospace;
  font-size: 1.25vh;
  background-color: ${dark ? '#1a1a1a' : '#fff'};
  border-radius: 0.2vh;
  display: flex;
  padding: 0.25vh;
  width: 7vh;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
`;

const wayPointButtonStyle = css`
  position: absolute;
  bottom: 1vh;
  left: 1vh;
  font-size: 1vh !important;
`;

interface VehicleData {
  _id: string;
  numberPlate: string;
  numberPlateBreakDown?: boolean;
  distance?: number; // in meters
  name: string; // hash
  inGarage: boolean;
  garagePosition: string;
  constructionDate?: string;
  owner?: { name: string };
  factory?: { label: string };
  tunning?: {
    color: {
      first: number | { r: number; g: number; b: number };
      second: number | { r: number; g: number; b: number };
    };
  };
  isPlayerOwner?: boolean;
  label?: string;
}

type VehicleCardType = {
  vehicle: VehicleData;
  setWayPoint?: (id: string) => void;
  dark: boolean;
};
const VehicleCard = ({ vehicle, setWayPoint, dark }: VehicleCardType) => {
  const firstColorValue = vehicle.tunning?.color?.first ?? 0;
  const firstColor =
    typeof firstColorValue === 'object'
      ? `rgb(${firstColorValue.r}, ${firstColorValue.g}, ${firstColorValue.b})`
      : pipe(
          (x: number) =>
            find(pathEq(['value'], x), exteriorColors.specialColors),
          pathOr('#000', ['hex'])
        )(firstColorValue);

  const secondColorValue = vehicle.tunning?.color?.second ?? 0;
  const secondColor =
    typeof secondColorValue === 'object'
      ? `rgb(${secondColorValue.r}, ${secondColorValue.g}, ${secondColorValue.b})`
      : pipe(
          (x: number) =>
            find(pathEq(['value'], x), exteriorColors.specialColors),
          pathOr('#000', ['hex'])
        )(secondColorValue);

  return (
    <div key={vehicle._id}>
      <Card css={cardStyle} elevation={4}>
        <CardMedia
          component="img"
          image={`img/vehicles/${vehicle.name}.jpg`}
          alt={vehicle.name}
        />
        <div css={vehicleColorContainer}>
          <div css={() => vehicleColor(firstColor)} />
          <div css={() => vehicleColor(secondColor)} />
        </div>
        <CardContent css={() => contentStyle(dark)}>
          <div css={[rowStyle, rowHeadStyle]}>
            <Typography variant="h5" component="div" css={vehNameStyle}>
              {vehicle.label}
            </Typography>
            <div css={() => numberplateStyle(dark)}>{vehicle.numberPlate}</div>
          </div>
          <div css={rowStyle}>
            Besitzer
            <div>
              {vehicle.owner?.name}
              {vehicle.factory?.label}
            </div>
          </div>
          {vehicle.inGarage && (
            <div css={rowStyle}>
              Garage<div>{translate(vehicle.garagePosition)}</div>
            </div>
          )}
          {(vehicle.factory || vehicle.isPlayerOwner) && setWayPoint && (
            <div css={wayPointButtonStyle}>
              <Button
                variant="outlined"
                onClick={() => setWayPoint(vehicle._id)}
                startIcon={<LocationIcon />}
              >
                Find my car
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default VehicleCard;
