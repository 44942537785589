import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startAdminPlayerVehiclesFetch } from '../../../../actions';
import {
  appsAdminPlayerVehiclesCompletedP,
  appsAdminPlayerVehiclesP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { PlayerVehicle } from '../types/playervehicles';
import { detailContainerStyle } from '../styles';

const vehIconStyle = css`
  height: 3vh;
  display: inline;
  margin-right: 0.5vh;
`

type VehiclesType = {
  _id: string;
  openVehicle: (_id: string, numberPlate: string) => void;
};
export const Vehicles = ({ _id, openVehicle }: VehiclesType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerVehicle[]>(
    pathOr([], appsAdminPlayerVehiclesP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerVehiclesCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerVehiclesFetch(_id, offset));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Fahrzeuge</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell>Kennzeichen</TableCell>
              <TableCell>In Garage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((entry) => (
              <TableRow
                key={entry._id}
                onClick={() => openVehicle(entry._id, entry.numberPlate)}
              >
                <TableCell><img src={`img/vehicles/${entry.name}.jpg`} css={vehIconStyle} />{entry.name}</TableCell>
                <TableCell>{entry.numberPlate}</TableCell>
                <TableCell>{entry.inGarage && <CheckCircle />}</TableCell>
              </TableRow>
            ))}
            {!reachedEndOfList && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Button
                    variant="outlined"
                    onClick={() => onLoad(list.length)}
                  >
                    Weitere Laden
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
