import { pipe, path } from 'ramda';
import { connect } from 'react-redux';
import { ProfilePage } from '../components/ProfilePage';
import {
  fetchProfilePage,
  startProfileFollow,
  startProfileUnfollow,
  fetchProfilePageMorePosts,
  startProfilePostLike,
} from '../actions/profilepage';
import {
  profilePageContentP,
  isProfilePageLoadingP,
} from '../store/reducer/profilepage';
import { startRouterGoto, logout } from '../actions';
import { routerParamsP } from '../store/reducer/router';

const mapStateToProps = (state: any) => {
  return {
    account: path([...profilePageContentP, 'account'], state),
    posts: path([...profilePageContentP, 'posts'], state),
    followerAmount: path([...profilePageContentP, 'followerAmount'], state),
    isFollower: path([...profilePageContentP, 'isFollower'], state),
    isLoading: path(isProfilePageLoadingP, state),
    urlParams: path(routerParamsP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchProfilePage: pipe(fetchProfilePage, dispatch),
  startFollow: pipe(startProfileFollow, dispatch),
  startUnfollow: pipe(startProfileUnfollow, dispatch),
  fetchProfileMorePosts: pipe(fetchProfilePageMorePosts, dispatch),
  goto: pipe(startRouterGoto, dispatch),
  like: pipe(startProfilePostLike, dispatch),
  logout: pipe(logout, dispatch),
});

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(ProfilePage);
