export default [
  {
    type: 'h1',
    text: 'Low End Apartment - Wohnraum für kleines Geld',
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Das Low End Apartment ist eine zwei Zimmer Wohnung mit Wohnküche. Vielleicht das erste eigene Reich das größer als ein Motel Zimmer ist? Für den relativ geringen Preis hat die Wohnung einen gute Schnitt und bietet die ein oder andere Möglichkeit.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/low-end-apartment/floor.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Angekommen im Wohn- und Essbereich, sticht die Küche direkt ins Auge. Dort finden Sie eine Einbauküche mit diversen Elektrogeräten sowie einen Gasherd. Das offene Flair der Küche bietet eine gute Möglichkeit den Essbereich mit dem Wohnbereich zu kombinieren. Sie stehen in der Küche, bereiten das Essen für die Gäste vor und bekommt nicht mit, was beredet wird? Mit dieser offenen Küche passiert Ihnen dies garantiert nicht.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/low-end-apartment/kitchen.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Der Wohnbereich bietet viel Platz für Ihre persönliche Einrichtung. Gestalten sie Ihren Erholungsbereich nach ihren Wünschen. Ihr lokaler Baumarkt wird Ihnen sicher bei der Auswahl der bequemsten Couch helfen. Tageslicht erhellt den Raum zusätzlich durch 2 Fenster.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/low-end-apartment/livingroom.jpg',
      'img/interior/low-end-apartment/livingroom2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Das kleine Schlafzimmer sorgt mit einem schönen Türkiston für angenehme Nächte und einen perfekten Start in den Tag. Hier finden Sie genug Platz für alle Ihre Lieblingskleidungsstücke.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/low-end-apartment/bedroom.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Neben dem Schlafzimmer befindet sich ein kleines Badezimmer, welches in einem Retroflair gehalten ist. Es ist halbhoch gefliest und hat eine geräumige Dusche. Das Waschbecken sowie die Toilette sind in der gleichen Farbe wie die Fliesen gehalten.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/low-end-apartment/bathroom.jpg'],
    customCss: '',
  },
];
