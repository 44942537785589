import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {
  setAdminFactorySearch,
  startAdminFactorySearch,
} from '../../../actions';
import {
  appsAdminFactorySearchResultsP,
  appsAdminFactorysSearchP,
} from '../../../redux/reducer/apps/admin/factorysearch';
import { FactorySearchEntryType } from './types/factorysearch';

type FactorySearchType = {
  openFactory: (_id: string, label: string) => void;
};
export const FactorySearch = ({ openFactory }: FactorySearchType) => {
  const dispatch = useDispatch();
  const search = useSelector<any, string>(pathOr('', appsAdminFactorysSearchP));
  const results = useSelector<any, FactorySearchEntryType[]>(
    pathOr([], appsAdminFactorySearchResultsP)
  );

  const updateSearch = (ev: any) => {
    dispatch(setAdminFactorySearch(ev.target.value));
  };
  const executeSearch = () => {
    dispatch(startAdminFactorySearch(search));
  };

  return (
    <div>
      <h1>Firmensuche</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={updateSearch}
      />

      <Button variant="outlined" onClick={executeSearch}>
        Suche starten
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Label</TableCell>
            <TableCell>Hash</TableCell>
            <TableCell>Besitzer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((entry) => (
            <TableRow
              key={entry._id}
              onClick={() => openFactory(entry._id, entry.label)}
            >
              <TableCell>{entry.label}</TableCell>
              <TableCell>{entry.hash}</TableCell>
              <TableCell>{entry.owner?.name}</TableCell>
            </TableRow>
          ))}
          {results.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>Keine Ergebnisse</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
