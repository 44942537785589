import { set, lensPath } from 'ramda';
import store from '../../redux/store';
import { startRouterGoto } from '../actions';
import { tokenState } from './token';

const authLens = lensPath(['headers', 'authorizationtoken']);

let host = '';
export const setHost = (url: string) => {
  host = url;
};

const handleErrors = (response: Response) => {
  if (!response.ok) {
    throw response;
  }
  return response;
};

const catchUnautorized = (response: Response) => {
  if (response.status === 401) {
    // switch to login route
    store.dispatch(startRouterGoto('/login'));
  }
  throw response;
};

export const authFetch = (url: string, opts: any = {}) => {
  opts = set(authLens, tokenState.token, opts);
  return fetch(host + url, opts)
    .then(handleErrors)
    .catch(catchUnautorized);
};

export const authFetchJson = (url: string, opts: any = {}) =>
  authFetch(url, opts).then((res) => res.json());
export const authFetchPost = (url: string, body: any = {}) => {
  let opts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  opts = set(authLens, tokenState.token, opts);
  return fetch(host + url, opts)
    .then(handleErrors)
    .catch(catchUnautorized);
};
export const authFetchDelete = (url: string, body: any = {}) => {
  let opts = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  opts = set(authLens, tokenState.token, opts);
  return fetch(host + url, opts)
    .then(handleErrors)
    .catch(catchUnautorized);
};
