import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../utils/callbag';
import { authFetch } from '../utils/fetch';

declare var parent: any;

export const onScreenshotInit = (action$: any) =>
  pipeC(
    action$,
    ofType(types.SCREENSHOT_INIT),
    mapC(() => {
      return { type: 'SCREENSHOT_OVERLAY_ENABLED', enabled: true };
    })
  );

export const onScreenshotCreate = (action$: any) =>
  pipeC(
    action$,
    ofType(types.SCREENSHOT_CREATE_START),
    mapPromiseC(({ name, pos, base64, successNotify }: any) => {
      return authFetch(`/leogram/screenshot/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          pos,
          base64,
        }),
      })
        .then((res) => {
          return res.json().then(({ id, thumb, url }) => ({
            type: types.SCREENSHOT_CREATE_SUCCESS,
            id,
            thumb,
            url,
            successNotify,
          }));
        })
        .catch(() => {
          return {
            type: types.SCREENSHOT_CREATE_FAILED,
          };
        });
    })
  );
