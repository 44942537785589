const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fix = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const funkStyle = {
  background: '#303947',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedLeft = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'text-align': 'left',
  color: '#fff',
  border: '0 !important',
};
const usedCenter = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
};
const select = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
};
//Styles ab const einfügen für sep. - kann auch ohne Eigenschaft wie background genutzt werden
export default [
  [
    {
      value: 'LEADER DASHBOARD',
      colSpan: 2,
      style: fix,
      readOnly: true,
      width: '100vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '100vh',
    },
  ],
  [
    {
      value: 'Wichtige Informationen',
      colSpan: 2,
      style: fix,
      readOnly: true,
      width: '100vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Wichtige Telefonnummern (Infos)',
      colSpan: 2,
      style: fix,
      readOnly: true,
      width: '100vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '50vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Themen Dienstbesprechung',
      colSpan: 2,
      style: fix,
      readOnly: true,
      width: '100vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
  [{ value: '', width: '100vh', style: usedCenter, colSpan: 2 }],
];
