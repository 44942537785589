import { lensPath, set } from 'ramda';
import * as types from '../../../types';
import { setData } from './player';

const deathsP = ['apps', 'admin', 'deaths'];

export const appsAdminDeathsSearchP = [...deathsP, 'search'];
export const appsAdminDeathsSearchL = lensPath(appsAdminDeathsSearchP);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminDeathsSearchL, search, state);
};

export const appsAdminDeathsResultsP = [...deathsP, 'results'];
export const appsAdminDeathsCompletedP = [...deathsP, 'completed'];
const setResults = setData(appsAdminDeathsResultsP, appsAdminDeathsCompletedP);

export const appsAdminDeathsReducerTree = {
  [types.ADMIN_DEATHS_SEARCH_SET]: [setSearch],
  [types.ADMIN_DEATHS_FETCH_SUCCESS]: [setResults],
};
