import React, { useState, useRef, useEffect } from 'react';
import format from 'date-fns/format';
import { BiSearch } from 'react-icons/bi';
import apps from '../configs/apps';
import launchpad from '../configs/launchpad';
import { useAuthentication } from '../utils/authentication';
import { dockSvg } from './dock/DockItem';
import { SvgIcon } from '@mui/material';
import { css } from '@emotion/react';

const mainIconStyle = css`
  width: 15vh;
  height: 15vh;
`;
const iconListStyle = css`
  width: 70%;
`;

const allApps = (hasRight: (right: string) => boolean): any => ({
  app: apps(hasRight),
  portfolio: launchpad,
});

const getRandom = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getRandomDate = () => {
  const timeStamp = new Date().getTime();
  const randomStamp = getRandom(0, timeStamp);
  const date = format(randomStamp, 'MM/dd/yyyy');
  return date;
};

const Spotlight = (props: any) => {
  const [searchText, setSearchText] = useState('');
  const [curSelectIndex, setCurSelectIndex] = useState(0);
  const spotlightRef = useRef(null);
  const [hasRight] = useAuthentication();

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        spotlightRef &&
        // @ts-ignore
        !spotlightRef.current.contains(e.target) &&
        !props.btnRef.current.contains(e.target)
      )
        props.toggleSpotlight();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [spotlightRef]);

  const search = (type: string) => {
    if (searchText === '') {
      return [];
    }
    const text = searchText.toLowerCase();
    const list = allApps(hasRight)[type].filter((item: any) => {
      return (
        item.title.toLowerCase().includes(text) ||
        item.id.toLowerCase().includes(text)
      );
    });
    return list;
  };

  const handleClick = (id: string) => {
    const curSelectIndex = appIdList.findIndex((item) => {
      return item === id;
    });
    updateHighlight(curSelectIndex, curSelectIndex);
    setCurSelectIndex(curSelectIndex);
  };

  const handleDoubleClick = (id: string) => {
    handleClick(id);
    launchCurApp();
  };

  const launchCurApp = () => {
    if (!curDetails) {
      return;
    }

    if (curDetails.type === 'app' && !curDetails.link) {
      const id = curDetails.id;
      if (id === 'launchpad') {
        props.toggleLaunchpad(true);
      } else {
        props.openApp(id);
      }
      props.toggleSpotlight();
    } else {
      window.open(curDetails.link);
      props.toggleSpotlight();
    }
  };

  const getTypeAppList = (type: string, startIndex: number) => {
    const result = search(type);
    let appList = [];
    let appIdList = [];

    for (let app of result) {
      const curIndex = startIndex + appIdList.length;
      const bg = curIndex === 0 ? 'bg-blue-500' : 'bg-transparent';
      const text = curIndex === 0 ? 'text-white' : 'text-black';

      appList.push(
        <li
          id={`spotlight-${app.id}`}
          key={`spotlight-${app.id}`}
          className={`pl-4 h-7 w-full flex flex-row ${bg} ${text} cursor-default`}
          data-app-type={type}
          onClick={() => handleClick(app.id)}
          onDoubleClick={() => handleDoubleClick(app.id)}
        >
          <div className="flex-none w-8 flex items-center">
            <div css={iconListStyle}>
              <SvgIcon
                component={app.icon}
                className="text-black"
                css={() => dockSvg(app.bgColor)}
              />
            </div>
          </div>
          <div className="flex-grow flex items-center pl-3">{app.title}</div>
        </li>
      );
      appIdList.push(app.id);
    }

    return {
      appList: appList,
      appIdList: appIdList,
    };
  };

  const updateHighlight = (prevIndex: number, curIndex: number) => {
    // remove highlight
    const prevAppId = appIdList[prevIndex];
    const prev: any = document.querySelector(`#spotlight-${prevAppId}`);
    let classes = prev.className;
    classes = classes.replace('text-white', 'text-black');
    classes = classes.replace('bg-blue-500', 'bg-transparent');
    prev.className = classes;

    // add highlight
    const curAppId = appIdList[curIndex];
    const cur: any = document.querySelector(`#spotlight-${curAppId}`);
    classes = cur.className;
    classes = classes.replace('text-black', 'text-white');
    classes = classes.replace('bg-transparent', 'bg-blue-500');
    cur.className = classes;
  };

  const handleKeyPress = (e: any) => {
    const keyCode = e.key;
    const numApps = appIdList.length;

    // ----------- select next app -----------
    if (keyCode === 'ArrowDown' && curSelectIndex < numApps - 1) {
      setCurSelectIndex(curSelectIndex + 1);
      updateHighlight(curSelectIndex, curSelectIndex + 1);
    }
    // ----------- select previous app -----------
    else if (keyCode === 'ArrowUp' && curSelectIndex > 0) {
      setCurSelectIndex(curSelectIndex - 1);
      updateHighlight(curSelectIndex, curSelectIndex - 1);
    }
    // ----------- launch app -----------
    else if (keyCode === 'Enter') {
      if (!curDetails) {
        return;
      }
      launchCurApp();
    }
  };

  const handleInputChange = (e: any) => {
    setCurSelectIndex(0);
    setSearchText(e.target.value);
  };

  const app = getTypeAppList('app', 0);
  const portfolio = getTypeAppList('portfolio', app.appIdList.length);

  const appIdList = [...app.appIdList, ...portfolio.appIdList];
  const indexOfFirstPortfolio = app.appIdList.length;

  const appList = (
    <div>
      {app.appList.length !== 0 && (
        <div>
          <div className="pl-6 py-0.5 text-xs leading-none bg-gray-400 bg-opacity-80 flex items-center text-black">
            Applications
          </div>
          <ul className="w-full text-xs">{app.appList}</ul>
        </div>
      )}
      {portfolio.appList.length !== 0 && (
        <div>
          <div className="pl-6 py-0.5 text-xs leading-none bg-gray-400 bg-opacity-80 flex items-center text-black">
            Portfolio
          </div>
          <ul className="w-full text-xs">{portfolio.appList}</ul>
        </div>
      )}
    </div>
  );

  const appId = appIdList[curSelectIndex];
  const elem: any = document.querySelector(`#spotlight-${appId}`);
  const id = appId;
  const type = curSelectIndex < indexOfFirstPortfolio ? 'app' : 'portfolio';
  const curDetails = type
    ? allApps(hasRight)[type].find((item: any) => {
        return item.id === id;
      })
    : null;
  if (curDetails) {
    curDetails.type = type;
  }

  const focusOnInput = () => {
    // @ts-ignore
    document.querySelector('#spotlight-input').focus();
  };

  return (
    <div
      className="spotlight fixed top-1/4 -mt-16 h-max rounded-md bg-gray-50 bg-opacity-80 blur border border-gray-400 border-opacity-50 shadow-2xl"
      style={{ zIndex: 99997 }}
      onKeyDown={handleKeyPress}
      onClick={focusOnInput}
      ref={spotlightRef}
    >
      <div className="w-full grid grid-cols-8 sm:grid-cols-11 h-12 sm:h-14 rounded-md bg-transparent">
        <div className="col-start-1 col-span-1 flex justify-center items-center">
          <BiSearch className="ml-1 text-gray-600" size={28} />
        </div>
        <input
          id="spotlight-input"
          className="col-start-2 col-span-7 sm:col-span-10 outline-none focus:outline-none bg-transparent px-1 text-black text-xl sm:text-2xl"
          placeholder="App Search"
          value={searchText}
          onChange={(e) => handleInputChange(e)}
          autoFocus={true}
        />
      </div>
      {searchText !== '' && (
        <div
          className="bg-transparent flex flex-row border-t border-gray-400 border-opacity-50"
          style={{ height: '341px' }}
        >
          <div className="flex-none w-32 sm:w-72 border-r border-gray-400 border-opacity-50 overflow-y-scroll">
            {appList}
          </div>
          <div className="flex-grow">
            {curDetails && (
              <div className="h-full w-full flex flex-col">
                <div className="mx-auto w-4/5 flex-none flex flex-col items-center justify-center h-56 border-b border-gray-400 border-opacity-50">
                  <div css={mainIconStyle}>
                    <SvgIcon
                      component={curDetails.icon}
                      className="text-black"
                      css={() => dockSvg(curDetails.bgColor)}
                    />
                  </div>
                  <div className="mt-4 text-xl text-black">
                    {curDetails.title}
                  </div>
                  <div className="text-xs text-gray-500">
                    {`Version: ${getRandom(0, 99)}.${getRandom(0, 999)}`}
                  </div>
                </div>
                <div className="flex-grow flex flex-row text-xs">
                  <div className="flex-none w-1/2 flex flex-col items-end justify-center text-gray-500">
                    <div>Kind</div>
                    <div>Size</div>
                    <div>Created</div>
                    <div>Modified</div>
                    <div>Last opened</div>
                  </div>
                  <div className="pl-2 flex-grow flex flex-col items-start justify-center text-black">
                    <div>
                      {curDetails.type === 'app' ? 'Application' : 'Portfolio'}
                    </div>
                    <div>{`${getRandom(0, 999)} G`}</div>
                    <div>{getRandomDate()}</div>
                    <div>{getRandomDate()}</div>
                    <div>{getRandomDate()}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Spotlight;
