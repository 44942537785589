import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminVehiclDetailP = ['apps', 'admin', 'vehicle', 'detail'];
export const appsAdminVehiclDetailL = lensPath(appsAdminVehiclDetailP);
const setDetail = (state: any, { data }: any) => {
  return set(appsAdminVehiclDetailL, data, state);
};

export const appsAdminVehiclOwnerSearchP = [
  'apps',
  'admin',
  'vehicle',
  'ownersearch',
];
export const appsAdminVehiclOwnerSearchL = lensPath(
  appsAdminVehiclOwnerSearchP
);
const setOwnerSearch = (state: any, { data }: any) => {
  return set(appsAdminVehiclOwnerSearchL, data, state);
};

export const appsAdminVehicleReducerTree = {
  [types.ADMIN_VEHICLE_FETCH_SUCCESS]: [setDetail],
  [types.ADMIN_VEHICLE_OWNER_NEW_SEARCH_SUCCESS]: [setOwnerSearch],
};
