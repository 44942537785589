import { css } from '@emotion/react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { find, range } from 'ramda';
import React from 'react';

const selectContainerStyle = css`
  margin: -0.4vh;
  width: 100%;
`;

// the close button is a svg
// the click can be on the svg or the inner path element
const isEventTargetSelectClearBtn = (ev: any) =>
  ev.target.tagName === 'path' || ev.target.tagName === 'svg';

const renderCheckbox = (cell: any, onChange: (newValue: any) => void) => {
  return (
    <input
      type="checkbox"
      checked={cell.value}
      onChange={() => onChange(!cell.value)}
    />
  );
};

const renderSelect = (
  cell: any,
  onChange: (newValue: any) => void,
  scope: { [sheetId: string]: any[][] }
) => {
  const depOptions = cell.depOptions;
  const options = cell.options
    ? cell.options.map((value: string) => ({ value, label: value }))
    : depOptions
    ? range(depOptions.rowStart, depOptions.rowEnd + 1).map((index) => {
        const propName = depOptions.sheetId
          ? depOptions.sheetId + '$' + depOptions.column + index
          : '' + depOptions.column + index;
        const val = scope[propName];
        return val
          ? {
              label: val,
              value: val,
            }
          : null;
      })
    : [];

  const selectOptions = [
    { value: '', label: '-' },
    ...options.filter((x: any) => !!x),
  ];

  const selectedOption =
    find((x: any) => x.value === cell.value, selectOptions) || selectOptions[0];

  return (
    <div css={selectContainerStyle}>
      <Autocomplete
        getOptionLabel={(option: any) => option.label || ''}
        // getOptionSelected={(option: any, value: any) => option.value === value.value}
        value={selectedOption}
        onChange={(ev: any, option: any) => {
          if ((option && option.value) || isEventTargetSelectClearBtn(ev)) {
            onChange(option?.value ?? '');
          }
        }}
        options={selectOptions}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};

const renderLookup = (
  cell: any,
  onChange: (newValue: any) => void,
  scope: { [sheetId: string]: any[][] }
) => {
  const lookup = cell.lookup;
  const useIndex = cell.useIndex;

  let foundIndex = -1;
  range(lookup.rowStart, lookup.rowEnd + 1).forEach((index: number) => {
    const propName = lookup.sheetId
      ? lookup.sheetId + '$' + (lookup.column + index)
      : '' + (lookup.column + index);
    if (scope[propName] === scope[cell.searchField]) {
      foundIndex = index - lookup.rowStart;
    }
  });

  const propName = useIndex.sheetId
    ? useIndex.sheetId +
      '$' +
      useIndex.column +
      (foundIndex + useIndex.rowStart)
    : '' + useIndex.column + (foundIndex + useIndex.rowStart);

  return foundIndex === -1 ? '' : scope[propName];
};

const cellRenderMap: any = {
  default: (cell: any) => cell.value,
  checkbox: renderCheckbox,
  select: renderSelect,
  lookup: renderLookup,
};

export const renderCell = (
  cell: any,
  row: number,
  col: number,
  onCellsChanged: any,
  scope: { [sheetId: string]: any[][] }
) => {
  let style = `
  min-height: 3vh;
  height: 100%;
    border: 1px solid #e5e7eb;
    padding: 0.4vh;
    display: flex;
    align-items: center;
  `;

  if (cell.style) {
    const keys = Object.keys(cell.style);
    keys.forEach((key) => {
      if (key === 'classStyling') {
        cell.style[key].forEach((subStyling: any) => {
          let subStyle = '';
          const subKeys = Object.keys(subStyling.style);
          subKeys.forEach((key) => {
            subStyle += `${key}: ${subStyling.style[key]};`;
          });
          style += `.${subStyling.class} {${subStyle}}`;
        });
      } else {
        style += `${key}: ${cell.style[key]};`;
      }
    });
  }

  const contentRender = cellRenderMap[cell.type] || cellRenderMap.default;

  const change = (newValue: any) => {
    onCellsChanged([{ row, col, value: newValue }]);
  };

  return (
    <div
      css={css`
        ${style}
      `}
    >
      {contentRender(cell, change, scope)}
    </div>
  );
};
