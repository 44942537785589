export default [
  {
    type: 'h1',
    text: 'Medium House - Mittlere Preisklasse mit viel Individualiserung',
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Das Medium House ist die perfekte Lösung, wenn Sie eine indivduelle Einrichtung ohne zu große Ausgaben wollen. Sie finden diverse farblich ansprechend gestaltete Räume zu Ihrer Verfügung. Mit Hilfe ihrer indivduellen Einrichtung aus dem lokalen Baumarkt kann jedes Zimmer zu einem Traum werden',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-house/floor.jpg',
      'img/interior/medium-house/livingroom.jpg',
      'img/interior/medium-house/livingroom2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Die kleine, aber gut ausgestattete Küche, bietet Platz zum kochen und kommt voll ausgestattet. Ihr neues rustikale Badezimmer bietet eine gute Erholung in einem warmen Bad.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-house/kitchen1.jpg',
      'img/interior/medium-house/kitchen2.jpg',
      'img/interior/medium-house/bathroom.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Weiterhin stehen ihnen 3 weitere Zimmer zu ihrer freien Verfügung. Schlafzimmer, Gästezimmer oder doch ein Fitnessraum? Oder doch etwas ganz individuelles? Ihrer Gestaltung sind keine Grenzen gesetzt.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/medium-house/room1.jpg',
      'img/interior/medium-house/room2.jpg',
      'img/interior/medium-house/room3.jpg',
    ],
    customCss: '',
  },
];
