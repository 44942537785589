import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  InputAdornment,
} from '@mui/material';
import { DateTime } from 'luxon';
import SearchIcon from '@mui/icons-material/SearchRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import {
  documentsListP,
  documentsSearchP,
} from '../../../redux/reducer/apps/documents';
import {
  createNewDocument,
  getMyDocuments,
  setDocumentsSearch,
  deleteDocument,
} from '../../../actions';
import { DocumentListType } from './types';
import DeleteIcon from '@mui/icons-material/Delete';

const pagiantionStyle = css`
  margin-top: 1vh;
  width: 100%;
  justify-content: center;
  display: flex;
`;
const headlineStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;
`;
const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

const containerStyle = css`
  padding: 2vh;
`;

type DocumentHomeType = {
  openDocument: (id: string, name: string) => void;
  isSelected: boolean;
};
export const DocumentHome = ({
  openDocument,
  isSelected,
}: DocumentHomeType) => {
  const dispatch = useDispatch();
  const search = useSelector(pathOr('', documentsSearchP));
  const { list, page, pages } = useSelector<any, DocumentListType>(
    pathOr<any>({ list: [], page: 1, pages: 1 }, documentsListP)
  );

  const setSearch = (value: string) => {
    dispatch(setDocumentsSearch(value));
  };

  const changePage = (ev: any, newPage: number) => {
    dispatch(getMyDocuments(newPage, search));
  };

  const fetchDocuments = () => {
    dispatch(getMyDocuments(page, search));
  };

  const onDeleteDocument = (id: string) => {
    dispatch(deleteDocument(id));
  };

  const onSearchInput = (key: string) => {
    if (key === 'Enter') {
      fetchDocuments();
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const createNew = () => {
    dispatch(createNewDocument());
  };

  return isSelected ? (
    <div css={containerStyle}>
      <div css={headlineStyle}>
        <TextField
          css={inputStyle}
          label="Suche"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyPress={(e) => onSearchInput(e.key)}
          InputProps={{
            endAdornment: search !== '' && (
              <>
                <InputAdornment
                  position="end"
                  css={{ cursor: 'pointer' }}
                  onClick={() => fetchDocuments()}
                >
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
        <Button variant="outlined" onClick={createNew} startIcon={<AddIcon />}>
          Neues Dokument
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Titel</TableCell>
            <TableCell>Änderungsdatum</TableCell>
            <TableCell>Erstellungsdatum</TableCell>
            <TableCell>Öffnen</TableCell>
            <TableCell>Löschen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>{entry.subject}</TableCell>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.modifiedDate))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.creationDate))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => openDocument(entry._id, entry.subject)}
                >
                  Öffnen
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => onDeleteDocument(entry._id)}
                >
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div css={pagiantionStyle}>
        <Pagination
          page={page}
          count={pages}
          onChange={changePage}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  ) : null;
};
