import { set, lensPath, pipe } from 'ramda';

import * as types from '../types';

export const screenshotLoadingP = ['leogram', 'newMessageScreenshotLoading'];
export const screenshotLoadingL = lensPath(screenshotLoadingP);

export const screenshotP = ['leogram', 'newMessageScreenshot'];
export const screenshotL = lensPath(screenshotP);

const setScreenshotLoading = (enabled: boolean) => (state: any) => {
  return set(screenshotLoadingL, enabled, state);
};

const onScreenshotSuccess = (state: any, { id, thumb, url }: any) => {
  return pipe<any, any, any>(
    set(screenshotLoadingL, false),
    set(screenshotL, { id, thumb, url })
  )(state);
};

export const screenshotReducerTree = {
  [types.SCREENSHOT_INIT]: [setScreenshotLoading(true)],
  [types.SCREENSHOT_CREATE_SUCCESS]: [onScreenshotSuccess],
};
