import * as type from '../redux/types';

export const setDesktopAppsShow = (data: any) => ({
  type: type.DESKTOP_APPS_SHOW_SET,
  data,
});

export const setDesktopAppsZ = (data: any) => ({
  type: type.DESKTOP_APPS_Z_SET,
  data,
});

export const setDesktopAppsMaxZ = (data: any) => ({
  type: type.DESKTOP_APPS_Z_MAX_SET,
  data,
});
export const setDesktopAppsMax = (data: any) => ({
  type: type.DESKTOP_APPS_MAX_SET,
  data,
});
export const setDesktopAppsMin = (data: any) => ({
  type: type.DESKTOP_APPS_MIN_SET,
  data,
});

export const setDesktopTitle = (data: any) => ({
  type: type.DESKTOP_TITLE_SET,
  data,
});

export const openDesktopApp = (id: string, title: string) => ({
  type: type.DESKTOP_APP_OPEN,
  id,
  title,
});
