import { Dispatch } from 'redux';
import { initStartup } from './startup';

function linkEvents(dispatch: Dispatch<any>, getState: () => any) {
  initStartup(dispatch, getState);
}

export const middleware = (store: any) => {
  const { getState, dispatch } = store;
  setTimeout(linkEvents, 0, dispatch, getState);
  return (next: any) => (action: any) => next(action);
};
