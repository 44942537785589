import { lensPath, set } from 'ramda';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { CompanyData, CompanyEmployeeData } from './types';
import { isProductionRunning } from './utils';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import DoneIcon from '@mui/icons-material/DoneRounded';
import SendIcon from '@mui/icons-material/SendRounded';
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import Headline from '../../Headline';

const rightList = [
  ['Informationen', 'readBusinessInformation', 'writeBusinessInformation'],
  ['Finanzen', 'readFinance', 'writeFinance'],
  ['Mitarbeiter', 'readEmployees', 'writeEmployees'],
  ['Lager', 'readStorage', 'writeStorage'],
  ['Bestelllungen', 'readOrders', 'writeOrders'],
  ['Gehälter', 'readSalary', 'writeSalary'],
  ['Mitarbeiter Check Ins', 'readEmployeeCheckin', 'writeEmployeeCheckin'],
  ['Produktionen', 'readProduction', 'writeProduction'],
  ['Ladentheke', 'readCounterItems', 'writeCounterItems'],
  ['Fahrzeugzugriff', 'readVehicles', 'accessVehicles'],
  ['An/Verkauf Einstellungen', 'readCommerceItems', 'writeCommerceItems'],
  ['Exportaufträge', 'readExportAssignments', 'writeExportAssignments'],
  ['Importauktion', 'readImportAssignments', 'writeImportAssignments'],
  ['Aktenzugriff', undefined, 'accessFolders'],
];

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

type EmployeeType = {
  company: CompanyData;
  editEmployeeRights: (id: string, employeeId: string, newRights: any) => void;
  fire: (id: string, name: string) => void;
  invite: (id: string, name: string) => void;
  changeSalaries: (
    id: string,
    employeeData: { _id: string; money: number }[]
  ) => void;
};

export const Employee = ({
  company,
  editEmployeeRights,
  fire,
  invite,
  changeSalaries,
}: EmployeeType) => {
  const [newEmployee, setNewEmployee] = useState('');
  const [showEmployeeInput, setShowEmployeeInput] = useState(false);
  const [employeeInEdit, setEmployeeInEdit] =
    useState<CompanyEmployeeData | null>(null);
  const [firingEmployee, setFiringEmployee] =
    useState<CompanyEmployeeData | null>(null);
  const [salaryEmployee, setSalaryEmployee] =
    useState<CompanyEmployeeData | null>(null);

  const employeeInEditRights: any = employeeInEdit?.rights;

  const isProdRunning = isProductionRunning(company);

  const sendInvitation = () => {
    if (!isProdRunning) {
      invite(company._id, newEmployee);
      setShowEmployeeInput(false);
    }
  };

  return (
    <div>
      <Headline>Mitarbeiter</Headline>
      {!showEmployeeInput && (
        <PersonAddIcon onClick={() => setShowEmployeeInput(true)} />
      )}
      {showEmployeeInput && (
        <div>
          <TextField
            type="text"
            value={newEmployee}
            placeholder="Max_Mustermann"
            onChange={(ev) => setNewEmployee(ev.target.value)}
            css={inputStyle}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonAddIcon />
                </InputAdornment>
              ),
              endAdornment: newEmployee !== '' && (
                <InputAdornment
                  position="end"
                  css={{ cursor: 'pointer' }}
                  onClick={sendInvitation}
                >
                  <SendIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Gehalt</TableCell>
            <TableCell>im Dienst</TableCell>
            {company.playerRights.writeEmployees && (
              <TableCell>Rechte bearbeiten</TableCell>
            )}
            {company.playerRights.writeEmployees && (
              <TableCell>entlassen</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {company.employees.map((employee) => (
            <TableRow key={employee._id}>
              <TableCell>{employee.character?.name}</TableCell>
              <TableCell>
                ${employee.money}{' '}
                {company.playerRights.writeEmployees && !isProdRunning && (
                  <span onClick={() => setSalaryEmployee(employee)}>
                    <EditIcon />
                  </span>
                )}
              </TableCell>
              <TableCell>
                {employee.duty ? <DoneIcon /> : <ClearIcon />}
              </TableCell>
              {company.playerRights.writeEmployees && !isProdRunning && (
                <TableCell onClick={() => setEmployeeInEdit(employee)}>
                  <EditIcon />
                </TableCell>
              )}
              {company.playerRights.writeEmployees && !isProdRunning && (
                <TableCell onClick={() => setFiringEmployee(employee)}>
                  <DeleteIcon />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {employeeInEdit && (
        <>
          <Headline>{employeeInEdit.character?.name} bearbeiten</Headline>

          <h3>Rechte</h3>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Beschreibung</TableCell>
                <TableCell>Lesen</TableCell>
                <TableCell>Schreiben</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rightList.map(([description, read, write]) => (
                <TableRow key={description}>
                  <TableCell>{description}</TableCell>
                  <TableCell>
                    {read ? (
                      <Checkbox
                        checked={employeeInEditRights[read]}
                        onChange={() =>
                          setEmployeeInEdit(
                            set(
                              lensPath(['rights', read]),
                              !employeeInEditRights[read],
                              employeeInEdit
                            )
                          )
                        }
                      />
                    ) : (
                      <span />
                    )}
                  </TableCell>
                  <TableCell>
                    {write ? (
                      <Checkbox
                        checked={employeeInEditRights[write]}
                        onChange={() =>
                          setEmployeeInEdit(
                            set(
                              lensPath(['rights', write]),
                              !employeeInEditRights[write],
                              employeeInEdit
                            )
                          )
                        }
                      />
                    ) : (
                      <span />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Button
            onClick={() =>
              editEmployeeRights(
                company._id,
                employeeInEdit._id,
                employeeInEditRights
              )
            }
          >
            Bearbeitung speichern
          </Button>
          <Button onClick={() => setEmployeeInEdit(null)}>
            Bearbeitung abbrechen
          </Button>
        </>
      )}

      {firingEmployee && (
        <>
          <h3>{firingEmployee.character?.name} entlassen</h3>
          <div>
            <Button
              onClick={() => {
                fire(company._id, firingEmployee.character?.name || '');
                setFiringEmployee(null);
              }}
            >
              Mitarbeiter englültig entlassen
            </Button>
            <Button onClick={() => setFiringEmployee(null)}>
              Entlassung abbrechen
            </Button>
          </div>
        </>
      )}

      {salaryEmployee && (
        <>
          <Headline>{salaryEmployee.character?.name} bearbeiten</Headline>

          <div>
            <span>Gehalt $</span>
            <TextField
              type="number"
              value={salaryEmployee.money}
              onChange={(ev) =>
                setSalaryEmployee({
                  ...salaryEmployee,
                  money: parseFloat(ev.target.value),
                })
              }
            />
          </div>

          <Button
            onClick={() => {
              changeSalaries(company._id, [
                { _id: salaryEmployee._id, money: salaryEmployee.money },
              ]);
            }}
          >
            Bearbeitung speichern
          </Button>
          <Button onClick={() => setSalaryEmployee(null)}>
            Bearbeitung abbrechen
          </Button>
        </>
      )}
    </div>
  );
};
