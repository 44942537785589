import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import nightwind from 'nightwind/helper';

import TopBar from '../components/menus/TopBar';
import Dock from '../components/dock/Dock';
import Launchpad from '../components/Launchpad';
import Window from '../components/Window';
import Spotlight from '../components/Spotlight';
import apps from '../configs/apps';
import wallpapers from '../configs/wallpapers';
import { useAuthentication } from '../utils/authentication';
import ControlCenterMenu from '../components/menus/ControlCenterMenu';
import { brightnessP, darkP } from '../redux/reducer/common';
import { pathOr } from 'ramda';
import AppleMenu from '../components/menus/AppleMenu';
import WifiMenu from '../components/menus/WifiMenu';
import {
  checkLogin,
  openDesktopApp,
  setDesktopAppsMax,
  setDesktopAppsMin,
  setDesktopAppsShow,
  setDesktopTitle,
} from '../actions';
import {
  desktopAppsMaxP,
  desktopAppsMinP,
  desktopAppsShowP,
  desktopAppsZP,
  desktopTitleP,
} from '../redux/reducer/desktop';

const Desktop = ({
  dark,
  brightness = 1,
  setLogin,
  shutMac,
  sleepMac,
  restartMac,
}: any) => {
  const dispatch = useDispatch();

  const showApps = useSelector<any, { [id: string]: boolean }>(
    pathOr({}, desktopAppsShowP)
  );
  const setShowApps = (data: any) => {
    dispatch(setDesktopAppsShow(data));
  };

  const appsZ = useSelector<any, { [id: string]: number }>(
    pathOr({}, desktopAppsZP)
  );

  const maxApps = useSelector<any, { [id: string]: boolean }>(
    pathOr({}, desktopAppsMaxP)
  );
  const setMaxApps = (data: any) => {
    dispatch(setDesktopAppsMax(data));
  };

  const minApps = useSelector<any, { [id: string]: boolean }>(
    pathOr({}, desktopAppsMinP)
  );
  const setMinApps = (data: any) => {
    dispatch(setDesktopAppsMin(data));
  };

  const currentTitle = useSelector<any, string>(pathOr('', desktopTitleP));
  const setCurrentTitle = (data: any) => {
    dispatch(setDesktopTitle(data));
  };

  const [showLaunchpad, setShowLaunchpad] = useState(false);
  const [hideDock, setHideDock] = useState(false);
  const [spotlight, setSpotlight] = useState(false);
  const [spotlightBtnRef, setSpotlightBtnRef] = useState(false);
  const [controlCenter, setControlCenter] = useState(false);
  const [controlCenterBtnRef, setControlCenterBtnRef] = useState(false);
  const [appleMenu, setAppleMenu] = useState(false);
  const [appleMenuBtnRef, setAppleMenuBtnRef] = useState(false);
  const [wifiMenu, setWifiMenu] = useState(false);
  const [wifiMenuBtnRef, setWifiMenuBtnRef] = useState(false);
  const [hasRight] = useAuthentication();

  useEffect(() => {
    dispatch(checkLogin());
  }, []);

  const toggleLaunchpad = (target: boolean) => {
    const r: any = document.querySelector(`#launchpad`);
    if (target) {
      r.style.transform = 'scale(1)';
      r.style.transition = 'ease-in 0.2s';
    } else {
      r.style.transform = 'scale(1.1)';
      r.style.transition = 'ease-out 0.2s';
    }

    setShowLaunchpad(target);
  };

  const toggleSpotlight = () => {
    setSpotlight(!spotlight);
  };

  const setWinowsPosition = (id: string) => {
    const container: any = document.getElementById('MainContainer');
    const r: any = document.querySelector(`#window-${id}`);
    const containerRect = container.getBoundingClientRect();
    const rect = r.getBoundingClientRect();
    r.style.setProperty(
      '--window-transform-x',
      (rect.x - containerRect.x).toFixed(1).toString() + 'px'
    );
    r.style.setProperty(
      '--window-transform-y',
      (rect.y - containerRect.y).toFixed(1).toString() + 'px'
    );
  };

  const closeApp = (id: string) => {
    setShowApps({
      ...showApps,
      [id]: false,
    });
    setHideDock(false);
    setCurrentTitle('');
  };

  const openApp = (id: string) => {
    const title =
      apps(hasRight).find((app) => {
        return app.id === id;
      })?.title || '';

    // abort if already openend
    if (title === currentTitle) {
      return;
    }

    dispatch(openDesktopApp(id, title));
  };

  const setAppMax = (id: string, target: boolean) => {
    if (target === undefined) {
      target = !maxApps[id];
    }

    setMaxApps({
      ...maxApps,
      [id]: target,
    });
    setHideDock(target);
  };

  const setAppMin = (id: string, target: any) => {
    if (target === undefined) {
      target = !minApps[id];
    }

    setMinApps({
      ...minApps,
      [id]: target,
    });
  };

  const minimizeApp = (id: string) => {
    setWinowsPosition(id);

    // get the corrosponding dock icon's position
    let r: any = document.querySelector(`#dock-${id}`);
    const dockAppRect = r.getBoundingClientRect();

    r = document.querySelector(`#window-${id}`);
    const appRect = r.getBoundingClientRect();
    const posY =
      document.body.offsetHeight -
      appRect.y.toFixed(1) -
      // @ts-ignore
      (r.offsetHeight / 2).toFixed(1);
    // @ts-ignore
    const posX = dockAppRect.x.toFixed(1) - (r.offsetWidth / 2).toFixed(1) + 25;

    // translate the window to that position
    r.style.transform = `translate(${posX}px, ${posY}px) scale(0.2)`;
    r.style.transition = 'ease-out 0.3s';

    // add it to the minimized app list
    setAppMin(id, true);

    setCurrentTitle('');
  };

  const renderAppWindows = () => {
    return apps(hasRight).map((app) => {
      if (app.desktop && showApps[app.id]) {
        const props = {
          title: app.title,
          id: app.id,
          width: app.width,
          height: app.height,
          minWidth: app.minWidth,
          minHeight: app.minHeight,
          z: appsZ[app.id],
          max: maxApps[app.id],
          min: minApps[app.id],
          close: closeApp,
          setMax: setAppMax,
          setMin: minimizeApp,
          focus: openApp,
        };

        return (
          <Window key={`desktop-app-${app.id}`} {...props}>
            {app.content}
          </Window>
        );
      } else {
        return <div key={`desktop-app-${app.id}`} />;
      }
    });
  };

  return (
    <div
      id="MainContainer"
      className="overflow-hidden bg-center bg-cover"
      style={{
        backgroundImage: `url(${dark ? wallpapers.night : wallpapers.day})`,
        filter: `brightness( ${brightness * 0.7 + 50}% )`,
        height: '100%',
        width: '100%',
      }}
    >
      {/* Dark Model Toggler */}
      <script dangerouslySetInnerHTML={{ __html: nightwind.init() }} />

      {/* Top Menu Bar */}
      <TopBar
        title={currentTitle}
        setLogin={setLogin}
        shutMac={shutMac}
        sleepMac={sleepMac}
        restartMac={restartMac}
        toggleSpotlight={toggleSpotlight}
        setSpotlightBtnRef={(value: any) => {
          setSpotlightBtnRef(value);
        }}
        toggleControlCenter={() => setControlCenter(!controlCenter)}
        setControlCenterBtnRef={(value: any) => {
          setControlCenterBtnRef(value);
        }}
        toggleAppleMenu={() => setAppleMenu(!appleMenu)}
        setAppleMenuBtnRef={(value: any) => {
          setAppleMenuBtnRef(value);
        }}
        toggleWifiMenu={() => setWifiMenu(!wifiMenu)}
        setWifiMenuBtnRef={(value: any) => {
          setWifiMenuBtnRef(value);
        }}
      />

      {/* Desktop Apps */}
      {renderAppWindows()}

      {/* Spotlight */}
      {spotlight && (
        <Spotlight
          openApp={openApp}
          toggleLaunchpad={toggleLaunchpad}
          toggleSpotlight={toggleSpotlight}
          btnRef={spotlightBtnRef}
        />
      )}

      {/* Control Center */}
      {controlCenter && (
        <ControlCenterMenu
          toggleControlCenter={() => setControlCenter(!controlCenter)}
          btnRef={controlCenterBtnRef}
        />
      )}

      {/* Open this when clicking on Apple logo */}
      {appleMenu && (
        <AppleMenu
          toggleAppleMenu={() => setAppleMenu(!appleMenu)}
          btnRef={appleMenuBtnRef}
        />
      )}

      {/* Open this when clicking on Wifi button */}
      {wifiMenu && (
        <WifiMenu
          // @ts-ignore
          btnRef={wifiMenuBtnRef}
          // @ts-ignore
          toggleWifiMenu={() => setWifiMenu(!wifiMenu)}
        />
      )}

      {/* Launchpad */}
      {/* @ts-ignore */}
      <Launchpad show={showLaunchpad} />

      {/* Dock */}
      <Dock
        open={openApp}
        showApps={showApps}
        showLaunchpad={showLaunchpad}
        toggleLaunchpad={toggleLaunchpad}
        hide={false}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
    brightness: pathOr(100, brightnessP, state),
  };
};

// @ts-ignore
export default connect(mapStateToProps, null)(Desktop);
