import React, { useState } from 'react';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/LockRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import Headline from '../../Headline';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

type ChangePinType = {
  vban: string;
  close: () => void;
  changePin: (
    vban: string,
    currentPin: string,
    newPin: string,
    newPinRe: string
  ) => void;
};

export const ChangePin = ({ vban, close, changePin }: ChangePinType) => {
  const [currentPin, setCurrentPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [newPinConfirm, setNewPinConfirm] = useState('');

  const change = () => {
    changePin(vban, currentPin, newPin, newPinConfirm);
    close();
  };

  return (
    <div>
      <Headline>PIN ändern</Headline>
      <TextField
        css={inputStyle}
        type="password"
        placeholder="Aktueller PIN"
        value={currentPin}
        onChange={(ev) => setCurrentPin(ev.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        css={inputStyle}
        type="password"
        placeholder="Neuer PIN (4 Zahlen)"
        value={newPin}
        onChange={(ev) => setNewPin(ev.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        css={inputStyle}
        type="password"
        placeholder="Neuer PIN bestätigen"
        value={newPinConfirm}
        onChange={(ev) => setNewPinConfirm(ev.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button onClick={change} startIcon={<SaveIcon />}>
        PIN ändern
      </Button>

      <Button onClick={close} startIcon={<CloseIcon />}>
        Schliessen
      </Button>
    </div>
  );
};
