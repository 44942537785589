import { set, lensPath } from 'ramda';

import * as types from '../types';

export const profileSearchContentP = ['leogram', 'profileSearchContent'];
export const profileSearchContentL = lensPath(profileSearchContentP);
export const profileSearchTextP = ['leogram', 'profileSearchText'];
export const profileSearchTextL = lensPath(profileSearchTextP);

const setSearch = (state: any, { text }: any) => {
  return set(profileSearchTextL, text, state);
};

const searchFetchSuccess = (state: any, { data }: any) => {
  return set(profileSearchContentL, data, state);
};

export const profileSearchReducerTree = {
  [types.LEOGRAM_PROFILE_SEARCH_FETCH_SUCCESS]: [searchFetchSuccess],
  [types.LEOGRAM_PROFILE_SEARCH_SET]: [setSearch],
};
