import { css } from '@emotion/react';
import WifiIcon from '@mui/icons-material/WifiRounded';
import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { usernameP } from '../../../redux/reducer/login';
import { BankAccount } from './types';

const accountCardContainer = (theme: any) => css`
  position: relative;
  display: inline-block;
  background: linear-gradient(
    145deg,
    ${theme.colors.RedLight},
    ${theme.colors.Yellow}
  );
  border-radius: 1vh;
  margin-right: 2.5vh;
`;

const cardBodyStyle = css`
  width: 35vh;
  height: 20vh;
  padding: 0.75vh 1.75vh;
  position: relative;
  display: inline-block;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 15vh rgba(255, 255, 255, 0.75);
    filter: blur(1vh);
  }
`;

const bankTextStyle = css`
  color: white;
  font-size: 1.5vh;
  font-family: Tahoma, sans-serif;
`;

const bankAccountTextStyle = css`
  color: white;
  font-size: 1vh;
  font-family: Tahoma, sans-serif;
`;

const chips = css`
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='50px' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cg id='surface1'%3E%3Cpath fill='%23F5CE85' d='M8.9,34.5c-6.4,0.1-6.4,0.1-6.4-6.4V11.9c0-3.5-0.1-6.4,3.4-6.4h25.3c6.4,0,6.4,0,6.4,6.4v16.3 c0,6.4,0,6.4-6.4,6.4L8.9,34.5z'/%3E%3Cpath fill='%23967A44' d='M31.1,6C37,6,37,6,37,11.9v16.3c0,5.9,0,5.9-5.9,5.9H8.9c-5.9,0-5.9,0-5.9-6V11.9C3,8.2,2.7,6,6,6H31.1 M31.1,5H9.2C2.3,5,2,5,2,11.9v16.3C2,35,2,35,8.9,35h22.3c6.9-0.1,6.9-0.1,6.9-6.9V11.9C38.1,5,38.1,5,31.1,5z'/%3E%3Cpath fill='%23967A44' d='M29,35c-3.3,0-6-2.7-6-6s2.7-6,6-6h8.5v1H29c-2.8,0-5,2.2-5,5s2.2,5,5,5V35z'/%3E%3Cpath fill='%23967A44' d='M11,35v-1c2.8,0,5-2.2,5-5s-2.2-5-5-5H2.5v-1H11c3.3,0,6,2.7,6,6S14.3,35,11,35z'/%3E%3Cpath fill='%23967A44' d='M37.5,17H27c-2.2,0-4-1.8-4-4s1.8-4,4-4h2.1v1H27c-1.7,0-3,1.3-3,3s1.3,3,3,3h10.5V17z'/%3E%3Cpath fill='%23967A44' d='M28,16h1v7.5h-1V16z'/%3E%3Cpath fill='%23967A44' d='M13,17H2.5v-1H13c1.7,0,3-1.3,3-3s-1.3-3-3-3h-2.1V9H13c2.2,0,4,1.8,4,4S15.2,17,13,17z'/%3E%3Cpath fill='%23967A44' d='M11,16h1v7.5h-1V16z'/%3E%3C/g%3E%3C/svg%3E");
`;

const paywave = css`
  position: absolute;
  top: 1.5vh;
  right: 1.75vh;
  font-size: 3vh;
  transform: rotate(90deg);
  color: white;
`;

const cardRedLionStyle = css`
  position: absolute;
  width: 14vh;
  left: calc(50% - 7vh);
  margin-top: 3vh;
  // z-index: 0;
`;

const card_no = css`
  transform: translateZ(40px);
  font-family: monospace;
  font-weight: bold;
  font-size: 2vh;
  color: #fff;
  position: absolute;
  letter-spacing: 0.5vh;
  bottom: 7.5vh;
  z-index: 2;
  width: calc(100% - 3.5vh);
  text-align: right;
`;

const holderStyle = css`
  position: absolute;
  font-family: Tahoma, sans-serif;
  font-size: 2vh;
  font-weight: bold;
  color: #fff;
  bottom: 1.5vh;
  left: 1.75vh;
  transform: translateZ(50px);
  text-transform: uppercase;
`;

const amountStyle = css`
  z-index: 2;
  width: calc(100% - 3.5vh);
  text-align: right;
  position: absolute;
  right: 2.5vh;
  top: 6.75vh;
  font-family: Tahoma;
  font-weight: bold;
  font-size: 2vh;
`;

const positiveAmountStyle = (theme: any) => css`
  color: ${theme.colors.GreenDark};
`;

const negativeAmountStyle = (theme: any) => css`
  color: ${theme.colors.Red};
`;

type AccountType = {
  account: BankAccount;
};

export const AccountCard = ({ account }: AccountType) => {
  const username = useSelector(pathOr('', usernameP));
  return (
    <div css={accountCardContainer}>
      <img css={cardRedLionStyle} src="img/RedLion_Logo_Red.png" />
      <div css={cardBodyStyle}>
        <div css={bankTextStyle}>{account.bank.name}</div>
        <div css={bankAccountTextStyle}>
          {account.bankAccountType
            ? `${account.bankAccountType.name} ${
                account.main ? ' (Hauptkonto)' : ''
              }`
            : 'Fraktionskonto'}
        </div>
        <WifiIcon css={paywave} />
        <div css={chips}></div>
        <div css={card_no}>{account.vban}</div>
        <div
          css={[
            amountStyle,
            account.money >= 0 ? positiveAmountStyle : negativeAmountStyle,
          ]}
        >
          ${account.money.toFixed(2)}
        </div>
        <div css={holderStyle}>{username}</div>
      </div>
    </div>
  );
};
