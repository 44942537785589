import React, { useState } from 'react';
import { Sidebar } from '../../sidebars/Sidebar';
import Person from '@mui/icons-material/Person';
import { Profile } from './Profile';

const menu = [
  {
    id: 'profile',
    title: 'Profil',
    icon: <Person />,
  },
];

type SettingsType = {};
export const Settings = ({}: SettingsType) => {
  const [selectedMenu, setSelectedMenu] = useState('profile');

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar
          items={menu}
          selected={selectedMenu}
          select={setSelectedMenu}
        />
      </div>
      <div className="flex-grow">
        <div className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6">
          {selectedMenu === 'profile' && <Profile />}
        </div>
      </div>
    </div>
  );
};
