import React, { useState, useEffect } from 'react';
import BackIcon from '@mui/icons-material/ChevronLeftRounded';
import ForwardIcon from '@mui/icons-material/ChevronRightRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import {
  appsBrowserActiveTabIndexP,
  appsBrowserTabP,
  defaultTabs,
} from '../../../redux/reducer/apps/browser';
import {
  changeBrowserTab,
  createBrowserTab,
  goBrowserTabBack,
  goBrowserTabForward,
  openBrowserUrl,
} from '../../../actions';
import TabList from '../../shared/TabList';
import { NotFound404 } from './NotFound';
import { availableUrls } from './configs/urls';

const hideStyle = css`
  display: none;
`;
const containerStyle = css`
  height: calc(100% - 7vh);
  overflow: auto;
  position: relative;
`;

const navigationIconsStyle = css`
  display: flex;
  flex-direction: row;
  height: 3vh;
`;

const navigationIconStyle = (theme: any, isEnabled: Boolean) => css`
  padding: 0.5vh;
  color: ${isEnabled ? theme.colors.Black : theme.colors.Gray40} !important;
  height: 3vh !important;
  width: 3vh !important;
`;

const urlInputStyle = (theme: any) => css`
  color: ${theme.colors.Black};
  height: 2vh;
  width: 100%;
  padding: 0 1vh;
  border-radius: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const navigationBarStyle = css`
  box-shadow: 0 0 1vh 0 #000000;
  height: 4vh;
`;

type BrowserType = {
  width?: number;
  height?: number;
};
export const Browser = ({ width = 0, height }: BrowserType) => {
  const [urlInput, setUrlInput] = useState('');
  const dispatch = useDispatch();

  const tabs = useSelector(pathOr(defaultTabs(), appsBrowserTabP));
  const activeTabIndex = useSelector(pathOr(0, appsBrowserActiveTabIndexP));
  const activeTab = tabs[activeTabIndex];
  const url: string = activeTab.history[activeTab.historyPointer];

  useEffect(() => {
    setUrlInput(url);
  }, [url]);

  const hideLast = width < 640 ? 'hidden' : '';

  const onUrlKeyPress = (e: any) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      dispatch(openBrowserUrl(activeTabIndex, urlInput));
    }
  };

  const openUrl = (tabIndex: number) => (url: string) => {
    dispatch(openBrowserUrl(tabIndex, url));
  };

  const createTab = () => {
    dispatch(createBrowserTab());
  };
  const selectTab = (index: number) => {
    dispatch(changeBrowserTab(index));
  };

  const canGoBack = activeTab.historyPointer > 0;
  const canGoForward = activeTab.historyPointer < activeTab.history.length - 1;
  const goBack = () => {
    if (canGoBack) {
      dispatch(goBrowserTabBack(activeTabIndex));
    }
  };
  const goForward = () => {
    if (canGoForward) {
      dispatch(goBrowserTabForward(activeTabIndex));
    }
  };

  const tabTitles = tabs.map(
    (tab) =>
      availableUrls[tab.history[tab.historyPointer]]?.title ||
      tab.history[tab.historyPointer]
  );

  return (
    <div className="w-full h-full bg-gray-50">
      <TabList
        tabs={tabTitles}
        onTabClick={(index) => selectTab(index)}
        activeTabIndex={activeTabIndex}
      />
      {/* browser topbar */}
      <div className={`flex items-center bg-gray-50`} css={navigationBarStyle}>
        <div css={navigationIconsStyle}>
          <BackIcon
            onClick={goBack}
            css={(theme) => navigationIconStyle(theme, canGoBack)}
          />
          <ForwardIcon
            onClick={goForward}
            css={(theme) => navigationIconStyle(theme, canGoForward)}
          />
        </div>
        {/* <div className="flex flex-row justify-center px-2"> */}
        <input
          css={urlInputStyle}
          type="text"
          value={urlInput}
          onChange={(e) => setUrlInput(e.target.value)}
          onKeyPress={onUrlKeyPress}
          className="bg-gray-100 outline-none focus:outline-none focus:border-blue-400"
          placeholder="Search or enter website name"
        />
        {/* </div> */}
        <div className={`flex flex-row justify-end px-2 ${hideLast}`}>
          <AddIcon
            onClick={createTab}
            css={(theme: any) => ({ color: theme.colors.Black })}
          />
        </div>
      </div>
      {tabs.map((tab, index) => {
        const tabUrl = tab.history[tab.historyPointer];
        const Cmp = availableUrls[tabUrl]?.component || NotFound404;
        return (
          <div
            key={index}
            css={[containerStyle, index !== activeTabIndex && hideStyle]}
          >
            <Cmp openUrl={openUrl(index)} />
          </div>
        );
      })}
    </div>
  );
};
