import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Department of Commerce</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Das Department of Commerce ist mit dessen Mitarbeitern die oberste Stelle aller </span><span style="font-size: 14pt;">Belange der wirtschaftlichen Angelegenheiten in diesem Staate.</span></p>
	<p><span style="font-size: 14pt;">II. Es dient als oberste Behörde dem Allgemeinwohl.</span></p>
	<p><span style="font-size: 14pt;">III. Das Department of Commerce ist befugt, Unternehmen eigenständig zu verkaufen, </span><span style="font-size: 14pt;">zu vermieten oder zu verpachten.</span></p>
	<p><span style="font-size: 14pt;">IV. Das Department of Commerce ist berechtigt, Verordnungen über Wirtschaftsabläufe </span><span style="font-size: 14pt;">sowie über die Abwicklung des Verkaufes, der Verpachtung und der Vermietung von </span><span style="font-size: 14pt;">Unternehmen zu beschließen.</span></p>
	<p><span style="font-size: 14pt;">V. Das Department of Commerce entscheidet bei schwerwiegenden Verstößen gegen </span><span style="font-size: 14pt;">das HGB, ob dem Unternehmen vorübergehend der Handel untersagt wird oder es </span><span style="font-size: 14pt;">vorübergehend geschlossen wird.</span></p>
	<p><span style="font-size: 14pt;">VI. Mitarbeitern des Departments of Commerce ist jederzeit Zugang und Einblick zu </span><span style="font-size: 14pt;">Geschäftsunterlagen, Konten, Fahrzeugen und den Räumlichkeiten zu gewähren.</span></p>
	<p><span style="font-size: 14pt;">VII. Vorladungen des Departments of Commerce sind rechtsverbindliche Einladungen, </span><span style="font-size: 14pt;">denen Folge zu leisten sind. Bei Nichterscheinen kann es im Einzelfall bis zur </span><span style="font-size: 14pt;">Schließung des Unternehmens führen.</span></p>
	<p><span style="font-size: 14pt;">VIII. Das Department of Commerce pfändet das Unternehmensvermögen sowie </span><span style="font-size: 14pt;">Wertgegenstände, sofern Forderungen nach Versäumen des </span><span style="font-size: 14pt;">Zahlungsziels gegen den Pächter des Unternehmens oder das Unternehmen </span><span style="font-size: 14pt;">vorliegen.</span></p>
	<p><span style="font-size: 14pt;">IX. Ein Unternehmen wird nach einer Frist von 14 Tagen geschlossen. </span><span style="font-size: 14pt;">Eigentum, welches sich auf dem Unternehmensgrundstück befindet, kann gepfändet </span><span style="font-size: 14pt;">werden.</span></p>
	<p><span style="font-size: 14pt;">X. Das Department of Commerce ist berechtigt, Bearbeitungsgebühren zu fordern.</span></p>
	<p><span style="font-size: 14pt;">XI. Mahngebühren in Höhe von 15% der zu zahlenden Forderungen können nach </span><span style="font-size: 14pt;">Ablauf des Zahlungsziels erhoben werden.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Handelsregistereintragung</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Das Handelsregister hält folgende Informationen fest: Pächter/</span><span style="font-size: 14pt;">Inhaber, Unternehmensstandort, Branche des eingetragenes Unternehmens sowie </span><span style="font-size: 14pt;">Kontaktinformationen des Pächters/Käufers.</span></p>
	<p><span style="font-size: 14pt;">II. Das Department of Commerce ist verantwortlich für die Führung und Verwaltung des </span><span style="font-size: 14pt;">Handelsregisters.</span></p>
	<p><span style="font-size: 14pt;">III. Jegliche Form von Gewerbe muss dem Department of Commerce gemeldet werden.</span></p>
	<p><span style="font-size: 14pt;">IV. Um sein Gewerbe registrieren zu lassen, wird der Personalausweis benötigt.</span></p>
	<p><span style="font-size: 14pt;">V. Jeder Bürger darf maximal mit zwei Unternehmen als Pächter oder Inhaber im </span><span style="font-size: 14pt;">Handelsregister eingetragen sein.</span></p>
	<p><span style="font-size: 14pt;">VI. Der registrierte Pächter/Inhaber übernimmt die Haftung für das Unternehmen.</span></p>
	<p><span style="font-size: 14pt;">VII. Jeder Unternehmer, der ein Gewerbe betreiben möchte, ist verpflichtet, ein </span><span style="font-size: 14pt;">Geschäftsgebäude beim Department of Commerce zu pachten und sich im </span><span style="font-size: 14pt;">Handelsregister eintragen zu lassen.</span></p>
	<p><span style="font-size: 14pt;">VIII. Unternehmer, die eine Dienstleistung anbieten, sind verpflichtet, sich passende </span><span style="font-size: 14pt;">Büroräume anzumieten.</span></p>
	<p><span style="font-size: 14pt;">IX. Sämtliche Änderungen am Unternehmen sind vom Pächter/Inhaber des Unternehmens </span><span style="font-size: 14pt;">beim D</span><span style="font-size: 14pt;">epartment of Commerce zu melden.</span></p>
	<p><span style="font-size: 14pt;"><br></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Vertragsabschluss</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Das Department of Commerce ist für Unternehmensverträge zuständig, </span><span style="font-size: 14pt;">jedoch nicht für intern geschlossene Verträge und Abmachungen, die z.B. </span><span style="font-size: 14pt;">Arbeitsverträge, Kreditverträge oder Ratenzahlungsverträge etc. beinhalten.</span></p>
	<p><span style="font-size: 14pt;">II. Gebühren sind, wenn nichts weiteres vertraglich vereinbart worden ist, vom Pächter </span><span style="font-size: 14pt;">des Unternehmens über das Unternehmenskonto zu tragen.</span></p>
	<p><span style="font-size: 14pt;">III. Unternehmer sind dazu verpflichtet, sich über neue Informationen, Anordnungen </span><span style="font-size: 14pt;">sowie Gesetze zu informieren.</span></p>
	<p><span style="font-size: 14pt;">IV. Alle Aushänge des Dep</span><span style="font-size: 14pt;">artments of Commerce sind mit Datum des Aushangs rechtswirksam.</span></p>
	<p><span style="font-size: 14pt;">V. Verträge sind in mündlicher und schriftlicher Form gültig. </span></p>
	<ul><li><span style="font-size: 14pt;">S</span><span style="font-size: 14pt;">chriftliche Verträge müssen den vollständigen Namen der Vertragparteien, Vertragsinhalte wie Gegenstände und die Unterschrift der Vertragsparteien beinhalten.</span></li>
		<li><span style="font-size: 14pt;">Mündliche Verträge sind unter neutralen Zeugen gültig.</span></li>
	</ul><p><span style="font-size: 14pt;">VI. Verträge haben nur dann Gültigkeit, wenn alle Vertragsparteien in </span><span style="font-size: 14pt;">zurechnungsfähig</span><span style="font-size: 14pt;">em Zustand sin</span><span style="font-size: 14pt;">d und diese bei Vertragsschluss nicht gegen geltendes Gesetz verstoßen.</span></p>
	<p><span style="font-size: 14pt;">VII. Jedes Unternehmen ist dazu verpflichtet, einen Briefkasten an dessen Standort aufzustellen, der gut sichtbar und für die Bürger erreichbar ist.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4</strong></span><span style="font-size: 14pt;"><strong> Steuern</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Folgende Steuersätze sind im Staat gültig:</span></p>
	<ul><li><span style="font-size: 14pt;">8% bei unversteuerten Verkäufen an Endkunden,</span></li>
		<li><span style="font-size: 14pt;">4% bei unversteuerten Verkäufen an Unternehmen und staatliche Organisationen,</span></li>
		<li><span style="font-size: 14pt;">2% bei unversteuerten Privatentnahmen und Lohnzahlungen.</span></li>
	</ul><p><span style="font-size: 14pt;">II. Die Steuerbeträ</span><span style="font-size: 14pt;">ge sind am 01. &amp; 15. des Monats zu überweisen und beinhalten die </span><span style="font-size: 14pt;">Steuerschuld jeglicher steuerpflichtiger Umsätze zwischen dem oben genannten </span><span style="font-size: 14pt;">Zeitraum oder der letzten Überweisung der Steuerbeträge.</span></p>
	<p><span style="font-size: 14pt;">III. D</span><span style="font-size: 14pt;">er Arbeitgeber/Arbeitnehmer ist verpflichtet, jegliche Verkäufe von Handelsgütern versteuern zu lassen.</span></p>
	<ul><li><span style="font-size: 14pt;">Pächter von Endkunden-Unternehmen unterliegen gesonderter Regelung.</span></li>
		<li><span style="font-size: 14pt;">Ein Endkunden-Unternehmen definiert sich als: 24/7 Shop, Waffenladen, Autohaus, Möbelhaus, Friseursalon, Juwelier, Kleiderladen, Restaurant, Tankstelle, Tattoostudio, Werkstatt.</span></li>
	</ul><p><span style="font-size: 14pt;">IV. Dienstleistun</span><span style="font-size: 14pt;">gen sind steuerpflichtig.</span></p>
	<p><span style="font-size: 14pt;">V. Warenhandel an Endkunden ist nur nur über Endkunden-Unternehmen gestattet.</span></p>
	<p><span style="font-size: 14pt;">VI. Kooperationen zwischen Unternehmen müssen vom Department of Commerce </span><span style="font-size: 14pt;">zugelassen werden.</span></p>
	<p><span style="font-size: 14pt;">VII. Das Department of Commerce ist berechtigt, fehlende Steuerzahlungen zu </span><span style="font-size: 14pt;">sanktionieren.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§</strong></span><span style="font-size: 14pt;"><strong> 5 Geschäftskonten</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Transaktionen über ein Firmenkonto müssen stets belegt werden und einen </span><span style="font-size: 14pt;">korrekten sowie nachvollziehbaren Verwendungszweck angegeben haben.</span></p>
	<p><span style="font-size: 14pt;">II. Geschäftstransaktionen dürfen ausschließlich über beim Department of Commerce </span><span style="font-size: 14pt;">eingetragene Unternehmenskonten abgewickelt werden.</span></p>
	<p><span style="font-size: 14pt;">III. Geschäftskonten dürfen ausschließlich für Geschäftstransaktionen des </span><span style="font-size: 14pt;">Unternehmens verwendet werden.</span></p>
	<p><span style="font-size: 14pt;">IV. Ein Unternehmer muss sein Unternehmenskonto stets im Haben (+) führen.</span></p>
	<p><span style="font-size: 14pt;">V. Wenn ein Konto ein Saldo im Soll (-) aufweist, muss dies innerhalb von 7 Tagen </span><span style="font-size: 14pt;">ausgleichen werden.</span></p>
	<p><span style="font-size: 14pt;">VI. Das Department of Commerce behält sich vor, Konten unangekündigt zu überprüfen.</span></p>
	<p><span style="font-size: 14pt;">VII. Transaktionen, welche aus mehreren Zahlungen oder Rückzahlungen bestehen, </span><span style="font-size: 14pt;">müssen über eine eindeutige, den Zusammenhang erläuternde Formulierung </span><span style="font-size: 14pt;">erkennbar gemacht werden.</span></p>
	<p><span style="font-size: 14pt;">VII</span><span style="font-size: 14pt;">I. Es müssen alle Unternehmenskonten beim DoC angegeben werden.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 6 Warenvertrieb</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Ein Unternehmen darf ausschließlich Waren, die zur Weiterverarbeitung benötigt </span><span style="font-size: 14pt;">werden, erwerben oder importieren.</span></p>
	<p><span style="font-size: 14pt;">II. Ein Unternehmen darf ausschließlich Waren, die es herstellen kann, vertreiben bzw.</span><span style="font-size: 14pt;"> exportieren.</span></p>
	<p><span style="font-size: 14pt;">III. Der Ressourcenexport ist nur für Bürger ohne Arbeitsvertrag zu nutzen und</span><span style="font-size: 14pt;"> für Arbeitgeber sowie Arbeitnehmer untersagt.</span></p>
	<p><span style="font-size: 14pt;">IV. Ein Restaurant darf nur fertig zubereitete Lebensmittel und offene oder </span><span style="font-size: 14pt;">verschlossene Getränke anbiet</span><span style="font-size: 14pt;">en. Ausgenommen hiervon sind Mischgetränke wie Cocktails und Longdrinks. Über entsprechend ausgestattete und geeignete Fahrzeuge dürfen Waren außerhalb deren Betriebsstätte vertrieben werden.</span></p>
	<p><span style="font-size: 14pt;">V. Ein 24/7 darf nur folgende Waren anbieten:</span></p>
	<ul><li><span style="font-size: 14pt;">Waren des täglichen Bedarfs,</span></li>
		<li><span style="font-size: 14pt;">verschlossene Lebensmittel </span></li>
		<li><span style="font-size: 14pt;">nicht- und leicht alkoholische Getränke in verschlossenen Flaschen.</span></li>
		<li><span style="font-size: 14pt;">Dekoartikel, kleine Bausätze</span></li>
	</ul><p><span style="font-size: 14pt;">VI. Ein Waffenladen darf nur folgende Waren anbieten:</span></p>
	<ul><li><span style="font-size: 14pt;">Waffen &amp; Munition</span></li>
		<li><span style="font-size: 14pt;">Sportgeräte und Werkzeuge</span></li>
		<li><span style="font-size: 14pt;">Taschenlampen</span></li>
	</ul><p><span style="font-size: 14pt;">VII. Clubs und Bars dürfen nur Spirituosen und Getränke anbieten. Ausgenommen davon sind kleine Snacks.</span></p>
	<p><span style="font-size: 14pt;">VIII. Ein Autohaus darf jegliche Art von Fahrzeugen anbieten. Ausgenommen davon sind Einsatzfahrzeuge der staatlichen Einrichtungen, diese dürfen nur in Kooperation mit der jeweiligen Staatsbehörde ausgehändigt werden.</span></p>
	<p><span style="font-size: 14pt;">IX. Ein Lagerhaus darf sämtliche Waren an- und verkaufen. Ausgenommen davon sind Waren aus einem Endkundenunternehmen, Import/Export Auktionen oder Fahrzeuge.</span></p>
	<p><span style="font-size: 14pt;">X. Kleiderläden, Juweliere, Friseursalons, Möbelhäuser, Tattoostudios und Autohäuser sind dazu verpflichtet, eine qualitative Beratung anzubieten sowie eine Kontaktmöglichkeit sichtbar am Ladengeschäft anzubringen.</span></p>
	<p><span style="font-size: 14pt;">XI. Vorbestraften Personen ist es nicht gestattet, Waffen und waffenähnliche Gegenstände zu produzieren und zu vertreiben.</span></p>
	<p><span style="font-size: 14pt;">XII. Auf ein Autohaus angemeldete Fahrzeuge dürfen ausschließlich für Probe- oder Überführungsfahrten verwendet werden.</span></p>
	<p><span style="font-size: 14pt;">XIII</span><span style="font-size: 14pt;">. K</span><span style="font-size: 14pt;">redite dürfen nur über ein Kreditunternehmen gewährt werden.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 7 Unternehmenshaftung</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Der Pächter muss gesetzten Fristen für das Begleichen von Strafzahlungen nachkommen.</span></p>
	<p><span style="font-size: 14pt;">II. Der Pächter ist verpflichtet, Auflagen und Anordnungen nachzukommen, andernfalls drohen Strafen sowie die Schließung des Gewerbes.</span></p>
	<p><span style="font-size: 14pt;">III. Jede Form von Illegalität, die auf ein Gewerbe zurückzuführen ist, kann die Schließung des Gewerbes nach sich ziehen.</span></p>
	<p><span style="font-size: 14pt;">IV. Für Straftaten, die mit Fahrzeugen getätigt wurden, die auf ein Unternehmen angemeldet sind, haftet stets der Pächter des Unternehmens.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 8 Waffenindustrie</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Unternehmer und Angestellte, die in diesen Gewerben tätig sind, müssen über eine </span><span style="font-size: 14pt;">straffreie Akte verfügen.</span></p>
	<p><span style="font-size: 14pt;">II. Um in der Waffenindustrie tätig sein zu können, bedarf es einem psychologischen </span><span style="font-size: 14pt;">Gutachten und einem kleinen Waffenschein für jeden Mitarbeiter.</span></p>
	<p><span style="font-size: 14pt;">III. Händler haften bei einer Anklage in Verbindung mit ihrer legal verkauften Ware und </span><span style="font-size: 14pt;">deren Gebrauch nicht.</span></p>
	<p><span style="font-size: 14pt;">IV. </span><span style="font-size: 14pt;">Waffen jeglicher Art, verpackt oder unverpackt, dürfen nur über einen angemeldeten, lizenzierten Ammunation vertrieben werden.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><strong><span style="color:rgb(255,255,255);">§ 9 </span>Wettbewerbs- und Kartellrecht (Gesetz gegen Wettbewerbsbeschränkungen)</strong></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Wettbewerbsschädigende Verhaltensweisen wie Rufmord, üble Nachrede, </span><span style="font-size: 14pt;">Vorteilsbeschaffung gegenüber Mitbewerbern etc. sind verboten und werden</span><span style="font-size: 14pt;">strafrechtlich verfolgt.</span></p>
	<p><span style="font-size: 14pt;">II. Werbung darf nicht imitiert, verändert, kopiert oder gelöscht werden.</span></p>
	<p><span style="font-size: 14pt;">III. Die Namen der Unternehmen dürfen nicht kopiert und imitiert werden.</span></p>
	<p><span style="font-size: 14pt;">IV. Unternehmen, die im Handelsregister eingetragen sind, genießen den Vorteil des rechtlichen Schutzes ihres Namens.</span></p>
	<p><span style="font-size: 14pt;">V. Arbeitgeber und Arbeitnehmer dürfen keine Abhängigkeit zu Unternehmen bilden. Ausnahmen bilden neutrale Unternehmen (Hangar, Recyclinghof).</span></p>
	<p><span style="font-size: 14pt;">VI. Vereinbarungen oder Absprachen unter Unternehmern oder unter Unternehmern und </span><span style="font-size: 14pt;">Privatpersonen, die eine Einschränkung oder Verfälschung des Wettbewerbs </span><span style="font-size: 14pt;">bezwecken oder bewirken, sind verboten.</span></p>
	<p><span style="font-size: 14pt;">VII. Die missbräuchliche Ausnutzung einer marktbeherrschenden Stellung durch ein oder </span><span style="font-size: 14pt;">mehrere Unternehmen ist verboten.</span></p>
	<p><span style="font-size: 14pt;">VIII. Unternehmen dürfen andere Unternehmen nicht beeinträchtigen, indem sie zu </span><span style="font-size: 14pt;">Liefersperren, Bezugssperren oder Ähnlichem auffordern.</span></p>
	<p><span style="font-size: 14pt;">IX. Lassen Umstände vermuten, dass der Wettbewerb möglicherweise eingeschränkt </span><span style="font-size: 14pt;">oder verfälscht ist, kann das Department of Commerce die Untersuchung eines </span><span style="font-size: 14pt;">bestimmten Wirtschaftszweiges anordnen und durchführen.</span></p>
	<p><span style="font-size: 14pt;">X. Eine an einem Kartell beteiligte natürliche Person, welche durch Informationen oder </span><span style="font-size: 14pt;">eine Aussage wesentlich dazu beiträgt, ein Kartell aufzudecken, kann die drohende </span><span style="font-size: 14pt;">Strafe vollständig erlassen sowie eine Belohnung ausgesetzt werden.</span></p>
	<p><span style="font-size: 14pt;">XI. Gebote auf Importgüter für staatliche Behörden sind nur mit vorherigem Auftrag </span><span style="font-size: 14pt;">durch eine staat</span><span style="font-size: 14pt;">liche Behörde zu tätigen.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 10 Unbewirtschaftete Unternehmen</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Unternehmen, die länger als 14 Tage nicht bewirtschaftet werden, können ohne </span><span style="font-size: 14pt;">Schließungsanordnung geschlossen werden.</span></p>
	<p><span style="font-size: 14pt;">II. Pächter, welche nicht mehr zur Verfügung stehen, werden nach 14 Tagen die </span><span style="font-size: 14pt;">Unternehmen entzogen, sofern kein Urlaubsantrag gestellt wurde.</span></p>
	<p><span style="font-size: 14pt;">III. Urlaubsanträge können von Pächtern postalisch oder im Department of </span><span style="font-size: 14pt;">Commerce gestellt werden, ma</span><span style="font-size: 14pt;">ximal können 14 Tage Urlaub beantragt werden.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§</strong></span><span style="font-size: 14pt;"><strong> 11 Wucher</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Wer die Zwangslage, die Unerfahrenheit, den Mangel an Urteilsvermögen oder die erhebliche Willensschwäche eines anderen dadurch ausbeutet, dass er sich oder einem Dritten</span></p>
	<ul><li><span style="font-size: 14pt;">für die Vermietung von Lagerfläche oder Gegenständen,</span></li>
		<li><span style="font-size: 14pt;">für die Gewährung eines Kredits,</span></li>
		<li><span style="font-size: 14pt;">für eine sonstige Leistung,</span></li>
		<li><span style="font-size: 14pt;">für die Vermittlung einer der vorbezeichneten Leistungen</span></li>
	</ul><p><span style="font-size: 14pt;">Vermögensvorteile versprechen oder gewähren lässt, die in einem auffälligen Missverhältnis zu der Leistung oder deren Vermittlung stehen, begeht Wucher.</span></p>
	<p><span style="font-size: 14pt;">II. Wirken mehrere Personen mit, machen sie sich der Beihilfe strafbar.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 12 Vergehen gegen das HGB</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Vergehen gegen das HGB sind strafbar, wenn vom Zeitpunkt des Vergehens bis zur Feststellung weniger als 8 Wochen vergangen sind.</span></span></p>
	<p><span style="font-size: 14pt;">II. Bei Verletzung des Handelsgesetzbuches kann das Department of Commerce das </span><span style="font-size: 14pt;">Unternehmen schließen.</span></p>

`;

export const HandelGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
