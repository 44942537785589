import React from 'react';
import { connect } from 'react-redux';
import { pathOr, pipe } from 'ramda';
import { MyVehiclesApp } from '../../components/apps/MyVehicles';
import { fetchMyVehicles, setVehicleWaypoint } from '../../actions/';
import { appsMyVehiclesP } from '../../redux/reducer/apps/myVehicles';
import { darkP } from '../../redux/reducer/common';

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
    vehicles: pathOr([], appsMyVehiclesP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetch: pipe(fetchMyVehicles, dispatch),
  setWayPoint: pipe(setVehicleWaypoint, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyVehiclesApp);
