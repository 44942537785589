import { find, map, pipe, prop, propEq, propOr, split } from 'ramda';
import React, { useState, useEffect } from 'react';
import { FaPaw } from 'react-icons/fa';
import { Middlebar } from '../../sidebars/Middlebar';
import { Sidebar } from '../../sidebars/Sidebar';
import { CheckIns } from './CheckIns';
import { Employee } from './Employee';
import { Exports } from './Exports';
import { Overview } from './Overview';
import { Production } from './Production';
import { Quit } from './Quit';
import { Storage } from './Storage';
import {
  CompanyData,
  CompanyDutyData,
  CompanyRights,
  CompanyStorageData,
  ExportData,
  ProductionData,
} from './types';

const middleMenu = (
  hasCounter: boolean,
  rights: CompanyRights | undefined,
  isEmployeeOnly: boolean = false
) => [
  {
    id: 'overview',
    title: 'Übersicht',
    icon: <FaPaw />,
    excerpt: 'Die wichtigsten Punkte auf einen Blick.',
  },
  {
    id: 'storage',
    title: 'Lager',
    icon: <FaPaw />,
    excerpt: 'Was liegt in deinem Lager?',
    hide: !rights || !rights.readStorage,
  },
  {
    id: 'counter',
    title: 'Ladentheke',
    icon: <FaPaw />,
    excerpt: 'Ist die Theke noch prall gefüllt?',
    hide: !hasCounter || !rights || !rights.readCounterItems,
  },
  {
    id: 'employees',
    title: 'Mitarbeiter',
    icon: <FaPaw />,
    excerpt: 'Deine Angestellten und ihre Rechte.',
    hide: !rights || !rights.readEmployees,
  },
  {
    id: 'employeeCheckIn',
    title: 'Mitarbeiter Check In',
    icon: <FaPaw />,
    excerpt: 'Wer ist schon im Feierabend?',
    hide: !rights || !rights.readEmployeeCheckin,
  },
  // {
  //   id: `finance`,
  //   title: `Finanzen`,
  //   icon: <FaPaw />,
  //   excerpt: 'Schon im roten Bereich?',
  //   hide: !rights || !rights.readFinance,
  // },
  {
    id: 'exports',
    title: 'Exportaufträge',
    icon: <FaPaw />,
    excerpt: 'Welche Aufträge sind noch zu erledigen?',
    hide: !rights || !rights.readOrders,
  },
  {
    id: 'production',
    title: 'Produktionen',
    icon: <FaPaw />,
    excerpt: 'Laufen die Maschinen noch?',
    hide: !rights || !rights.readProduction,
  },
  {
    id: 'quit',
    title: 'Kündigen',
    icon: <FaPaw />,
    excerpt: 'Du möchtest bei dieser Firma kündigen?',
    hide: !isEmployeeOnly,
  },
];

type CompanyType = {
  fetch: () => void;
  companies: CompanyData[];
  changeName: (id: string, name: string) => void;
  changeAdd: (id: string, adLine: string) => void;
  fetchStorage: (id: string, page: number, search: string) => void;
  storage: CompanyStorageData | undefined;
  saveStoragePrices: (id: string, items: any[]) => void;
  fetchCounter: (id: string, page: number, search: string) => void;
  counter: CompanyStorageData | undefined;
  saveCounterPrices: (id: string, items: any[]) => void;
  editEmployeeRights: (id: string, employeeId: string, newRights: any) => void;
  fire: (id: string, name: string) => void;
  invite: (id: string, name: string) => void;
  fetchCheckIns: (id: string) => void;
  checkIns: CompanyDutyData[];
  changeSalaries: (
    id: string,
    employeeData: { _id: string; money: number }[]
  ) => void;
  fetchExports: (id: string) => void;
  exports: ExportData[];
  fetchProduction: (id: string) => void;
  production: ProductionData | undefined;
  username: string;
};

export const Company = ({
  fetch,
  companies,
  changeName,
  changeAdd,
  fetchStorage,
  storage,
  saveStoragePrices,
  fetchCounter,
  counter,
  saveCounterPrices,
  editEmployeeRights,
  fire,
  invite,
  fetchCheckIns,
  checkIns,
  changeSalaries,
  fetchExports,
  exports,
  fetchProduction,
  production,
  username,
}: CompanyType) => {
  const [selectedMenu, setSelectedMenu] = useState('');
  const [selectedMiddleMenu, setSelectedMiddleMenu] = useState('overview');

  useEffect(() => {
    fetch();
  }, []);

  const menu = companies.map((company) => ({
    id: company._id,
    title: company.label,
    icon: <FaPaw />,
  }));

  const selectedCompany = find(propEq('_id', selectedMenu), companies);

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar
          items={menu}
          selected={selectedMenu}
          select={setSelectedMenu}
        />
      </div>
      {selectedMenu.length > 0 && (
        <>
          <div className="flex-none w-60">
            <Middlebar
              items={middleMenu(
                (selectedCompany?.counterItems.length || 0) > 0 &&
                  (selectedCompany?.hash || '').indexOf('barn') === -1,
                selectedCompany?.playerRights,
                selectedCompany?.isEmployeeOnly
              )}
              selected={selectedMiddleMenu}
              select={setSelectedMiddleMenu}
            />
          </div>
          <div className="flex-grow">
            <div className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6">
              {selectedMiddleMenu === 'overview' && selectedCompany && (
                <Overview
                  company={selectedCompany}
                  changeName={changeName}
                  changeAdd={changeAdd}
                />
              )}
              {selectedMiddleMenu === 'storage' && selectedCompany && (
                <Storage
                  title="Lagerinventar"
                  company={selectedCompany}
                  fetchStorage={fetchStorage}
                  storage={storage}
                  saveStoragePrices={saveStoragePrices}
                />
              )}
              {selectedMiddleMenu === 'counter' && selectedCompany && (
                <Storage
                  title="Ladentheke"
                  company={selectedCompany}
                  fetchStorage={fetchCounter}
                  storage={counter}
                  saveStoragePrices={saveCounterPrices}
                />
              )}
              {selectedMiddleMenu === 'employees' && selectedCompany && (
                <Employee
                  company={selectedCompany}
                  editEmployeeRights={editEmployeeRights}
                  fire={fire}
                  invite={invite}
                  changeSalaries={changeSalaries}
                />
              )}
              {selectedMiddleMenu === 'employeeCheckIn' && selectedCompany && (
                <CheckIns
                  company={selectedCompany}
                  fetchCheckIns={fetchCheckIns}
                  checkIns={checkIns}
                />
              )}
              {selectedMiddleMenu === 'exports' && selectedCompany && (
                <Exports
                  company={selectedCompany}
                  fetchExports={fetchExports}
                  exports={exports}
                />
              )}
              {selectedMiddleMenu === 'production' && selectedCompany && (
                <Production
                  company={selectedCompany}
                  fetchProduction={fetchProduction}
                  production={production}
                />
              )}
              {selectedMiddleMenu === 'quit' && selectedCompany && (
                <Quit
                  company={selectedCompany}
                  fire={fire}
                  username={username}
                />
              )}
            </div>
          </div>
        </>
      )}
      {selectedMenu.length === 0 && (
        <div className="markdown flex-grow bg-gray-50 text-gray-700 overflow-auto p-6">
          Wähle eine deiner Firmen aus.
          <br></br>
          Du besitzt keine Firmen? Reiche jetzt dein Konzept bei der DOC im
          Lifeinvader ein.
        </div>
      )}
    </div>
  );
};
