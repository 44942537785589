import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { fetchListValues, notifyError, notifySuccess } from '../../utils';

export const onFetchAdminStateBankAccounts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_STATEBANKACCOUNTS_FETCH_START),
    mapPromiseC(({}: any) => {
      return authFetchJson(`/admin/bankAccounts`)
        .then(({ accounts }: any) => {
          return {
            type: types.ADMIN_STATEBANKACCOUNTS_FETCH_SUCCESS,
            data: accounts,
          };
        })
        .catch(() => ({
          type: types.ADMIN_STATEBANKACCOUNTS_FETCH_FAILED,
        }));
    })
  );
