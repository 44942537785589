import { css } from '@emotion/react';
import { path, pipe } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { ingameP, visibleP } from '../redux/reducer/common';

const overlayStyle = css`
  position: absolute;
  height: 90vh;
  top: 5vh;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
`;
const contentContainerStyle = css`
  background: #282828;
  position: absolute;
  width: 111.2vh;
  height: 83.3vh;
  top: 8.5vh;
  left: 0.4vh;
  right: 0;
  margin: auto;

  @viewport {
    width: 30vw; /*Sets the width of the actual viewport to the device width*/
  }
`;

const browserStyles = css`
  width: 100vw;
  height: 100vh;
`;

const containerStyle = (isVisible: boolean) => css`
  ${!isVisible ? 'display: none' : ''}
`;

const IngameWrapper = ({ isVisible, children, isIngame }: any) => {
  return (
    <div css={[isIngame && containerStyle(isVisible)]}>
      <div
        css={[isIngame && contentContainerStyle, !isIngame && browserStyles]}
      >
        {children}
      </div>
      {isIngame && <img src="/img/overlay.png" css={overlayStyle} />}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isVisible: path(visibleP, state),
    isIngame: path(ingameP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(IngameWrapper);
