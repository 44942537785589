import { css } from '@emotion/react';
import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BankCreateAccount } from './BankCreateAccount';

const bankContainerStyle = css`
  padding-bottom: 2vh;
  border-bottom: 1px solid #1a1a1a;
  margin-bottom: 2vh;
`;

const bankHeaderStyle = css`
  height: 2vh;
  font-weight: bold;
`;
const bankOfferTextStyle = css`
  margin-top: 2vh;
  font-weight: bold;
`;
const bankOfferStyle = css`
  padding: 0.5vh;
`;

const TYPE_TEXT_MAP: any = {
  private: 'Privatkonto',
  business: 'Firmenkonto',
};

export type BankData = {
  hash: string;
  name: string;
  description: string;
  bankAccountTypes: {
    name: string;
    type: string;
  }[];
};

type CreationDataType = {
  [bankHash: string]:
    | {
        name: string;
        type: string;
      }
    | undefined;
};

type BanksTypes = {
  fetchBanks: () => void;
  banks: BankData[];
};
export const Banks = ({ fetchBanks, banks }: BanksTypes) => {
  const [creationData, setCreationData] = useState<CreationDataType>({});

  useEffect(() => {
    fetchBanks();
  }, []);
  return (
    <div>
      {banks.map((bank) => (
        <div key={bank.hash} css={bankContainerStyle}>
          <div css={bankHeaderStyle}>{bank.name}</div>
          <div>{bank.description}</div>

          {bank.bankAccountTypes.length > 0 && (
            <>
              <div css={bankOfferTextStyle}>Angebote</div>
              <table>
                <tbody>
                  {bank.bankAccountTypes.map((entry) =>
                    entry.type !== 'business' ? (
                      <tr key={entry.type}>
                        <td>
                          <span>{TYPE_TEXT_MAP[entry.type] || 'Konto'}</span>
                        </td>
                        <td>
                          <span>{entry.name}</span>
                        </td>
                        <td>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              setCreationData({
                                ...creationData,
                                [bank.hash]: entry,
                              })
                            }
                          >
                            Eröffnen
                          </Button>
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </>
          )}

          {creationData[bank.hash] && (
            <BankCreateAccount
              bankHash={bank.hash}
              data={creationData[bank.hash]}
              abort={() =>
                setCreationData({
                  ...creationData,
                  [bank.hash]: undefined,
                })
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};
