import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetAdminPlayerScMultiAccount,
  startAdminPlayerMultiaccountFetch,
} from '../../../../actions';
import {
  appsAdminPlayerMultiCompletedP,
  appsAdminPlayerMultiP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { PlayerMultiEntryType } from '../types/playermulti';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { detailContainerStyle } from '../styles';

const tHeadStyle = css`
  th {
    width: 150px;
  }
`;
const tBodyStyle = css`
  display: block;
  max-height: 200px;
  overflow: auto;

  td {
    width: 150px;
  }
`;

type MultiaccountType = {
  _id: string;
};
export const Multiaccount = ({ _id }: MultiaccountType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerMultiEntryType[]>(
    pathOr([], appsAdminPlayerMultiP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerMultiCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerMultiaccountFetch(_id, offset));
  };

  const resetAcc = (id: string) => {
    dispatch(resetAdminPlayerScMultiAccount(id, _id));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Multiaccounts A</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length === 0 && reachedEndOfList && <div>Keine Multiaccounts</div>}

      {list.length > 0 && (
        <Table>
          <TableHead css={tHeadStyle}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>SC</TableCell>
              <TableCell>SerialMatch</TableCell>
              <TableCell>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody css={tBodyStyle}>
            {list.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell>{entry.name}</TableCell>
                <TableCell>
                  {entry.socialClubMatch && <CheckCircle />}
                </TableCell>
                <TableCell>{entry.serialMatch && <CheckCircle />}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => resetAcc(entry._id)}
                  >
                    Zurücksetzen
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {!reachedEndOfList && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="outlined"
                    onClick={() => onLoad(list.length)}
                  >
                    Weitere Laden
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
