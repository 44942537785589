// @ts-ignore
import * as mathjs from 'mathjs';
import { isNil } from 'ramda';

export const computeExpr = (expr: string, scope: any) => {
  let value = null;
  if (expr.charAt(0) !== '=') {
    return { className: '', value: expr };
  } else {
    try {
      value = mathjs.evaluate(expr.substring(1), scope);
    } catch (e) {
      value = null;
    }

    if (value !== null) {
      return { className: 'equation', value: expr, computedValue: value };
    } else {
      return { className: 'error', value: expr, computedValue: 'error' };
    }
  }
};

export const createScope = (
  data: any[][],
  template: any[][],
  deps: { [sheetId: string]: any[][] }
) => {
  const scope: any = {};
  data.forEach((row: any[], y: number) => {
    row.forEach((value: any, x: number) => {
      const colChar = x === 0 ? 'Aa' : String.fromCharCode(65 + x - 1);
      const val = isNil(value) ? template[y][x].value : value;
      scope[colChar + y] = !val ? 0 : isNaN(val) ? val : parseFloat(val);
    });
  });

  const depKeys = Object.keys(deps);
  depKeys.forEach((sheetId) => {
    deps[sheetId].forEach((row: any[], y: number) => {
      row.forEach((value: any, x: number) => {
        const colChar = x === 0 ? 'Aa' : String.fromCharCode(65 + x - 1);
        scope[sheetId + '$' + colChar + y] = !value
          ? 0
          : isNaN(value)
          ? value
          : parseFloat(value);
      });
    });
  });

  return scope;
};
