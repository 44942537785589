import * as io from 'socket.io-client';

let isConnected = false;
let socket: any;

export const connect = () => {
  if (isConnected) {
    return socket;
  }

  const token = localStorage.getItem('token');
  // token can be null when opening route / and be redirected to /login
  // dont conenct in this case that the connection can be created after successfull login
  if (!token) {
    return;
  }
  // @ts-ignore
  socket = io('', {
    query: 'token=' + token,
  });

  isConnected = true;
  return socket;
};
