import React, { useState } from 'react';
import { useAuthentication } from '../../../utils/authentication';
import { Sidebar } from '../../sidebars/Sidebar';
import { Dashboard } from './Dashboard';
import { PlayerSearch } from './PlayerSearch';
import { Restart } from './Restart';
import { Roles } from './Roles';
import { UserRights } from './UserRights';
import { pipe, uniq, slice, find } from 'ramda';
import { PlayerView } from './PlayerView';
import { VehiclesSearch } from './VehiclesSearch';
import { VehicleView } from './VehicleView';
import { FactorySearch } from './FactorySearch';
import { FactoryView } from './FactoryView';
import Person from '@mui/icons-material/Person';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import Business from '@mui/icons-material/Business';
import Pets from '@mui/icons-material/Pets';
import House from '@mui/icons-material/House';
import Home from '@mui/icons-material/Home';
import Refresh from '@mui/icons-material/Refresh';
import Group from '@mui/icons-material/Group';
import Settings from '@mui/icons-material/Settings';
import LocationCity from '@mui/icons-material/LocationCity';
import LocalAtm from '@mui/icons-material/LocalAtm';
import Accessibility from '@mui/icons-material/Accessibility';
import Archive from '@mui/icons-material/Archive';
import AccountBalance from '@mui/icons-material/AccountBalance';
import LiveTv from '@mui/icons-material/LiveTv';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import InterestsIcon from '@mui/icons-material/Interests';
import { ApartmentSearch } from './ApartmentsSearch';
import { ApartmentView } from './ApartmentView';
import { StateBankAccounts } from './StateBankAccounts';
import { Tickets } from './Tickets';
import { Deaths } from './Deaths';
import { Logs } from './Logs';
import { BankAccountSearch } from './BankAccountSearch';
import { BankAccountView } from './BankAccountView';
import { LiveStats } from './LiveStats';
import { Meters } from './Meters';
import { Leogram } from './Leogram';

const ICON_TYPE_MAP: any = {
  player: Person,
  vehicle: DirectionsCar,
  factory: Business,
  apartment: House,
  bankaccount: AccountBalance,
  default: Pets,
};

const menu = (hasRight: (right: string | string[]) => boolean) =>
  [
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: <Home />,
    },
    {
      id: 'restart',
      title: 'Serverrestart',
      icon: <Refresh />,
      right: 'admin.server.restart.list',
    },
    {
      id: 'roles',
      title: 'Rollen',
      icon: <Settings />,
      right: 'admin.permissions',
    },
    {
      id: 'userrights',
      title: 'Spielerrechte',
      icon: <Group />,
      right: 'admin.permissions',
    },
    {
      id: 'playersearch',
      title: 'Spielersuche',
      icon: <Person />,
      right: 'admin.player.search',
    },
    {
      id: 'vehiclesearch',
      title: 'Fahrzeugsuche',
      icon: <DirectionsCar />,
      right: 'admin.vehiclesearch',
    },
    {
      id: 'factorysearch',
      title: 'Firmen',
      icon: <Business />,
      right: 'admin.factories.search',
    },
    {
      id: 'apartmentssearch',
      title: 'Apartments',
      icon: <House />,
      right: 'admin.houses.search',
    },
    {
      id: 'bankaccountsearch',
      title: 'Kontosuche',
      icon: <AccountBalance />,
      right: 'admin.bank.search',
    },
    {
      id: 'statebank',
      title: 'Staatskonto',
      icon: <LocationCity />,
      right: 'admin.bank',
    },
    {
      id: 'tickets',
      title: 'Ticketliste',
      icon: <LocalAtm />,
      right: 'admin.tickets.list',
    },
    {
      id: 'deaths',
      title: 'Deathlog',
      icon: <Accessibility />,
      right: 'admin.deaths.list',
    },
    {
      id: 'leogram',
      title: 'Leogram',
      icon: <InterestsIcon />,
      right: 'admin.leogram',
    },
    {
      id: 'userlog',
      title: 'Benutzerlog',
      icon: <Archive />,
      right: 'admin.log',
    },
    {
      id: 'meters',
      title: 'Meters',
      icon: <StackedLineChartIcon />,
      right: 'admin.server.meter',
    },
    {
      id: 'livestats',
      title: 'Livedaten',
      icon: <LiveTv />,
      right: 'admin.stats.live',
    },
  ].filter((entry) => !entry.right || hasRight(entry.right));

type OpenedEntryType = { type: string; _id: string; label: string };

type AdminType = {};

export const Admin = ({}: AdminType) => {
  const [selectedMenu, setSelectedMenu] = useState('dashboard');
  const [hasRight] = useAuthentication();
  const [openedEntries, setOpenedEntries] = useState<OpenedEntryType[]>([]);

  const createOpen = (type: string) => (_id: string, label: string) => {
    const newData = pipe<
      [OpenedEntryType[]],
      OpenedEntryType[],
      OpenedEntryType[],
      OpenedEntryType[]
    >(
      (x) => [
        {
          type,
          _id,
          label: label.length <= 13 ? label : label.substr(label.length - 13),
        },
        ...x,
      ],
      uniq,
      slice(0, 10)
    )(openedEntries);
    setOpenedEntries(newData);
    setSelectedMenu(`${type}-${_id}`);
  };

  const openPlayer = createOpen('player');
  const openVehicle = createOpen('vehicle');
  const openFactory = createOpen('factory');
  const openApartment = createOpen('apartment');
  const openBankAccount = createOpen('bankaccount');

  const menuData: any[] = [
    ...menu(hasRight),
    ...openedEntries.map((entry) => {
      const Icon = ICON_TYPE_MAP[entry.type] || ICON_TYPE_MAP.default;
      return {
        id: `${entry.type}-${entry._id}`,
        title: `${entry.label}`,
        icon: <Icon />,
        right: 'admin.player.detail',
      };
    }),
  ];

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar
          items={menuData}
          selected={selectedMenu}
          select={setSelectedMenu}
        />
      </div>
      <div className="flex-grow">
        <div className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6">
          {selectedMenu === 'dashboard' && <Dashboard />}
          {selectedMenu === 'restart' && <Restart />}
          {selectedMenu === 'roles' && <Roles />}
          {selectedMenu === 'userrights' && <UserRights />}
          {selectedMenu === 'playersearch' && (
            <PlayerSearch openPlayer={openPlayer} />
          )}
          {selectedMenu.startsWith('player-') && (
            <PlayerView
              _id={
                find<any>(
                  (x: OpenedEntryType) =>
                    x._id === selectedMenu.replace('player-', ''),
                  openedEntries
                )._id
              }
              openVehicle={openVehicle}
              openFactory={openFactory}
              openApartment={openApartment}
              openBankAccount={openBankAccount}
            />
          )}
          {selectedMenu == 'vehiclesearch' && (
            <VehiclesSearch open={openVehicle} />
          )}
          {selectedMenu.startsWith('vehicle-') && (
            <VehicleView
              _id={
                find<any>(
                  (x: OpenedEntryType) =>
                    x._id === selectedMenu.replace('vehicle-', ''),
                  openedEntries
                )._id
              }
              openPlayer={openPlayer}
              openFactory={openFactory}
            />
          )}
          {selectedMenu == 'factorysearch' && (
            <FactorySearch openFactory={openFactory} />
          )}
          {selectedMenu.startsWith('factory-') && (
            <FactoryView
              _id={
                find<any>(
                  (x: OpenedEntryType) =>
                    x._id === selectedMenu.replace('factory-', ''),
                  openedEntries
                )._id
              }
              openPlayer={openPlayer}
              openBankAccount={openBankAccount}
              openVehicle={openVehicle}
            />
          )}
          {selectedMenu == 'apartmentssearch' && (
            <ApartmentSearch openApartment={openApartment} />
          )}
          {selectedMenu.startsWith('apartment-') && (
            <ApartmentView
              _id={
                find<any>(
                  (x: OpenedEntryType) =>
                    x._id === selectedMenu.replace('apartment-', ''),
                  openedEntries
                )._id
              }
              openPlayer={openPlayer}
            />
          )}
          {selectedMenu == 'statebank' && <StateBankAccounts />}
          {selectedMenu == 'tickets' && <Tickets />}
          {selectedMenu == 'deaths' && <Deaths openPlayer={openPlayer} />}
          {selectedMenu == 'userlog' && <Logs />}
          {selectedMenu == 'meters' && <Meters />}
          {selectedMenu == 'bankaccountsearch' && (
            <BankAccountSearch openBankAccount={openBankAccount} />
          )}
          {selectedMenu.startsWith('bankaccount-') && (
            <BankAccountView
              _id={
                find<any>(
                  (x: OpenedEntryType) =>
                    x._id === selectedMenu.replace('bankaccount-', ''),
                  openedEntries
                )._id
              }
              openPlayer={openPlayer}
              openFactory={openFactory}
              openBankAccount={openBankAccount}
            />
          )}
          {selectedMenu == 'livestats' && <LiveStats openPlayer={openPlayer} />}
          {selectedMenu == 'leogram' && <Leogram />}
        </div>
      </div>
    </div>
  );
};
