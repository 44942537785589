import { lensPath, set } from 'ramda';
import * as types from '../../../types';

const metersP = ['apps', 'admin', 'meters'];

export const appsAdminMetersResultsP = [...metersP, 'results'];
export const appsAdminMetersResultsL = lensPath(appsAdminMetersResultsP);
const setMeters = (state: any, { data }: any) => {
  return set(appsAdminMetersResultsL, data, state);
};

export const appsAdminMetersReducerTree = {
  [types.ADMIN_METERS_FETCH_SUCCESS]: [setMeters],
};
