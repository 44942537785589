import React from 'react';
import Headline from '../../Headline';
import { FractionPlayerStatus } from './types';

type StatusType = {
  playerStatus: FractionPlayerStatus;
};

export const Status = ({ playerStatus }: StatusType) => {
  return (
    <div>
      <Headline>Ihr Behörden Status</Headline>
      <div>Staatsbehörde: {playerStatus.label}</div>
      <div>Rang: {playerStatus.teamRank}</div>
    </div>
  );
};
