export const MAIL_SEND_START = 'MAIL_SEND_START';
export const MAIL_SEND_SUCCESS = 'MAIL_SEND_SUCCESS';
export const MAIL_SEND_FAILED = 'MAIL_SEND_FAILED';

export const MAIL_INBOX_FETCH_START = 'MAIL_INBOX_FETCH_START';
export const MAIL_INBOX_FETCH_SUCCESS = 'MAIL_INBOX_FETCH_SUCCESS';
export const MAIL_INBOX_FETCH_FAILED = 'MAIL_INBOX_FETCH_FAILED';

export const MAIL_OUTBOX_FETCH_START = 'MAIL_OUTBOX_FETCH_START';
export const MAIL_OUTBOX_FETCH_SUCCESS = 'MAIL_OUTBOX_FETCH_SUCCESS';
export const MAIL_OUTBOX_FETCH_FAILED = 'MAIL_OUTBOX_FETCH_FAILED';

export const MAIL_TRASH_FETCH_START = 'MAIL_TRASH_FETCH_START';
export const MAIL_TRASH_FETCH_SUCCESS = 'MAIL_TRASH_FETCH_SUCCESS';
export const MAIL_TRASH_FETCH_FAILED = 'MAIL_TRASH_FETCH_FAILED';

export const MAIL_DELETE_START = 'MAIL_DELETE_START';
export const MAIL_DELETE_SUCCESS = 'MAIL_DELETE_SUCCESS';
export const MAIL_DELETE_FAILED = 'MAIL_DELETE_FAILED';

export const MAIL_READ_START = 'MAIL_READ_START';
export const MAIL_READ_SUCCESS = 'MAIL_READ_SUCCESS';
export const MAIL_READ_FAILED = 'MAIL_READ_FAILED';
