import React from 'react';
import { css } from '@emotion/react';

const stampStyles = css`
  width: 8vh;
  height: 8vh;
`;

const Stamp = ({ blockProps }: any) => {
  return (
    <img src={`../images/documents/${blockProps.type}.png`} css={stampStyles} />
  );
};

export default Stamp;
