import { lensPath, set } from 'ramda';
import * as types from '../../types';

export const appsMyVehiclesP = ['apps', 'myVehicles'];
export const appsMyVehiclesL = lensPath(appsMyVehiclesP);
const setAppsMyVehicles = (state: any, { data }: any) => {
  return set(appsMyVehiclesL, data, state);
};

export const appsMyVehiclesReducerTree = {
  [types.APPS_MYVEHICLE_FETCH_SUCCESS]: [setAppsMyVehicles],
};
