const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fix = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const funkStyle = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedLeft = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'text-align': 'left',
  color: '#fff',
  border: '0 !important',
};
const usedCenter = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const select = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
//Styles ab const einfügen für sep. - kann auch ohne Eigenschaft wie background genutzt werden
export default [
  [
    {
      value: 'Wichtige Informationen der Leitung',
      style: fix,
      colSpan: 5,
      readOnly: true,
      width: '200vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '=lsmdlead$Aa3',
      style: funkStyle,
      readOnly: true,
      colSpan: 5,
      width: '200vh',
    },
  ],
  [
    {
      value: '=lsmdlead$Aa4',
      style: funkStyle,
      readOnly: true,
      colSpan: 5,
      width: '200vh',
    },
  ],
  [
    {
      value: '=lsmdlead$Aa5',
      style: funkStyle,
      readOnly: true,
      colSpan: 5,
      width: '200vh',
    },
  ],
  [
    {
      value: '=lsmdlead$Aa6',
      style: funkStyle,
      readOnly: true,
      colSpan: 5,
      width: '200vh',
    },
  ],
  [
    {
      value: '=lsmdlead$Aa7',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Informationen',
      style: fix,
      colSpan: 5,
      readOnly: true,
      width: '200vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: '',
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      style: usedCenter,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'N',
        rowStart: 1,
        rowEnd: 10,
      },
    },
    { value: '', style: usedCenter, colSpan: 3, width: '190vh' },
  ],
  [
    {
      value: 'Funkfrequenzen',
      style: fix,
      colSpan: 5, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      width: '200vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Dispatch',
      style: funkStyle,
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh',
    },
    {
      value: '',
      colSpan: 3, //hier 2 Spalten breit die verbunden sind
      width: '120vh',
      style: funkStyle,
    },
  ],
  [
    {
      value: 'Fraktionsfunk LSPD / LSMD',
      style: funkStyle,
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh',
    },
    {
      value: '',
      colSpan: 3, //hier 2 Spalten breit die verbunden sind
      width: '120vh',
      style: funkStyle,
    },
  ],
  [
    {
      value: 'Einsatzstellenfunk LSFD',
      style: funkStyle,
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh',
    },
    {
      value: '',
      colSpan: 3, //hier 2 Spalten breit die verbunden sind
      width: '120vh',
      style: funkStyle,
    },
  ],
  [
    {
      value: 'Ausweichfunk / Eventfunk / Kolonnenfunk',
      style: funkStyle,
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh',
    },
    {
      value: '',
      colSpan: 3, //hier 2 Spalten breit die verbunden sind
      width: '120vh',
      style: funkStyle,
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '200vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Telefonnummern Suche',
      style: fix,
      colSpan: 5,
      readOnly: true,
      width: '200vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Namensauswahl',
      style: usedCenter,
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh',
    },
    {
      value: '',
      colSpan: 3, //hier 2 Spalten breit die verbunden sind
      width: '120vh',
      style: usedCenter,
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata', //hier aus einem anderen Tabellenblatt
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
  ],
  [
    {
      value: 'Tel',
      style: usedCenter,
      colSpan: 2, //hier 2 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh',
    },
    {
      value: '',
      style: usedCenter,
      readOnly: true,
      width: '10vh',
      type: 'lookup',
      searchField: 'A24', //Rang einfügen - Hier kommt das Suchkriterium rein
      lookup: {
        sheetId: 'lsmdcontroldata', //da soll er suchen
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
      useIndex: {
        sheetId: 'lsmdcontroldata', //das soll er nehmen
        column: 'G',
        rowStart: 1,
      },
    },
  ],
];
