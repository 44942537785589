import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetchJson, authFetchPost } from '../../utils/fetch';

export const onFetchPlayerOnDuty = (action$: any) =>
  pipeC(
    action$,
    ofType(types.PLAYER_ONDUTY_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/team/isPlayerOnDuty')
        .then(({ isOnDuty }) => [
          {
            type: types.PLAYER_ONDUTY_FETCH_SUCCESS,
            isOnDuty,
          },
        ])
        .catch(() => {});
    })
  );

export const onCreateFolderGroup = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_CREATE_START),
    mapPromiseC(({ label }: any) => {
      return authFetchPost('/group/add', { label })
        .then(() => [
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Gruppe erstellt',
          },
          {
            type: types.FOLDER_GROUP_ADMINS_FETCH_START,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Erstellen fehlgeschlagen',
        }));
    })
  );

export const onFetchGroupsAsMember = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_MEMBERS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/groups/member')
        .then((data) => [
          {
            type: types.FOLDER_GROUP_MEMBERS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchGroupsAsAdmin = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_ADMINS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/groups/admin')
        .then((data) => [
          {
            type: types.FOLDER_GROUP_ADMINS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onAddMemberToGroup = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_MEMBER_ADD_START),
    mapPromiseC(({ group, character }: any) => {
      return authFetchPost('/groups/character/add', { group, character })
        .then(() => [
          {
            type: types.FOLDER_GROUP_ADMINS_FETCH_START,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${character} hinzugefügt`,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Erstellen fehlgeschlagen',
        }));
    })
  );

export const onRmMemberFromGroup = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_MEMBER_REMOVE_START),
    mapPromiseC(({ group, character }: any) => {
      return authFetchPost('/groups/character/remove', { group, character })
        .then(() => [
          {
            type: types.FOLDER_GROUP_ADMINS_FETCH_START,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${character} entfernt`,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Entfernen fehlgeschlagen',
        }));
    })
  );

export const onAddAdminToGroup = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_ADMIN_ADD_START),
    mapPromiseC(({ group, character }: any) => {
      return authFetchPost('/groups/admin/add', { group, character })
        .then(() => [
          {
            type: types.FOLDER_GROUP_ADMINS_FETCH_START,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${character} hinzugefügt`,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Erstellen fehlgeschlagen',
        }));
    })
  );

export const onRmAdminFromGroup = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_ADMIN_REMOVE_START),
    mapPromiseC(({ group, character }: any) => {
      return authFetchPost('/groups/admin/remove', { group, character })
        .then(() => [
          {
            type: types.FOLDER_GROUP_ADMINS_FETCH_START,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${character} entfernt`,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Entfernen fehlgeschlagen',
        }));
    })
  );

export const onCreateFolderGroupForFactory = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FOLDER_GROUP_FACTORY_CREATE_START),
    mapPromiseC(({ label, factory }: any) => {
      return authFetchPost('/group/factory-add', { label, factory })
        .then(() => [
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Gruppe erstellt',
          },
          {
            type: types.FOLDER_GROUP_MEMBERS_FETCH_START,
          },
        ])
        .catch(() => ({
          type: types.NOTIFY_ERROR,
          msg: 'Erstellen fehlgeschlagen',
        }));
    })
  );
