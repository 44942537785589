import { css } from '@emotion/react';
import { pathOr, findIndex } from 'ramda';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeDocument,
  getDocument,
  selectDocumentTab,
} from '../../../actions';
import {
  documentsDetailsP,
  documentsTabSelectedP,
} from '../../../redux/reducer/apps/documents';
import { DocumentHome } from './Home';
import { DocumentTabs } from './Tabs';
import { DocumentMapType } from './types';
import { DocumentView } from './View';

export const DocumentsApp = () => {
  const dispatch = useDispatch();
  const [saveStatus, setSaveStatus] = useState<any>({});
  const documents = useSelector<any, DocumentMapType>(
    pathOr({}, documentsDetailsP)
  );
  const activeTab = useSelector(pathOr('home', documentsTabSelectedP));

  const documentIds = Object.keys(documents);

  const updateSavedStatus = (id: string, saved: boolean) => {
    setSaveStatus({ ...saveStatus, [id]: saved });
  };
  const setActiveTab = (id: string) => {
    dispatch(selectDocumentTab(id));
  };

  const tabs = [
    { label: 'Startseite', id: 'home' },
    ...documentIds.map((id) => ({ label: documents[id].subject, id })),
  ];

  const openDocument = (id: string, name: string) => {
    if (findIndex((tab) => tab.id === id, tabs) === -1) {
      dispatch(getDocument(id));
    }
    setActiveTab(id);
  };

  const closeTab = (id: string) => {
    if (id === 'home') {
      return;
    }
    dispatch(closeDocument(id));
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex-none">
        <DocumentTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          closeTab={closeTab}
          saveStatus={saveStatus}
        />
      </div>
      <div
        className="flex-grow"
        css={css`
          min-height: 0;
        `}
      >
        <div className='w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6"'>
          {tabs.map((tab) =>
            tab.id !== 'home' ? (
              <DocumentView
                key={tab.id}
                document={documents[tab.id]}
                isSelected={tab.id === activeTab}
                updateSavedStatus={updateSavedStatus}
                isSaved={!saveStatus[tab.id]}
              />
            ) : (
              <DocumentHome
                key="home"
                openDocument={openDocument}
                isSelected={activeTab === 'home'}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
