export const ADMIN_RESTARTS_TIMETABLE_FETCH_START =
  'ADMIN_RESTARTS_TIMETABLE_FETCH_START';
export const ADMIN_RESTARTS_TIMETABLE_FETCH_SUCCESS =
  'ADMIN_RESTARTS_TIMETABLE_FETCH_SUCCESS';
export const ADMIN_RESTARTS_TIMETABLE_FETCH_FAILED =
  'ADMIN_RESTARTS_TIMETABLE_FETCH_FAILED';

export const ADMIN_RESTARTS_TIMETABLE_PROP_SET =
  'ADMIN_RESTARTS_TIMETABLE_PROP_SET';

export const ADMIN_RESTARTS_TIMETABLE_UPDATE_START =
  'ADMIN_RESTARTS_TIMETABLE_UPDATE_START';
export const ADMIN_RESTARTS_TIMETABLE_UPDATE_SUCCESS =
  'ADMIN_RESTARTS_TIMETABLE_UPDATE_SUCCESS';
export const ADMIN_RESTARTS_TIMETABLE_UPDATE_FAILED =
  'ADMIN_RESTARTS_TIMETABLE_UPDATE_FAILED';

export const ADMIN_RESTARTS_FETCH_START = 'ADMIN_RESTARTS_FETCH_START';
export const ADMIN_RESTARTS_FETCH_SUCCESS = 'ADMIN_RESTARTS_FETCH_SUCCESS';
export const ADMIN_RESTARTS_FETCH_FAILED = 'ADMIN_RESTARTS_FETCH_FAILED';

export const ADMIN_RESTART_SERVER_START = 'ADMIN_RESTART_SERVER_START';
export const ADMIN_RESTART_SERVER_SUCCESS = 'ADMIN_RESTART_SERVER_SUCCESS';
export const ADMIN_RESTART_SERVER_FAILED = 'ADMIN_RESTART_SERVER_FAILED';

export const ADMIN_ROLES_FETCH_START = 'ADMIN_ROLES_FETCH_START';
export const ADMIN_ROLES_FETCH_SUCCESS = 'ADMIN_ROLES_FETCH_SUCCESS';
export const ADMIN_ROLES_FETCH_FAILED = 'ADMIN_ROLES_FETCH_FAILED';

export const ADMIN_RIGHTS_ALL_FETCH_START = 'ADMIN_RIGHTS_ALL_FETCH_START';
export const ADMIN_RIGHTS_ALL_FETCH_SUCCESS = 'ADMIN_RIGHTS_ALL_FETCH_SUCCESS';
export const ADMIN_RIGHTS_ALL_FETCH_FAILED = 'ADMIN_RIGHTS_ALL_FETCH_FAILED';

export const ADMIN_ROLES_ADD_START = 'ADMIN_ROLES_ADD_START';
export const ADMIN_ROLES_ADD_SUCCESS = 'ADMIN_ROLES_ADD_SUCCESS';
export const ADMIN_ROLES_ADD_FAILED = 'ADMIN_ROLES_ADD_FAILED';

export const ADMIN_ROLES_EDIT_START = 'ADMIN_ROLES_EDIT_START';
export const ADMIN_ROLES_EDIT_SUCCESS = 'ADMIN_ROLES_EDIT_SUCCESS';
export const ADMIN_ROLES_EDIT_FAILED = 'ADMIN_ROLES_EDIT_FAILED';

export const ADMIN_ROLES_DELETE_START = 'ADMIN_ROLES_DELETE_START';
export const ADMIN_ROLES_DELETE_SUCCESS = 'ADMIN_ROLES_DELETE_SUCCESS';
export const ADMIN_ROLES_DELETE_FAILED = 'ADMIN_ROLES_DELETE_FAILED';

export const ADMIN_PERMISSIONS_FETCH_START = 'ADMIN_PERMISSIONS_FETCH_START';
export const ADMIN_PERMISSIONS_FETCH_SUCCESS =
  'ADMIN_PERMISSIONS_FETCH_SUCCESS';
export const ADMIN_PERMISSIONS_FETCH_FAILED = 'ADMIN_PERMISSIONS_FETCH_FAILED';

export const ADMIN_PERMISSIONS_CHANGE_START = 'ADMIN_PERMISSIONS_CHANGE_START';
export const ADMIN_PERMISSIONS_CHANGE_SUCCESS =
  'ADMIN_PERMISSIONS_CHANGE_SUCCESS';
export const ADMIN_PERMISSIONS_CHANGE_FAILED =
  'ADMIN_PERMISSIONS_CHANGE_FAILED';

export const ADMIN_PERMISSIONS_DELETE_START = 'ADMIN_PERMISSIONS_DELETE_START';
export const ADMIN_PERMISSIONS_DELETE_SUCCESS =
  'ADMIN_PERMISSIONS_DELETE_SUCCESS';
export const ADMIN_PERMISSIONS_DELETE_FAILED =
  'ADMIN_PERMISSIONS_DELETE_FAILED';

export const ADMIN_PLAYER_SEARCH_SET = 'ADMIN_PLAYER_SEARCH_SET';
export const ADMIN_PLAYER_SEARCH_FETCH_START =
  'ADMIN_PLAYER_SEARCH_FETCH_START';
export const ADMIN_PLAYER_SEARCH_FETCH_SUCCESS =
  'ADMIN_PLAYER_SEARCH_FETCH_SUCCESS';
export const ADMIN_PLAYER_SEARCH_FETCH_FAILED =
  'ADMIN_PLAYER_SEARCH_FETCH_FAILED';

export const ADMIN_PLAYER_DETAILS_FETCH_START =
  'ADMIN_PLAYER_DETAILS_FETCH_START';
export const ADMIN_PLAYER_DETAILS_FETCH_SUCCESS =
  'ADMIN_PLAYER_DETAILS_FETCH_SUCCESS';
export const ADMIN_PLAYER_DETAILS_FETCH_FAILED =
  'ADMIN_PLAYER_DETAILS_FETCH_FAILED';

export const ADMIN_PLAYER_TP_ADMINBASE_FETCH_START =
  'ADMIN_PLAYER_TP_ADMINBASE_FETCH_START';
export const ADMIN_PLAYER_TP_ADMINBASE_FETCH_SUCCESS =
  'ADMIN_PLAYER_TP_ADMINBASE_FETCH_SUCCESS';
export const ADMIN_PLAYER_TP_ADMINBASE_FETCH_FAILED =
  'ADMIN_PLAYER_TP_ADMINBASE_FETCH_FAILED';

export const ADMIN_PLAYER_DISABLESERIAL_TOGGLE_FETCH_START =
  'ADMIN_PLAYER_DISABLESERIAL_TOGGLE_FETCH_START';

export const ADMIN_PLAYER_MULTIACCOUNT_ACCCHECK_TOGGLE_FETCH_START =
  'ADMIN_PLAYER_MULTIACCOUNT_ACCCHECK_TOGGLE_FETCH_START';

export const ADMIN_PLAYER_SECONDCHANCE_FETCH_START =
  'ADMIN_PLAYER_SECONDCHANCE_FETCH_START';

export const ADMIN_PLAYER_IDS_FETCH_START = 'ADMIN_PLAYER_IDS_FETCH_START';
export const ADMIN_PLAYER_IDS_FETCH_SUCCESS = 'ADMIN_PLAYER_IDS_FETCH_SUCCESS';
export const ADMIN_PLAYER_IDS_FETCH_FAILED = 'ADMIN_PLAYER_IDS_FETCH_FAILED';

export const ADMIN_PLAYER_BACKACCOUNTS_FETCH_START =
  'ADMIN_PLAYER_BACKACCOUNTS_FETCH_START';
export const ADMIN_PLAYER_BACKACCOUNTS_FETCH_SUCCESS =
  'ADMIN_PLAYER_BACKACCOUNTS_FETCH_SUCCESS';
export const ADMIN_PLAYER_BACKACCOUNTS_FETCH_FAILED =
  'ADMIN_PLAYER_BACKACCOUNTS_FETCH_FAILED';

export const ADMIN_PLAYER_VEHICLES_FETCH_START =
  'ADMIN_PLAYER_VEHICLES_FETCH_START';
export const ADMIN_PLAYER_VEHICLES_FETCH_SUCCESS =
  'ADMIN_PLAYER_VEHICLES_FETCH_SUCCESS';
export const ADMIN_PLAYER_VEHICLES_FETCH_FAILED =
  'ADMIN_PLAYER_VEHICLES_FETCH_FAILED';

export const ADMIN_PLAYER_TELNUMBERS_FETCH_START =
  'ADMIN_PLAYER_TELNUMBERS_FETCH_START';
export const ADMIN_PLAYER_TELNUMBERS_FETCH_SUCCESS =
  'ADMIN_PLAYER_TELNUMBERS_FETCH_SUCCESS';
export const ADMIN_PLAYER_TELNUMBERS_FETCH_FAILED =
  'ADMIN_PLAYER_TELNUMBERS_FETCH_FAILED';

export const ADMIN_PLAYER_INVENTORY_FETCH_START =
  'ADMIN_PLAYER_INVENTORY_FETCH_START';
export const ADMIN_PLAYER_INVENTORY_FETCH_SUCCESS =
  'ADMIN_PLAYER_INVENTORY_FETCH_SUCCESS';
export const ADMIN_PLAYER_INVENTORY_FETCH_FAILED =
  'ADMIN_PLAYER_INVENTORY_FETCH_FAILED';

export const ADMIN_PLAYER_MULTIACCOUNT_FETCH_START =
  'ADMIN_PLAYER_MULTIACCOUNT_FETCH_START';
export const ADMIN_PLAYER_MULTIACCOUNT_FETCH_SUCCESS =
  'ADMIN_PLAYER_MULTIACCOUNT_FETCH_SUCCESS';
export const ADMIN_PLAYER_MULTIACCOUNT_FETCH_FAILED =
  'ADMIN_PLAYER_MULTIACCOUNT_FETCH_FAILED';

export const ADMIN_PLAYER_MULTIACCOUNTB_FETCH_START =
  'ADMIN_PLAYER_MULTIACCOUNTB_FETCH_START';
export const ADMIN_PLAYER_MULTIACCOUNTB_FETCH_SUCCESS =
  'ADMIN_PLAYER_MULTIACCOUNTB_FETCH_SUCCESS';
export const ADMIN_PLAYER_MULTIACCOUNTB_FETCH_FAILED =
  'ADMIN_PLAYER_MULTIACCOUNTB_FETCH_FAILED';

export const ADMIN_PLAYER_FACTORYOWNER_FETCH_START =
  'ADMIN_PLAYER_FACTORYOWNER_FETCH_START';
export const ADMIN_PLAYER_FACTORYOWNER_FETCH_SUCCESS =
  'ADMIN_PLAYER_FACTORYOWNER_FETCH_SUCCESS';
export const ADMIN_PLAYER_FACTORYOWNER_FETCH_FAILED =
  'ADMIN_PLAYER_FACTORYOWNER_FETCH_FAILED';

export const ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_START =
  'ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_START';
export const ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_SUCCESS =
  'ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_SUCCESS';
export const ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_FAILED =
  'ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_FAILED';

export const ADMIN_PLAYER_APARTMENTS_FETCH_START =
  'ADMIN_PLAYER_APARTMENTS_FETCH_START';
export const ADMIN_PLAYER_APARTMENTS_FETCH_SUCCESS =
  'ADMIN_PLAYER_APARTMENTS_FETCH_SUCCESS';
export const ADMIN_PLAYER_APARTMENTS_FETCH_FAILED =
  'ADMIN_PLAYER_APARTMENTS_FETCH_FAILED';

export const ADMIN_PLAYER_DEATHS_FETCH_START =
  'ADMIN_PLAYER_DEATHS_FETCH_START';
export const ADMIN_PLAYER_DEATHS_FETCH_SUCCESS =
  'ADMIN_PLAYER_DEATHS_FETCH_SUCCESS';
export const ADMIN_PLAYER_DEATHS_FETCH_FAILED =
  'ADMIN_PLAYER_DEATHS_FETCH_FAILED';

export const ADMIN_PLAYER_TICKETS_FETCH_START =
  'ADMIN_PLAYER_TICKETS_FETCH_START';
export const ADMIN_PLAYER_TICKETS_FETCH_SUCCESS =
  'ADMIN_PLAYER_TICKETS_FETCH_SUCCESS';
export const ADMIN_PLAYER_TICKETS_FETCH_FAILED =
  'ADMIN_PLAYER_TICKETS_FETCH_FAILED';

export const ADMIN_PLAYER_NOTES_FETCH_START = 'ADMIN_PLAYER_NOTES_FETCH_START';
export const ADMIN_PLAYER_NOTES_FETCH_SUCCESS =
  'ADMIN_PLAYER_NOTES_FETCH_SUCCESS';
export const ADMIN_PLAYER_NOTES_FETCH_FAILED =
  'ADMIN_PLAYER_NOTES_FETCH_FAILED';

export const ADMIN_PLAYER_WARNINGS_FETCH_START =
  'ADMIN_PLAYER_WARNINGS_FETCH_START';
export const ADMIN_PLAYER_WARNINGS_FETCH_SUCCESS =
  'ADMIN_PLAYER_WARNINGS_FETCH_SUCCESS';
export const ADMIN_PLAYER_WARNINGS_FETCH_FAILED =
  'ADMIN_PLAYER_WARNINGS_FETCH_FAILED';

export const ADMIN_PLAYER_BANS_FETCH_START = 'ADMIN_PLAYER_BANS_FETCH_START';
export const ADMIN_PLAYER_BANS_FETCH_SUCCESS =
  'ADMIN_PLAYER_BANS_FETCH_SUCCESS';
export const ADMIN_PLAYER_BANS_FETCH_FAILED = 'ADMIN_PLAYER_BANS_FETCH_FAILED';

export const ADMIN_PLAYER_NAMECHANGE_START = 'ADMIN_PLAYER_NAMECHANGE_START';
export const ADMIN_PLAYER_PWCHANGE_START = 'ADMIN_PLAYER_PWCHANGE_START';
export const ADMIN_PLAYER_MSG_START = 'ADMIN_PLAYER_MSG_START';
export const ADMIN_PLAYER_KICK_START = 'ADMIN_PLAYER_KICK_START';

export const ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_START =
  'ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_START';
export const ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_SUCCESS =
  'ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_SUCCESS';
export const ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_FAILED =
  'ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_FAILED';

export const ADMIN_PLAYER_MAX_BANKACCOUNT_SET_START =
  'ADMIN_PLAYER_MAX_BANKACCOUNT_SET_START';

export const ADMIN_PLAYER_SCMULTIACCOUNT_RESET_START =
  'ADMIN_PLAYER_SCMULTIACCOUNT_RESET_START';

export const ADMIN_PLAYER_NOTE_CREATE_START = 'ADMIN_PLAYER_NOTE_CREATE_START';
export const ADMIN_PLAYER_WARNING_CREATE_START =
  'ADMIN_PLAYER_WARNING_CREATE_START';
export const ADMIN_PLAYER_BAN_CREATE_START = 'ADMIN_PLAYER_BAN_CREATE_START';
export const ADMIN_PLAYER_BAN_REMOVE_START = 'ADMIN_PLAYER_BAN_REMOVE_START';

export const ADMIN_PLAYER_ITEM_REMOVE_START = 'ADMIN_PLAYER_ITEM_REMOVE_START';

export const ADMIN_VEHICLES_SEARCH_SET = 'ADMIN_VEHICLES_SEARCH_SET';
export const ADMIN_VEHICLES_SEARCH_FETCH_START =
  'ADMIN_VEHICLES_SEARCH_FETCH_START';
export const ADMIN_VEHICLES_SEARCH_FETCH_SUCCESS =
  'ADMIN_VEHICLES_SEARCH_FETCH_SUCCESS';
export const ADMIN_VEHICLES_SEARCH_FETCH_FAILED =
  'ADMIN_VEHICLES_SEARCH_FETCH_FAILED';

export const ADMIN_VEHICLE_FETCH_START = 'ADMIN_VEHICLE_FETCH_START';
export const ADMIN_VEHICLE_FETCH_SUCCESS = 'ADMIN_VEHICLE_FETCH_SUCCESS';
export const ADMIN_VEHICLE_FETCH_FAILED = 'ADMIN_VEHICLE_FETCH_FAILED';

export const ADMIN_VEHICLE_ITEM_REMOVE_START =
  'ADMIN_VEHICLE_ITEM_REMOVE_START';

export const ADMIN_VEHICLE_GARAGE_START = 'ADMIN_VEHICLE_GARAGE_START';

export const ADMIN_VEHICLE_OWNER_NEW_SEARCH_START =
  'ADMIN_VEHICLE_OWNER_NEW_SEARCH_START';
export const ADMIN_VEHICLE_OWNER_NEW_SEARCH_SUCCESS =
  'ADMIN_VEHICLE_OWNER_NEW_SEARCH_SUCCESS';
export const ADMIN_VEHICLE_OWNER_NEW_SEARCH_FAILED =
  'ADMIN_VEHICLE_OWNER_NEW_SEARCH_FAILED';

export const ADMIN_VEHICLE_OWNER_CHANGE_START =
  'ADMIN_VEHICLE_OWNER_CHANGE_START';

export const ADMIN_VEHICLE_NUMBERPLATE_CHANGE_START =
  'ADMIN_VEHICLE_NUMBERPLATE_CHANGE_START';

export const ADMIN_VEHICLE_REPAIR_START = 'ADMIN_VEHICLE_REPAIR_START';

export const ADMIN_FACTORY_SEARCH_SET = 'ADMIN_FACTORY_SEARCH_SET';
export const ADMIN_FACTORY_SEARCH_FETCH_START =
  'ADMIN_FACTORY_SEARCH_FETCH_START';
export const ADMIN_FACTORY_SEARCH_FETCH_SUCCESS =
  'ADMIN_FACTORY_SEARCH_FETCH_SUCCESS';
export const ADMIN_FACTORY_SEARCH_FETCH_FAILED =
  'ADMIN_FACTORY_SEARCH_FETCH_FAILED';

export const ADMIN_FACTORY_FETCH_START = 'ADMIN_FACTORY_FETCH_START';
export const ADMIN_FACTORY_FETCH_SUCCESS = 'ADMIN_FACTORY_FETCH_SUCCESS';
export const ADMIN_FACTORY_FETCH_FAILED = 'ADMIN_FACTORY_FETCH_FAILED';

export const ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_START =
  'ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_START';
export const ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_SUCCESS =
  'ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_SUCCESS';
export const ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_FAILED =
  'ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_FAILED';

export const ADMIN_FACTORY_OWNER_SET_START = 'ADMIN_FACTORY_OWNER_SET_START';
export const ADMIN_FACTORY_RELOAD_START = 'ADMIN_FACTORY_RELOAD_START';

export const ADMIN_FACTORY_VEHICLES_FETCH_START =
  'ADMIN_FACTORY_VEHICLES_FETCH_START';
export const ADMIN_FACTORY_VEHICLES_FETCH_SUCCESS =
  'ADMIN_FACTORY_VEHICLES_FETCH_SUCCESS';
export const ADMIN_FACTORY_VEHICLES_FETCH_FAILED =
  'ADMIN_FACTORY_VEHICLES_FETCH_FAILED';

export const ADMIN_APARTMENT_SEARCH_SET = 'ADMIN_APARTMENT_SEARCH_SET';
export const ADMIN_APARTMENT_SEARCH_FETCH_START =
  'ADMIN_APARTMENT_SEARCH_FETCH_START';
export const ADMIN_APARTMENT_SEARCH_FETCH_SUCCESS =
  'ADMIN_APARTMENT_SEARCH_FETCH_SUCCESS';
export const ADMIN_APARTMENT_SEARCH_FETCH_FAILED =
  'ADMIN_APARTMENT_SEARCH_FETCH_FAILED';

export const ADMIN_APARTMENT_FETCH_START = 'ADMIN_APARTMENT_FETCH_START';
export const ADMIN_APARTMENT_FETCH_SUCCESS = 'ADMIN_APARTMENT_FETCH_SUCCESS';
export const ADMIN_APARTMENT_FETCH_FAILED = 'ADMIN_APARTMENT_FETCH_FAILED';

export const ADMIN_STATEBANKACCOUNTS_FETCH_START =
  'ADMIN_STATEBANKACCOUNTS_FETCH_START';
export const ADMIN_STATEBANKACCOUNTS_FETCH_SUCCESS =
  'ADMIN_STATEBANKACCOUNTS_FETCH_SUCCESS';
export const ADMIN_STATEBANKACCOUNTS_FETCH_FAILED =
  'ADMIN_STATEBANKACCOUNTS_FETCH_FAILED';

export const ADMIN_TICKETS_SEARCH_SET = 'ADMIN_TICKETS_SEARCH_SET';
export const ADMIN_TICKETS_FETCH_START = 'ADMIN_TICKETS_FETCH_START';
export const ADMIN_TICKETS_FETCH_SUCCESS = 'ADMIN_TICKETS_FETCH_SUCCESS';
export const ADMIN_TICKETS_FETCH_FAILED = 'ADMIN_TICKETS_FETCH_FAILED';

export const ADMIN_TICKET_DELETE_START = 'ADMIN_TICKET_DELETE_START';

export const ADMIN_DEATHS_SEARCH_SET = 'ADMIN_DEATHS_SEARCH_SET';
export const ADMIN_DEATHS_FETCH_START = 'ADMIN_DEATHS_FETCH_START';
export const ADMIN_DEATHS_FETCH_SUCCESS = 'ADMIN_DEATHS_FETCH_SUCCESS';
export const ADMIN_DEATHS_FETCH_FAILED = 'ADMIN_DEATHS_FETCH_FAILED';

export const ADMIN_LOGS_SEARCH_SET = 'ADMIN_LOGS_SEARCH_SET';
export const ADMIN_LOGS_FETCH_START = 'ADMIN_LOGS_FETCH_START';
export const ADMIN_LOGS_FETCH_SUCCESS = 'ADMIN_LOGS_FETCH_SUCCESS';
export const ADMIN_LOGS_FETCH_FAILED = 'ADMIN_LOGS_FETCH_FAILED';

export const ADMIN_BANKACCOUNTS_SEARCH_SET = 'ADMIN_BANKACCOUNTS_SEARCH_SET';
export const ADMIN_BANKACCOUNTS_FETCH_START = 'ADMIN_BANKACCOUNTS_FETCH_START';
export const ADMIN_BANKACCOUNTS_FETCH_SUCCESS =
  'ADMIN_BANKACCOUNTS_FETCH_SUCCESS';
export const ADMIN_BANKACCOUNTS_FETCH_FAILED =
  'ADMIN_BANKACCOUNTS_FETCH_FAILED';

export const ADMIN_BANKACCOUNT_DETAIL_FETCH_START =
  'ADMIN_BANKACCOUNT_DETAIL_FETCH_START';
export const ADMIN_BANKACCOUNT_DETAIL_FETCH_SUCCESS =
  'ADMIN_BANKACCOUNT_DETAIL_FETCH_SUCCESS';
export const ADMIN_BANKACCOUNT_DETAIL_FETCH_FAILED =
  'ADMIN_BANKACCOUNT_DETAIL_FETCH_FAILED';

export const ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_START =
  'ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_START';
export const ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_SUCCESS =
  'ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_SUCCESS';
export const ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_FAILED =
  'ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_FAILED';

export const ADMIN_BANKACCOUNT_BALANCE_EDIT_START =
  'ADMIN_BANKACCOUNT_BALANCE_EDIT_START';

export const ADMIN_BANKACCOUNT_MAKE_MAIN_START =
  'ADMIN_BANKACCOUNT_MAKE_MAIN_START';

export const ADMIN_LIVESTATS_FETCH_START = 'ADMIN_LIVESTATS_FETCH_START';
export const ADMIN_LIVESTATS_FETCH_SUCCESS = 'ADMIN_LIVESTATS_FETCH_SUCCESS';
export const ADMIN_LIVESTATS_FETCH_FAILED = 'ADMIN_LIVESTATS_FETCH_FAILED';

export const ADMIN_METERS_FETCH_START = 'ADMIN_METERS_FETCH_START';
export const ADMIN_METERS_FETCH_SUCCESS = 'ADMIN_METERS_FETCH_SUCCESS';

export const ADMIN_LEOGRAM_LIST_FETCH_START = 'ADMIN_LEOGRAM_LIST_FETCH_START';
export const ADMIN_LEOGRAM_LIST_FETCH_SUCCESS =
  'ADMIN_LEOGRAM_LIST_FETCH_SUCCESS';

export const ADMIN_LEOGRAM_POST_DELETE_START =
  'ADMIN_LEOGRAM_POST_DELETE_START';
export const ADMIN_LEOGRAM_POST_RMAD_START = 'ADMIN_LEOGRAM_POST_RMAD_START';
