import { path, pipe } from 'ramda';
import { connect } from 'react-redux';
import { createPost, setPostText } from '../actions';
import { NewMessage } from '../components/NewMessage';
import { postTextP } from '../store/reducer/posts';
import { initScreenshot } from '../actions/screenshot';
import { screenshotLoadingP, screenshotP } from '../store/reducer/screenshot';
import { profilePageContentP } from '../store/reducer/profilepage';

const mapStateToProps = (state: any) => {
  return {
    text: path(postTextP, state),
    isLoading: path(screenshotLoadingP, state),
    screenshot: path(screenshotP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  createPost: pipe(createPost, dispatch),
  setPostText: pipe(setPostText, dispatch),
  initScreenshot: pipe(initScreenshot, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(NewMessage);
