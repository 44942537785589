import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import 'react-rangeslider/lib/index.css';
import { toggleWIFI } from '../../redux/action';
import { pathOr } from 'ramda';
import { darkP } from '../../redux/reducer/common';
import { css } from '@emotion/react';

const zIndexStyle = css`
  z-index: 1000000;
`;
class WifiMenu extends Component {
  constructor(props) {
    super(props);
    this.wifiRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (
      this.wifiRef &&
      !this.wifiRef.current.contains(e.target) &&
      !this.props.btnRef.current.contains(e.target)
    )
      this.props.toggleWifiMenu();
  }

  render() {
    return (
      <div
        className="fixed w-80 max-w-full top-8 right-0 sm:right-2 px-2 py-0.5 flex gap-2 bg-gray-200 bg-opacity-90 blur rounded-lg text-black shadow-2xl"
        css={zIndexStyle}
        ref={this.wifiRef}
      >
        <div className="w-4/5 p-2 font-medium">Wi-Fi</div>
        <div className="w-1/5 p-2">
          <label className="switch-toggle">
            <input
              type="checkbox"
              checked={this.props.wifi}
              onClick={() => this.props.toggleWIFI(!this.props.wifi)}
              onChange={() => {}}
            />
            <span className="slider-toggle round"></span>
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dark: pathOr(false, darkP, state),
    wifi: state.wifi,
  };
};

export default connect(mapStateToProps, {
  toggleWIFI,
})(WifiMenu);
