import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC } from '../utils/callbag';
import { authFetch } from '../utils/fetch';

export const onAdsFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADS_FETCH_START),
    mapPromiseC(({ offset }: any) => {
      return authFetch(`/leogram/ads?skip=${offset}`)
        .then((res) => {
          return res.json().then((posts) => ({
            type: types.ADS_FETCH_SUCCESS,
            offset,
            posts,
          }));
        })
        .catch(() => {});
    })
  );
