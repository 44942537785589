import beachart from './interiors/beachart-apartment';
import luxuryHouse from './interiors/luxury-house';
import medium from './interiors/medium-apartment';
import modern7 from './interiors/modern-apartment7';
import modern8 from './interiors/modern-apartment8';
import highEndHouse from './interiors/high-end-house';
import caravan from './interiors/caravan';
import mediumHouse from './interiors/medium-house';
import lowEndApartment from './interiors/low-end-apartment';
import newWaterfallHouse from './interiors/new-waterfall-house';
import newFashionHouse from './interiors/new-fashion-house';
import lightApartment from './interiors/light-apartment';
import modern1 from './interiors/modern-apartment1';
import newAquaApartment from './interiors/new-aqua-apartment';
import newLionApartment from './interiors/new-lion-apartment';
import newModyApartment from './interiors/new-mody-apartment';
import newMonochromeApartment from './interiors/new-monochrome-apartment';
import newRegalApartment from './interiors/new-regal-apartment';
import newSeductiveApartment from './interiors/new-seductive-apartment';
import newSharpApartment from './interiors/new-sharp-apartment';
import newVibesApartment from './interiors/new-vibes-apartment';
import newVibrantApartment from './interiors/new-vibrant-apartment';

export default {
  layout: 'SimpleMenuWithContent',
  backgroundCss: `
		background: #f5f5f5;
	`,
  menuHeaderText: 'Interieure',
  menu: [
    { value: 'home', label: 'Startseite' },
    { value: 'beachart', label: 'Beachart Apartment' },
    { value: 'luxuryHouse', label: 'Luxury House' },
    { value: 'medium', label: 'Medium Apartment' },
    { value: 'modern1', label: 'Modern Apartment 1' },
    { value: 'modern7', label: 'Modern Apartment 7' },
    { value: 'modern8', label: 'Modern Apartment 8' },
    { value: 'lowEndApartment', label: 'Low End Apartment' },
    { value: 'highEndHouse', label: 'High-End House' },
    { value: 'mediumHouse', label: 'Medium House' },
    { value: 'caravan', label: 'Wohnwagen' },
    { value: 'newWaterfallHouse', label: 'New Waterfall House' },
    { value: 'newFashionHouse', label: 'New Fashion House' },
    { value: 'lightApartment', label: 'Light Apartment' },
    { value: 'newAquaApartment', label: 'New Aqua Apartment' },
    { value: 'newModyApartment', label: 'New Mody Apartment' },
    { value: 'newMonochromeApartment', label: 'New Monochrome Apartment' },
    { value: 'newRegalApartment', label: 'New Regal Apartment' },
    { value: 'newSeductiveApartment', label: 'New Seductive Apartment' },
    { value: 'newSharpApartment', label: 'New Sharp Apartment' },
    { value: 'newVibesApartment', label: 'New Vibes Apartment' },
    { value: 'newVibrantApartment', label: 'New Vibrant Apartment' },
  ],
  pages: {
    home: [
      { type: 'h1', text: 'Willkommen', customCss: '' },
      {
        type: 'section',
        text: 'Hier wird Ihr Traum wahr. Sehen sie sich um und finden Sie Ihren Traum. Eine Entspannung für jeden Geldbeutel',
        customCss: '',
      },
      {
        type: 'inline',
        text: 'Die Auswahl ist riesig: ',
        customCss: 'margin-top: 1vh;',
      },
      {
        type: 'inline',
        text: 'Luxus, Modern, Medium oder Motel.',
        customCss: 'font-style: italic;',
      },
      {
        type: 'inline',
        text: 'Und das ist noch nicht alles. Schauen Sie sich in Ruhe um und schlafen Sie einen Nacht darüber. Diese Entscheidung ist keine einfache und keiner kann Sie Ihnen abnehmen.',
        customCss: '',
      },
      {
        type: 'section',
        text: 'Viel Spass beim Stöbern :)',
        customCss: '',
      },
    ],
    // luxus: modernRed,
    beachart,
    luxuryHouse,
    medium,
    modern7,
    modern8,
    highEndHouse,
    mediumHouse,
    caravan,
    lowEndApartment,
    newWaterfallHouse,
    newFashionHouse,
    lightApartment,
    modern1,
    newAquaApartment,
    newLionApartment,
    newModyApartment,
    newMonochromeApartment,
    newRegalApartment,
    newSeductiveApartment,
    newSharpApartment,
    newVibesApartment,
    newVibrantApartment,
  },
};
