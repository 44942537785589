export default [
  {
    type: 'h1',
    text: 'Wohnwagen',
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Auch für den kleinere Geldbeutel gibt es Möglichkeiten zu wohnen! Gerade für Personen, welche sich von der Zivilisation zurückziehen wollen, ist ein Wohnwagen die perfekte Wahl.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/caravan/main.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Klein, kompakt, charmant und sogar mit Badezimmer! Es reicht vielleicht nicht für eine Poolparty, aber zweckerfüllend ist es!',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/caravan/shower.jpg'],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Natürlich ist auch hier genügend Platz um selber etwas Einrichtung mitzubringen. Gestalten Sie den freien Platz, wie Sie wollen!',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/caravan/bedroom.jpg'],
    customCss: '',
  },
];
