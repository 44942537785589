import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import {
  authFetch,
  authFetchDelete,
  authFetchJson,
  authFetchPost,
} from '../../utils/fetch';

export const onFetchFractionPlayerStatus = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_PLAYER_STATUS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/teams/playerstatus')
        .then((data) => [
          {
            type: types.FRACTION_PLAYER_STATUS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => ({
          type: types.FRACTION_PLAYER_STATUS_FETCH_FAILED,
        }));
    })
  );

export const onFetchFractionEmployees = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_EMPLOYEES_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/team')
        .then((data) => [
          {
            type: types.FRACTION_EMPLOYEES_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => [
          {
            type: types.FRACTION_EMPLOYEES_FETCH_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Abfragen der Beamten',
          },
        ]);
    })
  );

export const onChangeFractionEmployeeRank = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_EMPLOYEES_RANK_CHANGE_START),
    mapPromiseC(({ name, rank }: any) => {
      return authFetchPost('/team/member', { name, rank })
        .then((data) => [
          {
            type: types.FRACTION_EMPLOYEES_RANK_CHANGE_SUCCESS,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Ändern des Beamten erfolgreich',
          },
          {
            type: types.FRACTION_EMPLOYEES_FETCH_START,
          },
        ])
        .catch(() => [
          {
            type: types.FRACTION_EMPLOYEES_RANK_CHANGE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Ändern des Beamten',
          },
        ]);
    })
  );

export const onFireFractionEmployee = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_EMPLOYEES_FIRE_START),
    mapPromiseC(({ name }: any) => {
      return authFetchDelete(`/team/member/${name}`)
        .then(() => [
          {
            type: types.FRACTION_EMPLOYEES_FIRE_SUCCESS,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${name} gefeuert`,
          },
          {
            type: types.FRACTION_EMPLOYEES_FETCH_START,
          },
        ])
        .catch(() => [
          {
            type: types.FRACTION_EMPLOYEES_FIRE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: `Fehler beim Feuern von ${name}`,
          },
        ]);
    })
  );

export const onFetchFractionSalary = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_SALARY_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/team/salary')
        .then((data) => [
          {
            type: types.FRACTION_SALARY_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => ({
          type: types.FRACTION_SALARY_FETCH_FAILED,
        }));
    })
  );

export const onInviteFractionEmployee = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_EMPLOYEES_INVITE_START),
    mapPromiseC(({ name }: any) => {
      return authFetch(`/team/invitation/${name}?mail=true`)
        .then(() => [
          {
            type: types.NOTIFY_SUCCESS,
            msg: `${name} erfolgreich eingeladen`,
          },
        ])
        .catch(() => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Einladung fehlgeschlagen',
          },
        ]);
    })
  );

export const onFetchFractionDutyList = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_DUTYLIST_FETCH_START),
    mapPromiseC(({ search, page }: any) => {
      return authFetchJson(`/team/duty/list?search=${search}&page=${page}`)
        .then((data) => [
          {
            type: types.FRACTION_DUTYLIST_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Abfragen der Stempelkarten fehlgeschlagen',
          },
        ]);
    })
  );
