const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedFullDoctor = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  color: '#d50000',
  'font-weight': 'bold',
  'justify-content': 'center',
  border: '0 !important',
};
const usedFix = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
};
export default [
  [
    {
      value: 'MEDIKAMENTENBLATT',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Tabletten',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  //Kategorie: Schmerzmittel
  [
    {
      value: 'Kategorie: Schmerzmittel',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ibuprofen 800mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'leichte bis mäßig starke Schmerzen, Fieber und Entzündungen.',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 1200 - 2400mg pro Tag / Einzeldosis höchstens 1000mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ibuprofen 1000mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'leichte bis mäßig starke Schmerzen, Fieber und Entzündungen.',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 1200 - 2400mg pro Tag / Einzeldosis höchstens 1000mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Oxycodon 2,5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Starke Schmerzen die nur mit Opioiden behandel werden können',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 20mg pro Tag / Einzeldosis höchstens 5mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Oxycodon 5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Starke Schmerzen die nur mit Opioiden behandel werden können',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 20mg pro Tag / Einzeldosis höchstens 5mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  //Kategorie: Beruhigungsmittel
  [
    {
      value: 'Kategorie: Beruhigungsmittel',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Tavor 1mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value:
        'Schlaf- und Beruhigungsmitteln, Angstzustände und Schlafstörungen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 1 Tablette!',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Tavor 2mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value:
        'Schlaf- und Beruhigungsmitteln, Angstzustände und Schlafstörungen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 1 Tablette!',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Vasodilatatoren
  [
    {
      value: 'Kategorie: Vasodilatatoren',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Nitrolingual Mundspray',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung bei Hypertonie oder pektanginösen Beschwerden',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 1 - 3 Sprühstöße',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Adsorbenzien
  [
    {
      value: 'Kategorie: Adsorbenzien',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Medizinische Kohle',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung bei Oralen Vergiftungen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1 Tablette',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Anitasthmatika
  [
    {
      value: 'Kategorie: Anitasthmatika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Theophyllin 300mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwednung bei Asthma und COPD',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antibiotika
  [
    {
      value: 'Kategorie: Antibiotika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Penicillin 1,5Mega',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Bei Bakteriellen Infektionen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 3x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Amoxicillin 875mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Bei Bakteriellen Infektionen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 3x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Cephalexin 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Bei Bakteriellen Infektionen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 3x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Gentamicin 80mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Bei Bakteriellen Infektionen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 3x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antidrepessiva
  [
    {
      value: 'Kategorie: Antidrepessiva',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Sertralin 50mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung Bei Depression',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Venlafaxin 75mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung Bei Depression',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x 1/2 Tablette täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Imipramin 50mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung Bei Depression',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 3x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Biguanide
  [
    {
      value: 'Kategorie: Biguanide',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Metformin 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung von Typ 2 Diabetis',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antiepileptika
  [
    {
      value: 'Kategorie: Antiepileptika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Keppra 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung von chronischer Epilepsie',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antikoagulanzien
  [
    {
      value: 'Kategorie: Antikoagulanzien',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Falithrom 1,5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Hemmung der Blutgerinnung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 3x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Eliquis 5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Hemmung der Blutgerinnung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Pradaxa 110mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Hemmung der Blutgerinnung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Xarelto 20mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Hemmung der Blutgerinnung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Lixiana 60mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Hemmung der Blutgerinnung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: ACE-Hemmer
  [
    {
      value: 'Kategorie: ACE-Hemmer',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ramipril 5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Blutdruckregulation',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  //Kategorie: β1-Blocker
	[
    {
      value: 'Kategorie: β1-Blocker',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Bisoprolol 2,5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Blutdruckregulation',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Schleifendiuretika
  [
    {
      value: 'Kategorie: Schleifendiuretika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Lasix 40mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Ausscheiden von Wassereinlagerungen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Glukokortikoide
  [
    {
      value: 'Kategorie: Glukokortikoide',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Prednisolon 10mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Entzündungshemmend, Immunsupression',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Benzodiazepine
  [
    {
      value: 'Kategorie: Benzodiazepine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Midazolam 7,5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Beruhigungsmittel, Unterbrechen von Krampfanfällen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - Einzeldosis: 1 Tablette',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Opioide
  [
    {
      value: 'Kategorie: Opioide',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Morphin 10mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'mittelstarke bis starke Schmerzzustände',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Fentanyl 200mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'starke bis sehr starke Schmerzzustände',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Statine
  [
    {
      value: 'Kategorie: Statine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Simvastatin 20mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Cholesterinspiegel Regulation',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 2x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Phenothiazine
  [
    {
      value: 'Kategorie: Phenothiazine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Promethazin 25mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung von Unruhe- und Erregungszuständen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1 Tablette 2 - 4x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antiallergikum
  [
    {
      value: 'Kategorie: Antiallergikum',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Cetrizin 10mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Regulation von Heuschnupfen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Insulin
  [
    {
      value: 'Kategorie: Insulin',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Insulin Fertigspritze',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Regulation Blutzuckerwerte bei Typ-2-Diabetes',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'S.C.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'Dosierung - Insulinpen enthält 300 Einheiten (3ml), Einzeldosis zwischen 1 - 60 Einheiten je nach Blutzuckerwert',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: niedermolekulare Heparine
  [
    {
      value: 'Kategorie: Niedermolekulare Heparine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Clexane 40mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Blutgerinnung, Vorbeugen von Blutgerinseln',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 1x täglich eine Spritze in Bauch oder Oberschenkel',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'I.V. Medikamente',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
	//Kategorie: Schmerzmittel
  [
    {
      value: 'Kategorie: Schmerzmittel',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Ketamin 20/50mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Starke Schmerzen / Narkoseeinleitung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Analgesie 0,25 - 0,5mg/kgKG - Narkose Einleitung 1 - 2mg/kgKG',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Morphin 10mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Bei starken und stärksten Schmerzen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '2-10mg individuell dosieren',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Fentanyl 0,5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Bei starken, stärksten und chronischen Schmerzen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '0,5-1,5µg/kgKG (z.B. 0,1 -0,2 mg)',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antihistaminika
  [
    {
      value: 'Kategorie: Antihistaminika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Fenistil 4mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung allergischer Reaktionen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '1 Ampulle',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Glukokortikoide
  [
    {
      value: 'Kategorie: Glukokortikoide',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Prednisolut 250mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Immunsuppression',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'In kombination mit Fenistil 250mg / Höchstdosis 1g',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Dexamethason 4mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Immunsuppression und Behandlung von Entzündungen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung 4mg täglich, bis die Entzündung abklingt',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antifibrinolytika
  [
    {
      value: 'Kategorie: Antifibrinolytika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Tranexsamsäure 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'bei straken Blutungen, die nicht lokal gestillt werden können',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 1000mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antihypertensiva
  [
    {
      value: 'Kategorie: Antihypertensiva',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Urapidil 50mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Zur Behandlung von Hypertensiven Krisen oder Notfällen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 12,5-50mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antirheumatikum
  [
    {
      value: 'Kategorie: Antirheumatikum',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Aspirin ASS 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung von Herzinfakrt oder instabiler Angina pectoris',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 250mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antikoagulanzien
  [
    {
      value: 'Kategorie: Antikoagulanzien',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Heparin 5000IE',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Antikoagulation bei akutem Herzinfarkt',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Einzeldosis 5000IE',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Opioidantagonist
  [
    {
      value: 'Kategorie: Opioidantagonist',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Naloxon 0,4mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Gegenmittel bei Opioidüberdosen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'Dosierung initial 0,4mg bei gleichbleibendem Zustand nochmal 0,4mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antihypotonikum
  [
    {
      value: 'Kategorie: Antihypotonikum',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Akrinor 200mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Stabilisation von Hypotonien',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Je nach Bludruck initial 50-200mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Benzodiazepine
  [
    {
      value: 'Kategorie: Benzodiazepine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Diazepam 10mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Beruhigungsmittel',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung initial 2,5-5 mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
//Kategorie: Narkotika
  [
    {
      value: 'Kategorie: Narkotika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Propofol 200mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Narkose Einleitung und Narkos Aufrechterhaltung',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V./Perfusor',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'Einleitung: 1-2mg/kgKG - Aufrechterhaltung: 4-12mg/kgKG /h perfusion',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Muskelrelaxans
  [
    {
      value: 'Kategorie: Muskelrelaxans',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Rocuronium 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Narkoseeinleitung nicht-depolarisierendes Muskelrelaxans',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Narkoseeinleitung 1mg/kgKG',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Atracurium 2,5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Narkoseeinleitung nicht-depolarisierendes Muskelrelaxans',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Narkoseeinleitung 0,5-0,6mg/kgKG',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Parasympatholytika
  [
    {
      value: 'Kategorie: Parasympatholytika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Atropin 0,5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Sinusbradykardie',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value:
        'Dosierung initial 1mg, bei nachlassenden Effekt 0.5mg alle 2min - max 3mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Schleifendiuretikum
  [
    {
      value: 'Kategorie: Schleifendiuretikum',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Lasix 40mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Ausschwemmen von Wasser aus Körper / Harntreibend',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 0,5mg/kgKG',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Antiepileptika
  [
    {
      value: 'Kategorie: Antiepileptika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Keppra 500mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung gegen Epilepsie und Krampfanfälle',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - 60mg/kgKG',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Neuroleptika
  [
    {
      value: 'Kategorie: Neuroleptika',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Haloperidol 5mg',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value:
        'Anwendung bei Psychosen, Schizophrenie, Manie und Unruhezuständen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH / MED',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung - initial 2 - 5mg',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Infusionsgabe/Perfusionsgabe',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Kategorie: Schmerzmittel',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Morphin 10mg Perfusor',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'starke bis stärkste Schmerzen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: '10mg bei 1mg/ml über Perfusor 3ml/h',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
	//Kategorie: Katecholamine
  [
    {
      value: 'Kategorie: Katecholamine',
      colSpan: 10,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Präparat',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anwendung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'Applikation',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Zulassung',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Bereich',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Dosierung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: 'Arterenol 1mg Perfusor',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Stabilisation von Hypotonien über längeren Zeitraum',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: 'I.V.',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Doctor',
      colSpan: 1,
      style: usedFullDoctor,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'KH',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'je nach Schweregrad der Hypotonie 1-20µg/min',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 10,
      style: empty,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Legende',
      colSpan: 4,
      style: fixFett,
      readOnly: true,
      width: '95vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'Darreichungsform',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Erklärung',
      colSpan: 3,
      style: usedFull,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'Oral',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Als Tablette geschluckt',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'i.v. (= intravenös)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'In eine Vene',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'i.m. (=intramuskulär)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'In den Muskel (OS, OA)',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 's.c. (= subkutan)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'In das Unterhautfettgewebe',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 's.l. (= sublingual)',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Unter die Zunge',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'rektal',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Ins Rektum z.B. als Zäpchen',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
  [
    {
      value: 'CAVE',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eine Art Warnhinweis',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '75vh',
    },
    {
      value: '',
      colSpan: 6,
      style: empty,
      readOnly: true,
      width: '105vh',
    },
  ],
];
