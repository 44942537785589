import React from 'react';
import { connect } from 'react-redux';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import muiTheme from '../configs/styles/themes';
import { THEME_DEFAULT } from '../configs/styles/themes';
import { darkP } from '../redux/reducer/common';
import { pathOr } from 'ramda';

const ThemeProvider = ({ children, dark }: any) => {
  const createdMuiTheme = createTheme(muiTheme(THEME_DEFAULT, dark));

  return (
    <MuiThemeProvider theme={createdMuiTheme}>
      <EmotionThemeProvider theme={createdMuiTheme}>
        {children}
      </EmotionThemeProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
  };
};

export default connect(mapStateToProps)(ThemeProvider);
