import * as type from '../store/types';
import { authFetch } from '../utils/fetch';

export function createPost(text: string, screenshotId?: string) {
  return {
    type: type.LEOGRAM_POST_CREATE_START,
    text,
    screenshotId,
  };
}

export function fetchPosts() {
  return {
    type: type.LEOGRAM_POSTS_FETCH_START,
  };
}

export function setPostText(text: string) {
  return {
    type: type.LEOGRAM_POST_NEW_TEXT_SET,
    text,
  };
}

export function startLike(postId: string) {
  return {
    type: type.LEOGRAM_POST_LIKE_START,
    postId,
    followActionType: type.LEOGRAM_POSTS_FETCH_START,
  };
}
export function startDislike(postId: string) {
  return {
    type: type.LEOGRAM_POST_DISLIKE_START,
    postId,
    followActionType: type.LEOGRAM_POSTS_FETCH_START,
  };
}

export function fetchMorePosts() {
  return {
    type: type.LEOGRAM_POSTS_MORE_FETCH_START,
  };
}

export function fetchUserTagExistence(userTag: string) {
  return {
    type: type.LEOGRAM_USERTAG_EXISTS_FETCH_START,
    userTag,
  };
}
