import React from 'react';
import { css } from '@emotion/react';
import AddIcon from '@mui/icons-material/AddRounded';
// import { ReactComponent as Logo } from '../logo.svg';

const headerContainer = css`
  display: flex;
  background-color: #d50000;
  background-image: linear-gradient(to right, #d50000, #800000);
  color: white;
  align-items: center;
  padding: 1vh;
`;

const navIconStyle = css`
  height: 3vh !important;
  width: 3vh !important;
  padding: 0.5vh !important;
  cursor: pointer;
`;

const navTitle = css`
  font-size: 2.5vh;
  font-weight: 100;
  letter-spacing: -0.1vh;
  flex-grow: 1;
`;

const addIconStyle = css`
  height: 3vh !important;
  width: 3vh !important;
  padding: 0.5vh !important;
  border-radius: 50%;
  cursor: pointer;

  :hover {
    background-color: #ffffff66;
  }
`;

const Header = ({ goto = (url: string) => {} }) => {
  return (
    <div css={headerContainer}>
      {/* <Logo css={navIconStyle} /> */}
      <div css={navTitle}>Leogram</div>
      <AddIcon css={addIconStyle} onClick={() => goto('/new')} />
    </div>
  );
};

export default Header;
