import { toast } from 'react-toastify';
import { startLogout } from '../../actions';
import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../utils/fetch';
import { notifyError, notifySuccess } from '../utils';

export const onChangePwStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.SETTINGS_PW_CHANGE_START),
    mapPromiseC(({ oldPw, newPw }: any) => {
      return authFetchPost('/profile/changepw', {
        oldPw,
        newPw,
      })
        .then(() => [
          notifySuccess('Passwort erfolgreich geändert'),
          startLogout(),
        ])
        .catch(() => notifyError('Passwort ändern fehlgeschlagen'));
    })
  );
