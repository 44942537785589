import {
  Black,
  White,
  PrimaryColorDark,
  PrimaryColorExtraDark,
  PrimaryColorLight,
  SecondaryColorDark,
  SecondaryColorLight,
  COLORS,
  tailwindGray,
} from './colors';

const defaultTheme = {
  White: White,
  Black: Black,
  tailwind: {
    gray: tailwindGray(false),
  },
};
const darkModeTheme = {
  White: Black,
  Black: White,
  tailwind: {
    gray: tailwindGray(true),
  },
};

export const THEME_DEFAULT = 'Default';
export const THEMES = [THEME_DEFAULT];

// https://coolors.co/fcf4c9-fee3e2-fbcdf2-e8befa-abbfff-bbf3c0
// https://coolors.co/4d4205-620704-4b063e-45075f-001866-0c4611
const createDefaultTheme: any = (darkMode: boolean) => ({
  colors: {
    ...COLORS,
    ...(darkMode ? darkModeTheme : defaultTheme),
  },
  palette: {
    type: darkMode ? 'dark' : 'light',
    mode: darkMode ? 'dark' : 'light',
    primary: {
      main: darkMode ? White : Black,
      mainLight: PrimaryColorLight,
      mainDark: PrimaryColorDark,
      mainExtraDark: PrimaryColorExtraDark,
    },
    secondary: {
      main: darkMode ? White : Black,
      mainLight: SecondaryColorLight,
      mainDark: SecondaryColorDark,
    },
  },
});

const theme = (name = THEME_DEFAULT, darkMode: any) => {
  switch (name) {
    case THEME_DEFAULT:
      return createDefaultTheme(darkMode);
    // case OTHER_THEME:
    //   return createOtherTheme(darkMode);
    default:
      return createDefaultTheme(darkMode);
  }
};

export default theme;
