import { defaultTo, lensPath, path, pipe, set } from 'ramda';
import * as types from '../../../types';

const TRANSACTION_LIMIT_PER_REQUEST = 25;

const bankAccountP = ['apps', 'admin', 'bankAccount'];

export const appsAdminBankAccountDetailP = [...bankAccountP, 'detail'];
export const appsAdminBankAccountDetailL = lensPath(
  appsAdminBankAccountDetailP
);
const setDetail = (state: any, { data }: any) => {
  return set(appsAdminBankAccountDetailL, data, state);
};

export const appsAdminBankAccountTransactionsP = [
  ...bankAccountP,
  'transactions',
];
export const appsAdminBankAccountTransactionsL = lensPath(
  appsAdminBankAccountTransactionsP
);

const setTransactions = (state: any, { data, offset }: any) => {
  const current: any = pipe(
    offset === 0 ? () => undefined : path(appsAdminBankAccountTransactionsP),
    defaultTo({
      data: [],
      isComplete: false,
    })
  )(state);

  const newCurrent = pipe(
    set(lensPath(['data']), [...current.data, ...data]),
    set(lensPath(['isComplete']), data.length < TRANSACTION_LIMIT_PER_REQUEST)
  )(current);

  return set(appsAdminBankAccountTransactionsL, newCurrent, state);
};

export const appsAdminBankAccountReducerTree = {
  [types.ADMIN_BANKACCOUNT_DETAIL_FETCH_SUCCESS]: [setDetail],
  [types.ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_SUCCESS]: [setTransactions],
};
