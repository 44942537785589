import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { startAdminStateBankAccountsFetch } from '../../../actions';
import { appsAdminStateBankAccountsP } from '../../../redux/reducer/apps/admin/statebankaccounts';
import { StateBankAccount } from './types/statebankaccounts';

type StateBankAccountsType = {};
export const StateBankAccounts = ({}: StateBankAccountsType) => {
  const dispatch = useDispatch();
  const accounts = useSelector<any, StateBankAccount[]>(
    pathOr([], appsAdminStateBankAccountsP)
  );

  useEffect(() => {
    dispatch(startAdminStateBankAccountsFetch());
  }, []);

  return (
    <div>
      <h1>Staatskonten</h1>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Ban</TableCell>
            <TableCell>Bank</TableCell>
            <TableCell>Kontostand</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((account) => (
            <TableRow key={account.vban}>
              <TableCell>{account.vban}</TableCell>
              <TableCell>{account.bank}</TableCell>
              <TableCell>${account.money}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
