import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { startChangePw } from '../../../actions';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

type ProfileType = {};
export const Profile = ({}: ProfileType) => {
  const [oldPw, setOldPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [newPwRe, setNewPwRe] = useState('');
  const dispatch = useDispatch();

  const changePw = () => {
    dispatch(startChangePw(oldPw, newPw));
  };

  const isNewPwValid = newPw.length >= 8 && newPw === newPwRe;

  return (
    <div>
      <h1>Passwort ändern</h1>

      <div>
        Das Passwort kann nur geändert werden, wenn man nicht Online ist!
      </div>

      <div>Das Passwort muss mindestens 8 Stellen lang sein</div>

      <TextField
        label="Altes Passwort"
        type="password"
        value={oldPw}
        onChange={(ev) => setOldPw(ev.target.value)}
        css={inputStyle}
      />
      <TextField
        label="Neues Passwort"
        type="password"
        value={newPw}
        onChange={(ev) => setNewPw(ev.target.value)}
        css={inputStyle}
      />
      <TextField
        label="Passwort wiederholen"
        type="password"
        value={newPwRe}
        onChange={(ev) => setNewPwRe(ev.target.value)}
        css={inputStyle}
      />
      <Button
        variant="outlined"
        onClick={() => changePw()}
        disabled={!isNewPwValid}
      >
        Absenden
      </Button>
    </div>
  );
};
