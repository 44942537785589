import * as types from '../../../redux/types';
import {
  pipeC,
  ofType,
  mapPromiseC,
  mapC,
  debounceC,
} from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { notifySuccess, notifyError } from '../../utils';

export const onFetchAdminBankAccount = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_BANKACCOUNT_DETAIL_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/bank/${_id}`)
        .then((data) => {
          return {
            type: types.ADMIN_BANKACCOUNT_DETAIL_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_BANKACCOUNT_DETAIL_FETCH_FAILED,
        }));
    })
  );

export const onFetchAdminBankAccountTransactions = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_START),
    mapPromiseC(({ _id, offset }: any) => {
      return authFetchJson(`/admin/bank/${_id}/transactions?offset=${offset}`)
        .then((data) => {
          return {
            type: types.ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_SUCCESS,
            data,
            offset,
          };
        })
        .catch(() => ({
          type: types.ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_FAILED,
        }));
    })
  );

export const onEditAdminBankAccountBalance = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_BANKACCOUNT_BALANCE_EDIT_START),
    mapPromiseC(({ _id, balance }: any) => {
      return authFetchPost(`/admin/bank/balance/edit`, { bank: _id, balance })
        .then(() => [
          notifySuccess('Erfolgreich geändert'),
          {
            type: types.ADMIN_BANKACCOUNT_DETAIL_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Änderung fehlgeschlagen'));
    })
  );

export const onMakeAdminBankAccountMain = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_BANKACCOUNT_MAKE_MAIN_START),
    mapPromiseC(({ _id, state }: any) => {
      return authFetchPost(`/admin/bank/account/main`, {
        bankAccountId: _id,
        state,
      })
        .then(() => [
          notifySuccess('Erfolgreich geändert'),
          {
            type: types.ADMIN_BANKACCOUNT_DETAIL_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Änderung fehlgeschlagen'));
    })
  );
