import { css } from '@emotion/react';
import { TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrowserCars } from '../../../actions';
import { appsBrowserCarsP } from '../../../redux/reducer/apps/browser';
import { recommendedVehicleFilters } from '../../../resources/vehicles/recomended-filters';

const getRandomFilter = () => {
  const randomIndex = Math.floor(
    Math.random() * recommendedVehicleFilters.length
  );
  return recommendedVehicleFilters[randomIndex];
};

const bgStyle = css`
  background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
  padding: 1vh 2vh;
  min-height: 100%;
`;

const headlineStyle = css`
  font-size: 3vh;
  font-weight: bold;
  color: white;
  padding: 1vh 1vh;
`;

const searchContainerStyle = css`
  padding: 1vh 1vh;

  fieldset {
    border-color: rgba(255, 255, 255, 0.23);
  }
  fieldset:hover {
    border-color: #fff;
  }
  label,
  input {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const cardStyle = css`
  display: inline-block;
  width: 31vh;
  border: 1px solid #fff;
  border-radius: 0.5vh;
  overflow: hidden;
  margin: 1vh;
  position: relative;
`;

const descriptionStyle = css`
  position: absolute;
  bottom: 0;
  height: 4vh;
  font-size: 1.5vh;
  line-height: 2vh;
  padding: 1vh;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
`;

const filterListStyle = css`
  width: 100%;
  padding: 1vh;

  div {
    font-size: 1vh;
    padding: 0.2vh 1.3vh;
    border-radius: 1vh;
    background: rgba(255, 255, 255, 1);
    color: #000;
    display: inline-block;
    margin-right: 0.5vh;
    cursor: pointer;
  }
`;

export const Cars = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>(getRandomFilter());
  const cars = useSelector<any, { hash: string; label: string }[]>(
    pathOr([], appsBrowserCarsP)
  );

  useEffect(() => {
    dispatch(fetchBrowserCars());
  }, []);

  const filteredVehicles = cars.filter(
    (car) =>
      car.label.toLowerCase().includes(search.toLowerCase()) ||
      car.hash.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div css={bgStyle}>
      <div css={headlineStyle}>
        Finde dein passendes Fahrzeug hier und jetzt
      </div>

      <div css={searchContainerStyle}>
        <TextField
          label="Suchen"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />
      </div>

      <div css={filterListStyle}>
        {recommendedVehicleFilters.map((entry) => (
          <div key={entry} onClick={() => setSearch(entry)}>
            {entry}
          </div>
        ))}
      </div>

      <div>
        {filteredVehicles.map((car) => (
          <div key={car.hash} css={cardStyle}>
            <div css={descriptionStyle}>{car.label}</div>
            <img src={`/img/vehicles/${car.hash}.jpg`} />
          </div>
        ))}
      </div>
    </div>
  );
};
