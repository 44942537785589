import { lensPath, set } from 'ramda';
import * as types from '../../types';

export const testsuitesCatalogueP = ['apps', 'testsuites', 'catalogue'];
export const testsuitesCatalogueL = lensPath(testsuitesCatalogueP);
const setTestsuitesCatalogue = (state: any, { data }: any) => {
  return set(testsuitesCatalogueL, data, state);
};

export const testsuitesAssignedP = ['apps', 'testsuites', 'assigned'];
export const testsuitesAssignedL = lensPath(testsuitesAssignedP);
const setAssignedTestsuites = (state: any, { data }: any) => {
  return set(testsuitesAssignedL, data, state);
};

export const testsuitesCurrentP = ['apps', 'testsuites', 'current'];
export const testsuitesCurrentL = lensPath(testsuitesCurrentP);
const setCurrentTestsuites = (state: any, { data }: any) => {
  return set(testsuitesCurrentL, data, state);
};

export const testsuitesResultsP = ['apps', 'testsuites', 'results'];
export const testsuitesResultsL = lensPath(testsuitesResultsP);
const setTestsuiteResults = (state: any, { data }: any) => {
  return set(testsuitesResultsL, data, state);
};

const setTestsuiteActiveResult = (state: any, { data }: any) => {
  return set(testsuitesCurrentL, data, state);
};

export const appsTestsuitesReducerTree = {
  [types.TESTSUITES_CATALOGUE_FETCH_SUCCESS]: [setTestsuitesCatalogue],
  [types.TESTSUITES_LOAD_ASSIGNED_SUCCESS]: [setAssignedTestsuites],
  [types.TESTSUITES_LOAD_SUITE_SUCCESS]: [setCurrentTestsuites],
  [types.TESTSUITES_RESULTS_FETCH_SUCCESS]: [setTestsuiteResults],
  [types.TESTSUITES_RESULT_FETCH_SUCCESS]: [setTestsuiteActiveResult],
};
