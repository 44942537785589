import { toast } from 'react-toastify';
import * as types from '../redux/types';
import { pipeC, ofType, mapPromiseC, debounceC } from '../utils/callbag';
import { authFetchPost } from '../utils/fetch';
// @ts-ignore
import nightwind from 'nightwind/helper';

export const onChangeBrightness = (action$: any) =>
  pipeC(
    action$,
    ofType(types.SET_BRIGHTNESS),
    debounceC(1000),
    mapPromiseC(({ brightness }: any) => {
      // save brightness to character
      return authFetchPost('/settings', {
        updates: [{ key: 'liosBrightness', value: brightness }],
      })
        .then((res) => res.json())
        .then((settings) => ({
          type: types.SETTINGS_UPDATE,
          settings,
        }));
    })
  );

export const onChangeDarkMode = (action$: any) =>
  pipeC(
    action$,
    ofType(types.TOGGLE_DARK),
    mapPromiseC(({ dark }: any) => {
      nightwind.enable(dark);

      // save darkMode to character
      return authFetchPost('/settings', {
        updates: [{ key: 'liosDarkMode', value: dark }],
      })
        .then((res) => res.json())
        .then((settings) => ({
          type: types.SETTINGS_UPDATE,
          settings,
        }));
    })
  );
