import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC } from '../utils/callbag';
import { authFetch } from '../utils/fetch';
import { pathOr } from 'ramda';
import { profilePageContentP } from '../store/reducer/profilepage';

export const onProfilePageFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_PROFILE_PAGE_FETCH_START),
    mapPromiseC(({ userTag }: any) => {
      return authFetch(`/leogram/profilesite/${userTag}`)
        .then((res) => {
          return res.json().then((data) => ({
            type: types.LEOGRAM_PROFILE_PAGE_FETCH_SUCCESS,
            data,
          }));
        })
        .catch((res) => {
          return {
            type: types.LEOGRAM_PROFILE_PAGE_FETCH_FAILED,
          };
        });
    })
  );

export const onProfileFollowStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_PROFILE_FOLLOW_START),
    mapPromiseC(({ userTag }: any) => {
      return authFetch(`/leogram/follow/${userTag}`)
        .then(() => {
          return [
            {
              type: types.LEOGRAM_PROFILE_FOLLOW_SUCCESS,
            },
            {
              type: types.LEOGRAM_PROFILE_PAGE_FETCH_START,
              userTag,
            },
          ];
        })
        .catch((err) => {
          return {
            type: types.LEOGRAM_PROFILE_FOLLOW_FAILED,
            err,
          };
        });
    })
  );
export const onProfileUnfollowStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_PROFILE_UNFOLLOW_START),
    mapPromiseC(({ userTag }: any) => {
      return authFetch(`/leogram/unfollow/${userTag}`)
        .then(() => {
          return [
            {
              type: types.LEOGRAM_PROFILE_UNFOLLOW_SUCCESS,
            },
            {
              type: types.LEOGRAM_PROFILE_PAGE_FETCH_START,
              userTag,
            },
          ];
        })
        .catch((err) => {
          return {
            type: types.LEOGRAM_PROFILE_UNFOLLOW_FAILED,
            err,
          };
        });
    })
  );

export const onProfilePageMoreFetchStart = (
  action$: any,
  getState: () => any
) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_PROFILE_PAGE_MORE_FETCH_START),
    mapPromiseC(({ userTag }: any) => {
      const currentPosts: any[] = pathOr(
        [],
        [...profilePageContentP, 'posts'],
        getState()
      );
      return authFetch(
        `/leogram/profilesite/${userTag}?skip=${currentPosts.length}`
      )
        .then((res) => {
          return res.json().then((data) => ({
            type: types.LEOGRAM_PROFILE_PAGE_MORE_FETCH_SUCCESS,
            data,
          }));
        })
        .catch((res) => {
          return {
            type: types.LEOGRAM_PROFILE_PAGE_MORE_FETCH_FAILED,
          };
        });
    })
  );

export const onProfilePostLike = (action$: any, getState: () => any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_PROFILE_POST_LIKE_START),
    mapPromiseC(({ postId }: any) => {
      return authFetch('/leogram/like/' + postId).then(() => ({
        type: types.LEOGRAM_PROFILE_POST_LIKE_SUCCESS,
        postId,
      }));
    })
  );
