const header = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const azStyle = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
  'justify-content': 'center',
};
const personStyle = azStyle;
const taskStyle = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};

const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};

export default [
  [
    {
      value: 'Aktuelle TODOs in den Akten',
      colSpan: 19,
      style: fixFett,
      readOnly: true,
      width: '190vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 19,
      style: empty,
      readOnly: true,
      width: '190vh',
    },
  ],
  [
    { value: 'AZ', readOnly: true, width: '10vh', style: header },
    { value: 'Person', readOnly: true, width: '20vh', style: header },
    { value: 'Aufgabe', readOnly: true, width: '80vh', style: header },
  ],
];

export const lspdFilesTodoListLineTemplate = [
  { value: '', readOnly: false, style: azStyle },
  { value: '', readOnly: false, style: personStyle },
  { value: '', readOnly: false, style: taskStyle },
];
