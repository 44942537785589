import { Button, TextField, TextareaAutosize } from '@mui/material';
import { append, lensPath, pipe, remove, set } from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/ClearOutlined';
import PersonIcon from '@mui/icons-material/PersonOutline';
import SendIcon from '@mui/icons-material/SendOutlined';
import AddIcon from '@mui/icons-material/AddOutlined';
import { startMailSend } from '../../../actions';
import { css } from '@emotion/react';
import Headline from '../../Headline';
import { LexicalEditor } from '../../../leogram/shared/LexicalEditor';

const headStyle = css`
  padding: 0 1vh;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const subHeadlineStyle = css`
  font-size: 1.5vh;
  margin-top: 1vh;
`;

const bodyStyle = css`
  position: relative;
  margin: 1vh;
  margin-top: 2vh;
  border-bottom: 1px solid #dedede;
`;

const inputStyle = css`
  width: 100%;
  max-width: 30vh;
`;

const addReceiverButton = css`
  margin-top: 1vh !important;
`;

const sendMailButton = css`
  margin-left: 1vh !important;
`;

type SendType = {
  sendTo: string[];
  setSendTo: (sendTo: string[]) => void;
  sendSubject: string;
  setSendSubject: (sendTo: string) => void;
  sendBody: any;
  setSendBody: (body: any) => void;
  setMenu: (menu: string) => void;
};

export const Send = ({
  sendTo,
  setSendTo,
  sendSubject,
  setSendSubject,
  sendBody,
  setSendBody,
  setMenu,
}: SendType) => {
  const dispatch = useDispatch();

  const updateReceiver = (index: number) => (ev: any) => {
    let receiverMail = ev.target.value;
    const matched = receiverMail.match('([A-Z])([a-z]+)_([A-Z])([a-z]+)@');
    if (!!matched) {
      receiverMail = matched[0] + 'redlion-rp.de';
    }
    pipe(set(lensPath([index]), receiverMail), setSendTo)(sendTo);
  };

  const addReceiverField = () => {
    pipe<[string[]], string[], void>(append(''), setSendTo)(sendTo);
  };
  const removeReceiverField = (index: number) => () => {
    pipe<[string[]], string[], void>(remove(index, 1), setSendTo)(sendTo);
  };

  const send = () => {
    dispatch(startMailSend(sendTo, sendSubject, sendBody));
    setSendTo(['']);
    setSendSubject('');
    setSendBody(undefined);
    setMenu('inbox');
  };

  return (
    <div>
      <div css={headStyle}>
        <Headline>Mail senden</Headline>
        <h2 css={subHeadlineStyle}>Betreff</h2>
        <TextField
          css={inputStyle}
          type="text"
          placeholder="Betreff"
          value={sendSubject}
          onChange={(ev) => setSendSubject(ev.target.value)}
        />
        <h2 css={subHeadlineStyle}>Empfänger</h2>
        {sendTo.map((value, index) => (
          <TextField
            key={index}
            type="text"
            value={value}
            onChange={updateReceiver(index)}
            css={inputStyle}
            placeholder="Empfänger@redlion-rp.de"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
              endAdornment: sendTo.length > 1 && (
                <InputAdornment
                  position="end"
                  css={{ cursor: 'pointer' }}
                  onClick={removeReceiverField(index)}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        ))}
        <Button
          css={[inputStyle, addReceiverButton]}
          variant="outlined"
          onClick={addReceiverField}
          startIcon={<AddIcon />}
        >
          Weiteren Empfänger hinzufügen
        </Button>
      </div>

      <div css={bodyStyle}>
        {/* <Editor
          content={sendBody}
          setContent={setSendBody}
          size={{ width: '100%', height: 'unset' }}
        /> */}
        <LexicalEditor content={sendBody} setContent={setSendBody} />
      </div>

      <div>
        <Button
          css={sendMailButton}
          variant="outlined"
          onClick={send}
          startIcon={<SendIcon />}
        >
          Mail absenden
        </Button>
      </div>
    </div>
  );
};
