import * as type from '../redux/types';

export const fetchFolders = (group: string, page: number, search: string) => ({
  type: type.FOLDERS_FETCH_START,
  group,
  page,
  search,
});

export const saveFolder = (folder: any) => ({
  type: type.FOLDER_SAVE_START,
  folder,
});

export const fetchFolder = (group: string, folderId: string) => ({
  type: type.FOLDER_FETCH_START,
  group,
  folderId,
});

export const setFolderSearch = (group: string, search: string) => ({
  type: type.FOLDER_SEARCH_SET,
  group,
  search,
});

export const createNewFolder = (group: string, _id: string) => ({
  type: type.FOLDER_CREATE_START,
  group,
  _id,
});

export const selectFolderInGroup = (group: string, folderId: string) => ({
  type: type.FOLDER_SELECTION_SET,
  group,
  folderId,
});

export const addDocumentToFolder = (
  group: string,
  folderId: string,
  documentId: string
) => ({
  type: type.FOLDER_ADD_DOCUMENT_START,
  group,
  folderId,
  documentId,
});

export const fetchFolderDocument = (documentId: string) => ({
  type: type.FOLDER_DOCUMENT_FETCH_START,
  documentId,
});
