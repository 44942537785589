import { set, lensPath, pathOr, concat, findIndex, pathEq, pipe } from 'ramda';

import * as types from '../types';

export const profilePageContentP = ['leogram', 'profilePageContent'];
export const profilePageContentL = lensPath(profilePageContentP);
export const isProfilePageLoadingP = ['leogram', 'isProfilePageLoading'];
export const isProfilePageLoadingL = lensPath(isProfilePageLoadingP);

const profileFetchSuccess = (state: any, { data }: any) => {
  return set(profilePageContentL, data, state);
};

const profileFetchMoreSuccess = (state: any, { data }: any) => {
  const currentPosts: any[] = pathOr(
    [],
    [...profilePageContentP, 'posts'],
    state
  );
  data.posts = currentPosts.concat(data.posts);
  return set(profilePageContentL, data, state);
};

const setProfileLoading = (enabled: boolean) => (state: any) => {
  return set(isProfilePageLoadingL, enabled, state);
};

const toggleProfilPostLike = (state: any, { postId }: any) => {
  const posts: any[] = pathOr([], [...profilePageContentP, 'posts'], state);
  const index = findIndex(pathEq(['_id'], postId), posts);
  if (index === -1) {
    return state;
  }

  const isLiked = posts[index]?.isLiked;
  const likeAmount = posts[index]?.amountsLikes + (isLiked ? -1 : 1);
  return pipe(
    set(
      lensPath([...profilePageContentP, 'posts', index, 'isLiked']),
      !isLiked
    ),
    set(
      lensPath([...profilePageContentP, 'posts', index, 'amountsLikes']),
      likeAmount
    )
  )(state);
};

export const profilePageReducerTree = {
  [types.LEOGRAM_PROFILE_PAGE_FETCH_SUCCESS]: [profileFetchSuccess],
  [types.LEOGRAM_PROFILE_PAGE_MORE_FETCH_START]: [setProfileLoading(true)],
  [types.LEOGRAM_PROFILE_PAGE_MORE_FETCH_SUCCESS]: [
    profileFetchMoreSuccess,
    setProfileLoading(false),
  ],
  [types.LEOGRAM_PROFILE_PAGE_MORE_FETCH_FAILED]: [setProfileLoading(false)],
  [types.LEOGRAM_PROFILE_POST_LIKE_SUCCESS]: [toggleProfilPostLike],
};
