import * as type from '../redux/types';

export function fetchMyVehicles() {
  return {
    type: type.APPS_MYVEHICLE_FETCH_START,
  };
}

export function setVehicleWaypoint(vehicleId: string) {
  return {
    type: type.APPS_MYVEHICLE_WAYPOINT_SET,
    id: vehicleId,
  };
}
