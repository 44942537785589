import * as type from '../redux/types';

export function startLogin(username: string, password: string) {
  return {
    type: type.LOGIN_START,
    username,
    password,
  };
}

export function resetLoginFailed() {
  return {
    type: type.LOGIN_FAILED_RESET,
  };
}

export function startLogout() {
  return {
    type: type.LOGOUT,
  };
}
