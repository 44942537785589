module.exports = [
  {
    hash: 'documentA4',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
  },
  {
    hash: 'businessCard',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
  },
  {
    hash: 'documentA4DOJ',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    imgSrc: '../images/documents/a4DOJ.png',
    imgSrcV2: '../images/documentsV2/a4DOJ.png',
    right: 'doj.document.paper',
  },
  {
    hash: 'businessCardDOJ',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    imgSrc: '../images/documents/businessCardDOJ.png',
  },
  {
    hash: 'documentA4DOC',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    imgSrc: '../images/documents/a4DOC.png',
    imgSrcV2: '../images/documentsV2/a4DOC.png',
    right: 'doc.document.paper',
  },
  {
    hash: 'businessCardDOC',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    imgSrc: '../images/documents/businessCardDOC.png',
  },
  {
    hash: 'documentA4LSMD',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    imgSrc: '../images/documents/a4LSMD.png',
    imgSrcV2: '../images/documentsV2/a4LSMD.png',
    right: 'lsmd.document.paper',
  },
  {
    hash: 'businessCardLSMD',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    imgSrc: '../images/documents/businessCardLSMD.png',
  },
  {
    hash: 'documentA4LSPD',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    imgSrc: '../images/documents/a4LSPD.png',
    imgSrcV2: '../images/documentsV2/a4LSPD.png',
    right: 'lspd.document.paper',
  },
  {
    hash: 'businessCardLSPD',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    imgSrc: '../images/documents/businessCardLSPD.png',
  },
  {
    hash: 'documentA4DMV',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    imgSrc: '../images/documents/a4DMV.png',
    imgSrcV2: '../images/documentsV2/a4DMV.png',
    right: 'dmv.document.paper',
  },
  {
    hash: 'businessCardDMV',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    imgSrc: '../images/documents/businessCardDMV.png',
  },
  {
    hash: 'documentA4CottonCandy',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background: radial-gradient(circle at top, #FFBDDD, #FFDDBD99 66%, #BDFFDD33 100%), radial-gradient(circle at left, #BDDDFF 25%, #DDFFBD 60%, #DDBDFF 80%);',
  },
  {
    hash: 'businessCardCottonCandy',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background: radial-gradient(circle at top, #FFBDDD, #FFDDBD99 66%, #BDFFDD33 100%), radial-gradient(circle at left, #BDDDFF 25%, #DDFFBD 60%, #DDBDFF 80%);',
  },
  {
    hash: 'documentA4Rainbow',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(104deg, #ffc7c2 1.1%, #ffe6b3 22.7%, #f3f4b8 37.2%, #b8ffee 55.4%, #b3eeff 72.4%, #eabdff 101.9%);',
  },
  {
    hash: 'businessCardRainbow',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(104deg, #ffc7c2 1.1%, #ffe6b3 22.7%, #f3f4b8 37.2%, #b8ffee 55.4%, #b3eeff 72.4%, #eabdff 101.9%);',
  },
  {
    hash: 'documentA4StripeBrown',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(90deg, #d0c7c0 25%, #ffffff 25%);',
  },
  {
    hash: 'businessCardStripeBrown',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(90deg, #d0c7c0 25%, #ffffff 25%);',
  },
  {
    hash: 'documentA4Love',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    imgSrc: '../images/documents/a4LOVE.png',
    css: 'background-size: 100%; background-image: url(../images/documents/a4LOVE.png), radial-gradient(circle, rgb(254, 215, 229) 0%, rgb(252, 159, 193) 100%);',
  },
  {
    hash: 'businessCardLove',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-size: 100%; background-image: url(../images/documents/a4LOVE.png), radial-gradient(circle, rgb(254, 215, 229) 0%, rgb(252, 159, 193) 100%);',
  },
  {
    hash: 'documentA4Skull',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    imgSrc: '../images/documents/a4SKULL.png',
    imgSrcV2: '../images/documentsV2/a4SKULL.png',
  },
  {
    hash: 'businessCardSkull',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    imgSrc: '../images/documents/businessCardSKULL.png',
  },
  {
    hash: 'documentA4Yellow',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(45deg, rgb(255 255 150) 1%, rgb(255 255 200) 100%);',
  },
  {
    hash: 'businessCardYellow',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(45deg, rgb(255 255 150) 1%, rgb(255 255 200) 100%);',
  },
  {
    hash: 'documentA4Magenta',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(45deg, rgb(255 150 255) 1%, rgb(255 200 255) 100%);',
  },
  {
    hash: 'businessCardMagenta',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(45deg, rgb(255 150 255) 1%, rgb(255 200 255) 100%);',
  },
  {
    hash: 'documentA4Teal',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(45deg, rgb(150 255 255) 1%, rgb(200 255 255) 100%);',
  },
  {
    hash: 'businessCardTeal',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(45deg, rgb(150 255 255) 1%, rgb(200 255 255) 100%);',
  },
  {
    hash: 'documentA4Blue',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(45deg, rgb(150 150 255) 1%, rgb(200 200 255) 100%);',
  },
  {
    hash: 'businessCardBlue',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(45deg, rgb(150 150 255) 1%, rgb(200 200 255) 100%);',
  },
  {
    hash: 'documentA4Red',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(45deg, rgb(255 150 150) 1%, rgb(255 200 200) 100%);',
  },
  {
    hash: 'businessCardRed',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(45deg, rgb(255 150 150) 1%, rgb(255 200 200) 100%);',
  },
  {
    hash: 'documentA4Green',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-image: linear-gradient(45deg, rgb(200 255 150) 1%, rgb(200 255 200) 100%);',
  },
  {
    hash: 'businessCardGreen',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-image: linear-gradient(45deg, rgb(200 255 150) 1%, rgb(200 255 200) 100%);',
  },
  {
    hash: 'documentA4Black',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'documentA4',
    css: 'background-color: #000000;',
  },
  {
    hash: 'businessCardBlack',
    illegal: false,
    weight: 0.01,
    transportGroups: ['general', 'document'],
    cbType: 'viewDocument',
    type: 'businessCard',
    css: 'background-color: #000000;',
  },
  {
    hash: 'packagedDocumentA4DOJ',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardDOJ',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4DOC',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardDOC',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4LSMD',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardLSMD',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4LSPD',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardLSPD',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4DMV',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardDMV',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4CottonCandy',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardCottonCandy',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Rainbow',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardRainbow',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4StripeBrown',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardStripeBrown',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Love',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardLove',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Skull',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardSkull',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Yellow',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardYellow',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Magenta',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardMagenta',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Teal',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardTeal',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Blue',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardBlue',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Red',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardRed',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Green',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardGreen',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packaged_businessCard',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentA4Black',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedBusinessCardBlack',
    illegal: false,
    weight: 0.05,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedEnvelope',
    illegal: false,
    weight: 1,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentFolderRed',
    illegal: false,
    weight: 1,
    transportGroups: ['general'],
  },
  {
    hash: 'packagedDocumentFolderPurple',
    illegal: false,
    weight: 1,
    transportGroups: ['general'],
  },
];
