import { set, lensPath } from 'ramda';

import * as types from '../types';

export const darkP = ['settings', 'darkMode'];
export const darkL = lensPath(darkP);
const setDark = (state: any, { dark }: any) => {
  return set(darkL, dark, state);
};

const volumeP = ['volume'];
const volumeL = lensPath(volumeP);
const setVolume = (state: any, { volume }: any) => {
  return set(volumeL, volume, state);
};

export const brightnessP = ['settings', 'brightness'];
export const brightnessL = lensPath(brightnessP);
const setBrightness = (state: any, { brightness }: any) => {
  return set(brightnessL, brightness, state);
};

const fullscreenP = ['fullscreen'];
const fullscreenL = lensPath(fullscreenP);
const setFullscreen = (state: any, { fullscreen }: any) => {
  return set(fullscreenL, fullscreen, state);
};

const wifiP = ['wifi'];
const wifiL = lensPath(wifiP);
const setWifi = (state: any, { wifi }: any) => {
  return set(wifiL, wifi, state);
};

const bluetoothP = ['bluetooth'];
const bluetoothL = lensPath(bluetoothP);
const setBluetooth = (state: any, { bluetooth }: any) => {
  return set(bluetoothL, bluetooth, state);
};

const airdropP = ['airdrop'];
const airdropL = lensPath(airdropP);
const setAirdrop = (state: any, { airdrop }: any) => {
  return set(airdropL, airdrop, state);
};

export const visibleP = ['isVisible'];
export const visibleL = lensPath(visibleP);
const updateVisibility = (state: any, { isVisible }: any) => {
  return set(visibleL, isVisible, state);
};

export const ingameP = ['isIngame'];
export const ingameL = lensPath(ingameP);
const updateIngame = (state: any, { isIngame }: any) => {
  return set(ingameL, isIngame, state);
};

export const commonReducerTree = {
  [types.TOGGLE_DARK]: [setDark],
  [types.SET_VOLUME]: [setVolume],
  [types.SET_BRIGHTNESS]: [setBrightness],
  [types.TOGGLE_FULLSCREEN]: [setFullscreen],
  [types.TOGGLE_WIFI]: [setWifi],
  [types.TOGGLE_BLUETOOTH]: [setBluetooth],
  [types.TOGGLE_AIRDROP]: [setAirdrop],
  [types.VISIBLITY_SET]: [updateVisibility],
  [types.IS_INGAME]: [updateIngame],
};
