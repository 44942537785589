const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'text-align': 'left',
  color: '#fff',
  border: '0 !important',
};
const usedFix = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const select = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const usedCenter = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
//Styles ab const einfügen für sep. - kann auch ohne Eigenschaft wie background genutzt werden
export default [
  [
    {
      value: 'Fuhrpark Blatt',
      colSpan: 20,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 20,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Modell',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Kennzeichen',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Kilometerstand',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Tank',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Defekt',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Vollständig',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Anmerkungen',
      colSpan: 5,
      style: fixFett,
      readOnly: true,
      width: '50vh',
    },
    {
      value: 'Ausrüstung',
      colSpan: 5,
      style: fixFett,
      readOnly: true,
      width: '50vh',
    },
  ],
];
export const dmvVehicleControlLineTemplate = [
  {
    value: '',
    colSpan: 2,
    style: usedCenter,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedCenter,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedCenter,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedCenter,
    width: '20vh',
  },
  {
    value: false,
    type: 'checkbox',
    colSpan: 1,
    style: usedFix,
    readOnly: true,
    width: '10vh',
  },
  {
    value: false,
    type: 'checkbox',
    colSpan: 1,
    style: usedFix,
    readOnly: true,
    width: '10vh',
  },
  {
    value: '',
    colSpan: 5,
    style: usedCenter,
    width: '50vh',
  },
  {
    value: '',
    colSpan: 5,
    style: usedCenter,
    width: '50vh',
  },
];
