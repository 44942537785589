import { find, propEq } from 'ramda';
import React, { useState, useEffect } from 'react';
import { FaPaw } from 'react-icons/fa';
import { Sidebar } from '../../sidebars/Sidebar';
import { Account } from './Account';
import { Banks, BankData } from './Banks';
import { Transfer } from './Transfer';
import { AccessType, BankAccount, TransactionsType } from './types';

const getTitle = (account: BankAccount) => {
  const label = account.teamLabel || account.ownerFactory?.label;
  return label ? `${label} - ${account.vban}` : account.vban;
};

const defaultMenu: any[] = [
  {
    id: 'banks',
    title: 'Banken',
    icon: <FaPaw />,
  },
  {
    id: 'transfer',
    title: 'Überweisung',
    icon: <FaPaw />,
  },
];

type FinanceTypes = {
  fetchBanks: () => void;
  banks: BankData[];
  fetchAccounts: () => void;
  accounts: BankAccount[];
  fetchTransactions: (vban: string, offset: number) => void;
  transactions: TransactionsType;
  changePin: (
    vban: string,
    currentPin: string,
    newPin: string,
    newPinRe: string
  ) => void;
  makeMain: (vban: string, pin: string) => void;
  deleteAccount: (vban: string, pin: string) => void;
  transfer: (
    sourceVban: string,
    destinationVban: string,
    amount: number,
    reference: string
  ) => void;
  fetchAccess: (vban: string) => void;
  addAccess: (vban: string, playerName: string) => void;
  access: AccessType;
  rmAccess: (vban: string, _id: string) => void;
  editAccess: (
    vban: string,
    _id: string,
    accessRightType: string,
    newValue: boolean
  ) => void;
};

export const Finance = ({
  fetchBanks,
  banks,
  fetchAccounts,
  accounts,
  fetchTransactions,
  transactions,
  changePin,
  makeMain,
  deleteAccount,
  transfer,
  fetchAccess,
  addAccess,
  access,
  rmAccess,
  editAccess,
}: FinanceTypes) => {
  const [selectedMenu, setSelectedMenu] = useState(defaultMenu[0].id);
  const [transferFromVban, setTransferFromVban] = useState('');

  const menu = [
    ...defaultMenu,
    ...accounts.map((account) => ({
      id: account.vban,
      title: getTitle(account),
      icon: <FaPaw />,
      isAccount: true,
    })),
  ];

  useEffect(() => {
    fetchAccounts();
  }, []);

  const activeMenuEntry: any = find(propEq('id', selectedMenu), menu);

  const startTransfer = (vban: string) => {
    setTransferFromVban(vban);
    setSelectedMenu('transfer');
  };

  const transferWithReroute = (
    sourceVban: string,
    destinationVban: string,
    amount: number,
    reference: string
  ) => {
    transfer(sourceVban, destinationVban, amount, reference);
    setSelectedMenu(sourceVban);
  };

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar
          items={menu}
          selected={selectedMenu}
          select={setSelectedMenu}
          refresh={() => fetchAccounts()}
        />
      </div>
      <div className="flex-grow bg-gray-50 text-gray-700 overflow-auto py-6 pl-6">
        {selectedMenu === 'banks' && (
          <Banks fetchBanks={fetchBanks} banks={banks} />
        )}
        {selectedMenu === 'transfer' && (
          <Transfer
            transferFromVban={transferFromVban}
            setTransferFromVban={setTransferFromVban}
            accounts={accounts}
            transfer={transferWithReroute}
          />
        )}
        {activeMenuEntry && activeMenuEntry.isAccount && (
          <Account
            account={
              find(propEq('vban', selectedMenu), accounts) || accounts[0]
            }
            transactions={transactions[selectedMenu]}
            fetchTransactions={fetchTransactions}
            changePin={changePin}
            makeMain={makeMain}
            deleteAccount={deleteAccount}
            startTransfer={startTransfer}
            fetchAccess={fetchAccess}
            addAccess={addAccess}
            access={access[selectedMenu]}
            rmAccess={rmAccess}
            editAccess={editAccess}
          />
        )}
      </div>
    </div>
  );
};
