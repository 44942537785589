export const LSPD_MAP_VEHICLES_FETCH_START = 'LSPD_MAP_VEHICLES_FETCH_START';
export const LSPD_MAP_VEHICLES_FETCH_SUCCESS =
  'LSPD_MAP_VEHICLES_FETCH_SUCCESS';

export const LSPD_MAP_CALLS_FETCH_START = 'LSPD_MAP_CALLS_FETCH_START';
export const LSPD_MAP_CALLS_FETCH_SUCCESS = 'LSPD_MAP_CALLS_FETCH_SUCCESS';

export const LSPD_MAP_ANKLEMONITOR_FETCH_START =
  'LSPD_MAP_ANKLEMONITOR_FETCH_START';
export const LSPD_MAP_ANKLEMONITOR_FETCH_SUCCESS =
  'LSPD_MAP_ANKLEMONITOR_FETCH_SUCCESS';

export const LSPD_PERSON_SEARCH_SET = 'LSPD_PERSON_SEARCH_SET';

export const LSPD_PERSON_SEARCH_FETCH_START = 'LSPD_PERSON_SEARCH_FETCH_START';
export const LSPD_PERSON_SEARCH_FETCH_SUCCESS =
  'LSPD_PERSON_SEARCH_FETCH_SUCCESS';

export const LSPD_PERSON_DETAILS_FETCH_START =
  'LSPD_PERSON_DETAILS_FETCH_START';
export const LSPD_PERSON_DETAILS_FETCH_SUCCESS =
  'LSPD_PERSON_DETAILS_FETCH_SUCCESS';

export const LSPD_PERSON_NOTES_SAVE_FETCH_START =
  'LSPD_PERSON_NOTES_SAVE_FETCH_START';

export const LSPD_PERSON_TICKET_DELETE_FETCH_START =
  'LSPD_PERSON_TICKET_DELETE_FETCH_START';

export const LSPD_PERSON_WANTED_SET_FETCH_START =
  'LSPD_PERSON_WANTED_SET_FETCH_START';

export const LSPD_VEHICLE_SEARCH_SET = 'LSPD_VEHCILE_SEARCH_SET';
export const LSPD_VEHICLE_FETCH_START = 'LSPD_VEHICLE_FETCH_START';
export const LSPD_VEHICLE_FETCH_SUCCESS = 'LSPD_VEHICLE_FETCH_SUCCESS';

export const LSPD_WEAPON_SEARCH_SET = 'LSPD_WEAPON_SEARCH_SET';
export const LSPD_WEAPON_FETCH_START = 'LSPD_WEAPON_FETCH_START';
export const LSPD_WEAPON_FETCH_SUCCESS = 'LSPD_WEAPON_FETCH_SUCCESS';
export const LSPD_WEAPON_TAG_CHANGE_START = 'LSPD_WEAPON_TAG_CHANGE_START';

export const LSPD_WANTED_FETCH_START = 'LSPD_WANTED_FETCH_START';
export const LSPD_WANTED_FETCH_SUCCESS = 'LSPD_WANTED_FETCH_SUCCESS';

export const LSPD_LOGBOOK_FETCH_START = 'LSPD_LOGBOOK_FETCH_START';
export const LSPD_LOGBOOK_FETCH_SUCCESS = 'LSPD_LOGBOOK_FETCH_SUCCESS';

export const LSPD_OLDFILES_SEARCH_SET = 'LSPD_OLDFILES_SEARCH_SET';

export const LSPD_OLDFILES_FETCH_START = 'LSPD_OLDFILES_FETCH_START';
export const LSPD_OLDFILES_FETCH_SUCCESS = 'LSPD_OLDFILES_FETCH_SUCCESS';

export const LSPD_OLDFILE_FETCH_START = 'LSPD_OLDFILE_FETCH_START';
export const LSPD_OLDFILE_FETCH_SUCCESS = 'LSPD_OLDFILE_FETCH_SUCCESS';
