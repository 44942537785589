import React from 'react';
import { css } from '@emotion/react';
import { hoverBgColor } from '../colors';

const emojis: string[] = [
  '😀',
  '😁',
  '😂',
  '😃',
  '😉',
  '😋',
  '😎',
  '😍',
  '😗',
  '🤗',
  '🤔',
  '😣',
  '😫',
  '😴',
  '😌',
  '🤓',
  '😛',
  '😜',
  '😠',
  '😇',
  '😷',
  '😈',
  '👻',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '🙈',
  '🙉',
  '🙊',
  '🦄',
  '🦁',
  '👼',
  '👮',
  '🕵',
  '💂',
  '👳',
  '🎅',
  '👸',
  '👰',
  '👲',
  '🙍',
  '💪',
  '🖕',
  '🖖',
  '🤘',
  '🖐',
  '👌',
  '👍',
  '👎',
  '✊',
  '👊',
  '👏',
  '🙏',
  '🍉',
  '🍔',
  '🍟',
  '🌭',
  '🥞',
  '🥗',
  '🌮',
  '🍦',
  '🍫',
  '🧃',
  '☕',
  '🍤',
  '🍨',
  '🍪',
  '🎂',
  '🍰',
  '🍾',
  '🍷',
  '🍸',
  '🍺',
  '🌍',
  '⏰',
  '🌺',
  '💐',
  '🌻',
  '🌼',
  '🌳',
  '🌙',
  '🌝',
  '🌞',
  '⭐',
  '🌟',
  '🌠',
  '⛄',
  '🔥',
  '🎄',
  '🎈',
  '🎉',
  '🎊',
  '🎁',
  '🎲',
  '🔇',
  '🔈',
  '📣',
  '🔔',
  '🎵',
  '🎷',
  '💰',
  '🖊',
  '📅',
  '✅',
  '❎',
  '💯',
  '🧵',
  '🧶',
  '🛒',
  '👓',
  '🦺',
  '👔',
  '👕',
  '👖',
  '👗',
  '🩱',
  '👙',
  '👜',
  '👟',
  '👠',
  '👑',
  '👒',
  '💋',
  '💄',
  '💍',
  '💎',
  '🤿',
  '🥊',
  '🔑',
  '🗝',
  '🪓',
  '🔨',
  '🛠',
  '🔩',
  '⚗',
  '🧪',
  '🩺',
  '💉',
  '🩹',
  '🧰',
  '📞',
  '🚬',
  '🔎',
  '📃',
  '📩',
  '📦',
  '💼',
  '✂',
  '🚗',
  '🚓',
  '🚕',
  '🚌',
  '🛵',
  '🚑',
  '🚒',
  '🪒',
];

const emojiStyle = css`
  font-size: 2vh;
  cursor: pointer;
  display: inline-block;
  width: 2.4vh;
  height: 2.4vh;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${hoverBgColor};
  }
`;

const emojiContainer = css`
  max-height: 0;
  overflow: hidden;
`;
const emojiContainerOpen = css`
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  ::-webkit-scrollbar {
    width: 0.75vh;
  }

  ::-webkit-scrollbar-track {
    background: #00000033;
    transition: all 0.5s ease;
  }

  ::-webkit-scrollbar-thumb {
    background: #d50000aa;
    transition: all 0.5s ease;
  }
`;

type EmojisType = {
  onSelect: (emoji: string) => void;
  isOpen: boolean;
  additionalStyles?: any;
};

export const Emojis = ({ onSelect, isOpen, additionalStyles }: EmojisType) => {
  return (
    <div css={[emojiContainer, isOpen && emojiContainerOpen, additionalStyles]}>
      {emojis.map((emoji) => (
        <span key={emoji} css={emojiStyle} onClick={() => onSelect(emoji)}>
          {emoji}
        </span>
      ))}
    </div>
  );
};
