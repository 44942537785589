import React from 'react';
import { css } from '@emotion/react';
import ProfileIcon from '@mui/icons-material/PersonRounded';
import { layoutContainerStyle, contentContainer } from '../styles';
import Navbar from '../container/NavbarContainer';
import Header from './Header';

const headerStyle = css`
  padding: 1vh;
  font-weight: bold;
  color: #fff;
  font-size: 2vh;
  border-bottom: 0.1vh solid #ffffff33;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const searchInputStyle = css`
  background: transparent;
  border: 0.1vh solid #ffffff;
  color: #ffffff;
  border-radius: 2vh;
  padding: 0.5vh 1.25vh;
  font-size: 1.25vh;
  display: flex;
  flex-grow: 1;
`;

const noResultsStyle = css`
  width: 100%;
  text-align: center;
  font-weight: 100;
  padding: 1vh 0vh;
  color: #ffffff99;
  font-size: 1.25vh;
`;

const searchTextStyle = css`
  font-size: 2vh;
  font-weight: 100;
  padding-right: 1vh;
`;

const entryStyle = css`
  display: flex;
  align-items: center;
  padding: 1vh;
  cursor: pointer;

  :hover {
    background-color: #ffffff33;
  }
`;

const profileIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
  background-color: #d50000;
  border-radius: 50%;
  color: white;
  padding: 0.25vh;
  cursor: pointer;
`;

const userTagStyle = css`
  color: #ffffff;
  font-weight: bold;
  padding-left: 1vh;
  font-size: 1.5vh;
  cursor: pointer;
  word-break: break-all;
`;

const displayNameStyle = css`
  font-weight: 100;
`;

type SearchType = {
  startSearch: (query: string) => void;
  content: { userTag: string; displayName: string; _id: string }[];
  goto: (route: string, params?: any) => void;
  search: string;
  setSearch: (search: string) => void;
};
export const Search = ({
  startSearch,
  content,
  goto,
  search,
  setSearch,
}: SearchType) => {
  const executeSearch = () => {
    if (search.length > 2) {
      startSearch(search);
    }
  };

  const gotoProfilePage = (entry: any) => {
    goto(`/profilepage`, { userTag: entry.userTag });
  };

  const onSearchInput = (key: string) => {
    if (key === 'Enter') {
      executeSearch();
    }
  };

  return (
    <div css={layoutContainerStyle}>
      <Header goto={goto} />
      <Navbar />
      <div css={contentContainer}>
        <div css={headerStyle}>
          <span css={searchTextStyle}>Suche</span>
          <input
            placeholder="Leogram Tag"
            css={searchInputStyle}
            value={search}
            onChange={(ev: any) => {
              setSearch(ev.target.value);
            }}
            onKeyPress={(e) => onSearchInput(e.key)}
          />
        </div>

        {content &&
          content.map((entry) => (
            <div
              css={entryStyle}
              onClick={() => gotoProfilePage(entry)}
              key={entry.userTag}
            >
              <ProfileIcon css={profileIconStyle} />
              <div>
                <div css={userTagStyle}>{entry.userTag}</div>
                <div css={[userTagStyle, displayNameStyle]}>
                  {entry.displayName}
                </div>
              </div>
            </div>
          ))}
        {(!content || content.length === 0) && (
          <div css={noResultsStyle}>Keine Ergebnisse gefunden</div>
        )}
      </div>
    </div>
  );
};
