import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLspdPersonSearch, setLspdPersonSearch } from '../../../actions';
import {
  lspdPersonSearchListP,
  lspdPersonSearchP,
} from '../../../redux/reducer/apps/lspd';
import { PersonSearchListEntry } from './types';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';

type PersonSearchType = {
  openPerson: (_id: string, name: string) => void;
};
export const PersonSearch = ({ openPerson }: PersonSearchType) => {
  const dispatch = useDispatch();
  const search = useSelector(pathOr('', lspdPersonSearchP));
  const list = useSelector<any, PersonSearchListEntry[]>(
    pathOr([], lspdPersonSearchListP)
  );

  const setSearch = (search: string) => {
    dispatch(setLspdPersonSearch(search));
  };

  const fetchData = () => {
    dispatch(fetchLspdPersonSearch(search));
  };

  return (
    <div className="w-full h-full">
      <h1>Person suchen</h1>
      <div>
        <TextField
          label="Suche"
          type="text"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />
        <Button variant="outlined" onClick={fetchData}>
          Suchen
        </Button>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">Geschlecht</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((entry) => (
            <TableRow
              key={entry._id}
              onClick={() => openPerson(entry.name, entry.name)}
            >
              <TableCell>{entry.name}</TableCell>
              <TableCell align="center">
                {entry.sex === 1 && <MaleIcon />}
                {entry.sex === 0 && <FemaleIcon />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
