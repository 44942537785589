import { fromPromiseC } from './fromPromiseC';
import { CallbagState } from './types';

type TTalkback = (x: number) => void;
type TPossibleTalkback = undefined | TTalkback;

const flatmap = (
  makeSource: Function,
  combineResults: Function = (x: unknown, y: unknown) => y
) => (inputSource: Function) => (start: CallbagState, sink: Function) => {
  if (start !== 0) return;

  let index = 0;
  let talkbacks = {};
  let sourceEnded = false;
  let inputSourceTalkback: Function | null = null;

  let pullHandle = (t: CallbagState) => {
    // @ts-ignore
    var currTalkback: TPossibleTalkback = Object.values(talkbacks).pop();
    if (t === 1) {
      if (currTalkback) {
        currTalkback(1);
      } else if (!sourceEnded) {
        inputSourceTalkback && inputSourceTalkback(1);
      } else {
        sink(2);
      }
    }
    if (t === 2) {
      if (currTalkback) {
        currTalkback(2);
      }
      inputSourceTalkback && inputSourceTalkback(2);
    }
  };

  let stopOrContinue = (d: Function) => {
    // if (sourceEnded && Object.keys(talkbacks).length === 0) sink(2, d);
    // else inputSourceTalkback(1);
    sink(2, d);
  };

  let makeSink = (
    i: number,
    d: Function,
    talkbacks: { [index: number]: Function }
  ) => (currT: CallbagState, currD: Function) => {
    if (currT === 0) {
      talkbacks[i] = currD;
      talkbacks[i](1);
    }
    if (currT === 1) {
      sink(1, combineResults(d, currD));
    }
    if (currT === 2) {
      delete talkbacks[i];
      stopOrContinue(currD);
    }
  };

  inputSource(0, (t: CallbagState, d: Function) => {
    if (t === 0) {
      inputSourceTalkback = d;
      sink(0, pullHandle);
    }
    if (t === 1) {
      makeSource(d)(0, makeSink(index++, d, talkbacks));
    }
    if (t === 2) {
      sourceEnded = true;
      stopOrContinue(d);
    }
  });
};

const transfer = (data: unknown) => (type: CallbagState, d: Function) => {
  if (type === 2 && data) {
    d(2, data);
    return;
  }
  if (type !== 0) return;
  d(0, () => {});
  d(1, data);
};

export const mapPromiseC = (fn: Function, endpoint?: Function) => (
  source: Function
) => {
  return flatmap((d: unknown) => {
    d = fn(d);
    return d instanceof Promise ? fromPromiseC(d) : transfer(d);
  }, endpoint)(source);
};
