import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import { setAdminPlayerSearch, startAdminPlayerSearch } from '../../../actions';
import {
  appsAdminSearchP,
  appsAdminSearchResultsP,
} from '../../../redux/reducer/apps/admin/playersearch';
import { PlayerSearchEntryType } from './types/playersearch';

type PlayerSearchType = {
  openPlayer: (_id: string, playerName: string) => void;
};
export const PlayerSearch = ({ openPlayer }: PlayerSearchType) => {
  const dispatch = useDispatch();
  const search = useSelector<any, string>(pathOr('', appsAdminSearchP));
  const results = useSelector<any, PlayerSearchEntryType[]>(
    pathOr([], appsAdminSearchResultsP)
  );

  const updateSearch = (ev: any) => {
    dispatch(setAdminPlayerSearch(ev.target.value));
  };
  const executeSearch = () => {
    dispatch(startAdminPlayerSearch(search));
  };

  return (
    <div>
      <h1>Spielersuche</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={updateSearch}
      />

      <Button variant="outlined" onClick={executeSearch}>
        Suche starten
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Forum Name</TableCell>
            <TableCell>Forum Id</TableCell>
            <TableCell>Online</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((entry) => (
            <TableRow
              key={entry._id}
              onClick={() => openPlayer(entry._id, entry.name)}
              css={{ cursor: 'pointer' }}
            >
              <TableCell>{entry.name}</TableCell>
              <TableCell>{entry.forumname}</TableCell>
              <TableCell>{entry.forumid}</TableCell>
              <TableCell>
                <Checkbox checked={entry.online} disabled />
              </TableCell>
            </TableRow>
          ))}
          {results.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>Keine Ergebnisse</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
