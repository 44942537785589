import { Button, TextField } from '@mui/material';
import { find, lensPath, pathOr, pipe, set, filter, concat } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import {
  startAdminAllRightsFetch,
  startAdminPermissionsChange,
  startAdminPermissionsDelete,
  startAdminPermissionsFetch,
  startAdminRolesFetch,
} from '../../../actions';
import {
  appsAdminAllRightsP,
  appsAdminRolesP,
} from '../../../redux/reducer/apps/admin/roles';
import { appsAdminPermissionsP } from '../../../redux/reducer/apps/admin/userrights';
import { AdminRight, AdminRole } from './types/roles';
import { UserPermissionType } from './types/userrights';

const NEW_USER_TEMPLATE: UserPermissionType = {
  _id: '',
  roles: [],
  character: {
    _id: '',
    name: '',
  },
  rightsAdjustment: [],
};

type UserRightsType = {};

export const UserRights = ({}: UserRightsType) => {
  const [user, setUser] = useState<UserPermissionType | undefined>();
  const [isNew, setNew] = useState(false);
  const dispatch = useDispatch();
  const permissions = useSelector<any, UserPermissionType[]>(
    pathOr([], appsAdminPermissionsP)
  );
  const roles = useSelector<any, AdminRole[]>(pathOr([], appsAdminRolesP));
  const allRights = useSelector<any, AdminRight[]>(
    pathOr([], appsAdminAllRightsP)
  );

  useEffect(() => {
    dispatch(startAdminPermissionsFetch());
    dispatch(startAdminRolesFetch());
    dispatch(startAdminAllRightsFetch());
  }, []);

  const updateRoleName = (ev: any) => {
    setUser(set(lensPath(['character', 'name']), ev.target.value, user));
  };

  const updateSpecialRights = (right: string, value: -1 | 0 | 1) => {
    if (!user) {
      return;
    }

    const newUser = pipe<any[], any[], any[], any>(
      filter((x: any) => x.right !== right),
      concat(value === 0 ? [] : [{ right, adjustment: value }]),
      (rights: any[]) => set(lensPath(['rightsAdjustment']), rights, user)
    )(user.rightsAdjustment);

    setUser(newUser);
  };

  const updateRole = (role: string, enabled: boolean) => {
    if (!user) {
      return;
    }

    const newUser = pipe<any[], any[], any[], any>(
      filter((x: any) => x.key !== role),
      concat(!enabled ? [] : [{ key: role }]),
      (roles: any[]) => set(lensPath(['roles']), roles, user)
    )(user.roles);

    setUser(newUser);
  };

  const save = () => {
    if (!user) {
      return;
    }

    dispatch(startAdminPermissionsChange(user));

    setUser(undefined);
  };

  const deleteUser = (user: UserPermissionType) => {
    dispatch(startAdminPermissionsDelete(user));
  };

  return (
    <div>
      <h1>Spielerberechtigungen</h1>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Spielername</TableCell>
            <TableCell>Benutzerrollen</TableCell>
            <TableCell>Sonderrechte</TableCell>
            <TableCell>Bearbeiten</TableCell>
            <TableCell>Löschen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>{entry.character?.name}</TableCell>
              <TableCell>{entry.roles.map((x) => x.key).join(', ')}</TableCell>
              <TableCell>
                {entry.rightsAdjustment
                  .map(
                    (right) =>
                      `(${right.adjustment === 1 ? '+' : '-'}) ${right.right}`
                  )
                  .join(', ')}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setUser(entry);
                    setNew(false);
                  }}
                >
                  Bearbeiten
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() => {
                    deleteUser(entry);
                  }}
                >
                  <FaTrash />
                </Button>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={5}>
              <Button
                variant="outlined"
                onClick={() => {
                  setUser(NEW_USER_TEMPLATE);
                  setNew(true);
                }}
              >
                Neue Rolle erstellen
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {user && (
        <>
          <h1>
            {user.character?.name} {isNew ? 'erstellen' : 'bearbeiten'}
          </h1>

          <TextField
            label="Spielername"
            type="text"
            value={user.character?.name}
            onChange={updateRoleName}
            disabled={!isNew}
          />

          <h3>Benutzerrollen</h3>

          <div>
            {roles.map((role) => {
              const isActive =
                user.roles.map((entry) => entry.key).indexOf(role.key) > -1;
              return (
                <div key={role._id}>
                  <Checkbox
                    checked={isActive}
                    onChange={() => updateRole(role.key, !isActive)}
                  />
                  <span onClick={() => updateRole(role.key, !isActive)}>
                    {role.key}
                  </span>
                </div>
              );
            })}
          </div>

          <h3>Sonderrechte</h3>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Recht</TableCell>
                <TableCell>hinzufügen</TableCell>
                <TableCell>entziehen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allRights.map((right, index) => {
                const isAdded = !!find(
                  (x) => x.right === right.key && x.adjustment === 1,
                  user.rightsAdjustment
                );
                const isRemoved = !!find(
                  (x) => x.right === right.key && x.adjustment === -1,
                  user.rightsAdjustment
                );
                return (
                  <TableRow key={right.key}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{right.key}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={isAdded}
                        onChange={() =>
                          updateSpecialRights(right.key, isAdded ? 0 : 1)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={isRemoved}
                        onChange={() =>
                          updateSpecialRights(right.key, isRemoved ? 0 : -1)
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Button variant="outlined" onClick={save}>
            Speichern
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setUser(undefined);
            }}
          >
            Abbrechen
          </Button>
        </>
      )}
    </div>
  );
};
