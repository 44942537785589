import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { TextField, Button } from '@mui/material';
// import { ReactComponent as Logo } from '../logo.svg';

export const containerStyle = css`
  background-image: linear-gradient(to right, #d50000, #800000);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const firstContainerStyle = css`
  display: flex;
  flex-direction: column;
  background: #ffffff33;
  padding: 3vh;
  width: 80%;
  max-width: 33vh;
  align-self: center;
  color: #ffffff !important;
  font-weight: 100;
  font-size: 1.25vh;
`;

export const secondContainerStyle = css`
  margin-top: 2vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const headerContainer = css`
  display: flex;
  width: 100%;
  color: white;
  align-items: center;
  margin-bottom: 2vh;
`;

export const logoStyle = (theme: any) => css`
  height: 3vh !important;
  width: 3vh !important;
  padding: 0.5vh !important;
  cursor: pointer;
`;

export const titleStyle = css`
  font-size: 2.5vh;
  font-weight: 100;
  letter-spacing: -0.1vh;
`;

export const inputStyle = css`
  padding: 1vh 0 !important;
  input {
    color: #ffffff !important;
    font-size: 1.5vh;
  }
`;

export const buttonStyle = css`
  background-color: #d50000 !important;
  color: #ffffff !important;
  font-size: 1.25vh !important;
`;

export const registerButtonStyle = css`
  color: #ffffff !important;
`;

const failedStyle = css`
  border-color: red;
`;

type LoginType = {
  login: (userTag: string, pw: string) => void;
  goto: (route: string) => void;
  resetFailed: () => void;
  failed: boolean;
};

export const Login = ({ login, goto, resetFailed, failed }: LoginType) => {
  const [userTag, setUserTag] = useState('');
  const [pass, setPass] = useState('');

  useEffect(() => {
    if (failed) {
      setPass('');
    }
  }, [failed]);

  const onPassInput = (key: string) => {
    if (key === 'Enter') {
      onLogin();
    }
  };

  const onLogin = () => {
    resetFailed();
    login(userTag, pass);
  };

  return (
    <div css={containerStyle}>
      <div css={firstContainerStyle}>
        <div css={headerContainer}>
          {/* <Logo css={logoStyle} /> */}
          <div css={titleStyle}>Leogram</div>
        </div>
        <TextField
          css={[inputStyle, failed && failedStyle]}
          type="text"
          placeholder="Leogram Tag"
          value={userTag}
          onChange={(ev: any) => setUserTag(ev.target.value)}
          variant="standard"
        />
        <TextField
          css={[inputStyle, failed && failedStyle]}
          type="password"
          placeholder="Passwort"
          value={pass}
          onChange={(ev: any) => setPass(ev.target.value)}
          onKeyPress={(e) => onPassInput(e.key)}
          variant="standard"
        />
        <Button
          css={buttonStyle}
          disableElevation
          variant="contained"
          onClick={onLogin}
        >
          Einloggen
        </Button>
      </div>
      <div css={[firstContainerStyle, secondContainerStyle]}>
        Noch kein Account?
        <Button onClick={() => goto('/register')} css={registerButtonStyle}>
          Registrieren
        </Button>
      </div>
    </div>
  );
};
