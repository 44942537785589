import { useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {
  startAdminAutorestartSetProp,
  startAdminFetchAutorestarts,
  startAdminRestartServer,
  startAdminRestartsFetch,
  updateAdminAutorestart,
} from '../../../actions';
import {
  appsAutorestartsP,
  appsRestartCompleteLoadedP,
  appsRestartsP,
} from '../../../redux/reducer/apps/admin/restart';
import { pathOr } from 'ramda';
import { AutorestartType, RestartType } from './types/restart';
import { DateTime } from 'luxon';

type RestartCmpType = {};

export const Restart = ({}: RestartCmpType) => {
  const dispatch = useDispatch();
  const autoRestarts = useSelector<any, AutorestartType[]>(
    pathOr([], appsAutorestartsP)
  );
  const areRestartsCompletlyLoaded = useSelector<any, boolean>(
    pathOr(false, appsRestartCompleteLoadedP)
  );
  const restarts = useSelector<any, RestartType[]>(pathOr([], appsRestartsP));

  useEffect(() => {
    dispatch(startAdminFetchAutorestarts());
    dispatch(startAdminRestartsFetch(0));
  }, []);

  const doRestart = () => {
    dispatch(startAdminRestartServer());
  };

  const changeAutorestartValue = (index: number, prop: string) => (ev: any) => {
    dispatch(startAdminAutorestartSetProp([index, prop], ev.target.value));
  };

  const updateRestart = (entry: AutorestartType) => {
    dispatch(updateAdminAutorestart(entry));
  };

  const loadMoreRestarts = () => {
    dispatch(startAdminRestartsFetch(autoRestarts.length));
  };

  return (
    <div>
      <h1>Manueller Restart</h1>

      <Button variant="outlined" onClick={doRestart}>
        Restart jetzt ausführen
      </Button>

      <h1>Serverrestarts</h1>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Uhrzeit</TableCell>
            <TableCell>Ausführer</TableCell>
            <TableCell>Spieleranzahl</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {restarts.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.created))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>{entry.character?.name ?? ''}</TableCell>
              <TableCell>{entry.players}</TableCell>
            </TableRow>
          ))}
          {!areRestartsCompletlyLoaded && (
            <TableRow>
              <TableCell colSpan={3}>
                <Button variant="outlined" onClick={() => loadMoreRestarts()}>
                  Weitere Laden
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <h1>Autorestarts</h1>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Stunde</TableCell>
            <TableCell>Minute</TableCell>
            <TableCell>Aktiv</TableCell>
            <TableCell>Speichern</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {autoRestarts.map((entry, index) => (
            <TableRow key={entry._id}>
              <TableCell>
                <TextField
                  type="text"
                  value={entry.hour}
                  onChange={changeAutorestartValue(index, 'hour')}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={entry.minute}
                  onChange={changeAutorestartValue(index, 'minute')}
                />
              </TableCell>
              <TableCell>
                <input
                  type="checkbox"
                  checked={entry.active}
                  onChange={() =>
                    changeAutorestartValue(
                      index,
                      'active'
                    )({ target: { value: !entry.active } })
                  }
                />
              </TableCell>
              <TableCell>
                <Button variant="outlined" onClick={() => updateRestart(entry)}>
                  Speichern
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
