import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import * as types from '../redux/types';
import { pipeC, ofType, mapC } from '../utils/callbag';

export const onRouterChange = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ROUTER),
    mapC(({ payload }: any) => {
      if (
        payload.location.pathname.indexOf('/business/acceptinvite') > -1 ||
        payload.location.pathname.indexOf('/team/acceptinvite') > -1
      ) {
        return { type: types.LOGOUT };
      }
    })
  );
