import React from 'react';
import Button from '@mui/material/Button';
import Headline from '../../Headline';
import { CompanyData } from './types';

type QuitType = {
  company: CompanyData;
  fire: (id: string, name: string) => void;
  username: string;
};

export const Quit = ({ company, fire, username }: QuitType) => {
  return (
    <div>
      <Headline>Kündigen</Headline>
      <div>Bist du dir sicher das du kündigen möchtest?</div>

      <Button onClick={() => fire(company._id, username)}>
        Arbeitsvertrag beenden
      </Button>
    </div>
  );
};
