import * as type from '../store/types';

export function startLogin(userTag: string, password: string) {
  return {
    type: type.LEOGRAM_LOGIN_START,
    userTag,
    password,
  };
}

export function resetLoginFailed() {
  return {
    type: type.LEOGRAM_LOGIN_FAILED_RESET,
  };
}

export function logout() {
  return {
    type: type.LEOGRAM_LOGOUT,
  };
}
