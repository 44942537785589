import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { rootReducer, history } from './reducer';
import { createReduxbagMiddleware } from '../utils/callbag/reduxBagMiddleware';
import effects from '../effects';
import { middleware as eventsMiddleware } from '../events';

export { history };

export const initialState: any = {
  dark: false,
  volume: 100,
  brightness: 80,
  wifi: true,
  bluetooth: true,
  airdrop: true,
  fullscreen: false,
};

const middleware: any[] = [
  createReduxbagMiddleware(...effects),
  thunk,
  routerMiddleware(history),
  eventsMiddleware,
];

const enhancers = [];
if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);
export default store;
