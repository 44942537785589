import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { equals, find, lensPath, pipe, set } from 'ramda';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createDocumentScreenshot,
  createDuplicateDocument,
  printDocument,
  saveDocument,
  startDocumentScreenshot,
} from '../../../actions';
import Editor from '../../../leogram/shared/Editor';
import { DocumentType } from './types';
import IconButton from '@mui/material/IconButton';
import { Select, MenuItem, Autocomplete } from '@mui/material';
import SaveIcon from '@mui/icons-material/SaveRounded';
import { css } from '@emotion/react';
import ZoomSlider from './ZoomSlider';
import AddIcon from '@mui/icons-material/AddRounded';
import PrintIcon from '@mui/icons-material/Print';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LexicalEditor } from '../../../leogram/shared/LexicalEditor';
import { getDocTypes, getTemplateForDocType } from './doc-types';
import { translate } from '../../../utils/translate';
import { documentContainerStyle } from '../../../leogram/shared/LexicalEditor/custom-styles';
import { DocumentHistory } from './History';
import { useScreenshot } from '../../../leogram/shared/useScreenshot';

const subjectInputStyle = css`
  margin-top: 1vh !important;
  margin-bottom: 1vh !important;
  .MuiInput-input {
    font-size: 2.5vh !important;
  }
`;

const documentHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 3vh;
  > div {
    flex-grow: 1;
    margin-right: 3vh;
  }
`;

export const documentStyle = css`
  margin: 2vh;
  max-width: calc(100% - 4vh);
`;

const saveIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
`;

const sliderContainerStyle = css`
  margin: 0vh 10vh;
`;

const addPageStyle = css`
  display: flex;
  justify-content: center;
  margin-bottom: 2vh;
`;
const docTypeSelectionStyle = css`
  margin: 1vh 3vh;
}
`;

const titleBtnStyle = css`
  padding: 20px;
`;

const viewChangeStyle = css`
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
`;

type DocumentViewType = {
  document: DocumentType;
  isSelected: boolean;
  isSaved: boolean;
  updateSavedStatus: (id: string, saved: boolean) => void;
};
export const DocumentView = ({
  document,
  isSelected,
  isSaved,
  updateSavedStatus,
}: DocumentViewType) => {
  const dispatch = useDispatch();
  const ref = useRef<{ [pageNumber: string]: any }>({});
  const [image, takeScreenshot] = useScreenshot();

  const [doc, setDoc] = useState<DocumentType>();
  const [zoom, setZoom] = useState(25);
  const [docTypeInputValue, setDocTypeInputValue] = useState<string>('');
  const [viewType, setViewType] = useState('editor');

  const docTypes = useMemo(() => getDocTypes(() => true), []);
  const selectedDocType = find(
    (docType: any) => docType.hash === doc?.itemHash,
    docTypes
  );
  useEffect(() => {
    setDocTypeInputValue(translate(selectedDocType?.hash || ''));
  }, [selectedDocType]);

  useEffect(() => {
    if (document) {
      setDoc({
        ...document,
        pages:
          document.version === 2
            ? document.pages
            : document.pages.map((page) =>
                page
                  ? EditorState.createWithContent(
                      convertFromRaw(JSON.parse(page))
                    )
                  : EditorState.createEmpty()
              ),
      });

      if (document.isNew) {
        updateSavedStatus(document._id, false);
      } else {
        updateSavedStatus(document._id, true);
      }
    }
  }, [document]);

  if (!doc || !isSelected) {
    return null;
  }

  const updateSubject = (ev: any) => {
    updateSavedStatus(document._id, false);
    pipe(set(lensPath(['subject']), ev.target.value), setDoc)(doc);
  };

  const updatePage = (index: number) => (content: any) => {
    updateSavedStatus(document._id, false);
    pipe(set(lensPath(['pages', index]), content), setDoc)(doc);
  };

  const updateDocType = (hash: string) => {
    updateSavedStatus(document._id, false);
    pipe(set(lensPath(['itemHash']), hash), setDoc)(doc);
  };

  const save = () => {
    pipe(
      (doc: DocumentType) => ({
        ...doc,
        pages: doc.pages.map((page: any) =>
          doc.version === 2
            ? page
            : JSON.stringify(convertToRaw(page.getCurrentContent()))
        ),
      }),
      saveDocument,
      dispatch
    )(doc);
  };

  const createNewPage = () => {
    updateSavedStatus(document._id, false);
    pipe(set(lensPath(['pages']), [...doc.pages, undefined]), setDoc)(doc);
  };

  const print = () => {
    dispatch(printDocument(doc._id));
  };

  const duplicate = () => {
    dispatch(
      createDuplicateDocument(
        doc.subject,
        doc.pages,
        doc.itemHash || 'documentA4'
      )
    );
  };

  const onPrint = (page: number) => {
    const pageRef =
      ref.current[page.toString()].getElementsByClassName('editor-shell')[0];
    dispatch(startDocumentScreenshot());
    takeScreenshot(pageRef).then((img) => {
      if (img) {
        dispatch(createDocumentScreenshot(img));
      }
    });
  };

  const normalizedZoom = zoom / 20;
  return (
    <div>
      <div css={documentHeaderStyle}>
        <TextField
          css={subjectInputStyle}
          label={doc.subject !== '' && 'Titel'}
          placeholder="Titel"
          value={doc.subject}
          onChange={updateSubject}
        />
        {doc.version === 2 && (
          <IconButton onClick={duplicate} css={titleBtnStyle}>
            <ContentCopyIcon css={saveIconStyle} />
          </IconButton>
        )}
        {doc.version === 2 && (
          <IconButton onClick={print} disabled={isSaved} css={titleBtnStyle}>
            <PrintIcon css={saveIconStyle} />
          </IconButton>
        )}
        <IconButton onClick={save} disabled={!isSaved}>
          <SaveIcon css={saveIconStyle} />
        </IconButton>
      </div>
      {doc.version === 2 && (
        <div css={docTypeSelectionStyle}>
          <Autocomplete
            options={docTypes}
            getOptionLabel={(option: any) => translate(option.hash) || ''}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            value={selectedDocType}
            onChange={(ev, newValue: any) => {
              if (newValue) {
                updateDocType(newValue.hash);
                setDocTypeInputValue(
                  newValue.hash ? translate(newValue.hash) || '' : ''
                );
              } else {
                setDocTypeInputValue('');
              }
            }}
            inputValue={docTypeInputValue}
            onInputChange={(ev: any) =>
              ev && ev.target && setDocTypeInputValue(ev.target.value)
            }
          />
        </div>
      )}
      <div css={viewChangeStyle}>
        <FormControl>
          <FormLabel>Ansicht</FormLabel>
          <RadioGroup
            defaultValue="editor"
            row
            value={viewType}
            onChange={(ev) => setViewType(ev.target.value)}
          >
            <FormControlLabel
              value="editor"
              control={<Radio />}
              label="Dokument"
            />
            <FormControlLabel
              value="history"
              control={<Radio />}
              label="Historie"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div css={sliderContainerStyle}>
        <ZoomSlider
          value={zoom}
          setValue={setZoom}
          minValue={1}
          maxValue={40}
        />
      </div>
      {viewType === 'editor' && (
        <>
          <div
            css={() =>
              documentContainerStyle(
                doc.version === 2 ? 1 : normalizedZoom,
                getTemplateForDocType(doc.itemHash, doc.version || 1)
              )
            }
          >
            {doc.pages.map((page, index) => {
              return (
                <div
                  css={documentStyle}
                  key={index}
                  ref={(pageRef: any) =>
                    (ref.current[index.toString()] = pageRef)
                  }
                >
                  {(!doc.version || doc.version < 2) && (
                    <Editor
                      key={index}
                      content={page}
                      setContent={updatePage(index)}
                      size={{ height: '80vh', width: '50vh' }}
                      stamps={[]}
                      template={doc.template}
                    />
                  )}
                  {doc.version === 2 && (
                    <LexicalEditor
                      key={index}
                      content={page}
                      zoom={normalizedZoom}
                      setContent={updatePage(index)}
                      onBtnTakeScrenshot={() => onPrint(index)}
                    />
                  )}
                </div>
              );
            })}
          </div>

          {doc.version === 2 && (
            <div css={addPageStyle}>
              <Button
                variant="outlined"
                onClick={createNewPage}
                startIcon={<AddIcon />}
              >
                Neue Seite hinzufügen
              </Button>
            </div>
          )}
        </>
      )}

      {viewType === 'history' && (
        <DocumentHistory documentId={doc._id} normalizedZoom={normalizedZoom} />
      )}
    </div>
  );
};
