import { css } from '@emotion/react';
import { pathOr, find, pathEq, pipe } from 'ramda';
import { translate } from '../../../utils/translate';

export const WEAPON_STATES = ['NONE', 'STOLEN', 'WANTED', 'CONFISCATED'].map(
  (entry) => ({
    label: translate(`weaponTag.${entry}`) || '-',
    value: entry,
  })
);

export const getWeaponStateLabel = (state: string) => {
  return pipe(
    find(pathEq(['value'], state)),
    pathOr('-', ['label'])
  )(WEAPON_STATES);
};

export const weaponStateIconStyle = (theme: any) => css`
  &.WANTED {
    color: ${theme.colors.Red};
  }
  &.STOLEN {
    color: ${theme.colors.Orange};
  }
  &.CONFISCATED {
    color: ${theme.colors.Yellow};
  }
  &.NONE {
    color: ${theme.colors.Green};
  }
`;
