import { AutorestartType } from '../components/apps/Admin/types/restart';
import { AdminRole } from '../components/apps/Admin/types/roles';
import { UserPermissionType } from '../components/apps/Admin/types/userrights';
import * as type from '../redux/types';

export function startAdminFetchAutorestarts() {
  return {
    type: type.ADMIN_RESTARTS_TIMETABLE_FETCH_START,
  };
}

export function startAdminAutorestartSetProp(path: any[], value: any) {
  return {
    type: type.ADMIN_RESTARTS_TIMETABLE_PROP_SET,
    path,
    value,
  };
}

export function updateAdminAutorestart(data: AutorestartType) {
  return {
    type: type.ADMIN_RESTARTS_TIMETABLE_UPDATE_START,
    data,
  };
}

export function startAdminRestartsFetch(offset: number) {
  return {
    type: type.ADMIN_RESTARTS_FETCH_START,
    offset,
  };
}

export function startAdminRestartServer() {
  return {
    type: type.ADMIN_RESTART_SERVER_START,
  };
}

export function startAdminRolesFetch() {
  return {
    type: type.ADMIN_ROLES_FETCH_START,
  };
}
export function startAdminAllRightsFetch() {
  return {
    type: type.ADMIN_RIGHTS_ALL_FETCH_START,
  };
}

export function startAdminRoleAdd(role: AdminRole) {
  return {
    type: type.ADMIN_ROLES_ADD_START,
    role,
  };
}
export function startAdminRoleEdit(role: AdminRole) {
  return {
    type: type.ADMIN_ROLES_EDIT_START,
    role,
  };
}
export function startAdminRoleDelete(role: AdminRole) {
  return {
    type: type.ADMIN_ROLES_DELETE_START,
    role,
  };
}
export function startAdminPermissionsFetch() {
  return {
    type: type.ADMIN_PERMISSIONS_FETCH_START,
  };
}
export function startAdminPermissionsChange(user: UserPermissionType) {
  return {
    type: type.ADMIN_PERMISSIONS_CHANGE_START,
    user,
  };
}
export function startAdminPermissionsDelete(user: UserPermissionType) {
  return {
    type: type.ADMIN_PERMISSIONS_DELETE_START,
    user,
  };
}
export function setAdminPlayerSearch(search: string) {
  return {
    type: type.ADMIN_PLAYER_SEARCH_SET,
    search,
  };
}
export function startAdminPlayerSearch(search: string) {
  return {
    type: type.ADMIN_PLAYER_SEARCH_FETCH_START,
    search,
  };
}
export function startAdminPlayerDetails(_id: string) {
  return {
    type: type.ADMIN_PLAYER_DETAILS_FETCH_START,
    _id,
  };
}
export function startAdminTpAdminBase(_id: string) {
  return {
    type: type.ADMIN_PLAYER_TP_ADMINBASE_FETCH_START,
    _id,
  };
}
export function startAdminToggleDisableSerial(_id: string) {
  return {
    type: type.ADMIN_PLAYER_DISABLESERIAL_TOGGLE_FETCH_START,
    _id,
  };
}
export function startAdminToggleMultiAccAccCheck(_id: string) {
  return {
    type: type.ADMIN_PLAYER_MULTIACCOUNT_ACCCHECK_TOGGLE_FETCH_START,
    _id,
  };
}
export function startAdminSecondchance(_id: string) {
  return {
    type: type.ADMIN_PLAYER_SECONDCHANCE_FETCH_START,
    _id,
  };
}
export function startAdminPlayerIdsFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_IDS_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerBankAccountsFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_BACKACCOUNTS_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerVehiclesFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_VEHICLES_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerTelNumbersFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_TELNUMBERS_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerInventoryFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_INVENTORY_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerMultiaccountFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_MULTIACCOUNT_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerMultiaccountBFetch(
  _id: string,
  offset: number
) {
  return {
    type: type.ADMIN_PLAYER_MULTIACCOUNTB_FETCH_START,
    _id,
    offset,
  };
}

export function startAdminPlayerFactoryOwnerFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_FACTORYOWNER_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerFactoryEmploymentFetch(
  _id: string,
  offset: number
) {
  return {
    type: type.ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerApartmentsFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_APARTMENTS_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerDeathsFetch(_id: string, offset: number) {
  return {
    type: type.ADMIN_PLAYER_DEATHS_FETCH_START,
    _id,
    offset,
  };
}
export function startAdminPlayerTicketsFetch(_id: string) {
  return {
    type: type.ADMIN_PLAYER_TICKETS_FETCH_START,
    _id,
  };
}
export function startAdminPlayerNotesFetch(_id: string) {
  return {
    type: type.ADMIN_PLAYER_NOTES_FETCH_START,
    _id,
  };
}
export function startAdminPlayerWarningsFetch(_id: string) {
  return {
    type: type.ADMIN_PLAYER_WARNINGS_FETCH_START,
    _id,
  };
}
export function startAdminPlayerBansFetch(_id: string) {
  return {
    type: type.ADMIN_PLAYER_BANS_FETCH_START,
    _id,
  };
}

export function startAdminPlayerNameChange(_id: string, name: string) {
  return {
    type: type.ADMIN_PLAYER_NAMECHANGE_START,
    _id,
    name,
  };
}

export function startAdminPlayerPwChange(_id: string, password: string) {
  return {
    type: type.ADMIN_PLAYER_PWCHANGE_START,
    _id,
    password,
  };
}

export function startAdminPlayerMsg(_id: string, msgReason: string) {
  return {
    type: type.ADMIN_PLAYER_MSG_START,
    _id,
    msgReason,
  };
}

export function startAdminPlayerKick(_id: string, kickReason: string) {
  return {
    type: type.ADMIN_PLAYER_KICK_START,
    _id,
    kickReason,
  };
}

export function startAdminPlayerMaxBankAccountsFetch(_id: string) {
  return {
    type: type.ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_START,
    _id,
  };
}

export function setAdminPlayerMaxBankAccounts(_id: string, num: number) {
  return {
    type: type.ADMIN_PLAYER_MAX_BANKACCOUNT_SET_START,
    _id,
    num,
  };
}

export function resetAdminPlayerScMultiAccount(_id: string, refreshId: string) {
  return {
    type: type.ADMIN_PLAYER_SCMULTIACCOUNT_RESET_START,
    _id,
    refreshId,
  };
}

export function createAdminPlayerNote(_id: string, note: string) {
  return {
    type: type.ADMIN_PLAYER_NOTE_CREATE_START,
    _id,
    note,
  };
}

export function createAdminPlayerWarning(_id: string, reason: string) {
  return {
    type: type.ADMIN_PLAYER_WARNING_CREATE_START,
    _id,
    reason,
  };
}

export function createAdminPlayerBan(
  _id: string,
  reason: string,
  expireInMinutes: number,
  banOnlyChar = false
) {
  return {
    type: type.ADMIN_PLAYER_BAN_CREATE_START,
    _id,
    reason,
    expireInMinutes,
    banOnlyChar,
  };
}

export function createAdminPlayerUnban(_id: string, refreshId: string) {
  return {
    type: type.ADMIN_PLAYER_BAN_REMOVE_START,
    _id,
    refreshId,
  };
}

export function removeAdminPlayerItem(
  _id: string,
  id: string,
  hash: string,
  amount: number
) {
  return {
    type: type.ADMIN_PLAYER_ITEM_REMOVE_START,
    _id,
    id,
    hash,
    amount,
  };
}

export function setAdminVehiclesSearch(search: string) {
  return {
    type: type.ADMIN_VEHICLES_SEARCH_SET,
    search,
  };
}
export function startAdminVehiclesSearch(search: string) {
  return {
    type: type.ADMIN_VEHICLES_SEARCH_FETCH_START,
    search,
  };
}

export function startAdminVehicleFetch(_id: string) {
  return {
    type: type.ADMIN_VEHICLE_FETCH_START,
    _id,
  };
}

export function removeAdminVehicleItem(
  _id: string,
  id: string,
  hash: string,
  amount: number
) {
  return {
    type: type.ADMIN_VEHICLE_ITEM_REMOVE_START,
    _id,
    id,
    hash,
    amount,
  };
}

export function garageAdminVehicle(_id: string, garage: string) {
  return {
    type: type.ADMIN_VEHICLE_GARAGE_START,
    _id,
    garage,
  };
}

export function searchOwnerAdminVehicle(query: string) {
  return {
    type: type.ADMIN_VEHICLE_OWNER_NEW_SEARCH_START,
    query,
  };
}

export function changeAdminVehicleOwner(_id: string, playerId: string) {
  return {
    type: type.ADMIN_VEHICLE_OWNER_CHANGE_START,
    _id,
    playerId,
  };
}

export function changeAdminVehicleNumberplate(
  _id: string,
  numberplate: string
) {
  return {
    type: type.ADMIN_VEHICLE_NUMBERPLATE_CHANGE_START,
    _id,
    numberplate,
  };
}
export function repairAdminVehicle(_id: string) {
  return {
    type: type.ADMIN_VEHICLE_REPAIR_START,
    _id,
  };
}

export function setAdminFactorySearch(search: string) {
  return {
    type: type.ADMIN_FACTORY_SEARCH_SET,
    search,
  };
}
export function startAdminFactorySearch(search: string) {
  return {
    type: type.ADMIN_FACTORY_SEARCH_FETCH_START,
    search,
  };
}
export function startAdminFactoryFetch(_id: string) {
  return {
    type: type.ADMIN_FACTORY_FETCH_START,
    _id,
  };
}
export function startAdminFactoryBankAccountFetch(_id: string) {
  return {
    type: type.ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_START,
    _id,
  };
}
export function startAdminFactoryVehiclesFetch(_id: string) {
  return {
    type: type.ADMIN_FACTORY_VEHICLES_FETCH_START,
    _id,
  };
}

export function startAdminFactoryOwnerSet(_id: string, ownerName: string) {
  return {
    type: type.ADMIN_FACTORY_OWNER_SET_START,
    _id,
    ownerName,
  };
}

export function startAdminFactoryReload(_id: string) {
  return {
    type: type.ADMIN_FACTORY_RELOAD_START,
    _id,
  };
}

export function setAdminApartmentSearch(search: string) {
  return {
    type: type.ADMIN_APARTMENT_SEARCH_SET,
    search,
  };
}
export function startAdminApartmentSearch(search: string) {
  return {
    type: type.ADMIN_APARTMENT_SEARCH_FETCH_START,
    search,
  };
}
export function startAdminApartmentFetch(_id: string) {
  return {
    type: type.ADMIN_APARTMENT_FETCH_START,
    _id,
  };
}
export function startAdminStateBankAccountsFetch() {
  return {
    type: type.ADMIN_STATEBANKACCOUNTS_FETCH_START,
  };
}

export function setAdminTicketsSearch(search: string) {
  return {
    type: type.ADMIN_TICKETS_SEARCH_SET,
    search,
  };
}
export function startAdminTicketsFetch(query: string, offset: number) {
  return {
    type: type.ADMIN_TICKETS_FETCH_START,
    query,
    offset,
  };
}
export function startAdminTicketDelete(
  _id: string,
  reason: string,
  search: string,
  offset: number
) {
  return {
    type: type.ADMIN_TICKET_DELETE_START,
    _id,
    reason,
    search,
    offset,
  };
}

export function setAdminDeathsSearch(search: string) {
  return {
    type: type.ADMIN_DEATHS_SEARCH_SET,
    search,
  };
}
export function startAdminDeathsFetch(query: string, offset: number) {
  return {
    type: type.ADMIN_DEATHS_FETCH_START,
    query,
    offset,
  };
}

export function setAdminLogsSearch(search: string) {
  return {
    type: type.ADMIN_LOGS_SEARCH_SET,
    search,
  };
}
export function startAdminLogsFetch(query: string, offset: number) {
  return {
    type: type.ADMIN_LOGS_FETCH_START,
    query,
    offset,
  };
}

export function setAdminBankAccountSearch(search: string) {
  return {
    type: type.ADMIN_BANKACCOUNTS_SEARCH_SET,
    search,
  };
}
export function startAdminBankAccountFetch(query: string, offset: number) {
  return {
    type: type.ADMIN_BANKACCOUNTS_FETCH_START,
    query,
    offset,
  };
}

export function startAdminBankAccountDetailFetch(_id: string) {
  return {
    type: type.ADMIN_BANKACCOUNT_DETAIL_FETCH_START,
    _id,
  };
}

export function startAdminBankAccountTransactionsFetch(
  _id: string,
  offset: number
) {
  return {
    type: type.ADMIN_BANKACCOUNT_TRANSACTIONS_FETCH_START,
    _id,
    offset,
  };
}

export function editAdminBankAccountBalance(_id: string, balance: number) {
  return {
    type: type.ADMIN_BANKACCOUNT_BALANCE_EDIT_START,
    _id,
    balance,
  };
}

export function makeAdminBankAccountMain(_id: string, shouldBeMain: boolean) {
  return {
    type: type.ADMIN_BANKACCOUNT_MAKE_MAIN_START,
    _id,
    state: shouldBeMain,
  };
}

export function startAdminLiveStatsFetch() {
  return {
    type: type.ADMIN_LIVESTATS_FETCH_START,
  };
}

export function startAdminMetersFetch(offset?: number, endDate?: Date) {
  return {
    type: type.ADMIN_METERS_FETCH_START,
    offset,
    endDate,
  };
}

export function startAdminLeogramPostsFetch(search: string, offset: number) {
  return {
    type: type.ADMIN_LEOGRAM_LIST_FETCH_START,
    offset,
    search,
  };
}

export function deleteAdminLeogramPostFetch(_id: string) {
  return {
    type: type.ADMIN_LEOGRAM_POST_DELETE_START,
    _id,
  };
}
export function rmAdAdminLeogramPostFetch(_id: string) {
  return {
    type: type.ADMIN_LEOGRAM_POST_RMAD_START,
    _id,
  };
}
