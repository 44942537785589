import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminVehicleSearch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_VEHICLES_SEARCH_FETCH_START),
    mapPromiseC(({ search }: any) => {
      return authFetchJson(`/admin/vehicle/search/${search}`)
        .then((data) => {
          return {
            type: types.ADMIN_VEHICLES_SEARCH_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_VEHICLES_SEARCH_FETCH_FAILED,
        }));
    })
  );
