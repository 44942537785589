import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';
import { find, pathEq, pathOr, pipe } from 'ramda';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { css } from '@emotion/react';
import {
  setAdminVehiclesSearch,
  startAdminVehiclesSearch,
} from '../../../actions';
import {
  appsAdminVehicleSearchResultsP,
  appsAdminVehiclesSearchP,
} from '../../../redux/reducer/apps/admin/vehiclesearch';
import { VehicleSearchEntryType } from './types/vehiclesearch';
import { translate } from '../../../utils/translate';

const exteriorColors = require('../../../resources/stores/mechanic/exterieurcolors');

const vehicleColor = (color: string) => css`
  width: 1.5vh;
  height: 1.5vh;
  display: inline-block;
  background-color: ${color};
  border-radius: 50%;
  margin-right: 0.5vh;
`;

const vehIconStyle = css`
  height: 3vh;
  display: inline;
  margin-right: 0.5vh;
`

type VehiclesType = {
  open: (_id: string, numberPlate: string) => void;
};
export const VehiclesSearch = ({ open }: VehiclesType) => {
  const dispatch = useDispatch();
  const search = useSelector<any, string>(pathOr('', appsAdminVehiclesSearchP));
  const results = useSelector<any, VehicleSearchEntryType[]>(
    pathOr([], appsAdminVehicleSearchResultsP)
  );

  const updateSearch = (ev: any) => {
    dispatch(setAdminVehiclesSearch(ev.target.value));
  };
  const executeSearch = () => {
    dispatch(startAdminVehiclesSearch(search));
  };

  return (
    <div>
      <h1>Fahrzeugsuche</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={updateSearch}
      />

      <Button variant="outlined" onClick={executeSearch}>
        Suche starten
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fahrzeugtyp</TableCell>
            <TableCell>Kennzeichen</TableCell>
            <TableCell>Lackierung</TableCell>
            <TableCell>Garage</TableCell>
            <TableCell>Besitzer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((entry) => {
            const firstColorValue = entry.tunning?.color?.first ?? 0;
            const firstColor =
              typeof firstColorValue === 'object'
                ? `rgb(${firstColorValue.r}, ${firstColorValue.b}, ${firstColorValue.g})`
                : pipe(
                    (x: number) =>
                      find(pathEq(['value'], x), exteriorColors.specialColors),
                    pathOr('#000', ['hex'])
                  )(firstColorValue);

            const secondColorValue = entry.tunning?.color?.second ?? 0;
            const secondColor =
              typeof secondColorValue === 'object'
                ? `rgb(${secondColorValue.r}, ${secondColorValue.b}, ${secondColorValue.g})`
                : pipe(
                    (x: number) =>
                      find(pathEq(['value'], x), exteriorColors.specialColors),
                    pathOr('#000', ['hex'])
                  )(secondColorValue);

            return (
              <TableRow
                key={entry._id}
                onClick={() => open(entry._id, entry.numberPlate)}
                css={{ cursor: 'pointer' }}
              >
                <TableCell><img src={`img/vehicles/${entry.name}.jpg`} css={vehIconStyle} />{entry.name}</TableCell>
                <TableCell>{entry.numberPlate}</TableCell>
                <TableCell>
                  <div css={() => vehicleColor(firstColor)} />
                  <div css={() => vehicleColor(secondColor)} />
                </TableCell>
                <TableCell>
                  {entry.inGarage && translate(entry.garagePosition)}
                </TableCell>
                <TableCell>
                  {entry.owner?.name ||
                    entry.team?.label ||
                    entry.factory?.label}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
