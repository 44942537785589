import controlCenterTemplate from '../../datasheets/templates/dmv/controlcenter';
import controlDatasheet from '../../datasheets/templates/dmv/control-datasheet';
import dashboardTemplate from '../../datasheets/templates/dmv/dashboard';
import leadTemplate from '../../datasheets/templates/dmv/lead';
import datesTemplate, {
  datesLineTemplate,
} from '../../datasheets/templates/dmv/dates';
import vehiclecontrolTemplate, {
  dmvVehicleControlLineTemplate,
} from '../../datasheets/templates/dmv/vehiclecontrol';

export const DMVAppSheets = [
  {
    sheetId: 'dmvdashboard',
    label: 'Dashboard',
    right: 'dmv.dashboard',
    config: {
      template: dashboardTemplate,
      requiredSheets: ['dmvlead', 'dmvcontroldata'],
    },
  },
  {
    sheetId: 'dmvcontrolcenter',
    label: 'Dienstblatt',
    right: 'dmv.controlcenter',
    config: {
      template: controlCenterTemplate,
      requiredSheets: ['dmvcontroldata'],
    },
  },
  {
    sheetId: 'dmvdatestheory',
    label: 'Termine Theory',
    right: 'dmv.datestheory',
    config: {
      template: datesTemplate('Termine Theory'),
      requiredSheets: ['dmvcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: datesLineTemplate,
    },
  },
  {
    sheetId: 'dmvdatespractical',
    label: 'Termine Praxis',
    right: 'dmv.datespratical',
    config: {
      template: datesTemplate('Termine Praxis'),
      requiredSheets: ['dmvcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: datesLineTemplate,
    },
  },
  {
    sheetId: 'dmvdatestaxi',
    label: 'Termine Beförderung',
    right: 'dmv.datestaxi',
    config: {
      template: datesTemplate('Termine Beförderung'),
      requiredSheets: ['dmvcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: datesLineTemplate,
    },
  },
  {
    sheetId: 'dmvvehiclecontrol',
    label: 'Fahrzeug Status',
    right: 'dmv.vehiclecontrol',
    config: {
      template: vehiclecontrolTemplate,
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: dmvVehicleControlLineTemplate,
    },
  },
  {
    sheetId: 'dmvlead',
    label: 'Lead',
    right: 'dmv.lead',
    config: {
      template: leadTemplate,
    },
  },
  {
    sheetId: 'dmvcontroldata',
    label: 'DB-Daten',
    right: 'dmv.controldata',
    config: {
      template: controlDatasheet,
    },
  },
];
