import { lensPath, pipe, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminFactoryBankAccountsP = [
  'apps',
  'admin',
  'factory',
  'bankAccounts',
];
export const appsAdminFactoryBankAccountsL = lensPath(
  appsAdminFactoryBankAccountsP
);
const setBankAccounts = (state: any, { data }: any) => {
  return set(appsAdminFactoryBankAccountsL, data, state);
};

export const appsAdminFactoryVehiclesP = [
  'apps',
  'admin',
  'factory',
  'vehicles',
];
export const appsAdminFactoryVehiclesL = lensPath(appsAdminFactoryVehiclesP);
const setVehicles = (state: any, { data }: any) => {
  return set(appsAdminFactoryVehiclesL, data, state);
};

export const appsAdminFactoryDetailP = ['apps', 'admin', 'factory', 'detail'];
export const appsAdminFactoryDetailL = lensPath(appsAdminFactoryDetailP);
const setDetail = (state: any, { data }: any) => {
  return pipe(
    set(appsAdminFactoryDetailL, data),
    set(appsAdminFactoryVehiclesL, undefined),
    set(appsAdminFactoryBankAccountsL, undefined)
  )(state);
};

export const appsAdminFactoryReducerTree = {
  [types.ADMIN_FACTORY_FETCH_SUCCESS]: [setDetail],
  [types.ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_SUCCESS]: [setBankAccounts],
  [types.ADMIN_FACTORY_VEHICLES_FETCH_SUCCESS]: [setVehicles],
};
