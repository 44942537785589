import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/ClearRounded';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import Headline from '../../Headline';
import { DateTime } from 'luxon';

const inputStyle = css`
  width: 100%;
  max-width: 30vh;
`;

const subHeadlineStyle = css`
  font-size: 1.5vh;
  margin-top: 1vh;
`;

export type TestsuiteResultEntry = {
  id: string;
  examiner: string;
  examinee: string;
  label: string;
  createdDate: string;
  submitted: boolean;
  submittedDate: string;
};

export type ResultsType = {
  fetchResults: (playerName?: string) => void;
  results: TestsuiteResultEntry[];
  fetchResult: (id: string) => void;
};

export const Results = ({
  fetchResults,
  results,
  fetchResult,
}: ResultsType) => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchResults();
  }, []);

  const onSearchInput = (key: string) => {
    if (key === 'Enter') {
      fetchResults(search);
    }
  };

  return (
    <div>
      <Headline>Ergebnisse</Headline>
      <h2 css={subHeadlineStyle}>Suche</h2>
      <TextField
        css={inputStyle}
        type="text"
        placeholder="Max_Mustermann"
        value={search}
        onChange={(ev) => setSearch(ev.target.value)}
        onKeyPress={(e) => onSearchInput(e.key)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              css={{ cursor: 'pointer' }}
              onClick={() => fetchResults(search)}
            >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Ausgeber</TableCell>
              <TableCell>Prüfling</TableCell>
              <TableCell>Abgabe</TableCell>
              <TableCell>Anschauen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result) => (
              <TableRow key={result.id}>
                <TableCell>{result.label}</TableCell>
                <TableCell>{result.examiner}</TableCell>
                <TableCell>{result.examinee}</TableCell>
                <TableCell>
                  {result.submitted ? (
                    DateTime.fromJSDate(new Date(result.submittedDate))
                      .setLocale('de')
                      .toFormat('dd.LL.yy, HH:mm')
                  ) : (
                    <ClearIcon />
                  )}
                </TableCell>
                <TableCell>
                  {result.submitted && (
                    <Button onClick={() => fetchResult(result.id)}>
                      Öffnen
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
