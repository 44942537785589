import React from 'react';
import { Headline } from './Headline';
import { Image } from './Image';
import { Inline } from './Inline';
import { Section } from './Section';

const typeMap: any = {
  h1: Headline,
  inline: Inline,
  section: Section,
  image: Image,
};

export const TextView = ({ contents }: any) => {
  return contents.map((entry: any, index: number) => {
    const Cmp = typeMap[entry.type];
    return Cmp ? <Cmp key={index} {...entry} /> : null;
  });
};
