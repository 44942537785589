import React from 'react';
import { css } from '@emotion/react';
import { useEffect } from 'react';
import VehicleCard from '../shared/VehicleCard'

const gridContainer = css`
  margin-top: 2vh !important;
  margin-bottom: 2vh !important;
  display: grid;
  grid-template-columns: repeat(auto-fill, 30vh);
  grid-gap: 1vh;
  justify-content: center;   
`;

interface VehicleData {
  _id: string;
  numberPlate: string;
  numberPlateBreakDown: boolean;
  distance: number; // in meteres
  name: string; // hash
  inGarage: boolean;
  garagePosition: string;
  constructionDate: string;
  owner?: { name: string };
  factory?: { label: string };
  tunning?: {
    color: {
      first: number | { r: number; g: number; b: number };
      second: number | { r: number; g: number; b: number };
    };
  };
  isPlayerOwner?: boolean;
  label?: string;
}

type MyVehiclesAppType = {
  dark: boolean;
  fetch: () => void;
  vehicles: VehicleData[];
  setWayPoint: (id: string) => void;
};
export const MyVehiclesApp = ({
  dark,
  fetch,
  vehicles,
  setWayPoint,
}: MyVehiclesAppType) => {
  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="w-full h-full bg-gray-50 text-gray-700 overflow-y-auto">
      <div css={gridContainer}>
        {vehicles.map((veh) => <VehicleCard vehicle={veh} setWayPoint={setWayPoint} dark={dark} />)}
      </div>
    </div>
  );
};
