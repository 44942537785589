const documents = require('../../../resources/items/processed/documents');

const docTypes = documents.filter(
  (entry: any) =>
    entry.hash.indexOf('A4') > -1 && entry.hash.indexOf('packaged') === -1
);

export const getDocTypes = (hasRight: (right: string) => boolean) => {
  return docTypes.filter((entry: any) => !entry.right || hasRight(entry.right));
};

export const getTemplateForDocType = (
  docType: string | undefined,
  version: number
) => {
  const itemConfig = docTypes.find((entry: any) => entry.hash === docType);

  return (
    itemConfig && {
      imgSrc:
        version === 2 && itemConfig.imgSrcV2
          ? itemConfig.imgSrcV2
          : itemConfig.imgSrc,
      css:
        version === 2 && itemConfig.cssV2 ? itemConfig.cssV2 : itemConfig.css,
    }
  );
};
