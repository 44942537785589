import { lensPath, set } from 'ramda';
import * as types from '../../types';

export const appsFractionPlayerStatusP = ['apps', 'fraction', 'playerstatus'];
export const appsFractionPlayerStatusL = lensPath(appsFractionPlayerStatusP);
const setAppsFractionPlayerStatus = (state: any, { data }: any) => {
  return set(appsFractionPlayerStatusL, data, state);
};

export const appsFractionMembersP = ['apps', 'fraction', 'members'];
export const appsFractionMembersL = lensPath(appsFractionMembersP);
const setAppsFractionMembers = (state: any, { data }: any) => {
  return set(appsFractionMembersL, data, state);
};

export const appsFractionSalaryP = ['apps', 'fraction', 'salary'];
export const appsFractionSalaryL = lensPath(appsFractionSalaryP);
const setAppsFractionSalary = (state: any, { data }: any) => {
  return set(appsFractionSalaryL, data, state);
};

export const appsFractionDutyListP = ['apps', 'fraction', 'dutyList'];
export const appsFractionDutyListL = lensPath(appsFractionDutyListP);
const setAppsFractionDutyList = (state: any, { data }: any) => {
  return set(appsFractionDutyListL, data, state);
};
export const appsFractionDutyListSearchP = [
  'apps',
  'fraction',
  'dutyListSearch',
];
export const appsFractionDutyListSearchL = lensPath(
  appsFractionDutyListSearchP
);
const setAppsFractionDutyListSearch = (state: any, { search }: any) => {
  return set(appsFractionDutyListSearchL, search, state);
};

export const appsFractionReducerTree = {
  [types.FRACTION_PLAYER_STATUS_FETCH_SUCCESS]: [setAppsFractionPlayerStatus],
  [types.FRACTION_EMPLOYEES_FETCH_SUCCESS]: [setAppsFractionMembers],
  [types.FRACTION_SALARY_FETCH_SUCCESS]: [setAppsFractionSalary],
  [types.FRACTION_DUTYLIST_FETCH_SUCCESS]: [setAppsFractionDutyList],
  [types.FRACTION_DUTYLIST_SEARCH_SET]: [setAppsFractionDutyListSearch],
};
