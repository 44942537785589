import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminRoles = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_ROLES_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/admin/permissions/roles')
        .then((data) => {
          return {
            type: types.ADMIN_ROLES_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_ROLES_FETCH_FAILED,
        }));
    })
  );

export const onFetchAdminAllRights = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_RIGHTS_ALL_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/admin/permissions/rights')
        .then((data) => {
          return {
            type: types.ADMIN_RIGHTS_ALL_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_RIGHTS_ALL_FETCH_FAILED,
        }));
    })
  );

export const onFetchAdminRoleAdd = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_ROLES_ADD_START),
    mapPromiseC(({ role }: any) => {
      return authFetchPost('/admin/permissions/roles/add', {
        role: role.key,
        rights: role.rights,
      })
        .then(() => {
          return [
            {
              type: types.ADMIN_ROLES_ADD_SUCCESS,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Rolle hinzugefügt',
            },
            { type: types.ADMIN_ROLES_FETCH_START },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_ROLES_ADD_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Erstellen der Rolle',
          },
        ]);
    })
  );

export const onFetchAdminRoleEdit = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_ROLES_EDIT_START),
    mapPromiseC(({ role }: any) => {
      return authFetchPost('/admin/permissions/roles/edit', {
        id: role._id,
        key: role.key,
        rights: role.rights,
      })
        .then(() => {
          return [
            {
              type: types.ADMIN_ROLES_EDIT_SUCCESS,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Rolle bearbeitet',
            },
            { type: types.ADMIN_ROLES_FETCH_START },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_ROLES_EDIT_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Bearbeiten der Rolle',
          },
        ]);
    })
  );

export const onFetchAdminRoleDelete = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_ROLES_DELETE_START),
    mapPromiseC(({ role }: any) => {
      return authFetchPost('/admin/permissions/roles/delete', { id: role._id })
        .then(() => {
          return [
            {
              type: types.ADMIN_ROLES_DELETE_SUCCESS,
            },
            {
              type: types.NOTIFY_SUCCESS,
              msg: 'Rolle gelöscht',
            },
            { type: types.ADMIN_ROLES_FETCH_START },
          ];
        })
        .catch(() => [
          {
            type: types.ADMIN_ROLES_DELETE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Löschen der Rolle',
          },
        ]);
    })
  );
