import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLspdOldFile } from '../../../actions';
import { lspdOldFileP } from '../../../redux/reducer/apps/lspd';
import { OldFileDetailsData } from './types';
import { DateTime } from 'luxon';
import { css } from '@emotion/react';

const containerStyle = css`
  margin-top: 3vh;
`;

type OldFileViewType = {
  _id: string;
};
export const OldFileView = ({ _id }: OldFileViewType) => {
  const dispatch = useDispatch();
  const data = useSelector<any, null | OldFileDetailsData>(
    pathOr(null, lspdOldFileP)
  );

  useEffect(() => {
    dispatch(fetchLspdOldFile(_id));
  }, [_id]);

  return data ? (
    <div>
      <div css={containerStyle}>
        <h2>Allgemein</h2>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>
                {data.closed ? '(CLOSED) ' : ''}
                {data.subject}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>
                {DateTime.fromJSDate(new Date(data.date))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Straftaten</TableCell>
              <TableCell>{data.offenses.join(', ')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Waffe</TableCell>
              <TableCell>{data.weapon}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Waffen Seriennummer</TableCell>
              <TableCell>{data.weaponSerial}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dateien</TableCell>
              <TableCell>
                {data.files.map((file, index) => (
                  <p key={index}>{file}</p>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div css={containerStyle}>
        <h2>Notizen</h2>
        {(data.notes || '-').split('\\n').map((entry, index) => (
          <p key={index}>{entry}</p>
        ))}
      </div>

      <div css={containerStyle}>
        <h2>Opfer</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Addresse</TableCell>
              <TableCell>Kennzeichen</TableCell>
              <TableCell>Telefonnummer</TableCell>
              <TableCell>Beschreibung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.victims.map((victim, index) => (
              <TableRow key={index}>
                <TableCell>{victim.name}</TableCell>
                <TableCell>{victim.address}</TableCell>
                <TableCell>{victim.numberplate}</TableCell>
                <TableCell>{victim.telnumber}</TableCell>
                <TableCell>{victim.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div css={containerStyle}>
        <h2>Täter</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Addresse</TableCell>
              <TableCell>Kennzeichen</TableCell>
              <TableCell>Telefonnummer</TableCell>
              <TableCell>Beschreibung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.offenders.map((offender, index) => (
              <TableRow key={index}>
                <TableCell>{offender.name}</TableCell>
                <TableCell>{offender.address}</TableCell>
                <TableCell>{offender.numberplate}</TableCell>
                <TableCell>{offender.telnumber}</TableCell>
                <TableCell>{offender.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div css={containerStyle}>
        <h2>Zeugen</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Addresse</TableCell>
              <TableCell>Kennzeichen</TableCell>
              <TableCell>Telefonnummer</TableCell>
              <TableCell>Beschreibung</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.witnesses.map((witness, index) => (
              <TableRow key={index}>
                <TableCell>{witness.name}</TableCell>
                <TableCell>{witness.address}</TableCell>
                <TableCell>{witness.numberplate}</TableCell>
                <TableCell>{witness.telnumber}</TableCell>
                <TableCell>{witness.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {data.judgements.map((judgement, index) => (
        <div css={containerStyle} key={index}>
          <h2>Urteil</h2>

          <div>Erledigt: {judgement.done ? 'Ja' : 'Nein'}</div>
          <div>
            Datum:{' '}
            {DateTime.fromJSDate(new Date(judgement.time))
              .setLocale('de')
              .toFormat('dd.LL.yy, HH:mm')}
          </div>
          <div>
            {(judgement.notes || '-').split('\\n').map((entry, index) => (
              <p key={index}>{entry}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
  ) : null;
};
