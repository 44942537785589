import { pathOr, find, pipe, uniq, slice } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pollPlayerOnDuty } from '../../../actions';
import { playerOnDutyP } from '../../../redux/reducer/apps/folder-groups';
import { Sidebar } from '../../sidebars/Sidebar';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { Datasheet } from '../../Datasheet/Datasheet';
import { css } from '@emotion/react';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import ArchiveIcon from '@mui/icons-material/Archive';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { LspdOverview } from './Overview';
import { LspdLiveMap } from './Livemap';
import { PersonSearch } from './PersonSearch';
import { PersonDetails } from './PersonDetails';
import { VehicleSearch } from './VehicleSearch';
import { WeaponSearch } from './WeaponSearch';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { LspdWantedList } from './WantedList';
import { LogBook } from './LogBook';
import { OldFiles } from './OldFiles';
import { OldFileView } from './OldFileView';
import { useAuthentication } from '../../../utils/authentication';
import { LSPDAppSheets } from '../../../resources/teams/cops/app-sheets';

const contentStyle = css`
  position: relative;
`;

const missingDutyContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2vh;
`;

const ICON_TYPE_MAP: any = {
  person: AccountCircleIcon,
  oldfile: MenuBookIcon,
};

type OpenedEntryType = { type: string; _id: string; label: string };

export const LspdApp = () => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const isOnDuty = useSelector(pathOr(false, playerOnDutyP));
  const [selectedMenu, setSelectedMenu] = useState('overview');
  const [openedEntries, setOpenedEntries] = useState<OpenedEntryType[]>([]);

  useEffect(() => {
    dispatch(pollPlayerOnDuty());
    const int = setInterval(() => {
      dispatch(pollPlayerOnDuty());
    }, 20000);
    return () => {
      clearInterval(int);
    };
  }, []);

  const createOpen = (type: string) => (_id: string, label: string) => {
    const newData = pipe<
      [OpenedEntryType[]],
      OpenedEntryType[],
      OpenedEntryType[],
      OpenedEntryType[]
    >(
      (x) => [
        {
          type,
          _id,
          label: label.length <= 13 ? label : label.substr(label.length - 13),
        },
        ...x,
      ],
      uniq,
      slice(0, 10)
    )(openedEntries);
    setOpenedEntries(newData);
    setSelectedMenu(`${type}-${_id}`);
  };
  const openPerson = createOpen('person');
  const openOldFile = createOpen('oldfile');

  const menu = [
    {
      id: 'overview',
      title: 'Übersicht',
      icon: <HomeIcon />,
    },
    {
      id: 'personsearch',
      title: 'Personensuche',
      icon: <AccountCircleIcon />,
    },
    {
      id: 'vehiclesearch',
      title: 'Fahrzeugsuche',
      icon: <DirectionsCarIcon />,
    },
    {
      id: 'weaponsearch',
      title: 'Waffensuche',
      icon: <LocalDiningIcon />,
    },
    {
      id: 'wantedlist',
      title: 'Wanted Liste',
      icon: <LocationSearchingIcon />,
    },
    {
      id: 'livemap',
      title: 'Livekarte',
      icon: <MapIcon />,
    },
    {
      id: 'logs',
      title: 'Logs',
      icon: <MenuBookIcon />,
      right: 'lspd.logs',
    },
    {
      id: 'oldfiles',
      title: 'Archiv',
      icon: <ArchiveIcon />,
      right: 'lspd.oldFiles',
    },
    ...LSPDAppSheets.map((entry) => ({
      id: `datasheet-${entry.sheetId}`,
      title: entry.label,
      icon: <ControlCameraIcon />,
      right: entry.right,
    })),
    ...openedEntries.map((entry) => {
      const Icon = ICON_TYPE_MAP[entry.type] || ICON_TYPE_MAP.default;
      return {
        id: `${entry.type}-${entry._id}`,
        title: `${entry.label}`,
        icon: <Icon />,
      };
    }),
  ].filter((entry: any) => !entry.right || hasRight(entry.right));

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      {isOnDuty ? (
        <>
          <div className="flex-none w-44">
            <Sidebar
              items={menu}
              selected={selectedMenu}
              select={setSelectedMenu}
            />
          </div>
          <div className="flex-grow">
            <div
              className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6"
              css={contentStyle}
            >
              {selectedMenu === 'overview' && <LspdOverview />}
              {selectedMenu === 'personsearch' && (
                <PersonSearch openPerson={openPerson} />
              )}
              {selectedMenu === 'vehiclesearch' && (
                <VehicleSearch openPerson={openPerson} />
              )}
              {selectedMenu === 'weaponsearch' && (
                <WeaponSearch openPerson={openPerson} />
              )}
              {selectedMenu === 'wantedlist' && (
                <LspdWantedList openPerson={openPerson} />
              )}
              {selectedMenu === 'logs' && <LogBook />}
              {selectedMenu === 'livemap' && <LspdLiveMap />}
              {selectedMenu === 'oldfiles' && (
                <OldFiles openOldFile={openOldFile} />
              )}
              {selectedMenu.startsWith('datasheet-') && (
                <Datasheet
                  sheetId={selectedMenu.replace('datasheet-', '')}
                  config={
                    find<any>(
                      (x: any) =>
                        x.sheetId === selectedMenu.replace('datasheet-', ''),
                      LSPDAppSheets
                    ).config
                  }
                />
              )}
              {selectedMenu.startsWith('person-') && (
                <PersonDetails
                  _id={selectedMenu.replace('person-', '')}
                  openMenu={setSelectedMenu}
                />
              )}
              {selectedMenu.startsWith('oldfile-') && (
                <OldFileView
                  _id={
                    find<any>(
                      (x: OpenedEntryType) =>
                        x._id === selectedMenu.replace('oldfile-', ''),
                      openedEntries
                    )._id
                  }
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          css={missingDutyContainerStyle}
          className="w-full h-full bg-gray-50 text-gray-700"
        >
          Nur im Dienst verfügbar
        </div>
      )}
    </div>
  );
};
