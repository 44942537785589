import { appsAdminRestartReducerTree } from './restart';
import { appsAdminRolesReducerTree } from './roles';
import { appsAdminUserRightsReducerTree } from './userrights';
import { appsAdminPlayerSearchReducerTree } from './playersearch';
import { appsAdminPlayerReducerTree } from './player';
import { appsAdminVehicleSearchReducerTree } from './vehiclesearch';
import { appsAdminVehicleReducerTree } from './vehicle';
import { appsAdminFactorySearchReducerTree } from './factorysearch';
import { appsAdminFactoryReducerTree } from './factory';
import { appsAdminApartmentSearchReducerTree } from './apartmentssearch';
import { appsAdminApartmentReducerTree } from './apartment';
import { appsAdminStateBankAccountsReducerTree } from './statebankaccounts';
import { appsAdminTicketsReducerTree } from './tickets';
import { appsAdminDeathsReducerTree } from './deaths';
import { appsAdminLogsReducerTree } from './logs';
import { appsAdminBankAccountsSearchReducerTree } from './bankaccountsearch';
import { appsAdminBankAccountReducerTree } from './bankaccount';
import { appsAdminLiveStatsReducerTree } from './livestats';
import { appsAdminMetersReducerTree } from './meters';
import { appsAdminLeogramTree } from './leogram';

export const appsAdminReducerTree = {
  ...appsAdminRestartReducerTree,
  ...appsAdminRolesReducerTree,
  ...appsAdminUserRightsReducerTree,
  ...appsAdminPlayerSearchReducerTree,
  ...appsAdminPlayerReducerTree,
  ...appsAdminVehicleSearchReducerTree,
  ...appsAdminVehicleReducerTree,
  ...appsAdminFactorySearchReducerTree,
  ...appsAdminFactoryReducerTree,
  ...appsAdminApartmentSearchReducerTree,
  ...appsAdminApartmentReducerTree,
  ...appsAdminStateBankAccountsReducerTree,
  ...appsAdminTicketsReducerTree,
  ...appsAdminDeathsReducerTree,
  ...appsAdminLogsReducerTree,
  ...appsAdminBankAccountsSearchReducerTree,
  ...appsAdminBankAccountReducerTree,
  ...appsAdminLiveStatsReducerTree,
  ...appsAdminMetersReducerTree,
  ...appsAdminLeogramTree,
};
