const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fix = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const select = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const usedFix = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const usedRight = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'text-align': 'right',
  color: '#fff',
  border: '0 !important',
};
export default [
  [
    {
      value: 'Stationäre Aufnahme',
      colSpan: 9,
      style: fix,
      readOnly: true,
      width: '180vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 9,
      style: empty,
      readOnly: true,
      width: '180vh',
    },
  ],
  [
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },

    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 9,
      style: empty,
      readOnly: true,
      width: '180vh',
    },
  ],
  [
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },

    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 9,
      style: empty,
      readOnly: true,
      width: '180vh',
    },
  ],
  [
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },

    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 9,
      style: empty,
      readOnly: true,
      width: '180vh',
    },
  ],
  [
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },

    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 9,
      style: empty,
      readOnly: true,
      width: '180vh',
    },
  ],
  [
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Zimmer:',
      style: fix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '-HIER EINTARGEN-',
      style: fix,
      colSpan: 1,
      width: '20vh',
    },
    {
      value: '',
      style: fix,
      colSpan: 2,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patientename:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
    {
      value: 'Diagnose / Informationen:',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Eingewiesen durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Behandlung durch:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Patient entlassen am:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'um (Uhrzeit):',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 1,
      style: usedFix,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },

    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Akte geschrieben',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'von:',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
  [
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '20vh',
    },
    {
      value: false,
      type: 'checkbox',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '',
      style: usedFix,
      colSpan: 1,
      readOnly: true,
      width: '20vh',
      type: 'select',
      depOptions: {
        sheetId: 'lsmdcontroldata',
        column: 'E',
        rowStart: 1,
        rowEnd: 110,
      },
    },
    {
      value: '',
      colSpan: 2,
      style: usedFix,
      width: '40vh',
    },
  ],
];
