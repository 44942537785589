import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLspdMapAnkleMonitor,
  fetchLspdMapCalls,
  fetchLspdMapVehicles,
} from '../../../actions';
import {
  lspdMapAnkleMonitorP,
  lspdMapCallsP,
  lspdMapVehiclesP,
} from '../../../redux/reducer/apps/lspd';
import { GtaMap } from '../../shared/GtaMap';
import { MapCallData, MapVehicleData, MapAnkleMonitorData } from './types';
import { DateTime } from 'luxon';
import { useAuthentication } from '../../../utils/authentication';

export const LspdLiveMap = () => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const vehicles = useSelector<any, MapVehicleData[]>(
    pathOr([], lspdMapVehiclesP)
  );
  const calls = useSelector<any, MapCallData[]>(pathOr([], lspdMapCallsP));
  const ankleMonitor = useSelector<any, MapAnkleMonitorData[]>(
    pathOr([], lspdMapAnkleMonitorP)
  );

  const fetchData = () => {
    dispatch(fetchLspdMapVehicles());
    dispatch(fetchLspdMapCalls());
    if (hasRight('lspd.map.anklemonitor')) {
      dispatch(fetchLspdMapAnkleMonitor());
    }
  };

  useEffect(() => {
    fetchData();
    const int = setInterval(() => {
      fetchData();
    }, 10000);
    return () => {
      clearInterval(int);
    };
  }, []);

  const markers = [
    ...calls.map((x) => ({
      x: x.position.x,
      y: x.position.y,
      title:
        DateTime.fromJSDate(new Date(x.date))
          .setLocale('de')
          .toFormat('dd.LL.yy, HH:mm') +
        ' ' +
        x.number,
      id: x._id,
      icon: '/img/map/icons/emergency.png',
    })),
    ...vehicles.map((x) => ({
      x: x.position.x,
      y: x.position.y,
      title: x.numberplate,
      id: x._id,
      icon: '/img/map/icons/policecar.png',
    })),
    ...ankleMonitor.map((x) => ({
      x: x.position.x,
      y: x.position.y,
      title: x.name,
      id: x._id,
      icon: '/img/map/icons/anklemonitor.png',
    })),
  ];

  return <GtaMap markers={markers} />;
};
