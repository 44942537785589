import { values } from 'ramda';

import * as login from './login';
import * as register from './register';
import * as post from './post';
import * as search from './search';
import * as profilepage from './profilepage';
import * as screenshot from './screenshot';
import * as comments from './comments';
import * as activity from './activity';
import * as ads from './ads';

export default [
  ...values(login),
  ...values(register),
  ...values(post),
  ...values(search),
  ...values(profilepage),
  ...values(screenshot),
  ...values(comments),
  ...values(activity),
  ...values(ads),
];
