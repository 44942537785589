import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import {
  authFetch,
  authFetchDelete,
  authFetchJson,
  authFetchPost,
} from '../../../utils/fetch';
import { notifyError, notifySuccess } from '../../utils';

export const onFetchAdminLeogramPosts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_LEOGRAM_LIST_FETCH_START),
    mapPromiseC(({ offset, search }: any) => {
      return authFetchJson(
        `/admin/leogram/posts/list?skip=${offset}&search=${search}`
      )
        .then((data) => {
          return {
            type: types.ADMIN_LEOGRAM_LIST_FETCH_SUCCESS,
            data,
            offset,
          };
        })
        .catch(() => {});
    })
  );

export const onAdminDeleteLeogramPost = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_LEOGRAM_POST_DELETE_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchDelete(`/admin/leogram/posts/delete/${_id}`, {})
        .then(() => [
          notifySuccess('Post gelöscht'),
          {
            type: types.ADMIN_LEOGRAM_LIST_FETCH_START,
            offset: 0,
            search: '',
          },
        ])
        .catch(() => notifyError('Löschen fehlgeschlagen'));
    })
  );

export const onAdminRmAdLeogramPost = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_LEOGRAM_POST_RMAD_START),
    mapPromiseC(({ _id }: any) => {
      return authFetch(`/admin/leogram/posts/rm-ad/${_id}`, {})
        .then(() => [
          notifySuccess('Werbung aus Post gelöscht'),
          {
            type: types.ADMIN_LEOGRAM_LIST_FETCH_START,
            offset: 0,
            search: '',
          },
        ])
        .catch(() => notifyError('Werbung Löschen fehlgeschlagen'));
    })
  );
