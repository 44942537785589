import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminApartmentSearchP = [
  'apps',
  'admin',
  'apartmentsearch',
  'search',
];
export const appsAdminApartmentSearchL = lensPath(appsAdminApartmentSearchP);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminApartmentSearchL, search, state);
};

export const appsAdminApartmentSearchResultsP = [
  'apps',
  'admin',
  'apartmentsearch',
  'results',
];
export const appsAdminApartmentSearchResultsL = lensPath(
  appsAdminApartmentSearchResultsP
);
const setSearchResults = (state: any, { data }: any) => {
  return set(appsAdminApartmentSearchResultsL, data, state);
};

export const appsAdminApartmentSearchReducerTree = {
  [types.ADMIN_APARTMENT_SEARCH_SET]: [setSearch],
  [types.ADMIN_APARTMENT_SEARCH_FETCH_SUCCESS]: [setSearchResults],
};
