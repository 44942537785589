import { set, lensPath, pathOr } from 'ramda';

import * as types from '../types';

export const activityNewP = ['leogram', 'activity', 'new'];
export const activityNewL = lensPath(activityNewP);

const setComments = (state: any, { newAmount }: any) => {
  return set(activityNewL, newAmount, state);
};

export const activityDataP = ['leogram', 'activity', 'data'];
export const activityDataL = lensPath(activityDataP);

const setData = (state: any, { data, offset }: any) => {
  const old = pathOr([], activityDataP, state);
  const new_ = offset === 0 ? data : [...old, ...data];
  return set(activityDataL, new_, state);
};

export const activityReducerTree = {
  [types.ACTIVITY_UNREAD_FETCH_SUCCESS]: [setComments],
  [types.ACTIVITY_NEW_FETCH_SUCCESS]: [setData],
};
