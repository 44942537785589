import { DocumentType } from '../components/apps/Documents/types';
import { SCREENSHOT_CREATE_START } from '../leogram/store/types';
import * as type from '../redux/types';

export function getMyDocuments(page: number, search: string) {
  return {
    type: type.DOCUMENTS_FETCH_START,
    page,
    search,
  };
}

export function setDocumentsSearch(search: string) {
  return {
    type: type.DOCUMENT_SEARCH_SET,
    search,
  };
}

export function createNewDocument() {
  return {
    type: type.DOCUMENT_NEW_CREATE,
  };
}

export function createDuplicateDocument(
  subject: string,
  pages: any[],
  itemHash: string
) {
  return {
    type: type.DOCUMENT_DUPLICATE_CREATE,
    subject,
    pages,
    itemHash,
  };
}

export function getDocument(_id: string) {
  return {
    type: type.DOCUMENT_FETCH_START,
    _id,
  };
}

export function saveDocument(document: DocumentType) {
  return {
    type: type.DOCUMENT_CREATE_START,
    document,
  };
}

export function selectDocumentTab(tab: string) {
  return {
    type: type.DOCUMENT_TAB_SELECT,
    tab,
  };
}

export function closeDocument(_id: string) {
  return {
    type: type.DOCUMENT_RAW_REMOVE,
    _id,
  };
}

export function printDocument(_id: string) {
  return {
    type: type.DOCUMENT_PRINT_START,
    _id,
  };
}

export function deleteDocument(_id: string) {
  return {
    type: type.DOCUMENT_DELETE_START,
    _id,
  };
}

export function fetchDocumentHistory(_id: string) {
  return {
    type: type.DOCUMENT_HISTORY_FETCH_START,
    _id,
  };
}

export function fetchDocumentHistoryEntry(
  documentId: string,
  historyId: string
) {
  return {
    type: type.DOCUMENT_HISTORY_ENTRY_FETCH_START,
    documentId,
    historyId,
  };
}

export const createDocumentScreenshot = (base64: string) => ({
  type: SCREENSHOT_CREATE_START,
  name: localStorage.getItem('username'),
  base64: base64.replace('data:image/png;base64,', ''),
  pos: { x: 0, y: 0, z: 0 },
  successNotify: true,
});

export const startDocumentScreenshot = () => ({
  type: type.DOCUMENT_SCREENSHOT_START,
});
