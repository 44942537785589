import * as type from '../redux/types';

export function startMailSend(
  receivers: string[],
  subject: string,
  body: string
) {
  return {
    type: type.MAIL_SEND_START,
    receivers,
    subject,
    body,
  };
}

export function startInboxFetch(search: string, page: number) {
  return {
    type: type.MAIL_INBOX_FETCH_START,
    search,
    page,
  };
}

export function startOutboxFetch(search: string, page: number) {
  return {
    type: type.MAIL_OUTBOX_FETCH_START,
    search,
    page,
  };
}

export function startTrashFetch(search: string, page: number) {
  return {
    type: type.MAIL_TRASH_FETCH_START,
    search,
    page,
  };
}

export function startMailDelete(id: string, followAction: any) {
  return {
    type: type.MAIL_DELETE_START,
    id,
    followAction,
  };
}

export function startMailRead(id: string, followAction: any) {
  return {
    type: type.MAIL_READ_START,
    id,
    followAction,
  };
}
