module.exports = {
  paint: {
    0: 'paintRed',
    60: 'paintYellow',
    120: 'paintGreen',
    180: 'paintCyan',
    240: 'paintBlue',
    300: 'paintPurple',
    360: 'paintRed',
  },
  specialColors: [
    {
      label: 'Black',
      value: 0,
      hex: '#080808',
      requiredItems: [
        { item: 'paintBlack', amount: 4.8 },
        { item: 'paintWhite', amount: 0.2 },
      ],
    },
    {
      label: 'Graphite',
      value: 1,
      hex: '#0F0F0F',
      requiredItems: [
        { item: 'paintBlack', amount: 4.6 },
        { item: 'paintWhite', amount: 0.4 },
      ],
    },
    {
      label: 'Black Steel',
      value: 2,
      hex: '#1C1E21',
      requiredItems: [
        { item: 'paintBlack', amount: 4.4 },
        { item: 'paintWhite', amount: 0.6 },
      ],
    },
    {
      label: 'Dark Steel',
      value: 3,
      hex: '#292C2E',
      requiredItems: [
        { item: 'paintBlack', amount: 4.2 },
        { item: 'paintWhite', amount: 0.8 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Silver',
      value: 4,
      hex: '#5A5E66',
      requiredItems: [
        { item: 'paintBlack', amount: 4.0 },
        { item: 'paintWhite', amount: 0.9 },
        { item: 'paintBlue', amount: 0.1 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Bluish Silver',
      value: 5,
      hex: '#777C87',
      requiredItems: [
        { item: 'paintBlack', amount: 3.8 },
        { item: 'paintWhite', amount: 1.1 },
        { item: 'paintBlue', amount: 0.1 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Rolled Steel',
      value: 6,
      hex: '#515459',
      requiredItems: [
        { item: 'paintBlack', amount: 4.1 },
        { item: 'paintWhite', amount: 0.8 },
        { item: 'paintBlue', amount: 0.1 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Shadow Silver',
      value: 7,
      hex: '#323B47',
      requiredItems: [
        { item: 'paintBlack', amount: 4.1 },
        { item: 'paintWhite', amount: 0.4 },
        { item: 'paintBlue', amount: 0.4 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Stone Silver',
      value: 8,
      hex: '#333333',
      requiredItems: [
        { item: 'paintBlack', amount: 3.5 },
        { item: 'paintWhite', amount: 1.5 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Midnight Silver',
      value: 9,
      hex: '#1F2226',
      requiredItems: [
        { item: 'paintBlack', amount: 4.2 },
        { item: 'paintBlue', amount: 0.5 },
        { item: 'paintWhite', amount: 0.3 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Cast Iron Silver',
      value: 10,
      hex: '#23292E',
      requiredItems: [
        { item: 'paintBlack', amount: 4.1 },
        { item: 'paintBlue', amount: 0.6 },
        { item: 'paintWhite', amount: 0.3 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Anthracite Black',
      value: 11,
      hex: '#121110',
      requiredItems: [
        { item: 'paintBlack', amount: 4 },
        { item: 'paintRed', amount: 0.3 },
        { item: 'paintBlue', amount: 0.2 },
        { item: 'paintWhite', amount: 0.4 },
      ],
    },
    {
      label: 'Matte Black',
      value: 12,
      hex: '#050505',
      requiredItems: [
        { item: 'paintBlack', amount: 5 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'Matte Grey',
      value: 13,
      hex: '#121212',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 4 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'Matte Light Grey',
      value: 14,
      hex: '#2F3233',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlack', amount: 3 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'Util Black',
      value: 15,
      hex: '#080808',
      requiredItems: [
        { item: 'paintBlack', amount: 5 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Util Black Poly',
      value: 16,
      hex: '#121212',
      requiredItems: [
        { item: 'paintBlack', amount: 5 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Dark silver',
      value: 17,
      hex: '#202224',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 4 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Silver',
      value: 18,
      hex: '#575961',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintBlack', amount: 2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Gun Metal',
      value: 19,
      hex: '#23292E',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlack', amount: 2 },
        { item: 'paintBlue', amount: 1 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Shadow Silver',
      value: 20,
      hex: '#323B47',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlack', amount: 1 },
        { item: 'paintBlue', amount: 2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Black',
      value: 21,
      hex: '#0F1012',
      requiredItems: [
        { item: 'paintBlack', amount: 5 },
        { item: 'coalDust', amount: 20 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Worn Graphite',
      value: 22,
      hex: '#212121',
      requiredItems: [
        { item: 'paintBlack', amount: 5 },
        { item: 'coalDust', amount: 20 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Worn Silver Grey',
      value: 23,
      hex: '#5B5D5E',
      requiredItems: [
        { item: 'paintWhite', amount: 4 },
        { item: 'paintBlack', amount: 1 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Worn Silver',
      value: 24,
      hex: '#888A99',
      requiredItems: [
        { item: 'paintWhite', amount: 5 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 40 },
      ],
    },
    {
      label: 'Worn Blue Silver',
      value: 25,
      hex: '#697187',
      requiredItems: [
        { item: 'paintWhite', amount: 4 },
        { item: 'paintBlue', amount: 1 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Worn Shadow Silver',
      value: 26,
      hex: '#3B4654',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintBlue', amount: 2 },
        { item: 'coalDust', amount: 20 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Red',
      value: 27,
      hex: '#690000',
      requiredItems: [
        { item: 'paintBlack', amount: 3.3 },
        { item: 'paintRed', amount: 1.7 },
      ],
    },
    {
      label: 'Torino Red',
      value: 28,
      hex: '#8A0B00',
      requiredItems: [
        { item: 'paintRed', amount: 4.3 },
        { item: 'paintWhite', amount: 0.5 },
        { item: 'paintGreen', amount: 0.2 },
      ],
    },
    {
      label: 'Formula Red',
      value: 29,
      hex: '#6B0000',
      requiredItems: [
        { item: 'paintBlack', amount: 3.1 },
        { item: 'paintRed', amount: 1.9 },
      ],
    },
    {
      label: 'Blaze Red',
      value: 30,
      hex: '#611009',
      requiredItems: [
        { item: 'paintRed', amount: 4.5 },
        { item: 'paintWhite', amount: 0.2 },
        { item: 'paintGreen', amount: 0.2 },
        { item: 'paintBlue', amount: 0.1 },
      ],
    },
    {
      label: 'Grace Red',
      value: 31,
      hex: '#4A0A0A',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintGreen', amount: 0.1 },
        { item: 'paintBlue', amount: 0.1 },
        { item: 'paintRed', amount: 1.8 },
      ],
    },
    {
      label: 'Garnet Red',
      value: 32,
      hex: '#470E0E',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintGreen', amount: 0.2 },
        { item: 'paintBlue', amount: 0.2 },
        { item: 'paintRed', amount: 1.6 },
      ],
    },
    {
      label: 'Sunset Red',
      value: 33,
      hex: '#380C00',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintGreen', amount: 0.2 },
        { item: 'paintRed', amount: 1.8 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Cabernet Red',
      value: 34,
      hex: '#26030B',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintGreen', amount: 0.2 },
        { item: 'paintRed', amount: 1 },
        { item: 'paintBlue', amount: 0.8 },
      ],
    },
    {
      label: 'Candy Red',
      value: 35,
      hex: '#630012',
      requiredItems: [
        { item: 'paintRed', amount: 1.5 },
        { item: 'paintBlue', amount: 0.5 },
        { item: 'paintWhite', amount: 3 },
      ],
    },
    {
      label: 'Sunrise Orange',
      value: 36,
      hex: '#802800',
      requiredItems: [
        { item: 'paintRed', amount: 3 },
        { item: 'paintYellow', amount: 1.8 },
        { item: 'paintBlack', amount: 0.2 },
      ],
    },
    {
      label: 'Gold',
      value: 37,
      hex: '#6E4F2D',
      requiredItems: [
        { item: 'paintRed', amount: 2.5 },
        { item: 'paintGreen', amount: 0.9 },
        { item: 'paintBlue', amount: 0.6 },
        { item: 'goldDust', amount: 30 },
      ],
    },
    {
      label: 'Orange',
      value: 38,
      hex: '#BD4800',
      requiredItems: [
        { item: 'paintRed', amount: 3 },
        { item: 'paintYellow', amount: 1.7 },
        { item: 'paintWhite', amount: 0.3 },
        { item: 'goldDust', amount: 10 },
      ],
    },
    {
      label: 'Matte Red',
      value: 39,
      hex: '#780000',
      requiredItems: [
        { item: 'paintRed', amount: 5 },
        { item: 'coalDust', amount: 10 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Matte Dark Red',
      value: 40,
      hex: '#360000',
      requiredItems: [
        { item: 'paintBlack', amount: 1 },
        { item: 'paintRed', amount: 4 },
        { item: 'coalDust', amount: 10 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Matte Orange',
      value: 41,
      hex: '#AB3F00',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 2.5 },
        { item: 'paintYellow', amount: 1.5 },
        { item: 'coalDust', amount: 10 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Matte Yellow',
      value: 42,
      hex: '#DE7E00',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 2 },
        { item: 'paintYellow', amount: 2 },
        { item: 'coalDust', amount: 10 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Util Red',
      value: 43,
      hex: '#520000',
      requiredItems: [
        { item: 'paintRed', amount: 5 },
        { item: 'goldDust', amount: 30 },
      ],
    },
    {
      label: 'Util Bright Red',
      value: 44,
      hex: '#8C0404',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 4 },
        { item: 'goldDust', amount: 30 },
      ],
    },
    {
      label: 'Util Garnet Red',
      value: 45,
      hex: '#4A1000',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 1 },
        { item: 'paintRed', amount: 3 },
        { item: 'goldDust', amount: 30 },
      ],
    },
    {
      label: 'Worn Red',
      value: 46,
      hex: '#592525',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintRed', amount: 3 },
        { item: 'silverDust', amount: 20 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Golden Red',
      value: 47,
      hex: '#754231',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintRed', amount: 2 },
        { item: 'silverDust', amount: 20 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Dark Red',
      value: 48,
      hex: '#210804',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 1 },
        { item: 'paintRed', amount: 3 },
        { item: 'silverDust', amount: 20 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Dark Green',
      value: 49,
      hex: '#001207',
      requiredItems: [
        { item: 'paintBlack', amount: 3.5 },
        { item: 'paintGreen', amount: 1.3 },
        { item: 'paintBlue', amount: 0.2 },
      ],
    },
    {
      label: 'Racing Green',
      value: 50,
      hex: '#001A0B',
      requiredItems: [
        { item: 'paintBlack', amount: 3.2 },
        { item: 'paintGreen', amount: 1.5 },
        { item: 'paintBlue', amount: 0.3 },
      ],
    },
    {
      label: 'Sea Green',
      value: 51,
      hex: '#00211E',
      requiredItems: [
        { item: 'paintBlack', amount: 3.2 },
        { item: 'paintGreen', amount: 1.2 },
        { item: 'paintCyan', amount: 0.6 },
      ],
    },
    {
      label: 'Olive Green',
      value: 52,
      hex: '#1F261E',
      requiredItems: [
        { item: 'paintBlack', amount: 2 },
        { item: 'paintGreen', amount: 1.8 },
        { item: 'paintBlue', amount: 0.2 },
        { item: 'paintWhite', amount: 1 },
      ],
    },
    {
      label: 'Bright Green',
      value: 53,
      hex: '#003805',
      requiredItems: [
        { item: 'paintGreen', amount: 3.6 },
        { item: 'paintWhite', amount: 1.3 },
        { item: 'paintBlue', amount: 0.1 },
      ],
    },
    {
      label: 'Gasoline Green',
      value: 54,
      hex: '#0B4145',
      requiredItems: [
        { item: 'paintCyan', amount: 2.2 },
        { item: 'paintGreen', amount: 1.5 },
        { item: 'paintWhite', amount: 1.3 },
      ],
    },
    {
      label: 'Matte Lime Green',
      value: 55,
      hex: '#418503',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintGreen', amount: 3 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Dark Green',
      value: 56,
      hex: '#0F1F15',
      requiredItems: [
        { item: 'paintBlack', amount: 2 },
        { item: 'paintGreen', amount: 3 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Util Green',
      value: 57,
      hex: '#023613',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 1 },
        { item: 'paintGreen', amount: 3 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Worn Dark Green',
      value: 58,
      hex: '#162419',
      requiredItems: [
        { item: 'paintBlack', amount: 2 },
        { item: 'paintGreen', amount: 3 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Green',
      value: 59,
      hex: '#2A3625',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 1 },
        { item: 'paintGreen', amount: 3 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Sea Wash',
      value: 60,
      hex: '#455C56',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 2 },
        { item: 'paintGreen', amount: 2 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Galaxy Blue',
      value: 61,
      hex: '#000D14',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintBlue', amount: 1.5 },
        { item: 'paintWhite', amount: 0.5 },
      ],
    },
    {
      label: 'Dark Blue',
      value: 62,
      hex: '#001029',
      requiredItems: [
        { item: 'paintBlack', amount: 2.6 },
        { item: 'paintBlue', amount: 1.5 },
        { item: 'paintWhite', amount: 0.5 },
        { item: 'paintGreen', amount: 0.4 },
      ],
    },
    {
      label: 'Saxon Blue',
      value: 63,
      hex: '#1C2F4F',
      requiredItems: [
        { item: 'paintBlue', amount: 4 },
        { item: 'paintGreen', amount: 0.3 },
        { item: 'paintRed', amount: 0.2 },
        { item: 'paintWhite', amount: 0.5 },
      ],
    },
    {
      label: 'Blue',
      value: 64,
      hex: '#001B57',
      requiredItems: [
        { item: 'paintBlue', amount: 4.4 },
        { item: 'paintGreen', amount: 0.3 },
        { item: 'paintWhite', amount: 0.3 },
      ],
    },
    {
      label: 'Mariner Blue',
      value: 65,
      hex: '#3B4E78',
      requiredItems: [
        { item: 'paintBlue', amount: 3.8 },
        { item: 'paintGreen', amount: 0.4 },
        { item: 'paintRed', amount: 0.2 },
        { item: 'paintWhite', amount: 0.6 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Harbor Blue',
      value: 66,
      hex: '#272D3B',
      requiredItems: [
        { item: 'paintBlack', amount: 3.8 },
        { item: 'paintBlue', amount: 0.8 },
        { item: 'paintWhite', amount: 0.4 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Diamond Blue',
      value: 67,
      hex: '#95B2DB',
      requiredItems: [
        { item: 'paintWhite', amount: 4.5 },
        { item: 'paintBlue', amount: 0.5 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Surf Blue',
      value: 68,
      hex: '#3E627A',
      requiredItems: [
        { item: 'paintWhite', amount: 4.3 },
        { item: 'paintBlue', amount: 0.7 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Nautical Blue',
      value: 69,
      hex: '#1C3140',
      requiredItems: [
        { item: 'paintWhite', amount: 3.8 },
        { item: 'paintBlue', amount: 1 },
        { item: 'paintBlack', amount: 0.2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Ultra Blue',
      value: 70,
      hex: '#0055C4',
      requiredItems: [
        { item: 'paintBlue', amount: 4 },
        { item: 'paintGreen', amount: 0.6 },
        { item: 'paintWhite', amount: 0.4 },
      ],
    },
    {
      label: 'Schafter Purple',
      value: 71,
      hex: '#1A182E',
      requiredItems: [
        { item: 'paintBlack', amount: 2.8 },
        { item: 'paintBlue', amount: 0.7 },
        { item: 'paintRed', amount: 0.5 },
        { item: 'paintGreen', amount: 0.5 },
        { item: 'paintWhite', amount: 0.5 },
      ],
    },
    {
      label: 'Spinnaker Purple',
      value: 72,
      hex: '#161629',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintBlue', amount: 0.7 },
        { item: 'paintRed', amount: 0.5 },
        { item: 'paintGreen', amount: 0.5 },
        { item: 'paintWhite', amount: 0.3 },
      ],
    },
    {
      label: 'Racing Blue',
      value: 73,
      hex: '#0E316D',
      requiredItems: [
        { item: 'paintBlue', amount: 3.8 },
        { item: 'paintGreen', amount: 0.4 },
        { item: 'paintRed', amount: 0.3 },
        { item: 'paintWhite', amount: 0.5 },
      ],
    },
    {
      label: 'Light Blue',
      value: 74,
      hex: '#395A83',
      requiredItems: [
        { item: 'paintBlue', amount: 2.5 },
        { item: 'paintWhite', amount: 2 },
        { item: 'paintRed', amount: 0.2 },
        { item: 'paintGreen', amount: 0.3 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Dark Blue',
      value: 75,
      hex: '#09142E',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintBlue', amount: 2 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Midnight Blue',
      value: 76,
      hex: '#0F1021',
      requiredItems: [
        { item: 'paintBlack', amount: 2 },
        { item: 'paintBlue', amount: 3 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Blue',
      value: 77,
      hex: '#152A52',
      requiredItems: [
        { item: 'paintBlack', amount: 2 },
        { item: 'paintGreen', amount: 1 },
        { item: 'paintBlue', amount: 2 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Sea Foam Blue',
      value: 78,
      hex: '#324654',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlue', amount: 3 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Lightning Blue',
      value: 79,
      hex: '#152563',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintBlue', amount: 2 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Util Maui Blue Poly',
      value: 80,
      hex: '#223BA1',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlue', amount: 4 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Util Bright Blue/Slate Blue',
      value: 81,
      hex: '#1F1FA1',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlue', amount: 3 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Matte Dark Blue',
      value: 82,
      hex: '#030E2E',
      requiredItems: [
        { item: 'paintBlack', amount: 2 },
        { item: 'paintBlue', amount: 3 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Matte Blue',
      value: 83,
      hex: '#0F1E73',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlue', amount: 3 },
        { item: 'silverDust', amount: 30 },
      ],
    },
    {
      label: 'Matte Midnight Blue',
      value: 84,
      hex: '#001C32',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintBlack', amount: 1 },
        { item: 'paintGreen', amount: 1 },
        { item: 'paintBlue', amount: 2 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Dark Blue',
      value: 85,
      hex: '#2A3754',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlack', amount: 1 },
        { item: 'paintBlue', amount: 2 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Blue',
      value: 86,
      hex: '#303C5E',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintBlue', amount: 3 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Baby Blue',
      value: 87,
      hex: '#3B6796',
      requiredItems: [
        { item: 'paintWhite', amount: 4 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Yellow',
      value: 88,
      hex: '#F5890F',
      requiredItems: [
        { item: 'paintYellow', amount: 5 },
        { item: 'goldDust', amount: 10 },
      ],
    },
    {
      label: 'Race Yellow',
      value: 89,
      hex: '#D9A600',
      requiredItems: [
        { item: 'paintYellow', amount: 4.5 },
        { item: 'paintWhite', amount: 0.5 },
      ],
    },
    {
      label: 'Bronze',
      value: 90,
      hex: '#4A341B',
      requiredItems: [
        { item: 'paintBlack', amount: 0.8 },
        { item: 'paintRed', amount: 2.3 },
        { item: 'paintGreen', amount: 1.2 },
        { item: 'paintBlue', amount: 0.7 },
        { item: 'goldDust', amount: 50 },
      ],
    },
    {
      label: 'Dew Yellow/Yellow Bird',
      value: 91,
      hex: '#A2A827',
      requiredItems: [
        { item: 'paintGreen', amount: 1.9 },
        { item: 'paintYellow', amount: 2.1 },
        { item: 'paintWhite', amount: 1 },
      ],
    },
    {
      label: 'Lime Green',
      value: 92,
      hex: '#568F00',
      requiredItems: [
        { item: 'paintGreen', amount: 3.4 },
        { item: 'paintWhite', amount: 1.5 },
        { item: 'paintRed', amount: 0.1 },
      ],
    },
    {
      label: 'Champagne',
      value: 93,
      hex: '#57514B',
      requiredItems: [
        { item: 'paintBlack', amount: 1.5 },
        { item: 'paintWhite', amount: 2.6 },
        { item: 'paintRed', amount: 0.4 },
        { item: 'paintGreen', amount: 0.3 },
        { item: 'paintBlue', amount: 0.2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Feltzer Brown',
      value: 94,
      hex: '#291B06',
      requiredItems: [
        { item: 'paintBlack', amount: 1 },
        { item: 'paintRed', amount: 2.2 },
        { item: 'paintGreen', amount: 1.1 },
        { item: 'paintBlue', amount: 0.7 },
        { item: 'goldDust', amount: 50 },
      ],
    },
    {
      label: 'Creek Brown',
      value: 95,
      hex: '#262117',
      requiredItems: [
        { item: 'paintBlack', amount: 3 },
        { item: 'paintRed', amount: 0.8 },
        { item: 'paintGreen', amount: 0.7 },
        { item: 'paintBlue', amount: 0.5 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Chocolate Brown',
      value: 96,
      hex: '#120D07',
      requiredItems: [
        { item: 'paintRed', amount: 2.8 },
        { item: 'paintGreen', amount: 1.6 },
        { item: 'paintBlack', amount: 0.6 },
        { item: 'coalDust', amount: 30 },
      ],
    },
    {
      label: 'Maple Brown',
      value: 97,
      hex: '#332111',
      requiredItems: [
        { item: 'paintBlack', amount: 1 },
        { item: 'paintRed', amount: 2.3 },
        { item: 'paintGreen', amount: 1.1 },
        { item: 'paintBlue', amount: 0.6 },
        { item: 'goldDust', amount: 50 },
      ],
    },
    {
      label: 'Saddle Brown',
      value: 98,
      hex: '#3D3023',
      requiredItems: [
        { item: 'paintWhite', amount: 3.5 },
        { item: 'paintRed', amount: 0.6 },
        { item: 'paintGreen', amount: 0.5 },
        { item: 'paintBlue', amount: 0.4 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'Straw Brown',
      value: 99,
      hex: '#5E5343',
      requiredItems: [
        { item: 'paintWhite', amount: 3.8 },
        { item: 'paintRed', amount: 0.5 },
        { item: 'paintGreen', amount: 0.4 },
        { item: 'paintBlue', amount: 0.3 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'Moss Brown',
      value: 100,
      hex: '#37382B',
      requiredItems: [
        { item: 'paintBlack', amount: 1 },
        { item: 'paintGreen', amount: 1.8 },
        { item: 'paintBlue', amount: 0.4 },
        { item: 'paintRed', amount: 0.3 },
        { item: 'paintWhite', amount: 1.3 },
      ],
    },
    {
      label: 'Bison Brown',
      value: 101,
      hex: '#221918',
      requiredItems: [
        { item: 'paintBlack', amount: 0.8 },
        { item: 'paintRed', amount: 2.6 },
        { item: 'paintGreen', amount: 1.1 },
        { item: 'paintBlue', amount: 0.5 },
        { item: 'goldDust', amount: 50 },
      ],
    },
    {
      label: 'WoodBeech Brown',
      value: 102,
      hex: '#575036',
      requiredItems: [
        { item: 'paintWhite', amount: 3.7 },
        { item: 'paintRed', amount: 0.5 },
        { item: 'paintGreen', amount: 0.5 },
        { item: 'paintBlue', amount: 0.3 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'BeechWood Brown',
      value: 103,
      hex: '#241309',
      requiredItems: [
        { item: 'paintRed', amount: 2.9 },
        { item: 'paintGreen', amount: 1.7 },
        { item: 'paintBlack', amount: 0.4 },
        { item: 'coalDust', amount: 30 },
      ],
    },
    {
      label: 'Sienna Brown',
      value: 104,
      hex: '#3B1700',
      requiredItems: [
        { item: 'paintRed', amount: 2.9 },
        { item: 'paintGreen', amount: 1.7 },
        { item: 'paintBlack', amount: 0.4 },
      ],
    },
    {
      label: 'Sandy Brown',
      value: 105,
      hex: '#6E6246',
      requiredItems: [
        { item: 'paintWhite', amount: 3.9 },
        { item: 'paintRed', amount: 0.4 },
        { item: 'paintGreen', amount: 0.4 },
        { item: 'paintBlue', amount: 0.3 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'Bleached Brown',
      value: 106,
      hex: '#998D73',
      requiredItems: [
        { item: 'paintWhite', amount: 4 },
        { item: 'paintRed', amount: 0.4 },
        { item: 'paintGreen', amount: 0.4 },
        { item: 'paintBlue', amount: 0.2 },
      ],
    },
    {
      label: 'Cream',
      value: 107,
      hex: '#CFC0A5',
      requiredItems: [
        { item: 'paintWhite', amount: 4.4 },
        { item: 'paintRed', amount: 0.3 },
        { item: 'paintGreen', amount: 0.2 },
        { item: 'paintBlue', amount: 0.1 },
      ],
    },
    {
      label: 'Util Brown',
      value: 108,
      hex: '#1F1709',
      requiredItems: [
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 2 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 20 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Medium Brown',
      value: 109,
      hex: '#3D311D',
      requiredItems: [
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 2 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 10 },
        { item: 'goldDust', amount: 10 },
      ],
    },
    {
      label: 'Util Light Brown',
      value: 110,
      hex: '#665847',
      requiredItems: [
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 2 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Ice White',
      value: 111,
      hex: '#F0F0F0',
      requiredItems: [
        { item: 'paintWhite', amount: 4.9 },
        { item: 'paintBlue', amount: 0.1 },
      ],
    },
    {
      label: 'Frost White',
      value: 112,
      hex: '#B3B9C9',
      requiredItems: [
        { item: 'paintWhite', amount: 4.7 },
        { item: 'paintBlue', amount: 0.3 },
      ],
    },
    {
      label: 'Worn Honey Beige',
      value: 113,
      hex: '#615F55',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 1 },
        { item: 'paintBlue', amount: 1 },
      ],
    },
    {
      label: 'Worn Brown',
      value: 114,
      hex: '#241E1A',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 1 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Worn Dark Brown',
      value: 115,
      hex: '#171413',
      requiredItems: [
        { item: 'paintBlack', amount: 1 },
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 1 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Straw Beige',
      value: 116,
      hex: '#3B372F',
      requiredItems: [
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 2 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Brushed Steel',
      value: 117,
      hex: '#3B4045',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintBlack', amount: 2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Brushed Black Steel',
      value: 118,
      hex: '#1A1E21',
      requiredItems: [
        { item: 'paintBlack', amount: 5 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Brushed Aluminium',
      value: 119,
      hex: '#5E646B',
      requiredItems: [
        { item: 'paintWhite', amount: 5 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Chrome',
      value: 120,
      hex: '#000000',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintBlack', amount: 2 },
        { item: 'coalDust', amount: 10 },
        { item: 'diamondDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
        { item: 'goldDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Off White',
      value: 121,
      hex: '#B0B0B0',
      requiredItems: [
        { item: 'paintWhite', amount: 5 },
        { item: 'coalDust', amount: 10 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Util Off White',
      value: 122,
      hex: '#999999',
      requiredItems: [
        { item: 'paintWhite', amount: 5 },
        { item: 'coalDust', amount: 20 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Orange',
      value: 123,
      hex: '#B56519',
      requiredItems: [
        { item: 'paintRed', amount: 2.5 },
        { item: 'paintYellow', amount: 2.5 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Worn Light Orange',
      value: 124,
      hex: '#C45C33',
      requiredItems: [
        { item: 'paintRed', amount: 2.5 },
        { item: 'paintYellow', amount: 2 },
        { item: 'paintWhite', amount: 0.5 },
        { item: 'coalDust', amount: 20 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Pea Green/Securicor Green',
      value: 125,
      hex: '#47783C',
      requiredItems: [
        { item: 'paintGreen', amount: 4.5 },
        { item: 'paintYellow', amount: 0.4 },
        { item: 'paintBlack', amount: 0.1 },
        { item: 'goldDust', amount: 30 },
      ],
    },
    {
      label: 'Worn Taxi Yellow',
      value: 126,
      hex: '#BA8425',
      requiredItems: [
        { item: 'paintYellow', amount: 4.5 },
        { item: 'paintRed', amount: 0.5 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Police Blue',
      value: 127,
      hex: '#2A77A1',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintBlue', amount: 2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Matte Green',
      value: 128,
      hex: '#243022',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintGreen', amount: 4 },
        { item: 'coalDust', amount: 20 },
      ],
    },
    {
      label: 'Matte Brown',
      value: 129,
      hex: '#6B5F54',
      requiredItems: [
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 2 },
        { item: 'paintBlue', amount: 1 },
      ],
    },
    {
      label: 'Worn Orange 2',
      value: 130,
      hex: '#C96E34',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 2 },
        { item: 'paintYellow', amount: 2 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Ice White Matte',
      value: 131,
      hex: '#D9D9D9',
      requiredItems: [{ item: 'paintWhite', amount: 5 }],
    },
    {
      label: 'Worn White',
      value: 132,
      hex: '#F0F0F0',
      requiredItems: [
        { item: 'paintWhite', amount: 5 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Worn Olive Army Green',
      value: 133,
      hex: '#3F4228',
      requiredItems: [
        { item: 'paintRed', amount: 1 },
        { item: 'paintGreen', amount: 4 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Pure White',
      value: 134,
      hex: '#FFFFFF',
      requiredItems: [
        { item: 'paintWhite', amount: 5 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Hot Pink',
      value: 135,
      hex: '#B01259',
      requiredItems: [
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 1 },
        { item: 'paintBlue', amount: 2 },
      ],
    },
    {
      label: 'Salmon Pink',
      value: 136,
      hex: '#F69799',
      requiredItems: [
        { item: 'paintRed', amount: 3 },
        { item: 'paintGreen', amount: 2 },
        { item: 'paintBlue', amount: 2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Pfister Pink',
      value: 137,
      hex: '#8F2F55',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintRed', amount: 1 },
        { item: 'paintPurple', amount: 1 },
      ],
    },
    {
      label: 'Bright Orange',
      value: 138,
      hex: '#C26610',
      requiredItems: [
        { item: 'paintRed', amount: 3 },
        { item: 'paintYellow', amount: 2 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Green Bright',
      value: 139,
      hex: '#69BD45',
      requiredItems: [
        { item: 'paintWhite', amount: 4 },
        { item: 'paintGreen', amount: 1 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Fluorescent Blue',
      value: 140,
      hex: '#00AEEF',
      requiredItems: [
        { item: 'paintWhite', amount: 4 },
        { item: 'paintBlue', amount: 1 },
        { item: 'silverDust', amount: 20 },
      ],
    },
    {
      label: 'Midnight Blue',
      value: 141,
      hex: '#000108',
      requiredItems: [
        { item: 'paintBlack', amount: 4.3 },
        { item: 'paintBlue', amount: 0.7 },
      ],
    },
    {
      label: 'Midnight Purple',
      value: 142,
      hex: '#050008',
      requiredItems: [
        { item: 'paintBlack', amount: 4.2 },
        { item: 'paintPurple', amount: 0.8 },
        { item: 'paintRed', amount: 0.4 },
      ],
    },
    {
      label: 'Wine Red',
      value: 143,
      hex: '#080000',
      requiredItems: [
        { item: 'paintBlack', amount: 4.3 },
        { item: 'paintRed', amount: 0.7 },
      ],
    },
    {
      label: 'Hunter Green',
      value: 144,
      hex: '#565751',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintGreen', amount: 2 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Bright Purple',
      value: 145,
      hex: '#320642',
      requiredItems: [
        { item: 'paintPurple', amount: 4.4 },
        { item: 'paintWhite', amount: 0.6 },
      ],
    },
    {
      label: 'Midnight Purple / V Dark Blue',
      value: 146,
      hex: '#050008',
      requiredItems: [
        { item: 'paintBlack', amount: 4 },
        { item: 'paintBlue', amount: 1 },
      ],
    },
    {
      label: 'Carbon Black',
      value: 147,
      hex: '#080808',
      requiredItems: [
        { item: 'paintBlack', amount: 5 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Matte Schafter Purple',
      value: 148,
      hex: '#320642',
      requiredItems: [
        { item: 'paintBlack', amount: 1 },
        { item: 'paintPurple', amount: 4 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Matte Midnight Purple',
      value: 149,
      hex: '#050008',
      requiredItems: [
        { item: 'paintBlack', amount: 4 },
        { item: 'paintBlue', amount: 1 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Lava Red',
      value: 150,
      hex: '#6B0B00',
      requiredItems: [
        { item: 'paintRed', amount: 4 },
        { item: 'paintWhite', amount: 0.9 },
        { item: 'paintGreen', amount: 0.1 },
      ],
    },
    {
      label: 'Matte Forest Green',
      value: 151,
      hex: '#121710',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintGreen', amount: 4 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Matte Olive Drab',
      value: 152,
      hex: '#323325',
      requiredItems: [
        { item: 'paintWhite', amount: 2 },
        { item: 'paintGreen', amount: 3 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Matte Dark Earth',
      value: 153,
      hex: '#3B352D',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 2 },
      ],
    },
    {
      label: 'Matte Desert Tan',
      value: 154,
      hex: '#706656',
      requiredItems: [
        { item: 'paintWhite', amount: 1 },
        { item: 'paintRed', amount: 2 },
        { item: 'paintGreen', amount: 2 },
        { item: 'paintBlue', amount: 2 },
      ],
    },
    {
      label: 'Matte Foliage Green',
      value: 155,
      hex: '#2B302B',
      requiredItems: [
        { item: 'paintWhite', amount: 3 },
        { item: 'paintGreen', amount: 2 },
      ],
    },
    {
      label: 'Default Alloy Color',
      value: 156,
      hex: '#414347',
      requiredItems: [
        { item: 'paintWhite', amount: 4 },
        { item: 'paintBlack', amount: 1 },
        { item: 'coalDust', amount: 10 },
      ],
    },
    {
      label: 'Epsilon Blue',
      value: 157,
      hex: '#6690B5',
      requiredItems: [
        { item: 'paintWhite', amount: 5 },
        { item: 'paintBlue', amount: 5 },
        { item: 'silverDust', amount: 10 },
      ],
    },
    {
      label: 'Pure Gold',
      value: 158,
      hex: '#47391B',
      requiredItems: [
        { item: 'paintWhite', amount: 4.5 },
        { item: 'paintYellow', amount: 0.5 },
        { item: 'goldDust', amount: 50 },
      ],
    },
    {
      label: 'Brushed Gold',
      value: 159,
      hex: '#47391B',
      requiredItems: [
        { item: 'paintWhite', amount: 4.5 },
        { item: 'paintYellow', amount: 0.5 },
        { item: 'goldDust', amount: 50 },
        { item: 'coalDust', amount: 30 },
      ],
    },
    {
      label: 'MP100',
      value: 160,
      hex: '#FFD859',
      requiredItems: [
        { item: 'paintWhite', amount: 1.5 },
        { item: 'paintRed', amount: 1.1 },
        { item: 'paintGreen', amount: 1 },
        { item: 'paintBlue', amount: 0.4 },
        { item: 'goldDust', amount: 10 },
      ],
    },
  ],
};
