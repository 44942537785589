import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDutyOverview } from '../../../actions';
import { appsFractionOverviewP } from '../../../redux/reducer/apps/lsmd';
import { OverviewEntryData } from './types';
import CachedIcon from '@mui/icons-material/Cached';
import { translate } from '../../../utils/translate';
import { css } from '@emotion/react';

const refreshStyle = css`
  position: absolute !important;
  right: 3vh;
`;

export const LspdOverview = () => {
  const dispatch = useDispatch();
  const overviewData = useSelector<any, OverviewEntryData[]>(
    pathOr([], appsFractionOverviewP)
  );

  const requestData = () => {
    dispatch(fetchDutyOverview());
  };

  useEffect(() => {
    requestData();
  }, []);

  return (
    <div>
      <h1>
        Übersicht
        <Button variant="outlined" onClick={requestData} css={refreshStyle}>
          Aktualisieren <CachedIcon />
        </Button>
      </h1>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Rang</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {overviewData.map((player) => (
              <TableRow key={player._id}>
                <TableCell>{player.name}</TableCell>
                <TableCell>
                  {translate('lspdrank' + player.teamRank)} ({player.teamRank})
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
