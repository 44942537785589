import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminMeters = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_METERS_FETCH_START),
    mapPromiseC(({ endDate, offset }: any) => {
      return authFetchJson(
        `/admin/server/meter?offset=${offset}&date=${
          endDate ? endDate.toISOString() : ''
        }`
      )
        .then((data) => {
          return {
            type: types.ADMIN_METERS_FETCH_SUCCESS,
            data,
            offset,
          };
        })
        .catch(() => {});
    })
  );
