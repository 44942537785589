import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminVehiclesSearchP = [
  'apps',
  'admin',
  'vehiclesearch',
  'search',
];
export const appsAdminVehiclesSearchL = lensPath(appsAdminVehiclesSearchP);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminVehiclesSearchL, search, state);
};

export const appsAdminVehicleSearchResultsP = [
  'apps',
  'admin',
  'vehiclesearch',
  'results',
];
export const appsAdminVehicleSearchResultsL = lensPath(
  appsAdminVehicleSearchResultsP
);
const setSearchResults = (state: any, { data }: any) => {
  return set(appsAdminVehicleSearchResultsL, data, state);
};

export const appsAdminVehicleSearchReducerTree = {
  [types.ADMIN_VEHICLES_SEARCH_SET]: [setSearch],
  [types.ADMIN_VEHICLES_SEARCH_FETCH_SUCCESS]: [setSearchResults],
};
