import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Geltungsbereich</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das Betäubungsmittelgesetz wirkt im gesamten Raum von San Andreas und den umliegenden Gewässern.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Besitz</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Besitz von Marihuana ist in einer Menge von bis zu 2 Joints oder 2 Weed-Buds gestattet.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Besitz von anderen Betäubungsmittel ist nicht gestattet und wird im Rahmen des illegalen Besitzes bestraft.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Wer erhebliche Mengen von Marihuana besitzt, macht sich des illegalen Besitzes strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Handel</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer mit Betäubungsmitteln handelt, macht sich des illegalen Handels strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Nutzung</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das Konsumieren von Kokain ist nicht gestattet.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Das Konsumieren von Marihuana ist in geringen Mengen gestattet.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Anbau</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Anbau der oben genannten Betäubungsmittel ist nicht gestattet und wird unter dem illegalen Anbau bestraft.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 6 Gewerblicher Vertrieb</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der gewerbliche Vertrieb von Betäubungsmitteln ist nicht gestattet und wird mit dem illegalen gewerblichen Handel bestraft.</span></span></p>
`;

export const Btmg = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
