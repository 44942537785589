import * as type from '../redux/types';

export function openBrowserUrl(index: number, url: string) {
  return {
    type: type.BROWSER_TAB_GOTO,
    index,
    url,
  };
}

export function changeBrowserTab(index: number) {
  return {
    type: type.BROWSER_TAB_SELECT,
    index,
  };
}

export function createBrowserTab() {
  return {
    type: type.BROWSER_TAB_CREATE,
  };
}

export function goBrowserTabBack(index: number) {
  return {
    type: type.BROWSER_TAB_BACK,
    index,
  };
}
export function goBrowserTabForward(index: number) {
  return {
    type: type.BROWSER_TAB_FORWARD,
    index,
  };
}

export function fetchBrowserCars() {
  return {
    type: type.BROWSER_CARS_FETCH_START,
  };
}
