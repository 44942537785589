import { reduce, lensPath, set } from 'ramda';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

import * as type from '../types';
import { commonReducerTree } from './common';
// import { registerReducerTree } from './register';
import { loginReducerTree } from './login';
import { appsMyVehiclesReducerTree } from './apps/myVehicles';
import { appsTestsuitesReducerTree } from './apps/testsuites';
import { appsFinanceReducerTree } from './apps/finance';
import { appsCompanyReducerTree } from './apps/company';
import { appsFractionReducerTree } from './apps/fraction';
import { appsMailReducerTree } from './apps/mail';
import { appsAdminReducerTree } from './apps/admin';
import { settingsReducerTree } from './settings';
import { appsBrowserReducerTree } from './apps/browser';
import { leogramReducerTree } from '../../leogram/store/reducer';
import { appsDocumentsReducerTree } from './apps/documents';
import { appsFolderGroupsReducerTree } from './apps/folder-groups';
import { appsFoldersReducerTree } from './apps/folders';
import { desktopReducerTree } from './desktop';
import { appsLsmdReducerTree } from './apps/lsmd';
import { lspdReducerTree } from './apps/lspd';
import { photoReducerTree } from './photo';

export const history = createBrowserHistory({ basename: '/#' });
const router = connectRouter(history);

function lastActionReducer(state: any, action: any) {
  return {
    ...state,
    lastAction: action.type,
  };
}

function routerReducer(state: any, action: any) {
  return { ...state, router: router(state.router, action) };
}

const reducerTree: any = {
  '*': [lastActionReducer],

  [type.ROUTER]: [routerReducer],

  ...commonReducerTree,
  // ...registerReducerTree,
  ...loginReducerTree,
  ...appsMyVehiclesReducerTree,
  ...appsTestsuitesReducerTree,
  ...appsFinanceReducerTree,
  ...appsCompanyReducerTree,
  ...appsFractionReducerTree,
  ...appsMailReducerTree,
  ...appsAdminReducerTree,
  ...settingsReducerTree,
  ...appsBrowserReducerTree,
  ...leogramReducerTree,
  ...appsDocumentsReducerTree,
  ...appsFolderGroupsReducerTree,
  ...appsFoldersReducerTree,
  ...desktopReducerTree,
  ...appsLsmdReducerTree,
  ...lspdReducerTree,
  ...photoReducerTree,
};

const noReducer = (state: any, action: any) => state;

const getFromTree = (type: any) =>
  reducerTree[type] ? reducerTree[type] : [noReducer];

export const rootReducer = (state: any, action: any) => {
  return reduce((acc: any, fn: any) => fn(acc, action), state, [
    ...reducerTree['*'],
    ...getFromTree(action.type),
  ]);
};
