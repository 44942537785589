import * as type from '../store/types';

export function startFetchComments(post: string, offset: number) {
  return {
    type: type.COMMENTS_FETCH_START,
    post,
    offset,
  };
}

export function startCreateComment(post: string, text: string) {
  return {
    type: type.COMMENT_CREATE_START,
    post,
    text,
  };
}

export function startToggleLikeComment(comment: string) {
  return {
    type: type.COMMENT_LIKE_TOGGLE_START,
    comment,
  };
}
