import React from 'react';

const html = `
<p><strong>
		</strong><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Geltungsbereich</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das Polizeiliche Eingriffsgesetz wirkt im gesamten Raum von San Andreas und den umliegenden Gewässern und gilt nur für die exekutiven Beamten des Staates San Andreas.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Festnahmen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Eine Person darf festgenommen werden, wenn ein Anfangsverdacht einer Straftat vorliegt oder ein begründeter Fall der Eigensicherung vorliegt. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Verfallen während der Festnahme die Haftgründe, so muss die Person ohne Verzögerung entlassen werden. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Nach der Festnahme sind die Personen die Rechte gemäß § 8 PEinG zu verlesen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Präventivhaft</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Im Ermessen des LSPD darf eine Präventivhaft von bis zu 2 Stunden in Form einer Sicherheitsmaßnahme angeordnet werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Dem Täter sind stets Grundlebensmittel auszuhändigen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Bei illegalen Präventivhaften ist der betroffenen Person Schadensersatz auszuhändigen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Durchsuchungen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Eine Person darf im Rahmen der Eigensicherung bei Anfangsverdacht jederzeit durchsucht werden. Ebenfalls darf jederzeit eine Person durchsucht werden, sofern diese nicht ihren Ausweis aushändigen kann oder möchte.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Ein Anfangsverdacht liegt vor, wenn zwei verschiedene Indizien auf eine Straftat hindeuten.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Das Durchsuchen von Wohngebäuden und Firmen ist nur mit einem richterlichen Beschluss gestattet. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Ein richterlicher Beschluss kann bei Gefahr im Verzug mündlich erfolgen, soll jedoch danach schriftlich nachgereicht werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Fahrzeuge dürfen ebenfalls nur mit einem richterlichen Beschluss oder bei Gefahr im Verzug auf Anordnung des DoJ durchsucht werden. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VI. Das willkürliche Durchsuchen von Personen und Fahrzeugen ist nicht gestattet.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VII. Gefahr im Verzug kann erst vorliegen, sofern ein Anfangsverdacht gegeben ist. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VIII. In Checkpoints gemäß § 6 PEinG ist die Durchsuchung von Fahrzeug jederzeit gestattet. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Gefahr im Verzug</strong> </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Gefahr im Verzug stellt die Inanspruchnahme von Maßnahmen und Befugnissen zur Bewältigung einer Maßnahme dar, ohne dass auf die Entscheidung eines Richters gewartet werden kann.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 6 Checkpoints</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das LSPD kann jederzeit einen Checkpoint ausrufen. Dieser Checkpoint muss optisch einem entsprechen und muss objektiv hierfür geeignet sein. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 7 Stören von Maßnahmen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Gegen Personen, die Maßnahmen des LSPD stören, behindern oder vermeiden, darf das LSPD unmittelbaren Zwang anwenden im Verhältnis zur gestörten Maßnahme.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Im schweren Fall machen sich der Störer gemäß § 24 StGB strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Das Nichtaushändigen des Ausweises fällt ebenfalls unter das Stören von Maßnahmen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 8 Miranda Warning</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Einem Beschuldigten muss unmittelbar bei dessen Festnahme die Miranda Warning verlesen werden, es sei denn, es ist aus einem Gefahrengrund nicht möglich. Danach muss die Miranda Warning unverzüglich nachgetragen werden.</span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. </span>Die Miranda Warning hat folgenden Wortlaut:</span></p>
	<ul><li><span style="font-size: 14pt;">“Sie haben das Recht zu schweigen. Alles, was Sie sagen, wird vor Gericht gegen Sie verwendet. Sie haben das Recht auf einen Anwalt. Wenn Sie sich nachweislich keinen leisten können, wird Ihnen einer vom Staat gestellt. Sollte kein Anwalt verfügbar sein, müssen Sie sich selber verteidigen. Haben Sie Ihre Rechte verstanden?”</span></li>
	</ul><p><span style="font-size: 14pt;">III. Sollte die Miranda Warning nicht verlesen werden, so sind die getätigten Aussagen des Beschuldigten nicht verwendbar.<br></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Die Miranda Warning gilt spätestens nach dreimaligem Verlesen automatisch als verstanden.</span><span style="font-size: 14pt;"> </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 9 Beschlagnahme</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Ermittlungsgegenstände, illegale Gegenstände, fehlerhafte Fahrzeuge, Tatgegenstände und Dokumente, die einen Bezug zur Tat haben, können auf Anordnung des LSPD beschlagnahmt werden. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Gegenstände, die keine Ermittlungsrelevanz oder Strafrelevanz haben, sind auf kürzestem Wege dem Eigentümer auszuhändigen. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Bei Fahren unter fehlender körperlicher Eignung, Drogeneinfluss oder einem nicht verkehrskonformen Fahrstil kann das LSPD jederzeit den Führerschein sowie das Fahrzeug beschlagnahmen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 10 Anwendung von tödlicher Gewalt</strong> </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Im Fall von Gefahr für Leib oder Leben oder eines erheblichen Sachguts darf das LSPD auf den Täter mit tödlicher Gewalt wirken. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Im Fall von Notwehr gemäß § 43 StGB ist die Nutzung von tödlicher Gewalt ebenfalls gestattet. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Sollte eine Person eine polizeiliche Absperrung mit einem Fahrzeug durchbrechen oder mit einem Fahrzeug den Beamten in eine gefährliche Nähe kommen, darf auf das Fahrzeug geschossen werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 11 Unmittelbarer Zwang</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Unmittelbarer Zwang ist stets verhältnismäßig anzuwenden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Unmittelbarer Zwang darf nur durch das LSPD mit einem triftigen Grund zur Durchsetzung der Maßnahmen des LSPD angewandt werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Übermaß von unmittelbarem Zwang ist verboten. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 12 Vermummung</strong> </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Ist es für die Bewältigung der Maßnahmen notwendig oder die Identität der Beamten schützenswürdig, so ist das LSPD vom Vermummungsverbot gemäß § 32 StGB entbunden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 13 Dienstausweispflicht</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Beamten des LSPD sind verpflichtet, dem Bürger die Dienstnummer sowie den Dienstausweis auszuhändigen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Sollte das aufgrund der Gefahrenlage oder im Fall von Gefahr im Verzug nicht möglich sein, so ist das nachträglich unverzüglich durchzuführen. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. LSPD Officer im Undercover-Einsatz sind von der Regelung ausgeschlossen. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 14 Kontrollen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Beamten des LSPD ist es jederzeit gestattet, Fahrzeuge im Straßenverkehr anzuhalten und die Personen zu kontrollieren.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Personen, die sich nicht in einem Fahrzeug befinden, dürfen jederzeit bei einem Anfangsverdacht einer Straftat kontrolliert werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Weitere Maßnahmen ergeben sich aus den anderen Vorschriften der Gesetze.</span></span><span style="color:rgb(255,255,255);"></span></p>
`;

export const PEingG = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
