import * as types from '../../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';

export const onFetchAdminLiveStats = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_LIVESTATS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/admin/livestats')
        .then((data) => {
          return {
            type: types.ADMIN_LIVESTATS_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_LIVESTATS_FETCH_FAILED,
        }));
    })
  );
