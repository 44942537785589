import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Hausrecht</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I.  Das Hausrecht ist das Recht des Grundstückseigentümers und Befugten darüber zu entscheiden, welche Personen sich auf der Immobilie aufhalten dürfen und welche nicht. Darüber hinaus kann der Eigentümer oder Befugte den Zugang zum Grundstück an bestimmte Bedingungen koppeln.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Der Grundstückseigentümer und Befugte haben das Recht, Hausverbote für bestimmte Personen auszusprechen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Die staatlichen Organisationen bilden eine Ausnahme und dürfen zur Erfüllung ihrer hoheitlichen Pflichten private Grundstücke und andere Immobilien betreten.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Todesfall eines Bürgers</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Tod einer Person benötigt einen Totenschein, um bestätigt zu werden. Dieser wird vom LSMD ausgestellt und muss beim DoJ eingereicht werden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Das LSMD darf den Totenschein nur ausstellen, wenn ein medizinisches Gutachten vorliegt oder ausreichende Beweise für ein Versterben einer verschollenen Person vorliegen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Der Totenschein darf nur an Familienmitglieder, in Form von Verwandten, Ehegatten, Lebensgefährten, oder das DoJ ausgestellt werden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Erst mit einem gültigen Totenschein und mit der Vorlage beim DoJ darf eine Beerdigung organisiert werden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Eheschließung</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Bund der Ehe kann nur mit einer Willenserklärung beider Personen eingegangen werden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Die Eheschließung ist rechtskräftig, sobald diese vor dem Standesbeamten geschlossen wurde und die Urkunde von dem Standesbeamten ausgestellt wurde.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Die Heiratsurkunde umfasst die persönlichen Daten der heiratenden Personen und gegebenenfalls Änderungen des Nachnamen einer Person.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Scheidung der Ehe</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Scheidungsantrag benötigt die Willenserklärungen beider Parteien und muss schriftlich beim DoJ eingereicht werden. Eine Ausnahme bildet die Einreichung einer Todesurkunde. Hierbei wird die Ehe nach Eingang beim DoJ aufgelöst.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Falls ein Scheidungsantrag mit nur einer gültigen Willenserklärung eingereicht wird, beginnt eine Frist von 2 Wochen. Sobald diese Scheidungsfrist abgelaufen ist, wird die Ehe geschieden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Im Scheidungsvertrag muss festgelegt sein, ob die sich scheidenden Personen ihren Namen beibehalten oder ob der Nachname wieder zum Geburtsnamen geändert wird.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Einem Scheidungsantrag und die damit einhergehende Namensänderung einer Person wird nicht stattgegeben, falls die betreffende Person in einem laufenden Zivil- oder Strafverfahren verwickelt ist. </span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Versammlungsrecht</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Jeder hat das Recht, eine Versammlung zu veranstalten. Dabei ist es unbeachtlich, ob es sich um eine Versammlung im öffentlichen oder im privaten Rahmen handelt.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Eine öffentliche Versammlung / Feier benötigt eine Anmeldung beim DoC</span></span><span style="font-size: 14pt;color:rgb(255,255,255);">. Weitergehend müssen das LSMD und das LSPD über öffentliche Versammlungen / Feier informiert werden und dies genehmigen.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Eine private Versammlung benötigt grundsätzlich keine Anmeldung, solange diese auch auf privatem Gelände stattfindet.</span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
`;

export const ZiviGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
