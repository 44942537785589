import { lensPath, set, pipe, dissocPath } from 'ramda';
import * as types from '../../types';
import { DOCUMENT_A4 } from '../../../leogram/shared/constants/documents';

const uuidv4 = require('uuid/v4');

export const documentsListP = ['apps', 'documents', 'list'];
export const documentsListL = lensPath(documentsListP);
const setDocumentsList = (state: any, { data }: any) => {
  return set(documentsListL, data, state);
};
export const documentsDetailsP = ['apps', 'documents', 'details'];
export const documentsDetailsL = lensPath(documentsDetailsP);
const setDocumentDetail = (state: any, { data }: any) => {
  return pipe(
    set(lensPath([...documentsDetailsP, data._id]), data),
    set(documentsTabSelectedL, data._id)
  )(state);
};

export const documentsSearchP = ['apps', 'documents', 'search'];
export const documentsSearchL = lensPath(documentsSearchP);
const setDocumentsSearch = (state: any, { search }: any) => {
  return set(documentsSearchL, search, state);
};

export const documentsTabSelectedP = ['apps', 'documents', 'selectedTab'];
export const documentsTabSelectedL = lensPath(documentsTabSelectedP);
const setDocumentsTab = (state: any, { tab }: any) => {
  return set(documentsTabSelectedL, tab, state);
};

const createNewDocument = (state: any, {}: any) => {
  const id = uuidv4();
  return pipe(
    set(lensPath([...documentsDetailsP, id]), {
      _id: id,
      subject: 'Neues Dokument',
      pages: [undefined],
      type: DOCUMENT_A4.type,
      isNew: true,
      itemHash: 'documentA4',
      version: 2,
    }),
    set(documentsTabSelectedL, id)
  )(state);
};

const createDuplicateDocument = (
  state: any,
  { subject, pages, itemHash }: any
) => {
  const id = uuidv4();
  return pipe(
    set(lensPath([...documentsDetailsP, id]), {
      _id: id,
      subject: subject + ' Kopie',
      pages,
      type: DOCUMENT_A4.type,
      isNew: true,
      itemHash,
      version: 2,
    }),
    set(documentsTabSelectedL, id)
  )(state);
};

const rmDocument = (state: any, { _id }: any) => {
  return pipe(dissocPath([...documentsDetailsP, _id]), (state) =>
    setDocumentsTab(state, 'home')
  )(state);
};

export const documentHistoryP = ['apps', 'documents', 'history'];
export const documentHistoryL = lensPath(documentHistoryP);
const setDocumentHistory = (state: any, { data }: any) => {
  return set(documentHistoryL, data, state);
};
export const documentHistoryEntryP = ['apps', 'documents', 'historyEntry'];
export const documentHistoryEntryL = lensPath(documentHistoryEntryP);
const setDocumentHistoryEntry = (state: any, { data }: any) => {
  return set(documentHistoryEntryL, data, state);
};
const setDocumentHistoryEntryToNull = (state: any, {}: any) => {
  return set(documentHistoryEntryL, null, state);
};

export const appsDocumentsReducerTree = {
  [types.DOCUMENTS_FETCH_SUCCESS]: [setDocumentsList],
  [types.DOCUMENT_FETCH_SUCCESS]: [setDocumentDetail],
  [types.DOCUMENT_SEARCH_SET]: [setDocumentsSearch],
  [types.DOCUMENT_NEW_CREATE]: [createNewDocument],
  [types.DOCUMENT_DUPLICATE_CREATE]: [createDuplicateDocument],
  [types.DOCUMENT_TAB_SELECT]: [setDocumentsTab],
  [types.DOCUMENT_RAW_REMOVE]: [rmDocument],
  [types.DOCUMENT_HISTORY_FETCH_SUCCESS]: [setDocumentHistory],
  [types.DOCUMENT_HISTORY_ENTRY_FETCH_START]: [setDocumentHistoryEntryToNull],
  [types.DOCUMENT_HISTORY_ENTRY_FETCH_SUCCESS]: [setDocumentHistoryEntry],
};
