import { set, lensPath, pathOr } from 'ramda';

import * as types from '../types';

export const photosP = ['photos'];
export const photosL = lensPath(photosP);

const setPhotos = (state: any, { data, offset }: any) => {
  const oldPhotos = pathOr([], photosP, state);
  const newPhotos = offset === 0 ? data : [...oldPhotos, ...data];
  return set(photosL, newPhotos, state);
};

export const photoReducerTree = {
  [types.APPS_PHOTO_GALERY_FETCH_SUCCESS]: [setPhotos],
};
