import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  Pagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/SearchRounded';
import PriorityIcon from '@mui/icons-material/FiberManualRecordRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
  createNewFolder,
  fetchFolders,
  selectFolderInGroup,
  setFolderSearch,
} from '../../../actions';
import { FolderListEntryData } from './folder-types';
import { pathOr } from 'ramda';
import {
  folderSearchP,
  foldersP,
  selectedFolderP,
} from '../../../redux/reducer/apps/folders';
import { Folder } from './Folder';
import { MemberGroupData } from './group-types';

const uuidv4 = require('uuid/v4');

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

const pagiantionStyle = css`
  margin-top: 1vh;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const priorityIconStyle = (theme: any) => css`
  &.veryhigh {
    color: ${theme.colors.Red};
  }
  &.high {
    color: ${theme.colors.Orange};
  }
  &.normal {
    color: ${theme.colors.Yellow};
  }
  &.low {
    color: ${theme.colors.Green};
  }
`;

const headlineStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;
`;

type FoldersType = {
  groupId: string;
  groups: MemberGroupData[];
};
export const Folders = ({ groupId, groups }: FoldersType) => {
  const dispatch = useDispatch();
  const { list, page, pages } = useSelector<
    any,
    { list: FolderListEntryData[]; page: number; pages: number }
  >(pathOr({ list: [], page: 1, pages: 1 }, foldersP));
  const search = useSelector<any, string>(
    pathOr('', [...folderSearchP, groupId])
  );
  const selectedGroup = groups.find((group) => group._id === groupId);
  const selectedFolder = useSelector<any, string>(
    pathOr('', [...selectedFolderP, groupId])
  );
  const setSelectedFolder = (folderId: string) => {
    dispatch(selectFolderInGroup(groupId, folderId));
  };

  const setSearch = (search: string) => {
    dispatch(setFolderSearch(groupId, search));
  };

  const fetchData = (page: number) => {
    dispatch(fetchFolders(groupId, page, search));
  };

  const createNew = () => {
    const id = uuidv4();
    dispatch(createNewFolder(groupId, id));
    setSelectedFolder(id);
  };

  const selectFolder = (id: string) => {
    setSelectedFolder(id);
  };

  const goBackToList = () => {
    setSelectedFolder('');
  };

  const onSearchInput = (key: string) => {
    if (key === 'Enter') {
      fetchData(1);
    }
  };

  const changePage = (ev: any, newPage: number) => {
    console.log(newPage);
    fetchData(newPage);
  };

  useEffect(() => {
    fetchData(1);
  }, [groupId]);

  return selectedFolder ? (
    <Folder
      groupId={groupId}
      folderId={selectedFolder}
      goBackToList={goBackToList}
      groups={groups}
    />
  ) : (
    <div>
      <h1>{selectedGroup && selectedGroup.label}</h1>
      <div css={headlineStyle}>
        <TextField
          css={inputStyle}
          label="Suche"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyPress={(e) => onSearchInput(e.key)}
          InputProps={{
            endAdornment: search !== '' && (
              <>
                <InputAdornment
                  position="end"
                  css={{ cursor: 'pointer' }}
                  onClick={() => fetchData(1)}
                >
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
        <Button variant="outlined" onClick={createNew} startIcon={<AddIcon />}>
          Neue Akte
        </Button>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Priorität</TableCell>
            <TableCell>Titel</TableCell>
            <TableCell>Erstellungsdatum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((entry) => (
            <TableRow key={entry._id} onClick={() => selectFolder(entry._id)}>
              <TableCell>
                <PriorityIcon
                  css={priorityIconStyle}
                  className={entry.priority}
                />
              </TableCell>
              <TableCell>{entry.subject}</TableCell>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.creationDate))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div css={pagiantionStyle}>
        <Pagination
          page={page}
          count={pages}
          onChange={changePage}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};
