import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { layoutContainerStyle, contentContainer } from '../styles';
import Navbar from '../container/NavbarContainer';
import { userTagStyle } from './Post';
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';

const postsStyle = css`
  max-width: 100vh;
  margin: auto;
  margin-top: 0.5vh;
  margin-bottom: 7vh;
`;

export const loadMoreStyle = css`
  cursor: pointer;

  :hover {
    background: #ffffff33;
    color: #ffffff;
  }
`;

export const loadingStyle = css`
  color: #ffffff !important;
  height: 2vh !important;
  width: 2vh !important;
`;

const noPostsStyle = css`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 1vh 0;
  color: #ffffff99;
  font-weight: 100;
  font-size: 1.25vh;
  margin-bottom: 3vh;
`;

export const loadMoreContainer = css`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 1vh 0;
  color: #ffffff99;
  font-weight: 100;
  font-size: 1.25vh;
`;

const entryStyle = css`
  display: flex;
  align-items: center;
  margin: 1vh 0;
  padding: 0.5vh 1vh;
  img {
    max-height: 3vh;
    width: auto;
  }
`;

const unreadEntryStyle = css`
  background-color: #ffffff11;
`;

const entryUserTagStyle = css`
  font-size: 1.5vh;
  padding-left: 0;
`;

const entryTextStyle = css`
  display: flex;
  color: #ffffff99;
  flex-direction: column;
  flex-grow: 1;
`;

const typeNotifications: any = {
  PROFILE_LIKE: 'folgt dir jetzt',
  POST_LIKE: 'mag deinen Beitrag',
  POST_COMMENT: 'hat deinen Beitrag kommentiert',
  POST_COMMENT_LIKE: 'mag deinen Kommentar',
};

export type ActivityType = {
  fetchActivities: (offset: number) => void;
  markRead: () => void;
  feeds: any[];
  goto: (route: string, params?: any) => void;
};

// const feed = [
//   {
//     _id: 1,
//     created: new Date(),
//     forUserTag: 'mila',
//     fromUserTag: 'MCZitrone',
//     type: 'PROFILE_LIKE',
//     read: false,
//   },
//   {
//     _id: 2,
//     created: new Date(),
//     forUserTag: 'mila',
//     fromUserTag: 'ThiloDerBaumeister',
//     type: 'POST_LIKE',
//     read: false,
//     post: {
//       userText: 'a',
//       created: new Date().toString(),
//       images: [
//         {
//           _id: 'https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg',
//           thumb: 'https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg',
//           url: 'https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg',
//         },
//       ],
//     },
//   },
//   {
//     _id: 2,
//     created: new Date(),
//     forUserTag: 'mila',
//     fromUserTag: 'ThiloDerBaumeister',
//     type: 'POST_LIKE',
//     read: true,
//     post: {
//       userText: 'a',
//       created: new Date().toString(),
//       images: [
//         {
//           _id: 'https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg',
//           thumb: 'https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg',
//           url: 'https://i.gyazo.com/91b2cf6ddf664e1dee2f2191ad863786.jpg',
//         },
//       ],
//       text: 'aaaaa',
//     },
//     comment: {
//       userTag: 'a',
//       text: 'lalala',
//       created: new Date().toString(),
//     },
//   },
// ];

const Entry = ({ activity, goto }: any) => {
  const postImage =
    activity.post &&
    activity.post.images &&
    activity.post.images.length > 0 &&
    activity.post.images[0].url;
  return (
    <div css={[entryStyle, !activity.read && unreadEntryStyle]}>
      <div css={entryTextStyle}>
        <div
          css={[userTagStyle, entryUserTagStyle]}
          onClick={() =>
            goto(`/profilepage`, { userTag: activity.fromUserTag })
          }
        >
          @{activity.fromUserTag}
        </div>
        <div>
          {typeNotifications[activity.type]}
          {activity.comment && activity.comment.text
            ? `: ${activity.comment.text}`
            : ''}
        </div>
      </div>
      {postImage && <img src={postImage} />}
    </div>
  );
};

export const Activity = ({
  fetchActivities,
  feeds,
  goto,
  markRead,
}: ActivityType) => {
  useEffect(() => {
    fetchActivities(0);
    markRead();
  }, []);

  const isLoading = false;

  return (
    <div css={layoutContainerStyle}>
      <Header goto={goto} />
      <Navbar />
      <div css={contentContainer}>
        <div css={postsStyle}>
          {feeds && feeds.length > 0 ? (
            feeds.map(activity => (
              <Entry key={activity._id} activity={activity} goto={goto} />
            ))
          ) : (
            <div css={noPostsStyle}>Keine Beiträge vorhanden</div>
          )}
          <div
            css={[loadMoreContainer, !isLoading && loadMoreStyle]}
            onClick={() => fetchActivities(feeds.length)}
          >
            {isLoading ? (
              <CircularProgress css={loadingStyle} />
            ) : (
              `${feeds && feeds.length > 0 ? 'Weitere ' : ''}Beiträge laden`
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
