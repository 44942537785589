export const browserBgColor = '#1b1b1b';

export const bgColor = '#1c1c1c';
export const borderColor = '#2b3954';

export const textColor = '#c0c9da';
export const textColorHover = '#233452';

export const greenColor = '#8cbf8c';
export const redColor = '#e26565';

export const hoverBgColor = '#4e5157';
