import React from 'react';
import { css } from '@emotion/react';
import MUISlider from '@mui/material/Slider';
import ZoomInIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutIcon from '@mui/icons-material/ZoomOutRounded';

const sliderContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// todo: redlion img ersetzen
const sliderStyle = css`
  height: 1.5vh !important;
  max-width: 25vh !important;
  float: right !important;
  margin-bottom: 0.25vh !important;
  height: 0.5vh !important;
  width: 50%;
  .MuiSlider-track {
    border: none !important;
  }
  .MuiSlider-thumb {
    color: white !important;
    width: 2vh !important;
    height: 2vh !important;
    ::after {
      width: 2vh !important;
      height: 2vh !important;
      top: 50% !important;
      left: 50% !important;
      background: url(https://redlion-rp.de/images/style-2/apple-touch-icon.png)
        no-repeat !important;
      background-position: center !important;
      background-size: 1.75vh !important;
    }
  }
`;

const iconContainer = css`
  display: flex;
  height: 2.5vh;
  align-items: center;
  justify-content: center;
  min-width: unset;
  max-width: unset;
  width: 5vh;
`;

const ZoomSlider = ({ value, setValue, minValue = 5, maxValue = 10 }: any) => (
  <div css={sliderContainer}>
    <div css={iconContainer}>
      <ZoomOutIcon />
    </div>
    <MUISlider
      css={sliderStyle}
      value={typeof value === 'number' ? value : 5}
      onChange={(e: any) => setValue(parseInt(e.target.value))}
      aria-labelledby="input-slider"
      min={minValue}
      max={maxValue}
    />
    <div css={iconContainer}>
      <ZoomInIcon />
    </div>
  </div>
);

export default ZoomSlider;
