import { pathOr } from 'ramda';
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAdminLeogramPostFetch,
  rmAdAdminLeogramPostFetch,
  startAdminLeogramPostsFetch,
} from '../../../actions';
import { appsAdminLeogramPostsP } from '../../../redux/reducer/apps/admin/leogram';
import { Button, TextField } from '@mui/material';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';

const TIME_OPTIONS = {
  locale: de,
};

const titleStyle = css`
  font-size: 2vh;
  font-weight: bold;
  padding-bottom: 1vh;
`;
const textStyle = css`
  padding: 2vh;
`;
const postContainerStyle = css`
  padding-bottom: 1vh;
  border-bottom: 1px solid #aaa;
  margin-bottom: 1vh;
  position: relative;
`;
const containerStyle = css`
  margin-top: 1vh;
`;

const btnsStyle = css`
  display: inline-block;
  position: absolute;
  right: 0vh;
`;

const deletedStyle = css`
  background: rgb(255 0 0 / 25%);
`;

export const Leogram = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const posts = useSelector<any, any[]>(pathOr([], appsAdminLeogramPostsP));

  useEffect(() => {
    dispatch(startAdminLeogramPostsFetch('', 0));
  }, []);

  const execSearch = () => {
    dispatch(startAdminLeogramPostsFetch(search, 0));
  };

  const loadMore = () => {
    dispatch(startAdminLeogramPostsFetch(search, posts.length));
  };

  const deletePost = (post: any) => {
    dispatch(deleteAdminLeogramPostFetch(post._id));
  };
  const rmAddOnPost = (post: any) => {
    dispatch(rmAdAdminLeogramPostFetch(post._id));
  };

  return (
    <div>
      <h1>Leogramm Posts</h1>

      <TextField
        label="Suche"
        type="text"
        value={search}
        onChange={(ev: any) => setSearch(ev.target.value)}
      />

      <Button variant="outlined" onClick={execSearch}>
        Suche starten
      </Button>

      <div css={containerStyle}>
        {posts.map((post) => {
          const textLines: string[] = post.text.split('\n');
          return (
            <div
              css={[postContainerStyle, post.deletedByAdmin && deletedStyle]}
            >
              <div css={titleStyle}>
                {post.displayName} ({post.userTag} -{' '}
                {format(
                  new Date(post.created),
                  'd.MM.yyyy HH:mm',
                  TIME_OPTIONS
                )}
                )
                <div css={btnsStyle}>
                  <Button variant="outlined" onClick={() => rmAddOnPost(post)}>
                    Werbung entfernen
                  </Button>
                  <Button variant="outlined" onClick={() => deletePost(post)}>
                    Löschen
                  </Button>
                </div>
              </div>
              {post.images.map((image: any) => (
                <img src={image.url} />
              ))}
              <div css={textStyle}>
                {textLines.map((entry, index) => (
                  <>
                    {entry}
                    {index < textLines.length - 1 && <br key={index} />}
                  </>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      <Button variant="outlined" onClick={loadMore}>
        Weitere Posts laden
      </Button>
    </div>
  );
};
