import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find, pathEq, pathOr, pipe, propEq } from 'ramda';
import {
  changeAdminVehicleNumberplate,
  changeAdminVehicleOwner,
  garageAdminVehicle,
  removeAdminVehicleItem,
  repairAdminVehicle,
  searchOwnerAdminVehicle,
  startAdminVehicleFetch,
} from '../../../actions';
import {
  appsAdminVehiclDetailP,
  appsAdminVehiclOwnerSearchP,
} from '../../../redux/reducer/apps/admin/vehicle';
import { VehicleDetailType, VehicleOwnerSearchEntry } from './types/vehicle';
import { Button, TextField } from '@mui/material';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Autocomplete } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { DateTime } from 'luxon';
import { PlayerInventoryEntry } from './types/playerinventory';
import { translate } from '../../../utils/translate';

const exteriorColors = require('../../../resources/stores/mechanic/exterieurcolors');

const garagesConfig = require('../../../resources/garages/garages.json');

const itemContainerStyle = css`
  padding: 10px;
`;
const subItemsStyle = css`
  padding-left: 10px;
`;

const vehicleColor = (color: string) => css`
  width: 1.5vh;
  height: 1.5vh;
  display: inline-block;
  background-color: ${color};
  border-radius: 50%;
  margin-right: 0.5vh;
`;

const renderItems = (
  items: PlayerInventoryEntry[],
  inGarage: boolean,
  rmFn?: Function
) => (
  <div>
    {items.map((entry) => (
      <div key={entry._id}>
        <div>
          {entry.amount}x {translate(entry.hash)}{' '}
          {entry.data?.label && '- ' + entry.data.label}
          {'    '}
          {inGarage && rmFn && (
            <span onClick={() => rmFn(entry._id, entry.hash, entry.amount)}>
              x
            </span>
          )}
        </div>
        {entry.subitems && entry.subitems.length > 0 && (
          <div css={subItemsStyle}>{renderItems(entry.subitems, inGarage)}</div>
        )}
      </div>
    ))}
  </div>
);

type VehicleType = {
  _id: string;
  openPlayer: (_id: string, playerName: string) => void;
  openFactory: (_id: string, label: string) => void;
};
export const VehicleView = ({ _id, openPlayer, openFactory }: VehicleType) => {
  const [isNPChangeActive, setNPChangeActive] = useState(false);
  const [numberplate, setNumberplate] = useState('');
  const [isGarageEditing, setGarageEditing] = useState(false);
  const [garagePosition, setGaragePosition] = useState('');
  const [isOwnerEditing, setOwnerEditing] = useState(false);
  const [ownerInputValue, setOwnerInputValue] = useState<string>('');
  const [
    ownerInputSelected,
    setOwnerInputSelected,
  ] = useState<null | VehicleOwnerSearchEntry>(null);

  const dispatch = useDispatch();
  const vehicle = useSelector<any, VehicleDetailType | undefined>(
    pathOr(undefined, appsAdminVehiclDetailP)
  );
  const ownerSearch = useSelector<any, VehicleOwnerSearchEntry[]>(
    pathOr([], appsAdminVehiclOwnerSearchP)
  );

  useEffect(() => {
    if (_id) {
      dispatch(startAdminVehicleFetch(_id));
    }
  }, [_id]);

  useEffect(() => {
    if (vehicle) {
      setNumberplate(vehicle.numberPlate);
      setGaragePosition(vehicle.garagePosition);
      setNPChangeActive(false);
      setGarageEditing(false);
      setOwnerEditing(false);
      setOwnerInputValue('');
      setOwnerInputSelected(null);
    }
  }, [vehicle]);
  const refresh = () => {
    dispatch(startAdminVehicleFetch(_id));
  };

  const repair = () => {
    if (vehicle?.inGarage) {
      dispatch(repairAdminVehicle(_id));
    }
  };

  const changeNumberplate = () => {
    if (numberplate.length >= 3 && numberplate.length <= 8) {
      dispatch(changeAdminVehicleNumberplate(_id, numberplate.toLowerCase()));
      setNPChangeActive(false);
    }
  };

  const garage = () => {
    dispatch(garageAdminVehicle(_id, garagePosition));
  };

  const rmItem = (id: string, hash: string, amount: number) => {
    dispatch(removeAdminVehicleItem(_id, id, hash, amount));
  };

  const searchNewOwner = (ev: any) => {
    const query = pathOr('', ['target', 'value'], ev);
    // @ts-ignore
    if (isNaN(query)) {
      setOwnerInputValue(query);
    }
    if (query.length > 2) {
      dispatch(searchOwnerAdminVehicle(query));
    }
  };

  const changeOwner = () => {
    if (vehicle?.inGarage && ownerInputSelected) {
      dispatch(changeAdminVehicleOwner(_id, ownerInputSelected.id));
    }
  };

  if (!vehicle) {
    return null;
  }

  const firstColorValue = vehicle.tunning?.color?.first ?? 0;
  const firstColor =
    typeof firstColorValue === 'object'
      ? `rgb(${firstColorValue.r}, ${firstColorValue.b}, ${firstColorValue.g})`
      : pipe(
          (x: number) =>
            find(pathEq(['value'], x), exteriorColors.specialColors),
          pathOr('#000', ['hex'])
        )(firstColorValue);

  const secondColorValue = vehicle.tunning?.color?.second ?? 0;
  const secondColor =
    typeof secondColorValue === 'object'
      ? `rgb(${secondColorValue.r}, ${secondColorValue.b}, ${secondColorValue.g})`
      : pipe(
          (x: number) =>
            find(pathEq(['value'], x), exteriorColors.specialColors),
          pathOr('#000', ['hex'])
        )(secondColorValue);

  return (
    <div>
      <h1>Fahrzeug: {vehicle.numberPlate}</h1>

      <Button variant="outlined" onClick={refresh}>
        Aktualisieren <CachedIcon />
      </Button>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Fahrzeug Name</TableCell>
            <TableCell>{vehicle.name}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          {vehicle.owner && (
            <TableRow>
              <TableCell>Privater Besitzer</TableCell>
              <TableCell
                onClick={() =>
                  !isOwnerEditing &&
                  openPlayer(
                    vehicle.owner?._id ?? '',
                    vehicle.owner?.name ?? ''
                  )
                }
              >
                {isOwnerEditing ? (
                  <Autocomplete
                    options={ownerSearch}
                    getOptionLabel={(option) => option.text}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    value={ownerInputSelected}
                    onChange={(ev, newValue) => {
                      setOwnerInputSelected(newValue);
                      setOwnerInputValue(newValue?.text ?? '');
                    }}
                    inputValue={ownerInputValue}
                    onInputChange={searchNewOwner}
                  />
                ) : (
                  vehicle.owner.name
                )}
              </TableCell>
              <TableCell>
                {isOwnerEditing ? (
                  <Button variant="outlined" onClick={changeOwner}>
                    Anwenden
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => vehicle.inGarage && setOwnerEditing(true)}
                    disabled={!vehicle.inGarage}
                  >
                    Ändern
                  </Button>
                )}
              </TableCell>
            </TableRow>
          )}
          {vehicle.team && (
            <TableRow>
              <TableCell>Fraktionsbesitzer</TableCell>
              <TableCell>{vehicle.team?.label}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          {vehicle.factory && (
            <TableRow>
              <TableCell>Firmenbesitzer</TableCell>
              <TableCell
                onClick={() =>
                  vehicle.factory &&
                  openFactory(vehicle.factory._id, vehicle.factory.label)
                }
              >
                {vehicle.factory?.label}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Kennzeichen</TableCell>
            <TableCell>
              {isNPChangeActive ? (
                <TextField
                  type="text"
                  value={numberplate}
                  onChange={(ev: any) => setNumberplate(ev.target.value)}
                />
              ) : (
                vehicle.numberPlate
              )}
            </TableCell>
            <TableCell>
              {isNPChangeActive ? (
                <Button
                  variant="outlined"
                  onClick={changeNumberplate}
                  disabled={numberplate.length < 3 || numberplate.length > 8}
                >
                  Speichern
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => vehicle.inGarage && setNPChangeActive(true)}
                  disabled={!vehicle.inGarage}
                >
                  Ändern
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Position</TableCell>
            <TableCell>
              {vehicle.position.x.toFixed(2)} {vehicle.position.y.toFixed(2)}{' '}
              {vehicle.position.z.toFixed(2)}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Dimension</TableCell>
            <TableCell>{vehicle.dimension}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Lackierung</TableCell>
            <TableCell>
              <div css={() => vehicleColor(firstColor)} />
              <div css={() => vehicleColor(secondColor)} />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tank</TableCell>
            <TableCell>{vehicle.fuel.toFixed(1)}l</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Karosserie Gesundheit</TableCell>
            <TableCell>{vehicle.hpBody.toFixed(1)}%</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Motor Gesundheit</TableCell>
            <TableCell>{vehicle.hpEngine.toFixed(1)}%</TableCell>
            <TableCell>
              <Button
                variant="outlined"
                onClick={repair}
                disabled={!vehicle.inGarage}
              >
                Reparieren
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Laufleistung</TableCell>
            <TableCell>{(vehicle.distance / 1000).toFixed(1)} km</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Garage</TableCell>
            <TableCell>
              {!isGarageEditing ? (
                vehicle.inGarage ? (
                  translate(vehicle.garagePosition)
                ) : (
                  'x'
                )
              ) : (
                <Autocomplete
                  options={garagesConfig}
                  getOptionLabel={(option: any) => translate(option.hash)}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  value={find(propEq('hash', garagePosition), garagesConfig)}
                  onChange={(ev, newValue) =>
                    setGaragePosition(newValue?.hash ?? '')
                  }
                />
              )}
            </TableCell>
            <TableCell>
              {isGarageEditing ? (
                <Button
                  variant="outlined"
                  onClick={garage}
                  disabled={garagePosition.length < 4}
                >
                  Abschliessen
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setGarageEditing(true)}
                >
                  Einparken
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hersteller</TableCell>
            <TableCell>{vehicle.constructorName}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Herstelldatum</TableCell>
            <TableCell>
              {DateTime.fromJSDate(new Date(vehicle.constructionDate))
                .setLocale('de')
                .toFormat('dd.LL.yy, HH:mm')}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>Kofferraum</h2>
      {vehicle.items.length > 0 && (
        <div css={itemContainerStyle}>
          {renderItems(vehicle.items, vehicle.inGarage, rmItem)}
        </div>
      )}
    </div>
  );
};
