import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { fetchLspdVehicleSearch, setLspdVehicleSearch } from '../../../actions';
import {
  lspdVehicleSearchDetailsP,
  lspdVehicleSearchP,
} from '../../../redux/reducer/apps/lspd';
import { VehicleSearchData } from './types';
import { css } from '@emotion/react';
import { renderVehColor } from './vehcolor.utils';

const containerStyle = css`
  margin-top: 3vh;
`;

const cursorStyle = css`
  cursor: pointer;
`;

type VehicleSearchType = {
  openPerson: (_id: string, label: string) => void;
};

export const VehicleSearch = ({ openPerson }: VehicleSearchType) => {
  const dispatch = useDispatch();
  const search = useSelector(pathOr('', lspdVehicleSearchP));
  const data = useSelector<any, null | VehicleSearchData>(
    pathOr(null, lspdVehicleSearchDetailsP)
  );

  const setSearch = (search: string) => {
    dispatch(setLspdVehicleSearch(search));
  };

  const fetchData = () => {
    dispatch(fetchLspdVehicleSearch(search));
  };

  return (
    <div className="w-full h-full">
      <h1>Fahrzeug suchen</h1>
      <div>
        <TextField
          label="Suche"
          type="text"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />
        <Button variant="outlined" onClick={fetchData}>
          Suchen
        </Button>
      </div>

      {data && data.notFound && (
        <div css={containerStyle}>Keine Ergebnisse gefunden</div>
      )}
      {data && !data.notFound && (
        <div css={containerStyle}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Typ</TableCell>
                <TableCell align="center">{data.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Motorisierung</TableCell>
                <TableCell align="center">{data.engine}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Lackierung</TableCell>
                <TableCell align="center">{renderVehColor(data)}</TableCell>
              </TableRow>
              {data.owner && (
                <TableRow>
                  <TableCell>Besitzer</TableCell>
                  <TableCell
                    align="center"
                    css={cursorStyle}
                    onClick={() =>
                      openPerson(data.owner?.name ?? '', data.owner?.name ?? '')
                    }
                  >
                    {data.owner.name}
                  </TableCell>
                </TableRow>
              )}
              {data.team && (
                <TableRow>
                  <TableCell>Fraktion</TableCell>
                  <TableCell align="center">{data.team.label}</TableCell>
                </TableRow>
              )}
              {data.factory && (
                <TableRow>
                  <TableCell>Firma</TableCell>
                  <TableCell align="center">{data.factory.label}</TableCell>
                </TableRow>
              )}
              {data.constructorName && (
                <TableRow>
                  <TableCell>Autohaus</TableCell>
                  <TableCell align="center">{data.constructorName}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
