import { pathOr } from 'ramda';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import CheckCircle from '@mui/icons-material/CheckCircle';
import {
  setAdminPlayerMaxBankAccounts,
  startAdminPlayerBankAccountsFetch,
  startAdminPlayerMaxBankAccountsFetch,
} from '../../../../actions';
import {
  appsAdminPlayerBackAccountsCompletedP,
  appsAdminPlayerBackAccountsP,
  appsAdminPlayerMaxBankAccountsP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import { PlayerBankAccount } from '../types/playerbankaccounts';
import { detailContainerStyle } from '../styles';

const tHeadStyle = css`
  th {
    width: 150px;
  }
`;
const tBodyStyle = css`
  display: block;
  max-height: 200px;
  overflow: auto;

  td {
    width: 150px;
  }
`;

type BankAccountsType = {
  _id: string;
  openBankAccount: (_id: string, label: string) => void;
};
export const BankAccounts = ({ _id, openBankAccount }: BankAccountsType) => {
  const [maxAcc, setMaxAcc] = useState(0);

  const dispatch = useDispatch();
  const list = useSelector<any, PlayerBankAccount[]>(
    pathOr([], appsAdminPlayerBackAccountsP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerBackAccountsCompletedP)
  );
  const reduxMaxAcc = useSelector<any, number>(
    pathOr(0, appsAdminPlayerMaxBankAccountsP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerBankAccountsFetch(_id, offset));
    dispatch(startAdminPlayerMaxBankAccountsFetch(_id));
  };

  useEffect(() => {
    setMaxAcc(reduxMaxAcc);
  }, [reduxMaxAcc]);

  const updateMaxAcc = () => {
    if (reduxMaxAcc !== maxAcc) {
      dispatch(setAdminPlayerMaxBankAccounts(_id, maxAcc));
    }
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Konten</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <>
          <Table>
            <TableHead css={tHeadStyle}>
              <TableRow>
                <TableCell>Ban</TableCell>
                <TableCell>Money</TableCell>
                <TableCell>Hauptkonto</TableCell>
                <TableCell>gelöscht</TableCell>
              </TableRow>
            </TableHead>
            <TableBody css={tBodyStyle}>
              {list.map((entry) => (
                <TableRow key={entry._id}>
                  <TableCell
                    onClick={() => openBankAccount(entry._id, entry.vban)}
                  >
                    {entry.vban}
                  </TableCell>
                  <TableCell>${entry.money.toFixed(2)}</TableCell>
                  <TableCell>{entry.main && <CheckCircle />}</TableCell>
                  <TableCell>{entry.deleted && <CheckCircle />}</TableCell>
                </TableRow>
              ))}
              {!reachedEndOfList && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Button
                      variant="outlined"
                      onClick={() => onLoad(list.length)}
                    >
                      Weitere Laden
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <div>
            <TextField
              label="Anzahl maximaler Bankkonten"
              type="number"
              value={maxAcc}
              onChange={(ev: any) => setMaxAcc(ev.target.value)}
            />
            <Button
              variant="outlined"
              disabled={maxAcc === reduxMaxAcc}
              onClick={updateMaxAcc}
            >
              Speichern
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
