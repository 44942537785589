import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAdminPlayerBan,
  createAdminPlayerUnban,
  startAdminPlayerBansFetch,
} from '../../../../actions';
import {
  appsAdminPlayerBansCompletedP,
  appsAdminPlayerBansP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { DateTime } from 'luxon';
import { PlayerBansType } from '../types/playerBans';
import Add from '@mui/icons-material/Add';
import { detailContainerStyle } from '../styles';

const tHeadStyle = css`
  th {
    width: 150px;
  }
`;
const tBodyStyle = css`
  display: block;
  max-height: 200px;
  overflow: auto;

  td {
    width: 150px;
  }
`;

const textFieldStyle = css`
  width: 100%;
`;

type BansType = {
  _id: string;
};
export const Bans = ({ _id }: BansType) => {
  const [reason, setReason] = useState('');
  const [banOnlyChar, setBanOnlyChar] = useState(false);
  const [duration, setDuration] = useState('0');
  const [showNew, setShowNew] = useState(false);

  const dispatch = useDispatch();
  const list = useSelector<any, PlayerBansType[]>(
    pathOr([], appsAdminPlayerBansP)
  );
  const hasLoaded = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerBansCompletedP)
  );

  const onLoad = () => {
    dispatch(startAdminPlayerBansFetch(_id));
  };

  const create = () => {
    dispatch(
      createAdminPlayerBan(
        _id,
        reason,
        parseFloat(duration) * 24 * 60,
        banOnlyChar
      )
    );
    setReason('');
    setDuration('0');
    setShowNew(false);
  };

  const abort = () => {
    setShowNew(false);
  };

  const unban = (banEntry: PlayerBansType) => {
    dispatch(createAdminPlayerUnban(banEntry._id, _id));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>
        Bans{' '}
        <Button variant="outlined" onClick={() => setShowNew(true)}>
          <Add />
        </Button>
      </h2>

      {list.length === 0 && !hasLoaded && (
        <Button variant="outlined" onClick={() => onLoad()}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead css={tHeadStyle}>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Benutzer</TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>Grund</TableCell>
              <TableCell>Abgelaufen</TableCell>
              <TableCell>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody css={tBodyStyle}>
            {list.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell>
                  {DateTime.fromJSDate(new Date(entry.date))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>{entry.player?.name}</TableCell>
                <TableCell>{entry.admin?.name}</TableCell>
                <TableCell>{entry.reason}</TableCell>
                <TableCell>
                  {entry.expired
                    ? `Aufgehoben ${entry.unbanBy ? `(${entry.unbanBy})` : ''}`
                    : entry.expire
                    ? DateTime.fromJSDate(new Date(entry.expire))
                        .setLocale('de')
                        .toFormat('dd.LL.yy, HH:mm')
                    : ''}
                </TableCell>
                <TableCell>
                  {!entry.expired && (
                    <Button variant="outlined" onClick={() => unban(entry)}>
                      Entbannen
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {showNew && (
        <>
          <h3>Neuer Ban</h3>

          <TextField
            label="Beschreibung"
            type="text"
            value={reason}
            onChange={(ev: any) => setReason(ev.target.value)}
            css={textFieldStyle}
          />

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  value={banOnlyChar}
                  onChange={() => setBanOnlyChar(!banOnlyChar)}
                />
              }
              label="Nur den Char sperren"
            />
          </div>

          {!banOnlyChar && (
            <TextField
              label="Dauer (0 = permanent)"
              type="number"
              value={duration}
              onChange={(ev: any) => setDuration(ev.target.value)}
            />
          )}

          <Button variant="outlined" onClick={() => create()}>
            Absenden
          </Button>
          <Button variant="outlined" onClick={() => abort()}>
            Abbrechen
          </Button>
        </>
      )}
    </div>
  );
};
