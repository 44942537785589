import React, { useEffect } from 'react';
import { translate } from '../../../utils/translate';
import { CompanyData, ExportData } from './types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Headline from '../../Headline';
import { DateTime } from 'luxon';

type ExportsType = {
  company: CompanyData;
  fetchExports: (id: string) => void;
  exports: ExportData[];
};

export const Exports = ({ company, fetchExports, exports }: ExportsType) => {
  useEffect(() => {
    fetchExports(company._id);
  }, []);

  return (
    <div>
      <Headline>Exportaufträge</Headline>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Anzahl</TableCell>
            <TableCell>Gesamtgewicht</TableCell>
            <TableCell>Preis</TableCell>
            <TableCell>Ablaufdatum</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exports.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.auctionFinishTime))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>{translate(entry.item)}</TableCell>
              <TableCell>{entry.amount}</TableCell>
              <TableCell>{entry.amount * entry.weight}kg</TableCell>
              <TableCell>${(entry.amount * entry.bid).toFixed(2)}</TableCell>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.endTime))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>
                {entry.expired
                  ? `Strafe $${entry.fine.toFixed(2)}`
                  : entry.done
                  ? 'Erfüllt'
                  : 'noch zu erfüllen'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
