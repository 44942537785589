import React, { useState } from 'react';
import { css } from '@emotion/react';
import {
  containerStyle,
  firstContainerStyle,
  secondContainerStyle,
  headerContainer,
  titleStyle,
  logoStyle,
  inputStyle,
  buttonStyle,
  registerButtonStyle,
} from './Login';
// import { ReactComponent as Logo } from '../logo.svg';
import { TextField, Button } from '@mui/material';

export const buttomBgStyle = css`
  position: absolute;
  bottom: 0vh;
  background: url(images/registerBgBottom.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-size: contain;
  width: 100%;
  height: 50%;
`;

const errorStyle = css`
  margin: 0.5vh;
  width: 92%;
  background: none;
  padding: 0.5vh 1vh;
  text-align: center;
  border-radius: 1.5vh;
  color: red;
  font-size: 1.2vh;
  margin-top: 0.8vh;
`;

type RegisterType = {
  register: (
    email: string,
    displayName: string,
    userTag: string,
    password: string,
    passwordRe: string
  ) => void;
  failedText: string;
  goto: any;
};

export const Register = ({ register, failedText, goto }: RegisterType) => {
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [userTag, setUserTag] = useState('');
  const [pass, setPass] = useState('');
  const [passRe, setPassRe] = useState('');

  const executeRegister = () => {
    register(email, displayName, userTag, pass, passRe);
  };

  const onEmailChange = (value: any) => {
    if (!!value.match('([A-Z])([a-z]+)_([A-Z])([a-z]+)@')) {
      setEmail(value + 'redlion-rp.de');
    } else {
      setEmail(value);
    }
  };

  return (
    <div css={containerStyle}>
      <div css={firstContainerStyle}>
        <div css={headerContainer}>
          {/* <Logo css={logoStyle} /> */}
          <div css={titleStyle}>Leogram</div>
        </div>
        <TextField
          css={inputStyle}
          type="text"
          placeholder="Email"
          value={email}
          onChange={(ev: any) => onEmailChange(ev.target.value)}
          variant="standard"
        />
        <TextField
          css={inputStyle}
          type="text"
          placeholder="Anzeigename"
          value={displayName}
          onChange={(ev: any) => setDisplayName(ev.target.value)}
          variant="standard"
        />
        <TextField
          css={inputStyle}
          type="text"
          placeholder="Leogram Tag"
          value={userTag}
          onChange={(ev: any) => setUserTag(ev.target.value)}
          variant="standard"
        />
        <TextField
          css={inputStyle}
          type="password"
          placeholder="Passwort"
          value={pass}
          onChange={(ev: any) => setPass(ev.target.value)}
          variant="standard"
        />
        <TextField
          css={inputStyle}
          type="password"
          placeholder="Passwort wiederholen"
          value={passRe}
          onChange={(ev: any) => setPassRe(ev.target.value)}
          variant="standard"
        />
        <Button
          css={buttonStyle}
          variant="contained"
          onClick={executeRegister}
          disableElevation
        >
          Registrieren
        </Button>
        <div css={errorStyle}>{failedText || ''}</div>
      </div>
      <div css={[firstContainerStyle, secondContainerStyle]}>
        Bereits einen Account?
        <Button onClick={() => goto('/login')} css={registerButtonStyle}>
          Login
        </Button>
      </div>
    </div>
  );
};
