import React from 'react';

const html = `
<p><strong>
		</strong><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Geltungsbereich</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das Korruptionsgesetz findet Anwendung im gesamten Staat San Andreas in den staatlichen Fraktionen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Sanktionen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Art und Umfang von Sanktionen bestimmen die Fraktionen intern.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Externe Sanktionen, wie Geldstrafen und Freiheitsstrafen, werden vom DoJ bestimmt.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Korruption</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. </span></span><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">Missbrauch eines öffentlichen Amts oder einer Funktion in einer Fraktion zugunsten eines anderen oder sich selbst, auf dessen Veranlassung oder in Eigeninitiative, zur Erlangung eines Vorteils für sich oder einen Dritten mit Eintritt oder in Erwartung des Eintritts eines Schadens oder Nachteils für die Allgemeinheit oder die Fraktion ist als Korruption zu verstehen und wird sanktioniert.</span></span></p>

`;

export const KorruptionsGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
