import { concat, lensPath, pathOr, pipe, set } from 'ramda';
import * as types from '../../../types';
const untypedPipe: any = pipe;

const LIST_LOADING_SIZE = 25;

export const setData = (dataP: string[], completedP: string[]) => (
  state: any,
  { data, offset }: any
) => {
  const newData =
    offset === 0
      ? data
      : pipe(pathOr([], dataP), (old) => [...old, ...data])(state);
  return pipe(
    set(lensPath(dataP), newData),
    set(lensPath(completedP), data.length < LIST_LOADING_SIZE)
  )(state);
};
const setDataDirectCompleted = (dataP: string[], completedP: string[]) => (
  state: any,
  { data, offset }: any
) => {
  return pipe(
    set(lensPath(dataP), data),
    set(lensPath(completedP), true)
  )(state);
};

const playerP = ['apps', 'admin', 'player'];

export const appsAdminPlayerIdsP = [...playerP, 'ids'];
export const appsAdminPlayerIdsCompletedP = [...playerP, 'idsCompleted'];
const setIds = setData(appsAdminPlayerIdsP, appsAdminPlayerIdsCompletedP);

export const appsAdminPlayerBackAccountsP = [...playerP, 'bankAccounts'];
export const appsAdminPlayerBackAccountsCompletedP = [
  ...playerP,
  'bankAccountsCompleted',
];
const setBankAccounts = setData(
  appsAdminPlayerBackAccountsP,
  appsAdminPlayerBackAccountsCompletedP
);

export const appsAdminPlayerVehiclesP = [...playerP, 'vehicles'];
export const appsAdminPlayerVehiclesCompletedP = [
  ...playerP,
  'vehiclesCompleted',
];
const setVehicles = setData(
  appsAdminPlayerVehiclesP,
  appsAdminPlayerVehiclesCompletedP
);

export const appsAdminPlayerTelNumbersP = [...playerP, 'telnumbers'];
export const appsAdminPlayerTelNumbersCompletedP = [
  ...playerP,
  'telnumbersCompleted',
];
const setTelNumbers = setData(
  appsAdminPlayerTelNumbersP,
  appsAdminPlayerTelNumbersCompletedP
);

export const appsAdminPlayerItemsP = [...playerP, 'items'];
export const appsAdminPlayerItemsCompletedP = [...playerP, 'itemsCompleted'];
const setItems = setData(appsAdminPlayerItemsP, appsAdminPlayerItemsCompletedP);

export const appsAdminPlayerMultiP = [...playerP, 'multi'];
export const appsAdminPlayerMultiCompletedP = [...playerP, 'multiCompleted'];
const setMulti = setData(appsAdminPlayerMultiP, appsAdminPlayerMultiCompletedP);

export const appsAdminPlayerMultiBP = [...playerP, 'multiB'];
export const appsAdminPlayerMultiBCompletedP = [...playerP, 'multiBCompleted'];
const setMultiB = setDataDirectCompleted(
  appsAdminPlayerMultiBP,
  appsAdminPlayerMultiBCompletedP
);

export const appsAdminPlayerFactoryOwnerP = [...playerP, 'factoryOwner'];
export const appsAdminPlayerFactoryOwnerCompletedP = [
  ...playerP,
  'factoryOwnerCompleted',
];
const setFactoryOwner = setData(
  appsAdminPlayerFactoryOwnerP,
  appsAdminPlayerFactoryOwnerCompletedP
);

export const appsAdminPlayerFactoryEmploymentP = [
  ...playerP,
  'factoryEmployment',
];
export const appsAdminPlayerFactoryEmploymentCompletedP = [
  ...playerP,
  'factoryEmploymentCompleted',
];
const setFactoryEmployment = setData(
  appsAdminPlayerFactoryEmploymentP,
  appsAdminPlayerFactoryEmploymentCompletedP
);

export const appsAdminPlayerApartmentsP = [...playerP, 'apartments'];
export const appsAdminPlayerApartmentsCompletedP = [
  ...playerP,
  'apartmentsCompleted',
];
const setApartments = setData(
  appsAdminPlayerApartmentsP,
  appsAdminPlayerApartmentsCompletedP
);

export const appsAdminPlayerDeathsP = [...playerP, 'deaths'];
export const appsAdminPlayerDeathsCompletedP = [...playerP, 'deathsCompleted'];
const setDeaths = setData(
  appsAdminPlayerDeathsP,
  appsAdminPlayerDeathsCompletedP
);

export const appsAdminPlayerTicketsP = [...playerP, 'tickets'];
export const appsAdminPlayerTicketsCompletedP = [
  ...playerP,
  'ticketsCompleted',
];
const setTickets = setDataDirectCompleted(
  appsAdminPlayerTicketsP,
  appsAdminPlayerTicketsCompletedP
);

export const appsAdminPlayerNotesP = [...playerP, 'notes'];
export const appsAdminPlayerNotesCompletedP = [...playerP, 'notesCompleted'];
const setNotes = setDataDirectCompleted(
  appsAdminPlayerNotesP,
  appsAdminPlayerNotesCompletedP
);

export const appsAdminPlayerWarningsP = [...playerP, 'warnings'];
export const appsAdminPlayerWarningsCompletedP = [
  ...playerP,
  'warningsCompleted',
];
const setWarnings = setDataDirectCompleted(
  appsAdminPlayerWarningsP,
  appsAdminPlayerWarningsCompletedP
);

export const appsAdminPlayerBansP = [...playerP, 'bans'];
export const appsAdminPlayerBansCompletedP = [...playerP, 'bansCompleted'];
const setBans = setDataDirectCompleted(
  appsAdminPlayerBansP,
  appsAdminPlayerBansCompletedP
);

export const appsAdminPlayerDetailsP = ['apps', 'admin', 'player', 'details'];
export const appsAdminPlayerDetailsL = lensPath(appsAdminPlayerDetailsP);
const setDetails = (state: any, { data }: any) => {
  return untypedPipe(
    set(appsAdminPlayerDetailsL, data),
    set(lensPath(appsAdminPlayerIdsP), undefined),
    set(lensPath(appsAdminPlayerIdsCompletedP), undefined),
    set(lensPath(appsAdminPlayerBackAccountsP), undefined),
    set(lensPath(appsAdminPlayerBackAccountsCompletedP), undefined),
    set(lensPath(appsAdminPlayerVehiclesP), undefined),
    set(lensPath(appsAdminPlayerVehiclesCompletedP), undefined),
    set(lensPath(appsAdminPlayerTelNumbersP), undefined),
    set(lensPath(appsAdminPlayerTelNumbersCompletedP), undefined),
    set(lensPath(appsAdminPlayerItemsP), undefined),
    set(lensPath(appsAdminPlayerItemsCompletedP), undefined),
    set(lensPath(appsAdminPlayerMultiP), undefined),
    set(lensPath(appsAdminPlayerMultiCompletedP), undefined),
    set(lensPath(appsAdminPlayerMultiBP), undefined),
    set(lensPath(appsAdminPlayerMultiBCompletedP), undefined),
    set(lensPath(appsAdminPlayerFactoryOwnerP), undefined),
    set(lensPath(appsAdminPlayerFactoryOwnerCompletedP), undefined),
    set(lensPath(appsAdminPlayerFactoryEmploymentP), undefined),
    set(lensPath(appsAdminPlayerFactoryEmploymentCompletedP), undefined),
    set(lensPath(appsAdminPlayerApartmentsP), undefined),
    set(lensPath(appsAdminPlayerApartmentsCompletedP), undefined),
    set(lensPath(appsAdminPlayerDeathsP), undefined),
    set(lensPath(appsAdminPlayerDeathsCompletedP), undefined),
    set(lensPath(appsAdminPlayerTicketsP), undefined),
    set(lensPath(appsAdminPlayerTicketsCompletedP), undefined),
    set(lensPath(appsAdminPlayerNotesP), undefined),
    set(lensPath(appsAdminPlayerNotesCompletedP), undefined),
    set(lensPath(appsAdminPlayerWarningsP), undefined),
    set(lensPath(appsAdminPlayerWarningsCompletedP), undefined),
    set(lensPath(appsAdminPlayerBansP), undefined),
    set(lensPath(appsAdminPlayerBansCompletedP), undefined)
  )(state);
};

export const appsAdminPlayerMaxBankAccountsP = [...playerP, 'maxBankAccounts'];
const setMaxBankAccounts = (state: any, { data }: any) => {
  return set(lensPath(appsAdminPlayerMaxBankAccountsP), data, state);
};

export const appsAdminPlayerReducerTree = {
  [types.ADMIN_PLAYER_DETAILS_FETCH_SUCCESS]: [setDetails],
  [types.ADMIN_PLAYER_IDS_FETCH_SUCCESS]: [setIds],
  [types.ADMIN_PLAYER_BACKACCOUNTS_FETCH_SUCCESS]: [setBankAccounts],
  [types.ADMIN_PLAYER_VEHICLES_FETCH_SUCCESS]: [setVehicles],
  [types.ADMIN_PLAYER_TELNUMBERS_FETCH_SUCCESS]: [setTelNumbers],
  [types.ADMIN_PLAYER_INVENTORY_FETCH_SUCCESS]: [setItems],
  [types.ADMIN_PLAYER_MULTIACCOUNT_FETCH_SUCCESS]: [setMulti],
  [types.ADMIN_PLAYER_MULTIACCOUNTB_FETCH_SUCCESS]: [setMultiB],
  [types.ADMIN_PLAYER_FACTORYOWNER_FETCH_SUCCESS]: [setFactoryOwner],
  [types.ADMIN_PLAYER_FACTORYEMPLOYMENT_FETCH_SUCCESS]: [setFactoryEmployment],
  [types.ADMIN_PLAYER_APARTMENTS_FETCH_SUCCESS]: [setApartments],
  [types.ADMIN_PLAYER_DEATHS_FETCH_SUCCESS]: [setDeaths],
  [types.ADMIN_PLAYER_TICKETS_FETCH_SUCCESS]: [setTickets],
  [types.ADMIN_PLAYER_NOTES_FETCH_SUCCESS]: [setNotes],
  [types.ADMIN_PLAYER_WARNINGS_FETCH_SUCCESS]: [setWarnings],
  [types.ADMIN_PLAYER_BANS_FETCH_SUCCESS]: [setBans],
  [types.ADMIN_PLAYER_MAX_BANKACCOUNT_FETCH_SUCCESS]: [setMaxBankAccounts],
};
