import { concat, lensPath, pathOr, pipe, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminPermissionsP = ['apps', 'admin', 'permissions'];
export const appsAdminPermissionsL = lensPath(appsAdminPermissionsP);
const setPermissions = (state: any, { data }: any) => {
  return set(appsAdminPermissionsL, data, state);
};

export const appsAdminUserRightsReducerTree = {
  [types.ADMIN_PERMISSIONS_FETCH_SUCCESS]: [setPermissions],
};
