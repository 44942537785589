import React from 'react';
import { connect } from 'react-redux';
import { path, pathOr, pipe } from 'ramda';
import { Finance } from '../../components/apps/Finance';
import {
  startBankAccountAccessAdd,
  startBankAccountAccessEdit,
  startBankAccountAccessFetch,
  startBankAccountAccessRemove,
  startBankAccountDelete,
  startBankAccountMakeMain,
  startBankAccountPinChange,
  startBankAccountTransaction,
  startFetchBankAccounts,
  startFetchBankAccountTransactions,
  startFetchBanks,
} from '../../actions/finance';
import {
  appsFinanceAccessP,
  appsFinanceAccountsP,
  appsFinanceBanksP,
  appsFinanceTransactionsP,
} from '../../redux/reducer/apps/finance';
import { darkP } from '../../redux/reducer/common';

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
    banks: pathOr([], appsFinanceBanksP, state),
    accounts: pathOr([], appsFinanceAccountsP, state),
    transactions: pathOr({}, appsFinanceTransactionsP, state),
    access: pathOr({}, appsFinanceAccessP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchBanks: pipe(startFetchBanks, dispatch),
  fetchAccounts: pipe(startFetchBankAccounts, dispatch),
  fetchTransactions: pipe(startFetchBankAccountTransactions, dispatch),
  // @ts-ignore
  changePin: pipe(startBankAccountPinChange, dispatch),
  makeMain: pipe(startBankAccountMakeMain, dispatch),
  deleteAccount: pipe(startBankAccountDelete, dispatch),
  // @ts-ignore
  transfer: pipe(startBankAccountTransaction, dispatch),
  fetchAccess: pipe(startBankAccountAccessFetch, dispatch),
  addAccess: pipe(startBankAccountAccessAdd, dispatch),
  rmAccess: pipe(startBankAccountAccessRemove, dispatch),
  // @ts-ignore
  editAccess: pipe(startBankAccountAccessEdit, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Finance);
