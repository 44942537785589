export const LEOGRAM_POST_CREATE_START = 'LEOGRAM_POST_CREATE_START';
export const LEOGRAM_POST_CREATE_SUCCESS = 'LEOGRAM_POST_CREATE_SUCCESS';
export const LEOGRAM_POST_CREATE_FAILED = 'LEOGRAM_POST_CREATE_FAILED';

export const LEOGRAM_POSTS_FETCH_START = 'LEOGRAM_POSTS_FETCH_START';
export const LEOGRAM_POSTS_FETCH_SUCCESS = 'LEOGRAM_POSTS_FETCH_SUCCESS';
export const LEOGRAM_POSTS_FETCH_FAILED = 'LEOGRAM_POSTS_FETCH_FAILED';

export const LEOGRAM_POST_NEW_TEXT_SET = 'LEOGRAM_POST_NEW_TEXT_SET';

export const LEOGRAM_POST_LIKE_START = 'LEOGRAM_POST_LIKE_START';
export const LEOGRAM_POST_LIKE_SUCCESS = 'LEOGRAM_POST_LIKE_SUCCESS';
export const LEOGRAM_POST_LIKE_FAILED = 'LEOGRAM_POST_LIKE_FAILED';

export const LEOGRAM_POST_DISLIKE_START = 'LEOGRAM_POST_DISLIKE_START';
export const LEOGRAM_POST_DISLIKE_SUCCESS = 'LEOGRAM_POST_DISLIKE_SUCCESS';
export const LEOGRAM_POST_DISLIKE_FAILED = 'LEOGRAM_POST_DISLIKE_FAILED';

export const LEOGRAM_POSTS_MORE_FETCH_START = 'LEOGRAM_POSTS_MORE_FETCH_START';
export const LEOGRAM_POSTS_MORE_FETCH_SUCCESS =
  'LEOGRAM_POSTS_MORE_FETCH_SUCCESS';
export const LEOGRAM_POSTS_MORE_FETCH_FAILED =
  'LEOGRAM_POSTS_MORE_FETCH_FAILED';

export const LEOGRAM_PROFILE_POST_LIKE_START =
  'LEOGRAM_PROFILE_POST_LIKE_START';
export const LEOGRAM_PROFILE_POST_LIKE_SUCCESS =
  'LEOGRAM_PROFILE_POST_LIKE_SUCCESS';

export const LEOGRAM_USERTAG_EXISTS_FETCH_START =
  'LEOGRAM_USERTAG_EXISTS_FETCH_START';
export const LEOGRAM_USERTAG_EXISTS_FETCH_SUCCESS =
  'LEOGRAM_USERTAG_EXISTS_FETCH_SUCCESS';
