import { pipe } from 'ramda';
import { connect } from 'react-redux';
import { confirmRegistration } from '../actions';
import { RegisterCode } from '../components/RegisterCode';

const mapStateToProps = (state: any) => {
  return {};
};
const mapDispatchToProps = (dispatch: any) => ({
  confirm: pipe(confirmRegistration, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCode);
