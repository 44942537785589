import { css } from '@emotion/react';
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { startLogout } from '../../actions';
import { MenuItem, MenuItemGroup } from './base';

const zIndexStyle = css`
  z-index: 1000000;
`;

export default function AppleMenu({ toggleAppleMenu, btnRef }: any) {
  const ref = useRef<any>(null);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(startLogout());
  };

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        !btnRef.current.contains(e.target)
      )
        toggleAppleMenu();
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, toggleAppleMenu, btnRef]);

  const restart = () => {
    window.location.reload();
  };

  return (
    <div
      className="fixed top-6 left-4 w-56 bg-gray-200 bg-opacity-90 blur rounded-b-lg shadow-2xl"
      css={zIndexStyle}
      ref={ref}
    >
      {/* <MenuItemGroup>
        <MenuItem>About This Mac</MenuItem>
      </MenuItemGroup>
      <MenuItemGroup>
        <MenuItem>System Preferences...</MenuItem>
        <MenuItem>App Store...</MenuItem>
      </MenuItemGroup>
      <MenuItemGroup>
        <MenuItem>Recent Items</MenuItem>
      </MenuItemGroup>
      <MenuItemGroup>
        <MenuItem>Force Quit...</MenuItem>
      </MenuItemGroup> */}
      <MenuItemGroup border={false}>
        <MenuItem onClick={restart}>Restart...</MenuItem>
        {/* <MenuItem onClick={logout}>Lock Screen</MenuItem> */}
        <MenuItem onClick={logout}>Log Out...</MenuItem>
      </MenuItemGroup>
    </div>
  );
}
