import { set, lensPath, pathOr } from 'ramda';

import * as types from '../types';

export const adsP = ['leogram', 'ads'];
export const adsL = lensPath(adsP);

const setData = (state: any, { posts, offset = 0 }: any) => {
  const old = pathOr([], adsP, state);
  const new_ = offset === 0 ? posts : [...old, ...posts];
  return set(adsL, new_, state);
};

export const adsReducerTree = {
  [types.ADS_FETCH_SUCCESS]: [setData],
};
