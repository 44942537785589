import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import SendIcon from '@mui/icons-material/SendRounded';
import Headline from '../../Headline';

const assignDialogStyle = css`
  margin-top: 2vh;
`;

const inputStyle = css`
  width: 100%;
  max-width: 30vh;
`;

const subHeadlineStyle = css`
  font-size: 1.5vh;
  margin-top: 1vh;
`;

type CatalogEntry = {
  hash: string;
  label: string;
  amountOfMultipleChoiceQuestions: number;
  amountOfFreeTextQuestions: number;
  amountAvailableMultipleChoice: number;
  amountAvailableFreeText: number;
};

type AssignType = {
  fetchCatalogue: () => void;
  catalogue: CatalogEntry[];
  assign: (hash: string, name: string) => void;
};

export const Assign = ({
  fetchCatalogue,
  catalogue = [],
  assign,
}: AssignType) => {
  const [selected, setSelected] = useState<CatalogEntry | null>(null);
  const [assignTo, setAssignTo] = useState('');

  useEffect(() => {
    fetchCatalogue();
  }, []);

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Verfügbare Multiple Choice Fragen</TableCell>
            <TableCell>Ausgewählte Multiple Choice Fragen</TableCell>
            <TableCell>Verfügbare Multiple Freitext Fragen</TableCell>
            <TableCell>Ausgewählte Multiple Freitext Fragen</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {catalogue.map((entry) => (
            <TableRow key={entry.hash}>
              <TableCell>{entry.label}</TableCell>
              <TableCell>{entry.amountAvailableMultipleChoice}</TableCell>
              <TableCell>{entry.amountOfMultipleChoiceQuestions}</TableCell>
              <TableCell>{entry.amountAvailableFreeText}</TableCell>
              <TableCell>{entry.amountOfFreeTextQuestions}</TableCell>
              <TableCell>
                <Button css={inputStyle} onClick={() => setSelected(entry)}>
                  Zuweisen
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {selected && (
        <div css={assignDialogStyle}>
          <Headline>{selected.label}</Headline>
          <div>
            <h2 css={subHeadlineStyle}>Zuweisen an</h2>
            <TextField
              css={inputStyle}
              type="text"
              placeholder="Max_Mustermann"
              value={assignTo}
              onChange={(ev) => setAssignTo(ev.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    css={{ cursor: 'pointer' }}
                    onClick={() => setAssignTo('')}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <Button
              css={inputStyle}
              onClick={() => assign(selected.hash, assignTo)}
              startIcon={<SendIcon />}
            >
              Zuweisen
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
