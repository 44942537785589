import React, { useState, useEffect } from 'react';
import { FaPaw } from 'react-icons/fa';
import { Sidebar } from '../../sidebars/Sidebar';
import { Members } from './Members';
import { Salary } from './Salary';
import { Status } from './Status';
import {
  FractionMemberData,
  FractionPlayerStatus,
  FractionSalaryData,
} from './types';
import { useAuthentication } from '../../../utils/authentication';
import { DutyList } from './DutyList';

const menu = (
  canChangeSalary: boolean,
  canViewManagement: boolean,
  canSeeDuty: boolean
) => [
  {
    id: 'me',
    title: 'Beamtenstatus',
    icon: <FaPaw />,
    excerpt: 'Dein Status in der Behörde.',
  },
  ...(canViewManagement
    ? [
        {
          id: 'management',
          title: 'Mitarbeiter',
          icon: <FaPaw />,
          excerpt: 'Feuere Beamte oder stelle jemanden ein.',
        },
      ]
    : []),
  ...(canChangeSalary
    ? [
        {
          id: 'salary',
          title: 'Gehälter',
          icon: <FaPaw />,
          excerpt: 'Ändere den Verdienst.',
        },
      ]
    : []),
  ...(canSeeDuty
    ? [
        {
          id: 'dutylist',
          title: 'Stempelkarten',
          icon: <FaPaw />,
          excerpt: 'Wann haben sich die Mitarbeiter eingestempelt',
        },
      ]
    : []),
  // {
  //   id: 'finance',
  //   title: 'Finanzen',
  //   icon: <FaPaw />,
  //   excerpt: 'Wie viel Geld kann deine Behörde noch ausgeben?',
  // },
];

type FractionType = {
  fetchPlayerStatus: () => void;
  playerStatus: FractionPlayerStatus | undefined;
  fetchMembers: () => void;
  members: FractionMemberData[];
  changeRank: (name: string, rank: number) => void;
  fire: (name: string) => void;
  fetchSalary: () => void;
  salary: FractionSalaryData[];
};

export const Fraction = ({
  fetchPlayerStatus,
  playerStatus,
  fetchMembers,
  members,
  changeRank,
  fire,
  fetchSalary,
  salary,
}: FractionType) => {
  const [selectedMenu, setSelectedMenu] = useState('me');
  const [hasRight] = useAuthentication();

  useEffect(() => {
    fetchPlayerStatus();
  }, []);

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      <div className="flex-none w-44">
        <Sidebar
          items={menu(
            hasRight('team.management.changeSalary'),
            hasRight('team.management.viewManagementPage'),
            hasRight('team.duty')
          )}
          selected={selectedMenu}
          select={setSelectedMenu}
        />
      </div>
      <div className="flex-grow">
        <div className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6">
          {selectedMenu === 'me' && playerStatus && (
            <Status playerStatus={playerStatus} />
          )}
          {selectedMenu === 'management' && playerStatus && (
            <Members
              fetchMembers={fetchMembers}
              members={members}
              changeRank={changeRank}
              fire={fire}
            />
          )}
          {selectedMenu === 'salary' && playerStatus && (
            <Salary fetchSalary={fetchSalary} salary={salary} />
          )}
          {selectedMenu === 'dutylist' && playerStatus && <DutyList />}
        </div>
      </div>
    </div>
  );
};
