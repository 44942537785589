import { toast } from 'react-toastify';
import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../utils/fetch';
import { notifyError, notifySuccess } from '../utils';

export const onFetchBanks = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANKS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/bank')
        .then((data) => [
          {
            type: types.FINANCE_BANKS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch((res) => ({
          type: types.FINANCE_BANKS_FETCH_FAILED,
        }));
    })
  );

export const onFetchBanksFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANKS_FETCH_FAILED),
    mapC(() => {
      toast.error('Banken konnten nicht abgefragt werden');
    })
  );

export const onCreateBankAccount = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_CREATE_START),
    mapPromiseC(({ bankHash, note, pin, accountName }: any) => {
      return authFetchPost(`/bank/${bankHash}/createAccount`, {
        note,
        pin,
        accountName,
      })
        .then(() => [
          notifySuccess('Konto erstellt'),
          {
            type: types.FINANCE_BANK_ACCOUNTS_FETCH_START,
          },
        ])
        .catch(() => notifyError('Kontoerstellung fehlgeschlagen'));
    })
  );

export const onFetchAccounts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNTS_FETCH_START),
    mapPromiseC(() => {
      return Promise.all([
        authFetchJson('/bankAccounts').catch(() => []),
        authFetchJson('/businessBankAccounts').catch(() => []),
        authFetchJson('/teamBankAccounts').catch(() => []),
      ])
        .then(([_private, business, team]: any[]) => [
          {
            type: types.FINANCE_BANK_ACCOUNTS_FETCH_SUCCESS,
            data: [..._private, ...business, ...team].filter(
              (x: any) => !x.deleted
            ),
          },
        ])
        .catch((res) => ({
          type: types.FINANCE_BANK_ACCOUNTS_FETCH_FAILED,
        }));
    })
  );

export const onFetchAccountTransactions = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_START),
    mapPromiseC(({ vban, offset }: any) => {
      return authFetchJson(
        `/bankAccounts/${vban}/transactions?offset=${offset}`
      )
        .then((data) => [
          {
            type: types.FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_SUCCESS,
            vban,
            offset,
            data,
          },
        ])
        .catch((res) => ({
          type: types.FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_FAILED,
        }));
    })
  );

export const onPinChangeStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_PIN_CHANGE_START),
    mapPromiseC(({ vban, currentPin, newPin, newPinRe }: any) => {
      if (newPin.length !== 4 || isNaN(newPin)) {
        return [
          {
            type: types.FINANCE_BANK_ACCOUNT_PIN_CHANGE_FAILED,
            msg: 'Der PIN muss aus 4 Zahlen bestehen',
          },
        ];
      }

      if (newPin !== newPinRe) {
        return [
          {
            type: types.FINANCE_BANK_ACCOUNT_PIN_CHANGE_FAILED,
            msg: 'Neuer Pin wurde nicht korrekt erneut eingegeben',
          },
        ];
      }

      return authFetchPost('/bank/pin/change', {
        vban,
        oldpin: currentPin,
        newpin: newPin,
      })
        .then(() => [
          {
            type: types.FINANCE_BANK_ACCOUNT_PIN_CHANGE_SUCCESS,
          },
        ])
        .catch((res) => {
          return res.text().then((err: any) => {
            console.log(err);
            return {
              type: types.FINANCE_BANK_ACCOUNT_PIN_CHANGE_FAILED,
              msg: 'Ändern des PINs fehlgeschlagen: ' + err,
            };
          });
        });
    })
  );

export const onPinChangeStartSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_PIN_CHANGE_SUCCESS),
    mapC(() => {
      toast.success('PIN erfolgreich geändert');
    })
  );
export const onPinChangeStartFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_PIN_CHANGE_FAILED),
    mapC(({ msg }: any) => {
      toast.error(msg);
    })
  );

export const onMakeMainStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_MAIN_SET_START),
    mapPromiseC(({ vban, pin }: any) => {
      if (pin.length !== 4 || isNaN(pin)) {
        return [
          {
            type: types.FINANCE_BANK_ACCOUNT_MAIN_SET_FAILED,
            msg: 'Falscher PIN',
          },
        ];
      }

      return authFetchPost('/bank/makeMainAccount', {
        vban,
      })
        .then(() => [
          {
            type: types.FINANCE_BANK_ACCOUNT_MAIN_SET_SUCCESS,
            vban,
          },
          {
            type: types.FINANCE_BANK_ACCOUNTS_FETCH_START,
          },
        ])
        .catch((res) => {
          return {
            type: types.FINANCE_BANK_ACCOUNT_MAIN_SET_FAILED,
            vban,
          };
        });
    })
  );

export const onMakeMainSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_MAIN_SET_SUCCESS),
    mapC(({ vban }: any) => {
      toast.success(`Konto ${vban} erfolgreich zum Hauptkonto gemacht`);
    })
  );
export const onMakeMainFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_MAIN_SET_FAILED),
    mapC(({ vban, msg }: any) => {
      toast.error(msg || `Fehler bei Konto ${vban}`);
    })
  );

export const onDeleteAccountStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_DELETE_START),
    mapPromiseC(({ vban, pin }: any) => {
      if (pin.length !== 4 || isNaN(pin)) {
        return [
          {
            type: types.FINANCE_BANK_ACCOUNT_DELETE_FAILED,
            msg: 'Falscher PIN',
          },
        ];
      }

      return authFetchPost('/bank/deleteAccount', {
        vban,
      })
        .then(() => [
          {
            type: types.FINANCE_BANK_ACCOUNT_DELETE_SUCCESS,
            vban,
          },
          {
            type: types.FINANCE_BANK_ACCOUNTS_FETCH_START,
          },
        ])
        .catch((res) => {
          return {
            type: types.FINANCE_BANK_ACCOUNT_DELETE_FAILED,
            vban,
          };
        });
    })
  );

export const onDeleteAccountSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_DELETE_SUCCESS),
    mapC(({ vban }: any) => {
      toast.success(`Konto ${vban} erfolgreich gelöscht`);
    })
  );
export const onDeleteAccountFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_DELETE_FAILED),
    mapC(({ vban, msg }: any) => {
      toast.error(msg || `Fehler beim Löschen von Konto ${vban}`);
    })
  );

export const onTransactionStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_START),
    mapPromiseC(({ sourceVban, destinationVban, amount, reference }: any) => {
      return authFetchPost('/bank/transaction/add', {
        source: sourceVban,
        destination: destinationVban,
        amount,
        reference,
      })
        .then(() => [
          {
            type: types.FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_SUCCESS,
          },
          {
            type: types.FINANCE_BANK_ACCOUNTS_FETCH_START,
          },
          {
            type: types.FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_START,
            vban: sourceVban,
            offset: 0,
          },
        ])
        .catch(() => {
          return {
            type: types.FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_FAILED,
          };
        });
    })
  );

export const onTransactionSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_SUCCESS),
    mapC(() => {
      toast.success('Überweisung erfolgreich ausgeführt');
    })
  );
export const onTransactionFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_FAILED),
    mapC(() => {
      toast.error('Überweisung fehlgeschlagen');
    })
  );

export const onAccessFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_GET_START),
    mapPromiseC(({ vban }: any) => {
      return authFetchJson(`/bank/accountAccess/get/${vban}`)
        .then((data) => [
          {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_GET_SUCCESS,
            vban,
            data,
          },
        ])
        .catch(() => {
          return {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_GET_FAILED,
          };
        });
    })
  );

export const onAccessAddStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_ADD_START),
    mapPromiseC(({ vban, playerName }: any) => {
      return authFetchPost('/bank/accountAccess/add', {
        account: vban,
        userToAdd: playerName,
      })
        .then((data) => [
          {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_ADD_SUCCESS,
            playerName,
          },
          {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_GET_START,
            vban,
          },
        ])
        .catch(() => {
          return {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_ADD_FAILED,
          };
        });
    })
  );

export const onAccessAddSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_ADD_SUCCESS),
    mapC(({ playerName }: any) => {
      toast.success(`Zugriff für ${playerName} erfolgreich hinzugefügt`);
    })
  );
export const onAccessAddFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_ADD_FAILED),
    mapC(() => {
      toast.error('Hinzufügen fehlgeschlagen');
    })
  );

export const onAccessRemoveStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_START),
    mapPromiseC(({ _id, vban }: any) => {
      return authFetchPost('/bank/accountAccess/remove', {
        accessRightId: _id,
      })
        .then(() => [
          {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_SUCCESS,
          },
          {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_GET_START,
            vban,
          },
        ])
        .catch(() => {
          return {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_FAILED,
          };
        });
    })
  );

export const onAccessRemoveSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_SUCCESS),
    mapC(() => {
      toast.success(`Zugriff entfernt`);
    })
  );
export const onAccessRemoveFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_FAILED),
    mapC(() => {
      toast.error('Entfernen fehlgeschlagen');
    })
  );

export const onAccessEditStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_EDIT_START),
    mapPromiseC(({ _id, vban, accessRightType, newValue }: any) => {
      return authFetchPost('/bank/accountAccess/edit', {
        accessRightId: _id,
        accessRightType,
        newValue,
      })
        .then(() => [
          {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_EDIT_SUCCESS,
          },
          {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_GET_START,
            vban,
          },
        ])
        .catch(() => {
          return {
            type: types.FINANCE_BANK_ACCOUNT_ACCESS_EDIT_FAILED,
          };
        });
    })
  );

export const onAccessEditFailed = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_FAILED),
    mapC(() => {
      toast.error('Recht ändern fehlgeschlagen');
    })
  );
