import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminSearchP = ['apps', 'admin', 'playersearch', 'search'];
export const appsAdminSearchL = lensPath(appsAdminSearchP);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminSearchL, search, state);
};

export const appsAdminSearchResultsP = [
  'apps',
  'admin',
  'playersearch',
  'results',
];
export const appsAdminSearchResultsL = lensPath(appsAdminSearchResultsP);
const setSearchResults = (state: any, { data }: any) => {
  return set(appsAdminSearchResultsL, data, state);
};

export const appsAdminPlayerSearchReducerTree = {
  [types.ADMIN_PLAYER_SEARCH_SET]: [setSearch],
  [types.ADMIN_PLAYER_SEARCH_FETCH_SUCCESS]: [setSearchResults],
};
