import * as type from '../redux/types';

export const fetchDutyOverview = () => ({
  type: type.FRACTION_OVERVIEW_DUTY_FETCH_START,
});

export const fetchLsmdMapVehicles = () => ({
  type: type.LSMD_MAP_VEHICLES_FETCH_START,
});

export const fetchLsmdMapCalls = () => ({
  type: type.LSMD_MAP_CALLS_FETCH_START,
});

export const listOldLsmdFiles = (search: string) => ({
  type: type.LSMD_OLDFILES_LIST_START,
  search,
});
export const fetchOldLsmdFile = (_id: string) => ({
  type: type.LSMD_OLDFILE_FETCH_START,
  _id,
});
