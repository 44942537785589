import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import {
  authFetch,
  authFetchDelete,
  authFetchJson,
  authFetchPost,
} from '../../utils/fetch';

export const onMailSend = (action$: any) =>
  pipeC(
    action$,
    ofType(types.MAIL_SEND_START),
    mapPromiseC(({ receivers, subject, body }: any) => {
      return authFetchPost('/mail', { receivers, subject, body })
        .then(() => [
          {
            type: types.MAIL_SEND_SUCCESS,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Mail versendet',
          },
        ])
        .catch(() => [
          {
            type: types.MAIL_SEND_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Senden der Mail',
          },
        ]);
    })
  );

const createListEffect = (
  startEvent: string,
  successEvent: string,
  failedEvent: string,
  url: string,
  failedMsg: string
) => (action$: any) =>
  pipeC(
    action$,
    ofType(startEvent),
    mapPromiseC(({ search, page }: any) => {
      return authFetchJson(`${url}?search=${search}&page=${page}`)
        .then((data) => [
          {
            type: successEvent,
            data,
          },
        ])
        .catch(() => [
          {
            type: failedEvent,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: failedMsg,
          },
        ]);
    })
  );

export const onFetchInbox = createListEffect(
  types.MAIL_INBOX_FETCH_START,
  types.MAIL_INBOX_FETCH_SUCCESS,
  types.MAIL_INBOX_FETCH_FAILED,
  '/mail/private',
  'Fehler beim Abfragen deiner Inbox'
);

export const onFetchOutbox = createListEffect(
  types.MAIL_OUTBOX_FETCH_START,
  types.MAIL_OUTBOX_FETCH_SUCCESS,
  types.MAIL_OUTBOX_FETCH_FAILED,
  '/mail/sent',
  'Fehler beim Abfragen deiner Outbox'
);

export const onFetchTrash = createListEffect(
  types.MAIL_TRASH_FETCH_START,
  types.MAIL_TRASH_FETCH_SUCCESS,
  types.MAIL_TRASH_FETCH_FAILED,
  '/mail/trash',
  'Fehler beim Abfragen des Papierkorbs'
);

export const onMailDelete = (action$: any) =>
  pipeC(
    action$,
    ofType(types.MAIL_DELETE_START),
    mapPromiseC(({ id, followAction }: any) => {
      return authFetchDelete(`/mail/${id}`)
        .then(() => [
          {
            type: types.MAIL_DELETE_SUCCESS,
          },
          {
            type: types.NOTIFY_SUCCESS,
            msg: 'Mail erfolgreich gelöscht',
          },
          followAction,
        ])
        .catch(() => [
          {
            type: types.MAIL_DELETE_FAILED,
          },
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Löschen der Mail',
          },
        ]);
    })
  );

export const onMailRead = (action$: any) =>
  pipeC(
    action$,
    ofType(types.MAIL_READ_START),
    mapPromiseC(({ id, followAction }: any) => {
      return authFetch(`/mail/${id}`)
        .then(() => [
          {
            type: types.MAIL_READ_SUCCESS,
          },
          followAction,
        ])
        .catch(() => [
          {
            type: types.MAIL_READ_FAILED,
          },
        ]);
    })
  );
