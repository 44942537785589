import { set, lensPath, pipe } from 'ramda';
import * as types from '../types';
import { settingsL } from './login';

const updateSettings = (state: any, { settings }: any) => {
  localStorage.setItem('settings', JSON.stringify(settings));
  return set(settingsL, settings, state);
};

export const settingsReducerTree = {
  [types.SETTINGS_UPDATE]: [updateSettings],
};
