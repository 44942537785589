import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Grundsätze</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die MGVO ist von allen medizinischen Beamten des Staates zu befolgen und als Kodex anzusehen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Das LSMD ist eine Staatsfraktion und übt ihren Dienst somit im Sinne der Erhaltung und Pflege des Staates unter Einhaltung der im Staat geltenden Gesetze aus.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Ein Beamter des LSMD hat die Befugnis und Pflicht, in medizinischen sowie katastrophalen Situationen Hilfestellung zu leisten.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Ein Beamter des LSMD handelt immer neutral allen Personen, Menschengruppen und Fraktionen gegenüber.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Einem Beamten des LSMD ist es untersagt, im Dienst Drogen oder Rauschmittel zu konsumieren.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VI.Ein Beamter des LSMD hat den Dienst nur anzutreten, wenn er in der Lage ist, diesen unbedenklich auszuüben.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VII. Ein Beamter des LSMD ist dazu berechtigt, jegliche Medikamente und Betäubungsmittel zum Wohle des Patienten und in passender Menge einzusetzen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VIII. Sollte ein Beamter des LSMD mit Medikamenten arbeiten, so ist ihm der Eigengebrauch und Handel untersagt.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IX. Ein Beamter des LSMD hat sich jederzeit an die Gesetze des Staates San Andreas zu halten.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">X. Der Leitung wird es vorbehalten, Personen mit einer kriminellen Vorgeschichte im Laufe des Bewerbungsverfahrens abzulehnen.</span></span></p><p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">XI. Ein im Dienste des LSMD erworbener Akademischer Titel wird auf Dauer des Beamtenverhältnisses auf unbestimmte Zeit verliehen. Bei Austritt darf dieser nicht weiter benutzt werden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Hausrecht</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Beamten des LSMD haben im Krankenhaus das Hausrecht und dürfen dies jederzeit, auch mit Hilfe des LSPD, durchsetzen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Beschwerden</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Ein Bürger des Staates San Andreas hat jederzeit das Recht, eine Beschwerde gegen einen Beamten des LSMD einzureichen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Datenschutz</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Ein Beamter unterliegt jederzeit der Schweigepflicht.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Sollte ein Beamter kündigen oder entlassen werden, gilt die Schweigepflicht auch weiterhin.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Ein Beamter des LSMD hat Daten eines Patienten nur mit dessen Erlaubnis und in angemessener Weise weiterzugeben.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Die Schweigepflicht entfällt, sollte eine Gefahr für Dritte in der Zukunft oder ein richterlicher Beschluss vorliegen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Bei Gefahr Dritter in der Zukunft entfällt die Schweigepflicht nur gegenüber dem LSPD und dem DoJ. Beim Hilfeersuchen des LSPD durch das LSMD sind Ergebnisse der entsprechenden Maßnahme mitzuteilen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Einwilligung</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. </span></span><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">Vor Durchführung einer medizinischen Maßnahme ist der Behandelnde verpflichtet, die Einwilligung des Patienten einzuholen. Ist der Patient nicht einwilligungsfähig, so kann die Einwilligung über Verwandte erfolgen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Ist der Patient nicht einwilligungsfähig und keine Verwandten konnten die Einwilligung tätigen, wird von dem Überlebenswillen des Patienten ausgegangen. </span></span><span style="color:rgb(255,255,255);"></span></p><p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Dem LSMD ist es bei einem Anfangsverdacht jederzeit erlaubt, sofort eine Blutprobe zu nehmen. Die Auswertung kann der Patient immer einfordern. Die Ergebnisse der Blutprobe darf nur mit einem Beschluss der Justiz (Richterschaft) an das LSPD herausgegeben werden. </span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 6 Informationen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Behandelnde ist verpflichtet, dem Patienten zu Beginn der Behandlung und in deren Verlauf sämtliche für die Behandlung wesentlichen Umstände zu erläutern, insbesondere die Diagnose, die voraussichtliche gesundheitliche Entwicklung, die Therapie und die nach der sowie zur Therapie zu ergreifenden Maßnahmen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 7 Freiheitsbeschränkung</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Freiheit der Person für medizinische Maßnahmen ist nur mit der Einwilligung der betroffenen Person einzuschränken. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Sollte die Person sich im akuten Notstand befinden und die Person nicht einwilligungsfähig sein, kann eine Behandlung direkt durchgeführt werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 8 Medikamente</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Medikamente können verhältnismäßig von den Beamten des LSMD ausgehändigt werden, sofern diese notwendig sind.</span></span></p>
`;

export const MedGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
