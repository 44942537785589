const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'text-align': 'left',
  color: '#fff',
  border: '0 !important',
};
const usedFix = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const select = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const usedCenter = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
//Styles ab const einfügen für sep. - kann auch ohne Eigenschaft wie background genutzt werden
export default [
  [
    {
      value: 'Fuhrpark Blatt', //noch anpassen
      colSpan: 20,
      style: fixFett,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 20,
      readOnly: true,
      width: '200vh',
    },
  ],
  // Abklären ob wir sowas reinhohlen aber erst mal definiert
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: 'Sollbestände',
      style: fixFett,
      colSpan: 8,
      readOnly: true,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
    {
      value: '',
      style: usedCenter,
      colSpan: 8,
      width: '80vh',
    },
    {
      value: '',
      style: empty,
      colSpan: 6,
      readOnly: true,
      width: '60vh',
    },
  ],
  [
    {
      value: '',
      style: empty,
      colSpan: 20,
      readOnly: true,
      width: '200vh',
    },
  ],
  [
    {
      value: 'Modell',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Kennzeichen',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: '>10.000km',
      style: fixFett,
      colSpan: 1,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Defekt',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Vollständig',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
    {
      value: 'Freigabe ab',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Anmerkungen',
      colSpan: 5,
      style: fixFett,
      readOnly: true,
      width: '50vh',
    },
    {
      value: 'Ausrüstung',
      colSpan: 6,
      style: fixFett,
      readOnly: true,
      width: '60vh',
    },
  ],
];
export const lspdVehicleControlLineTemplate = [
  {
    value: '',
    style: usedCenter,
    colSpan: 2,
  },
  {
    value: '',
    style: select,
    readOnly: true,
    colSpan: 2,
    type: 'select',
    depOptions: {
      sheetId: 'lspdcontroldata',
      column: 'A',
      rowStart: 1,
      rowEnd: 110,
    },
  },
  {
    value: false,
    type: 'checkbox',
    colSpan: 1,
    style: usedFix,
    readOnly: true,
    width: '10vh',
  },
  {
    value: false,
    type: 'checkbox',
    colSpan: 1,
    style: usedFix,
    readOnly: true,
    width: '10vh',
  },
  {
    value: false,
    type: 'checkbox',
    colSpan: 1,
    style: usedFix,
    readOnly: true,
    width: '10vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedCenter,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 5,
    style: usedCenter,
    width: '50vh',
  },
  {
    value: '',
    colSpan: 6,
    style: usedCenter,
    width: '60vh',
  },
];
