import { lensPath, set } from 'ramda';
import * as types from '../../types';

export const appsCompanyAllP = ['apps', 'company', 'all'];
export const appsCompanyAllL = lensPath(appsCompanyAllP);
const setCompanyAll = (state: any, { data }: any) => {
  return set(appsCompanyAllL, data, state);
};

export const appsCompanyStorageP = ['apps', 'company', 'selected', 'storage'];
export const appsCompanyStorageL = lensPath(appsCompanyStorageP);
const setCompanyStorage = (state: any, { data }: any) => {
  return set(appsCompanyStorageL, data, state);
};

export const appsCompanyCounterP = ['apps', 'company', 'selected', 'counter'];
export const appsCompanyCounterL = lensPath(appsCompanyCounterP);
const setCompanyCounter = (state: any, { data }: any) => {
  return set(appsCompanyCounterL, data, state);
};

export const appsCompanyCheckInsP = ['apps', 'company', 'selected', 'checkins'];
export const appsCompanyCheckInsL = lensPath(appsCompanyCheckInsP);
const setCompanyCheckIns = (state: any, { data }: any) => {
  return set(appsCompanyCheckInsL, data, state);
};

export const appsCompanyExportsP = ['apps', 'company', 'selected', 'exports'];
export const appsCompanyExportsL = lensPath(appsCompanyExportsP);
const setCompanyExports = (state: any, { data }: any) => {
  return set(appsCompanyExportsL, data, state);
};

export const appsCompanyProductionP = [
  'apps',
  'company',
  'selected',
  'production',
];
export const appsCompanyProductionL = lensPath(appsCompanyProductionP);
const setCompanyProduction = (state: any, { data }: any) => {
  return set(appsCompanyProductionL, data, state);
};

export const appsCompanyByOwnerSimpleP = ['apps', 'company', 'byOwnerSimple'];
export const appsCompanyByOwnerSimpleL = lensPath(appsCompanyByOwnerSimpleP);
const setCompanyByOwnerSimple = (state: any, { data }: any) => {
  return set(appsCompanyByOwnerSimpleL, data, state);
};

export const appsCompanyReducerTree = {
  [types.COMPANIES_FETCH_SUCCESS]: [setCompanyAll],
  [types.COMPANY_STORAGE_FETCH_SUCCESS]: [setCompanyStorage],
  [types.COMPANY_COUNTER_FETCH_SUCCESS]: [setCompanyCounter],
  [types.COMPANY_EMPLOYEE_CHECKIN_FETCH_SUCCESS]: [setCompanyCheckIns],
  [types.COMPANY_EXPORTS_FETCH_SUCCESS]: [setCompanyExports],
  [types.COMPANY_PRODUCTION_FETCH_SUCCESS]: [setCompanyProduction],
  [types.COMANIES_BY_OWNER_SIMPLE_FETCH_SUCCESS]: [setCompanyByOwnerSimple],
};
