import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteFilledIcon from '@mui/icons-material/FavoriteRounded';
import EmojiIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Emojis } from './Emojis';
import { useDispatch, useSelector } from 'react-redux';
import {
  startCreateComment,
  startFetchComments,
  startToggleLikeComment,
} from '../actions';
import { pathOr } from 'ramda';
import { DateTime } from 'luxon';
import { commentsP } from '../store/reducer/comments';
import { getLoggedInUserTag } from '../store/reducer/login';
import { loadMoreContainer, loadMoreStyle } from './Dashboard';
import RenderedText from './RenderedText';

const commentsContainer = css`
  position: fixed;
  top: 7.5vh;
  bottom: 4.5vh;
  left: 0;
  right: 0;
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const commentsList = css`
  padding-top: 2vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.75vh;
  }

  ::-webkit-scrollbar-track {
    background: #00000033;
    transition: all 0.5s ease;
  }

  ::-webkit-scrollbar-thumb {
    background: #d50000aa;
    transition: all 0.5s ease;
  }
`;

const commentStyle = css`
  display: flex;
  align-items: center;
  padding: 1vh 1.5vh;
`;

const dateStyle = css`
  color: #ffffff99;
  font-size: 1.2vh;
  display: flex;
  align-items: center;
  line-height: 1.2vh;
  align-items: center;
`;

const smallLikeIcon = css`
  height: 1.4vh !important;
  width: 1.4vh !important;
  margin-left: 0.75vh;
  margin-right: 0.25vh;
`;

const textContainer = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const textStyle = css`
  padding: 0.25vh 0;
  color: #ffffff;
  font-weight: 100;
  font-size: 1.5vh;
  color: #cccccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  span {
    font-weight: bold;
    color: #ffffff;
  }
`;

const textboxStyle = css`
  width: calc(100% - 1vh);
  min-height: 3.5vh;
  border: none;
  padding: 0.5vh;
  resize: none;
  color: #ffffff;
  font-size: 1.75vh;
  line-height: 1.75vh;
  background-color: #00000066;
  font-weight: 100;
  font-family: Arial;

  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    color: #ffffff66;
  }

  ::-webkit-scrollbar {
    width: 0.75vh;
  }

  ::-webkit-scrollbar-track {
    background: #00000033;
    transition: all 0.5s ease;
  }

  ::-webkit-scrollbar-thumb {
    background: #d50000aa;
    transition: all 0.5s ease;
  }
`;

const optionsStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1vh;
  padding-bottom: 1vh;
`;

const messageIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
  padding: 0.25vh;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;

  :hover {
    background: #ffffff66;
  }
`;

const sendButtonStyle = css`
  background-color: #d50000 !important;
  color: #ffffff !important;
  padding: 0 1vh;
  height: 3vh;
  font-size: 1.25vh;
  :disabled {
    color: #ffffff66 !important;
    box-shadow: none;
    background-color: #d5000066 !important;
  }
`;

const reactionIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
  color: #ffffff;
  padding: 0.25vh;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  :hover {
    background-color: #ffffff33;
  }
`;

const reactionIconDisabledStyle = css`
  :hover {
    background-color: transparent;
  }
`;

const closeIconStyle = css`
  top: 0.25vh;
  right: 2vh;
  position: absolute;
  height: 2.5vh !important;
  width: 2.5vh !important;
  padding: 0.25vh;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  :hover {
    background-color: #ffffff66;
  }
`;

// const demoComments = [
//   {
//     _id: 'a',
//     userTag: 'billy',
//     displayName: 'Billy mit den Grünen Haaren',
//     created: new Date(),
//     text: 'Du hast hier gar nichts zu melden',
//     likeAmount: 1,
//   },
//   {
//     _id: 'b',
//     userTag: 'MCZitrone',
//     displayName: 'Fred',
//     created: new Date(),
//     text: 'Frag Mila',
//     likeAmount: 3,
//   },
// ];

type CommentData = {
  _id: string;
  userTag: string;
  displayName: string;
  created: string;
  text: string;
  likeAmount: number;
  isLiked: boolean;
};

type CommentsType = {
  post: string;
  onClose: () => void;
  goto: (url: string, params: any) => void;
};
const Comments = ({ post, onClose, goto }: CommentsType) => {
  const dispatch = useDispatch();
  const comments = useSelector<any, CommentData[]>(pathOr([], commentsP));

  const [newComment, setNewComment] = useState('');
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const fetchComments = (offset: number) => {
    dispatch(startFetchComments(post, offset));
  };

  const toggleLike = (commentId: string) => {
    dispatch(startToggleLikeComment(commentId));
  };

  const createNewComment = () => {
    dispatch(startCreateComment(post, newComment));
    setNewComment('');
  };

  useEffect(() => {
    fetchComments(0);
  }, [post]);

  return (
    <div css={commentsContainer}>
      <CloseIcon onClick={onClose} css={closeIconStyle} />
      <div css={commentsList}>
        {comments.map((comment) => {
          const isOwnComment = getLoggedInUserTag() === comment.userTag;

          return (
            <div key={comment._id} css={commentStyle}>
              <div css={textContainer}>
                <div css={textStyle}>
                  <div>
                    <span
                      css={{ cursor: 'pointer' }}
                      onClick={() =>
                        goto(`/profilepage`, { userTag: comment.userTag })
                      }
                    >
                      @{comment.userTag}
                    </span>{' '}
                    <RenderedText text={comment.text} goto={goto} />
                  </div>
                </div>
                <span css={dateStyle}>
                  {DateTime.fromISO(comment.created).toRelative()}
                  <FavoriteOutlinedIcon css={smallLikeIcon} />
                  {comment.likeAmount}
                </span>
              </div>
              {comment.isLiked ? (
                <FavoriteFilledIcon
                  css={[
                    reactionIconStyle,
                    isOwnComment && reactionIconDisabledStyle,
                  ]}
                  onClick={() => (isOwnComment ? {} : toggleLike(comment._id))}
                />
              ) : (
                <FavoriteOutlinedIcon
                  css={[
                    reactionIconStyle,
                    isOwnComment && reactionIconDisabledStyle,
                  ]}
                  onClick={() => (isOwnComment ? {} : toggleLike(comment._id))}
                />
              )}
            </div>
          );
        })}
        {comments.length >= 50 && (
          <div
            css={[loadMoreContainer, loadMoreStyle]}
            onClick={() => fetchComments(comments.length)}
          >
            Weitere Laden
          </div>
        )}
      </div>
      <div>
        <textarea
          css={textboxStyle}
          placeholder="Kommentar eingeben..."
          value={newComment}
          onChange={(ev) => setNewComment(ev.target.value)}
        />
        {isEmojiOpen && (
          <Emojis
            isOpen={isEmojiOpen}
            onSelect={(emoji: string) =>
              setNewComment((newComment || '') + emoji)
            }
            additionalStyles={{ 'max-height': '11vh' }}
          />
        )}
        <div css={optionsStyle}>
          <div>
            <EmojiIcon
              css={messageIconStyle}
              onClick={() => setIsEmojiOpen(!isEmojiOpen)}
            />
          </div>
          <Button
            css={sendButtonStyle}
            onClick={createNewComment}
            disableElevation
            variant="contained"
            disabled={!newComment}
          >
            Senden
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Comments;
