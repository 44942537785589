import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Autocomplete } from '@mui/material';
import { TextField, InputAdornment, Button } from '@mui/material';
import DollarIcon from '@mui/icons-material/AttachMoneyOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import SendIcon from '@mui/icons-material/CreditCardOutlined';
import DescriptionIcon from '@mui/icons-material/NotesOutlined';
import { BankAccount } from './types';
import { find, propEq } from 'ramda';
import Headline from '../../Headline';

const FEE = 0.02;
const MIN_VALUE = 2000;

const subHeadlineStyle = css`
  font-size: 1.5vh;
  margin-top: 1vh;
`;

const inputStyle = css`
  width: 100%;
  max-width: 30vh;
`;

const rowStyle = css`
  width: 100%;
  max-width: 30vh;
  display: flex;
  justify-content: space-between;
`;

const marginTop = css`
  margin-top: 1vh !important;
`;

type TransferType = {
  transferFromVban: string;
  setTransferFromVban: (vban: string) => void;
  accounts: BankAccount[];
  transfer: (
    sourceVban: string,
    destinationVban: string,
    amount: number,
    reference: string
  ) => void;
};

export const Transfer = ({
  accounts,
  transferFromVban,
  setTransferFromVban,
  transfer,
}: TransferType) => {
  const [target, setTarget] = useState('');
  const [money, setMoney] = useState(2000);
  const [reference, setReference] = useState('');

  const activeAccount = find(propEq('vban', transferFromVban), accounts);

  const fee = money * FEE;
  const compelteValue = money + fee;

  const getOptionLabel = (option: any) => {
    let label = '';
    if (option.teamLabel) {
      label = option.teamLabel;
    } else if (option.ownerFactory) {
      label = option.ownerFactory.label;
    } else {
      label = option.bankAccountType.name;
    }
    return `${option.vban} - ${label}`;
  };

  return (
    <div>
      <Headline>Überweisung</Headline>
      <h2 css={subHeadlineStyle}>Auftraggeber</h2>
      <Autocomplete
        css={inputStyle}
        options={accounts}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Auftraggeber"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        value={activeAccount}
        onChange={(ev, newValue) => setTransferFromVban(newValue?.vban || '')}
      />

      <h2 css={subHeadlineStyle}>Empfänger</h2>
      <TextField
        css={inputStyle}
        placeholder="Empfänger"
        type="number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountBalanceIcon />
            </InputAdornment>
          ),
        }}
        value={target}
        onChange={(ev) => setTarget(ev.target.value)}
      />

      <h2 css={subHeadlineStyle}>Betrag</h2>
      <TextField
        css={inputStyle}
        placeholder="Betrag"
        type="number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DollarIcon />
            </InputAdornment>
          ),
        }}
        value={money}
        onChange={(ev) => setMoney(parseFloat(ev.target.value))}
      />
      <div css={[rowStyle, marginTop]}>
        <span>Gebühren</span>
        <span>${fee.toFixed(2)}</span>
      </div>
      <div css={rowStyle}>
        <span>Gesamtbetrag</span>
        <span>${compelteValue.toFixed(2)}</span>
      </div>

      <h2 css={subHeadlineStyle}>Verwendungszweck</h2>
      <TextField
        css={inputStyle}
        placeholder="Verwendungszweck"
        type="text"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}
        value={reference}
        onChange={(ev) => setReference(ev.target.value)}
      />
      <div>
        <Button
          css={marginTop}
          variant="outlined"
          startIcon={<SendIcon />}
          onClick={() => {
            transfer(transferFromVban, target, money, reference);
          }}
        >
          Geld senden
        </Button>
      </div>
    </div>
  );
};
