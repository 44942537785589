import { FractionSalaryData } from '../components/apps/Fraction/types';
import * as type from '../redux/types';

export function startFractionPlayerStatusFetch() {
  return {
    type: type.FRACTION_PLAYER_STATUS_FETCH_START,
  };
}

export function startFractionMembersFetch() {
  return {
    type: type.FRACTION_EMPLOYEES_FETCH_START,
  };
}

export function startFractionMembersRankChange(name: string, rank: number) {
  return {
    type: type.FRACTION_EMPLOYEES_RANK_CHANGE_START,
    name,
    rank,
  };
}

export function startFractionMembersFire(name: string) {
  return {
    type: type.FRACTION_EMPLOYEES_FIRE_START,
    name,
  };
}

export function startFractionSalaryFetch() {
  return {
    type: type.FRACTION_SALARY_FETCH_START,
  };
}

export function inviteFractionEmployee(name: string) {
  return {
    type: type.FRACTION_EMPLOYEES_INVITE_START,
    name,
  };
}

export function startFractionDutyListFetch(search: string, page: number) {
  return {
    type: type.FRACTION_DUTYLIST_FETCH_START,
    search,
    page,
  };
}
export function setFractionDutyListSearch(search: string) {
  return {
    type: type.FRACTION_DUTYLIST_SEARCH_SET,
    search,
  };
}
