import { css } from '@emotion/react';
import React from 'react';

const containerStyle = css`
  margin: 1vh 0vh;
`;
const multipleStyle = css`
  display: flex;
  flex-direction: row;

  div {
    flex: 1;
  }
  div:not(:last-child) {
    padding-right: 1vh;
  }
`;

export const Image = ({ url, customCss = '' }: any) => {
  if (Array.isArray(url)) {
    return (
      <div
        css={[
          containerStyle,
          css`
            ${customCss}
          `,
          multipleStyle,
        ]}
      >
        {url.map((singleUrl: string, index: number) => (
          <div key={index}>
            <img src={singleUrl} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <img
      css={[
        containerStyle,
        css`
          ${customCss}
        `,
      ]}
      src={url}
    />
  );
};
