import React, { useState } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocumentHistory,
  fetchDocumentHistoryEntry,
} from '../../../actions';
import { pathOr } from 'ramda';
import {
  documentHistoryEntryP,
  documentHistoryP,
} from '../../../redux/reducer/apps/documents';
import { HistoryData, HistoryDoc } from './types';
import { DateTime } from 'luxon';
import { documentContainerStyle } from '../../../leogram/shared/LexicalEditor/custom-styles';
import { getTemplateForDocType } from './doc-types';
import Editor from '../../../leogram/shared/Editor/ReadOnly';
import { LexicalEditor } from '../../../leogram/shared/LexicalEditor';
import { documentStyle } from './View';
import { css } from '@emotion/react';
import { convertFromRaw, EditorState } from 'draft-js';

const loadBtnContainerStyle = css`
  display: flex;
  justify-content: center;
  margin: 1vh 3vh;
`;

type DocumentHistoryType = {
  documentId: string;
  normalizedZoom: number;
};
export const DocumentHistory = ({
  documentId,
  normalizedZoom,
}: DocumentHistoryType) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedHisoryEntry, setSelectedHistoryEntry] =
    useState<HistoryData | null>(null);
  const history = useSelector<any, HistoryData[]>(pathOr([], documentHistoryP));
  const doc = useSelector<any, HistoryDoc | null>(
    pathOr(null, documentHistoryEntryP)
  );

  const load = () => {
    setIsLoaded(true);
    dispatch(fetchDocumentHistory(documentId));
  };

  const loadHistoryEntry = (entry: HistoryData) => {
    setSelectedHistoryEntry(entry);
    dispatch(fetchDocumentHistoryEntry(documentId, entry._id));
  };

  return (
    <div>
      <div css={loadBtnContainerStyle}>
        {!isLoaded && (
          <Button variant="outlined" onClick={load} startIcon={<ReplayIcon />}>
            Laden
          </Button>
        )}

        {isLoaded && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Neuste bis zum Datum</TableCell>
                <TableCell>stateUntilDate</TableCell>
                <TableCell>Bearbeiter</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((entry) => (
                <React.Fragment key={entry._id}>
                  <TableRow onClick={() => loadHistoryEntry(entry)}>
                    <TableCell>
                      {DateTime.fromJSDate(new Date(entry.stateUntilDate))
                        .setLocale('de')
                        .toFormat('dd.LL.yy, HH:mm')}
                    </TableCell>
                    <TableCell>{entry.subject}</TableCell>
                    <TableCell>
                      {entry.lastModifier.map((x) => x.name).join(', ')}
                    </TableCell>
                  </TableRow>
                  {entry._id === selectedHisoryEntry?._id && doc && (
                    <TableRow key={entry._id + Math.random()}>
                      <TableCell colSpan={3}>
                        <div
                          css={() =>
                            documentContainerStyle(
                              doc.version === 2 ? 1 : normalizedZoom,
                              getTemplateForDocType(
                                doc.itemHash,
                                doc.version || 1
                              )
                            )
                          }
                        >
                          {doc.pages.map((page, index) => {
                            return (
                              <div css={documentStyle} key={index}>
                                {(!doc.version || doc.version < 2) && (
                                  <Editor
                                    key={index}
                                    content={
                                      page
                                        ? EditorState.createWithContent(
                                            convertFromRaw(JSON.parse(page))
                                          )
                                        : EditorState.createEmpty()
                                    }
                                    setContent={() => {}}
                                    size={{ height: '80vh', width: '50vh' }}
                                    stamps={[]}
                                    template={{}}
                                  />
                                )}
                                {doc.version === 2 && (
                                  <LexicalEditor
                                    key={index}
                                    content={page}
                                    zoom={normalizedZoom}
                                    setContent={() => {}}
                                    isReadOnly={true}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
};
