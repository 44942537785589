import React, { useState } from 'react';
import { css } from '@emotion/react';
import {
  containerStyle,
  firstContainerStyle,
  inputStyle,
  buttonStyle,
  headerContainer,
  logoStyle,
  titleStyle,
} from './Login';
import { TextField, Button } from '@mui/material';
// import { ReactComponent as Logo } from '../logo.svg';

type RegisterCodeType = {
  confirm: (code: string) => void;
};

export const RegisterCode = ({ confirm }: RegisterCodeType) => {
  const [code, setCode] = useState('');

  return (
    <div css={containerStyle}>
      <div css={firstContainerStyle}>
        <div css={headerContainer}>
          {/* <Logo css={logoStyle} /> */}
          <div css={titleStyle}>Leogram</div>
        </div>
        Bestätigungscode eingeben (aus E-Mail)
        <TextField
          css={inputStyle}
          type="text"
          placeholder="Code"
          value={code}
          onChange={(ev: any) => setCode(ev.target.value)}
          variant="standard"
        />
        <Button
          css={buttonStyle}
          variant="contained"
          onClick={() => confirm(code)}
          disableElevation
        >
          Bestätigen
        </Button>
      </div>
    </div>
  );
};
