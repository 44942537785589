import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Geltungsbereich</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Straßenverkehrsordnung gilt im gesamten Staat San Andreas.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Sonder- und Wegerechte</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die folgenden Regelungen können durch die Beamten des LSPD und LSMD im Rahmen der verhältnismäßigen Nutzung von Sondersignalen außer Kraft gesetzt werden oder nicht beachtet werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Die Nutzung von Sondersignalen ohne triftigen Grund ist verboten. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Fahrzeugen, welche sich mit Sonder- und Wegerechten bewegen, ist stets der Weg freizumachen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 3 Fahrerlaubnis</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. Jede Person, die ein PKW, LKW oder Motorrad im Straßenverkehr bewegt, benötigt die passende Fahrerlaubnis. </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. Die Fahrerlaubnis, welche in Form eines Führerscheins durch das DMV ausgestellt wird, muss stets vom Fahrer mitgeführt werden.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">III. Für das Transportieren von mehr als 4 Personen in einem Fahrzeug und das gewerbliche Transportieren von Personen muss der Personenbeförderungsschein mitgeführt werden.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">IV. Der Führerschein kann bei einem erheblichen Vergehen oder bei körperlichen Mängeln durch das LSPD für 72 Stunden eingezogen werden. Für eine längere Einziehung muss ein richterlicher Beschluss vorliegen.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Ein Entzug der Fahrerlaubnis wegen körperlicher Mängel muss dabei durch das LSMD beauftragt oder bestätigt werden.<br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">VI. Für neu eingereiste Personen gilt eine vorläufige Fahrerlaubnis für 3 Wochen ab Einreisedatum.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Sicherheitskleidung</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer ein motorbetriebenes Zweirad oder Quad fährt, muss einen Sicherheitshelm tragen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Fahrzeug</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Das geführte Fahrzeug muss stets in einem verkehrstauglichen Zustand gehalten werden. </span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Für das Fahrzeug und die damit begangenen Taten haftet bei Nichtermittlung des Fahrers stets der Halter.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Das Fahrzeug darf die Breite der Fahrbahn nicht überschreiten. Für solche Fahrzeuge ist eine Sondergenehmigung durch das LSPD einzuholen. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. In jedem Fahrzeug müssen mindestens ein Verbandkasten mit 2 Bandagen und ein Warndreieck mitgeführt werden. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Spezialfahrzeuge, wie beispielsweise Golfcarts oder Rasenmäher, sind im Straßenverkehr verboten.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VI</span><span style="font-size: 14pt;">. Sonderfahrzeuge wie Polizeifahrzeuge, Panzerfahrzeuge und Rettungswagen dürfen nur durch die jeweilige Behörde erworben und gefahren werden.</span></span><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VII. Nicht angemessene Kennzeichen sind verboten. Darunter fallen beleidigende und diskriminierende Kennzeichen oder solche, die auf eine Staatsbehörde hinweisen, ausgenommen Fraktionsfahrzeuge.</span></span></p>
	<ul><li><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">Der Ermessensspielraum liegt hier beim kontrollierenden Officer.</span></span></li>
		<li><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">Bei Beanstandung des Tickets hat ein Richter hat das letzte Wort. Es kann bis zur Klärung auch § 5 Abs. 8 StVO herangezogen werden.</span><span style="color:rgb(255,255,255);"></span></span></li>
	</ul><p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VIII. Ein fehlerhaftes Fahrzeug, auch gemäß § 6 StVO, darf durch das LSPD beschlagnahmt werden.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IX. Das Befahren des Highways mit nicht-motorisierten Fahrzeugen ist verboten.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">X. Das Befahren von öffentlichen Straßen mit Offroadfah</span><span style="font-size: 14pt;">rzeugen ohne Straßenzulassung (Kennzeichen) ist verboten.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 6 Körperliche Eignung</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Jede Person ist für seinen eigenen körperlichen Zustand im Straßenverkehr zuständig. Es ist stets gemäß des körperlichen Zustands, Bodenbeschaffenheit und der Sichtverhältnisse zu fahren.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Für Alkohol gilt eine 0,0 Promille Toleranz. Das Fahren unter Alkoholeinfluss führt in jedem Fall zum Entzug der Fahrerlaubnis.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Das Fahren unter Drogen und bewusstseinsverändernden Medikamenten ist verboten.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Bei erkenntlichen Fehlverhalten aufgrund körperlicher Mängel kann eine MPU durch das LSPD oder das DoJ angeordnet werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Bei Fahren ohne körperliche Eignung kann das Fahrzeug und die Fahrerlaubnis eingezogen werden.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 7 Lichtanlagen</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Bei Dunkelheit, Dämmerung und eingeschränkter Sicht sind die entsprechenden Beleuchtungen einzuschalten.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Fernlicht ist nur gestattet, wenn keine andere Person dadurch gestört wird.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Xenon-Beleuchtung ist nur in der Standardausführung gestattet.  Fahrzeuge, welche von Werk aus eine andere Beleuchtungseinrichtung/Farbe haben, müssen sofort umgerüstet werden und mit zugelassen Standardlicht augerüstet werden. Farbige Beleuchtung jeglicher Art ist im öffentlichen Straßenverkehr verboten.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 8 Gefährlicher Eingriff in den Straßenverkehr</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer mutwillig den Straßenverkehr behindert, macht sich des gefährlichen Eingriffs in den Straßenverkehr strafbar.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Wer gemäß § 8 Abs. 1 StVO handelt und dabei das Leib oder Leben einer Person gefährdet, macht sich des schweren gefährlichen Eingriffs in de</span><span style="font-size: 14pt;">n Straßenverkehr strafbar.</span></span><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><br></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"><strong>§ 9 Geschwindigkeiten</strong></span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Innerorts beträgt die Höchstgeschwindigkeit 80 km/h.</span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. Baustellen sind mit einer maximalen Geschwindigkeit von 80 km/h zu passieren.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">III. Bei Checkpoints liegt die Höchstgeschwindigkeit bei 100 km/h.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">IV. Auf Landstraßen liegt die Höchstgeschwindigkeit bei 140 km/h.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">V. Die Mindestgeschwindigkeit auf dem Highway beträgt 80 km/h. </span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">VI. Die Highways unterliegen keiner Höchstgeschwindigkeit.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">VII. Die Geschwindigkeiten können auf Anordnung des LSPD abweichen.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">VIII. Das Vorbeifahren an Hindernissen und Unfällen ist nur mit sehr geringer Geschwindigkeit gestattet.</span><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:#ffffff;"><span style="font-size: 14pt;">IX. Vollsperrungen des LSPD und LSMD sind nicht zu durchfahren.</span></span></p>
	<p><span style="color:#ffffff;"><span style="font-size: 14pt;">X. Wer sich mit deutlich überhöhter Geschwindigkeit fortbewegt oder gegen andere Personen ein nicht genehmigtes Straßenrennen fährt, macht sich des illegalen Straßenrennens strafbar.</span></span></p><p><span style="color:#ffffff;"><span style="font-size: 14pt;">XI. Das LSMD / LSFD kann bei Einsätzen mithilfe eines Verkehrsleitanhängers die Straßenführung und die Geschwindigkeit regulieren. </span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 10 Verschmutzung</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die grundlose Verursachung von Lärm mit einem Fahrzeug ist verboten.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Das Befahren von nicht befestigten Straßen und Grünanlagen ist verboten.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 11 Vorfahrtsregeln</strong> </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Im Staat gilt grundsätzlich die Vorfahrtsregel rechts vor links.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Im Falle eines Schildes mit der Aufschrift “Yield” ist den anderen Verkehrsteilnehmern die Vorfahrt zu gewähren.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Im Falles eines Stoppschildes ist an der Haltelinie oder beim Schild zu halten und den anderen Verkehrsteilnehmern die Vorfahrt zu gewähren. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Fahrzeugen mit aktiven Sonder- und Wegerechten ist stets die Vorfahrt zu gewähren.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Eine Ausnahme hierbei ist die Regelung des Verkehrs durch Beamte des LSPD.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 12 Halten und Parken</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer länger als 3 Minuten hält oder sein Fahrzeug verlässt, parkt.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Das Parken und Halten an gelben bzw. roten Bordsteinen und Parkverbotszonen (Halteverbotsschildern) ist verboten. Fahrer im Dienst eines Gewerbes zur Personenbeförderung dürfen Kunden be- und entladen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Das Parken ist auf Grünflächen ebenfalls verboten. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Das Parken ist nur gemäß der Fahrtrichtung oder in den vorgeschriebenen Haltebuchten gestattet. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Es ist ebenfalls verboten, in Ein- und Ausfahrten, in Kreuzungsbereichen und Plätzen, wo der öffentliche Verkehr gestört wird, zu parken. Garagen und Rettungswege sind ebenfalls stets freizuhalten. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VI. Beamte des LSPD / LSFD können neue Parkverbotszonen ausrufen und wieder einstellen.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 13 Fahrverhalten</strong></span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Im Staat gilt grundsätzlich Rechtsfahrgebot, außer in der Stadt Los Santos.</span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Es ist links zu überholen. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Das Überfahren der gelben doppelt durchgezogenen Linien ist verboten. Nur zum Abbiegen oder Befahren von Ein- und Ausfahrten ist dies gestattet. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Das Überfahren von Sperrflächen ist nicht gestattet. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Das Nutzen von Signalanlagen des Fahrzeuges ist nur zur Warnung anderer Verkehrsteilnehmer gestattet. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VI. Wer sich unbefugt von einem Unfallort entfernt, macht sich der Fahrerflucht und der unterlassenen Hilfeleistung gemäß § 31 StGB strafbar. </span></span><span style="color:rgb(255,255,255);"></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VII.</span><span style="font-size: 14pt;"> Das Fahren entgegen der Fahrtrichtung ist verboten. </span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="font-size: 14pt;">VIII. Die weißen Pfeile auf der Fahrbahn sind nicht zu beachten. Beim Abbiegen ist sich jedoch trotzdem auf der richtigen Spur einzuordnen.</span></p>
	<p><span style="font-size: 14pt;">IX. Fahrzeuge der Land- und Forstwirtschaft dürfen sich ohne Genehmigung auf Straßen (Highways ausgenommen) fortbewegen.</span></p>
	<p><span style="font-size: 14pt;"><br></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 14 Vorsätzliche Behinderung</strong></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Wer sein Fahrzeug absichtlich auf der Fahrbahn stehen lässt und dadurch den Verkehr behindert, macht sich der vorsätzlichen Behinderung strafbar.<br></span></span></p>
`;

export const STVO = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
