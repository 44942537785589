import React from 'react';
import { appContainerStyle } from '../styles';
import LoginContainer from '../container/LoginContainer';
import RegisterContainer from '../container/RegisterContainer';
import RegisterCodeContainer from '../container/RegisterCodeContainer';
import DashboardContainer from '../container/DashboardContainer';
import ProfileSearchContainer from '../container/ProfileSearchContainer';
import ProfilePageContainer from '../container/ProfilePageContainer';
import ActivityContainer from '../container/ActivityContainer';
import AdsContainer from '../container/AdsContainer';
import { getLoggedInUserTag } from '../store/reducer/login';
import NewPost from '../components/NewPost';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { routerUrlP } from '../store/reducer/router';

const ROUTE_MAP: any = {
  '/': DashboardContainer,
  '/login': LoginContainer,
  '/search': ProfileSearchContainer,
  '/new': NewPost,
  '/register': RegisterContainer,
  '/registercode': RegisterCodeContainer,
  '/profilepage': ProfilePageContainer,
  '/activity': ActivityContainer,
  '/ads': AdsContainer,
};

export const Router = ({}: any) => {
  const currentUrl = useSelector<any, string>(pathOr('', routerUrlP));
  const currentUser = getLoggedInUserTag();

  if (
    !currentUser &&
    currentUrl !== '/login' &&
    currentUrl !== '/register' &&
    currentUrl !== '/registercode'
  ) {
    return (
      <div css={appContainerStyle}>
        <LoginContainer />
      </div>
    );
  }

  const Cmp = ROUTE_MAP[currentUrl] || ROUTE_MAP['/'];
  return (
    <div css={appContainerStyle}>
      <Cmp />
    </div>
  );
};
