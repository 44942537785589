import { css } from '@emotion/react';
import { bgColor, textColor } from './colors';

export const appContainerStyle = css`
  height: 100%;
  width: 100%;
`;

export const layoutContainerStyle = css`
  width: 100%;
  height: 100%;
  background: ${bgColor};

  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
`;

export const navbarContainerStyle = css`
  flex-basis: 8vh;
  flex-grow: 0;
  flex-shrink: 0;

  color: ${textColor};
`;

export const contentContainer = css`
  flex-basis: 20vh;
  flex-grow: 1;

  // border-top: 0.2vh solid rgb(192 201 218 / 31%);
  overflow: auto;

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  scrollbar-width: none;
`;
