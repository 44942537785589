const funkStyle = {
  background: '#fff',
  color: '#000',
};

export default [
  [
    {
      value: '',
    },
  ],
];
