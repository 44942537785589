import React, { useEffect } from 'react';
import { translate } from '../../../utils/translate';
import { CompanyData, ProductionData } from './types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Headline from '../../Headline';
import { DateTime } from 'luxon';

type ProductionType = {
  company: CompanyData;
  fetchProduction: (id: string) => void;
  production: ProductionData | undefined;
};

export const Production = ({
  company,
  fetchProduction,
  production,
}: ProductionType) => {
  useEffect(() => {
    fetchProduction(company._id);
  }, []);

  if (!production) {
    return null;
  }

  const remainingTime = Math.floor(production.remaining / 1000 / 60);

  return (
    <div>
      {production.item && (
        <>
          <Headline>Aktuelle Produktion</Headline>
          <div>
            <div>Item: {translate(production.item)}</div>
            <div>Anzahl: {production.amount}</div>
            <div>Status {production.status}%</div>
            <div>
              Verbleibende Zeit{' '}
              {remainingTime > 0 ? `${remainingTime} Minuten` : '-'}
            </div>
          </div>
        </>
      )}

      <Headline>Vergangene Produktionen</Headline>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Anzahl</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {production.productions.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.date))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>{translate(entry.item)}</TableCell>
              <TableCell>
                {entry.sucessfull} / {entry.sucessfull + entry.failed}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
