import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import Badge from '@mui/material/Badge';
import HomeIcon from '@mui/icons-material/HomeRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import ProfileIcon from '@mui/icons-material/PersonRounded';
import PublicIcon from '@mui/icons-material/PublicRounded';
import { getLoggedInUserTag } from '../store/reducer/login';
import HeartIcon from '@mui/icons-material/FavoriteRounded';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { routerUrlP, routerParamsP } from '../store/reducer/router';
import { startFetchUnreadActivity } from '../actions/activity';

const bottomNavigationContainer = css`
  display: flex;
  justify-content: space-around;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #d50000;
  background-image: linear-gradient(to right, #d50000, #800000);
  color: white;
  text-align: center;
  z-index: 1;
  padding: 1vh 0;
`;

const navIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
  padding: 0.25vh !important;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    background-color: #ffffff33;
  }
`;

type NavbarType = {
  route: string;
  goto: (route: string, params?: any) => void;
  newActivityAmount: number;
};

export const Navbar = ({ route, goto, newActivityAmount }: NavbarType) => {
  const dispatch = useDispatch();
  const currentUser = getLoggedInUserTag();

  useEffect(() => {
    const loadActivityAmount = () => {
      dispatch(startFetchUnreadActivity());
    };
    loadActivityAmount();
    const int = setInterval(loadActivityAmount, 60000);
    return () => {
      clearInterval(int);
    };
  }, []);

  return (
    <div css={bottomNavigationContainer}>
      <HomeIcon onClick={() => goto('/')} css={navIconStyle} />
      <PublicIcon onClick={() => goto('/ads')} css={navIconStyle} />
      <Badge badgeContent={newActivityAmount} color="primary">
        <HeartIcon onClick={() => goto('/activity')} css={navIconStyle} />
      </Badge>
      <SearchIcon onClick={() => goto('/search')} css={navIconStyle} />
      <ProfileIcon
        onClick={() => goto('/profilepage', { userTag: currentUser })}
        css={navIconStyle}
      />
    </div>
  );
};
