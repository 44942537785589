export const PLAYER_ONDUTY_FETCH_START = 'PLAYER_ONDUTY_FETCH_START';
export const PLAYER_ONDUTY_FETCH_SUCCESS = 'PLAYER_ONDUTY_FETCH_SUCCESS';

export const FOLDER_GROUP_CREATE_START = 'FOLDER_GROUP_CREATE_START';
export const FOLDER_GROUP_FACTORY_CREATE_START =
  'FOLDER_GROUP_FACTORY_CREATE_START';

export const FOLDER_GROUP_MEMBERS_FETCH_START =
  'FOLDER_GROUP_MEMBERS_FETCH_START';
export const FOLDER_GROUP_MEMBERS_FETCH_SUCCESS =
  'FOLDER_GROUP_MEMBERS_FETCH_SUCCESS';

export const FOLDER_GROUP_ADMINS_FETCH_START =
  'FOLDER_GROUP_ADMINS_FETCH_START';
export const FOLDER_GROUP_ADMINS_FETCH_SUCCESS =
  'FOLDER_GROUP_ADMINS_FETCH_SUCCESS';

export const FOLDER_GROUP_ADMIN_ADD_START = 'FOLDER_GROUP_ADMIN_ADD_START';
export const FOLDER_GROUP_ADMIN_REMOVE_START =
  'FOLDER_GROUP_ADMIN_REMOVE_START';
export const FOLDER_GROUP_MEMBER_ADD_START = 'FOLDER_GROUP_MEMBER_ADD_START';
export const FOLDER_GROUP_MEMBER_REMOVE_START =
  'FOLDER_GROUP_MEMBER_REMOVE_START';

export const FOLDER_SAVE_START = 'FOLDER_SAVE_START';
export const FOLDER_CREATE_START = 'FOLDER_CREATE_START';

export const FOLDERS_FETCH_START = 'FOLDERS_FETCH_START';
export const FOLDERS_FETCH_SUCCESS = 'FOLDERS_FETCH_SUCCESS';

export const FOLDER_FETCH_START = 'FOLDER_FETCH_START';
export const FOLDER_FETCH_SUCCESS = 'FOLDER_FETCH_SUCCESS';

export const FOLDER_SEARCH_SET = 'FOLDER_SEARCH_SET';

export const FOLDER_SELECTION_SET = 'FOLDER_SELECTION_SET';

export const FOLDER_ADD_DOCUMENT_START = 'FOLDER_ADD_DOCUMENT_START';

export const FOLDER_DOCUMENT_FETCH_START = 'FOLDER_DOCUMENT_FETCH_START';
export const FOLDER_DOCUMENT_FETCH_SUCCESS = 'FOLDER_DOCUMENT_FETCH_SUCCESS';
