import { set, lensPath, pathOr, findIndex, pathEq, pipe } from 'ramda';

import * as types from '../types';

export const commentsP = ['leogram', 'comments'];
export const commentsL = lensPath(commentsP);

const setComments = (state: any, { data, offset }: any) => {
  const old = pathOr([], commentsP, state);
  const new_ = offset === 0 ? data : [...old, ...data];
  return set(commentsL, new_, state);
};

const clear = (state: any, { offset }: any) => {
  return offset === 0 ? set(commentsL, [], state) : state;
};

const toggleLike = (state: any, { comment }: any) => {
  const comments: any[] = pathOr([], commentsP, state);
  const index = findIndex(pathEq(['_id'], comment), comments);
  const current = comments[index].isLiked;
  const amount = comments[index].likeAmount + (current ? -1 : 1);
  return pipe(
    set(lensPath([index, 'isLiked']), !current),
    set(lensPath([index, 'likeAmount']), amount),
    (comments) => set(commentsL, comments, state)
  )(comments);
};

export const commentsReducerTree = {
  [types.COMMENTS_FETCH_START]: [clear],
  [types.COMMENTS_FETCH_SUCCESS]: [setComments],
  [types.COMMENT_LIKE_TOGGLE_SUCCESS]: [toggleLike],
};
