import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFolderDocument,
  getDocument,
  openDesktopApp,
} from '../../../actions';
import { pathOr } from 'ramda';
import { css } from '@emotion/react';
import { folderDocumentViewP } from '../../../redux/reducer/apps/folders';
import { DocumentType } from '../Documents/types';
import { convertFromRaw, EditorState } from 'draft-js';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditRounded';
import CancelIcon from '@mui/icons-material/CancelRounded';
import Editor from '../../../leogram/shared/Editor/ReadOnly';
import { LexicalEditor } from '../../../leogram/shared/LexicalEditor';
import ZoomSlider from '../Documents/ZoomSlider';
import { getTemplateForDocType } from '../Documents/doc-types';
import { documentContainerStyle } from '../../../leogram/shared/LexicalEditor/custom-styles';

const documentViewStyle = css`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const documentStyle = css`
  margin: 2vh 0;
  margin-right: 1vh;
  max-width: 100%;
`;

const documentContainerStyleAdditions = css`
  margin-top: 2vh;
`;

const iconButtonContainer = (theme: any) => css`
  position: absolute;
  top: 2vh;
  right: 0;
  margin: 0.5vh;
  color: #ffffff;
  button {
    border-radius: 50%;
    background-color: ${theme.colors.Gray20};
    padding: 0.5vh;
    margin: 0.5vh;
    :hover {
      background-color: ${theme.colors.Gray40};
    }
    svg {
      height: 2.5vh;
      width: 2.5vh;
    }
  }
`;

type FolderDocumentViewType = {
  documentId: string;
  goBack: () => void;
  viewOnly?: boolean;
};
const FolderDocumentView = ({
  documentId,
  goBack,
  viewOnly,
}: FolderDocumentViewType) => {
  const dispatch = useDispatch();
  const document = useSelector<any, DocumentType | null>(
    pathOr(null, folderDocumentViewP)
  );
  const [zoom, setZoom] = React.useState(9);
  const normalizedZoom = zoom / 10;

  const editDoc = () => {
    if (document) {
      dispatch(openDesktopApp('documents', 'Dokumente'));
      dispatch(getDocument(document._id));
    }
  };

  useEffect(() => {
    dispatch(fetchFolderDocument(documentId));
  }, [documentId]);

  return document && document._id === documentId ? (
    <div css={documentViewStyle}>
      <div css={iconButtonContainer}>
        {!viewOnly && (
          <IconButton onClick={editDoc}>
            <EditIcon />
          </IconButton>
        )}
        <IconButton onClick={goBack}>
          <CancelIcon />
        </IconButton>
      </div>
      {document.version === 2 && (
        <div>
          <ZoomSlider
            value={zoom}
            setValue={setZoom}
            minValue={1}
            maxValue={20}
          />
        </div>
      )}
      <div
        css={[
          documentContainerStyle(
            1,
            getTemplateForDocType(document.itemHash, document.version || 1)
          ),
          documentContainerStyleAdditions,
        ]}
      >
        {document.pages.map((page, index) => (
          <div key={index} css={documentStyle}>
            {(!document.version || document.version === 1) && (
              <Editor
                content={EditorState.createWithContent(
                  convertFromRaw(JSON.parse(page))
                )}
                size={{ width: '50vh', height: '80vh' }}
                template={document.template}
              />
            )}
            {document.version === 2 && (
              <LexicalEditor
                content={page}
                setContent={() => {}}
                isReadOnly={true}
                zoom={normalizedZoom}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default FolderDocumentView;
