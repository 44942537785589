import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminStateBankAccountsP = [
  'apps',
  'admin',
  'stateBankAccounts',
];
export const appsAdminStateBankAccountsL = lensPath(
  appsAdminStateBankAccountsP
);
const setData = (state: any, { data }: any) => {
  return set(appsAdminStateBankAccountsL, data, state);
};

export const appsAdminStateBankAccountsReducerTree = {
  [types.ADMIN_STATEBANKACCOUNTS_FETCH_SUCCESS]: [setData],
};
