import React from 'react';
import { findIndex } from 'ramda';
import TabList from '../../shared/TabList';

type DocumentTabsType = {
  tabs: { label: string; id: string }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  closeTab: (tab: string) => void;
  saveStatus: { [id: string]: boolean };
};
export const DocumentTabs = ({
  tabs,
  activeTab,
  setActiveTab,
  closeTab,
  saveStatus,
}: DocumentTabsType) => {
  return (
    <TabList
      tabs={tabs.map(
        (tab) =>
          tab.label + (saveStatus[tab.id] || tab.id === 'home' ? '' : ' *')
      )}
      onTabClick={(index) => setActiveTab(tabs[index].id)}
      onTabClose={(index) => closeTab(tabs[index].id)}
      activeTabIndex={findIndex((tab) => tab.id === activeTab, tabs)}
    />
  );
};
