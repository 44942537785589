import * as types from '../../../redux/types';
import {
  pipeC,
  ofType,
  mapPromiseC,
  mapC,
  debounceC,
} from '../../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../../utils/fetch';
import { notifySuccess, notifyError } from '../../utils';

export const onFetchAdminFactory = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_FACTORY_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/factories/${_id}`)
        .then((data) => {
          return {
            type: types.ADMIN_FACTORY_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_FACTORY_FETCH_FAILED,
        }));
    })
  );

export const onFetchAdminFactoryBankAccounts = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/factories/${_id}/bankAccounts`)
        .then((data) => {
          return {
            type: types.ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => ({
          type: types.ADMIN_FACTORY_BANK_ACCOUNTS_FETCH_FAILED,
        }));
    })
  );

export const onSetAdminFactoryOwner = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_FACTORY_OWNER_SET_START),
    mapPromiseC(({ _id, ownerName }: any) => {
      return authFetchPost(`/admin/factories/${_id}/setOwner`, { ownerName })
        .then(() => [
          notifySuccess('Besitzer erfolgreich geändert'),
          {
            type: types.ADMIN_FACTORY_FETCH_START,
            _id,
          },
        ])
        .catch(() => notifyError('Besitzer Änderung fehlgeschlagen'));
    })
  );

export const onSetAdminFactoryReload = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_FACTORY_RELOAD_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/factories/${_id}/reload`)
        .then(() => notifySuccess('Fabrik auf Server neu geladen'))
        .catch(() => notifyError('Fehler beim neuladen'));
    })
  );

export const onFetchAdminFactoryVehicles = (action$: any) =>
  pipeC(
    action$,
    ofType(types.ADMIN_FACTORY_VEHICLES_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/admin/factories/${_id}/vehicles`)
        .then((data) => {
          return {
            type: types.ADMIN_FACTORY_VEHICLES_FETCH_SUCCESS,
            data,
          };
        })
        .catch(() => notifyError('Fehler beim Abfragen der Fabrikfahrzeuge'));
    })
  );
