import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLspdLogs } from '../../../actions';
import { lspdLogsP } from '../../../redux/reducer/apps/lspd';
import CachedIcon from '@mui/icons-material/Cached';
import { css } from '@emotion/react';
import { LogsEntryData } from './types';
import { DateTime } from 'luxon';

const refreshStyle = css`
  position: absolute !important;
  right: 3vh;
`;

export const LogBook = () => {
  const dispatch = useDispatch();
  const data = useSelector<any, LogsEntryData[]>(pathOr([], lspdLogsP));

  const fetchData = (skip = data.length) => {
    dispatch(fetchLspdLogs(skip));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full h-full">
      <h1>
        Logs
        <Button
          variant="outlined"
          onClick={() => fetchData(0)}
          css={refreshStyle}
        >
          Aktualisieren <CachedIcon />
        </Button>
      </h1>

      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Beamter</TableCell>
              <TableCell>Aktion</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell>
                  {DateTime.fromJSDate(new Date(entry.date))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>{entry.player?.name}</TableCell>
                <TableCell>{entry.subject}</TableCell>
                <TableCell>{entry.info}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4}>
                <Button
                  variant="outlined"
                  onClick={() => fetchData()}
                  fullWidth
                >
                  Weitere Laden
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
