import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startAdminPlayerDeathsFetch } from '../../../../actions';
import {
  appsAdminPlayerDeathsCompletedP,
  appsAdminPlayerDeathsP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { PlayerDeathType } from '../types/playerdeaths';
import { DateTime } from 'luxon';
import { getDeathReasonByNumber } from '../../../../utils/death';
import { detailContainerStyle } from '../styles';

const tHeadStyle = css`
  th {
    width: 200px;
  }
`;
const tBodyStyle = css`
  display: block;
  max-height: 200px;
  overflow: auto;

  td {
    width: 200px;
  }
`;

type DeathsType = {
  _id: string;
};
export const Deaths = ({ _id }: DeathsType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, PlayerDeathType[]>(
    pathOr([], appsAdminPlayerDeathsP)
  );
  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerDeathsCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerDeathsFetch(_id, offset));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Tode</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead css={tHeadStyle}>
            <TableRow>
              <TableCell>Datum</TableCell>
              <TableCell>Opfer</TableCell>
              <TableCell>Täter</TableCell>
              <TableCell>Mögliche Täter</TableCell>
              <TableCell>Grund</TableCell>
              <TableCell>Position</TableCell>
            </TableRow>
          </TableHead>
          <TableBody css={tBodyStyle}>
            {list.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell>
                  {DateTime.fromJSDate(new Date(entry.date))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>{entry.victimName}</TableCell>
                <TableCell>{entry.killerName}</TableCell>
                <TableCell>
                  {entry.possibleKillers && entry.possibleKillers.join(', ')}
                </TableCell>
                <TableCell>{getDeathReasonByNumber(entry.reason)}</TableCell>
                <TableCell>
                  {entry.position.x.toFixed(1)} {entry.position.y.toFixed(1)}{' '}
                  {entry.position.z.toFixed(1)}
                </TableCell>
              </TableRow>
            ))}
            {!reachedEndOfList && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    variant="outlined"
                    onClick={() => onLoad(list.length)}
                  >
                    Weitere Laden
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
