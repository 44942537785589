import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminLiveStatsP = ['apps', 'admin', 'livestats'];
export const appsAdminLiveStatsL = lensPath(appsAdminLiveStatsP);
const setStats = (state: any, { data }: any) => {
  return set(appsAdminLiveStatsL, data, state);
};

export const appsAdminLiveStatsReducerTree = {
  [types.ADMIN_LIVESTATS_FETCH_SUCCESS]: [setStats],
};
