export default [
  {
    type: 'h1',
    text: 'High End House - Luxuriöses Interior aus Stein',
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Für den besonderen Wohnstil bieten wir ein High End House, welches mit verschiedenen hochwertigen Steinen verziert wurde. Direkt im Eingangsbereich stechen die besonderen Materialien direkt ins Auge.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/high-end-house/eingang.jpg',
      'img/interior/high-end-house/eingang2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Im unteren Bereich des Hauptraums erwarten Sie ein große Wohnraum, welche mit einem atemberaubenden Ausblick wirbt. Richten Sie diesen freien Ruam ganz nach Ihren Wünsche ein und relaxen Sie hier von Ihrem anstrengenden Alltag',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/high-end-house/main.jpg',
      'img/interior/high-end-house/main2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text:
      'Passend zum Look der bisherigen Besichtung finden Sie eine offene Küche mit einer Kochinsel. Auch wenn der ein oder andere ein Restaurant bevorzugt, hier kann kochen nicht nur Arbeit sondern auch zu einem Erlebnis werden.',
    customCss: '',
  },
  {
    type: 'image',
    url: ['img/interior/high-end-house/kitchen.jpg'],
    customCss: '',
  },

  {
    type: 'section',
    text:
      'Über die Treppe gelangen Sie in die untere Etage. Somit sind ihre privaten Räume etwas von dem Bereich getrennt, in dem Sie ihre Gäste empfangen können. Der Flur glänzt durch seine Beleuchtung und seine Akzenten durch Ziegelsteinwände. Hier befindet sich auch ihr Schlafzimmer mit beeindruckendem Ausblick.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/high-end-house/floorbottom.jpg',
      'img/interior/high-end-house/bedroom.jpg',
    ],
    customCss: '',
  },
];
