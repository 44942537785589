import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminGroupData, MemberGroupData } from './group-types';
import { useAuthentication } from '../../../utils/authentication';
import Button from '@mui/material/Button';
import { InputAdornment, TextField, Autocomplete } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import GroupIcon from '@mui/icons-material/GroupRounded';
import GroupAddIcon from '@mui/icons-material/GroupAddRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLessRounded';
import AdminIcon from '@mui/icons-material/ManageAccountsRounded';
import PersonIcon from '@mui/icons-material/PersonRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import {
  addAdminToFolderGroup,
  addMemberToFolderGroup,
  createFolderGroup,
  createFolderGroupForFactory,
  rmAdminToFolderGroup,
  rmMemberToFolderGroup,
} from '../../../actions';
import { css } from '@emotion/react';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import SendIcon from '@mui/icons-material/SendRounded';
import { pathOr } from 'ramda';
import { usernameP } from '../../../redux/reducer/login';
import { startCompaniesByOwnerSimpleFetch } from '../../../actions/company';
import { appsCompanyByOwnerSimpleP } from '../../../redux/reducer/apps/company';
import { FactorySimpleData } from './folder-types';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

const rowHeadStyle = (theme: any) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
    :hover {
      color: ${theme.palette.text.hint};
    }

    > svg {
      padding-right: 0.75vh;
      width: 3.5vh !important;
      height: 3.5vh !important;
      cursor: pointer;
    }
  }
`;

const iconButtonStyle = css`
  border-radius: 50% !important;
`;

const h3Style = css`
  margin: 2vh 0 0.5vh 0 !important;
`;

const memberStyle = css`
  padding: 0.25vh;
  font-size: 1.25vh;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.5vh;
  }

  > button {
    margin-left: 0.5vh;
    padding: 0.25vh;
  }
`;
const factorySelectionContainerStyle = css`
  margin-bottom: 1vh;
  max-width: 35vh;
`;

type RowType = {
  group: AdminGroupData | MemberGroupData;
  isAdmin?: boolean;
  goTo: any;
};
const Row = ({ group, isAdmin, goTo }: RowType) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [newMember, setNewMember] = useState('');
  const [isNewMemberAdmin, setIsNewMemberAdmin] = useState(false);
  const myName = useSelector(pathOr('', usernameP));

  const onSelect = () => {
    setIsOpen(!isOpen);
  };

  const addMember = () => {
    isNewMemberAdmin
      ? dispatch(addAdminToFolderGroup(group._id, newMember))
      : dispatch(addMemberToFolderGroup(group._id, newMember));
    setNewMember('');
  };

  const rmMember = (name: string) => {
    dispatch(rmMemberToFolderGroup(group._id, name));
  };
  const rmAdmin = (name: string) => {
    dispatch(rmAdminToFolderGroup(group._id, name));
  };

  return (
    <TableRow key={group._id}>
      <TableCell>
        <div css={rowHeadStyle}>
          <h2 onClick={() => goTo(`group-${group._id}`)}>
            <GroupIcon /> {group.label}
          </h2>
          {isAdmin && (
            <IconButton css={iconButtonStyle} onClick={onSelect}>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </div>
        {isOpen && isAdmin && (
          <div>
            <h3 css={h3Style}>Mitglieder</h3>
            {group.admins &&
              group.admins.map((admin) => (
                <div css={memberStyle} key={admin._id}>
                  <AdminIcon />
                  {admin.name}
                  {myName !== admin.name && (
                    <IconButton
                      css={iconButtonStyle}
                      onClick={() => rmAdmin(admin.name)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </div>
              ))}
            {group.characters &&
              group.characters.map((char) => (
                <div css={memberStyle} key={char._id}>
                  <PersonIcon />
                  {char.name}
                  {myName !== char.name && (
                    <IconButton
                      css={iconButtonStyle}
                      onClick={() => rmMember(char.name)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </div>
              ))}

            <h3 css={h3Style}>Mitglied hinzufügen</h3>
            <div>
              <Checkbox
                checked={isNewMemberAdmin}
                onChange={() => setIsNewMemberAdmin(!isNewMemberAdmin)}
              />{' '}
              Admin
            </div>
            <TextField
              type="text"
              placeholder="Max_Mustermann"
              value={newMember}
              css={inputStyle}
              onChange={(ev) => setNewMember(ev.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonAddIcon />
                  </InputAdornment>
                ),
                endAdornment: newMember !== '' && (
                  <InputAdornment
                    position="end"
                    css={{ cursor: 'pointer' }}
                    onClick={addMember}
                  >
                    <SendIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

type GroupsType = {
  groupsAsMember: MemberGroupData[];
  groupsAsAdmin: AdminGroupData[];
  goTo: any;
};
export const Groups = ({ groupsAsMember, groupsAsAdmin, goTo }: GroupsType) => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const [newGroupLabel, setNewGroupLabel] = useState('');
  const [newFactoryGroupLabel, setNewFactoryGroupLabel] = useState('');
  const [selectedFactory, setSelectedFactory] = useState<any>(undefined);
  const [factorySelectionInputValue, setFactorySelectionInputValue] =
    useState('');
  const factoriesAsOwner = useSelector<any, FactorySimpleData[]>(
    pathOr([], appsCompanyByOwnerSimpleP)
  );

  const canCreateGroups = hasRight('documentGroup.create');

  const createNewGroup = () => {
    dispatch(createFolderGroup(newGroupLabel));
    setNewGroupLabel('');
  };

  const createNewFactoryGroup = () => {
    dispatch(
      createFolderGroupForFactory(newFactoryGroupLabel, selectedFactory)
    );
  };

  useEffect(() => {
    dispatch(startCompaniesByOwnerSimpleFetch());
  }, []);

  return (
    <div>
      <h1>Gruppen</h1>
      <Table>
        <TableBody>
          {groupsAsAdmin.length > 0 &&
            groupsAsAdmin.map((group) => (
              <Row key={group._id} group={group} isAdmin goTo={goTo} />
            ))}
          {groupsAsMember.length > 0 &&
            groupsAsMember.map((group) => (
              <Row key={group._id} group={group} goTo={goTo} />
            ))}
        </TableBody>
      </Table>

      {canCreateGroups && (
        <>
          <h3 css={h3Style}>Neue Gruppe erstellen</h3>
          <TextField
            css={inputStyle}
            label="Gruppenname"
            value={newGroupLabel}
            onChange={(ev) => setNewGroupLabel(ev.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GroupAddIcon />
                </InputAdornment>
              ),
              endAdornment: newGroupLabel !== '' && (
                <InputAdornment
                  position="end"
                  css={{ cursor: 'pointer' }}
                  onClick={createNewGroup}
                >
                  <SendIcon />
                </InputAdornment>
              ),
            }}
          />
        </>
      )}

      {factoriesAsOwner.length > 0 && (
        <>
          <h3 css={h3Style}>Neue Firmen Gruppe erstellen</h3>
          <div css={factorySelectionContainerStyle}>
            <Autocomplete
              options={factoriesAsOwner}
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Firma auswählen"
                  variant="outlined"
                />
              )}
              value={selectedFactory}
              onChange={(ev, newValue: any) => {
                setSelectedFactory(newValue);
                setFactorySelectionInputValue(
                  newValue.hash ? newValue.label : ''
                );
              }}
              inputValue={factorySelectionInputValue}
              onInputChange={(ev: any) =>
                ev &&
                ev.target &&
                setFactorySelectionInputValue(ev.target.value)
              }
            />
          </div>
          <TextField
            css={inputStyle}
            label="Gruppenname"
            value={newFactoryGroupLabel}
            onChange={(ev) => setNewFactoryGroupLabel(ev.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GroupAddIcon />
                </InputAdornment>
              ),
              endAdornment: newFactoryGroupLabel !== '' && selectedFactory && (
                <InputAdornment
                  position="end"
                  css={{ cursor: 'pointer' }}
                  onClick={createNewFactoryGroup}
                >
                  <SendIcon />
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
    </div>
  );
};
