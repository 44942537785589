import MyVehiclesPage from '../pages/apps/MyVehiclesPage';
import TestsuitePage from '../pages/apps/TestsuitePage';
import FinancePage from '../pages/apps/FinancePage';
import CompanyPage from '../pages/apps/CompanyPage';
import FractionPage from '../pages/apps/FractionPage';
import { Mail } from '../components/apps/Mail';
import { Admin } from '../components/apps/Admin';
import { Settings as SettingsCmp } from '../components/apps/Settings';
import {
  Red,
  Blue,
  Green,
  Purple,
  Pink,
  Orange,
  Cyan,
  Gray70,
  Teal,
  RedDark,
  TealDark,
  BlueNight,
  YellowDark,
} from './styles/colors';

import { ReactComponent as FinanceSvg } from '../img/icons/accountBalance.svg';
import { ReactComponent as AdminSvg } from '../img/icons/adminPanelSettings.svg';
import { ReactComponent as FractionSvg } from '../img/icons/badge.svg';
import { ReactComponent as CompanySvg } from '../img/icons/business.svg';
import { ReactComponent as TestsuiteSvg } from '../img/icons/contentPaste.svg';
import { ReactComponent as MyVehiclesSvg } from '../img/icons/directionsCar.svg';
import { ReactComponent as MailSvg } from '../img/icons/email.svg';
import { ReactComponent as HashtagSvg } from '../img/icons/hashtag.svg';
import Settings from '@mui/icons-material/Settings';
import Web from '@mui/icons-material/Web';
import ArticleIcon from '@mui/icons-material/Article';
import FolderIcon from '@mui/icons-material/Folder';
import { Browser } from '../components/apps/Browser';
import { Leogram } from '../components/apps/Leogram';
import { DocumentsApp } from '../components/apps/Documents';
import { FolderApp } from '../components/apps/Folder';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { LsmdApp } from '../components/apps/LSMD';
import { ContentCreationApp } from '../components/apps/ContentCreation';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import { LspdApp } from '../components/apps/LSPD';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import GavelIcon from '@mui/icons-material/Gavel';
import GarageIcon from '@mui/icons-material/Garage';
import { DojApp } from '../components/apps/DOJ';
import { DmvApp } from '../components/apps/DMV';
import DockMailItem from '../components/dock/MailItem';

interface IApp {
  id: string;
  title: string;
  desktop?: boolean;
  show?: boolean;
  icon: any;
  content?: JSX.Element;
  link?: string;
  bgColor?: string;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  right?: string;
  customDockItemCmp?: any;
}

const apps = (hasRight: (right: string) => boolean): IApp[] =>
  [
    {
      id: 'mail',
      title: 'Mail',
      desktop: true,
      show: false,
      bgColor: Teal,
      icon: MailSvg,
      content: <Mail />,
      width: 1200,
      height: 600,
      customDockItemCmp: DockMailItem,
    },
    {
      id: 'myvehicles',
      title: 'Fahrzeuge',
      desktop: true,
      show: false,
      icon: MyVehiclesSvg,
      bgColor: Cyan,
      content: <MyVehiclesPage />,
      width: 1026,
      height: 675,
    },
    {
      id: 'finance',
      title: 'Finanzen',
      desktop: true,
      show: false,
      icon: FinanceSvg,
      bgColor: Blue,
      content: <FinancePage />,
      width: 1000,
      height: 600,
    },
    {
      id: 'browser',
      title: 'Webbrowser',
      desktop: true,
      show: false,
      icon: Web,
      bgColor: Purple,
      content: <Browser />,
      width: 1000,
      height: 600,
    },
    {
      id: 'leogram',
      title: 'Leogram',
      desktop: true,
      show: false,
      icon: HashtagSvg,
      bgColor: Red,
      content: <Leogram />,
      width: 400,
      height: 650,
    },
    {
      id: 'company',
      title: 'Firmen',
      desktop: true,
      show: false,
      icon: CompanySvg,
      bgColor: Orange,
      content: <CompanyPage />,
      width: 1000,
      height: 600,
    },
    {
      id: 'documents',
      title: 'Dokumente',
      desktop: true,
      show: false,
      icon: ArticleIcon,
      bgColor: YellowDark,
      content: <DocumentsApp />,
      width: 1100,
      height: 600,
    },
    {
      id: 'folders',
      title: 'Akten',
      desktop: true,
      show: false,
      icon: FolderIcon,
      bgColor: Green,
      content: <FolderApp />,
      width: 1200,
      height: 600,
    },
    {
      id: 'lsmd',
      title: 'LSMD',
      desktop: true,
      show: false,
      icon: LocalHospitalIcon,
      bgColor: Teal,
      content: <LsmdApp />,
      width: 1000,
      height: 600,
      right: 'lsmd.app',
    },
    {
      id: 'lspd',
      title: 'LSPD',
      desktop: true,
      show: false,
      icon: LocalPoliceIcon,
      bgColor: Cyan,
      content: <LspdApp />,
      width: 1000,
      height: 600,
      right: 'lspd.app',
    },
    {
      id: 'doj',
      title: 'DOJ',
      desktop: true,
      show: false,
      icon: GavelIcon,
      bgColor: Blue,
      content: <DojApp />,
      width: 1000,
      height: 600,
      right: 'doj.app',
    },
    {
      id: 'dmv',
      title: 'DMV',
      desktop: true,
      show: false,
      icon: GarageIcon,
      bgColor: Blue,
      content: <DmvApp />,
      width: 1000,
      height: 600,
      right: 'dmv.app',
    },
    {
      id: 'fraction',
      title: 'Behördenverwaltung',
      desktop: true,
      show: false,
      icon: FractionSvg,
      bgColor: Purple,
      content: <FractionPage />,
      width: 1000,
      height: 600,
      right: 'team.app',
    },
    {
      id: 'testsuite',
      title: 'Fragebögen',
      desktop: true,
      show: false,
      icon: TestsuiteSvg,
      bgColor: Pink,
      content: <TestsuitePage />,
      width: 1000,
      height: 600,
    },
    {
      id: 'settings',
      title: 'Einstellungen',
      desktop: true,
      show: false,
      icon: Settings,
      bgColor: Gray70,
      content: <SettingsCmp />,
      width: 1000,
      height: 600,
    },
    {
      id: 'contentcreation',
      title: 'Content Creation',
      desktop: true,
      show: false,
      icon: EditRoadIcon,
      bgColor: Gray70,
      content: <ContentCreationApp />,
      width: 1000,
      height: 600,
      right: 'admin.contentcreation',
    },
    {
      id: 'admin',
      title: 'Admin',
      desktop: true,
      show: false,
      icon: AdminSvg,
      bgColor: RedDark,
      content: <Admin />,
      width: 1000,
      height: 600,
      right: 'admin.player.search',
    },
  ].filter((entry) => !entry.right || hasRight(entry.right));

export default apps;
