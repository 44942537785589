const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
export default (title: string) => [
  [
    {
      value: title,
      colSpan: 19,
      style: fixFett,
      readOnly: true,
      width: '190vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 19,
      style: empty,
      readOnly: true,
      width: '190vh',
    },
  ],
  [
    {
      value: 'Datum',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Mitarbeiter',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Kunde',
      colSpan: 5,
      style: fixFett,
      readOnly: true,
      width: '50vh',
    },
    {
      value: 'Telnr.',
      colSpan: 5,
      style: fixFett,
      readOnly: true,
      width: '50vh',
    },
    {
      value: 'Notizen',
      colSpan: 5,
      style: fixFett,
      readOnly: true,
      width: '50vh',
    },
  ],
];

export const docDatesLineTemplate = [
  {
    value: '',
    colSpan: 2,
    style: usedFull,
    width: '20vh',
  },
  {
    value: '',
    style: usedFull,
    colSpan: 2,
    readOnly: true,
    width: '20vh',
    type: 'select',
    depOptions: {
      sheetId: 'doccontroldata',
      column: 'E',
      rowStart: 1,
      rowEnd: 110,
    },
  },
  {
    value: '',
    colSpan: 5,
    style: usedFull,
    width: '50vh',
  },
  {
    value: '',
    colSpan: 5,
    style: usedFull,
    width: '50vh',
  },
  {
    value: '',
    colSpan: 5,
    style: usedFull,
    width: '50vh',
  },
];
