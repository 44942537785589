import React, { useState, useEffect } from 'react';
import wallpapers from '../configs/wallpapers';
import { CgSleep } from 'react-icons/cg';
import { RiShutDownLine, RiRestartLine } from 'react-icons/ri';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { path } from 'ramda';
import { loginFailedP } from '../redux/reducer/login';

const loginBoxContainerStyles = css`
  position: relative;
  width: 25vh;
  height: 28vh;
  background: inherit;
  border-radius: 1.25vh;
  overflow: hidden;
  background-size: auto;

  :before {
    content: '';
    width: 25vh;
    height: 28vh;
    background: inherit;
    border-radius: 1vh;
    position: absolute;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 20vh rgb(255 255 255 / 7%);
    filter: blur(0.175vh);
  }
`;

const loginBoxStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const loginAvatarStyles = css`
  background-color: rgba(209, 213, 219, 0.3);
  border-radius: 50%;
  margin-bottom: 2vh;
`;

const loginBtnStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const loginBtnEnabled = css`
  cursor: pointer;
  :hover {
    background-color: #ffffff66;
  }
`;

const loginInputStyles = css`
  ::placeholder {
    opacity: 0.5;
  }
`;

const bannedTextStyle = css`
  color: #ffffff;
  font-weight: bold;
  padding: 10px;
`;

type LoginType = {
  dark: boolean;
  sleepMac: () => void;
  restartMac: () => void;
  shutMac: () => void;
  login: (bn: string, pw: string) => void;
};
export const Login = ({
  dark,
  sleepMac,
  restartMac,
  shutMac,
  login,
}: LoginType) => {
  const [username, setUsername] = useState('');
  const [password, setPasswort] = useState('');
  const loginEnabled = username !== '' && password !== '';
  const loginError = useSelector<
    any,
    { status: number; msg: string } | undefined
  >(path(loginFailedP));

  useEffect(() => {
    setPasswort('');
  }, [loginError]);

  const onPasswordInput = (key: string) => {
    if (key === 'Enter' && loginEnabled) {
      login(username, password);
    }
  };

  const banned = loginError?.status === 400;

  return (
    <div
      className="login text-center"
      style={{
        background: `url(${
          dark ? wallpapers.night : wallpapers.day
        }) center/cover no-repeat`,
        height: '100%',
        width: '100%',
      }}
    >
      <div
        className="inline-block w-auto relative top-1/2 -mt-40"
        css={loginBoxContainerStyles}
      >
        <div css={loginBoxStyles}>
          {/* Avatar */}
          <img
            className="rounded-full w-24 h-24 my-0 mx-auto"
            css={loginAvatarStyles}
            src="img/RedLion_Logo_Red.png"
            alt="img"
          />
          {!banned && (
            <>
              <div className="nightwind-prevent mx-auto grid grid-cols-5 w-44 h-8 mt-4 rounded-md bg-gray-300 blur bg-opacity-30">
                <input
                  css={loginInputStyles}
                  className="nightwind-prevent text-sm col-start-1 col-span-4 outline-none focus:outline-none bg-transparent px-2 text-white"
                  placeholder="Benutzername"
                  onClick={(e) => e.stopPropagation()}
                  value={username}
                  onChange={(ev: any) => setUsername(ev.target.value)}
                />
              </div>

              {/* Password Input */}
              <div className="nightwind-prevent mx-auto grid grid-cols-5 w-44 h-8 mt-4 rounded-md bg-gray-300 blur bg-opacity-30">
                <input
                  css={loginInputStyles}
                  className="nightwind-prevent text-sm col-start-1 col-span-4 outline-none focus:outline-none bg-transparent px-2 text-white"
                  type="password"
                  placeholder="Passwort"
                  onClick={(e) => e.stopPropagation()}
                  value={password}
                  onChange={(ev: any) => setPasswort(ev.target.value)}
                  onKeyPress={(e) => onPasswordInput(e.key)}
                />
              </div>

              <div
                className="nightwind-prevent mx-auto grid grid-cols-5 w-44 h-8 mt-4 rounded-md bg-gray-300 blur bg-opacity-30 text-sm mt-2 text-white"
                css={[loginBtnStyles, loginEnabled && loginBtnEnabled]}
                onClick={() => (loginEnabled ? login(username, password) : {})}
              >
                Einloggen
              </div>
            </>
          )}
          {banned && <div css={bannedTextStyle}>{loginError?.msg}</div>}
        </div>
      </div>

      {/* buttons */}
      {/* <div className="nightwind-prevent-block text-sm fixed bottom-64 left-0 right-0 mx-auto flex flex-row space-x-4 w-max">
        <div
          className="flex flex-col items-center text-white w-24"
          onClick={sleepMac}
        >
          <div className="h-10 w-10 bg-gray-700 rounded-full inline-flex justify-center items-center">
            <CgSleep size={40} />
          </div>
          <span>Sleep</span>
        </div>
        <div
          className="flex flex-col items-center text-white w-24"
          onClick={restartMac}
        >
          <div className="h-10 w-10 bg-gray-700 rounded-full inline-flex justify-center items-center">
            <RiRestartLine size={36} />
          </div>
          <span>Restart</span>
        </div>
        <div
          className="flex flex-col items-center text-white w-24"
          onClick={shutMac}
        >
          <div className="h-10 w-10 bg-gray-700 rounded-full inline-flex justify-center items-center">
            <RiShutDownLine size={36} />
          </div>
          <span>Shut Down</span>
        </div>
      </div> */}
    </div>
  );
};
