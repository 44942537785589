import { Dashboard } from '../Dashboard';
import { LSMD } from '../LSMD';
// import { createRSSFeedWebsite } from '../RSSFeed';
import { createSimpleMenuWithContent } from '../SimpleMenuWithContent';
import interiorConfig from '../../../../resources/websites/interior';
import { Laws } from '../Laws';
import { Cars } from '../Cars';

export const availableUrls: {
  [url: string]: {
    title: string;
    component: any;
  };
} = {
  '': {
    title: 'Startseite',
    component: Dashboard,
  },
  'lioninterior.red': {
    title: 'Interieure',
    component: createSimpleMenuWithContent(interiorConfig),
  },
  // 'lsmd.red': {
  //   title: 'LSMD',
  //   component: createRSSFeedWebsite('LSMD', '/proxy/rss?id=26'),
  // },
  // 'lspd.red': {
  //   title: 'LSPD',
  //   component: createRSSFeedWebsite('LSPD', '/proxy/rss?id=28'),
  // },
  // 'dmv.red': {
  //   title: 'DMV',
  //   component: createRSSFeedWebsite('DMV', '/proxy/rss?id=23'),
  // },
  // 'doc.red': {
  //   title: 'DOC',
  //   component: createRSSFeedWebsite('DOC', '/proxy/rss?id=70'),
  // },
  // 'doj.red': {
  //   title: 'DOJ',
  //   component: createRSSFeedWebsite('DOJ', '/proxy/rss?id=30'),
  // },
  // 'events.red': {
  //   title: 'Events',
  //   component: createRSSFeedWebsite('Events', '/proxy/rss?id=7'),
  // },
  'law.red': {
    title: 'Gesetze',
    component: Laws,
  },
  'cars.red': {
    title: 'Fahrzeuge',
    component: Cars,
  },
};
