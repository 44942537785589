import { css } from '@emotion/react';
import React from 'react';
import { isNil } from 'ramda';

export const SimpleValueViewer = ({ cell }: any) => {
  let style = `
    min-height: 3vh;
    height: 100%;
    border: 1px solid #e5e7eb;
    padding: 0.2vh;
    display: flex;
    align-items: center;
  `;

  if (cell.style) {
    const keys = Object.keys(cell.style);
    keys.forEach((key) => {
      style += `${key}: ${cell.style[key]};`;
    });
  }

  return (
    <div
      css={css`
        ${style}
      `}
    >
      {isNil(cell.computedValue) ? cell.value : cell.computedValue}
    </div>
  );
};
