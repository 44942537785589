import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listOldLsmdFiles } from '../../../actions';
import { appsLsmdOldFilesListP } from '../../../redux/reducer/apps/lsmd';
import { LsmdOldFileView } from './OldFileView';
import { OldFilesListEntryData } from './types';

export const LsmdOldFiles = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [openedFile, setOpenedFile] = useState('');
  const list = useSelector<any, OldFilesListEntryData[]>(
    pathOr([], appsLsmdOldFilesListP)
  );

  const fetchData = () => {
    dispatch(listOldLsmdFiles(search));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return openedFile ? (
    <LsmdOldFileView fileId={openedFile} goBack={() => setOpenedFile('')} />
  ) : (
    <div>
      <div>
        <TextField
          label="Suche"
          type="text"
          value={search}
          onChange={(ev: any) => setSearch(ev.target.value)}
        />

        <Button variant="outlined" onClick={fetchData}>
          Suchen
        </Button>
      </div>

      <Table>
        <TableBody>
          {list.map((entry) => (
            <TableRow key={entry._id} onClick={() => setOpenedFile(entry._id)}>
              <TableCell>{entry.patient}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
