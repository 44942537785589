export const TESTSUITES_CATALOGUE_FETCH_START =
  'TESTSUITES_CATALOGUE_FETCH_START';
export const TESTSUITES_CATALOGUE_FETCH_SUCCESS =
  'TESTSUITES_CATALOGUE_FETCH_SUCCESS';
export const TESTSUITES_CATALOGUE_FETCH_FAILED =
  'TESTSUITES_CATALOGUE_FETCH_FAILED';

export const TESTSUITES_ASSIGN_START = 'TESTSUITES_ASSIGN_START';
export const TESTSUITES_ASSIGN_SUCCESS = 'TESTSUITES_ASSIGN_SUCCESS';
export const TESTSUITES_ASSIGN_FAILED = 'TESTSUITES_ASSIGN_FAILED';

export const TESTSUITES_LOAD_ASSIGNED_START = 'TESTSUITES_LOAD_ASSIGNED_START';
export const TESTSUITES_LOAD_ASSIGNED_SUCCESS =
  'TESTSUITES_LOAD_ASSIGNED_SUCCESS';
export const TESTSUITES_LOAD_ASSIGNED_FAILED =
  'TESTSUITES_LOAD_ASSIGNED_FAILED';

export const TESTSUITES_LOAD_SUITE_START = 'TESTSUITES_LOAD_SUITE_START';
export const TESTSUITES_LOAD_SUITE_SUCCESS = 'TESTSUITES_LOAD_SUITE_SUCCESS';
export const TESTSUITES_LOAD_SUITE_FAILED = 'TESTSUITES_LOAD_SUITE_FAILED';

export const TESTSUITES_ANSWER_SEND_START = 'TESTSUITES_ANSWER_SEND_START';
export const TESTSUITES_ANSWER_SEND_SUCCESS = 'TESTSUITES_ANSWER_SEND_SUCCESS';
export const TESTSUITES_ANSWER_SEND_FAILED = 'TESTSUITES_ANSWER_SEND_FAILED';

export const TESTSUITES_RESULTS_FETCH_START = 'TESTSUITES_RESULTS_FETCH_START';
export const TESTSUITES_RESULTS_FETCH_SUCCESS =
  'TESTSUITES_RESULTS_FETCH_SUCCESS';
export const TESTSUITES_RESULTS_FETCH_FAILED =
  'TESTSUITES_RESULTS_FETCH_FAILED';

export const TESTSUITES_RESULT_FETCH_START = 'TESTSUITES_RESULT_FETCH_START';
export const TESTSUITES_RESULT_FETCH_SUCCESS =
  'TESTSUITES_RESULT_FETCH_SUCCESS';
export const TESTSUITES_RESULT_FETCH_FAILED = 'TESTSUITES_RESULT_FETCH_FETCH';
