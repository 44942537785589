import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { userRightsP } from '../../redux/reducer/login';
const { isAllowed } = require('access-right');

export const useAuthentication = (): [
  (right: string | string[]) => boolean
] => {
  const state = useSelector((state: any) => state);
  const rightList = pathOr([], userRightsP, state);
  const hasRight = (needs: string | string[]) => isAllowed(rightList, needs);

  return [hasRight];
};
