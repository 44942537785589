import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../utils/fetch';
import { notifyError } from '../utils';

export const onFetchFractionDutyOverview = (action$: any) =>
  pipeC(
    action$,
    ofType(types.FRACTION_OVERVIEW_DUTY_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/fraction/dutymember')
        .then((data) => [
          {
            type: types.FRACTION_OVERVIEW_DUTY_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => notifyError('Fehler beim Abfragen der Mitarbeiter'));
    })
  );

export const onFetchLsmdMapVehicles = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSMD_MAP_VEHICLES_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/map/lsmd/vehicles')
        .then((data) => [
          {
            type: types.LSMD_MAP_VEHICLES_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLsmdMapCalls = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSMD_MAP_CALLS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/map/lsmd/emergency')
        .then((data) => [
          {
            type: types.LSMD_MAP_CALLS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onListOldFiles = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSMD_OLDFILES_LIST_START),
    mapPromiseC(({ search }: any) => {
      return authFetchJson(`/lsmd/old-files/list?search=${search}`)
        .then((data) => [
          {
            type: types.LSMD_OLDFILES_LIST_SUCCESS,
            data,
          },
        ])
        .catch(() => notifyError('Fehler beim Abfragen'));
    })
  );
export const onFetchOldFile = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSMD_OLDFILE_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/lsmd/old-files/patient/${_id}`)
        .then((data) => [
          {
            type: types.LSMD_OLDFILE_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => notifyError('Fehler beim Abfragen'));
    })
  );
