import React from 'react';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/ClearRounded';
import Headline from '../../Headline';

type OverviewType = {
  fetchAssigned: () => void;
  assigned: {
    createdDate: string;
    id: string;
    label: string;
    submitted: boolean;
  }[];
  runSuite: (id: string) => void;
};

export const Overview = ({
  fetchAssigned,
  assigned = [],
  runSuite,
}: OverviewType) => {
  useEffect(() => {
    fetchAssigned();
  }, []);
  return (
    <div>
      <Headline>Übersicht</Headline>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Abgegeben</TableCell>
            <TableCell>Aktion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assigned.map((entry) => (
            <TableRow key={entry.id}>
              <TableCell>{entry.label}</TableCell>
              <TableCell>
                {entry.submitted ? <CheckIcon /> : <ClearIcon />}
              </TableCell>
              <TableCell>
                {!entry.submitted && (
                  <Button
                    disabled={entry.submitted}
                    onClick={() => runSuite(entry.id)}
                  >
                    Ausführen
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
