import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CachedIcon from '@mui/icons-material/Cached';
import { translate } from '../../../utils/translate';
import { css } from '@emotion/react';
import { lspdWantedP } from '../../../redux/reducer/apps/lspd';
import { fetchLspdWanted } from '../../../actions';
import { WantedListEntryData } from './types';
import { getWantedStateLabel, wantedStateIconStyle } from './wanted.utils';
import PriorityIcon from '@mui/icons-material/FiberManualRecordRounded';
import { DateTime } from 'luxon';

const refreshStyle = css`
  position: absolute !important;
  right: 3vh;
`;
const cursorStyle = css`
  cursor: pointer;
`;

type WantedListType = {
  openPerson: (_id: string, label: string) => void;
};
export const LspdWantedList = ({ openPerson }: WantedListType) => {
  const dispatch = useDispatch();
  const data = useSelector<any, WantedListEntryData[]>(pathOr([], lspdWantedP));

  const requestData = () => {
    dispatch(fetchLspdWanted());
  };

  useEffect(() => {
    requestData();
  }, []);

  return (
    <div>
      <h1>
        Wanted Liste
        <Button variant="outlined" onClick={requestData} css={refreshStyle}>
          Aktualisieren <CachedIcon />
        </Button>
      </h1>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Grund</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Beamter</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell
                  css={cursorStyle}
                  onClick={() =>
                    openPerson(
                      entry.character?.name ?? '',
                      entry.character?.name ?? ''
                    )
                  }
                >
                  {entry.character?.name ?? ''}
                </TableCell>
                <TableCell>
                  <PriorityIcon
                    css={wantedStateIconStyle}
                    className={entry.wanted.state}
                  />{' '}
                  {getWantedStateLabel(entry.wanted.state)}
                </TableCell>
                <TableCell>{entry.wanted.reason}</TableCell>
                <TableCell>
                  {DateTime.fromJSDate(new Date(entry.wanted.date))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>{entry.wanted.taggedBy?.name ?? ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
