import { pipe, path, pathOr } from 'ramda';
import { connect } from 'react-redux';
import { startSearch, setSearch, startRouterGoto } from '../actions';
import { Search } from '../components/Search';
import {
  profileSearchContentP,
  profileSearchTextP,
} from '../store/reducer/search';

const mapStateToProps = (state: any) => {
  return {
    content: path(profileSearchContentP, state),
    search: pathOr('', profileSearchTextP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  setSearch: pipe(setSearch, dispatch),
  startSearch: pipe(startSearch, dispatch),
  goto: pipe(startRouterGoto, dispatch),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Search);
