import dojControlCenterTemplate from '../../datasheets/templates/justice/dojcontrolcenter';
import controlDatasheet from '../../datasheets/templates/justice/control-datasheet';
import dojDashboardTemplate from '../../datasheets/templates/justice/dojdashboard';
import leadTemplate from '../../datasheets/templates/justice/lead';
import dojDatesTemplate, {
  dojDatesLineTemplate,
} from '../../datasheets/templates/justice/dojdates';
import docDashboardTemplate from '../../datasheets/templates/justice/docdashboard';
import docControlCenterTemplate from '../../datasheets/templates/justice/doccontrolcenter';
import docDatesTemplate, {
  docDatesLineTemplate,
} from '../../datasheets/templates/justice/docdates';
import vehiclecontrolTemplate, {
  justiceVehicleControlLineTemplate,
} from '../../datasheets/templates/justice/vehiclecontrol';

export const DOJAppSheets = [
  {
    sheetId: 'dojdashboard',
    label: 'Dashboard DOJ',
    right: 'doj.dashboard',
    config: {
      template: dojDashboardTemplate,
      requiredSheets: ['dojlead', 'dojcontroldata'],
    },
  },
  {
    sheetId: 'dojcontrolcenter',
    label: 'Dienstblatt DOJ',
    right: 'doj.controlcenter',
    config: {
      template: dojControlCenterTemplate,
      requiredSheets: ['dojcontroldata'],
    },
  },
  {
    sheetId: 'dojdatesoutside',
    label: 'Termine Auswärts (DOJ)',
    right: 'doj.datesoutside',
    config: {
      template: dojDatesTemplate('Termine Auswärts'),
      requiredSheets: ['dojcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: dojDatesLineTemplate,
    },
  },
  {
    sheetId: 'dojdatesinside',
    label: 'Termine im Haus (DOJ)',
    right: 'doj.datesinside',
    config: {
      template: dojDatesTemplate('Termine im Haus'),
      requiredSheets: ['dojcontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: dojDatesLineTemplate,
    },
  },
  {
    sheetId: 'docdashboard',
    label: 'Dashboard DOC',
    right: 'doc.dashboard',
    config: {
      template: docDashboardTemplate,
      requiredSheets: ['doclead', 'doccontroldata'],
    },
  },
  {
    sheetId: 'doccontrolcenter',
    label: 'Dienstblatt DOC',
    right: 'doc.controlcenter',
    config: {
      template: docControlCenterTemplate,
      requiredSheets: ['doccontroldata'],
    },
  },
  {
    sheetId: 'docdatesoutside',
    label: 'Termine Auswärts (DOC)',
    right: 'doc.datesoutside',
    config: {
      template: docDatesTemplate('Termine Auswärts'),
      requiredSheets: ['doccontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: docDatesLineTemplate,
    },
  },
  {
    sheetId: 'docdatesinside',
    label: 'Termine im Haus (DOC)',
    right: 'doc.datesinside',
    config: {
      template: docDatesTemplate('Termine im Haus'),
      requiredSheets: ['doccontroldata'],
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: docDatesLineTemplate,
    },
  },
  {
    sheetId: 'justicevehiclecontrol',
    label: 'Fahrzeug Status',
    right: 'justice.vehiclecontrol',
    config: {
      template: vehiclecontrolTemplate,
      canRemoveEmptyLines: true,
      headLinesCountToIgnoreOnRemove: 3,
      addLineTemplate: justiceVehicleControlLineTemplate,
    },
  },
  {
    sheetId: 'dojlead',
    label: 'Lead DOJ',
    right: 'doj.lead',
    config: {
      template: leadTemplate,
    },
  },
  {
    sheetId: 'doclead',
    label: 'Lead DOC',
    right: 'doc.lead',
    config: {
      template: leadTemplate,
    },
  },
  {
    sheetId: 'dojcontroldata',
    label: 'DB-Daten DOJ',
    right: 'doj.controldata',
    config: {
      template: controlDatasheet,
    },
  },
  {
    sheetId: 'doccontroldata',
    label: 'DB-Daten DOC',
    right: 'doc.controldata',
    config: {
      template: controlDatasheet,
    },
  },
];
