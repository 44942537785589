import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminApartmentDetailP = [
  'apps',
  'admin',
  'apartment',
  'detail',
];
export const appsAdminApartmentDetailL = lensPath(appsAdminApartmentDetailP);
const setDetail = (state: any, { data }: any) => {
  return set(appsAdminApartmentDetailL, data, state);
};

export const appsAdminApartmentReducerTree = {
  [types.ADMIN_APARTMENT_FETCH_SUCCESS]: [setDetail],
};
