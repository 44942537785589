import { Button, TextField } from '@mui/material';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {
  searchOwnerAdminVehicle,
  startAdminFactoryBankAccountFetch,
  startAdminFactoryFetch,
  startAdminFactoryOwnerSet,
  startAdminFactoryReload,
  startAdminFactoryVehiclesFetch,
} from '../../../actions';
import {
  appsAdminFactoryBankAccountsP,
  appsAdminFactoryDetailP,
  appsAdminFactoryVehiclesP,
} from '../../../redux/reducer/apps/admin/factory';
import { translate } from '../../../utils/translate';
import {
  FactoryBankAccount,
  FactoryDetailsType,
  FactoryVehicleData,
} from './types/factory';
import { PlayerInventoryEntry } from './types/playerinventory';
import CachedIcon from '@mui/icons-material/Cached';
import { Autocomplete } from '@mui/material';
import { VehicleOwnerSearchEntry } from './types/vehicle';
import { appsAdminVehiclOwnerSearchP } from '../../../redux/reducer/apps/admin/vehicle';

const subItemsStyle = css`
  padding-left: 10px;
`;

const renderItems = (items: PlayerInventoryEntry[]) => {
  if (!items) {
    return null;
  }
  return (
    <div>
      {items.map((entry) => (
        <div key={entry._id}>
          <div>
            {entry.amount}x {translate(entry.hash)}{' '}
            {entry.data?.label && '- ' + entry.data.label}
          </div>
          {entry.subitems && entry.subitems.length > 0 && (
            <div css={subItemsStyle}>{renderItems(entry.subitems)}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const BuySellView = ({ entries }: any) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Item</TableCell>
        <TableCell>Anazahl</TableCell>
        <TableCell>Preis</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {entries.map((entry: any) => (
        <TableRow key={entry._id}>
          <TableCell>{translate(entry.hash)}</TableCell>
          <TableCell>{entry.amount}</TableCell>
          <TableCell>${entry.price}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

type FactoryViewType = {
  _id: string;
  openPlayer: (_id: string, playerName: string) => void;
  openBankAccount: (_id: string, label: string) => void;
  openVehicle: (_id: string, numberPlate: string) => void;
};
export const FactoryView = ({
  _id,
  openPlayer,
  openBankAccount,
  openVehicle,
}: FactoryViewType) => {
  const [hasLoadedBankAccounts, setHasLoadedBankAccounts] = useState(false);
  const [hasLoadedVehicles, setHasLoadedVehicles] = useState(false);
  const [isOwnerEditing, setOwnerEditing] = useState(false);
  const [ownerInputValue, setOwnerInputValue] = useState<string>('');
  const [
    ownerInputSelected,
    setOwnerInputSelected,
  ] = useState<null | VehicleOwnerSearchEntry>(null);

  const dispatch = useDispatch();
  const factory = useSelector<any, null | FactoryDetailsType>(
    pathOr(null, appsAdminFactoryDetailP)
  );
  const bankAccounts = useSelector<any, FactoryBankAccount[]>(
    pathOr([], appsAdminFactoryBankAccountsP)
  );
  const vehicles = useSelector<any, FactoryVehicleData[]>(
    pathOr([], appsAdminFactoryVehiclesP)
  );
  const ownerSearch = useSelector<any, VehicleOwnerSearchEntry[]>(
    pathOr([], appsAdminVehiclOwnerSearchP)
  );

  useEffect(() => {
    if (factory) {
      setOwnerEditing(false);
      setOwnerInputValue('');
      setOwnerInputSelected(null);
    }
  }, [factory]);

  useEffect(() => {
    setHasLoadedBankAccounts(false);
    setHasLoadedVehicles(false);
    dispatch(startAdminFactoryFetch(_id));
  }, [_id]);

  const loadBankAccounts = () => {
    dispatch(startAdminFactoryBankAccountFetch(_id));
    setHasLoadedBankAccounts(true);
  };
  const loadVehicles = () => {
    dispatch(startAdminFactoryVehiclesFetch(_id));
    setHasLoadedVehicles(true);
  };

  const refresh = () => {
    setHasLoadedBankAccounts(false);
    setHasLoadedVehicles(false);
    dispatch(startAdminFactoryFetch(_id));
  };

  const relaodFactoryOnGameserver = () => {
    dispatch(startAdminFactoryReload(_id));
  };

  const searchNewOwner = (ev: any) => {
    const query = pathOr('', ['target', 'value'], ev);
    console.log('set', query);
    // @ts-ignore
    if (isNaN(query)) {
      setOwnerInputValue(query);
    }
    if (query.length > 2) {
      dispatch(searchOwnerAdminVehicle(query));
    }
  };

  const changeOwner = () => {
    if (ownerInputSelected) {
      dispatch(startAdminFactoryOwnerSet(_id, ownerInputSelected.text));
    }
  };

  if (!factory) {
    return null;
  }

  const counterItems: any[] = factory.counterItems || [];
  const machineItems = pathOr([], ['machineItems', 0, 'items'], factory);
  return (
    <div>
      <h1>Fabrik {factory.label}</h1>

      <Button variant="outlined" onClick={refresh}>
        Aktualisieren <CachedIcon />
      </Button>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Hash</TableCell>
            <TableCell>{factory.hash}</TableCell>
            <TableCell>
              <Button variant="outlined" onClick={relaodFactoryOnGameserver}>
                Auf Gameserver neu laden
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Typ</TableCell>
            <TableCell>{factory.type}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Label</TableCell>
            <TableCell>{factory.label}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Werbetext</TableCell>
            <TableCell>{factory.adLine}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Preis</TableCell>
            <TableCell>{factory.price}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Besitzer</TableCell>
            <TableCell
              onClick={() =>
                !isOwnerEditing &&
                openPlayer(factory.owner?._id ?? '', factory.owner?.name ?? '')
              }
            >
              {isOwnerEditing ? (
                <Autocomplete
                  options={ownerSearch}
                  getOptionLabel={(option) => option.text}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  value={ownerInputSelected}
                  onChange={(ev, newValue) => {
                    setOwnerInputSelected(newValue);
                    setOwnerInputValue(newValue?.text ?? '');
                  }}
                  inputValue={ownerInputValue}
                  onInputChange={searchNewOwner}
                />
              ) : (
                factory.owner?.name
              )}
            </TableCell>
            <TableCell>
              {isOwnerEditing ? (
                <Button variant="outlined" onClick={changeOwner}>
                  Anwenden
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setOwnerEditing(true)}
                >
                  Ändern
                </Button>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Dimension</TableCell>
            <TableCell>{factory.dimension}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <h2>Konten</h2>

      {!hasLoadedBankAccounts && (
        <Button variant="outlined" onClick={() => loadBankAccounts()}>
          Laden
        </Button>
      )}

      {hasLoadedBankAccounts && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ban</TableCell>
              <TableCell>Geld</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankAccounts.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell
                  onClick={() => openBankAccount(entry._id, entry.vban)}
                >
                  {entry.vban}
                </TableCell>
                <TableCell>${entry.money}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <h2>Fahrzeuge</h2>

      {!hasLoadedVehicles && (
        <Button variant="outlined" onClick={() => loadVehicles()}>
          Laden
        </Button>
      )}

      {hasLoadedVehicles && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kennzeichen</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell>Garage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vehicles.map((entry) => (
              <TableRow key={entry._id}>
                <TableCell
                  onClick={() => openVehicle(entry._id, entry.numberPlate)}
                >
                  {entry.numberPlate}
                </TableCell>
                <TableCell>{entry.name}</TableCell>
                <TableCell>
                  {entry.inGarage ? translate(entry.garagePosition) : '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <h2>Mitarbeiter</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Gehalt</TableCell>
            <TableCell>Paydays</TableCell>
            <TableCell>Rechte</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {factory.employees.map((employee) => (
            <TableRow key={employee.character?._id}>
              <TableCell>{employee.character?.name}</TableCell>
              <TableCell>{employee.money}</TableCell>
              <TableCell>{employee.paydays}</TableCell>
              <TableCell>
                {Object.keys(employee.rights)
                  // @ts-ignore
                  .filter((right) => employee.rights[right])
                  .join(', ')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <h2>Items</h2>
      {renderItems(factory.items)}

      <h2>Maschinenitems</h2>
      {renderItems(machineItems)}

      <h2>Thekenitems</h2>
      {renderItems(counterItems)}

      <h2>Türankauf</h2>
      <BuySellView entries={factory.buyingItems} />

      <h2>Türverkauf</h2>
      <BuySellView entries={factory.sellingItems} />
    </div>
  );
};
