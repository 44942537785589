import { set, lensPath } from 'ramda';
import store from '../redux/store';
import { push } from 'connected-react-router';
import { notifyError } from '../effects/utils';
import { startLogout } from '../actions';

const authLens = lensPath(['headers', 'authorizationtoken']);

// load initial token
let token = localStorage.getItem('token') || '';

// switch to login on empty token
if (!token) {
  setTimeout(() => {
    store.dispatch(push('/login'));
  }, 1000);
} else {
  const username = localStorage.getItem('username') || '';
  setTimeout(() => {
    store.dispatch({
      type: 'LOGIN_SUCCESS',
      token,
      username,
      settings: JSON.parse(localStorage.getItem('settings') || '{}'),
    });
  }, 1000);
}

export const setToken = (t: string) => {
  token = t;
};

const handleErrors = (response: Response) => {
  if (!response.ok) {
    throw response;
  }
  return response;
};

const catchUnautorized = (response: Response) => {
  if (response.status === 401) {
    // switch to login route
    store.dispatch(push('/login'));
    store.dispatch(startLogout());
  }
  if (response.status === 412) {
    // notify on no power error
    store.dispatch(notifyError('Keine Verbindung'));
  }
  if (response.status === 428) {
    // notify on production running
    store.dispatch(
      notifyError('System ist mit der laufenden Produktion beschäftigt')
    );
  }
  throw response;
};

export const authFetch = (url: string, opts: any = {}) => {
  opts = set(authLens, token, opts);
  return fetch(url, opts).then(handleErrors).catch(catchUnautorized);
};
export const authFetchJson = (url: string, opts: any = {}) =>
  authFetch(url, opts).then((res) => res.json());
export const authFetchPost = (url: string, body: any = {}) => {
  let opts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  opts = set(authLens, token, opts);
  return fetch(url, opts).then(handleErrors).catch(catchUnautorized);
};
export const authFetchDelete = (url: string, body: any = {}) => {
  let opts = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  opts = set(authLens, token, opts);
  return fetch(url, opts).then(handleErrors).catch(catchUnautorized);
};
