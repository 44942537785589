import { lensPath, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminBankAccountsSearchP = [
  'apps',
  'admin',
  'bankaccountssearch',
  'search',
];
export const appsAdminBankAccountsSearchL = lensPath(
  appsAdminBankAccountsSearchP
);
const setSearch = (state: any, { search }: any) => {
  return set(appsAdminBankAccountsSearchL, search, state);
};

export const appsAdminBankAccountsResultsP = [
  'apps',
  'admin',
  'bankaccountssearch',
  'results',
];
export const appsAdminBankAccountsResultsL = lensPath(
  appsAdminBankAccountsResultsP
);
const setSearchResults = (state: any, { data }: any) => {
  return set(appsAdminBankAccountsResultsL, data, state);
};

export const appsAdminBankAccountsSearchReducerTree = {
  [types.ADMIN_BANKACCOUNTS_SEARCH_SET]: [setSearch],
  [types.ADMIN_BANKACCOUNTS_FETCH_SUCCESS]: [setSearchResults],
};
