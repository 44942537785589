import React, { useState } from 'react';
import { css } from '@emotion/react';
import ProfileIcon from '@mui/icons-material/PersonRounded';
import EmojiIcon from '@mui/icons-material/SentimentSatisfiedRounded';
import CameraIcon from '@mui/icons-material/PhotoCameraRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { Button, CircularProgress } from '@mui/material';
import { textColor, hoverBgColor } from '../colors';
import { Emojis } from './Emojis';
import { tokenState } from '../utils/token';
import Gallery from '../shared/Gallery';
import { getLoggedInUserTag } from '../store/reducer/login';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  height: calc(100% - 12.5vh);
`;
export const profileImage = css`
  border-radius: 50%;
  width: 80%;
  margin: 10%;
  border: 0.1vh solid rgb(15 104 127 / 50%);
`;
export const messageContainerStyle = css`
  padding: 0 1vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  justify-content: space-between;
`;
const textboxStyle = css`
  width: calc(100% - 1vh);
  min-height: 3.5vh;
  border: none;
  padding: 0.5vh;
  resize: none;
  color: #ffffff;
  font-size: 1.75vh;
  line-height: 1.75vh;
  background-color: #00000066;
  font-weight: 100;
  font-family: Arial;

  :focus {
    border: none;
    outline: none;
  }

  ::placeholder {
    color: ${textColor};
    opacity: 0.7;
  }

  ::-webkit-scrollbar {
    width: 0.75vh;
  }

  ::-webkit-scrollbar-track {
    background: #00000033;
    transition: all 0.5s ease;
  }

  ::-webkit-scrollbar-thumb {
    background: #d50000aa;
    transition: all 0.5s ease;
  }
`;

const messageIconStyle = css`
  height: 2.5vh !important;
  width: 2.5vh !important;
  padding: 0.25vh;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;

  :hover {
    background: ${hoverBgColor};
  }
`;

const sendButtonStyle = css`
  background-color: #d50000 !important;
  color: #ffffff !important;
  padding: 0 1vh;
  height: 3vh;
  font-size: 1.25vh;
  :disabled {
    color: #ffffff66 !important;
    box-shadow: none;
    background-color: #d5000066 !important;
  }
`;

const headlineStyle = css`
  display: flex;
  align-items: center;
  padding: 1vh;
`;

const profileIconStyle = css`
  height: 2vh !important;
  width: 2vh !important;
  background-color: #d50000;
  border-radius: 50%;
  color: white;
  padding: 0.25vh;
  cursor: pointer;
`;

export const userTagStyle = css`
  color: #ffffff;
  font-weight: 100;
  padding-left: 1vh;
  font-size: 1.75vh;
  cursor: pointer;
  word-break: break-all;
`;

const optionsStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const loadingStyle = css`
  color: #ffffff !important;
  height: 2vh !important;
  width: 2vh !important;
`;

const galleryStyles = css`
  flex-grow: 1;
  ::-webkit-scrollbar {
    width: 0.75vh;
  }

  ::-webkit-scrollbar-track {
    background: #00000033;
    transition: all 0.5s ease;
  }

  ::-webkit-scrollbar-thumb {
    background: #d50000aa;
    transition: all 0.5s ease;
  }

  button {
    color: white;
    border: 1px solid white !important;
    width: 100%;
  }
`;

const noPhotoStyle = css`
  color: #ffffff99;
  padding: 1vh;
  font-size: 1.25vh;
  font-weight: 100;
`;

const imgContainer = css`
  position: relative;

  img {
    width: 100%;
    margin-bottom: 0.75vh;
  }

  svg {
    position: absolute;
    top: 1vh;
    right: 1vh;
    cursor: pointer;
    width: 2vh;
    height: 2vh;
    padding: 0.25vh;
    background-color: #ffffff66;
    border-radius: 50%;
    :hover {
      background-color: #ffffffaa;
    }
  }
`;

type NewMessageType = {
  createPost: (text: string, screenshotId?: string) => void;
  text: string;
  setPostText: (text: string) => void;
  initScreenshot: () => void;
  isLoading: boolean;
  screenshot?: { id: string; thumb: string; url: string };
};

export const NewMessage = ({
  createPost,
  text,
  setPostText,
  initScreenshot,
  isLoading,
  screenshot,
}: NewMessageType) => {
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState<any>(null);

  const startScreenshot = () => {
    initScreenshot();
  };

  const toggleGallery = () => {
    setIsGalleryOpen(!isGalleryOpen);
    if (!isGalleryOpen) {
      setIsEmojiOpen(isGalleryOpen);
    }
  };

  const toggleEmojis = () => {
    setIsEmojiOpen(!isEmojiOpen);
    if (!isEmojiOpen) {
      setIsGalleryOpen(isEmojiOpen);
    }
  };

  return (
    <div css={containerStyle}>
      <div css={headlineStyle}>
        <ProfileIcon css={profileIconStyle} />
        <div css={userTagStyle}>@{getLoggedInUserTag()}</div>
      </div>
      {selectedImg && (
        <div css={imgContainer}>
          <img src={selectedImg.url} />
          <CloseIcon onClick={() => setSelectedImg(null)} />
        </div>
      )}
      <div css={messageContainerStyle}>
        <textarea
          css={textboxStyle}
          placeholder="Was gibts neues?"
          value={text}
          onChange={(ev) => setPostText(ev.target.value)}
        />
        {isLoading && <CircularProgress css={loadingStyle} />}
        {isGalleryOpen && (
          <Gallery
            onImgClick={(img) => setSelectedImg(img)}
            additionalStyles={galleryStyles}
            EmptyInfo={() => (
              <div css={noPhotoStyle}>Keine Fotos vorhanden</div>
            )}
            columns={2}
          />
        )}
        <Emojis
          isOpen={isEmojiOpen}
          onSelect={(emoji: string) => setPostText((text || '') + emoji)}
        />
        <div css={optionsStyle}>
          <div>
            {tokenState.canCreateScreenshots && (
              <CameraIcon css={messageIconStyle} onClick={toggleGallery} />
            )}
            <EmojiIcon css={messageIconStyle} onClick={toggleEmojis} />
          </div>
          <Button
            css={sendButtonStyle}
            onClick={() => createPost(text, selectedImg?.id)}
            disableElevation
            variant="contained"
            disabled={!selectedImg && !text}
          >
            Senden
          </Button>
        </div>
      </div>
    </div>
  );
};
