import { set, lensPath, pathOr } from 'ramda';
import { photosL, photosP } from '../../leogram/store/reducer/photo';
import { APPS_PHOTO_GALERY_FETCH_SUCCESS } from '../../leogram/store/types';

const setPhotos = (state: any, { data, offset }: any) => {
  const oldPhotos = pathOr([], photosP, state);
  const newPhotos = offset === 0 ? data : [...oldPhotos, ...data];
  return set(photosL, newPhotos, state);
};

export const photoReducerTree = {
  [APPS_PHOTO_GALERY_FETCH_SUCCESS]: [setPhotos],
};
