import * as type from '../store/types';

export function startRegister(
  email: string,
  displayName: string,
  userTag: string,
  password: string,
  passwordRe: string
) {
  return {
    type: type.LEOGRAM_REGISTER_START,
    email,
    displayName,
    userTag,
    password,
    passwordRe,
  };
}

export function confirmRegistration(code: string) {
  return {
    type: type.LEOGRAM_REGISTER_CONFIRM_START,
    code,
  };
}
