import * as type from '../redux/types';

export const fetchLspdMapVehicles = () => ({
  type: type.LSPD_MAP_VEHICLES_FETCH_START,
});

export const fetchLspdMapCalls = () => ({
  type: type.LSPD_MAP_CALLS_FETCH_START,
});

export const fetchLspdMapAnkleMonitor = () => ({
  type: type.LSPD_MAP_ANKLEMONITOR_FETCH_START,
});

export const setLspdPersonSearch = (search: string) => ({
  type: type.LSPD_PERSON_SEARCH_SET,
  search,
});

export const fetchLspdPersonSearch = (search: string) => ({
  type: type.LSPD_PERSON_SEARCH_FETCH_START,
  search,
});

export const fetchLspdPersonDetails = (name: string) => ({
  type: type.LSPD_PERSON_DETAILS_FETCH_START,
  name,
});
export const saveLspdPersonWanted = (
  name: string,
  state: string,
  reason: string
) => ({
  type: type.LSPD_PERSON_WANTED_SET_FETCH_START,
  name,
  state,
  reason,
});

export const saveLspdPersonNotes = (name: string, notes: string) => ({
  type: type.LSPD_PERSON_NOTES_SAVE_FETCH_START,
  name,
  notes,
});

export const deleteLspdPersonTicket = (name: string, _id: string) => ({
  type: type.LSPD_PERSON_TICKET_DELETE_FETCH_START,
  _id,
  name,
});

export const setLspdVehicleSearch = (search: string) => ({
  type: type.LSPD_VEHICLE_SEARCH_SET,
  search,
});

export const fetchLspdVehicleSearch = (search: string) => ({
  type: type.LSPD_VEHICLE_FETCH_START,
  search,
});

export const setLspdWeaponSearch = (search: string) => ({
  type: type.LSPD_WEAPON_SEARCH_SET,
  search,
});

export const fetchLspdWeaponSearch = (search: string) => ({
  type: type.LSPD_WEAPON_FETCH_START,
  search,
});

export const changeLspdWeaponTag = (weaponId: string, tag: string) => ({
  type: type.LSPD_WEAPON_TAG_CHANGE_START,
  weaponId,
  tag,
});

export const fetchLspdWanted = () => ({
  type: type.LSPD_WANTED_FETCH_START,
});

export const fetchLspdLogs = (skip: number) => ({
  type: type.LSPD_LOGBOOK_FETCH_START,
  skip,
});

export const setLspdOldFilesSearch = (search: string) => ({
  type: type.LSPD_OLDFILES_SEARCH_SET,
  search,
});

export const fetchLspdOldFiles = (search: string, skip: number) => ({
  type: type.LSPD_OLDFILES_FETCH_START,
  skip,
  search,
});

export const fetchLspdOldFile = (_id: string) => ({
  type: type.LSPD_OLDFILE_FETCH_START,
  _id,
});
