import * as types from '../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../utils/callbag';
import { push } from 'connected-react-router';
import { authFetch } from '../utils/fetch';
import { notifyError } from './utils';
const nightwind = require('nightwind/helper');

export const onLoginStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LOGIN_START),
    mapPromiseC(({ username, password }: any) => {
      return authFetch('/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      })
        .then((res) => {
          return res.json().then(({ token, settings }) => [
            {
              type: types.LOGIN_SUCCESS,
              token,
              username,
              settings,
            },
            push('/'),
          ]);
        })
        .catch((res) => {
          return res.json().then(({ message }: any) => [
            {
              type: types.LOGIN_FAILED,
              msg: message,
              status: res.status,
            },
            notifyError('Einloggen fehlgeschlagen'),
          ]);
        });
    })
  );

export const onLoginSuccess = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LOGIN_SUCCESS),
    mapPromiseC(({ settings }: any) => {
      nightwind.enable(settings.darkMode);
    })
  );

export const onLogout = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LOGOUT),
    mapC(() => {
      return push('/login');
    })
  );

export const onCheckLogin = (action$: any) =>
  pipeC(
    action$,
    ofType(types.CHECK_LOGIN),
    mapPromiseC(() => {
      return authFetch('/is-logged-in').then(() => undefined);
    })
  );
