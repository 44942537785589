import * as types from '../store/types';
import {
  pipeC,
  ofType,
  mapPromiseC,
  debounceWithCollectC,
} from '../utils/callbag';
import { authFetch, authFetchPost } from '../utils/fetch';
import { existingUsersP, postsP } from '../store/reducer/posts';
import { filter, pathOr } from 'ramda';
import { startRouterGoto } from '../actions';

export const onPostCreateStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_POST_CREATE_START),
    mapPromiseC(({ text, screenshotId }: any) => {
      return authFetch('/leogram/post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text,
          screenshotId,
        }),
      })
        .then((res) => {
          return [
            {
              type: types.LEOGRAM_POST_CREATE_SUCCESS,
            },
            startRouterGoto('/'),
          ];
        })
        .catch((res) => {
          return {
            type: types.LEOGRAM_LOGIN_FAILED,
          };
        });
    })
  );

export const onPostsFetchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_POSTS_FETCH_START),
    mapPromiseC(() => {
      return authFetch('/leogram/post')
        .then((res) => {
          return res.json().then((posts) => ({
            type: types.LEOGRAM_POSTS_FETCH_SUCCESS,
            posts,
          }));
        })
        .catch((res) => {
          return {
            type: types.LEOGRAM_POSTS_FETCH_FAILED,
          };
        });
    })
  );

export const onPostsMoreFetchStart = (action$: any, getState: () => any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_POSTS_MORE_FETCH_START),
    mapPromiseC(() => {
      const currentPosts: any[] = pathOr([], postsP, getState());
      return authFetch(`/leogram/post?skip=${currentPosts.length}`)
        .then((res) => {
          return res.json().then((posts) => ({
            type: types.LEOGRAM_POSTS_MORE_FETCH_SUCCESS,
            posts,
          }));
        })
        .catch(() => {
          return {
            type: types.LEOGRAM_POSTS_MORE_FETCH_FAILED,
          };
        });
    })
  );

const createLikeOrDislike = (
  startType: string,
  successType: string,
  failedType: string,
  url: string
) => (action$: any) =>
  pipeC(
    action$,
    ofType(startType),
    mapPromiseC(({ postId, followActionType }: any) => {
      return authFetch('/leogram' + url + '/' + postId)
        .then(() => {
          return [
            {
              type: successType,
            },
            {
              type: followActionType,
            },
          ];
        })
        .catch((res) => {
          return {
            type: failedType,
            res,
          };
        });
    })
  );

export const onPostLikeStart = createLikeOrDislike(
  types.LEOGRAM_POST_LIKE_START,
  types.LEOGRAM_POST_LIKE_SUCCESS,
  types.LEOGRAM_POST_LIKE_FAILED,
  '/like'
);
export const onPostDislikeStart = createLikeOrDislike(
  types.LEOGRAM_POST_DISLIKE_START,
  types.LEOGRAM_POST_DISLIKE_SUCCESS,
  types.LEOGRAM_POST_DISLIKE_FAILED,
  '/dislike'
);

export const onUserTagExistsStart = (action$: any, getState: () => any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_USERTAG_EXISTS_FETCH_START),
    debounceWithCollectC(300),
    mapPromiseC((args: any[]) => {
      const userTags = args.map((arg) => arg.userTag);
      const current: string[] = pathOr([], existingUsersP, getState());
      const userTagsToCheck = filter(
        (x: string) => current.indexOf(x) === -1,
        userTags
      );
      return userTagsToCheck.length > 0
        ? authFetchPost('/leogram/user/exists', { userTags: userTagsToCheck })
            .then((res) => {
              return res.json().then((data) => ({
                type: types.LEOGRAM_POSTS_MORE_FETCH_SUCCESS,
                data,
                hasBeenChecked: userTags,
              }));
            })
            .catch(() => {})
        : Promise.resolve();
    })
  );
