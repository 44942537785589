import React, { useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';

import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/index.tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toastify.css';
import './styles/scrollbar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import store, { history } from './redux/store';
import ReactGA from 'react-ga';
import { Route } from 'react-router';

import Desktop from './pages/Desktop';
import Login from './pages/LoginPage';
import Boot from './pages/Boot';
import keys from './configs/keys';
import IngameWrapper from './pages/IngameWrapper';
import ThemeWrapper from './wrappers/theme';

ReactGA.initialize(keys.ga);

// export default function App() {
//   const [login, setLogin] = useState(false);
//   const [booting, setBooting] = useState(false);
//   const [restart, setRestart] = useState(false);
//   const [sleep, setSleep] = useState(false);

//   const shutMac = (e: any) => {
//     e.stopPropagation();
//     setRestart(false);
//     setSleep(false);
//     setLogin(false);
//     setBooting(true);
//   };

//   const restartMac = (e: any) => {
//     e.stopPropagation();
//     setRestart(true);
//     setSleep(false);
//     setLogin(false);
//     setBooting(true);
//   };

//   const sleepMac = (e: any) => {
//     e.stopPropagation();
//     setRestart(false);
//     setSleep(true);
//     setLogin(false);
//     setBooting(true);
//   };

//   if (booting) {
//     return <Boot restart={restart} sleep={sleep} setBooting={setBooting} />;
//   } else if (login) {
//     return (
//       <Desktop
//         //@ts-ignore
//         setLogin={setLogin}
//         shutMac={shutMac}
//         sleepMac={sleepMac}
//         restartMac={restartMac}
//       />
//     );
//   } else {
//     return (
//       <Login
//         //@ts-ignore
//         setLogin={setLogin}
//         shutMac={shutMac}
//         sleepMac={sleepMac}
//         restartMac={restartMac}
//       />
//     );
//   }
// }

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <IngameWrapper>
      <ThemeWrapper>
        <ToastContainer />
        {/* @ts-ignore  */}
        <ConnectedRouter history={history}>
          {/* <React.StrictMode>
      <App />
    </React.StrictMode> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={Desktop} />
          {/* @ts-ignore  */}
          <Route exact path="/boot" component={Boot} />
        </ConnectedRouter>
      </ThemeWrapper>
    </IngameWrapper>
  </Provider>,
  rootElement
);
