import { path, pipe } from 'ramda';
import { connect } from 'react-redux';
import { Login } from '../components/Login';
import { startLogin, resetLoginFailed, startRouterGoto } from '../actions';
import { loginFailedP } from '../store/reducer/login';

const mapStateToProps = (state: any) => {
  return {
    failed: path(loginFailedP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  login: pipe(startLogin, dispatch),
  goto: pipe(startRouterGoto, dispatch),
  resetFailed: pipe(resetLoginFailed, dispatch),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Login);
