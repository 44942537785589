import { concat, lensPath, pathOr, pipe, set } from 'ramda';
import * as types from '../../../types';

export const appsAdminRolesP = ['apps', 'admin', 'roles'];
export const appsAdminRolesL = lensPath(appsAdminRolesP);
const setRoles = (state: any, { data }: any) => {
  return set(appsAdminRolesL, data, state);
};

export const appsAdminAllRightsP = ['apps', 'admin', 'allRights'];
export const appsAdminAllRightsL = lensPath(appsAdminAllRightsP);
const setAllRights = (state: any, { data }: any) => {
  return set(appsAdminAllRightsL, data, state);
};

export const appsAdminRolesReducerTree = {
  [types.ADMIN_ROLES_FETCH_SUCCESS]: [setRoles],
  [types.ADMIN_RIGHTS_ALL_FETCH_SUCCESS]: [setAllRights],
};
