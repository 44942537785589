import React, { useEffect } from 'react';
import { CompanyData, CompanyDutyData } from './types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Headline from '../../Headline';
import { DateTime } from 'luxon';

type CheckInsType = {
  company: CompanyData;
  fetchCheckIns: (id: string) => void;
  checkIns: CompanyDutyData[];
};

export const CheckIns = ({
  company,
  fetchCheckIns,
  checkIns,
}: CheckInsType) => {
  useEffect(() => {
    fetchCheckIns(company._id);
  }, []);

  return (
    <div>
      <Headline>Mitarbeiter Check Ins</Headline>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Mitarbeiter</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checkIns.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                {DateTime.fromJSDate(new Date(entry.date))
                  .setLocale('de')
                  .toFormat('dd.LL.yy, HH:mm')}
              </TableCell>
              <TableCell>{entry.employee}</TableCell>
              <TableCell>
                {entry.status ? 'Eingestempelt' : 'Ausgestempelt'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
