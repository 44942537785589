import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from 'react-redux';
import { find, pathOr } from 'ramda';
import { pollPlayerOnDuty } from '../../../actions';
import { playerOnDutyP } from '../../../redux/reducer/apps/folder-groups';
import { Sidebar } from '../../sidebars/Sidebar';
import { DmvOverview } from './Overview';
import { useAuthentication } from '../../../utils/authentication';
import { DMVAppSheets } from '../../../resources/teams/drivingschool/app-sheets';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { Datasheet } from '../../Datasheet/Datasheet';

const contentStyle = css`
  position: relative;
`;
const missingDutyContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2vh;
`;

export const DmvApp = () => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const [selectedMenu, setSelectedMenu] = useState('overview');
  const isOnDuty = useSelector(pathOr(false, playerOnDutyP));

  useEffect(() => {
    dispatch(pollPlayerOnDuty());
    const int = setInterval(() => {
      dispatch(pollPlayerOnDuty());
    }, 20000);
    return () => {
      clearInterval(int);
    };
  }, []);

  const menu = [
    {
      id: 'overview',
      title: 'Übersicht',
      icon: <HomeIcon />,
    },
    ...DMVAppSheets.map((entry) => ({
      id: `datasheet-${entry.sheetId}`,
      title: entry.label,
      icon: <ControlCameraIcon />,
      right: entry.right,
    })),
  ].filter((entry: any) => !entry.right || hasRight(entry.right));

  return (
    <div className="font-avenir flex flex-row w-full h-full">
      {isOnDuty ? (
        <>
          <div className="flex-none w-44">
            <Sidebar
              items={menu}
              selected={selectedMenu}
              select={setSelectedMenu}
            />
          </div>
          <div className="flex-grow">
            <div
              className="markdown w-full h-full bg-gray-50 text-gray-700 overflow-auto p-6"
              css={contentStyle}
            >
              {selectedMenu === 'overview' && <DmvOverview />}
              {selectedMenu.startsWith('datasheet-') && (
                <Datasheet
                  sheetId={selectedMenu.replace('datasheet-', '')}
                  config={
                    find<any>(
                      (x: any) =>
                        x.sheetId === selectedMenu.replace('datasheet-', ''),
                      DMVAppSheets
                    ).config
                  }
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div
          css={missingDutyContainerStyle}
          className="w-full h-full bg-gray-50 text-gray-700"
        >
          Nur im Dienst verfügbar
        </div>
      )}
    </div>
  );
};
