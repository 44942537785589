export const COMPANIES_FETCH_START = 'COMPANIES_FETCH_START';
export const COMPANIES_FETCH_SUCCESS = 'COMPANIES_FETCH_SUCCESS';
export const COMPANIES_FETCH_FAILED = 'COMPANIES_FETCH_FAILED';

export const COMPANY_LABEL_CHANGE_START = 'COMPANY_LABEL_CHANGE_START';
export const COMPANY_LABEL_CHANGE_SUCCESS = 'COMPANY_LABEL_CHANGE_SUCCESS';
export const COMPANY_LABEL_CHANGE_FAILED = 'COMPANY_LABEL_CHANGE_FAILED';

export const COMPANY_ADD_CHANGE_START = 'COMPANY_ADD_CHANGE_START';
export const COMPANY_ADD_CHANGE_SUCCESS = 'COMPANY_ADD_CHANGE_SUCCESS';
export const COMPANY_ADD_CHANGE_FAILED = 'COMPANY_ADD_CHANGE_FAILED';

export const COMPANY_STORAGE_FETCH_START = 'COMPANY_STORAGE_FETCH_START';
export const COMPANY_STORAGE_FETCH_SUCCESS = 'COMPANY_STORAGE_FETCH_SUCCESS';
export const COMPANY_STORAGE_FETCH_FAILED = 'COMPANY_STORAGE_FETCH_FAILED';

export const COMPANY_STORAGE_PRICE_SET_START =
  'COMPANY_STORAGE_PRICE_SET_START';
export const COMPANY_STORAGE_PRICE_SET_SUCCESS =
  'COMPANY_STORAGE_PRICE_SET_SUCCESS';
export const COMPANY_STORAGE_PRICE_SET_FAILED =
  'COMPANY_STORAGE_PRICE_SET_FAILED';

export const COMPANY_COUNTER_FETCH_START = 'COMPANY_COUNTER_FETCH_START';
export const COMPANY_COUNTER_FETCH_SUCCESS = 'COMPANY_COUNTER_FETCH_SUCCESS';
export const COMPANY_COUNTER_FETCH_FAILED = 'COMPANY_COUNTER_FETCH_FAILED';

export const COMPANY_COUNTER_PRICE_SET_START =
  'COMPANY_COUNTER_PRICE_SET_START';
export const COMPANY_COUNTER_PRICE_SET_SUCCESS =
  'COMPANY_COUNTER_PRICE_SET_SUCCESS';
export const COMPANY_COUNTER_PRICE_SET_FAILED =
  'COMPANY_COUNTER_PRICE_SET_FAILED';

export const COMPANY_EMPLOYEE_EDIT_START = 'COMPANY_EMPLOYEE_EDIT_START';
export const COMPANY_EMPLOYEE_EDIT_SUCCESS = 'COMPANY_EMPLOYEE_EDIT_SUCCESS';
export const COMPANY_EMPLOYEE_EDIT_FAILED = 'COMPANY_EMPLOYEE_EDIT_FAILED';

export const COMPANY_EMPLOYEE_FIRE_START = 'COMPANY_EMPLOYEE_FIRE_START';
export const COMPANY_EMPLOYEE_FIRE_SUCCESS = 'COMPANY_EMPLOYEE_FIRE_SUCCESS';
export const COMPANY_EMPLOYEE_FIRE_FAILED = 'COMPANY_EMPLOYEE_FIRE_FAILED';

export const COMPANY_EMPLOYEE_SALARY_START = 'COMPANY_EMPLOYEE_SALARY_START';
export const COMPANY_EMPLOYEE_SALARY_SUCCESS =
  'COMPANY_EMPLOYEE_SALARY_SUCCESS';
export const COMPANY_EMPLOYEE_SALARY_FAILED = 'COMPANY_EMPLOYEE_SALARY_FAILED';

export const COMPANY_EMPLOYEE_INVITE_START = 'COMPANY_EMPLOYEE_INVITE_START';
export const COMPANY_EMPLOYEE_INVITE_SUCCESS =
  'COMPANY_EMPLOYEE_INVITE_SUCCESS';
export const COMPANY_EMPLOYEE_INVITE_FAILED = 'COMPANY_EMPLOYEE_INVITE_FAILED';

export const COMPANY_EMPLOYEE_CHECKIN_FETCH_START =
  'COMPANY_EMPLOYEE_CHECKIN_FETCH_START';
export const COMPANY_EMPLOYEE_CHECKIN_FETCH_SUCCESS =
  'COMPANY_EMPLOYEE_CHECKIN_FETCH_SUCCESS';
export const COMPANY_EMPLOYEE_CHECKIN_FETCH_FAILED =
  'COMPANY_EMPLOYEE_CHECKIN_FETCH_FAILED';

export const COMPANY_EXPORTS_FETCH_START = 'COMPANY_EXPORTS_FETCH_START';
export const COMPANY_EXPORTS_FETCH_SUCCESS = 'COMPANY_EXPORTS_FETCH_SUCCESS';
export const COMPANY_EXPORTS_FETCH_FAILED = 'COMPANY_EXPORTS_FETCH_FAILED';

export const COMPANY_PRODUCTION_FETCH_START = 'COMPANY_PRODUCTION_FETCH_START';
export const COMPANY_PRODUCTION_FETCH_SUCCESS =
  'COMPANY_PRODUCTION_FETCH_SUCCESS';
export const COMPANY_PRODUCTION_FETCH_FAILED =
  'COMPANY_PRODUCTION_FETCH_FAILED';

export const COMANIES_BY_OWNER_SIMPLE_FETCH_START =
  'COMANIES_BY_OWNER_SIMPLE_FETCH_START';
export const COMANIES_BY_OWNER_SIMPLE_FETCH_SUCCESS =
  'COMANIES_BY_OWNER_SIMPLE_FETCH_SUCCCESS';
