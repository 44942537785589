import { clone, lensPath, set } from 'ramda';
import * as types from '../../types';

const LEXICAL_EMPTY_DOC = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
      },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};

export const foldersP = ['apps', 'folders', 'list'];
export const foldersL = lensPath(foldersP);
const setFolders = (state: any, { data }: any) => {
  return set(foldersL, data, state);
};

export const folderSearchP = ['apps', 'folders', 'search'];
const setFolderSearch = (state: any, { group, search }: any) => {
  return set(lensPath([...folderSearchP, group]), search, state);
};

export const folderP = ['apps', 'folders', 'details'];
const setFolder = (state: any, { data }: any) => {
  return set(lensPath([...folderP, data._id]), data, state);
};

const createFolder = (state: any, { group, _id }: any) => {
  return set(
    lensPath([...folderP, _id]),
    {
      _id,
      isNew: true,
      group,
      creationDate: undefined,
      documents: [],
      subject: '',
      priority: 'normal',
      notes: clone(LEXICAL_EMPTY_DOC),
      notesVersion: 2,
      targets: [],
    },
    state
  );
};

export const selectedFolderP = ['apps', 'folders', 'selection'];
const setSelectedFolder = (state: any, { group, folderId }: any) => {
  return set(lensPath([...selectedFolderP, group]), folderId, state);
};

export const folderDocumentViewP = ['apps', 'folders', 'documentView'];
const setDocumentViewData = (state: any, { data }: any) => {
  return set(lensPath(folderDocumentViewP), data, state);
};

export const appsFoldersReducerTree = {
  [types.FOLDERS_FETCH_SUCCESS]: [setFolders],
  [types.FOLDER_FETCH_SUCCESS]: [setFolder],
  [types.FOLDER_SEARCH_SET]: [setFolderSearch],
  [types.FOLDER_CREATE_START]: [createFolder],
  [types.FOLDER_SELECTION_SET]: [setSelectedFolder],
  [types.FOLDER_DOCUMENT_FETCH_SUCCESS]: [setDocumentViewData],
};
