import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { pathOr, reverse } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOldLsmdFile } from '../../../actions';
import { appsLsmdOldFileP } from '../../../redux/reducer/apps/lsmd';
import { OldFileData } from './types';
import { DateTime } from 'luxon';
import { css } from '@emotion/react';

const containerStyle = css`
  h3 {
    margin-top: 2vh;
  }
`;

const dataContainerStyle = css`
  padding: 1.5vh;

  h3 {
    margin-top: 3vh;
  }
`;

type FolderDocumentViewType = {
  fileId: string;
  goBack: () => void;
};
export const LsmdOldFileView = ({ fileId, goBack }: FolderDocumentViewType) => {
  const dispatch = useDispatch();
  const data = useSelector<any, OldFileData | null>(
    pathOr(null, appsLsmdOldFileP)
  );

  useEffect(() => {
    dispatch(fetchOldLsmdFile(fileId));
  }, [fileId]);

  return data ? (
    <div css={containerStyle}>
      <div>
        <Button variant="outlined" onClick={() => goBack()}>
          Zurück
        </Button>
      </div>

      <h3>Allgemeine Daten</h3>
      <div css={dataContainerStyle}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Patient</TableCell>
              <TableCell>{data.patient}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Allergien</TableCell>
              <TableCell>{data.allergies}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dauerhafte Diagnosen</TableCell>
              <TableCell>{data.permanentDiseases}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <h3>Behandlungen</h3>
      {reverse(data.treatments).map((treatment, index) => (
        <div key={treatment._id} css={dataContainerStyle}>
          <h3>
            {data.treatments.length - index}){' '}
            {DateTime.fromJSDate(new Date(treatment.date))
              .setLocale('de')
              .toFormat('dd.LL.yy, HH:mm')}
          </h3>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Behandelnder Mitarbeiter</TableCell>
                <TableCell>{treatment.doc}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Verletzungen</TableCell>
                <TableCell>{treatment.injury}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Notizen</TableCell>
                <TableCell>{treatment.notes}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Anamese</TableCell>
                <TableCell>{treatment.anamnese}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Befund</TableCell>
                <TableCell>{treatment.findings}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Diagnose</TableCell>
                <TableCell>{treatment.diagnosis}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Therapie</TableCell>
                <TableCell>{treatment.therapy}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ))}
    </div>
  ) : null;
};
