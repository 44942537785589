import React from 'react';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import HistoryIcon from '@mui/icons-material/HistoryOutlined';
import Button from '@mui/material/Button';
import Headline from '../../Headline';
import { translate } from '../../../utils/translate';
import { css } from '@emotion/react';
import { Red } from '../../../configs/styles/colors';

import { AccountTransactionData } from './types';

const TIME_OPTIONS = {
  locale: de,
};

const negativeStyle = css`
  color: ${Red} !important;
`;

const replaceJimPreston = (name: string) =>
  name === 'Jim_Preston' ? 'San Andreas Financial' : name;

type TransactionsType = {
  vban: string;
  transactions: AccountTransactionData | undefined;
  fetchTransactions: (vban: string, offset: number) => void;
};

export const Transactions = ({
  vban,
  transactions,
  fetchTransactions,
}: TransactionsType) => {
  const canLoadMore = transactions && !transactions?.isComplete;
  const loadMore = () => {
    fetchTransactions(vban, transactions?.data.length || 0);
  };

  return transactions ? (
    <div>
      <Headline>Transaktionen</Headline>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Auftraggeber</TableCell>
            <TableCell>Empfänger</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Verwendungszweck</TableCell>
            <TableCell align="right">Betrag</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.data.map((transaction) => {
            const isFromMyAccount = transaction.sourceAccount?.vban === vban;
            const fromTo = isFromMyAccount
              ? transaction.destinationAccount?.ownerCharacter?.name ??
                transaction.destinationAccount?.ownerFactory?.label
              : transaction.sourceAccount?.ownerCharacter?.name ??
                transaction.sourceAccount?.ownerFactory?.label;
            return (
              <TableRow
                key={transaction._id}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  {format(
                    new Date(transaction.created),
                    'd.MM.yyyy HH:mm',
                    TIME_OPTIONS
                  )}
                </TableCell>
                <TableCell>
                  {transaction.sourceAccount
                    ? transaction.sourceAccount.vban
                    : '-'}
                </TableCell>
                <TableCell>
                  {transaction.destinationAccount?.vban ?? '-'}
                </TableCell>
                <TableCell>{replaceJimPreston(fromTo || '')}</TableCell>
                <TableCell component="th" scope="row">
                  {translate(transaction.reference)} {transaction.payload || ''}
                </TableCell>
                <TableCell align="right" css={isFromMyAccount && negativeStyle}>
                  $ {isFromMyAccount && '-'}
                  {(isFromMyAccount
                    ? transaction.amount + transaction.taxAmount
                    : transaction.amount || transaction.taxAmount
                  ).toFixed(2)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {canLoadMore && (
        <Button
          variant="outlined"
          onClick={loadMore}
          startIcon={<HistoryIcon />}
        >
          ältere Einträge
        </Button>
      )}
    </div>
  ) : null;
};
