const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fix = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedFix = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
};
export default [
  [
    {
      value: 'ALARM- UND AUSRÜCKORDNUNG',
      colSpan: 7,
      style: fix,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 7,
      style: empty,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: 'Los Santos Medical Department',
      colSpan: 7,
      style: fix,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: 'Stichwort',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Lage',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Einheiten',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'SoSi',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'PRIO 0',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value:
        'Person mit leichter Verletzung, ansprechbar (Kein akuter Notfall!)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'MEDIC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'NEIN',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'PRIO 1',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Person mit nicht lebensbedrohlicher Verletzung/Erkrankung',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'MEDIC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'PRIO 2',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Person mit lebensbedrohlicher Verletzung/Erkrankung',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'MEDIC + DOC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'Nachforderung DOC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Nachforderung durch MEDIC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'DOC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'MANV',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value:
        'Großschadenslage mit MEHR Patienten als verfügbare Rettungsmittel',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value:
        'Je nach Pat. Anzahl Regelrettung/MEDIC neu besetzen + B-Unit + A-Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'SAFEGUARD',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Absicherung  Brandeinsatz',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'MEDIC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'ERKUNDUNG',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Unklare Lage - Erkundung vor Ort',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'A-Unit bzw. B-Unit oder DOC',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 7,
      style: empty,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: 'Los Santos Fire Department',
      colSpan: 7,
      style: fix,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: 'Stichwort',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Lage',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Einheiten',
      colSpan: 2,
      style: usedFull,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'SoSi',
      colSpan: 1,
      style: usedFull,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'FEUER 0',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Kleinbrand z.B. Wiese , PKW , Mülleimer',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Engine',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'FEUER 1',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value:
        'Mittelbrand z.B. Buschwerk , LKW  // PKW Brand Menschenleben in Gefahr *²',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Engine + D-Unit (*² + MEDIC , DOC)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'FEUER 2',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Großbrand z.B. Gebäudebrand // Feuer mit Menschenleben in Gefahr',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Engine + Engine + MED + DOC + D-Unit + C-Unit + A-Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'TECH 0',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Teschn. Hilfe Klein , Baum auf Straße etc',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: '(Heavy) Squad ',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'TECH 1',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Techn. Hilfe Groß',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value:
        'Engine + (Heavy) Squad +  Heavy Rescue + D-Unit + (nach Situation C Unit)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'TECH 1 - VU',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Verkehrsunfall ohne Personenschaden',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: '(Heavy) Squad + Engine',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'TECH 1 - GEFAHRGUT',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Auslaufende Betriebstoffe in großen Mengen ',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value:
        'Engine + (Heavy) Squad + Heavy Rescue + D-Unit + (nach Situation C Unit)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'UNTERSTÜTZUNG - MD',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Tragehilfe /Absicherung  MD',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Je nach Lage (Heavy) Squad / Engine',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'FIRST RESPONDER',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'First Responder Einsatz, da kein freies Rettungsmittel',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Engine / Utillity',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'ERKUNDUNG',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Unklare Lage - Erkundung vor Ort',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'C-Unit + ggf. Engine',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 7,
      style: empty,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: 'Gesamt',
      colSpan: 7,
      style: fix,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: 'UNTERSTÜTZUNG - PD',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Anforderung durch PD',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'je nach Lage',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'TECH 2 - VU',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Verkehrsunfall Person Schaden',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Engine + (Heavy)Squad + MEDIC + DOC + D-Unit + C-Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'HILFELEISTUNG - PERS. SUCHE',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Personen Suche',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Engine + Heavy Squad + Squad + MEDIC + DOC + A | B | C | D -Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'HILFELEISTUNG - PERS. WASSER',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Wasserrettung',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value:
        'Engine + Heavy Squad + Squad  + MEDIC + DOC + LG + A | B | C | D -Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'TECH 2 - GEFAHRGUT',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'First Responder Einsatz, da kein freies Rettungsmittel',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value:
        '2x Engine + (Heavy Squad) + Heavy Rescue + MEDIC + DOC + A | B | C | D -Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'HILFELEIST. - PERS. RETTUNG',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Person in Gefahr (Höhenrettung / Tiefenrettung)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value:
        'Engine + (Heavy) Squad + (Ladder) + Utility MEDIC + DOC + A | B | C | D -Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: 'BOMB ALERT',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Bombendrohung / Sprengstofffund',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '85vh',
    },
    {
      value: '2x Engine + Heavy Rescue + MEDIC + DOC + A | B | C | D -Unit',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '80vh',
    },
    {
      value: 'JA',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '15vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh',
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '85vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '15vh',
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '80vh',
    },
  ],
  [
    {
      value: 'Aktualisiert',
      colSpan: 2,
      style: fix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Legende',
      colSpan: 3,
      style: fix,
      readOnly: true,
      width: '95vh',
    },
  ],
  [
    {
      value: '12.01.2022',
      colSpan: 2,
      style: fix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '85vh',
    },
    {
      value: 'Menge der Einheiten werden je nach Notruf alarmiert',
      colSpan: 3,
      style: usedFix,
      readOnly: true,
      width: '95vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '115vh',
    },
    {
      value: 'Bei Fahrzeug-Neuzugängen wird die AAO stätig Aktualisiert',
      colSpan: 7,
      style: usedFix,
      readOnly: true,
      width: '95vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 7,
      style: empty,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'Fahrzeug- / Unitserklärung',
      colSpan: 3,
      style: fix,
      readOnly: true,
      width: '70vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'A-Unit',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Einsatzleitung Gesamt',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'B-Unit',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Einsatzleitung Medical Department',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'C-Unit',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Einsatzleitung Fire Department Chief / Batt. Chief',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'D-Unit',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Einsatzleitung Fire Department Lt. / Cpt.',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'Heavy Squad',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'FD Rüstwagen (Hydraulische Rettungsgeräte, etc.)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'Squad',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'FD Rüstwagen (Hydraulische Rettungsgeräte, etc.)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '40vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 4,
      style: empty,
      readOnly: true,
      width: '140vh',
    },
    {
      value: 'Heavy Rescue',
      colSpan: 1,
      style: usedFix,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'FD Gerätewagen Logistik (großer Rüstwagen mit viel Material)',
      colSpan: 2,
      style: usedFix,
      readOnly: true,
      width: '40vh',
    },
  ],
];
