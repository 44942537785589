import * as type from '../redux/types';

export function updateVisiblity(isVisible: boolean) {
  return {
    type: type.VISIBLITY_SET,
    isVisible,
  };
}

export function setIsIngame(isIngame: boolean) {
  return {
    type: type.IS_INGAME,
    isIngame,
  };
}

export function checkLogin() {
  return {
    type: type.CHECK_LOGIN,
  };
}
