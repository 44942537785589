import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../utils/fetch';
import { notifyError, notifySuccess } from '../utils';

export const onFetchLspdMapVehicles = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_MAP_VEHICLES_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/map/lspd/vehicles')
        .then((data) => [
          {
            type: types.LSPD_MAP_VEHICLES_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdMapCalls = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_MAP_CALLS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/map/lspd/emergency')
        .then((data) => [
          {
            type: types.LSPD_MAP_CALLS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdAnkleMonitorCalls = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_MAP_ANKLEMONITOR_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/map/lspd/anklemonitor')
        .then((data) => [
          {
            type: types.LSPD_MAP_ANKLEMONITOR_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdPersonSearch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_PERSON_SEARCH_FETCH_START),
    mapPromiseC(({ search }: any) => {
      return authFetchJson(`/lspd/person/search/${search}`)
        .then((data) => [
          {
            type: types.LSPD_PERSON_SEARCH_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdPersonDetails = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_PERSON_DETAILS_FETCH_START),
    mapPromiseC(({ name }: any) => {
      return authFetchJson(`/lspd/person/detail/${name}`)
        .then((data) => [
          {
            type: types.LSPD_PERSON_DETAILS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onSaveLspdPersonNotes = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_PERSON_NOTES_SAVE_FETCH_START),
    mapPromiseC(({ name, notes }: any) => {
      return authFetchPost('/lspd/person/notes', { name, notes })
        .then(() => notifySuccess('Notizen gespeichert'))
        .catch(() => notifyError('Notizen konnten nicht gespeichert werden'));
    })
  );

export const onDeleteLspdPersonTicket = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_PERSON_TICKET_DELETE_FETCH_START),
    mapPromiseC(({ _id, name }: any) => {
      return authFetch(`/lspd/tickets/delete/${_id}`)
        .then(() => ({ type: types.LSPD_PERSON_DETAILS_FETCH_START, name }))
        .catch(() => notifyError('Ticket konnte nicht gelöscht werden'));
    })
  );

export const onSetLspdPersonWanted = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_PERSON_WANTED_SET_FETCH_START),
    mapPromiseC(({ name, state, reason }: any) => {
      return authFetchPost('/lspd/person/wanted', {
        name,
        state: state === 'NONE' ? null : state,
        reason,
      })
        .then(() => ({ type: types.LSPD_PERSON_DETAILS_FETCH_START, name }))
        .catch(() => notifyError('Wanted Status konnte nicht geändert werden'));
    })
  );

export const onFetchLspdVehicleSearch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_VEHICLE_FETCH_START),
    mapPromiseC(({ search }: any) => {
      return authFetchJson(`/lspd/vehicle/search/${search}`)
        .then((data) => [
          {
            type: types.LSPD_VEHICLE_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdWeaponSearch = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_WEAPON_FETCH_START),
    mapPromiseC(({ search }: any) => {
      return authFetchJson(`/lspd/weapon/${search}`)
        .then((data) => [
          {
            type: types.LSPD_WEAPON_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onChangeLspdWeaponTag = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_WEAPON_TAG_CHANGE_START),
    mapPromiseC(({ weaponId, tag }: any) => {
      return authFetchPost(`/lspd/weapon/${weaponId}/state`, { state: tag })
        .then((data) => ({
          type: types.LSPD_WEAPON_FETCH_START,
          search: weaponId,
        }))
        .catch(() => notifyError('Fehler beim Ändern des Tags'));
    })
  );

export const onFetchLspdWanted = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_WANTED_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/lspd/person/wanted')
        .then((data) => [
          {
            type: types.LSPD_WANTED_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdLogBook = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_LOGBOOK_FETCH_START),
    mapPromiseC(({ skip }: any) => {
      return authFetchJson(`/lspd/logs?skip=${skip}`)
        .then((data) => [
          {
            type: types.LSPD_LOGBOOK_FETCH_SUCCESS,
            data,
            skip,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdOldFiles = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_OLDFILES_FETCH_START),
    mapPromiseC(({ search, skip }: any) => {
      return authFetchJson(`/lspd/old-files/list?search=${search}&skip=${skip}`)
        .then((data) => [
          {
            type: types.LSPD_OLDFILES_FETCH_SUCCESS,
            data,
            skip,
          },
        ])
        .catch(() => {});
    })
  );

export const onFetchLspdOldFile = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LSPD_OLDFILE_FETCH_START),
    mapPromiseC(({ _id }: any) => {
      return authFetchJson(`/lspd/old-files/folder/${_id}`)
        .then((data) => [
          {
            type: types.LSPD_OLDFILE_FETCH_SUCCESS,
            data,
          },
        ])
        .catch(() => {});
    })
  );
