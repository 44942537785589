import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { find } from 'ramda';

export const rmImagesFromContent = (content: EditorState) => {
  const raw = convertToRaw(content.getCurrentContent());

  const entityMapKeys = Object.keys(raw.entityMap);
  const entityKeysToDelete: string[] = [];
  entityMapKeys.forEach((key) => {
    const entry = raw.entityMap[key];
    if (
      entry.type === 'IMAGE' &&
      entry.data &&
      !entry.data.type.startsWith('stamp')
    ) {
      entityKeysToDelete.push(key);
    }
  });

  // delete blocks that are images
  raw.blocks = raw.blocks.filter((block) => {
    const entityRanges = block.entityRanges || [];
    const isDeleteEntityInRange = find(
      (x: any) => entityKeysToDelete.indexOf(x.key.toString()) > -1,
      entityRanges
    );
    return isDeleteEntityInRange ? false : true;
  });

  // delete in entityMap;
  entityKeysToDelete.forEach((key) => {
    delete raw.entityMap[key];
  });

  return EditorState.set(content, {
    currentContent: convertFromRaw(raw),
  });
};
