export const recommendedVehicleFilters = [
  'Albany',
  'Annis',
  'Benefactor',
  'BF',
  'Bollokan',
  'Bravado',
  'Brute',
  'Bicycle',
  'Canis',
  'Chariot',
  'Cheval',
  'Coil',
  'Declasse',
  'Dewbauchee',
  'Dinka',
  'Dundreary',
  'Emperor',
  'Enus',
  'Fathom',
  'Grotti',
  'Hijak',
  'HVY',
  'Imponte',
  'Invetero',
  'Jobuilt',
  'Karin',
  'Lampadati',
  'Liberty City Cycles',
  'Maibatsu',
  'Mammoth',
  'MTL',
  'Nagasaki',
  'Obey',
  'Ocelot',
  'Pegassi',
  'Pfister',
  'Principe',
  'Progen',
  'Schyster',
  'Shitzu',
  'Speedophile',
  'Stanley',
  'Truffade',
  'Ubermacht',
  'Vapid',
  'Vulcar',
  'Weeny',
  //'Western Company', (Helikopter)
  'Western Motorcycle',
  'Zirconium',
];
