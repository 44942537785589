import { values } from 'ramda';

import * as login from './login';
import * as notify from './notify';
import * as router from './router';
import * as myVehiclesApp from './apps/myVehicles';
import * as testsuitesApp from './apps/testsuites';
import * as financeApp from './apps/finance';
import * as companyApp from './apps/company';
import * as fractionApp from './apps/fraction';
import * as mailApp from './apps/mail';
import * as adminApp from './apps/admin';
import * as settings from './settings';
import * as settingsApp from './apps/settings';
import leogramEffects from '../leogram/effects';
import * as documents from './apps/documents';
import * as folderGroups from './apps/folder-groups';
import * as folders from './apps/folders';
import * as desktop from './desktop';
import * as lsmd from './apps/lsmd';
import * as lspd from './apps/lspd';
import * as photo from './photo';
import * as browser from './apps/browser';

export default [
  ...values(login),
  ...values(notify),
  ...values(router),
  ...values(myVehiclesApp),
  ...values(testsuitesApp),
  ...values(financeApp),
  ...values(companyApp),
  ...values(fractionApp),
  ...values(mailApp),
  ...values(adminApp),
  ...values(settings),
  ...values(settingsApp),
  ...leogramEffects,
  ...values(documents),
  ...values(folderGroups),
  ...values(folders),
  ...values(desktop),
  ...values(lsmd),
  ...values(lspd),
  ...values(photo),
  ...values(browser),
];
