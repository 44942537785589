const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fixFett = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const usedFull = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const usedFullDoctor = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  color: '#d50000',
  'font-weight': 'bold',
  'justify-content': 'center',
  border: '0 !important',
};
const usedFix = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
export default [
  [
    {
      value: 'FAHRZEUGVERWAHRUNG',
      colSpan: 19,
      style: fixFett,
      readOnly: true,
      width: '190vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 19,
      style: empty,
      readOnly: true,
      width: '190vh',
    },
  ],
  [
    {
      value: 'Datum',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Officer:',
      colSpan: 3,
      style: fixFett,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Name des Eigentümers:',
      colSpan: 3,
      style: fixFett,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Modell & Farbe:',
      colSpan: 3,
      style: fixFett,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Kennzeichen:',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Grund der Verwahrung:',
      colSpan: 3,
      style: fixFett,
      readOnly: true,
      width: '30vh',
    },
    {
      value: 'Ort der Verwahrung:',
      colSpan: 2,
      style: fixFett,
      readOnly: true,
      width: '20vh',
    },
    {
      value: 'Parkkralle?',
      colSpan: 1,
      style: fixFett,
      readOnly: true,
      width: '10vh',
    },
  ],
];

export const vehicleInpoundLineTemplate = [
  {
    value: '',
    colSpan: 2,
    style: usedFull,
    width: '20vh',
  },
  {
    value: '',
    style: usedFull,
    colSpan: 3,
    readOnly: true,
    width: '30vh',
    type: 'select',
    depOptions: {
      sheetId: 'lspdcontroldata',
      column: 'E',
      rowStart: 1,
      rowEnd: 110,
    },
  },
  {
    value: '',
    colSpan: 3,
    style: usedFull,
    width: '30vh',
  },
  {
    value: '',
    colSpan: 3,
    style: usedFull,
    width: '30vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedFull,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 3,
    style: usedFull,
    width: '30vh',
  },
  {
    value: '',
    colSpan: 2,
    style: usedFull,
    width: '20vh',
  },
  {
    value: '',
    colSpan: 1,
    style: usedFull,
    width: '10vh',
  },
];
