import { css } from '@emotion/react';

export const detailContainerStyle = css`
  margin-top: 1vh;
  padding: 1vh 0;

  h2 {
    font-weight: bold;
    font-size: 2vh;
    margin-bottom: 1vh;
  }

  button {
    width: 100%;
    margin: 0.3vh 0;
  }
`;
