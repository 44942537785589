export const TOGGLE_DARK = 'TOGGLE_DARK';
export const SET_VOLUME = 'SET_VOLUME';
export const SET_BRIGHTNESS = 'SET_BRIGHTNESS';
export const TOGGLE_WIFI = 'TOGGLE_WIFI';
export const TOGGLE_BLUETOOTH = 'TOGGLE_BLUETOOTH';
export const TOGGLE_AIRDROP = 'TOGGLE_AIRDROP';
export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN';

export const VISIBLITY_SET = 'VISIBLITY_SET';
export const IS_INGAME = 'IS_INGAME';

export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';

export const CHECK_LOGIN = 'CHECK_LOGIN';
