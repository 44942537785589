import React, { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import {
  appsAdminBankAccountDetailP,
  appsAdminBankAccountTransactionsP,
} from '../../../redux/reducer/apps/admin/bankaccount';
import {
  editAdminBankAccountBalance,
  makeAdminBankAccountMain,
  startAdminBankAccountDetailFetch,
  startAdminBankAccountTransactionsFetch,
} from '../../../actions';
import {
  BankAccountDetailType,
  BankAccountTransactionData,
  BanAccountTransactionExecuter,
} from './types/bankaccount';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Clear from '@mui/icons-material/Clear';
import { DateTime } from 'luxon';
import { Button, TextField } from '@mui/material';
import { css } from '@emotion/react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Red } from '../../../configs/styles/colors';
import { translate } from '../../../utils/translate';

const negativeStyle = css`
  color: ${Red} !important;
`;

const printExecuter = (
  data: BanAccountTransactionExecuter,
  openPlayer: (_id: string, playerName: string) => void,
  openFactory: (_id: string, label: string) => void,
  openBankAccount: (_id: string, label: string) => void
) => {
  const player = data.ownerCharacter?.name ?? '';
  const factory = data.ownerFactory?.label ?? '';
  return (
    <>
      <span
        onClick={() => openBankAccount(data._id, data.vban)}
        css={{ cursor: 'pointer' }}
      >
        {data.vban}
      </span>
      {player && (
        <span
          onClick={() =>
            data.ownerCharacter && openPlayer(data.ownerCharacter._id, player)
          }
          css={{ cursor: 'pointer' }}
        >
          ({player})
        </span>
      )}
      {factory && (
        <span
          onClick={() =>
            data.ownerFactory && openFactory(data.ownerFactory._id, factory)
          }
          css={{ cursor: 'pointer' }}
        >
          ({factory})
        </span>
      )}
    </>
  );
};

type BankAccountViewType = {
  _id: string;
  openPlayer: (_id: string, playerName: string) => void;
  openFactory: (_id: string, label: string) => void;
  openBankAccount: (_id: string, label: string) => void;
};
export const BankAccountView = ({
  _id,
  openPlayer,
  openFactory,
  openBankAccount,
}: BankAccountViewType) => {
  const [isBalanceEditing, setBalanceEditing] = useState(false);
  const [balance, setBalance] = useState(0);
  const dispatch = useDispatch();
  const bankAccount = useSelector<any, null | BankAccountDetailType>(
    pathOr(null, appsAdminBankAccountDetailP)
  );
  const transactions = useSelector<any, BankAccountTransactionData>(
    pathOr({ isComplete: false, data: [] }, appsAdminBankAccountTransactionsP)
  );

  const onLoadTransactions = (offset: number) => {
    dispatch(startAdminBankAccountTransactionsFetch(_id, offset));
  };

  useEffect(() => {
    dispatch(startAdminBankAccountDetailFetch(_id));
    dispatch(startAdminBankAccountTransactionsFetch(_id, 0));
  }, [_id]);

  useEffect(() => {
    setBalance(bankAccount?.money ?? 0);
    setBalanceEditing(false);
  }, [bankAccount]);

  if (!bankAccount) {
    return null;
  }

  const changeBalance = () => {
    // @ts-ignore
    dispatch(editAdminBankAccountBalance(_id, parseFloat(balance)));
  };

  const toggleMain = () => {
    dispatch(makeAdminBankAccountMain(_id, !bankAccount.main));
  };

  return (
    <div>
      <h1>Bank Konto: {bankAccount.vban}</h1>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Bank</TableCell>
            <TableCell>{bankAccount.bank?.name}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Kontotyp</TableCell>
            <TableCell>{bankAccount.bankAccountType?.name}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ban</TableCell>
            <TableCell>{bankAccount.vban}</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hauptkonto</TableCell>
            <TableCell>
              {bankAccount.main ? <CheckCircle /> : <Clear />}
            </TableCell>
            <TableCell>
              <Button variant="outlined" onClick={toggleMain}>
                {bankAccount.main ? 'Entfernen' : 'Hinzufügen'}
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Guthaben</TableCell>
            <TableCell>
              {isBalanceEditing ? (
                <TextField
                  value={balance}
                  onChange={(ev: any) => setBalance(ev.target.value)}
                  variant="outlined"
                  type="number"
                />
              ) : (
                bankAccount.money.toFixed(2)
              )}
            </TableCell>
            <TableCell>
              {isBalanceEditing ? (
                <Button variant="outlined" onClick={changeBalance}>
                  Anwenden
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setBalanceEditing(true)}
                >
                  Ändern
                </Button>
              )}
            </TableCell>
          </TableRow>
          {bankAccount.ownerCharacter && (
            <TableRow>
              <TableCell>Besitzer</TableCell>
              <TableCell
                onClick={() =>
                  bankAccount.ownerCharacter &&
                  openPlayer(
                    bankAccount.ownerCharacter._id,
                    bankAccount.ownerCharacter.name
                  )
                }
                css={{ cursor: 'pointer' }}
              >
                {bankAccount.ownerCharacter.name}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          {bankAccount.ownerFactory && (
            <TableRow>
              <TableCell>Firmenkonto für</TableCell>
              <TableCell
                onClick={() =>
                  bankAccount.ownerFactory &&
                  openFactory(
                    bankAccount.ownerFactory._id,
                    bankAccount.ownerFactory.label
                  )
                }
                css={{ cursor: 'pointer' }}
              >
                {bankAccount.ownerFactory.label}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <h2>Transaktionen</h2>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>von</TableCell>
            <TableCell>zu</TableCell>
            <TableCell>Verwendungszweck</TableCell>
            <TableCell>Betrag</TableCell>
            <TableCell>Steuern</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.data.map((entry) => {
            const isFromMyAccount =
              entry.sourceAccount?.vban === bankAccount.vban;
            return (
              <TableRow key={entry._id}>
                <TableCell>
                  {DateTime.fromJSDate(new Date(entry.created))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </TableCell>
                <TableCell>
                  {entry.sourceAccount &&
                    printExecuter(
                      entry.sourceAccount,
                      openPlayer,
                      openFactory,
                      openBankAccount
                    )}
                </TableCell>
                <TableCell>
                  {entry.destinationAccount &&
                    printExecuter(
                      entry.destinationAccount,
                      openPlayer,
                      openFactory,
                      openBankAccount
                    )}
                </TableCell>
                <TableCell>
                  {translate(entry.reference)} {entry.payload || ''}
                </TableCell>
                <TableCell css={isFromMyAccount && negativeStyle}>
                  $ {isFromMyAccount && '-'}
                  {entry.amount.toFixed(2)}
                </TableCell>
                <TableCell css={isFromMyAccount && negativeStyle}>
                  $ {isFromMyAccount && '-'}
                  {entry.taxAmount.toFixed(2)}
                </TableCell>
              </TableRow>
            );
          })}
          {!transactions.isComplete && (
            <TableRow>
              <TableCell colSpan={6}>
                <Button
                  variant="outlined"
                  onClick={() => onLoadTransactions(transactions.data.length)}
                >
                  Weitere Laden
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
