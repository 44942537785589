export default [
  {
    type: 'h1',
    text: 'Light Apartment - Maritimes Apartment',
    customCss: '',
  },
  {
    type: 'section',
    text: 'Dieses maritime Apartment lässt Urlaubsgefühle wachsen, richten Sie sich Ihr Apartment so ein, dass dieses tolle Gefühl nie ganz verschwindet.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/beachart/Eingangsbereich.jpg',
      'img/interior/beachart/Eingangsbereich1.jpg',
      'img/interior/beachart/Flur.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Vom Eingangsbereich gelangt man in die offene Küche mit anschließendem Wohn- und Essbereich. Die offene Küche lässt Sie spielend leicht an Gesprächen während Sie kochen teilhaben.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/beachart/Kueche.jpg',
      'img/interior/beachart/Kueche1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Der Wohn- und Essbereich verfügt über einen Kamin, an dem sich lauschige Abende zu zweit verbringen lassen.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/beachart/Wohnbereich.jpg',
      'img/interior/beachart/Wohnbereich1.jpg',
      'img/interior/beachart/Wohnbereich2.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Geruhsame Stunden finden Sie in diesem kuscheligen Schlafzimmer. Träumen Sie von Sonne, Strand und Meer.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/beachart/Schlafzimmer.jpg',
      'img/interior/beachart/Schlafzimmer1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Richten Sie sich in diesem tollen Raum mit maritimer Tapete ein Arbeits- oder Gästezimmer ein. Ihrer Fantasie sind keine Grenzen gesetzt.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/beachart/Arbeitszimmer.jpg',
      'img/interior/beachart/Arbeitszimmer1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Das gemütliche Badezimmer mit kleinen Dekoakzenten verfügt über eine Badewanne. Verbringen Sie hier ein entspannendes Schaumbad nach einem anstrengenden Arbeitstag.',
    customCss: '',
  },
  {
    type: 'image',
    url: [
      'img/interior/beachart/Badezimmer.jpg',
      'img/interior/beachart/Badezimmer1.jpg',
    ],
    customCss: '',
  },
  {
    type: 'section',
    text: 'Damit sind wir am Ende der Besichtung. Dieses maritime Apartment überzeugt mit seinem eigenem Charme, der zum Träumen einlädt. Gestalten Sie sich hier Ihre Wohlfühloase und lassen Sie Ihrer Kreativität freien Lauf.',
    customCss: '',
  },
];
