import * as type from '../redux/types';

export function startCompaniesFetch() {
  return {
    type: type.COMPANIES_FETCH_START,
  };
}

export function startCompanyChangeLabel(id: string, name: string) {
  return {
    type: type.COMPANY_LABEL_CHANGE_START,
    name,
    id,
  };
}

export function startCompanyChangeAdd(id: string, adLine: string) {
  return {
    type: type.COMPANY_ADD_CHANGE_START,
    adLine,
    id,
  };
}

export function startCompanyStorageFetch(
  id: string,
  page: number,
  search: string
) {
  return {
    type: type.COMPANY_STORAGE_FETCH_START,
    id,
    page,
    search,
  };
}

export function startCompanyStoragePrice(id: string, items: any[]) {
  return {
    type: type.COMPANY_STORAGE_PRICE_SET_START,
    id,
    items,
  };
}

export function startCompanyCounterFetch(
  id: string,
  page: number,
  search: string
) {
  return {
    type: type.COMPANY_COUNTER_FETCH_START,
    id,
    page,
    search,
  };
}

export function startCompanyCounterPrice(id: string, items: any[]) {
  return {
    type: type.COMPANY_COUNTER_PRICE_SET_START,
    id,
    items,
  };
}

export function startCompanyEmployeeEdit(
  id: string,
  employeeId: string,
  newRights: any
) {
  return {
    type: type.COMPANY_EMPLOYEE_EDIT_START,
    id,
    employeeId,
    newRights,
  };
}

export function startCompanyEmployeeSalary(
  id: string,
  employeeData: { _id: string; money: number }[]
) {
  return {
    type: type.COMPANY_EMPLOYEE_SALARY_START,
    id,
    employeeData,
  };
}

export function startCompanyEmployeeFire(id: string, name: string) {
  return {
    type: type.COMPANY_EMPLOYEE_FIRE_START,
    id,
    name,
  };
}

export function startCompanyEmployeeInvite(id: string, name: string) {
  return {
    type: type.COMPANY_EMPLOYEE_INVITE_START,
    id,
    name,
  };
}

export function startCompanyCheckInsFetch(id: string) {
  return {
    type: type.COMPANY_EMPLOYEE_CHECKIN_FETCH_START,
    id,
  };
}

export function startCompanyExportsFetch(id: string) {
  return {
    type: type.COMPANY_EXPORTS_FETCH_START,
    id,
  };
}

export function startCompanyProductionFetch(id: string) {
  return {
    type: type.COMPANY_PRODUCTION_FETCH_START,
    id,
  };
}

export function startCompaniesByOwnerSimpleFetch() {
  return {
    type: type.COMANIES_BY_OWNER_SIMPLE_FETCH_START,
  };
}
