// https://github.com/atomrc/callbag-debounce/blob/master/src/debounce.ts

export function debounceWithCollectC(wait: number) {
  return (source: any) => (start: number, sink: any) => {
    if (start !== 0) return;
    let timeout: any;
    let args: any[] = [];
    source(0, (t: number, d: any) => {
      if (t === 1 || (t === 2 && d === undefined)) {
        // t === 1 means the source is emitting a value
        // t === 2 and d === undefined means the source emits a completion
        if (!timeout && t === 2) {
          return sink(t, d);
        }
        if (timeout) {
          clearTimeout(timeout);
        }
        args.push(d);
        timeout = setTimeout(() => {
          sink(t, args);
          args = [];
          timeout = undefined;
        }, wait);
      } else sink(t, d);
      /*
       * no need to handle the t === 0 case because
       * the operator never needs to talkback to the source
       *
       * nothing specific to do when the source
       * sends a t === 2 d !== undefined signal
       */
    });
  };
}
