const empty = {
  background: 'rgba(13,13,13,var(--tw-bg-opacity))',
  color: '#fff',
  border: '0 !important',
};
const fix = {
  background: 'rgba(170,0,0,var(--tw-bg-opacity))',
  color: '#fff',
  'font-weight': 'bold',
  border: '0 !important',
  'justify-content': 'center',
};
const funkStyle = {
  background: '#303947',
  color: '#fff',
  'justify-content': 'center',
  border: '0 !important',
};
const usedLeft = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'text-align': 'left',
  color: '#fff',
  border: '0 !important',
};
const usedCenter = {
  background: 'rgba(26,26,26,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
const select = {
  background: 'rgba(51,51,51,var(--tw-bg-opacity))',
  'justify-content': 'center',
  color: '#fff',
  border: '0 !important',
  classStyling: [
    {
      class: 'MuiAutocomplete-input',
      style: {
        color: '#fff !important',
        'text-align': 'center',
      },
    },
  ],
};
//Styles ab const einfügen für sep. - kann auch ohne Eigenschaft wie background genutzt werden
export default [
  //Dispatchmenü
  [
    {
      value: 'INFOBOARD',
      colSpan: 13,
      style: fix,
      readOnly: true,
      width: '210vh',
    },
  ],
  [
    {
      value: '',
      colSpan: 13,
      style: empty,
      readOnly: true,
      width: '210vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Ten-Codes',
      colSpan: 3,
      style: fix,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Miranda Warning',
      style: fix,
      colSpan: 5, //hier 5 Spalten breit die verbunden sind
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'NATO Alphabet',
      colSpan: 3,
      style: fix,
      readOnly: true,
      width: '60vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-2',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Feierabend',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Sie haben das Recht zu schweigen.',
      colSpan: 5,
      style: usedCenter,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'A',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Alpha',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-4',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Verstanden',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value:
        'Alles, was Sie sagen, kann und wird vor Gericht gegen Sie verwendet.',
      colSpan: 5,
      style: usedCenter,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'B',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Bravo',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-5',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Am Einsatzort',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Sie haben das Recht auf einen Anwalt.',
      colSpan: 5,
      style: usedCenter,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'C',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Charlie',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-6',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Beschäftigt',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value:
        'Wenn Sie sich keinen leisten können, wird Ihnen einer vom Staat gestellt.',
      colSpan: 5,
      style: usedCenter,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'D',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Delta',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-7',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'On Duty',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value:
        'Sollte kein Anwalt verfügbar sein, müssen Sie sich selber verteidigen.',
      colSpan: 5,
      style: usedCenter,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'E',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Echo',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-8',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Datenabfrage',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Haben Sie Ihre Rechte verstanden?',
      colSpan: 5,
      style: usedCenter,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'F',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Foxtrot',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-9',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'RTS',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 5,
      style: empty,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'G',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Golf',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-10',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Verstärkung',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Wichtige Telefonnummern',
      colSpan: 5,
      style: fix,
      readOnly: true,
      width: '80vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'H',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Hotel',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-11',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Need Overwatch',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa16',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'I',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'India',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-20',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Standort, Status',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa17',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'J',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Juliett',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-25',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Einsatzanfahrt',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa18',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'K',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Kilo',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-30',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Tresorraub',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa19',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'L',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Lima',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-33',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Fahrzeugdiebstahl',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa20',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'M',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Mike',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-40',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Kraftwerk-Hack',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa21',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'N',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'November',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-50',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'VU Zivilfahrzeug',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa22',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'O',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Oscar',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-51',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'VU mit Dienstwagen',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa23',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'P',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Papa',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-80',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Verfolgungsjagd',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa24',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Q',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Quebec',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-85',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Gefangenentransport',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa25',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'R',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Romeo',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '10-99',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Officer in Not',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa26',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'S',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Sierra',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa27',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'T',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Tango',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: '► Dispatch Codes ◄',
      colSpan: 3,
      style: fix,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa28',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'U',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Uniform',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 1',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Einsatzanfahrt',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa29',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'V',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Victor',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 2',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Einsatzanfahrt Prio',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '=lspdlead$Aa30',
      style: usedCenter,
      readOnly: true,
      colSpan: 5,
      width: '50vh',
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'W',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Whiskey',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 2 High',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Einsatz - Notlage',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'X',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'X-Ray',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 3',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Officer in Lebensgefahr',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Y',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Yankee',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 4',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Einsatzende',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Z',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Zulu',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 5',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Mögliche Falle',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: false,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 6',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Verlasse Fahrzeug',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 7',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Pause',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'Code 8',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Kopfschmerzen',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'PD',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Police Department',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'MD',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Medical Department',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'SP',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'State Prison',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'NV',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Nicht verfügbar',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'UC',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Undercover',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
  [
    {
      value: 'HCA',
      colSpan: 1,
      style: usedCenter,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: 'Highcommand Aufgaben',
      colSpan: 2,
      style: usedCenter,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 3,
      style: empty,
      readOnly: true,
      width: '40vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '30vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 1,
      style: empty,
      readOnly: true,
      width: '10vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
    {
      value: '',
      colSpan: 2,
      style: empty,
      readOnly: true,
      width: '20vh', //Breite der Zelle / Standard 10 - hier doppelt also 20
    },
  ],
];
