import { startRouterGoto } from '../actions';
import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC, mapC } from '../utils/callbag';
import { authFetch } from '../utils/fetch';

export const onLoginStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_LOGIN_START),
    mapPromiseC(({ userTag, password }: any) => {
      return authFetch('/leogram/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: userTag,
          password,
        }),
      })
        .then((res) => {
          return res.json().then(({ token }) => [
            {
              type: types.LEOGRAM_LOGIN_SUCCESS,
              token,
              userTag,
            },
            startRouterGoto('/'),
          ]);
        })
        .catch((res) => {
          return res.json().then((data: any) => [
            {
              type: types.LEOGRAM_LOGIN_FAILED,
            },
            {
              type: 'NOTIFY_ERROR',
              msg: data.message,
            },
          ]);
        });
    })
  );

export const onLogout = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_LOGOUT),
    mapC(() => {
      return startRouterGoto('/login');
    })
  );
