export const DOCUMENTS_FETCH_START = 'DOCUMENTS_FETCH_START';
export const DOCUMENTS_FETCH_SUCCESS = 'DOCUMENTS_FETCH_SUCCESS';

export const DOCUMENT_FETCH_START = 'DOCUMENT_FETCH_START';
export const DOCUMENT_FETCH_SUCCESS = 'DOCUMENT_FETCH_SUCCESS';

export const DOCUMENT_CREATE_START = 'DOCUMENT_CREATE_START';

export const DOCUMENT_SEARCH_SET = 'DOCUMENT_SEARCH_SET';

export const DOCUMENT_NEW_CREATE = 'DOCUMENT_NEW_CREATE';
export const DOCUMENT_DUPLICATE_CREATE = 'DOCUMENT_DUPLICATE_CREATE';

export const DOCUMENT_TAB_SELECT = 'DOCUMENT_TAB_SELECT';

export const DOCUMENT_RAW_REMOVE = 'DOCUMENT_RAW_REMOVE';

export const DOCUMENT_PRINT_START = 'DOCUMENT_PRINT_START';

export const DOCUMENT_DELETE_START = 'DOCUMENT_DELETE_START';

export const DOCUMENT_HISTORY_FETCH_START = 'DOCUMENT_HISTORY_FETCH_START';
export const DOCUMENT_HISTORY_FETCH_SUCCESS = 'DOCUMENT_HISTORY_FETCH_SUCCESS';
export const DOCUMENT_HISTORY_ENTRY_FETCH_START =
  'DOCUMENT_HISTORY_ENTRY_FETCH_START';
export const DOCUMENT_HISTORY_ENTRY_FETCH_SUCCESS =
  'DOCUMENT_HISTORY_ENTRY_FETCH_SUCCESS';

export const DOCUMENT_SCREENSHOT_START = 'DOCUMENT_SCREENSHOT_START';
