import React from 'react';

const html = `
<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 1 Arbeitsvertrag</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Grundlage eines Verhältnisses zwischen Arbeitnehmer und Arbeitgeber ist durch einen Arbeitsvertrag fundamentiert.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Ein Arbeitsvertrag ist ein schriftlicher Vertrag, welcher Willenserklärungen beiden Parteien   benötigt, um zustandezukommen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Der Arbeitsvertrag muss vom Arbeitgeber und Arbeitnehmer unterschrieben sein.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Inhaltlich umfasst der Arbeitsvertrag den Namen des Arbeitgeber und Arbeitnehmer sowie den Lohn für die Tätigkeit und die Tätigkeit selbst. Dabei darf die Tätigkeit keine Gesetze verletzen.<br></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">V. </span>Der unterschriebene Arbeitsvertrag muss sowohl dem Arbeitgeber als auch dem</span></p>
	<p><span style="font-size: 14pt;">Arbeitnehmer vorliegen und jederzeit vorzeigbar sein.</span></p>
	<p><span style="font-size: 14pt;">VI. Sollte kein schriftlicher Arbeitsvertrag vorliegen, ist weder der Arbeitgeber noch der</span></p>
	<p><span style="font-size: 14pt;">Arbeitnehmer im Rahmen des Arbeitsgesetzes rechtlich zu belangen.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 2 Pflichten und Rechte des Arbeitgebers</strong> </span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">I. </span>Der Arbeitgeber ist verpflichtet, jeden Arbeitnehmer als Mitarbeiter einzutragen und diese beim Department of Commerce zu melden.</span></p>
	<p><span style="font-size: 14pt;">II. Der Arbeitgeber ist verpflichtet, das Unternehmen aktiv zu betreiben.</span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Der Arbeitgeber ist dazu verpflichtet, den Arbeitnehmer finanziell für seine Arbeit zu entlohnen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Der Arbeitgeber ist dazu verpflichtet, einen ausreichenden Arbeitsschutz für die Arbeitnehmer zu gewährleisten.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Der Arbeitgeber ist dazu verpflichtet, den Arbeitnehmer ordnungsgemäß einzuarbeiten und diesen über seine Tätigkeiten vollumfänglich aufzuklären.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VI. Arbeitgeber dürfen keine Abhängigkeit zu anderen Unternehmen vorweisen. Ausnahmen bilden neutrale Unternehmen (Hangar, Recyclinghof).</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">VII. Die Wiedereinstellung eines entlassenen Arbeitnehmers ist erst nach 48h zulässig.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 3 Pflichten und Rechte des Arbeitnehmers</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Der Arbeitnehmer ist dazu verpflichtet, die Tätigkeit, welche im Arbeitsvertrag festgehalten ist, auszuführen sowie geforderte Leistungen zu erfüllen, sofern er dabei nicht gege</span><span style="font-size: 14pt;">n geltende Gesetze verstößt.</span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);">II. </span>Der Arbeitnehmer ist verpflichtet, dem Arbeitgeber jede weitere Beschäftigung zu</span></p>
	<p><span style="font-size: 14pt;">melden. Arbeitnehmer im öffentlichen Dienst benötigen die Genehmigung der Fraktionsleitung.</span></p>
	<p><span style="font-size: 14pt;">III. Arbeitnehmer im öffentlichen Dienst dürfen lediglich eine weitere Beschäftigung aufnehmen.</span></p>
	<p><span style="font-size: 14pt;">IV. Arbeitnehmer dürfen keine Abhängigkeit zu anderen Unternehmen vorweisen, in denen sie angestellt sind. Ausnahmen bilden neutrale Unternehmen (Hangar, Recyclinghof).</span></p>
	<p><span style="font-size: 14pt;">V. Informationen, die im Unternehmen erlangt wurden, sind nicht an Drittpersonen weiterzugeben (KG).</span></p>
	<p><span style="font-size: 14pt;">VI. Der Arbeitnehmer muss eine vom MD bescheinigte Arbeitsunfähigkeit umgehend beim Arbeitgeber melden.</span></p>
	<p><span style="font-size: 14pt;">VII. Arbeitnehmer im öffent</span><span style="font-size: 14pt;">lichen Dienst dürfen nur in einer Firma neben der Haupttätigkeit in der Staatsbehörde angestellt sein und keine Führungsposition in einem Unternehmen besetzen.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 4 Suspendierung</strong></span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Dem Arbeitgeber ist das Recht vorbehalten, den Arbeitnehmer von der Arbeit zu suspendieren, wenn gegen diesen im Strafverfahren ermittelt wird oder wenn dieser gegen Vertragspflichten verstößt. </span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Die Freistellung hat eine maximale Dauer von einer Woche. Gegebenenfalls kann diese auch verlängert werden, sofern verhältnismäßige Gründe vorliegen oder Ermittlungen gegen den Arbeitnehmer laufen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Eine Suspendierung über drei Wochen zieht automatisch eine Kündigung nach sich.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Während einer Suspendierung darf der Arbeitnehmer:</span></span></p>
	<ul><li><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">seine Arbeit nicht ausüben,</span></span></li>
		<li><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">kein Eigentum der Firma oder bereitgestellte Arbeitsmittel nutzen,</span></span></li>
		<li><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">seine Position in einem Unternehmen oder einer Staatsfraktion nicht missbrauchen (KG),</span></span></li>
		<li><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span>
		<p><span style="font-size: 14pt;">die Verschwiegenheit über die bei der Arbeit erlangten Informationen nicht brechen (KG).</span></p></li>
	</ul><p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><br></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;"><strong>§ 5 Kündigung</strong> </span></span></p>
	<p><span style="font-size: 14pt;"><span style="color:rgb(255,255,255);"></span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">I. Die Kündigung des Arbeitsverhältnisses ist vom Arbeitgeber oder Arbeitnehmer schriftlich einzureichen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">II. Eine wirksame Kündigung muss folgende vertraglich vereinbarte Konditionen beinhalten:</span></span></p>
	<ul><li><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">Einhaltung der Kündigungsfrist,</span></span></li>
		<li><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">Gründe für die Kündigung,</span></span></li>
		<li><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">Rückgabe sämtlicher bereitgestellter Arbeitsmittel, Schlüssel, Dienstmarken, Dienstwaffen.</span></span></li>
	</ul><p><span style="font-size: 14pt;"><span style="color:#ffffff;">Anderenfalls gilt die Kündigung als nichtig. </span>Falls Bedingungen zur Kündigung im Arbeitsvertrag festgehalten sind, müssen diese ebenfalls erfüllt werden.</span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">III. Die Kündigungsfrist bei einer Fraktion beträgt immer 2 Wochen.</span></span></p><p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">Nach dieser Frist, kommt bei Fraktionswechsel noch eine Frist von 2 Wochen hinzu. Der Arbeitsnehmer hat also gesamt 4 Wochen Sperre bei Fraktionswechsel.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">IV. Bei einer Kündigung muss der Arbeitgeber die ausstehenden Löhne auszahlen.</span></span></p>
	<p><span style="color:rgb(255,255,255);"><span style="font-size: 14pt;">V. Wenn eine Kündigung unwahre oder Gründe ohne Belangen enthält, haben beide Parteien die Möglichkeit, Rechtsmittel einzulegen und die Kündigung somit nichtig zu machen.</span></span></p>
`;

export const ArbeitsGb = () => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
