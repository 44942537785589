import React from 'react';
import { fetchUserTagExistence } from '../actions';
import { css } from '@emotion/react';
import { indexOf } from 'lodash';
import { pathOr } from 'ramda';
import { checkedUsersP, existingUsersP } from '../store/reducer/posts';
import { useDispatch, useSelector } from 'react-redux';

const textSpan = css`
  cursor: pointer;
  font-weight: normal !important;
`;

const RenderedText = ({ text: _text, goto }: any) => {
  const dispatch = useDispatch();
  const userTags = useSelector<any, string[]>(pathOr([], existingUsersP));
  const checkedTags = useSelector<any, string[]>(pathOr([], checkedUsersP));

  if (_text) {
    const text = _text.split('\n').join(' <br> ');

    const words = text.split(' ');
    words.forEach((word: any) => {
      const subWord = word.substring(1);
      if (
        word.startsWith('@') &&
        indexOf(userTags, subWord) < 0 &&
        checkedTags.indexOf(subWord) === -1
      ) {
        dispatch(fetchUserTagExistence(subWord));
      }
    });

    const renderedText = words.map((word: any, index: number) => {
      // do nothing for empty string
      // this could happen on \n\n
      if (word === '') {
        return '';
      }

      // line break
      if (word === '<br>') {
        return <br key={index} />;
      }

      // tagging other users
      const subWord = word.substring(1);
      if (word.startsWith('@') && indexOf(userTags, subWord) >= 0) {
        return (
          <span
            key={index}
            css={textSpan}
            onClick={() => goto(`/profilepage`, { userTag: subWord })}
          >
            {' '}
            {word}
          </span>
        );
      }

      // hashtag werbung
      if (word.toLowerCase() === '#werbung') {
        return (
          <span key={index} css={textSpan} onClick={() => goto('/ads')}>
            {' '}
            {word}
          </span>
        );
      }
      return ` ${word}`;
    });

    return renderedText;
  }
  return <div />;
};

export default RenderedText;
