import * as type from '../redux/types';

export function startFetchBanks() {
  return {
    type: type.FINANCE_BANKS_FETCH_START,
  };
}
export function startCreateBankAccount(
  bankHash: string,
  accountName: string,
  note: string,
  pin: string
) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_CREATE_START,
    bankHash,
    note,
    pin,
    accountName,
  };
}

export function startFetchBankAccounts() {
  return {
    type: type.FINANCE_BANK_ACCOUNTS_FETCH_START,
  };
}

export function startFetchBankAccountTransactions(
  vban: string,
  offset: number
) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_TRANSACTIONS_FETCH_START,
    vban,
    offset,
  };
}

export function startBankAccountPinChange(
  vban: string,
  currentPin: string,
  newPin: string,
  newPinRe: string
) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_PIN_CHANGE_START,
    vban,
    currentPin,
    newPin,
    newPinRe,
  };
}

export function startBankAccountMakeMain(vban: string, pin: string) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_MAIN_SET_START,
    vban,
    pin,
  };
}

export function startBankAccountDelete(vban: string, pin: string) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_DELETE_START,
    vban,
    pin,
  };
}

export function startBankAccountTransaction(
  sourceVban: string,
  destinationVban: string,
  amount: number,
  reference: string
) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_TRANSACTION_ADD_START,
    sourceVban,
    destinationVban,
    amount,
    reference,
  };
}

export function startBankAccountAccessFetch(vban: string) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_ACCESS_GET_START,
    vban,
  };
}

export function startBankAccountAccessAdd(vban: string, playerName: string) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_ACCESS_ADD_START,
    vban,
    playerName,
  };
}

export function startBankAccountAccessRemove(vban: string, _id: string) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_ACCESS_REMOVE_START,
    vban,
    _id,
  };
}

export function startBankAccountAccessEdit(
  vban: string,
  _id: string,
  accessRightType: string,
  newValue: boolean
) {
  return {
    type: type.FINANCE_BANK_ACCOUNT_ACCESS_EDIT_START,
    vban,
    _id,
    accessRightType,
    newValue,
  };
}
