export const FRACTION_OVERVIEW_DUTY_FETCH_START =
  'FRACTION_OVERVIEW_DUTY_FETCH_START';
export const FRACTION_OVERVIEW_DUTY_FETCH_SUCCESS =
  'FRACTION_OVERVIEW_DUTY_FETCH_SUCCESS';

export const LSMD_MAP_VEHICLES_FETCH_START = 'LSMD_MAP_VEHICLES_FETCH_START';
export const LSMD_MAP_VEHICLES_FETCH_SUCCESS =
  'LSMD_MAP_VEHICLES_FETCH_SUCCESS';

export const LSMD_MAP_CALLS_FETCH_START = 'LSMD_MAP_CALLS_FETCH_START';
export const LSMD_MAP_CALLS_FETCH_SUCCESS = 'LSMD_MAP_CALLS_FETCH_SUCCESS';

export const LSMD_OLDFILES_LIST_START = 'LSMD_OLDFILES_LIST_START';
export const LSMD_OLDFILES_LIST_SUCCESS = 'LSMD_OLDFILES_LIST_SUCCESS';
export const LSMD_OLDFILE_FETCH_START = 'LSMD_OLDFILE_FETCH_START';
export const LSMD_OLDFILE_FETCH_SUCCESS = 'LSMD_OLDFILE_FETCH_SUCCESS';
