import * as types from '../../redux/types';
import { pipeC, ofType, mapPromiseC } from '../../utils/callbag';
import { authFetch, authFetchJson, authFetchPost } from '../../utils/fetch';

export const onFetchBrowserCars = (action$: any) =>
  pipeC(
    action$,
    ofType(types.BROWSER_CARS_FETCH_START),
    mapPromiseC(() => {
      return authFetchJson('/available-vehicles/all')
        .then((data) => [
          {
            type: types.BROWSER_CARS_FETCH_SUCCESS,
            data,
          },
        ])
        .catch((res) => [
          {
            type: types.NOTIFY_ERROR,
            msg: 'Fehler beim Abfragen der Fahrzeuge',
          },
        ]);
    })
  );
