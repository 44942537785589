import { find, lensPath, pathOr, remove, set } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addDocumentToFolder,
  fetchFolder,
  getMyDocuments,
  saveFolder,
} from '../../../actions';
import { folderP } from '../../../redux/reducer/apps/folders';
import { css } from '@emotion/react';
import { FolderData } from './folder-types';
import { Select, MenuItem, Button, TextField } from '@mui/material';
import Editor from '../../../leogram/shared/Editor';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { MemberGroupData } from './group-types';
import { DocumentListEntryType, DocumentListType } from '../Documents/types';
import { documentsListP } from '../../../redux/reducer/apps/documents';
import DocumentView from './DocumentView';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/EditRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import CancelIcon from '@mui/icons-material/CancelRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/ClearRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import PersonIcon from '@mui/icons-material/PersonRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import NoteAddIcon from '@mui/icons-material/NoteAddRounded';
import FilePresentIcon from '@mui/icons-material/FilePresentRounded';
import DocumentIcon from '@mui/icons-material/DescriptionRounded';
import PriorityIcon from '@mui/icons-material/FiberManualRecordRounded';
import { DateTime } from 'luxon';
import { LexicalEditor } from '../../../leogram/shared/LexicalEditor';
import { useAuthentication } from '../../../utils/authentication';
import { getDocTypes } from '../Documents/doc-types';
import { toast } from 'react-toastify';

const PRIORITIES = [
  { label: 'Sehr Hoch', value: 'veryhigh' },
  { label: 'Hoch', value: 'high' },
  { label: 'Normal', value: 'normal' },
  { label: 'Gering', value: 'low' },
];

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

const subjectContainer = css`
  h1 {
    font-size: 2.5vh;
  }
  .MuiInput-input {
    font-size: 2.5vh !important;
  }
  display: flex;
  align-items: center;

  > button {
    padding: 0.5vh;
    border-radius: 50%;
    margin-left: 0.5vh;
  }
`;

const rowContainer = css`
  width: 100%;
  max-width: 35vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2vh 0;
`;

const subHeadlineStyle = css`
  font-size: 1.5vh;
  margin-top: 1vh;
`;

const dropdownContainer = css`
  > .MuiInputBase-root {
    min-width: 12vh !important;
  }
`;

const addReceiverButton = css`
  margin-top: 1vh !important;
`;

const marginContainer = css`
  margin: 0.75vh 0;
`;

const iconButtonStyle = css`
  border-radius: 50% !important;
  > svg {
    padding: 0.5vh;
    width: 4vh;
    height: 4vh;
  }
`;

export const editorStyle = css`
  background-color: #ffffff;
`;

const priorityIconStyle = (theme: any) => css`
  &.veryhigh {
    color: ${theme.colors.Red};
  }
  &.high {
    color: ${theme.colors.Orange};
  }
  &.normal {
    color: ${theme.colors.Yellow};
  }
  &.low {
    color: ${theme.colors.Green};
  }
`;

const topNavigationContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1vh solid white;
  margin-bottom: 1vh;
  padding-bottom: 1vh;
`;

const documentHeaderContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2vh;
  h2 {
    margin: 0;
  }
`;

const documentAddListStyle = css`
  display: flex;
  > button {
    margin-left: 1vh;
  }
`;

type FolderType = {
  groupId: string;
  folderId: string;
  goBackToList: () => void;
  groups: MemberGroupData[];
};
export const Folder = ({
  groupId,
  folderId,
  goBackToList,
  groups,
}: FolderType) => {
  const dispatch = useDispatch();
  const [hasRight] = useAuthentication();
  const storeFolder = useSelector<any, null | FolderData>(
    pathOr(null, [...folderP, folderId])
  );
  const [folder, setFolder] = useState<null | FolderData>();
  const [isAddDocOpen, setAddDocOpen] = useState(false);
  const documentAddList = useSelector<any, DocumentListType>(
    pathOr<any>({ list: [], page: 1, pages: 1 }, documentsListP)
  );
  const [documentSearch, setDocumentSearch] = useState('');
  const [openDocument, setOpenDocument] = useState('');
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const [isFolderEdited, setIsFolderEdited] = useState(false);

  useEffect(() => {
    // dont fetch newly created fodler with uuidv4 with length 36
    if (folderId && folderId.length !== 36) {
      dispatch(fetchFolder(groupId, folderId));
    }
    setOpenDocument('');
  }, [folderId]);

  const resetFolder = () => {
    if (storeFolder && storeFolder.notes) {
      setFolder({
        ...storeFolder,
        notes:
          storeFolder.notesVersion === 2
            ? storeFolder.notes
            : storeFolder?.notes
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(storeFolder.notes))
              )
            : EditorState.createEmpty(),
      });
    }
    setAddDocOpen(false);
    setIsFolderEdited(false);
  };

  useEffect(() => {
    // @ts-ignore
    resetFolder();
  }, [storeFolder]);

  if (!folder) {
    return null;
  }

  const onCancelClick = () => {
    resetFolder();
  };

  const updateField = (prop: string) => (value: any) => {
    setIsFolderEdited(true);
    setFolder({
      ...folder,
      [prop]: value,
    });
  };

  const updateTarget = (index: number) => (value: string) => {
    setIsFolderEdited(true);
    setFolder(set(lensPath(['targets', index]), value, folder));
  };

  const removeTarget = (index: number) => {
    setIsFolderEdited(true);
    setFolder({
      ...folder,
      targets: remove(index, 1, folder.targets),
    });
  };

  const save = () => {
    if (folder.subject) {
      setIsFolderEdited(false);
      dispatch(
        saveFolder({
          ...folder,
          notes:
            folder.notesVersion === 2
              ? folder.notes
              : JSON.stringify(convertToRaw(folder.notes.getCurrentContent())),
        })
      );
    }
  };

  const searchDocuments = () => {
    dispatch(getMyDocuments(1, documentSearch));
  };

  const openAddingDocuments = () => {
    searchDocuments();
    setAddDocOpen(true);
  };

  const addDocument = (doc: DocumentListEntryType) => {
    const availableDocTypes = getDocTypes(hasRight);
    const foundType = find(
      (x: any) => x.hash === doc.itemHash,
      availableDocTypes
    );
    if (!doc.itemHash || foundType) {
      setIsFolderEdited(true);
      setAddDocOpen(false);
      dispatch(addDocumentToFolder(groupId, folderId, doc._id));
    } else {
      toast.error('Sie haben keine Berechtigung für diesen Dokumententyp');
    }
  };

  const onDocumentSearchInput = (key: string) => {
    if (key === 'Enter') {
      searchDocuments();
    }
  };

  return (
    <div>
      <div css={topNavigationContainer}>
        <Button
          variant="outlined"
          onClick={goBackToList}
          startIcon={<ArrowBackIcon />}
          disabled={isFolderEdited}
        >
          Zurück
        </Button>
        <div>
          <IconButton
            css={iconButtonStyle}
            disabled={!isFolderEdited}
            onClick={onCancelClick}
          >
            <CancelIcon />
          </IconButton>
          <IconButton
            css={iconButtonStyle}
            onClick={save}
            disabled={!isFolderEdited || !folder.subject}
          >
            <SaveIcon />
          </IconButton>
        </div>
      </div>
      <div css={subjectContainer}>
        {isEditingSubject ? (
          <TextField
            css={inputStyle}
            label="Titel"
            value={folder.subject}
            onChange={(ev) => updateField('subject')(ev.target.value)}
          />
        ) : (
          <h1>{folder.subject || 'Titel hier eingeben'}</h1>
        )}
        <IconButton onClick={() => setIsEditingSubject(!isEditingSubject)}>
          <EditIcon />
        </IconButton>
      </div>
      <div css={marginContainer}>
        {folder.creationDate && (
          <div css={rowContainer}>
            <span>Erstelldatum</span>
            <span>
              {DateTime.fromJSDate(new Date(folder.creationDate))
                .setLocale('de')
                .toFormat('dd.LL.yy, HH:mm')}
            </span>
          </div>
        )}
        {folder.modifiedDate && (
          <div css={rowContainer}>
            <span>Änderungsdatum</span>
            <span>
              {DateTime.fromJSDate(new Date(folder.modifiedDate))
                .setLocale('de')
                .toFormat('dd.LL.yy, HH:mm')}
            </span>
          </div>
        )}
      </div>
      <div css={marginContainer}>
        <div css={[rowContainer, dropdownContainer]}>
          Gruppe
          <Select
            value={folder.group}
            label="Gruppe"
            onChange={(ev) => updateField('group')(ev.target.value)}
          >
            {groups.map((group) => (
              <MenuItem key={group._id} value={group._id}>
                {group.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div css={[rowContainer, dropdownContainer]}>
          Priorität
          <Select
            value={folder.priority}
            label="Priorität"
            onChange={(ev) => updateField('priority')(ev.target.value)}
          >
            {PRIORITIES.map((priority) => (
              <MenuItem key={priority.value} value={priority.value}>
                <PriorityIcon
                  css={priorityIconStyle}
                  className={priority.value}
                />{' '}
                {priority.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div css={marginContainer}>
        <div>Ziele / -personen</div>
        {folder.targets.map((target, index) => (
          <div key={index}>
            <TextField
              css={inputStyle}
              key={index}
              placeholder="Zielperson"
              value={target}
              onChange={(ev) => updateTarget(index)(ev.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
                endAdornment: folder.targets.length > 1 && (
                  <InputAdornment
                    position="end"
                    css={{ cursor: 'pointer' }}
                    onClick={() => removeTarget(index)}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        ))}
        <div>
          <Button
            startIcon={<AddIcon />}
            css={[inputStyle, addReceiverButton]}
            variant="outlined"
            onClick={() => updateTarget(folder.targets.length)('')}
          />
        </div>
      </div>
      <h2 css={subHeadlineStyle}>Notizen</h2>
      <div css={editorStyle}>
        {(!folder.notesVersion || folder.notesVersion < 2) && (
          <Editor
            content={folder.notes}
            setContent={updateField('notes')}
            size={{ width: '100%', height: 'unset' }}
          />
        )}
        {folder.notesVersion === 2 && (
          <LexicalEditor
            content={folder.notes}
            setContent={updateField('notes')}
          />
        )}
      </div>
      <div>
        <div css={documentHeaderContainer}>
          <h2 css={subHeadlineStyle}>Dokumente</h2>
          {!isAddDocOpen && (
            <IconButton
              css={iconButtonStyle}
              onClick={() => openAddingDocuments()}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
        {isAddDocOpen && documentAddList && (
          <div css={marginContainer}>
            <TextField
              label="Suche"
              css={inputStyle}
              value={documentSearch}
              onChange={(ev) => setDocumentSearch(ev.target.value)}
              onKeyPress={(e) => onDocumentSearchInput(e.key)}
              InputProps={{
                endAdornment: documentSearch !== '' && (
                  <InputAdornment
                    position="end"
                    css={{ cursor: 'pointer' }}
                    onClick={searchDocuments}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {documentAddList.list.length === 0 && (
              <div>Keine Dokumente gefunden</div>
            )}
            {documentAddList.list.length > 0 &&
              documentAddList.list.map((doc) => (
                <div key={doc._id}>
                  <div css={documentAddListStyle}>
                    <div
                      onClick={() => setOpenDocument(doc._id)}
                      css={[rowContainer, { cursor: 'pointer' }]}
                    >
                      <span>
                        <DocumentIcon /> {doc.subject}
                      </span>
                      <span>
                        {DateTime.fromJSDate(new Date(doc.modifiedDate))
                          .setLocale('de')
                          .toFormat('dd.LL.yy, HH:mm')}
                      </span>
                    </div>
                    <Button
                      variant="text"
                      onClick={() => addDocument(doc)}
                      startIcon={<NoteAddIcon />}
                      size="small"
                    >
                      hinzufügen
                    </Button>
                  </div>
                  <div css={marginContainer}>
                    {openDocument !== '' && openDocument === doc._id && (
                      <DocumentView
                        documentId={openDocument}
                        goBack={() => setOpenDocument('')}
                        viewOnly
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}

        <div css={{ marginTop: '2vh' }}>
          <h3>Hinzugefügt</h3>
          {folder.documents.map((doc) => (
            <div key={doc._id}>
              <div
                onClick={() => setOpenDocument(doc._id)}
                css={[rowContainer, { cursor: 'pointer' }]}
              >
                <span>
                  <FilePresentIcon /> {doc.subject}
                </span>
                <span>
                  {DateTime.fromJSDate(new Date(doc.modifiedDate))
                    .setLocale('de')
                    .toFormat('dd.LL.yy, HH:mm')}
                </span>
              </div>
              <div css={marginContainer}>
                {openDocument !== '' && openDocument === doc._id && (
                  <DocumentView
                    documentId={openDocument}
                    goBack={() => setOpenDocument('')}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
