import { lensPath, pathOr, set } from 'ramda';
import * as types from '../../../types';

const leogramP = ['apps', 'admin', 'leogram'];

export const appsAdminLeogramPostsP = [...leogramP, 'posts'];
export const appsAdminLeogramPostsL = lensPath(appsAdminLeogramPostsP);
const setPosts = (state: any, { data, offset }: any) => {
  const old = pathOr([], appsAdminLeogramPostsP, state);
  return offset === 0
    ? set(appsAdminLeogramPostsL, data, state)
    : set(appsAdminLeogramPostsL, [...old, ...data], state);
};

export const appsAdminLeogramTree = {
  [types.ADMIN_LEOGRAM_LIST_FETCH_SUCCESS]: [setPosts],
};
