import * as types from '../store/types';
import { pipeC, ofType, mapPromiseC } from '../utils/callbag';
import { authFetch } from '../utils/fetch';

export const onProfileSearchStart = (action$: any) =>
  pipeC(
    action$,
    ofType(types.LEOGRAM_PROFILE_SEARCH_FETCH_START),
    mapPromiseC(({ query }: any) => {
      return authFetch(`/leogram/search?q=${query}`)
        .then((res) => {
          return res.json().then((data) => ({
            type: types.LEOGRAM_PROFILE_SEARCH_FETCH_SUCCESS,
            data,
          }));
        })
        .catch((res) => {
          return {
            type: types.LEOGRAM_PROFILE_SEARCH_FETCH_FAILED,
          };
        });
    })
  );
