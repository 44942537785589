import { Button } from '@mui/material';
import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { startAdminPlayerMultiaccountBFetch } from '../../../../actions';
import {
  appsAdminPlayerMultiBCompletedP,
  appsAdminPlayerMultiBP,
} from '../../../../redux/reducer/apps/admin/player';
import { css } from '@emotion/react';
import { detailContainerStyle } from '../styles';

type MultiaccountBType = {
  _id: string;
};
export const MultiaccountB = ({ _id }: MultiaccountBType) => {
  const dispatch = useDispatch();
  const list = useSelector<any, string[]>(pathOr([], appsAdminPlayerMultiBP));

  const reachedEndOfList = useSelector<any, boolean>(
    pathOr(false, appsAdminPlayerMultiBCompletedP)
  );

  const onLoad = (offset: number) => {
    dispatch(startAdminPlayerMultiaccountBFetch(_id, offset));
  };

  return (
    <div css={detailContainerStyle}>
      <h2>Multiaccounts B</h2>

      {list.length === 0 && !reachedEndOfList && (
        <Button variant="outlined" onClick={() => onLoad(0)}>
          Laden
        </Button>
      )}

      {list.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((entry) => (
              <TableRow key={entry}>
                <TableCell>{entry}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
