import { lensPath, set } from 'ramda';
import * as types from '../../types';

export const playerOnDutyP = ['apps', 'folder', 'onDuty'];
export const playerOnDutyL = lensPath(playerOnDutyP);
const setPlayerOnDuty = (state: any, { isOnDuty }: any) => {
  return set(playerOnDutyL, isOnDuty, state);
};

export const folderGroupsMemberP = ['apps', 'folder', 'groups', 'asMember'];
export const folderGroupsMemberL = lensPath(folderGroupsMemberP);
const setGroupsAsMember = (state: any, { data }: any) => {
  return set(folderGroupsMemberL, data, state);
};

export const folderGroupsAdminP = ['apps', 'folder', 'groups', 'asAdmin'];
export const folderGroupsAdminL = lensPath(folderGroupsAdminP);
const setGroupsAsAdmin = (state: any, { data }: any) => {
  return set(folderGroupsAdminL, data, state);
};

export const appsFolderGroupsReducerTree = {
  [types.PLAYER_ONDUTY_FETCH_SUCCESS]: [setPlayerOnDuty],
  [types.FOLDER_GROUP_MEMBERS_FETCH_SUCCESS]: [setGroupsAsMember],
  [types.FOLDER_GROUP_ADMINS_FETCH_SUCCESS]: [setGroupsAsAdmin],
};
