import { path, pipe } from 'ramda';
import { connect } from 'react-redux';
import { startRouterGoto } from '../actions';
import { markActivityRead, startFetchNewActivity } from '../actions/activity';
import { Activity } from '../components/Activity';
import { activityDataP } from '../store/reducer/activity';

const mapStateToProps = (state: any) => {
  return {
    feeds: path(activityDataP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  fetchActivities: pipe(startFetchNewActivity, dispatch),
  goto: pipe(startRouterGoto, dispatch),
  markRead: pipe(markActivityRead, dispatch),
});

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(Activity);
