import { pathOr, pipe } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { startLogin } from '../actions';
import { Login } from '../components/Login';
import { darkP } from '../redux/reducer/common';

const mapStateToProps = (state: any) => {
  return {
    dark: pathOr(false, darkP, state),
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  sleepMac: () => {},
  shutMac: () => {},
  restartMac: () => {},
  login: pipe(startLogin, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
