import { css } from '@emotion/react';
import React from 'react';

const pStyle = css`
  margin-bottom: 1vh;
`;

export const Information = () => {
  return (
    <div>
      <h1>Information</h1>

      <p css={pStyle}>
        Die Akten App ist nur für Staatsfraktionen und Firmen verfügbar.
      </p>
      <p css={pStyle}>
        Staatsfraktionen können in den Gruppen die Zugriffe manuell einstellen
        und so entsprechend Abteilungen unterschiedlichen Personen Zugriff
        ermöglichen. Für jeden Zugriff ist es jedoch notwendig, dass man sich im
        Dienst der Behörde befindet
      </p>
      <p css={pStyle}>
        Firmen können über die Berechtigungen der Mitarbeiter Zugriff zu den
        Akten freigeben. Eine weitere Unterteilung in die verschiedenen Gruppen
        oder nur Lese Rechte ist nicht möglich. Der Zugriff auf die Akten ist
        immer nur Firmen Intern und nicht Firmen übergreifend.
      </p>
    </div>
  );
};
