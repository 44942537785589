import { useState } from 'react';
import html2canvas from 'html2canvas';

const HTML2CANVAS_OPTIONS = {
  allowTaint: false,
  useCORS: true,
};

/**
 * @module Main_Hook
 * Hook return
 * @typedef {Array} HookReturn
 * @property {string} HookReturn[0] - image string
 * @property {string} HookReturn[1] - take screen shot string
 * @property {object} HookReturn[2] - errors
 */

/**
 * hook for creating screenshot from html node
 * @returns {HookReturn}
 */
const useScreenshot = ({
  type,
  quality,
}: { type?: string; quality?: any } = {}): [
  string | null,
  (node: HTMLElement) => Promise<string | void>,
  any
] => {
  const [image, setImage] = useState<string | null>(null);
  const [error, setError] = useState(null);
  /**
   * convert html node to image
   * @param {HTMLElement} node
   */
  const takeScreenShot = async (node: HTMLElement) => {
    if (!node) {
      throw new Error('You should provide correct html node.');
    }

    // replace all gyazoo images with our proxy
    const imgElems = document.getElementsByTagName('img');
    for (let i = 0; i < imgElems.length; i++) {
      const imgElem = imgElems[i];
      if (imgElem.src.startsWith('https://i.gyazo.com')) {
        imgElem.src = imgElem.src.replace(
          'https://i.gyazo.com',
          '/proxy/gyazoo'
        );
      }
    }

    // timeout required that new urls are loaded into the dom
    await new Promise((resolve) => setTimeout(resolve, 1000));

    return html2canvas(node, HTML2CANVAS_OPTIONS)
      .then((canvas) => {
        const croppedCanvas = document.createElement('canvas');
        const croppedCanvasContext = croppedCanvas.getContext('2d');

        // abort when croppedCanvas not found
        if (!croppedCanvasContext) {
          throw new Error('Cropped canvas not found');
        }

        // init data
        const cropPositionTop = 0;
        const cropPositionLeft = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop
        );

        const base64Image = croppedCanvas.toDataURL(type, quality);

        setImage(base64Image);
        return base64Image;
      })
      .catch(setError);
  };

  return [
    image,
    takeScreenShot,
    {
      error,
    },
  ];
};

/**
 * creates name of file
 * @param {string} extension
 * @param  {string[]} parts of file name
 */
const createFileName = (extension = '', ...names: string[]) => {
  if (!extension) {
    return '';
  }

  return `${names.join('')}.${extension}`;
};

export { useScreenshot, createFileName };
