import { css } from '@emotion/react';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import PersonIcon from '@mui/icons-material/PersonRounded';
import SendIcon from '@mui/icons-material/SendRounded';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import Headline from '../../Headline';

import { AccountAccessData, BankAccount } from './types';

const inputStyle = css`
  width: 100%;
  max-width: 35vh;
  display: flex !important;
`;

type AccountAccessType = {
  account: BankAccount;
  close: () => void;
  access: AccountAccessData[];
  editAccess: (
    vban: string,
    _id: string,
    accessRightType: string,
    newValue: boolean
  ) => void;
  rmAccess: (vban: string, _id: string) => void;
  addAccess: (vban: string, playerName: string) => void;
};

export const AccountAccess = ({
  account,
  close,
  access,
  editAccess,
  rmAccess,
  addAccess,
}: AccountAccessType) => {
  const [accessNewUser, setAccessNewUser] = useState('');

  return (
    <>
      <Headline>Kontozugriff</Headline>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Transaktionen ansehen</TableCell>
            <TableCell>Geld überweisen</TableCell>
            <TableCell>Konto bearbeiten</TableCell>
            <TableCell>Zugriff entfernen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {access.map((entry) => (
            <TableRow key={entry._id}>
              <TableCell>
                <PersonIcon /> {entry.character.name}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={entry.isAllowedToSee}
                  onChange={() =>
                    editAccess(
                      account.vban,
                      entry._id,
                      'isAllowedToSee',
                      !entry.isAllowedToSee
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={entry.isAllowedToUse}
                  onChange={() =>
                    editAccess(
                      account.vban,
                      entry._id,
                      'isAllowedToUse',
                      !entry.isAllowedToUse
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={entry.isAllowedToEdit}
                  onChange={() =>
                    editAccess(
                      account.vban,
                      entry._id,
                      'isAllowedToEdit',
                      !entry.isAllowedToEdit
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => rmAccess(account.vban, entry._id)}
                  startIcon={<CloseIcon />}
                >
                  Entfernen
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <TextField
          css={inputStyle}
          type="text"
          placeholder="Person hinzufügen"
          value={accessNewUser}
          onChange={(ev) => setAccessNewUser(ev.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
            endAdornment: accessNewUser !== '' && (
              <InputAdornment
                position="end"
                css={{ cursor: 'pointer' }}
                onClick={() => addAccess(account.vban, accessNewUser)}
              >
                <SendIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Button onClick={close} startIcon={<CloseIcon />}>
        Schliessen
      </Button>
    </>
  );
};
