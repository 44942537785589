import React, { useEffect, useState } from 'react';

import { $getRoot, $getSelection, EditorState, RootNode } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import ContentEditable from './ui/ContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import TableCellResizer from './plugins/TableCellResizer';
import { TablePlugin as NewTablePlugin } from './plugins/TablePlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import HorizontalRulePlugin from './plugins/HorizontalRulePlugin';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import TableCellNodes from './nodes/TableCellNodes';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
import Theme from './themes/default';

import './index.css';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { equals } from 'ramda';
import { css } from '@emotion/react';
import { containsChecklist } from './utils/contains-checklist';

function onError(error: any) {
  console.error(error);
}

type LexicalEditoType = {
  content: any;
  setContent: (content: any) => void;
  isReadOnly?: boolean;
  zoom?: number;
  onBtnTakeScrenshot?: () => void;
};
export const LexicalEditorContent = ({
  content,
  setContent,
  isReadOnly = false,
  zoom,
  onBtnTakeScrenshot,
}: LexicalEditoType) => {
  const [editor] = useLexicalComposerContext();
  const [initTime, setInitTime] = useState(Date.now());
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<
    HTMLDivElement | undefined
  >(undefined);

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== undefined) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  // initially load content
  useEffect(() => {
    if (content) {
      const editorState = editor.parseEditorState(content);
      editor.setEditorState(editorState);
      setInitTime(Date.now());

      if (isReadOnly) {
        const removeUpdateListener = editor.registerUpdateListener(
          ({ editorState: newState }) => {
            if (!equals(editorState.toJSON(), newState.toJSON())) {
              editor.setEditorState(editorState);
            }
          }
        );
        return () => {
          removeUpdateListener();
        };
      }
    }
  }, [editor]);

  const cellEditorConfig = {
    namespace: 'Playground',
    nodes: [...TableCellNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: Theme,
    // editable: !isReadOnly,
  };

  const onChange = (editorState: EditorState) => {
    // abort on change less than 1 second after init
    if (Date.now() - initTime < 1000) {
      return;
    }

    if (!isReadOnly) {
      setContent(editorState.toJSON());
    }
  };

  return (
    <>
      {!isReadOnly && <ToolbarPlugin onBtnTakeScrenshot={onBtnTakeScrenshot} />}
      <DragDropPaste />
      <RichTextPlugin
        contentEditable={
          <div
            className="editor-scroller"
            css={css`
              zoom: ${zoom};
              resize: none;
            `}
          >
            <div className="editor editor-shell" ref={onRef}>
              <ContentEditable />
            </div>
          </div>
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <MarkdownShortcutPlugin />
      <CodeHighlightPlugin />
      <ListPlugin />
      <CheckListPlugin />
      <ListMaxIndentLevelPlugin maxDepth={7} />
      <OnChangePlugin onChange={onChange} />
      <HistoryPlugin />
      <TablePlugin />
      {/* <TableCellResizer /> */}
      <NewTablePlugin cellEditorConfig={cellEditorConfig}>
        {/* <AutoFocusPlugin /> */}
        <RichTextPlugin
          contentEditable={
            <ContentEditable className="TableNode__contentEditable" />
          }
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <ImagesPlugin captionsEnabled={false} />
        {!isReadOnly ? <FloatingTextFormatToolbarPlugin /> : <span />}
      </NewTablePlugin>
      <ImagesPlugin />
      <HorizontalRulePlugin />
      <ExcalidrawPlugin />
      <TabFocusPlugin />
      <TabIndentationPlugin />
      {!isReadOnly && (!zoom || zoom === 1) && (
        <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
      )}
      {!isReadOnly && (
        <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
      )}
      {!isReadOnly && (
        <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
      )}
    </>
  );
};

export const LexicalEditor = ({
  content,
  setContent,
  isReadOnly = false,
  zoom,
  onBtnTakeScrenshot,
}: LexicalEditoType) => {
  const initialConfig = {
    namespace: 'MyEditor',
    nodes: [...PlaygroundNodes],
    theme: Theme,
    onError,
    // editable: !isReadOnly,
  };

  const hasChecklist = containsChecklist(content?.root);

  // zoom has to be disabled while using checklists
  // otherwise the click position used by lexical cannot be calculated correctly
  const checkedZoom = hasChecklist && !isReadOnly ? 1 : zoom;

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <div className={`editor-container`}>
          <LexicalEditorContent
            content={content}
            setContent={setContent}
            isReadOnly={isReadOnly}
            zoom={checkedZoom}
            onBtnTakeScrenshot={onBtnTakeScrenshot}
          />
        </div>
      </SharedHistoryContext>
    </LexicalComposer>
  );
};
