import { FactorySimpleData } from '../components/apps/Folder/folder-types';
import * as type from '../redux/types';

export const pollPlayerOnDuty = () => ({
  type: type.PLAYER_ONDUTY_FETCH_START,
});

export const createFolderGroup = (label: string) => ({
  type: type.FOLDER_GROUP_CREATE_START,
  label,
});

export const fetchFolderGroupsAsMember = () => ({
  type: type.FOLDER_GROUP_MEMBERS_FETCH_START,
});

export const fetchFolderGroupsAsAdmin = () => ({
  type: type.FOLDER_GROUP_ADMINS_FETCH_START,
});

export const addMemberToFolderGroup = (group: string, character: string) => ({
  type: type.FOLDER_GROUP_MEMBER_ADD_START,
  group,
  character,
});
export const rmMemberToFolderGroup = (group: string, character: string) => ({
  type: type.FOLDER_GROUP_MEMBER_REMOVE_START,
  group,
  character,
});
export const addAdminToFolderGroup = (group: string, character: string) => ({
  type: type.FOLDER_GROUP_ADMIN_ADD_START,
  group,
  character,
});
export const rmAdminToFolderGroup = (group: string, character: string) => ({
  type: type.FOLDER_GROUP_ADMIN_REMOVE_START,
  group,
  character,
});

export const createFolderGroupForFactory = (
  label: string,
  factory: FactorySimpleData
) => ({
  type: type.FOLDER_GROUP_FACTORY_CREATE_START,
  label,
  factory,
});
